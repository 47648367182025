import { format } from 'date-fns';
import { CheckIcon } from 'lucide-react';

import { LONG_DATE_FORMAT } from '@spektr/client/constants';
import { useGetUserById } from '@spektr/platform-hooks';

export type ServiceCardConfigurationStatusProps = {
  updatedBy: string;
  updatedAt?: number;
};

export const ServiceCardConfigurationStatus = ({
  updatedBy,
  updatedAt,
}: ServiceCardConfigurationStatusProps) => {
  const user = useGetUserById(updatedBy);

  const getFormattedTime = (number?: number) => {
    return number ? format(new Date(number), LONG_DATE_FORMAT) : null;
  };

  return (
    <div className="flex gap-2">
      <div className="dark:bg-color-bg-accent bg-color-bg-secondary/15 flex h-8 min-w-8 items-center justify-center rounded-md">
        <CheckIcon className="stroke-color-stroke-white h-3.5 stroke-2" />
      </div>

      <div className="flex flex-col justify-center">
        <p className="text-color-text-primary text-xs font-semibold leading-4">
          Added by {user?.firstName + ' ' + user?.lastName}
        </p>
        <span className="text-color-text-subtext text-xs font-normal">
          {getFormattedTime(updatedAt)}
        </span>
      </div>
    </div>
  );
};
