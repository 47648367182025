import {
  mitIdCprFields,
  mitIdAddressFields,
  mitIdBaseFields,
  SpektrFieldKey,
} from '@spektr/shared/types';

import { ServiceFieldClient } from '../types/ServiceFieldClient';

const CPR_KEY = mitIdCprFields[0].key as SpektrFieldKey;
const ADDRESS_KEY = mitIdAddressFields[0].key as SpektrFieldKey;

export const computeMitIdFields = (fields: ServiceFieldClient[]) => {
  const selectedFieldKeys = fields
    .filter((field) => field.value)
    .map((selectedField) => selectedField.key);
  return [
    {
      key: CPR_KEY as SpektrFieldKey,
      label: 'CPR',
      value: isMitIdFieldSelected(CPR_KEY, selectedFieldKeys),
      help: 'Get the CPR number of the individual.',
    },
    {
      key: ADDRESS_KEY as SpektrFieldKey,
      label: 'Address',
      value: isMitIdFieldSelected(ADDRESS_KEY, selectedFieldKeys),
      help: 'Get the address of the individual.',
    },
    {
      key: 'low' as SpektrFieldKey,
      label: 'Low',
      value: isMitIdFieldSelected('low', selectedFieldKeys),
      help: 'Set the login type to low.',
    },
    {
      key: 'substantial' as SpektrFieldKey,
      label: 'Substantial',
      value: isMitIdFieldSelected('substantial', selectedFieldKeys),
      help: 'Set the login type to substantial.',
    },
    {
      key: 'high' as SpektrFieldKey,
      label: 'High',
      value: isMitIdFieldSelected('high', selectedFieldKeys),
      help: 'Set the login type to high.',
    },
  ];
};

export const isMitIdFieldSelected = (
  fieldKey: string,
  fields: SpektrFieldKey[]
) => fields.some((field) => field === fieldKey);

export const getSelectedMitIdFields = (
  selectedFields: SpektrFieldKey[]
): SpektrFieldKey[] => {
  if (selectedFields.length === 0) {
    return [];
  }

  const isCprSelected = isMitIdFieldSelected(CPR_KEY, selectedFields);

  const isAddressSelected = isMitIdFieldSelected(ADDRESS_KEY, selectedFields);

  const extendedSelectedFields: SpektrFieldKey[] = [];

  if (isCprSelected) {
    extendedSelectedFields.push(
      ...mitIdCprFields.map((field) => field.key as SpektrFieldKey)
    );
  }
  if (isCprSelected && isAddressSelected) {
    extendedSelectedFields.push(
      ...mitIdAddressFields.map((field) => field.key as SpektrFieldKey)
    );
  }

  extendedSelectedFields.push(
    ...mitIdBaseFields.map((field) => field.key as SpektrFieldKey)
  );

  return extendedSelectedFields;
};
