import { useMemo } from 'react';
import { ListTodoIcon } from 'lucide-react';

import { IconWithBackground } from '@spektr/client/components';

import { NodeType, ProcessType } from '@spektr/shared/validators';

import { DrawerItemType } from '../types/DrawerItemType';
import { getAvailableItemsForProcess } from '../utils/getAvailableItemsForProcess';
import { useFeatureFlags } from '@spektr/platform-hooks';

export const useActions = (processType?: ProcessType): DrawerItemType[] => {
  const { manualReview } = useFeatureFlags();
  return useMemo(() => {
    const actions: DrawerItemType[] = [
      {
        type: NodeType.enum.action,
        title: 'Action',
        variant: 'action',
        icon: (
          <IconWithBackground className="bg-color-pink">
            <ListTodoIcon className="dark:stroke-color-stroke-primary stroke-color-stroke-default h-6 w-6" />
          </IconWithBackground>
        ),
        description:
          'The action step creates a manual task for you to resolve.',
      },
      {
        type: NodeType.enum.manualReview,
        title: 'Manual Review',
        variant: 'action', //TODO add new variant
        icon: (
          <IconWithBackground className="bg-color-pink">
            <ListTodoIcon className="dark:stroke-color-stroke-primary stroke-color-stroke-default h-6 w-6" />
          </IconWithBackground>
        ),
        description:
          'The manual review step creates a list of tasks for you to resolve.',
        hidden: !manualReview,
      },
    ];

    return getAvailableItemsForProcess(actions, processType);
  }, [processType]);
};
