import { cn } from '@spektr/client/utils';

import { useTranslation } from 'react-i18next';

import { ButtonProps, ButtonWithTooltip } from '../Button';

export type PageIntroCardProps = {
  title: string;
  description: string;
  actionName: string;
  actionButtonProps: ButtonProps & { 'data-cy'?: string };
  renderIcon: (className: string) => React.ReactNode;
  showDemoTooltip?: boolean;
};

export const PageIntroCard = ({
  title,
  description,
  actionName,
  actionButtonProps,
  renderIcon,
  showDemoTooltip = false,
}: PageIntroCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="page-intro-card"
      className={cn(
        'flex flex-col justify-between',
        'h-[131px] w-[248px] p-4',
        'border-color-border-card rounded-md border'
      )}
    >
      <div className="flex gap-3">
        {renderIcon('h-9 w-9 p-2 shrink-0')}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-medium leading-none">{title}</p>
          <p className="text-color-text-subtext line-clamp-2 text-xs font-medium">
            {description}
          </p>
        </div>
      </div>

      <ButtonWithTooltip
        fullWidth
        size="sm"
        className={cn('h-8.5 font-semibold', actionButtonProps.className)}
        {...actionButtonProps}
        disabled={!!showDemoTooltip}
        showTooltip={showDemoTooltip}
        tooltipProps={{
          content: t('errors.demoPlatform'),
          side: 'bottom',
        }}
      >
        {actionName}
      </ButtonWithTooltip>
    </div>
  );
};
