import { produce } from 'immer';
import { Search } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { cn } from '@spektr/client/utils';

import {
  Input,
  ProcessCollapsibleList,
  Select,
} from '@spektr/client/components';

import { ProcessSource, Process } from '@spektr/shared/types';

import { extractItems, transformItemToSource } from './utils';

export type ProcessOutcomesProps = {
  process?: Process;
  processes: Process[];
  onUpdateSelection: (sources: ProcessSource[]) => void;
};

// TODO: This needs to support single process selection
export const ProcessOutcomes = ({
  process,
  processes,
  onUpdateSelection,
}: ProcessOutcomesProps) => {
  const [items, setItems] = useState(extractItems(processes, process));
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (
    processId: string,
    childId: string,
    checked: boolean
  ) => {
    setItems(
      produce(items, (draft) => {
        const process = draft.find((item) => item.id === processId);

        if (process) {
          const child = process.children.find((item) => item.id === childId);

          if (child) {
            child.isSelected = checked;
          }
        }

        onUpdateSelection(transformItemToSource(draft));
      })
    );
  };

  const searchValue = searchParams.get('search') ?? undefined;
  const processTypeValue = searchParams.get('type') ?? 'all';

  const filteredItems = useMemo(() => {
    return items
      .filter((item) => {
        if (processTypeValue !== 'all') {
          return item.type === processTypeValue;
        }
        return true;
      })
      .filter((item) => {
        if (searchValue) {
          return item.label.toLowerCase().includes(searchValue.toLowerCase());
        }
        return true;
      });
  }, [items, searchValue, processTypeValue]);

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-3 gap-2">
        <Input
          startIcon={<Search className="h4 w-4" />}
          placeholder="Search"
          className="col-span-2"
          defaultValue={searchValue}
          onChange={(event) => {
            setSearchParams({
              ...Object.fromEntries(searchParams.entries()),
              search: event.target.value,
            });
          }}
          autoComplete="off"
        />
        <Select
          defaultValue={processTypeValue}
          options={[
            { value: 'all', label: 'All' },
            { value: 'monitoring', label: 'Monitoring' },
            { value: 'risk', label: 'Risk' },
            { value: 'score', label: 'Customer Score' },
          ]}
          onValueChange={(value) => {
            setSearchParams({
              ...Object.fromEntries(searchParams.entries()),
              type: value,
            });
          }}
        />
      </div>
      <div
        className={cn(
          'h-96 p-4',
          'rounded-md border',
          'rounded-md border',
          'overflow-y-auto'
        )}
      >
        <ProcessCollapsibleList items={filteredItems} onCheck={handleChange} />
      </div>
    </div>
  );
};
