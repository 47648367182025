import { ReactNode } from 'react';

import { cn } from '@spektr/client/utils';

export type SettingItemProps = {
  children: ReactNode;
  className?: string;
  title: string;
  description?: string;
};

export const SettingItem = ({
  children,
  className,
  title,
  description,
}: SettingItemProps) => (
  <div
    className={cn(
      'flex flex-row items-center py-3',
      'text-color-text-dialog-default',
      className
    )}
  >
    <div className="flex flex-col gap-0.5">
      <span className="whitespace-nowrap text-sm">{title}</span>
      {description && (
        <span className="text-color-text-dialog-description whitespace-nowrap text-xs">
          {description}
        </span>
      )}
    </div>
    <div className="ml-auto">{children}</div>
  </div>
);
