import { useMemo } from 'react';

import { deserializeSlate } from '@spektr/client/components';

import { type BrandingFormAssistingContent } from '@spektr/branding-types';
import { type DeserializedBrandingFormAssistingContent } from '@spektr/moonraker-types';

export function useDeserializedAssistingContent(
  assistingContent: BrandingFormAssistingContent
): DeserializedBrandingFormAssistingContent {
  const deserializedTheme =
    useMemo<DeserializedBrandingFormAssistingContent>(() => {
      return {
        title: assistingContent.title,
        description: deserializeSlate(assistingContent.description),
        support: {
          display: assistingContent.support.display,
          title: assistingContent.support.title,
          content: deserializeSlate(assistingContent.support.content),
          backgroundColor: assistingContent.support.backgroundColor,
          textColor: assistingContent.support.textColor,
        },
      };
    }, [assistingContent]);

  return deserializedTheme;
}
