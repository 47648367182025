import { z } from 'zod';

export const WidgetTypes = z.enum([
  'input',
  'textarea',
  'upload',
  'headline',
  'paragraph',
]);
export type WidgetTypes = z.infer<typeof WidgetTypes>;

export function isValidWidgetType(value: string): value is WidgetTypes {
  return WidgetTypes.safeParse(value).success;
}
