import { cn } from '@spektr/client/utils';

import { HubspotIcon, SalesforceIcon } from '@spektr/client/components';

export const CONNECTIONS = [
  {
    id: 'salesforce',
    description: 'Add your Salesforce data now',
    icon: (
      <SalesforceIcon
        className={cn('fill-muted-foreground h-9 w-9 rounded bg-[#00a1df] p-2')}
      />
    ),
    title: 'Salesforce',
  },
  {
    id: 'hubspot',
    description: 'Add your Hubspot data now',
    icon: (
      <HubspotIcon
        className={cn('fill-muted-foreground h-9 w-9 rounded bg-[#ff5c35] p-2')}
      />
    ),
    title: 'Hubspot',
  },
];
