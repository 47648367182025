import { insightsActionDetailsUrl } from '@spektr/shared/utils';

import { Action } from '@spektr/shared/validators';

import { ClientHitCategory } from '../types/ClientHitCategory';

export function computeHitCategories(action: Action) {
  const categoriesMap = action.hits.reduce(
    (acc, hit) => {
      const key = `${hit.vendorDetails.service}:${hit.vendorDetails.field}`;

      const searchParams = new URLSearchParams();

      searchParams.set('service', hit.vendorDetails.service);
      hit.vendorDetails?.field &&
        searchParams.set('field', hit.vendorDetails.field);

      const url = `${insightsActionDetailsUrl(
        action.id
      )}?${searchParams.toString()}`;

      const isResolved = hit.decision.status !== 'pending';

      if (acc[key]) {
        acc[key].count += 1;
        acc[key].unresolvedCount += isResolved ? 0 : 1;
      } else {
        acc[key] = {
          service: hit.vendorDetails.service,
          field: hit.vendorDetails.field,
          count: 1,
          unresolvedCount: isResolved ? 0 : 1,
          url,
        };
      }
      return acc;
    },
    {} as Record<string, ClientHitCategory>
  );

  return Object.values(categoriesMap);
}
