import { z } from 'zod';
import { makeApi, makeEndpoint } from '@zodios/core';

import { paginatedParameters } from '../pagination';

import {
  CommentCreateSchema,
  CommentUpdateSchema,
  commentListResponsePaginatedSchemaDto,
  commentSchemaDto,
  parentTypeSchema,
} from './comments';

export const createComment = makeEndpoint({
  method: 'post',
  path: '/comments',
  alias: 'createComment',
  parameters: [
    {
      name: 'body',
      schema: CommentCreateSchema,
      type: 'Body',
    },
  ],
  response: commentSchemaDto,
});

export const getCommentsByParentId = makeEndpoint({
  method: 'get',
  path: '/comments/:parentId',
  alias: 'getCommentsByParentId',
  parameters: [
    {
      name: 'parentId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'parentType',
      schema: parentTypeSchema,
      type: 'Query',
    },
    ...paginatedParameters,
  ],
  response: commentListResponsePaginatedSchemaDto,
});

export const updateCommentById = makeEndpoint({
  method: 'patch',
  path: '/comments/:commentId',
  alias: 'updateCommentById',
  parameters: [
    {
      name: 'commentId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'body',
      type: 'Body',
      schema: CommentUpdateSchema,
    },
  ],
  response: commentSchemaDto,
});

export const deleteCommentById = makeEndpoint({
  method: 'delete',
  path: '/comments/:id',
  alias: 'deleteCommentById',
  parameters: [
    {
      name: 'id',
      schema: z.string(),
      type: 'Path',
    },
  ],
  response: z.union([z.void(), z.string().nullable()]),
});

export const countCommentsByParentId = makeEndpoint({
  method: 'get',
  path: '/comments/:parentId/count',
  alias: 'countCommentsByParentId',
  parameters: [
    {
      name: 'parentId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'parentType',
      schema: parentTypeSchema,
      type: 'Query',
    },
  ],
  response: z.number(),
});

export const commentsApi = makeApi([
  createComment,
  getCommentsByParentId,
  updateCommentById,
  deleteCommentById,
  countCommentsByParentId,
]);
