import { useState, useCallback, useMemo } from 'react';

import { DataTableProps } from '../../DataTable';

export enum RowsPerPage {
  Ten = 10,
  TwentyFive = 25,
  Fifty = 50,
  Hundred = 100,
}

export const useTablePagination = <TData,>(
  rows: DataTableProps<TData, unknown>['data']
) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState<RowsPerPage>(RowsPerPage.Ten);

  const getNumberOfPages = (entitiesCount: number, rowsCount: RowsPerPage) => {
    return Math.ceil(entitiesCount / rowsCount);
  };

  const [numberOfPages, setNumberOfPages] = useState(
    getNumberOfPages(rows.length, rowsPerPage)
  );

  const getRows = useCallback(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return rows.slice(start, end);
  }, [page, rows, rowsPerPage]);

  return useMemo(
    () => ({
      page,
      rowsPerPage,
      numberOfPages,
      handlePageChange: (page: number) => {
        setPage(page);
      },
      handleRowsPerPageChange: (rowsPerPage: string) => {
        setRowsPerPage(Number(rowsPerPage));
        setNumberOfPages(getNumberOfPages(rows.length, Number(rowsPerPage)));
      },
      getRows,
    }),
    [rows.length, page, rowsPerPage, numberOfPages, getRows]
  );
};
