import { HTMLAttributes } from 'react';

import { cn } from '@spektr/client/utils';

export const SpektrDatasetIcon = ({
  className,
  ...props
}: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={cn('stroke-color-stroke-icon', className)}
      {...props}
    >
      <path
        d="M1.5 3.9C1.5 3.05992 1.5 2.63988 1.66349 2.31901C1.8073 2.03677 2.03677 1.8073 2.31901 1.66349C2.63988 1.5 3.05992 1.5 3.9 1.5H9.6C10.4401 1.5 10.8601 1.5 11.181 1.66349C11.4632 1.8073 11.6927 2.03677 11.8365 2.31901C12 2.63988 12 3.05992 12 3.9V9.6C12 10.4401 12 10.8601 11.8365 11.181C11.6927 11.4632 11.4632 11.6927 11.181 11.8365C10.8601 12 10.4401 12 9.6 12H3.9C3.05992 12 2.63988 12 2.31901 11.8365C2.03677 11.6927 1.8073 11.4632 1.66349 11.181C1.5 10.8601 1.5 10.4401 1.5 9.6V3.9Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.4C6 7.55992 6 7.13988 6.16349 6.81901C6.3073 6.53677 6.53677 6.3073 6.81901 6.16349C7.13988 6 7.55992 6 8.4 6H14.1C14.9401 6 15.3601 6 15.681 6.16349C15.9632 6.3073 16.1927 6.53677 16.3365 6.81901C16.5 7.13988 16.5 7.55992 16.5 8.4V14.1C16.5 14.9401 16.5 15.3601 16.3365 15.681C16.1927 15.9632 15.9632 16.1927 15.681 16.3365C15.3601 16.5 14.9401 16.5 14.1 16.5H8.4C7.55992 16.5 7.13988 16.5 6.81901 16.3365C6.53677 16.1927 6.3073 15.9632 6.16349 15.681C6 15.3601 6 14.9401 6 14.1V8.4Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
