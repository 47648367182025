import { ActionFieldSchema } from '@spektr/shared/validators';

export function computeAvailableAndSelectedFields(
  action: ActionFieldSchema,
  selectedFields: ActionFieldSchema | null
) {
  return Object.entries(action.fields)
    .filter(([_, value]) => Boolean(value))
    .map(([key]) => key)
    .reduce(
      (acc, field) => {
        return {
          ...acc,
          [field]: selectedFields?.fields[field] ?? false,
        };
      },
      {} as Record<string, boolean>
    );
}
