import { format } from 'date-fns';

import { FULL_DATE_TIME_FORMAT } from '@spektr/client/constants';
import { cn } from '@spektr/client/utils';

import {
  ActionStatusBadge,
  Button,
  ProcessIcon,
  SpektrRoutingNode,
  TableCell,
  TableRow,
} from '@spektr/client/components';

import { ActionTableRowData } from '../../types/ActionTableRowData';

const baseRowCellClasses = cn('px-4', 'border-b', 'text-sm font-medium');

export type ActionRowProps = {
  rowItem: ActionTableRowData;
  isLastRow?: boolean;
  onClickReview: (id: string) => void;
};

export const ActionRow = ({
  rowItem,
  isLastRow = false,
  onClickReview,
}: ActionRowProps) => {
  return (
    <TableRow
      onClick={() => onClickReview(rowItem.id)}
      key={rowItem.id}
      className="hover:bg-color-bg-table-row--hover h-[63px] cursor-pointer border-b"
    >
      <TableCell
        className={cn(baseRowCellClasses, 'border-l', {
          'rounded-bl-md': isLastRow,
        })}
      >
        {rowItem.recordName}
      </TableCell>
      <TableCell className={baseRowCellClasses}>
        <div className="flex flex-row items-center gap-2">
          <ProcessIcon boxIcon processType={rowItem.processType} />
          {rowItem.processName}
        </div>
      </TableCell>
      <TableCell className={baseRowCellClasses}>
        <div className="flex flex-row items-center gap-2">
          {rowItem?.sourceName && (
            // TODO: (@Robert) - Add node type icon here
            <>
              <div
                className={cn(
                  'flex items-center justify-center',
                  'h-5 w-5',
                  'rounded-[6px]',
                  'bg-spektr-red'
                )}
              >
                <SpektrRoutingNode />
              </div>
              {rowItem.sourceName}
            </>
          )}
        </div>
      </TableCell>
      <TableCell className={baseRowCellClasses}>
        {format(rowItem.createdAt, FULL_DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell
        className={cn(baseRowCellClasses, 'border-r', {
          'rounded-br-md': isLastRow,
        })}
      >
        <div className="flex shrink-0 flex-row items-center justify-end gap-3 text-nowrap">
          <ActionStatusBadge
            hitsCount={rowItem.hitsCount}
            resolvedHitsCount={rowItem.resolvedHitsCount}
          />
          {rowItem.resolvedHitsCount < rowItem.hitsCount && (
            <Button
              color="white"
              size="sm"
              onClick={() => onClickReview(rowItem.id)}
              data-cy={`review-action-${rowItem.processName}`}
            >
              Review
            </Button>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
