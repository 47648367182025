import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trash2 } from 'lucide-react';

import { RBAC } from '@spektr/shared/rbac';
import { triggerFrequency } from '@spektr/shared/validators';
import { TriggerFrequency } from '@spektr/shared/types';

import {
  ButtonWithTooltip,
  DatePicker,
  Input,
  Label,
  SelectWithTooltip,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@spektr/client/components';
import { useDemo, usePermissionsContext } from '@spektr/client/providers';

import { DatasetActionButton } from '../../components/DatasetActionButton';
import { useSourceProvider } from '../../providers/SourceProvider';

const triggerFrequencies = ['day', 'week', 'month'] as const;

// TODO: @Alex - resolve commented lines after api implementation
export const ProcessTrigger = () => {
  const { t } = useTranslation();
  const { t: tProcess } = useTranslation('process');
  const { processId, removeTrigger, trigger, setTrigger } = useSourceProvider();
  const { isDemo } = useDemo();
  const { hasPermission } = usePermissionsContext();
  const [triggerType, setTriggerType] = useState<
    'custom_trigger' | 'field_trigger'
  >('custom_trigger');

  const disabledReason = useMemo(() => {
    if (isDemo) {
      return t('errors.demoPlatform');
    }

    if (!hasPermission(RBAC.ACTIONS.PROCESS.LAUNCH)) {
      return tProcess('errors.permissions.trigger');
    }

    return '';
  }, [hasPermission, isDemo, t, tProcess]);

  if (!trigger?.frequency) {
    return (
      <DatasetActionButton
        onClick={() => {
          setTrigger({ processId, frequency: triggerFrequency[0] });
        }}
      >
        Add trigger
      </DatasetActionButton>
    );
  }

  const frequencyOptions = triggerFrequencies.map((frequency, index) => ({
    label: frequency,
    value: triggerFrequency[index],
  }));

  const canTrigger = !disabledReason;

  return (
    <div className="flex flex-col gap-4 rounded-md border p-4">
      <div className="flex items-center gap-4">
        <Tabs
          value={triggerType}
          className="grow text-xs"
          onValueChange={(value) =>
            setTriggerType(value as 'custom_trigger' | 'field_trigger')
          }
        >
          <TabsList>
            <TabsTrigger value="custom_trigger">
              Custom trigger date
            </TabsTrigger>
            <TabsTrigger value="field_trigger" disabled>
              Field as trigger date
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <ButtonWithTooltip
          color="secondary"
          variant="text"
          size="sm"
          className="group"
          showTooltip={true}
          tooltipProps={{
            content: 'Remove trigger',
          }}
          onClick={removeTrigger}
        >
          <Trash2 className="text-color-text-icon-tertiary group-hover:text-color-red h-4 w-4" />
        </ButtonWithTooltip>
      </div>
      <div className="flex items-center gap-6">
        <div className="flex basis-1/2 flex-col gap-1.5">
          <Label>First trigger date</Label>
          <DatePicker
            epochOrToday={new Date().getTime().toString()}
            onChange={() => {
              // setTrigger({ date });
            }}
            placeholder="Choose first trigger date"
            // disabled={!canTrigger}
            disabled
          />
        </div>
        <div className="flex basis-1/2 flex-col gap-1.5">
          <Label>Frequency trigger</Label>
          <div className="flex items-center gap-2">
            <span className="text-color-text-subtext text-sm">every</span>
            <Input
              value="1"
              // disabled={!canTrigger}
              disabled
            />
            <SelectWithTooltip
              value={trigger.frequency}
              options={frequencyOptions}
              disabled={!canTrigger}
              onValueChange={(value) =>
                setTrigger({ frequency: value as TriggerFrequency })
              }
              tooltipProps={{
                content: disabledReason,
                side: 'left',
              }}
              showTooltip={!canTrigger}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
