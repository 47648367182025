import { ReactNode } from 'react';

import { cn } from '@spektr/client/utils';

type IconWithBackgroundProps = {
  children: ReactNode;
  className?: string;
};

export const IconWithBackground = ({
  children,
  className,
}: IconWithBackgroundProps) => (
  <div
    className={cn(
      'bg-color-cyan flex w-fit items-center justify-center rounded-md border-0  p-1.5',
      className
    )}
  >
    {children}
  </div>
);
