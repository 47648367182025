import { PaginationWrapper, PaginationWrapperProps } from './PaginationWrapper';
import { PaginationArrow, ArrowType } from './PaginationArrow';
import { PaginationLink } from './PaginationLink';
import { PaginationContent } from './PaginationContent';

export type PaginationProps = PaginationWrapperProps & {
  currentPage: number;
  totalPages: number;
  nextPrevButtons?: boolean;
  firstLastButtons?: boolean;
  displayPages?: boolean;
  pageCount?: number;
  onPageChange: (page: number) => void;
};

export const Pagination = ({
  currentPage = 1,
  totalPages = 1,
  nextPrevButtons = false,
  firstLastButtons = false,
  displayPages = true,
  onPageChange,
  ...props
}: PaginationProps) => {
  // TODO: @Alex: add ellipsis logic to Pagination with pages displayed
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleForwardClick = () => {
    if (currentPage < totalPages) {
      onPageChange(totalPages);
    }
  };

  const handleBackwardClick = () => {
    if (currentPage > 1) {
      onPageChange(1);
    }
  };

  return (
    <PaginationWrapper {...props}>
      <PaginationContent>
        {firstLastButtons && (
          <PaginationArrow
            arrowType={ArrowType.Backward}
            aria-disabled={currentPage === 1}
            onClick={handleBackwardClick}
          />
        )}
        {nextPrevButtons && (
          <PaginationArrow
            arrowType={ArrowType.Previous}
            aria-disabled={currentPage === 1}
            onClick={handlePreviousClick}
          />
        )}
        {displayPages &&
          new Array(totalPages).fill(0).map((_, index) => (
            <PaginationLink
              key={index}
              isActive={currentPage === index + 1}
              onClick={() => onPageChange(index + 1)}
            >
              {index + 1}
            </PaginationLink>
          ))}
        {nextPrevButtons && (
          <PaginationArrow
            arrowType={ArrowType.Next}
            aria-disabled={currentPage === totalPages}
            onClick={handleNextClick}
          />
        )}
        {firstLastButtons && (
          <PaginationArrow
            arrowType={ArrowType.Forward}
            aria-disabled={currentPage === totalPages}
            onClick={handleForwardClick}
          />
        )}
      </PaginationContent>
    </PaginationWrapper>
  );
};
