import { Inbox } from 'lucide-react';

import { cn } from '@spektr/client/utils';

export const EmptyList = () => (
  <div
    className={cn(
      'flex grow flex-col items-center justify-center gap-4 py-8',
      'h-full',
      'rounded-md border',
      'text-color-text-error-boundry/70'
    )}
  >
    <Inbox className="h-8 w-8" />
    <span className="font-light">This dataset is not used in any process.</span>
  </div>
);
