import { useMemo } from 'react';

import { type Field } from '@spektr/moonraker-types';
import { type SpektrField } from '@spektr/shared/types';

import { getCustomSpektrFields } from '../utils/getCustomSpektrFields';

export function useSpektrFields(
  fields: Record<string, Field>,
  spektrFields: SpektrField[]
) {
  const customSpektrFields = useMemo<string[]>(
    () => getCustomSpektrFields(Object.values(fields), spektrFields),
    [fields, spektrFields]
  );

  return customSpektrFields;
}
