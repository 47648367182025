import { Process } from '@spektr/shared/types';

import { getRootNode } from './getters';

type Descendants = {
  [key: string]: boolean;
};

/**
 * @description
 *    Find descendants of a process
 * @param {Process} parentProcess process to find descendants for
 * @param {Process[]} processList list of processes to search
 * @returns {Process[]} list of descendant ids
 */
export const getDescendantsForProcess = (
  parentProcess: Process,
  processList: Process[]
): Descendants => {
  let descendants: Descendants = {};

  processList.forEach((process) => {
    if (isChildOfProcess(process, parentProcess.id)) {
      descendants[process.id] = true;
      descendants = {
        ...descendants,
        ...getDescendantsForProcess(process, processList),
      };
    }
  });

  return descendants;
};

export const isChildOfProcess = (process: Process, parentProcessId: string) => {
  const rootNode = getRootNode(process);

  return (
    rootNode?.nodeType === 'filter' &&
    rootNode.source?.type === 'process' &&
    rootNode.source.processId === parentProcessId
  );
};
