import { z } from 'zod';

import { MoonrakerForm } from '@spektr/moonraker-types';

import { SpektrData } from '../spektrfield';

import { ActionForm } from '../formBuilder';
import { objectIdSchema, predicateSchema } from '../common';
import { channelSettingsSchema } from '../loopChannel';

import { edgeSchema, routerEdgeSchema } from './edgeSchema';
import { createNodeBaseSchema } from './createNodeBaseSchema';

import {
  AiActionConfigNodeSchema,
  CreateAiActionConfigNodeSchema,
  UpdateAiActionConfigNodeSchema,
} from './aiActionConfig';

export const serviceNodeTypes = [
  'openCorporatesMonitoring',
  'openCorporatesRisk',
  'complyAdvantageKyb',
  'complyAdvantageKyc',
  'kyckr',
  'bodacc',
  'veriff',
  'mitId',
] as const;

export const nodeTypes = [
  'router',
  'calculation',
  'slack',
  'filter',
  'outcome',
  'loopSource',
  'onboardingProcessSource',
  'actionForm',
  'action',
  'returningProcess',
  'monitoringDataset',
  'mitId',
  'manualReview',
  'aiActionConfig',
  ...serviceNodeTypes,
] as const;

export const endClientFacingNodeTypes = [
  'actionForm',
  'mitId',
  'veriff',
] as const;
export const EndClientFacingNodeType = z.enum(endClientFacingNodeTypes);
export type EndClientFacingNodeType = z.infer<typeof EndClientFacingNodeType>;

export const NodeServiceType = z.enum(serviceNodeTypes);
export type NodeServiceType = z.infer<typeof NodeServiceType>;

export const NodeType = z.enum(nodeTypes);
export type NodeType = z.infer<typeof NodeType>;

export const filterNodeSourceTypes = ['process', 'source'] as const;
export const filterNodeSourceTypeSchema = z.enum(filterNodeSourceTypes);

/**
 * ROULE GROUP SCHEMAS
 */

export const RuleGroup = z.object({
  id: z.string().optional(),
  title: z.string().min(1),
  score: z.coerce.number().optional(),
  rule: predicateSchema,
});
export type RuleGroup = z.infer<typeof RuleGroup>;

/**
 * SEGMENT SCHEMAS
 */

export const SegmentInputSchema = z.object({
  title: z.string().min(1),
  weight: z.coerce.number().min(0).max(100),
  groups: z.array(RuleGroup),
  id: objectIdSchema.optional(),
});
export type SegmentInputSchema = z.infer<typeof SegmentInputSchema>;

export const SegmentSchema = SegmentInputSchema.merge(
  z.object({
    id: objectIdSchema,
  })
);
export type SegmentSchema = z.infer<typeof SegmentSchema>;

/**
 * ROUTER NODE SCHEMAS
 */

export const RouterNodeInputSchema = z.strictObject({
  nodeType: z.literal('router'),
  title: z.string().min(1),
  groups: z.array(RuleGroup),
});

export type RouterNodeInput = z.infer<typeof RouterNodeInputSchema>;

export const updateRouterNodeSchema = RouterNodeInputSchema;

export const routerNodeSchema = RouterNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(routerEdgeSchema),
  })
);

export const createRouterNodeSchema =
  RouterNodeInputSchema.merge(createNodeBaseSchema);

/**
 * CALCULATION NODE SCHEMAS
 */

export const CalculationNodeInputSchema = z.object({
  nodeType: z.literal('calculation'),
  title: z.string().min(1),
  segments: z.array(SegmentInputSchema),
});
export type CalculationNodeInputSchema = z.infer<
  typeof CalculationNodeInputSchema
>;

export const updateCalculationNodeSchema = CalculationNodeInputSchema;

export const createCalculationNodeSchema =
  CalculationNodeInputSchema.merge(createNodeBaseSchema);

export const calculationNodeSchema = CalculationNodeInputSchema.merge(
  z.object({
    id: objectIdSchema,
    segments: z.array(SegmentSchema),
    adj: z.array(edgeSchema),
  })
);

/**
 * SLACK NODE SCHEMAS
 */
export const SlackNodeInputSchema = z.object({
  nodeType: z.literal('slack'),
  title: z.string(),
  entityName: z.string().optional(),
  channelId: z.string().optional(),
  botUserOauthToken: z.string().optional(),
  customMessage: z.string().optional(),
});
export type SlackNodeInputSchema = z.infer<typeof SlackNodeInputSchema>;

export const updateSlackNodeSchema = SlackNodeInputSchema;

export const createSlackNodeSchema =
  SlackNodeInputSchema.merge(createNodeBaseSchema);

export const slackNodeSchema = SlackNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

/**
 * ACTION NODE SCHEMAS
 */
export const ActionFieldSchema = z.strictObject({
  processId: objectIdSchema,
  nodeId: objectIdSchema,
  fields: z.record(z.string(), z.boolean()),
  name: z.string().optional(),
});
export type ActionFieldSchema = z.infer<typeof ActionFieldSchema>;

export const ActionNodeInputSchema = z.object({
  nodeType: z.literal('action'),
  title: z.string(),
  actions: z.array(ActionFieldSchema),
});
export type ActionNodeInputSchema = z.infer<typeof ActionNodeInputSchema>;
export type ActionNodeFields = ActionNodeInputSchema;

export const updateActionNodeSchema = ActionNodeInputSchema;

export const createActionNodeSchema =
  ActionNodeInputSchema.merge(createNodeBaseSchema);

export const actionNodeSchema = ActionNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

/**
 * MANUAL REVIEW NODE SCHEMAS
 */

export const TasksSchema = z.strictObject({
  id: z.string(),
  title: z.string(),
  description: z.string(),
});

export const ManualReviewNodeInputSchema = z.strictObject({
  nodeType: z.literal('manualReview'),
  title: z.string().min(1),
  tasks: z.array(TasksSchema).optional(),
});

export type ManualReviewNodeInputSchema = z.infer<
  typeof ManualReviewNodeInputSchema
>;

export const updateManualReviewNodeSchema = ManualReviewNodeInputSchema;

export const createManualReviewNodeSchema =
  ManualReviewNodeInputSchema.merge(createNodeBaseSchema);

export const manualReviewNodeSchema = ManualReviewNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

/**
 * FILTER NODE SCHEMAS
 */
export const filterNodeSourceProcessInputSchema = z.strictObject({
  type: z.literal('process'),
  processId: objectIdSchema,
  outcomeId: objectIdSchema,
  filter: RuleGroup.optional(),
});

export const filterNodeSourceDataInputSchema = z.strictObject({
  type: z.literal('source'),
  sourceId: objectIdSchema,
  filter: RuleGroup.optional(),
});

export const filterNodeSourceInputSchema = z.discriminatedUnion('type', [
  filterNodeSourceProcessInputSchema,
  filterNodeSourceDataInputSchema,
]);

export const filterNodeInputSchema = z.strictObject({
  nodeType: z.literal('filter'),
  source: filterNodeSourceInputSchema.optional(),
});

export const filterNodeSchema = filterNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

export const createFilterNodeSchema = filterNodeInputSchema;

export const updateFilterNodeSchema = filterNodeInputSchema.required();

/**
 * OUTCOME NODE SCHEMAS
 */
export const outcomeNodeInputSchema = z.strictObject({
  nodeType: z.literal('outcome'),
  route: z
    .strictObject({
      routerId: objectIdSchema,
      ruleGroupId: objectIdSchema,
    })
    .optional(),
  tagIds: z.array(z.string()).optional(),
});

export const outcomeNodeSchema = outcomeNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema).length(0),
    tagIds: z.array(z.string()).optional(),
  })
);

export const createOutcomeNodeSchema =
  outcomeNodeInputSchema.merge(createNodeBaseSchema);

export const updateOutcomeNodeSchema = outcomeNodeInputSchema;

/**
 * VENDOR NODE SCHEMAS
 */

export type VendorMappingAliases<T extends object> = Readonly<
  Required<{
    [key in keyof T]: string[];
  }>
>;

export const OpenCorporatesMappingStrict = z.object({
  companyName: z.string().optional(),
  companyNumber: z.string().optional(),
  countryCode: z.string(),
});
export type OpenCorporatesMappingStrict = z.infer<
  typeof OpenCorporatesMappingStrict
>;

export const OpenCorporatesMappingLoose = OpenCorporatesMappingStrict.partial();
export type OpenCorporatesMappingLoose = z.infer<
  typeof OpenCorporatesMappingLoose
>;

export const openCorporatesMappingAliases: VendorMappingAliases<OpenCorporatesMappingLoose> =
  {
    companyName: [
      'companyName',
      'company_name',
      'company name',
      'corporations.name',
    ],
    companyNumber: [
      'companyNumber',
      'company_number',
      'company number',
      'corporations.registrationNumber',
    ],
    countryCode: ['countryCode', 'country_code', 'country code'],
  };

export const OpenCorporatesMonitoringNodeInput = z.strictObject({
  nodeType: z.literal('openCorporatesMonitoring'),
  title: z.string().min(1),
  mapping: OpenCorporatesMappingLoose.optional(),
});

export const OpenCorporatesMonitoringNode =
  OpenCorporatesMonitoringNodeInput.merge(
    z.strictObject({
      id: objectIdSchema,
      adj: z.array(edgeSchema),
      fields: z.record(z.string(), z.boolean()),
    })
  );
export type OpenCorporatesMonitoringNode = z.infer<
  typeof OpenCorporatesMonitoringNode
>;

export const CreateOpenCorporatesMonitoringNode =
  OpenCorporatesMonitoringNodeInput.merge(createNodeBaseSchema);
export type CreateOpenCorporatesMonitoringNode = z.infer<
  typeof CreateOpenCorporatesMonitoringNode
>;

export const UpdateOpenCorporatesMonitoringNode =
  OpenCorporatesMonitoringNodeInput.merge(
    z.strictObject({
      fields: z.record(z.string(), z.boolean()),
      mapping: OpenCorporatesMappingLoose.optional(),
    })
  );

const OpenCorporatesRiskNodeInput = z.strictObject({
  nodeType: z.literal('openCorporatesRisk'),
  title: z.string(),
  mapping: OpenCorporatesMappingLoose.optional(),
});

export const CreateOpenCorporatesRiskNode =
  OpenCorporatesRiskNodeInput.merge(createNodeBaseSchema);
export type CreateOpenCorporatesRiskNode = z.infer<
  typeof CreateOpenCorporatesRiskNode
>;

export const UpdateOpenCorporatesRiskNode = OpenCorporatesRiskNodeInput.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    mapping: OpenCorporatesMappingLoose.optional(),
  })
);

export const OpenCorporatesRiskNode = OpenCorporatesRiskNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type OpenCorporatesRiskNode = z.infer<typeof OpenCorporatesRiskNode>;

export const ComplyAdvantageFuziness = z.number().min(0).max(1);

export const ComplyAdvantageKybMappingStrict = z.object({
  companyName: z.string(),
  companyNumber: z.string().optional(),
  countryCode: z.string().optional(),
});
export type ComplyAdvantageKybMappingStrict = z.infer<
  typeof ComplyAdvantageKybMappingStrict
>;

export const ComplyAdvantageKybMappingLoose =
  ComplyAdvantageKybMappingStrict.partial();
export type ComplyAdvantageKybMappingLoose = z.infer<
  typeof ComplyAdvantageKybMappingLoose
>;

export const complyAdvantageKybMappingAliases: VendorMappingAliases<ComplyAdvantageKybMappingLoose> =
  {
    companyName: [
      'companyName',
      'company_name',
      'company name',
      'corporations.name',
    ],
    companyNumber: [
      'companyNumber',
      'company_number',
      'company number',
      'corporations.registrationNumber',
    ],
    countryCode: ['countryCode', 'country_code', 'country code'],
  };

export const ComplyAdvantageKycMappingStrict = z
  .object({
    firstName: z.string().optional(),
    middleName: z.string().optional(),
    lastName: z.string().optional(),
    fullName: z.string().optional(),
    dateOfBirth: z.string().optional(),
  })
  .refine(
    ({ firstName, lastName, fullName }) =>
      (firstName !== undefined && lastName !== undefined) ||
      fullName !== undefined,
    {
      message:
        'Properties firstName and lastName or property fullName must be defined',
    }
  );

export type ComplyAdvantageKycMappingStrict = z.infer<
  typeof ComplyAdvantageKycMappingStrict
>;

export const ComplyAdvantageKycMappingLoose = ComplyAdvantageKycMappingStrict;
export type ComplyAdvantageKycMappingLoose = z.infer<
  typeof ComplyAdvantageKycMappingLoose
>;

export const complyAdvantageKycMappingAliases: VendorMappingAliases<ComplyAdvantageKycMappingLoose> =
  {
    firstName: ['firstName', 'first_name', 'first name'],
    middleName: ['middleName', 'middle_name', 'middle name'],
    lastName: ['lastName', 'last_name', 'last name'],
    dateOfBirth: ['dateOfBirth', 'date_of_birth', 'date of birth'],
    fullName: ['fullName', 'full_name', 'full name', 'individuals.name'],
  };

export const KyckrMappingStrict = z.object({
  companyName: z.string().optional(),
  companyNumber: z.string().min(1),
  countryCode: z.string().min(2),
  maxCreditCost: z.string().optional(),
  uboThreshold: z.string().optional(),
  maxLayers: z.string().optional(),
});
export type KyckrMappingStrict = z.infer<typeof KyckrMappingStrict>;

export const KyckrMappingLoose = KyckrMappingStrict.partial();
export type KyckrMappingLoose = z.infer<typeof KyckrMappingLoose>;

export const KyckrMappingAliases: VendorMappingAliases<KyckrMappingLoose> = {
  companyName: [
    'companyName',
    'company_name',
    'company name',
    'corporations.name',
  ],
  companyNumber: [
    'companyNumber',
    'company_number',
    'company number',
    'corporations.registrationNumber',
  ],
  countryCode: ['countryCode', 'country_code', 'country code'],
  maxCreditCost: [],
  maxLayers: [],
  uboThreshold: [],
};

export const VeriffMappingStrict = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
});
export type VeriffMappingStrict = z.infer<typeof VeriffMappingStrict>;

export const VeriffMappingLoose = VeriffMappingStrict.partial();
export type VeriffMappingLoose = z.infer<typeof VeriffMappingLoose>;

export const veriffMappingAliases: VendorMappingAliases<VeriffMappingLoose> = {
  firstName: ['firstName', 'first_name', 'first name'],
  lastName: ['lastName', 'last_name', 'last name'],
};

const returningProcessMapping = z.strictObject({
  processId: z.string().optional(),
});
export type ReturningProcessMapping = z.infer<typeof returningProcessMapping>;

const datasetMonitoringMapping = z.strictObject({});
export type DatasetMonitoringMapping = z.infer<typeof datasetMonitoringMapping>;

export const ComplyAdvantageKybNodeInput = z.strictObject({
  nodeType: z.literal('complyAdvantageKyb'),
  title: z.string().min(1),
  fuzziness: ComplyAdvantageFuziness.default(0.7),
  mapping: ComplyAdvantageKybMappingLoose.optional(),
});
export type ComplyAdvantageKybNodeInput = z.infer<
  typeof ComplyAdvantageKybNodeInput
>;

export const ComplyAdvantageKybNode = ComplyAdvantageKybNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type ComplyAdvantageKybNode = z.infer<typeof ComplyAdvantageKybNode>;

export const CreateComplyAdvantageKybNode =
  ComplyAdvantageKybNodeInput.merge(createNodeBaseSchema);
export type CreateComplyAdvantageKybNode = z.infer<
  typeof CreateComplyAdvantageKybNode
>;

export const UpdateComplyAdvantageKybNode = ComplyAdvantageKybNodeInput.merge(
  z.strictObject({
    fuzziness: ComplyAdvantageFuziness,
    fields: z.record(z.string(), z.boolean()),
    mapping: ComplyAdvantageKybMappingLoose.optional(),
  })
);
export type UpdateComplyAdvantageKybNode = z.infer<
  typeof UpdateComplyAdvantageKybNode
>;

export const ComplyAdvantageKycNodeInput = z.strictObject({
  nodeType: z.literal('complyAdvantageKyc'),
  title: z.string().min(1),
  fuzziness: z.number().min(0).max(1).default(0.7),
  mapping: ComplyAdvantageKycMappingLoose.optional(),
});
export type ComplyAdvantageKycNodeInput = z.infer<
  typeof ComplyAdvantageKycNodeInput
>;

export const ComplyAdvantageKycNode = ComplyAdvantageKycNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type ComplyAdvantageKycNode = z.infer<typeof ComplyAdvantageKycNode>;

export const CreateComplyAdvantageKycNode =
  ComplyAdvantageKycNodeInput.merge(createNodeBaseSchema);
export type CreateComplyAdvantageKycNode = z.infer<
  typeof CreateComplyAdvantageKycNode
>;

export const UpdateComplyAdvantageKycNode = ComplyAdvantageKycNodeInput.merge(
  z.strictObject({
    fuzziness: ComplyAdvantageFuziness,
    fields: z.record(z.string(), z.boolean()),
    mapping: ComplyAdvantageKycMappingLoose.optional(),
  })
);
export type UpdateComplyAdvantageKycNode = z.infer<
  typeof UpdateComplyAdvantageKycNode
>;

export const KyckrNodeInput = z.strictObject({
  nodeType: z.literal('kyckr'),
  title: z.string().min(1),
  mapping: KyckrMappingLoose.optional(),
});

export const KyckrNode = KyckrNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type KyckrNode = z.infer<typeof KyckrNode>;

export const CreateKyckrNode = KyckrNodeInput.merge(createNodeBaseSchema);
export type CreateKyckrNode = z.infer<typeof CreateKyckrNode>;

export const UpdateKyckrNode = KyckrNodeInput.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    mapping: KyckrMappingLoose.optional(),
  })
);

export const VeriffNodeInput = z.strictObject({
  nodeType: z.literal('veriff'),
  title: z.string().min(1),
  mapping: VeriffMappingLoose.optional(),
});
export type VeriffNodeInput = z.infer<typeof VeriffNodeInput>;

export const VeriffNode = VeriffNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type VeriffNode = z.infer<typeof VeriffNode>;

export const CreateVeriffNode = VeriffNodeInput.merge(createNodeBaseSchema);
export type CreateVeriffNode = z.infer<typeof CreateVeriffNode>;

export const UpdateVeriffNode = VeriffNodeInput.merge(
  z.strictObject({
    mapping: VeriffMappingLoose.optional(),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type UpdateVeriffNode = z.infer<typeof UpdateVeriffNode>;

export const returningProcessNodeInputSchema = z.strictObject({
  nodeType: z.literal('returningProcess'),
  title: z.string().min(1),
  processId: objectIdSchema.optional(),
});
export type ReturningProcessNodeInputSchema = z.infer<
  typeof returningProcessNodeInputSchema
>;

export const returningProcessNodeSchema = returningProcessNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    processId: objectIdSchema.optional(),
    fields: z.record(z.string(), z.boolean()),
  })
);

export const createReturningProcessNodeSchema =
  returningProcessNodeInputSchema.merge(createNodeBaseSchema);

export const updateReturningProcessNodeSchema = returningProcessNodeInputSchema;

export const monitoringDatasetNodeInputSchema = z.strictObject({
  nodeType: z.literal('monitoringDataset'),
  title: z.string().min(1),
});
export type MonitoringDatasetNodeInputSchema = z.infer<
  typeof monitoringDatasetNodeInputSchema
>;

export const monitoringDatasetNodeSchema =
  monitoringDatasetNodeInputSchema.merge(
    z.strictObject({
      id: objectIdSchema,
      adj: z.array(edgeSchema),
      fields: z.record(z.string(), z.boolean()),
    })
  );

export type MonitoringDatasetNode = z.infer<typeof monitoringDatasetNodeSchema>;

export const createMonitoringDatasetNodeSchema =
  monitoringDatasetNodeInputSchema.merge(createNodeBaseSchema);

export const updateMonitoringDatasetNodeSchema =
  monitoringDatasetNodeInputSchema;

export const BodaccMappingStrict = z.object({
  companyName: z.string().optional(),
  siretNumber: z.string().min(1),
});
export type BodaccMappingStrict = z.infer<typeof BodaccMappingStrict>;

export const BodaccMappingLoose = BodaccMappingStrict.partial();
export type BodaccMappingLoose = z.infer<typeof BodaccMappingLoose>;

export const bodaccMappingAliases: VendorMappingAliases<BodaccMappingLoose> = {
  companyName: [
    'companyName',
    'company_name',
    'company name',
    'corporations.name',
  ],
  siretNumber: [
    'siretNumber',
    'siret_number',
    'siret number',
    'siret',
    'sirenNumber',
    'siren_number',
    'siren number',
    'siren',
    'corporations.registrationNumber',
  ],
};

const BodaccNodeInput = z.strictObject({
  nodeType: z.literal('bodacc'),
  title: z.string().min(1),
  fuzziness: z.number().min(0).max(1).default(0.7),
  mapping: BodaccMappingLoose.optional(),
});

export const BodaccNode = BodaccNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type BodaccNode = z.infer<typeof BodaccNode>;

export const CreateBodaccNode = BodaccNodeInput.merge(createNodeBaseSchema);
export type CreateBodaccNode = z.infer<typeof CreateBodaccNode>;

export const UpdateBodaccNode = BodaccNodeInput.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    mapping: BodaccMappingLoose.optional(),
  })
);
export type UpdateBodaccNode = z.infer<typeof UpdateBodaccNode>;

/**
 * PROCESS LINK NODE SCHEMAS
 */

export const processLinkSourceSchema = z.strictObject({
  processId: objectIdSchema,
  outcomeId: objectIdSchema,
});

export const processLinkSchema = z.strictObject({
  source: processLinkSourceSchema,
  processes: z.array(objectIdSchema),
});

const processSourceServiceSchema = z.strictObject({
  field: z.string(),
  nodeType: NodeServiceType,
});

export const processSourceSchema = processLinkSourceSchema.merge(
  z.strictObject({
    service: processSourceServiceSchema.optional(),
  })
);

/**
 * LOOP SOURCE NODE SCHEMAS
 */

const loopSourceNodeInputSchema = z.strictObject({
  nodeType: z.literal('loopSource'),
  sources: z.array(processSourceSchema),
  channelSettings: z.array(channelSettingsSchema).optional(),
});

export const createLoopSourceNodeSchema =
  loopSourceNodeInputSchema.merge(createNodeBaseSchema);

export const updateLoopSourceNodeSchema = loopSourceNodeInputSchema;

export const loopSourceExtendedSchema = processLinkSourceSchema.merge(
  z.strictObject({
    service: processSourceServiceSchema
      .merge(
        z.strictObject({
          originProcessId: z.string().optional(),
        })
      )
      .optional(),
  })
);

export const loopSourceNodeSchema = z.strictObject({
  id: objectIdSchema,
  nodeType: z.literal('loopSource'),
  sources: z.array(loopSourceExtendedSchema),
  adj: z.array(edgeSchema),
  channelSettings: z.array(channelSettingsSchema).optional(),
});

/**
 * ONBOARDING PROCESS SOURCE NODE SCHEMAS
 */

const onboardingProcessSourceNodeInputSchema = z.strictObject({
  nodeType: z.literal('onboardingProcessSource'),
  sources: z.array(processSourceSchema).length(1),
  channelSettings: z.array(channelSettingsSchema).optional(),
});

export const createOnboardingProcessSourceNodeSchema =
  onboardingProcessSourceNodeInputSchema.merge(createNodeBaseSchema);

export const updateOnboardingProcessSourceNodeSchema =
  onboardingProcessSourceNodeInputSchema;

export const onboardingProcessSourceExtendedSchema =
  processLinkSourceSchema.merge(
    z.strictObject({
      service: processSourceServiceSchema
        .merge(
          z.strictObject({
            originProcessId: z.string().optional(),
          })
        )
        .optional(),
    })
  );

export const onboardingProcessSourceNodeSchema = z.strictObject({
  id: objectIdSchema,
  nodeType: z.literal('onboardingProcessSource'),
  sources: z.array(onboardingProcessSourceExtendedSchema),
  adj: z.array(edgeSchema),
  channelSettings: z.array(channelSettingsSchema).optional(),
});

/**
 * MITID NODE SCHEMAs
 */

const mitIdMapping = z.object({});
export type MitIdMapping = z.infer<typeof mitIdMapping>;

const mitIdNodeInputSchema = z.strictObject({
  nodeType: z.literal('mitId'),
  title: z.string().min(1),
  mapping: mitIdMapping.optional(),
  configuration: z.record(z.string(), z.string()).optional(),
});

export const mitIdNodeSchema = mitIdNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);

export const createMitIdNodeSchema =
  mitIdNodeInputSchema.merge(createNodeBaseSchema);

export const updateMitIdNodeSchema = mitIdNodeInputSchema.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    configuration: z.record(z.string(), z.string()).optional(),
  })
);

export type MitIdNode = z.infer<typeof mitIdNodeSchema>;

/**
 * ACTION FORM NODE SCHEMAS
 */

export const ActionTicketPresignedPostSchema = z.strictObject({
  url: z.string(),
  fields: z.record(z.string(), z.string()),
  key: z.string(),
  filename: z.string(),
});
export type ActionTicketPresignedPostSchema = z.infer<
  typeof ActionTicketPresignedPostSchema
>;

export const ActionFormSubmissionSchema = z.record(
  z.string(),
  z.union([
    // TODO: replace these with SpektrFieldTypedValue (separate PR?). This would allow us to fix the error in ActionTicketConsumerService#redeemTokenForSubmission
    z.string(),
    z.number(),
    z.boolean(),
    z.record(z.string(), z.string()),
    ActionTicketPresignedPostSchema,
  ])
);
export type ActionFormSubmissionSchema = z.infer<
  typeof ActionFormSubmissionSchema
>;

export const actionFormNodeInputSchema = z.object({
  nodeType: z.literal('actionForm'),
  title: z.string().default(''),
  form: ActionForm,
  moonrakerForm: MoonrakerForm.optional(),
});

export const CreateActionFormNode =
  actionFormNodeInputSchema.merge(createNodeBaseSchema);
export type CreateActionFormNode = z.infer<typeof CreateActionFormNode>;

export const updateActionFormNodeSchema = actionFormNodeInputSchema;

export const actionFormNodeSchema = CreateActionFormNode.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

export const processNodeSchema = z.discriminatedUnion('nodeType', [
  calculationNodeSchema,
  slackNodeSchema,
  routerNodeSchema,
  filterNodeSchema,
  outcomeNodeSchema,
  OpenCorporatesRiskNode,
  ComplyAdvantageKybNode,
  ComplyAdvantageKycNode,
  KyckrNode,
  VeriffNode,
  monitoringDatasetNodeSchema,
  returningProcessNodeSchema,
  loopSourceNodeSchema,
  onboardingProcessSourceNodeSchema,
  OpenCorporatesMonitoringNode,
  actionFormNodeSchema,
  BodaccNode,
  actionNodeSchema,
  mitIdNodeSchema,
  manualReviewNodeSchema,
  AiActionConfigNodeSchema,
]);

export const createNodeSchema = z.discriminatedUnion('nodeType', [
  createCalculationNodeSchema,
  createSlackNodeSchema,
  createRouterNodeSchema,
  createFilterNodeSchema,
  createOutcomeNodeSchema,
  CreateOpenCorporatesRiskNode,
  CreateComplyAdvantageKybNode,
  CreateComplyAdvantageKycNode,
  CreateKyckrNode,
  CreateVeriffNode,
  createMonitoringDatasetNodeSchema,
  createReturningProcessNodeSchema,
  CreateBodaccNode,
  createLoopSourceNodeSchema,
  createOnboardingProcessSourceNodeSchema,
  CreateOpenCorporatesMonitoringNode,
  CreateActionFormNode,
  createActionNodeSchema,
  createMitIdNodeSchema,
  createManualReviewNodeSchema,
  CreateAiActionConfigNodeSchema,
]);

export const updateNodeSchema = z.discriminatedUnion('nodeType', [
  updateCalculationNodeSchema,
  updateSlackNodeSchema,
  updateRouterNodeSchema,
  updateFilterNodeSchema,
  UpdateOpenCorporatesRiskNode,
  UpdateComplyAdvantageKybNode,
  UpdateComplyAdvantageKycNode,
  UpdateKyckrNode,
  UpdateVeriffNode,
  updateMonitoringDatasetNodeSchema,
  updateReturningProcessNodeSchema,
  UpdateBodaccNode,
  updateOutcomeNodeSchema,
  updateLoopSourceNodeSchema,
  updateOnboardingProcessSourceNodeSchema,
  UpdateOpenCorporatesMonitoringNode,
  updateActionFormNodeSchema,
  updateActionNodeSchema,
  updateMitIdNodeSchema,
  updateManualReviewNodeSchema,
  UpdateAiActionConfigNodeSchema,
]);

/**
 * NodeOutput Schema
 */

export const NodeOutput = z.object({
  nodeId: objectIdSchema,
  nodeType: NodeType,
  processId: objectIdSchema,
  createdAt: z.number(),
  updatedAt: z.number(), // TODO: delete, path is immutable append-only so no updates ever occur
  data: SpektrData.optional(),
  nextNodeId: objectIdSchema.optional(), // for routing
  shouldTerminate: z.boolean().optional(), // for filtering // TODO: Replace the use of 'shouldTerminate' with 'nextNodeId: null'?
  ticketId: objectIdSchema.optional(), // for actionForm // TODO: combine/replace with token?
  oldValues: SpektrData.optional(), // for actionForm
  vendorSearchId: objectIdSchema.optional(), // for async vendor integrations
  token: z.string().optional(), // for actionForm
  mitIdCode: z.string().optional(), // for mitId
  mitIdNonce: z.string().optional(), // for mitId
  queryParameters: z.record(z.string(), z.string()).optional(), // for mitId
  redirectUrl: z.string().optional(), // for veriff
  vendorError: z.boolean().optional(), // for veriff & mitId when they return an error on an API request we make
  shouldNotifyEndClient: z.boolean().optional(), // for any runner that has to send and email/sms/api notification to the end client
});
export type NodeOutput = z.infer<typeof NodeOutput>;

export type ConfigurableNode = MitIdNode;
