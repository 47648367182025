import { Link } from 'react-router-dom';
import { Zap } from 'lucide-react';

import { cn } from '@spektr/client/utils';
import {
  getColorBasedOnProcessType,
  processBuilderUrl,
} from '@spektr/shared/utils';

import { iconBoxVariants } from '@spektr/client/components';

import { ProcessType } from '@spektr/shared/validators';

export type ProcessListItemProps = {
  id: string;
  name: string;
  type: ProcessType;
};

export const ProcessListItem = ({ id, name, type }: ProcessListItemProps) => {
  return (
    <Link
      to={processBuilderUrl(id)}
      key={id}
      className={cn(
        'flex items-center gap-3 p-3',
        'border-b',
        'cursor-pointer transition-colors',
        'hover:bg-color-bg-accent/20',
        'first:rounded-t-md',
        'last:rounded-b-md last:border-b-0'
      )}
    >
      <Zap
        className={cn(
          'h-8 w-8',
          iconBoxVariants({
            variant: getColorBasedOnProcessType(type),
          })
        )}
      />
      <div className="flex flex-col">
        <span className="text-color-text-primary text-sm">{name}</span>
        <span className="text-color-text-subtext text-xs capitalize">
          {type}
        </span>
      </div>
    </Link>
  );
};
