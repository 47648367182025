import { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';

import { TriggersApiClient } from '@spektr/client/services';

import {
  TriggerCreateInputSchema,
  TriggerFrequency,
} from '@spektr/shared/types';

export function useTriggersOperations() {
  const createTriggerMutation = useMutation({
    mutationFn: (item: TriggerCreateInputSchema) =>
      TriggersApiClient.getClient().createTrigger(item),
  });
  const updateTriggerMutation = useMutation({
    mutationFn: (item: {
      triggerId: string;
      frequency: TriggerFrequency;
      fields?: string[];
    }) =>
      TriggersApiClient.getClient().updateTriggerFrequency(
        {
          frequency: item.frequency,
          fields: item?.fields,
        },
        {
          params: {
            triggerId: item.triggerId,
          },
        }
      ),
  });
  const deleteTriggerMutation = useMutation({
    mutationFn: (triggerId: string) =>
      TriggersApiClient.getClient().deleteTriggerById(undefined, {
        params: { triggerId },
      }),
  });

  const api = useMemo(
    () => ({
      createTrigger: createTriggerMutation.mutateAsync,
      updateTrigger: updateTriggerMutation.mutateAsync,
      deleteTrigger: deleteTriggerMutation.mutateAsync,
    }),
    [
      createTriggerMutation.mutateAsync,
      deleteTriggerMutation.mutateAsync,
      updateTriggerMutation.mutateAsync,
    ]
  );

  return api;
}
