import {
  ReactNode,
  useState,
  useMemo,
  useContext,
  createContext,
  useCallback,
} from 'react';
import { Optional } from 'utility-types';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  FilterNodeProcessSourceInputSchema,
  FilterNodeSourceDataInputSchema,
  FilterNodeSourceInputSchema,
  Process,
} from '@spektr/shared/types';
import { ProcessTrigger } from '@spektr/shared/validators';

import { getTriggersForProcessQuery } from '@spektr/client/services';

type SourceProviderApi = {
  isFormDirty: boolean;
  processType: Process['type'];
  processId: Process['id'];
  getSelectedMethod: () => 'source' | 'process';
  setSelectedMethod: (method: 'source' | 'process') => void;
  getFilterSource: () => FilterNodeSourceDataInputSchema | undefined;
  setFilterSource: (sourceId: string) => void;
  updateFilterRuleGroup: (
    group: FilterNodeSourceDataInputSchema['filter']
  ) => void;
  updateProcessFilterRuleGroup: (
    ruleGroup: FilterNodeProcessSourceInputSchema['filter']
  ) => void;
  getFilterProcess: () => OptionalProcessSource | undefined;
  setProcessSource: (processId: string, outcomeId?: string) => void;
  trigger: ProcessTrigger | undefined;
  initialTrigger: ProcessTrigger | undefined;
  setTrigger: (trigger?: Partial<ProcessTrigger>) => void;
  removeTrigger: () => void;
};

const SourceProviderContext = createContext<SourceProviderApi>({
  isFormDirty: false,
  processType: 'risk',
  processId: '',
  getSelectedMethod: () => 'source',
  setSelectedMethod: () => null,
  getFilterSource: () => undefined,
  setFilterSource: () => null,
  updateFilterRuleGroup: () => null,
  updateProcessFilterRuleGroup: () => null,
  getFilterProcess: () => undefined,
  setProcessSource: () => null,
  trigger: undefined,
  initialTrigger: undefined,
  setTrigger: () => null,
  removeTrigger: () => null,
});

export function useSourceProvider() {
  return useContext(SourceProviderContext);
}

export type SourceProviderProps = {
  children: ReactNode | ReactNode[];
  initialSource?: FilterNodeSourceInputSchema;
  process: Process;
};

type OptionalProcessSource = Optional<
  FilterNodeProcessSourceInputSchema,
  'outcomeId'
>;

export const SourceProvider = ({
  children,
  initialSource,
  process,
}: SourceProviderProps) => {
  const { data: triggers } = useSuspenseQuery(
    getTriggersForProcessQuery(process.id)
  );
  const [selectedMethod, setSelectedMethod] = useState(
    initialSource?.type ?? 'source'
  );
  const [filterSource, setFilterSource] = useState(
    initialSource?.type === 'source' ? initialSource : undefined
  );
  const [processSource, setProcessSource] = useState<
    OptionalProcessSource | undefined
  >(initialSource?.type === 'process' ? initialSource : undefined);
  const [trigger, setTrigger] = useState<ProcessTrigger | undefined>(
    triggers[0]
  );
  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleChangeMethod = (method: 'source' | 'process') => {
    setSelectedMethod(method);
  };

  const handleSetFilterSource = (sourceId: string) => {
    setFilterSource({
      type: 'source',
      sourceId,
      filter: undefined,
    });

    setIsFormDirty(true);
  };

  const handleUpdateRuleGroup = useCallback(
    (rulegroup: FilterNodeSourceDataInputSchema['filter']) => {
      setFilterSource({
        type: 'source',
        sourceId: filterSource!.sourceId,
        filter: rulegroup,
      });
      setIsFormDirty(true);
    },
    [filterSource]
  );

  const handleUpdateProcessFilterRule = useCallback(
    (ruleGroup: FilterNodeProcessSourceInputSchema['filter']) => {
      setProcessSource({
        type: 'process',
        processId: processSource!.processId,
        outcomeId: processSource?.outcomeId,
        filter: ruleGroup,
      });
      setIsFormDirty(true);
    },
    [processSource]
  );

  const handleSetProcessSource = (processId: string, outcomeId?: string) => {
    setProcessSource({
      type: 'process',
      processId,
      outcomeId,
    });
    setIsFormDirty(true);
  };

  const getFilterSource = useCallback(() => {
    if (filterSource) {
      return filterSource;
    }

    if (initialSource?.type === 'source') {
      return initialSource;
    }

    return undefined;
  }, [filterSource, initialSource]);

  const getFilterProcess = useCallback(() => {
    if (processSource) {
      return processSource;
    }

    if (initialSource?.type === 'process') {
      return initialSource;
    }

    return undefined;
  }, [initialSource, processSource]);

  const handleSetTrigger = (trigger?: Partial<ProcessTrigger>) => {
    setTrigger((prev) => ({ ...prev, ...trigger }) as ProcessTrigger);
    setIsFormDirty(true);
  };

  const removeTrigger = useCallback(async () => {
    setTrigger(undefined);

    setIsFormDirty(true);
  }, [setTrigger]);

  const api = useMemo(
    () => ({
      isFormDirty,
      processType: process.type,
      processId: process.id,
      getSelectedMethod: () => selectedMethod,
      setSelectedMethod: handleChangeMethod,
      getFilterSource,
      updateFilterRuleGroup: handleUpdateRuleGroup,
      updateProcessFilterRuleGroup: handleUpdateProcessFilterRule,
      setFilterSource: handleSetFilterSource,
      handleUpdateRuleGroup,
      getFilterProcess,
      setProcessSource: handleSetProcessSource,
      trigger,
      initialTrigger: triggers[0],
      setTrigger: handleSetTrigger,
      removeTrigger,
    }),
    [
      isFormDirty,
      process,
      getFilterProcess,
      getFilterSource,
      handleUpdateRuleGroup,
      handleUpdateProcessFilterRule,
      selectedMethod,
      trigger,
      triggers,
      removeTrigger,
    ]
  );

  return (
    <SourceProviderContext.Provider value={api}>
      {children}
    </SourceProviderContext.Provider>
  );
};
