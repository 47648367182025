import _camelCase from 'lodash/camelCase';

import { assertUnreachable } from '@spektr/shared/utils';
import {
  BodaccPageHitDataDto,
  CaPageHitDataDto,
  HitWithVendorDetails,
  OcPageHitDataDto,
  VendorHitSchemaBaseWithId,
} from '@spektr/shared/validators';
import { BodaccAnnouncementSpektrField } from '@spektr/vendors';

export function computeHitDetails(hit: HitWithVendorDetails) {
  switch (hit.vendorDetails.service) {
    case 'openCorporatesRisk':
    case 'openCorporatesMonitoring':
      return computeOpenCorporatesDetails(hit);
    case 'complyAdvantageKyb':
    case 'complyAdvantageKyc':
      return computeComplyAdvantageDetails(hit as VendorHitSchemaBaseWithId);
    case 'bodacc':
      return computeBodaccDetails(hit);
    default:
      return [];
  }
}

// TODO: (@Robert) - Remove any when hit.data is typed. OcPageHitDataDto is not good
function computeOpenCorporatesDetails(hit: HitWithVendorDetails) {
  const anyData = hit.data?.ocPageHit || ({} as OcPageHitDataDto);

  const tables: Record<string, string>[] = [
    {
      incorporation_date: anyData?.incorporation_date ?? '',
      dissolution_date: anyData?.dissolution_date ?? '',
      company_type: anyData?.company_type ?? '',
      updated_at: anyData?.updated_at ?? '',
      'source.publisher': '',
      native_company_number: '',
    },
    {
      'registered_address.street_address':
        anyData?.registered_address?.street_address ?? '',
      'registered_address.locality': anyData.registered_address?.locality ?? '',
      'registered_address.region': anyData.registered_address?.region ?? '',
      'registered_address.postal_code':
        anyData?.registered_address?.postal_code ?? '',
      'registered_address.country': anyData?.registered_address?.country ?? '',
    },
  ];

  anyData?.previous_names.forEach((previousName: any) => {
    tables.push({
      'previous_names.company_name': previousName.company_name,
      'previous_names.start_date': previousName.start_date,
      'previous_names.end_date': previousName.end_date,
    });
  });

  anyData?.industry_codes.forEach((industryCode: any) => {
    tables.push({
      'industry_codes.code': industryCode.code,
      'industry_codes.description': industryCode.description,
      'industry_codes.code_scheme_name': industryCode.code_scheme_name,
    });
  });

  return tables;
}

function computeComplyAdvantageDetails(hit: VendorHitSchemaBaseWithId) {
  const hitData = hit.data?.caPageHit || ({} as CaPageHitDataDto);

  switch (hit.vendorDetails.field) {
    case 'has_adverse_media':
      return computeCaHasAdverseMedia(hitData?.adverse_media);
    case 'has_warnings':
      return computeCaHasWarnings(hitData?.warning);
    case 'is_PEP':
      return computeCaIsPep(hitData?.pep);
    case 'has_sanctions':
      return computeCaHasSanctions(hitData?.sanction);
    default:
      return [];
  }
}

function computeCaHasAdverseMedia(data: any) {
  const tables: Record<string, string>[] = [];

  if (data.aka) {
    tables.push({
      aka: data.aka,
    });
  }

  if (data.fields) {
    const table = data.fields.reduce(
      (acc: Record<string, string>, field: any) => ({
        ...acc,
        [`fields.${_camelCase(field.name)}`]: field.value,
      }),
      {}
    );

    if (table) {
      tables.push(table);
    }
  }

  data.media?.forEach((media: any) => {
    tables.push({
      'media.title': media.title,
      'media.url': media.url,
      'media.date': media.date,
      'media.snippet': media.snippet,
    });
  });

  if (data.source_notes) {
    try {
      Object.values(data.source_notes).forEach((sourceNote: any) => {
        tables.push({
          'source_notes.name': sourceNote.name,
          'source_notes.aml_types': sourceNote.aml_types,
          'source_notes.country_codes': sourceNote.country_codes,
        });
      });
    } catch (_err) {
      tables.push({
        'source_notes.name': data.source_notes.name,
        'source_notes.aml_types': data.source_notes.aml_types,
        'source_notes.country_codes': data.source_notes.country_codes,
      });
    }
  }

  if (data.match_types) {
    tables.push({
      match_type: data.match_types,
    });
  }

  return tables;
}

function computeCaHasWarnings(data: any) {
  const tables: Record<string, string>[] = [];

  if (data.aka) {
    tables.push({
      aka: data.aka,
    });
  }

  if (data.fields) {
    const table = data.fields.reduce(
      (acc: Record<string, string>, field: any) => ({
        ...acc,
        [`fields.${_camelCase(field.name)}`]: field.value,
      }),
      {}
    );

    if (table) {
      tables.push(table);
    }
  }

  if (data.sources) {
    tables.push({
      sources: data.sources,
    });
  }

  if (data.source_notes) {
    try {
      Object.values(data.source_notes).forEach((sourceNote: any) => {
        tables.push({
          'source_notes.name': sourceNote.name,
          'source_notes.url': sourceNote.url,
        });
      });
    } catch (_err) {
      tables.push({
        'source_notes.name': data.source_notes.name,
        'source_notes.url': data.source_notes.url,
      });
    }
  }

  if (data.match_types) {
    tables.push({
      match_type: data.match_types,
    });
  }

  return tables;
}

function computeCaIsPep(data: any) {
  const tables: Record<string, string>[] = [];

  if (data.aka) {
    tables.push({
      aka: data.aka,
    });
  }

  if (data.fields) {
    const table = data.fields.reduce(
      (acc: Record<string, string>, field: any) => ({
        ...acc,
        [`fields.${_camelCase(field.name)}`]: field.value,
      }),
      {}
    );

    if (table) {
      tables.push(table);
    }
  }

  data.associates?.forEach((associate: any) => {
    tables.push({
      'associates.name': associate.name,
      'associates.association': associate.association,
    });
  });

  if (data.source_notes) {
    try {
      Object.values(data.source_notes).forEach((sourceNote: any) => {
        tables.push({
          'source_notes.name': sourceNote.name,
          'source_notes.url': sourceNote.url,
          'source_notes.aml_types': sourceNote.aml_types,
          'source_notes.country_codes': sourceNote.country_codes,
        });
      });
    } catch (_err) {
      tables.push({
        'source_notes.name': data.source_notes.name,
        'source_notes.url': data.source_notes.url,
        'source_notes.aml_types': data.source_notes.aml_types,
        'source_notes.country_codes': data.source_notes.country_codes,
      });
    }
  }

  if (data.match_types) {
    tables.push({
      match_type: data.match_types,
    });
  }

  return tables;
}

function computeCaHasSanctions(data: any) {
  const tables: Record<string, string>[] = [];

  if (data.aka) {
    tables.push({
      aka: data.aka,
    });
  }

  if (data.fields) {
    const table = data.fields.reduce(
      (acc: Record<string, string>, field: any) => ({
        ...acc,
        [`fields.${_camelCase(field.name)}`]:
          field.name === 'Source' ? field.source : field.value,
      }),
      {}
    );

    if (table) {
      tables.push(table);
    }
  }

  data.associates?.forEach((associate: any) => {
    tables.push({
      'associates.name': associate.name,
      'associates.association': associate.association,
    });
  });

  if (data.source_notes) {
    try {
      Object.values(data.source_notes).forEach((sourceNote: any) => {
        tables.push({
          'source_notes.name': sourceNote.name,
          'source_notes.url': sourceNote.url,
          'source_notes.aml_types': sourceNote.aml_types,
          'source_notes.listing_started_utc': sourceNote.country_codes,
        });
      });
    } catch (_err) {
      tables.push({
        'source_notes.name': data.source_notes.name,
        'source_notes.url': data.source_notes.url,
        'source_notes.aml_types': data.source_notes.aml_types,
        'source_notes.listing_started_utc': data.source_notes.country_codes,
      });
    }
  }

  if (data.match_types) {
    tables.push({
      match_type: data.match_types,
    });
  }

  return tables;
}

function computeBodaccDetails(hit: HitWithVendorDetails) {
  const bodacData = hit.data?.bodaccPageHit || ({} as BodaccPageHitDataDto);
  const field = hit.vendorDetails.field as BodaccAnnouncementSpektrField;

  switch (field) {
    case 'is_sold':
      return computeBodaccIsSold(bodacData);
    case 'is_registered':
      return computeBodaccIsRegistered(bodacData);
    case 'has_branch':
      return computeBodaccHasBranch(bodacData);
    case 'has_changes':
      return computeBodaccHasChanges(bodacData);
    case 'is_deregistered':
      return computeBodaccDeregistered(bodacData);
    case 'in_collective_proceedings':
      return computeBodaccInCollectiveProceedings(bodacData);
    case 'in_conciliation_proceedings':
      return computeBodaccInConciliationProceedings(bodacData);
    default:
      assertUnreachable(field);
  }
}

function computeBaseBodaccDetails(data: BodaccPageHitDataDto) {
  return {
    departement_nom_officiel: data?.departement_nom_officiel ?? '',
    region_nom_officiel: data?.region_nom_officiel ?? '',
    tribunal: data?.tribunal ?? '',
    ville: data?.ville ?? '',
    cp: data?.cp ?? '',
  };
}

function computeBodaccIsSold(data: BodaccPageHitDataDto) {
  const tables: Record<string, string>[] = [computeBaseBodaccDetails(data)];

  if (data?.listepersonnes) {
    tables.push({
      'listepersonnes.nom': data.listepersonnes?.nom ?? '',
      'listepersonnes.prenom': data.listepersonnes?.prenom ?? '',
      'listepersonnes.numeroImmatriculation':
        data.listepersonnes.numeroImmatriculation ?? '',
    });
  }

  if (
    Array.isArray(data?.listeetablissements) &&
    data.listeetablissements.length > 0
  ) {
    data.listeetablissements.forEach((etablissement) => {
      tables.push({
        // TODO: (@Robert) - This is not present in the data
        'etablissement.qualiteEtablissement': '', // etablissement.qualiteEtablissement ?? '',
        'etablissement.activite': etablissement.activite ?? '',
        'etablissement.adresse': etablissement.adresse ?? '',
      });
    });
  }

  if (data?.acte) {
    tables.push({
      'acte.dateCommencementActivite':
        data.acte?.dateCommencementActivite ?? '',
      'acte.descriptif': data.acte?.descriptif ?? '',
    });
  }

  if (data?.listeprecedentproprietaire?.length > 0) {
    data.listeprecedentproprietaire.forEach((proprietaire) => {
      tables.push({
        'listeprecedentproprietaire.nom': proprietaire.nom ?? '',
        'listeprecedentproprietaire.typePersonne':
          proprietaire.typePersonne ?? '',
        'listeprecedentproprietaire.prenom': proprietaire.prenom ?? '',
      });
    });
  }

  return tables;
}

function computeBodaccIsRegistered(data: BodaccPageHitDataDto) {
  const tables: Record<string, string>[] = [computeBaseBodaccDetails(data)];

  if (data?.listepersonnes) {
    tables.push({
      'listepersonnes.nom': data.listepersonnes?.nom ?? '',
      'listepersonnes.prenom': data.listepersonnes?.prenom ?? '',
    });
  }

  if (
    Array.isArray(data?.listeetablissements) &&
    data.listeetablissements.length > 0
  ) {
    data.listeetablissements.forEach((listeetablissement) => {
      tables.push({
        // TODO: (@Robert) - This is not present in the data
        'etablissement.qualiteEtablissement': '', // etablissement.qualiteEtablissement ?? '',
        'listeetablissements.activite': listeetablissement.activite ?? '',
        'listeetablissements.adresse': listeetablissement.adresse ?? '',
      });
    });
  }

  if (data?.acte) {
    tables.push({
      'acte.dateCommencementActivite':
        data.acte?.dateCommencementActivite ?? '',
      'acte.descriptif': data.acte?.descriptif ?? '',
    });
  }

  return tables;
}

function computeBodaccHasBranch(data: BodaccPageHitDataDto) {
  const tables: Record<string, string>[] = [computeBaseBodaccDetails(data)];

  if (data?.listepersonnes) {
    tables.push({
      'listepersonnes.administration':
        data.listepersonnes?.administration ?? '',
      'listepersonnes.sigle': data.listepersonnes?.sigle ?? '',
      'listepersonnes.denomination': data.listepersonnes.denomination ?? '',
      'listepersonnes.formeJuridique': data.listepersonnes.formeJuridique ?? '',
      'listepersonnes.capital': data.listepersonnes.capital ?? '',
    });
  }

  if (
    Array.isArray(data?.listeetablissements) &&
    data.listeetablissements.length > 0
  ) {
    data.listeetablissements.forEach((listeetablissement) => {
      tables.push({
        'listeetablissements.activite': listeetablissement.activite ?? '',
        'listeetablissements.adresse': listeetablissement.adresse ?? '',
      });
    });
  }

  if (data?.acte) {
    tables.push({
      'acte.dateCommencementActivite':
        data.acte?.dateCommencementActivite ?? '',
      'acte.descriptif': data.acte?.descriptif ?? '',
      'acte.categorieCreation': data.acte?.categorieCreation ?? '',
    });
  }

  return tables;
}

function computeBodaccHasChanges(data: BodaccPageHitDataDto) {
  const tables: Record<string, string>[] = [computeBaseBodaccDetails(data)];

  if (data?.listepersonnes) {
    tables.push({
      'listepersonnes.numeroImmatriculation':
        data?.listepersonnes.numeroImmatriculation ?? '',
      'listepersonnes.formeJuridique':
        data?.listepersonnes.formeJuridique ?? '',
      'listepersonnes.denomination': data?.listepersonnes.denomination ?? '',
      'listepersonnes.adresseSiegeSocial':
        data?.listepersonnes.adresseSiegeSocial ?? '',
    });
  }

  return tables;
}

function computeBodaccDeregistered(data: BodaccPageHitDataDto) {
  const tables: Record<string, string>[] = [computeBaseBodaccDetails(data)];

  if (data?.listepersonnes) {
    tables.push({
      'listepersonnes.nom': data.listepersonnes?.nom ?? '',
      'listepersonnes.prenom': data.listepersonnes?.prenom ?? '',
      'listepersonnes.numeroImmatriculation':
        data.listepersonnes.numeroImmatriculation ?? '',
      // TODO: (@Robert) - This is not present in the data
      'listepersonnes.adressePP': '', // data.listepersonnes?.adressePP ?? '',
    });
  }

  if (data?.radiationaurcs) {
    tables.push({
      'radiationaurcs.dateCessation': data.radiationaurcs.dateCessation ?? '',
      'radiationaurcs.rediationPM': data.radiationaurcs.rediationPM ?? '',
    });
  }

  return tables;
}

function computeBodaccInCollectiveProceedings(data: BodaccPageHitDataDto) {
  const tables: Record<string, string>[] = [computeBaseBodaccDetails(data)];

  if (data?.listepersonnes) {
    tables.push({
      'listepersonnes.nom': data.listepersonnes?.nom ?? '',
      'listepersonnes.prenom': data.listepersonnes?.prenom ?? '',
      'listepersonnes.activite': data.listepersonnes?.activite ?? '',
      'listepersonnes.enseigne': data.listepersonnes?.enseigne ?? '',
    });
  }

  if (data?.jugement) {
    tables.push({
      'jugement.date': data.jugement.date ?? '',
      'jugement.complementJugement': data.jugement.complementJugement ?? '',
      'jugement.type': data.jugement.type ?? '',
      'jugement.famille': data.jugement.famille ?? '',
      'jugement.nature': data.jugement.nature ?? '',
    });
  }

  return tables;
}

function computeBodaccInConciliationProceedings(data: BodaccPageHitDataDto) {
  const tables: Record<string, string>[] = [computeBaseBodaccDetails(data)];

  if (data?.listepersonnes) {
    tables.push({
      'listepersonnes.activite': data.listepersonnes?.activite ?? '',
      'listepersonnes.denomination': data.listepersonnes?.denomination ?? '',
      'listepersonnes.formeJuridique':
        data.listepersonnes?.formeJuridique ?? '',
      'listepersonnes.adresseSiegeSocial':
        data.listepersonnes?.adresseSiegeSocial ?? '',
    });
  }

  if (data?.jugement) {
    tables.push({
      'jugement.date': data.jugement.date ?? '',
      'jugement.complementJugement': data.jugement.complementJugement ?? '',
    });
  }

  return tables;
}
