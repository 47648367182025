import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import { Button, Input, Label, Select } from '@spektr/client/components';
import { UsersApiClient } from '@spektr/client/services';
import {
  UserJobTitles,
  UserUpdateSchema,
  jobTitles,
} from '@spektr/shared/validators';

import { CHOOSE_DEMO_OPTION_URL } from '@spektr/shared/utils';

type FormValues = {
  firstName: string;
  lastName: string;
  jobTitle: string;
};

const jobFunctions = jobTitles.map((title: UserJobTitles) => ({
  label: title,
  value: title,
}));

type SetUpAccountFormProps = {
  updateUserName: (firstName: string, lastName: string) => Promise<void>;
};

export const SetUpAccountForm = ({ updateUserName }: SetUpAccountFormProps) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const {
    register,
    handleSubmit: handleFormSubmit,
    control,
  } = useForm<FormValues>();

  const setUpMutation = useMutation({
    mutationFn: async (data: UserUpdateSchema) => {
      const { firstName, lastName, jobTitle } = data;
      await UsersApiClient.getClient().updateUserOwnProfile({
        jobTitle,
        firstName,
        lastName,
      });

      await updateUserName(firstName!, lastName!);
    },
  });

  const handleSubmit = async (data: FormValues) => {
    setError(undefined);
    try {
      if (!data.firstName || !data.lastName) {
        throw new Error('First name and last name are required');
      }

      await setUpMutation.mutateAsync(data as UserUpdateSchema);
      navigate(CHOOSE_DEMO_OPTION_URL, {
        state: {
          show: true,
        },
      });
    } catch (error) {
      if (isAxiosError(error)) {
        setError(error.response?.data?.message ?? 'Something went wrong!');
      }
      throw error;
    }
  };

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <div>
            <Label className="text-xs" htmlFor="email">
              First name
            </Label>
            <Input
              id="firstName"
              type="text"
              placeholder="Input your first name"
              required
              {...register('firstName', { required: true })}
            />
          </div>
          <div>
            <Label className="text-xs" htmlFor="password">
              Last name
            </Label>
            <Input
              id="lastName"
              type="text"
              placeholder="Input your last name"
              required
              {...register('lastName', { required: true })}
            />
          </div>
        </div>
        <div>
          <Label className="text-xs" htmlFor="jobTitle">
            Job function/department
          </Label>
          <Controller
            name="jobTitle"
            render={({ field }) => (
              <Select
                required
                {...field}
                value={undefined}
                placeholder="Choose job function/department"
                options={jobFunctions}
                onValueChange={field.onChange}
              />
            )}
            control={control}
          />
        </div>

        {error && <div className="text-color-red text-sm">{error}</div>}
      </div>
      <Button color="white" type="submit" fullWidth className="mt-6">
        {setUpMutation.isPending ? 'Saving...' : 'Save account details'}
      </Button>
    </form>
  );
};
