export function formatBasicNumber(value: number) {
  return new Intl.NumberFormat().format(value);
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  let index = 0;

  while (bytes >= 1024 && index < sizes.length - 1) {
    bytes >>= 10; // bytes /= 1024
    index++;
  }

  return parseFloat(bytes.toFixed(decimals)) + ' ' + sizes[index];
}
