import { useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

export const useDebouncedSearch = (
  defaultValue = '',
  onSearch?: (searchValue: string) => void,
  debounceTime = 200
) => {
  const [searchValue, setSearchValue] = useState(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState(defaultValue);

  useDebounce(
    () => {
      setDebouncedValue(searchValue);
      onSearch?.(searchValue);
    },
    debounceTime,
    [searchValue]
  );

  return useMemo(
    () => ({
      searchValue,
      debouncedValue,
      setSearchValue,
    }),
    [searchValue, debouncedValue, setSearchValue]
  );
};
