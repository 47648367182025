import { useLocation, useNavigate } from 'react-router-dom';

import { createNodeFactory } from '@spektr/client/utils';
import {
  processBuilderUrl,
  processDetailsParamsSchema,
} from '@spektr/shared/utils';

import { useCreateNode, useParsedParams } from '@spektr/shared/hooks';
import { NodeType } from '@spektr/shared/validators';

export function useCreateNodeFactory() {
  const location = useLocation();
  const navigate = useNavigate();
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const createNode = useCreateNode(processId);

  const handleClick = (nodeType: NodeType) => {
    const bodyInput = createNodeFactory(nodeType, {
      predecessorId: location.state?.predecessorNode?.id,
      successorId: location.state?.successorNode?.id,
      edgeType: location.state?.edgeType,
    });

    createNode
      .mutateAsync(bodyInput)
      .then((response) => {
        navigate(processBuilderUrl(processId, response.id));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return handleClick;
}
