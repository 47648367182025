import createDomPurity from 'dompurify';
import escapeHtml from 'escape-html';

import {
  isSlateElement,
  isSlateText,
  type SlateDescendant,
} from '@spektr/shared/slate-types';

/**
 * @description
 *    Converts a Slate node to HTML by recursively going through the Slate node and converting it to HTML.
 *
 * @param node - Slate node
 * @returns HTML string
 */
// TODO: (@Alex) - Add support for mention and other custom elements
export function slateToHtml(node: SlateDescendant): string {
  const sanitizer = createDomPurity(window);

  if (isSlateText(node)) {
    let text = sanitizer.sanitize(node.text);

    if (node.bold) {
      text = `<strong>${text}</strong>`;
    }

    if (node.italic) {
      text = `<em>${text}</em>`;
    }

    if (node.underline) {
      text = `<u>${text}</u>`;
    }

    return text;
  }

  if (isSlateElement(node)) {
    const children = node.children.map(slateToHtml).join('');

    switch (node.type) {
      case 'paragraph':
        return `<p>${children}</p>`;

      case 'link':
        return `<a href="${escapeHtml(node.url)}">${children}</a>`;

      default:
        return children;
    }
  }

  return '';
}
