import { createContext, useContext } from 'react';

import { LiveProcessVersion } from '@spektr/shared/types';

export type LiveVersionsContextType = {
  liveVersions: LiveProcessVersion[];
};

const LiveVersionsContext = createContext({
  liveVersions: [],
} as LiveVersionsContextType);

export const useLiveVersionsContext = () => useContext(LiveVersionsContext);

type LiveVersionsProviderProps = {
  liveVersions: LiveProcessVersion[];
  children: React.ReactNode;
};

export const LiveVersionsProvider = ({
  liveVersions,
  children,
}: LiveVersionsProviderProps) => {
  return (
    <LiveVersionsContext.Provider value={{ liveVersions }}>
      {children}
    </LiveVersionsContext.Provider>
  );
};
