import { Skeleton } from '@spektr/client/components';

export const RiskMatrixListSkeleton = () => {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      <div className="flex h-[181px] flex-col overflow-hidden rounded-md border">
        <div className="flex h-24 flex-grow flex-col gap-3 p-3">
          <div className="flex gap-2">
            <Skeleton className="h-5 w-24" />
          </div>
          <Skeleton className="h-5 w-1/4" />
        </div>
        <div className="flex h-10 items-center px-2">
          <span>
            <Skeleton className="h-4 w-32" />
          </span>
        </div>
      </div>
      <div className="flex h-[181px] flex-col overflow-hidden rounded-md border">
        <div className="flex h-24 flex-grow flex-col gap-3 p-3">
          <div className="flex gap-2">
            <Skeleton className="h-5 w-24" />
          </div>
          <Skeleton className="h-5 w-1/4" />
        </div>
        <div className="flex h-10 items-center px-2">
          <span>
            <Skeleton className="h-4 w-32" />
          </span>
        </div>
      </div>
      <div className="flex h-[181px] flex-col overflow-hidden rounded-md border">
        <div className="flex h-24 flex-grow flex-col gap-3 p-3">
          <div className="flex gap-2">
            <Skeleton className="h-5 w-24" />
          </div>
          <Skeleton className="h-5 w-1/4" />
        </div>
        <div className="flex h-10 items-center px-2">
          <span>
            <Skeleton className="h-4 w-32" />
          </span>
        </div>
      </div>
      <div className="flex h-[181px] flex-col overflow-hidden rounded-md border">
        <div className="flex h-24 flex-grow flex-col gap-3 p-3">
          <div className="flex gap-2">
            <Skeleton className="h-5 w-24" />
          </div>
          <Skeleton className="h-5 w-1/4" />
        </div>
        <div className="flex h-10 items-center px-2">
          <span>
            <Skeleton className="h-4 w-32" />
          </span>
        </div>
      </div>
    </div>
  );
};
