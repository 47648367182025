import { NodeServiceType } from '@spektr/shared/validators';

import {
  BODACC_DETAILS,
  CA_COMPANY_DETAILS,
  CA_INDIVIDUAL_DETAILS,
  KYCKR_DETAILS,
  MITID_DETAILS,
  MONITORING_DATASET_DETAILS,
  OC_COMPANY_DETAILS,
  VERIFF_DETAILS,
} from '../constants/serviceSpektrFields';

export function getIdentifiers(
  nodeType: NodeServiceType | 'monitoringDataset'
) {
  if (nodeType === 'complyAdvantageKyb') {
    return CA_COMPANY_DETAILS;
  }

  if (nodeType === 'complyAdvantageKyc') {
    return CA_INDIVIDUAL_DETAILS;
  }

  if (
    nodeType === 'openCorporatesMonitoring' ||
    nodeType === 'openCorporatesRisk'
  ) {
    return OC_COMPANY_DETAILS;
  }

  if (nodeType === 'bodacc') {
    return BODACC_DETAILS;
  }

  if (nodeType === 'kyckr') {
    return KYCKR_DETAILS;
  }

  if (nodeType === 'veriff') {
    return VERIFF_DETAILS;
  }

  if (nodeType === 'mitId') {
    return MITID_DETAILS;
  }

  if (nodeType === 'monitoringDataset') {
    return MONITORING_DATASET_DETAILS;
  }

  throw new Error(`Node type ${nodeType} is not supported`);
}
