import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { StrictOmit } from '@spektr/shared/utils';
import { NodeType, SpektrFieldTypedKey } from '@spektr/shared/validators';

import { getRiskMatrixQuery } from '@spektr/client/services';

import {
  IncompleteRuleGroup,
  RiskMatrixSegment,
  ScoredRows,
  extractRowsByScore,
} from '../tree-helpers';

import { SegmentConfig, segmentConfigs } from './segment-config';

export type IncompleteMatrixSegmentContextType = {
  segment: RiskMatrixSegment | undefined;
  config: SegmentConfig;
  updateSegment: (
    changes: Partial<StrictOmit<RiskMatrixSegment, 'id' | 'clientSideOnlyId'>>
  ) => void;
  removeSegment: () => void;
  updateRuleGroup: (id: string, updates: Partial<IncompleteRuleGroup>) => void;
  scoredRows: ScoredRows;
  spektrFields: SpektrFieldTypedKey[];
};

export const useIncompleteMatrixSegment = () =>
  useContext(IncompleteMatrixSegmentContext);

const IncompleteMatrixSegmentContext = createContext(
  {} as IncompleteMatrixSegmentContextType
);

export type ProcessNodeMatrixSegmentProviderProps = {
  type: Extract<NodeType, 'calculation' | 'router' | 'filter'>;
  segment: RiskMatrixSegment;
  onChange: (segment: RiskMatrixSegment | undefined) => void;
  spektrFields: SpektrFieldTypedKey[];
};

export const MatrixSegmentProvider = ({
  segment,
  type,
  children,
  spektrFields,
  onChange,
}: ProcessNodeMatrixSegmentProviderProps & { children: ReactNode }) => {
  const config = useMemo(() => segmentConfigs[type], [type]);
  const { data: riskMatrix } = useSuspenseQuery(
    getRiskMatrixQuery(segment.riskMatrixId)
  );

  const scoredRows = useMemo(
    () => extractRowsByScore(riskMatrix),
    [riskMatrix]
  );

  const updateSegment = useCallback(
    (
      props: Partial<StrictOmit<RiskMatrixSegment, 'id' | 'clientSideOnlyId'>>
    ) => {
      onChange({ ...segment, ...props });
    },
    [segment, onChange]
  );

  const removeSegment = () => onChange(undefined);

  const updateRuleGroup = (
    id: string,
    updates: Partial<IncompleteRuleGroup>
  ) => {
    if (!segment) return;

    const groups = segment.groups.map((group) => {
      if (group.clientSideOnlyId === id) return { ...group, ...updates };
      else return group;
    });

    onChange({ ...segment, groups });
  };

  return (
    <IncompleteMatrixSegmentContext.Provider
      value={{
        segment,
        config,
        updateSegment,
        removeSegment,
        updateRuleGroup,
        scoredRows,
        spektrFields,
      }}
    >
      {children}
    </IncompleteMatrixSegmentContext.Provider>
  );
};
