import { type SlateDescendant } from '@spektr/shared/slate-types';

import { htmlToSlate } from './htmlToSlate';

export function deserializeSlate(html: string): SlateDescendant[] {
  if (html === '') {
    return [];
  }

  const document = new DOMParser().parseFromString(html, 'text/html');
  const slate = htmlToSlate(document.body);

  return Array.isArray(slate) ? slate : [slate];
}
