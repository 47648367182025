import {
  SpektrFieldType,
  SpektrFieldTypedKey,
} from '@spektr/shared/validators';

import { IncompletePredicate } from '../tree-helpers';

export function extractLeftOperandSpektrfieldsFromRule(
  rule: IncompletePredicate
): SpektrFieldTypedKey[] {
  if (typeof rule.left === 'string') {
    return [{ key: rule.left, type: rule.type as SpektrFieldType }];
  }

  let lefts: SpektrFieldTypedKey[] = [];
  let rights: SpektrFieldTypedKey[] = [];

  if (rule.left) lefts = extractLeftOperandSpektrfieldsFromRule(rule.left);
  if (typeof rule.right === 'object' && !Array.isArray(rule.right)) {
    rights = extractLeftOperandSpektrfieldsFromRule(rule.right);
  }

  return [...lefts, ...rights];
}
