import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { RBAC } from '@spektr/shared/rbac';
import {
  getChannelSettingsFromRootNode,
  getRootNode,
  processBuilderUrl,
} from '@spektr/shared/utils';

import {
  getAllowedSpektrFieldsQuery,
  getApiKeysQuery,
  getProcessesQuery,
} from '@spektr/client/services';

import { useDialogClose, useUpdateNode } from '@spektr/shared/hooks';

import { usePermissionsContext } from '@spektr/client/providers';
import { Dialog, DialogContent } from '@spektr/client/components';

import { type ChannelSettings } from '@spektr/shared/validators';
import {
  type Process,
  type NodeUpdateInput,
  type OnboardingProcessSourceNode,
  type ProcessSource,
} from '@spektr/shared/types';

import { SourceSettings } from '../../../processes';

export type OnboardingSourceDialogProps = {
  process: Process;
  node: OnboardingProcessSourceNode;
};

export const OnboardingSourceDialog = ({
  process,
  node,
}: OnboardingSourceDialogProps) => {
  const navigate = useNavigate();
  const [open, startExitAnimation] = useDialogClose();
  const { hasPermission } = usePermissionsContext();
  const { data: apiKeys } = useSuspenseQuery(getApiKeysQuery());
  const { data: processes } = useSuspenseQuery(
    getProcessesQuery({
      types: ['onboarding'],
    })
  );
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(process.id, node.id)
  );

  const channelSettings = useMemo(() => {
    return getChannelSettingsFromRootNode(process)?.[0];
  }, [process]);

  const handleClose = () => {
    startExitAnimation();
    navigate(processBuilderUrl(process.id));
  };
  const updateLoopMutation = useUpdateNode(process.id, handleClose);

  const filteredProcesses = useMemo(
    () => processes.filter((p) => p.id !== process.id),
    [processes, process]
  );

  const handleUpdateLoop = (
    sources: ProcessSource[],
    settings: ChannelSettings
  ) => {
    const rootNode = process ? getRootNode(process) : undefined;
    const result = {
      nodeType: rootNode?.nodeType,
      sources,
      channelSettings: [settings],
    } as NodeUpdateInput;

    updateLoopMutation.mutate({ nodeId: node.id, node: result });
  };

  return (
    <Dialog open={open} modal={false}>
      <DialogContent
        modal={false}
        className="bg-color-bg-dialog-default absolute flex flex-col"
        requestStartExitAnimation={handleClose}
        onEndExitAnimation={handleClose}
        onEscapeKeyDown={handleClose}
      >
        <SourceSettings
          apiKeys={apiKeys}
          channelSettings={channelSettings}
          isUpdatePending={updateLoopMutation.isPending}
          hasUpdatePermission={hasPermission(RBAC.ACTIONS.PROCESS.UPDATE)}
          nodeTitle="Process settings"
          nodeIcon="Cog"
          process={process}
          processes={filteredProcesses}
          spektrFields={spektrFields}
          onClickUpdate={handleUpdateLoop}
        />
      </DialogContent>
    </Dialog>
  );
};
