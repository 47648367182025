import { Cog } from 'lucide-react';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import { PROCESS_FILTER_URL } from '@spektr/shared/utils';

import { cn } from '@spektr/client/utils';
import { NODE } from '@spektr/shared/components';
import { IconWithBackground } from '@spektr/client/components';

export const ProcessFilterNode = memo(() => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(PROCESS_FILTER_URL);
  };

  return (
    <div
      className={cn(
        'flex flex-col gap-4 p-6',
        `h-[${NODE.HEIGHT}px] w-[${NODE.WIDTH}px]`,
        'rounded-lg border-2 dark:border',
        'bg-color-bg-accent',
        'cursor-pointer duration-200',
        'hover:bg-color-bg-accent/70 hover:shadow-lg'
      )}
      onClick={handleClick}
      data-cy="filter-node"
    >
      <div className="flex h-full items-center gap-6">
        <IconWithBackground
          className={cn('h-10 w-10', 'bg-color-stroke-default/20')}
        >
          <Cog className="stroke-color-stroke-default h-5 w-5" />
        </IconWithBackground>
        <div className="flex flex-col gap-1">
          <h3 className="text-color-text-primary text-sm font-medium">
            Process Settings
          </h3>
          <span className="text-color-text-subtext text-xs">
            Configure the process
          </span>
        </div>
      </div>
      <Handle
        type="source"
        id="root"
        position={Position.Right}
        isConnectable={false}
        style={{ opacity: 0 }}
      />
    </div>
  );
});
