import { cn } from '@spektr/client/utils';

import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';

type SkeletonTableProps = {
  rows?: number;
};

export const SkeletonTable = ({ rows = 7 }: SkeletonTableProps) => (
  <Table className="rounded-md border">
    <TableBody>
      {Array.from({ length: rows }).map((_, index) => (
        <TableRow className="hover:bg-inherit" key={index}>
          <TableCell
            className={cn(
              'border-r p-4',
              'text-sm font-medium',
              'text-color-text-subtext',
              index < rows - 1 && 'border-b'
            )}
          >
            <Skeleton className="w-20" />
          </TableCell>
          <TableCell
            className={cn(
              'px-4',
              'text-sm font-medium',
              index < rows - 1 && 'border-b'
            )}
          >
            <Skeleton className="w-32" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
