import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { CommentParentType } from '@spektr/shared/validators';

import { countCommentsByParentId } from '@spektr/client/services';

export const useGetMessageCount = (
  parentId: string,
  parentType: CommentParentType = 'hit'
) => {
  const messageCount = useSuspenseQuery(
    countCommentsByParentId(parentId, parentType)
  );

  return useMemo(() => messageCount.data, [messageCount]);
};
