import { Eye, EyeOff } from 'lucide-react';
import { useState } from 'react';

export type KeyPrivacyProps = {
  value: string;
};

export const KeyPrivacy = ({ value }: KeyPrivacyProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggleVisibility = () => {
    setIsVisible((prevValue) => !prevValue);
  };

  const displayValue = isVisible ? value : '********';

  return (
    <div className="flex flex-row items-start gap-2">
      <span>{displayValue}</span>
      {isVisible ? (
        <EyeOff
          className="h-4 w-4 cursor-pointer"
          onClick={handleToggleVisibility}
        />
      ) : (
        <Eye
          className="h-4 w-4 cursor-pointer"
          onClick={handleToggleVisibility}
        />
      )}
    </div>
  );
};
