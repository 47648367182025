import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInterval } from 'react-use';
import { useMutation } from '@tanstack/react-query';

import { useUpdateCurrentRoute } from '@spektr/client/providers';
import { LOGIN_URL } from '@spektr/shared/utils';

import { AuthContext } from '../../auth/auth-context';

export const useSessionExpiration = (
  minutesBefore = 5,
  expiryCheckInterval = 30000
) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { logOut } = useContext(AuthContext);
  const updateState = useUpdateCurrentRoute();
  const [expiration, setExpiration] = useState<number | null>();
  const [hasUserDeclinedRefresh, setUserDeclinedRefresh] = useState(false);

  const navigateToLogin = useCallback(
    () =>
      navigate(LOGIN_URL, {
        state: {
          redirectFrom: null,
        },
      }),
    [navigate]
  );

  const getSessionExpiry = useCallback(async () => {
    const expiryTime =
      expiration ??
      (await import('../../auth/authService').then((module) =>
        module.default.getSessionExpiration()
      ));

    if (expiryTime) {
      setExpiration(expiryTime);
      const timeLeft = expiryTime - Date.now() / 1000;

      return timeLeft;
    }

    return 0;
  }, [expiration, setExpiration]);

  const handleRefresh = useMutation({
    mutationFn: async () => {
      await import('../../auth/authService').then((module) =>
        module.default.refreshSession()
      );
    },
    onSuccess: () => {
      setExpiration(null);
    },
  });

  const checkForExpiry = useCallback(async () => {
    const timeLeft = await getSessionExpiry();

    if (timeLeft <= 0) {
      logOut(navigateToLogin);
    }

    if (hasUserDeclinedRefresh) {
      return;
    }

    if (timeLeft <= minutesBefore * 60) {
      if (state?.show_refresh_session) {
        return;
      }

      updateState({
        state: {
          show_refresh_session: true,
        },
      });

      return;
    }

    if (state?.show_refresh_session) {
      updateState();
    }
  }, [
    getSessionExpiry,
    hasUserDeclinedRefresh,
    logOut,
    minutesBefore,
    navigateToLogin,
    state,
    updateState,
  ]);

  useInterval(checkForExpiry, expiryCheckInterval);

  useEffect(() => {
    checkForExpiry();
  }, [checkForExpiry]);

  return useMemo(
    () => ({
      handleRefresh,
      onDeclineRefresh: () => setUserDeclinedRefresh(true),
    }),
    [handleRefresh, setUserDeclinedRefresh]
  );
};
