import { Suspense } from 'react';

import { HitData } from '../../containers/HitData';
import { SkeletonTable } from '../../components/SkeletonTable';

export const HitDetails = () => {
  return (
    <Suspense fallback={<SkeletonTable />}>
      <HitData />
    </Suspense>
  );
};
