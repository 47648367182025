import { RouterIcon } from '@spektr/client/components';

export type RouterNodeProps = {
  title: string;
};

export const RouterNode = ({ title }: RouterNodeProps) => {
  return (
    <>
      <RouterIcon className="ml-5 mr-4 h-10 w-10" iconClassName="w-5 h-5" />
      <div className="flex flex-col gap-0.5">
        <h3 className="text-color-text-node text-sm font-medium">{title}</h3>
        <span className="text-color-text-node-subtext text-xs">
          Routing step
        </span>
      </div>
    </>
  );
};
