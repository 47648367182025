import { z } from 'zod';

import { objectIdSchema } from '../common';

import { edgeSchema } from './edgeSchema';
import { createNodeBaseSchema } from './createNodeBaseSchema';

export const AiActionConfigNodeInputSchema = z.strictObject({
  nodeType: z.literal('aiActionConfig'),
  title: z.string().min(1),
});
export type AiActionConfigNodeInputSchema = z.infer<
  typeof AiActionConfigNodeInputSchema
>;

// TODO: Add the specific fields
export const AiActionConfigNodeSchema = AiActionConfigNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

export type AiActionConfigNode = z.infer<typeof AiActionConfigNodeSchema>;

export const CreateAiActionConfigNodeSchema =
  AiActionConfigNodeInputSchema.merge(createNodeBaseSchema);

export const UpdateAiActionConfigNodeSchema = AiActionConfigNodeInputSchema;
