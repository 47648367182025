import { useMemo } from 'react';
import { produce } from 'immer';

import { cn } from '@spektr/client/utils';

import { Button } from '@spektr/client/components';

import {
  CompanyNumberFormat,
  InputField,
  isEntitiesField,
  isInputField,
  isWritableField,
  type Field,
} from '@spektr/moonraker-types';

import { useFormEngine } from '../../../../hooks/useFormEngine';

import { ConfigSegment } from '../../../../components/ConfigSegment';
import { CompanyNameFormatDropdown } from '../../../../components/CompanyNumberFormatDropdown';

import { TextareaField } from '../fields';

export type ValidationProps = {
  currentField: Field;
};

function hasFormatValidation(field: InputField) {
  return field.validation.some((rule) => {
    return rule.type === 'regex';
  });
}

export const Validation = ({ currentField }: ValidationProps) => {
  const { state, updateField } = useFormEngine();

  const handleChangeFormat = (format: CompanyNumberFormat) => {
    const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];
    let fieldToBeUpdated = currentField;

    if (fieldId) {
      fieldToBeUpdated = state.fields[rootFieldId];
    }

    if (!fieldToBeUpdated) {
      return;
    }

    const updatedField = produce(fieldToBeUpdated, (draft) => {
      if (isEntitiesField(draft)) {
        const field = draft.form.fields[fieldId];
        if (isInputField(field) && hasFormatValidation(field)) {
          const formatRule = field.validation.find(
            (rule) => rule.type === 'regex'
          );
          if (formatRule) {
            formatRule.preset = format.id;
            formatRule.value = format.regex;
            formatRule.message = format.message;
          }
        } else if (isInputField(field)) {
          field.validation.push({
            type: 'regex',
            preset: format.id,
            value: format.regex,
            message: format.message,
          });
        }

        return;
      }

      if (isInputField(draft) && hasFormatValidation(draft)) {
        const formatRule = draft.validation.find(
          (rule) => rule.type === 'regex'
        );
        if (formatRule) {
          formatRule.preset = format.id;
          formatRule.value = format.regex;
          formatRule.message = format.message;
        }
      } else if (isInputField(draft)) {
        draft.validation.push({
          type: 'regex',
          preset: format.id,
          value: format.regex,
          message: format.message,
        });
      }
    });

    updateField(updatedField);
  };

  const handleClearFormat = () => {
    const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];
    let fieldToBeUpdated = currentField;

    if (fieldId) {
      fieldToBeUpdated = state.fields[rootFieldId];
    }

    if (!fieldToBeUpdated) {
      return;
    }

    const updatedField = produce(fieldToBeUpdated, (draft) => {
      if (isEntitiesField(draft)) {
        const field = draft.form.fields[fieldId];
        if (isInputField(field)) {
          field.validation = field.validation.filter(
            (rule) => rule.type !== 'regex'
          );
        }

        return;
      }

      if (isInputField(draft)) {
        draft.validation = draft.validation.filter(
          (rule) => rule.type !== 'regex'
        );
      }
    });

    updateField(updatedField);
  };

  const handleChange = (name: string, value: string) => {
    const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];
    let fieldToBeUpdated = currentField;

    if (fieldId) {
      fieldToBeUpdated = state.fields[rootFieldId];
    }

    if (!fieldToBeUpdated || !isWritableField(fieldToBeUpdated)) {
      return;
    }

    const updatedField = produce(fieldToBeUpdated, (draft) => {
      if (isEntitiesField(draft)) {
        const field = draft.form.fields[fieldId];

        if (name === 'regex_error_message') {
          if (isInputField(field) && hasFormatValidation(field)) {
            const formatRule = field.validation.find(
              (rule) => rule.type === 'regex'
            );

            if (formatRule) {
              formatRule.message = value;
            }
          }
        }
        return;
      }

      if (name === 'regex_error_message') {
        if (isInputField(draft) && hasFormatValidation(draft)) {
          const formatRule = draft.validation.find(
            (rule) => rule.type === 'regex'
          );

          if (formatRule) {
            formatRule.message = value;
          }
        }
      }
    });

    updateField(updatedField);
  };

  const formatRule = useMemo(() => {
    if (isInputField(currentField)) {
      return currentField.validation.find((rule) => rule.type === 'regex');
    }

    return null;
  }, [currentField]);

  if (
    isInputField(currentField) &&
    currentField.attributes.type === 'company_number'
  ) {
    return (
      <ConfigSegment name="validation" title="Validation">
        <div className={cn('flex flex-col gap-4', 'mb-2 pb-2')}>
          <CompanyNameFormatDropdown
            label="Company number format"
            value={
              currentField.validation.find((rule) => rule.type === 'regex')
                ?.preset
            }
            onChangeFormat={handleChangeFormat}
          />
          <TextareaField
            value={formatRule?.message}
            label="Error message"
            name="formatErrorMessage"
            disabled={!formatRule}
            placeholder="Enter the error message"
            rows={3}
            onChange={(ev) =>
              handleChange('regex_error_message', ev.target.value)
            }
          />
          {formatRule && (
            <Button
              className="text-color-text-secondary ml-auto w-fit"
              variant="text"
              onClick={handleClearFormat}
            >
              Clear format
            </Button>
          )}
        </div>
      </ConfigSegment>
    );
  }

  return null;
};
