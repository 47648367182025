import { ReactNode } from 'react';

import { type SettingsTab } from '../../types/SettingsTab';

export type TabViewProps = {
  activeTab: SettingsTab;
  children: ReactNode;
  name: SettingsTab;
};

export const TabView = ({ activeTab, children, name }: TabViewProps) => {
  if (activeTab !== name) {
    return null;
  }

  return children;
};
