import { format } from 'date-fns';
import { AlarmClock } from 'lucide-react';

import { LONG_DATE_FORMAT } from '@spektr/client/constants';

export type ServiceCardRequestedStatusProps = {
  requestedAt: number;
};

export const ServiceCardRequestedStatus = ({
  requestedAt,
}: ServiceCardRequestedStatusProps) => {
  return (
    <div className="flex gap-2">
      <div className="dark:bg-color-bg-accent bg-color-bg-secondary/15 bg-flex flex h-8 min-w-8 items-center justify-center rounded-md">
        <AlarmClock className="stroke-color-stroke-white h-3.5 stroke-2" />
      </div>

      <div className="flex flex-col justify-center">
        <p className="text-color-text-primary text-xs font-semibold leading-4">
          Access requested
        </p>
        <span className="text-color-text-subtext text-xs font-normal">
          {format(new Date(requestedAt), LONG_DATE_FORMAT)}
        </span>
      </div>
    </div>
  );
};
