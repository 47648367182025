import {
  SpektrFieldKey,
  kyckrFields,
  kyckrOutcomes,
} from '@spektr/shared/types';

import { ClientTrigger } from '@spektr/client/types';

import { ServiceFieldClient } from '../types/ServiceFieldClient';

export const computeKyckrFields = (fields: ServiceFieldClient[]) => {
  const enabledFieldKeys = fields
    .filter((field) => field.value)
    .map((filteredField) => filteredField.key);

  return [
    {
      key: 'representatives' as SpektrFieldKey,
      label: 'Representatives',
      value: isKyckrFieldSelected('representatives', enabledFieldKeys),
      help: 'Get information about representatives and their role in the company.',
    },
    {
      key: 'first_layer_of_ownership' as SpektrFieldKey,
      label: 'First layer of ownership',
      value: isKyckrFieldSelected('first_layer_of_ownership', enabledFieldKeys),
      help: 'Get the first layer of ownership, i.e. the direct owners of the company and the shareholding amount (%). Note that this list of owners can contain individuals and/or companies.',
    },
    {
      key: 'all_potential_layers_of_ownership' as SpektrFieldKey,
      label: 'All potential layers of ownership',
      value: isKyckrFieldSelected(
        'all_potential_layers_of_ownership',
        enabledFieldKeys
      ),
      help: 'Get information about the whole ownership structure. Note that this research costs more than a first-layer ownership request and can take a bit longer. With this service, the UBO list is guaranteed to be individuals.',
    },
  ];
};

export const isKyckrFieldSelected = (
  fieldKey: string,
  fields: SpektrFieldKey[]
) =>
  fields.some((field) => {
    const kyckrField = kyckrFields.find(
      (spektrField) => spektrField.key === field
    );

    return !!kyckrField?.availableIn?.includes(fieldKey);
  });

export const getSelectedServiceFields = (
  fields: ServiceFieldClient[],
  selectedKyckrFields: SpektrFieldKey[]
): SpektrFieldKey[] => {
  if (selectedKyckrFields.length === 0) {
    return [];
  }

  return fields
    .filter((field) => {
      const kyckrField = kyckrFields.find(
        (spektrField) => spektrField.key === field.key
      );

      if (!kyckrField?.availableIn) {
        return true;
      }

      return kyckrField?.availableIn?.some((outcome) =>
        selectedKyckrFields.includes(outcome)
      );
    })
    .map((field) => field.key);
};

export const getSelectedKyckrFields = (fields: ServiceFieldClient[]) => {
  const selectedFieldKeys = fields
    .filter((field) => field.value)
    .map((selectedField) => selectedField.key);

  return kyckrOutcomes.filter((outcome) =>
    isKyckrFieldSelected(outcome, selectedFieldKeys)
  );
};

export const getFirstAvailableFieldKey = (
  fields: ServiceFieldClient[],
  fieldKey: string
) =>
  (fields.find((field) => {
    const kyckrField = kyckrFields.find(
      (spektrField) => spektrField.key === field.key
    );

    return !!kyckrField?.availableIn?.includes(fieldKey);
  })?.key ?? '') as SpektrFieldKey;

/**
 * @description Check if the field is a common kyckr field.
 * Common fields are fields that are available in kyckr outcomes
 * (e.g. representatives, first_layer_of_ownership, all_potential_layers_of_ownership)
 *
 * @param fieldKey - key of the service field
 * @returns true if the field doesn't have the availableIn property set, false otherwise
 */
export const isCommonField = (fieldKey: SpektrFieldKey) => {
  const kyckrField = kyckrFields.find(
    (spektrField) => spektrField.key === fieldKey
  );

  return !!kyckrField && !kyckrField.availableIn;
};

export const keepCommonField = (
  fieldKey: SpektrFieldKey,
  currentTrigger: ClientTrigger,
  fields: ServiceFieldClient[],
  kyckrKey?: SpektrFieldKey
) => {
  if (!isCommonField(fieldKey) || !kyckrKey || !fields.length) {
    return false;
  }

  return kyckrOutcomes.some((outcome) => {
    if ((outcome as SpektrFieldKey) === kyckrKey) {
      return false;
    }

    const availableServiceField = getFirstAvailableFieldKey(fields, outcome);
    return currentTrigger.fields?.includes(availableServiceField);
  });
};
