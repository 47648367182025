import { cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

import {
  complyAdvantageContained,
  complyAdvantageOutlined,
  complyAdvantageText,
} from './variants/complyAdvantage';
import {
  openCorporatesContained,
  openCorporatesOutlined,
  openCorporatesText,
} from './variants/openCorporates';
import { bodaccContained, bodaccOutlined, bodaccText } from './variants/bodacc';
import { veriffContained, veriffOutlined, veriffText } from './variants/veriff';
import {
  companiesHouseContained,
  companiesHouseOutlined,
  companiesHouseText,
} from './variants/companiesHouse';
import { kyckrContained, kyckrOutlined, kyckrText } from './variants/kyckr';
import {
  dunBradstreetContained,
  dunBradstreetOutlined,
  dunBradstreetText,
} from './variants/dunBradstreet';
import {
  dowJonesContained,
  dowJonesOutlined,
  dowJonesText,
} from './variants/dowJones';
import {
  lexisNexisContained,
  lexisNexisOutlined,
  lexisNexisText,
} from './variants/lexisNexis';
import {
  transUnionContained,
  transUnionOutlined,
  transUnionText,
} from './variants/transUnion';
import {
  creditSafeContained,
  creditSafeOutlined,
  creditSafeText,
} from './variants/creditSafe';
import { mitIdContained, mitIdOutlined, mitIdText } from './variants/mitId';

const outlined = cn('border', 'bg-transparent');
const text = cn('bg-transparent');

const primaryContained = cn(
  'bg-color-bg-button-primary',
  'text-color-text-button-contained-primary',
  'hover:bg-color-bg-button-primary--hover',
  'active:bg-color-bg-button-primary--active',
  'disabled:bg-opacity-50'
);
const primaryOutlined = cn(
  primaryContained,
  'dark:border-color-border-button-primary',
  'border-color-border-secondary',
  'disabled:border-color-border-button-primary--disabled'
);
const primaryText = cn('text-color-text-button-primary');

const secondaryContained = cn(
  'bg-color-bg-button-secondary',
  'text-color-text-button-contained-secondary',
  'hover:bg-color-bg-button-secondary--hover',
  'active:bg-color-bg-button-secondary--active/50',
  'disabled:bg-opacity-50'
);
const secondaryOutlined = cn(
  secondaryContained,
  'border-color-border-button-secondary',
  'disabled:border-color-border-button-secondary--disabled'
);
const secondaryText = cn('text-color-text-button-secondary');

const cyanContained = cn(
  'bg-color-cyan',
  'text-color-text-button-contained-cyan',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'disabled:bg-opacity-50',
  'disabled:border-color-cyan/50'
);
const cyanOutlined = cn(
  cyanContained,
  'border-color-cyan',
  'text-color-cyan',
  'hover:bg-color-cyan/10',
  'active:bg-color-cyan/20',
  'disabled:border-color-cyan/50'
);
const cyanText = cn('text-color-cyan');

const successContained = cn(
  'bg-color-secondary-green',
  'text-color-text-button-contained-success',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'disabled:bg-opacity-50',
  'disabled:border-color-secondary-green/50'
);
const successOutlined = cn(
  successContained,
  'border-color-secondary-green',
  'text-color-secondary-green',
  'hover:bg-color-secondary-green/10',
  'active:bg-color-secondary-green/20',
  'disabled:border-color-secondary-green/50'
);
const successText = cn('text-color-secondary-green');

const yellowContained = cn(
  'bg-color-yellow',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'text-color-text-button-contained-warning',
  'disabled:bg-opacity-50'
);
const yellowOutlined = cn(
  yellowContained,
  'border-color-yellow',
  'text-color-yellow',
  'hover:bg-color-yellow/10',
  'active:bg-color-yellow/20',
  'disabled:border-color-yellow/50'
);
const yellowText = cn('text-color-yellow');

const blueContained = cn(
  'bg-color-blue',
  'hover: bg-opacity-90',
  'active: bg-opacity-80',
  'text-color-text-button-contained-info',
  'disabled:bg-opacity-50',
  'disabled:border-color-blue/50'
);

const blueOutlined = cn(
  blueContained,
  'border-color-blue',
  'text-color-blue',
  'hover:bg-color-blue/10',
  'active:bg-color-blue/20',
  'disabled:border-color-blue/50'
);

const blueText = cn('text-color-blue');

const redContained = cn(
  'bg-color-red',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'text-color-text-button-contained-alert',
  'disabled:bg-opacity-50'
);
const redOutlined = cn(
  redContained,
  'border-color-red',
  'text-color-red',
  'hover:bg-color-red/10',
  'active:bg-color-red/20',
  'disabled:border-color-red/50'
);
const redText = cn('text-color-red');

const whiteContained = cn(
  'bg-color-bg-button-white',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'text-color-text-button-contained-white',
  'disabled:bg-opacity-50'
);
const whiteOutlined = cn(
  redContained,
  'border-color-border-button-white',
  'text-color-text-button-outlined-white',
  'hover:bg-color-bg-button-white/10',
  'active:bg-color-bg-button-white/20',
  'disabled:border-color-border-button-white--disabled'
);
const whiteText = cn('text-color-text-button-white');

const actionContained = cn(
  'bg-color-bg-button-action',
  'text-color-text-button-contained-action',
  'hover:bg-spektr-tertiary-default/90',
  'active:bg-spektr-tertiary-default/80'
);
const actionOutlined = cn(
  actionContained,
  'border-color-border-button-action',
  'text-color-text-tertiary',
  'hover:bg-color-bg-button-action/10',
  'active:bg-color-bg-button-action/20',
  'disabled:border-color-border-button-action--disabled'
);
const actionText = cn('text-color-text-tertiary');

const slackContained = cn(
  'bg-color-slack',
  'text-color-text-button-contained-slack',
  'hover:bg-color-slack/90',
  'active:bg-color-slack/80'
);
const slackOutlined = cn(
  slackContained,
  'border-color-slack',
  'text-color-text-tertiary',
  'hover:bg-color-slack/10',
  'active:bg-color-slack/20',
  'disabled:border-color-slack/50'
);
const slackText = cn('text-color-text-tertiary');

export const buttonVariants = cva(
  [
    'group',
    'inline-flex items-center justify-center',
    'rounded-md',
    'text-xs font-semibold',
    'transition-colors',
    'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
    'disabled:pointer-events-none disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        'contained-primary': primaryContained,
        'outlined-primary': cn(primaryOutlined, outlined),
        'text-primary': cn(primaryText, text),
        'contained-secondary': secondaryContained,
        'outlined-secondary': cn(secondaryOutlined, outlined),
        'text-secondary': cn(secondaryText, text),
        'contained-cyan': cyanContained,
        'outlined-cyan': cn(cyanOutlined, outlined),
        'text-cyan': cn(cyanText, text),
        'contained-success': successContained,
        'outlined-success': cn(successOutlined, outlined),
        'text-success': cn(successText, text),
        'contained-yellow': yellowContained,
        'outlined-yellow': cn(yellowOutlined, outlined),
        'text-yellow': cn(yellowText, text),

        'contained-blue': blueContained,
        'outlined-blue': cn(blueOutlined, outlined),
        'text-blue': cn(blueText, text),

        'contained-red': redContained,
        'outlined-red': cn(redOutlined, outlined),
        'text-red': cn(redText, text),

        'contained-white': whiteContained,
        'outlined-white': cn(whiteOutlined, outlined),
        'text-white': cn(whiteText, text),

        'contained-action': cn(actionContained),
        'outlined-action': cn(actionOutlined, outlined),
        'text-action': cn(actionText, text),

        // Vendor variants
        'contained-complyAdvantage': complyAdvantageContained,
        'outlined-complyAdvantage': cn(complyAdvantageOutlined, outlined),
        'text-complyAdvantage': cn(complyAdvantageText, text),

        'contained-openCorporates': openCorporatesContained,
        'outlined-openCorporates': cn(openCorporatesOutlined, outlined),
        'text-openCorporates': cn(openCorporatesText, text),

        'contained-bodacc': bodaccContained,
        'outlined-bodacc': cn(bodaccOutlined, outlined),
        'text-bodacc': cn(bodaccText, text),

        'contained-kyckr': kyckrContained,
        'outlined-kyckr': cn(kyckrOutlined, outlined),
        'text-kyckr': cn(kyckrText, text),

        'contained-companiesHouse': companiesHouseContained,
        'outlined-companiesHouse': cn(companiesHouseOutlined, outlined),
        'text-companiesHouse': cn(companiesHouseText, text),

        'contained-veriff': veriffContained,
        'outlined-veriff': cn(veriffOutlined, outlined),
        'text-veriff': cn(veriffText, text),

        'contained-mitId': mitIdContained,
        'outlined-mitId': cn(mitIdOutlined, outlined),
        'text-mitId': cn(mitIdText, text),

        'contained-dunBradstreet': dunBradstreetContained,
        'outlined-dunBradstreet': cn(dunBradstreetOutlined, outlined),
        'text-dunBradstreet': cn(dunBradstreetText, text),

        'contained-dowJones': dowJonesContained,
        'outlined-dowJones': cn(dowJonesOutlined, outlined),
        'text-dowJones': cn(dowJonesText, text),

        'contained-lexisNexis': lexisNexisContained,
        'outlined-lexisNexis': cn(lexisNexisOutlined, outlined),
        'text-lexisNexis': cn(lexisNexisText, text),

        'contained-transUnion': transUnionContained,
        'outlined-transUnion': cn(transUnionOutlined, outlined),
        'text-transUnion': cn(transUnionText, text),

        'contained-creditSafe': creditSafeContained,
        'outlined-creditSafe': cn(creditSafeOutlined, outlined),
        'text-creditSafe': cn(creditSafeText, text),

        // Integration variants
        'contained-slack': cn(slackContained),
        'outlined-slack': cn(slackOutlined, outlined),
        'text-slack': cn(slackText, text),
      },
      size: {
        default: 'h-9 rounded-md px-4 py-2',
        sm: 'h-7 rounded-md px-3',
        lg: 'h-10 rounded-md px-4 text-sm',
      },
    },
    defaultVariants: {
      variant: 'contained-primary',
      size: 'default',
    },
  }
);
