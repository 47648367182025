import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

export type StatusCheckProps = VariantProps<typeof fadedCircleVariant> & {
  className?: string;
  label: string;
};

export const StatusCheck = ({
  animated,
  className,
  color,
  label,
}: StatusCheckProps) => (
  <div className={cn('flex flex-row items-center gap-2', className)}>
    <span className="relative flex h-1.5 w-1.5 shrink-0 ">
      <span
        className={cn(
          fadedCircleVariant({
            color,
            animated,
          })
        )}
      ></span>
      <span className={cn(circleVariant({ color }))}></span>
    </span>
    <span className="text-color-text-subtext text-xs">{label}</span>
  </div>
);

const fadedCircleVariant = cva(
  ['absolute', 'inline-flex', 'h-full w-full', 'rounded-full', 'opacity-75'],
  {
    variants: {
      color: {
        red: 'bg-color-red',
        cyan: 'bg-color-cyan',
      },
      animated: {
        true: 'animate-ping',
        false: '',
      },
    },
    defaultVariants: {
      color: 'cyan',
      animated: false,
    },
  }
);

const circleVariant = cva(
  ['relative', 'inline-flex', 'h-1.5 w-1.5', 'rounded-full'],
  {
    variants: {
      color: {
        red: 'bg-color-red',
        cyan: 'bg-color-cyan',
      },
    },
    defaultVariants: {
      color: 'cyan',
    },
  }
);
