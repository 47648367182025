import { Suspense } from 'react';

import { Skeleton, SpektrErrorBoundary } from '@spektr/client/components';

import { ProcessNameWrapper } from './ProcessNameWrapper';

export const ProcessDetailsHeader = () => {
  return (
    <SpektrErrorBoundary fallback={null}>
      <Suspense fallback={<Skeleton />}>
        <ProcessNameWrapper />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
