import { datadogRum } from '@datadog/browser-rum';
import { ZodError } from 'zod';

import { getFirstParsingError } from '../network';

export function logError(error: Error, context?: object) {
  if (error instanceof ZodError) {
    const firstError = getFirstParsingError(error);

    if (firstError) {
      return datadogRum.addError(new Error(firstError), context);
    }
  }

  return datadogRum.addError(error, context);
}
