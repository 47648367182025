import { cn } from '@spektr/client/utils';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';

import { ActionsTableHeader } from '../ActionsTableHeader';

const baseRowCellClasses = cn('px-4 py-6', 'border-b', 'text-sm font-medium');

export const SkeletonActionsTable = () => {
  return (
    <Table className="border-separate">
      <ActionsTableHeader />
      <TableBody>
        {new Array(5).fill(null).map((_, index) => (
          <TableRow key={index}>
            <TableCell
              className={cn(baseRowCellClasses, index === 4 && 'border-0')}
            >
              <Skeleton className="w-full" />
            </TableCell>
            <TableCell
              className={cn(baseRowCellClasses, index === 4 && 'border-0')}
            >
              <Skeleton className="w-full" />
            </TableCell>
            <TableCell
              className={cn(baseRowCellClasses, index === 4 && 'border-0')}
            >
              <Skeleton className="w-full" />
            </TableCell>
            <TableCell
              className={cn(baseRowCellClasses, index === 4 && 'border-0')}
            >
              <Skeleton className="w-full min-w-16" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
