export type VendorServiceName =
  | 'bodacc'
  | 'companiesHouse'
  | 'complyAdvantage'
  | 'creditSafe'
  | 'csiWatchdog'
  | 'dowJones'
  | 'dunBradstreet'
  | 'experian'
  | 'finScan'
  | 'kyckr'
  | 'lexisNexis'
  | 'lseg'
  | 'moody'
  | 'openCorporates'
  | 'transUnion'
  | 'veriff'
  | 'mitId';

const vendorNames = [
  'bodacc',
  'companiesHouse',
  'complyAdvantage',
  'creditSafe',
  'csiWatchdog',
  'dowJones',
  'dunBradstreet',
  'experian',
  'finScan',
  'kyckr',
  'lexisNexis',
  'lseg',
  'moody',
  'openCorporates',
  'transUnion',
  'veriff',
  'mitId',
] as const;

export function isServiceName(name: VendorServiceName) {
  return vendorNames.includes(name);
}
