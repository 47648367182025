import { cn } from '@spektr/client/utils';

import { Label, Select } from '../forms';

import { Pagination } from './Pagination';

export type PaginationCombobox = {
  currentPage: number;
  totalPages: number;
  pageLimit: number;
  alignment?: 'start' | 'end';
  onChangeLimit: (limit: number) => void;
  onChangePage: (page: number) => void;
};

export const PaginationCombobox = ({
  currentPage,
  totalPages,
  pageLimit = 10,
  alignment = 'end',
  onChangeLimit,
  onChangePage,
}: PaginationCombobox) => {
  const alignmentClass = alignment === 'end' ? 'justify-end' : 'justify-start';

  return (
    <div className="mt-auto h-[56px] w-full shrink-0">
      <div className={cn('flex items-center gap-4', alignmentClass)}>
        <div className="flex items-center gap-2">
          <Label className="text-color-text-subtext shrink-0 text-[10px] font-medium">
            Rows per page
          </Label>
          <Select
            value={pageLimit.toString()}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
            ]}
            onValueChange={(value) => onChangeLimit(Number(value))}
          />
        </div>
        <div className="w-[140px] shrink-0">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            nextPrevButtons
            firstLastButtons
            displayPages={false}
            onPageChange={onChangePage}
          />
        </div>
      </div>
    </div>
  );
};
