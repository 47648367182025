import { cn } from '@spektr/client/utils';

export const kyckrContained = cn(
  'bg-color-kyckr',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-kyckr',
  'active:bg-color-kyckr',
  'border'
);
export const kyckrOutlined = cn(
  kyckrContained,
  'border-color-kyckr',
  'text-color-kyckr',
  'hover:bg-color-kyckr/10',
  'active:bg-color-kyckr/20',
  'disabled:border-color-kyckr/50'
);
export const kyckrText = cn('text-color-kyckr');
