import { CloudUpload, Equal, Hash, Minus } from 'lucide-react';

import { WidgetBlock } from '../types/WidgetBlock';

export const BLOCKS: WidgetBlock[] = [
  {
    label: 'Single line',
    type: 'input',
    icon: <Minus className="h-4 w-4" />,
    group: 'input',
  },
  {
    label: 'Multi line',
    type: 'textarea',
    icon: <Equal className="h-4 w-4" />,
    group: 'input',
  },
  {
    label: 'File upload',
    type: 'upload',
    icon: <CloudUpload className="h-4 w-4" />,
    group: 'input',
  },
  {
    label: 'Headline',
    type: 'headline',
    icon: <Hash className="h-4 w-4" />,
    group: 'content',
  },
  {
    label: 'Paragraph',
    type: 'paragraph',
    icon: <Hash className="h-4 w-4" />,
    group: 'content',
  },
];
