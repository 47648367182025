import { logError } from '@spektr/client/utils';

import { DatasetWithMetaExtendedResponse } from '@spektr/shared/types';

import { FormValues, formValuesSchema } from '../validators/formValuesSchema';

import { fieldsArrayToObject } from './fieldsArrayToObject';

export function getDraftOrDefaultValue(
  datasetId: string,
  dataset: DatasetWithMetaExtendedResponse
): FormValues {
  const draft = sessionStorage.getItem(datasetId);

  if (draft) {
    try {
      return formValuesSchema.parse(JSON.parse(draft));
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error;
      }

      logError(error, {
        raw: JSON.stringify(error),
      });
    }
  }

  return {
    fields: fieldsArrayToObject(dataset.fields),
    fieldId: dataset.fieldId,
  };
}
