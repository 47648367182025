import { Suspense, useState } from 'react';
import {
  QueryErrorResetBoundary,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { cn } from '@spektr/client/utils';

import { ApiKeysApiClient, getApiKeysQueryKey } from '@spektr/client/services';

import { ApiKeySchema } from '@spektr/shared/validators';

import {
  AlertDialog,
  SpektrErrorBoundary,
  TableRow,
  toast,
} from '@spektr/client/components';
import { Card } from '@spektr/shared/components';

import { ListApiKeys } from '../../components/ListApiKeys';
import { TableSkeleton } from '../../components/TableSkeleton';
import { ApiKeysTable } from '../../components/ApiKeysTable';
import { SettingsTableCell } from '../../components/SettingsTableCell';

export type DeveloperApiKeysProps = {
  className?: string;
};

export const DeveloperApiKeys = ({ className }: DeveloperApiKeysProps) => {
  const queryClient = useQueryClient();
  const [pendingDeleteId, setPendingDeleteId] = useState('');

  const deleteApiKey = useMutation({
    mutationFn: async (id: string) => {
      await queryClient.cancelQueries({ queryKey: getApiKeysQueryKey() });

      queryClient.setQueryData(
        getApiKeysQueryKey(),
        (apiKeys: ApiKeySchema[]) => {
          return apiKeys.filter((apiKey) => apiKey.id !== id);
        }
      );

      return ApiKeysApiClient.getClient().deleteApiKey(undefined, {
        params: {
          id,
        },
      });
    },
    onError: () => {
      toast.error({
        title: 'Delete API Key',
        description: 'An error occurred while deleting the API key.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getApiKeysQueryKey(),
      });
    },
  });

  const onConfirmDeleteAPIKey = () => {
    setPendingDeleteId('');
    deleteApiKey.mutateAsync(pendingDeleteId);
  };

  return (
    <div className={cn(className)}>
      <AlertDialog
        open={!!pendingDeleteId}
        title="Are you sure?"
        paragraph="Deleting this API key will immediately disable it. You can't undo this action."
        onCancel={() => setPendingDeleteId('')}
        cancel="Cancel"
        onConfirm={onConfirmDeleteAPIKey}
        confirm="Yes, delete"
        confirmDataCy="Yes-delete"
      />
      <Card className="flex w-full max-w-7xl flex-col gap-4">
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <SpektrErrorBoundary
              onReset={reset}
              fallbackRender={() => (
                <ApiKeysTable>
                  <TableRow>
                    <SettingsTableCell>There was an error!</SettingsTableCell>
                    <SettingsTableCell />
                    <SettingsTableCell />
                  </TableRow>
                </ApiKeysTable>
              )}
            >
              <Suspense fallback={<TableSkeleton />}>
                <ListApiKeys onDelete={setPendingDeleteId} />
              </Suspense>
            </SpektrErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </Card>
    </div>
  );
};
