import { useQuery } from '@tanstack/react-query';
import _debounce from 'lodash/debounce';
import { Clipboard } from 'lucide-react';

import { processNodeParamsSchema } from '@spektr/shared/utils';

import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import { BasicDialog } from '@spektr/client/components';
import { LoopFormBuilder } from '@spektr/client/loop-form-builder';
import { useParsedParams, useUpdateLoopNode } from '@spektr/shared/hooks';

import {
  updateActionFormNodeSchema,
  type WidgetInstance,
} from '@spektr/shared/validators';

import { ActionFormNode, NodeUpdateInput } from '@spektr/shared/types';

export type FormBuilderProps = {
  node: ActionFormNode;
  onClose: () => void;
};

export const FormBuilder = ({ node, onClose }: FormBuilderProps) => {
  const { processId } = useParsedParams(processNodeParamsSchema);
  const { data: spektrFields } = useQuery({
    ...getAllowedSpektrFieldsQuery(processId, node.id),
    retry: false,
  });

  const updateMutation = useUpdateLoopNode(processId);

  const handleUpdateNode = (data: NodeUpdateInput) => {
    updateMutation.mutateAsync({
      nodeId: node.id,
      node: data,
    });
  };

  const debouncedHandleUpdateNode = _debounce(handleUpdateNode, 500);

  const handleSaveWidgets = (widgets: WidgetInstance[]) => {
    const evaluated = updateActionFormNodeSchema.safeParse({
      nodeType: node.nodeType,
      form: widgets,
    });

    if (!evaluated.success) {
      return;
    }

    debouncedHandleUpdateNode(evaluated.data);
  };

  return (
    <BasicDialog
      className="w-[1014px] max-w-[1014px] gap-3"
      defaultOpen
      title="Build a form"
      icon={<Clipboard className="text-color-text-icon-primary mr-3 h-5 w-5" />}
      size="large"
      onClose={onClose}
    >
      <LoopFormBuilder
        widgets={node.form ?? []}
        spektrFields={spektrFields ?? []}
        onUpdate={handleSaveWidgets}
      />
    </BasicDialog>
  );
};
