import { useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@spektr/client/utils';

import { getActionById } from '@spektr/client/services';

import { Checkbox } from '@spektr/client/components';

import {
  HitDecision,
  VendorBaseDto,
  HitResolvedDecision,
  AnyHitData,
  SpektrAIDetails,
} from '@spektr/shared/validators';

import { computeHitCardDetails } from '../../utils';

import { useHitResolutionReason } from '../../hooks/useHitResolutionReason';
import { useSpektrAISearch } from '../../hooks/useSpektrAISearch';
import { useHitsProviderContext } from '../../providers';

import { HitStatus } from '../HitStatus';
import { HitResolutionButtons } from '../HitResolutionButtons';
import { BasicDetailsTable } from '../BasicDetailsTable';
import { HitResolutionComment } from '../HitResolutionComment';
import { MessageCount } from '../MessageCount';
import { formatSpektrAIDetails } from '../../utils/computeSpektrAIDetails';

export type HitCardProps = {
  className?: string;
  data: AnyHitData;
  commentCount?: number;
  decision: HitDecision | HitResolvedDecision;
  vendorDetails: VendorBaseDto;
  id: string;
  actionId: string;
  subject: string;
  hitType: 'aml' | 'kyb';
  isCheckboxVisible?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
  spektrAI?: SpektrAIDetails;
  onFalsePositiveClick: (reason: string) => (hitId: string) => void;
  onTruePositiveClick: (reason: string) => (hitId: string) => void;
  onClickHit: (hitId: string) => void;
  onCheckedChange?: (hitId: string, isChecked: boolean) => void;
};

// TODO: (@Robert) - check the hardcoded hex color
export const HitCard = ({
  id,
  actionId,
  className,
  data,
  commentCount = 0,
  decision,
  subject,
  hitType = 'kyb',
  isChecked = false,
  isDisabled = false,
  vendorDetails,
  spektrAI,
  onClickHit,
  onFalsePositiveClick,
  onTruePositiveClick,
  onCheckedChange,
}: HitCardProps) => {
  const { t } = useTranslation();
  const { users } = useHitsProviderContext();
  const { reason, updateReason, user, resolvedAt } = useHitResolutionReason(
    decision,
    users
  );
  const { data: action } = useSuspenseQuery(getActionById(actionId));

  const renderColumn = (key: string) => {
    return t(`fields:${key}`);
  };

  const renderValue = (_: string, value: unknown) => {
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    if (typeof value === 'string') {
      return <span>{value}</span>;
    }

    return JSON.stringify(value);
  };

  const cleanData = useMemo(
    () => computeHitCardDetails(vendorDetails, data),
    [data, vendorDetails]
  );
  const updateSpektrAIMutation = useSpektrAISearch(
    actionId,
    action.datasetId,
    action.spektrId
  );

  return (
    <div
      className={cn(
        'flex flex-col',
        'px-6',
        'dark:bg-color-bg-accent/40 bg-color-bg-card-accent/50',
        'dark:hover:bg-color-bg-accent/20 hover:bg-color-bg-card-accent',
        'transition-colors',
        className
      )}
    >
      <div className={cn('flex flex-row', 'py-6')}>
        <button
          className="cursor-pointer font-medium"
          onClick={() => onClickHit(id)}
        >
          <span className="text-color-text-subtext">Hit on</span>{' '}
          <strong>{subject}</strong>
        </button>
        <div className={cn('flex flex-row gap-4', 'ml-auto')}>
          <HitStatus hitType={hitType} decision={decision.status} />
          <Checkbox
            className="bg-transparent"
            disabled={isDisabled}
            checked={isChecked}
            onCheckedChange={(checked) => {
              if (typeof checked === 'boolean') {
                onCheckedChange?.(id, checked);
              }
            }}
          />
        </div>
      </div>
      <div onClick={() => onClickHit(id)} className="cursor-pointer">
        {data && (
          <BasicDetailsTable
            data={cleanData}
            columnClassName="w-1/3"
            renderColumn={renderColumn}
            renderValue={renderValue}
          />
        )}
        {spektrAI && spektrAI.status === 'enabled' && (
          <div className="mt-4 flex flex-col gap-4">
            <p className="text-color-text-primary text-base font-medium">
              SpektrAI details
            </p>
            <BasicDetailsTable
              data={formatSpektrAIDetails(spektrAI, t, subject)}
              columnClassName="w-[320px]"
              renderValue={renderValue}
            />
          </div>
        )}
      </div>
      <div className={cn('flex flex-col gap-4', 'mt-auto pb-5 pt-4')}>
        <div className="flex w-full gap-4">
          <MessageCount hitId={id} count={commentCount} />
          <HitResolutionComment
            resolvedAt={resolvedAt}
            value={reason}
            user={user}
            onUpdateComment={updateReason}
          />
        </div>
        <HitResolutionButtons
          id={id}
          hitType={hitType}
          isDisabled={isDisabled}
          spektrAI={{
            performSearch: () => updateSpektrAIMutation.mutate({ hitId: id }),
            isPending: updateSpektrAIMutation.isPending,
            details: spektrAI,
          }}
          onFalsePositiveClick={onFalsePositiveClick(reason)}
          onTruePositiveClick={onTruePositiveClick(reason)}
        />
      </div>
    </div>
  );
};
