import { forwardRef, useState } from 'react';
import { icons } from 'lucide-react';

import { Input, InputProps } from './Input';

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [type, setType] = useState('password');

    const EyeIcon = icons[type === 'password' ? 'Eye' : 'EyeOff'];

    return (
      <div className="relative">
        <Input className="pr-8" {...props} type={type} ref={ref} />
        <button
          type="button"
          className="absolute right-3 top-1/2 -translate-y-1/2 transform"
          onClick={() => setType(type === 'password' ? 'text' : 'password')}
        >
          <EyeIcon className="text-color-text-icon-secondary h-4 w-4" />
        </button>
      </div>
    );
  }
);
