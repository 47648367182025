import { TopologyProcess } from '@spektr/shared/types';

export const getPathToNode = (
  nodeId: string,
  nodes: TopologyProcess[],
  path: TopologyProcess[] = []
): TopologyProcess[] => {
  const parent = nodes.find((n) =>
    n.links.some((link) => link.childProcessId === nodeId)
  );
  if (parent) {
    parent.outcomes = parent.outcomes.filter((o) =>
      parent.links.some(
        (link) => link.childProcessId === nodeId && link.outcomeId === o.id
      )
    );
    return getPathToNode(parent.id, nodes, [...path, parent]);
  }

  return path;
};

export const getPathFromNode = (
  topology: TopologyProcess[],
  selectedNodeId: string
) => {
  const visited = new Set();
  const path: TopologyProcess[] = [];

  const visitNodes = (nodeId: string) => {
    visited.add(nodeId);

    const node = topology.find((p) => p.id === nodeId);
    if (node) {
      path.push(node);
    }

    const children = node ? node.links.map((link) => link.childProcessId) : [];

    for (const child of children) {
      if (!visited.has(child)) {
        visitNodes(child);
      }
    }
  };

  visitNodes(selectedNodeId);

  return path;
};

export const getSelectedNodePath = (
  topology: TopologyProcess[],
  selectedNodeId?: string
) =>
  selectedNodeId
    ? [
        ...getPathToNode(selectedNodeId, topology),
        ...getPathFromNode(topology, selectedNodeId),
      ]
    : [];
