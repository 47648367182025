import { z } from 'zod';
import { ZodiosEndpointDefinition, makeApi, makeEndpoint } from '@zodios/core';

import { createNodeSchema, processNodeSchema, updateNodeSchema } from '../node';
import {
  deprecatedCreateLoopProcessSchema,
  processSchema,
  updateProcessSchema,
} from '../process';
import { objectIdSchema, spektrFieldDetailsSchema } from '../common';

/**
 * TODO: Add errors
 */

const createLoop = makeEndpoint({
  method: 'post',
  path: '/loops',
  alias: 'createLoop',
  parameters: [
    {
      name: 'input',
      type: 'Body',
      schema: deprecatedCreateLoopProcessSchema,
    },
  ],
  response: processSchema,
});

const getAllLoops = makeEndpoint({
  method: 'get',
  path: '/loops',
  alias: 'getAllLoops',
  response: z.array(processSchema),
});

const getLoopById = makeEndpoint({
  method: 'get',
  path: '/loops/:id',
  alias: 'getLoopById',
  response: processSchema,
});

const updateLoopById = makeEndpoint({
  method: 'patch',
  path: '/loops/:id',
  alias: 'updateLoopById',
  parameters: [
    {
      name: 'input',
      type: 'Body',
      schema: updateProcessSchema,
    },
  ],
  response: processSchema,
});

const getNodeById = makeEndpoint({
  method: 'get',
  path: '/loops/:loopId/nodes/:nodeId',
  alias: 'getNodeById',
  parameters: [
    {
      name: 'loopId',
      type: 'Path',
      description: 'The id of the loop the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeId',
      type: 'Path',
      description: 'The id of the node to fetch',
      schema: objectIdSchema,
    },
  ],
  response: processNodeSchema,
});

const getAllowedSpektrfieldsForNode = makeEndpoint({
  method: 'get',
  path: '/loops/:loopId/nodes/:nodeId/fields',
  alias: 'getAllowedSpektrfieldsForNode',
  parameters: [
    {
      name: 'loopId',
      type: 'Path',
      description: 'The id of the loop the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeId',
      type: 'Path',
      description: 'The id of the node to fetch',
      schema: objectIdSchema,
    },
  ],
  response: z.array(spektrFieldDetailsSchema),
});

// TODO: find a way to avoid redundancy (see issue #478 for details)
export interface CreateLoopNodeEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'post';
  path: '/loops/:loopId/nodes';
  alias: 'createNode';
  parameters: [
    {
      name: 'loopId';
      type: 'Path';
      description: 'The id of the loop the node belongs to';
      schema: typeof objectIdSchema;
    },
    {
      name: 'nodeInput';
      type: 'Body';
      schema: typeof createNodeSchema;
    },
  ];
  response: typeof processNodeSchema;
}

const createNode: CreateLoopNodeEndpointDefinition = makeEndpoint({
  method: 'post',
  path: '/loops/:loopId/nodes',
  alias: 'createNode',
  parameters: [
    {
      name: 'loopId',
      type: 'Path',
      description: 'The id of the loop the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeInput',
      type: 'Body',
      schema: createNodeSchema,
    },
  ],
  response: processNodeSchema,
});

// TODO: find a way to avoid redundancy (see issue #478 for details)
export interface UpdateLoopNodeEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'put';
  path: '/loops/:loopId/nodes/:nodeId';
  alias: 'updateNodeById';
  parameters: [
    {
      name: 'loopId';
      type: 'Path';
      description: 'The id of the loop the node belongs to';
      schema: typeof objectIdSchema;
    },
    {
      name: 'nodeId';
      type: 'Path';
      description: 'The id of the node to update';
      schema: typeof objectIdSchema;
    },
    {
      name: 'nodeInput';
      type: 'Body';
      schema: typeof updateNodeSchema;
    },
  ];
  response: typeof processNodeSchema;
}
const updateNodeById: UpdateLoopNodeEndpointDefinition = makeEndpoint({
  method: 'put',
  path: '/loops/:loopId/nodes/:nodeId',
  alias: 'updateNodeById',
  parameters: [
    {
      name: 'loopId',
      type: 'Path',
      description: 'The id of the loop the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeId',
      type: 'Path',
      description: 'The id of the node to update',
      schema: objectIdSchema,
    },
    {
      name: 'nodeInput',
      type: 'Body',
      schema: updateNodeSchema,
    },
  ],
  response: processNodeSchema,
});

const deleteNodeById = makeEndpoint({
  method: 'delete',
  path: '/loops/:loopId/nodes/:nodeId',
  alias: 'deleteNodeById',
  parameters: [
    {
      name: 'loopId',
      type: 'Path',
      description: 'The id of the loop the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeId',
      type: 'Path',
      description: 'The id of the node to delete',
      schema: objectIdSchema,
    },
  ],
  response: z.void(),
});

export const loopApi = makeApi([
  createLoop,
  getAllLoops,
  getLoopById,
  updateLoopById,
  getNodeById,
  createNode,
  deleteNodeById,
  getAllowedSpektrfieldsForNode,
  updateNodeById,
]);
