import { ColumnDef } from '@tanstack/react-table';
import { Key, Tag } from 'lucide-react';

import { DataTable, Skeleton } from '@spektr/client/components';

export const SkeletonFieldsDataTable = () => {
  const columns: ColumnDef<{ rowKey: number }>[] = [
    {
      accessorKey: 'isIncluded',
      header: () => (
        <div className="flex flex-col items-center justify-center">
          <Tag className="h-[14px] w-[14px]" />
        </div>
      ),
      cell: () => <Skeleton className="mx-auto h-4 w-4" />,
    },
    {
      accessorKey: 'name',
      header: 'Attribute name',
      cell: () => <Skeleton className="h-[14px] w-[120px]" />,
    },
    {
      accessorKey: 'type',
      size: 140,
      header: () => <div className="mr-9 text-right">Data type</div>,
      cell: () => (
        <div className="ml-auto flex h-[35px] items-center justify-end">
          <Skeleton className="ml-auto h-6 w-[100px]" />
        </div>
      ),
    },
    {
      accessorKey: 'identifier',
      header: () => (
        <div className="flex items-center justify-center">
          <Key className="h-[14px] w-[14px] -scale-x-100" />
        </div>
      ),
      cell: () => (
        <Skeleton className="mx-auto h-[14px] w-[14px] rounded-full" />
      ),
    },
  ];

  const data = [
    {
      rowKey: 1,
    },
    {
      rowKey: 2,
    },
    {
      rowKey: 3,
    },
    {
      rowKey: 4,
    },
  ];

  return (
    <div className="relative h-[300px] w-full rounded-t">
      <DataTable columns={columns} data={data} />
    </div>
  );
};
