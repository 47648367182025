import { generatePath } from 'react-router-dom';
import { z } from 'zod';

export const INSIGHTS_URL = '/insights';
export const INSIGHTS_RECORDS = 'records';
export const INSIGHTS_RECORD_DETAILS = 'details';
export const INSIGHTS_DATASET_PARAM = ':datasetId';
export const INSIGHTS_RECORD_PARAM = ':recordId';
export const INSIGHTS_RECORD_RUNS = 'runs';
export const INSIGHTS_RECORD_ACTIONS = 'actions';
export const INSIGHTS_RECORD_OWNERSHIP_STRUCTURE = 'ownership';

export const INSIGHTS_ACTIONS = 'actions';
export const INSIGHTS_ACTION_PARAM = ':actionId';
export const INSIGHTS_ACTION_HITS = 'hits';
export const INSIGHTS_ACTION_HITS_PARAM = ':hitId';
export const INSIGHTS_ACTION_HIT_COMMENTS = 'comments';

export const INSIGHTS_RUNS = 'runs';
export const INSIGHTS_RUN_PARAM = ':spektrId';

export const INSIGHTS_ACTIONS_FULL_URL = `${INSIGHTS_URL}/${INSIGHTS_ACTIONS}`;
export const INSIGHTS_ACTION_FULL_URL = `${INSIGHTS_URL}/${INSIGHTS_ACTIONS}/${INSIGHTS_ACTION_PARAM}`;
export const INSIGHTS_ACTION_HITS_FULL_URL = `${INSIGHTS_ACTION_FULL_URL}/${INSIGHTS_ACTION_HITS}`;
export const INSIGHTS_ACTION_HIT_DETAILS_FULL_URL = `${INSIGHTS_ACTION_HITS_FULL_URL}/${INSIGHTS_ACTION_HITS_PARAM}`;
export const INSIGHTS_ACTION_HIT_COMMENTS_FULL_URL = `${INSIGHTS_ACTION_HITS_FULL_URL}/${INSIGHTS_ACTION_HITS_PARAM}/${INSIGHTS_ACTION_HIT_COMMENTS}`;

export const INSIGHTS_RUNS_FULL_URL = `${INSIGHTS_URL}/${INSIGHTS_RUNS}`;
export const INSIGHTS_RUN_DETAILS_FULL_URL = `${INSIGHTS_URL}/${INSIGHTS_RUNS}/${INSIGHTS_RUN_PARAM}`;

export const INSIGHTS_RECORD_DETAILS_URL = `${INSIGHTS_DATASET_PARAM}/${INSIGHTS_RECORD_DETAILS}/${INSIGHTS_RECORD_PARAM}`;

export const INSIGHTS_RECORDS_FULL_URL = `${INSIGHTS_URL}/${INSIGHTS_RECORDS}`;
export const INSIGHTS_RECORD_FULL_URL = `${INSIGHTS_RECORDS_FULL_URL}/${INSIGHTS_RECORD_DETAILS_URL}`;
export const INSIGHTS_RECORD_RUNS_FULL_URL = `${INSIGHTS_RECORD_FULL_URL}/${INSIGHTS_RECORD_RUNS}`;
export const INSIGHTS_RECORD_ACTIONS_FULL_URL = `${INSIGHTS_RECORD_FULL_URL}/${INSIGHTS_RECORD_ACTIONS}`;
export const INSIGHTS_RECORD_OWNERSHIP_STRUCTURE_FULL_URL = `${INSIGHTS_RECORD_FULL_URL}/${INSIGHTS_RECORD_OWNERSHIP_STRUCTURE}`;

export function insightsActionsUrl() {
  return INSIGHTS_ACTIONS_FULL_URL;
}

export function insightsRunUrl(spektrId: string) {
  return generatePath(INSIGHTS_RUN_DETAILS_FULL_URL, { spektrId });
}

export function insightsActionDetailsUrl(actionId: string) {
  return generatePath(INSIGHTS_ACTION_FULL_URL, { actionId });
}

export function insightsActionHitsUrl(actionId: string) {
  return generatePath(INSIGHTS_ACTION_HITS_FULL_URL, { actionId });
}

export function insightsActionHitDetailsUrl(actionId: string, hitId: string) {
  return generatePath(INSIGHTS_ACTION_HIT_DETAILS_FULL_URL, {
    actionId,
    hitId,
  });
}

export function insightsActionHitCommentsUrl(actionId: string, hitId: string) {
  return generatePath(INSIGHTS_ACTION_HIT_COMMENTS_FULL_URL, {
    actionId,
    hitId,
  });
}

export function insightsRecordDatasetIdUrl(datasetId: string) {
  return generatePath(
    `${INSIGHTS_RECORDS_FULL_URL}/${INSIGHTS_DATASET_PARAM}`,
    { datasetId }
  );
}

export function insightsRecordDetailsUrl(datasetId: string, recordId: string) {
  return generatePath(INSIGHTS_RECORD_FULL_URL, { datasetId, recordId });
}

export function insightsRecordRunsUrl(datasetId: string, recordId: string) {
  return generatePath(INSIGHTS_RECORD_RUNS_FULL_URL, { datasetId, recordId });
}

export function insightsRecordOwnershipStructureUrl(
  datasetId: string,
  recordId: string
) {
  return generatePath(INSIGHTS_RECORD_OWNERSHIP_STRUCTURE_FULL_URL, {
    datasetId,
    recordId,
  });
}

export function insightsRecordActionsUrl(datasetId: string, recordId: string) {
  return generatePath(INSIGHTS_RECORD_ACTIONS_FULL_URL, {
    datasetId,
    recordId,
  });
}

export const insightsRunParamsSchema = z.object({
  spektrId: z.string(),
});

export const insightsActionsParamsSchema = z.object({
  actionId: z.string(),
});

export const insightsActionHitParamsSchema = insightsActionsParamsSchema.merge(
  z.object({
    hitId: z.string(),
  })
);

export const insightsRecordDatasetParamsSchema = z.object({
  datasetId: z.string(),
});

export const insightsRecordDetailsParamsSchema = z.object({
  datasetId: z.string(),
  recordId: z.string(),
});
