import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  ACTIONS_KEYS,
  DATASETS_KEYS,
  MoneypennyApiClient,
} from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';

import { SpektrAISearchMutationArg } from '../types';

export function useSpektrAISearch(
  actionId: string,
  datasetId: string,
  spektrId: string
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ hitId }: SpektrAISearchMutationArg) => {
      return MoneypennyApiClient.getClient().performSpektrAISearchOnHit(
        undefined,
        {
          params: {
            actionId: actionId,
            hitId: hitId,
          },
        }
      );
    },
    onSuccess: async () => {
      await invalidateMultipleQueries(queryClient, [
        {
          queryKey: ACTIONS_KEYS.BY_ID(actionId),
        },
        {
          queryKey: DATASETS_KEYS.ALL_ACTIONS(datasetId, spektrId),
        },
      ]);
    },
  });
}
