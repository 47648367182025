import { z } from 'zod';

export const EmailTemplate = z.strictObject({
  // 'subject' will be shown in the subject line of the email
  subject: z.string().min(1),
  // 'text' will be shown in the body of the email if the recipient's email client does not support HTML.
  text: z.string().min(1),
  // 'body' will be shown in the body of the email if the recipient's email client supports HTML (which is the case for basically all email clients nowadays)
  body: z.string().min(1),
});

export type EmailTemplate = z.infer<typeof EmailTemplate>;

export const ProcessSettingsResponse = z.strictObject({
  emailTemplate: EmailTemplate,
});
export type ProcessSettingsResponse = z.infer<typeof ProcessSettingsResponse>;

export const ProcessSettingsUpsert = z.strictObject({
  emailTemplate: EmailTemplate,
});

export type ProcessSettingsUpsert = z.infer<typeof ProcessSettingsUpsert>;
