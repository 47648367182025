import { z } from 'zod';

import { objectIdSchema } from '../common';

export const apiKeyBase = z.object({
  key: z.string().default(''),
  keyType: z.string().optional(),
});

export type VendorApiKeyCreate = z.infer<typeof apiKeyBase>;

export const apiKeySchema = apiKeyBase.merge(
  z.strictObject({
    id: objectIdSchema.optional(),
  })
);
export type VendorApiKey = z.infer<typeof apiKeySchema>;

export const vendorSettingsCreateSchema = z.object({
  vendor: z.string().min(1),
  apiKey: apiKeyBase.optional(),
});
export type VendorSettingsCreate = z.infer<typeof vendorSettingsCreateSchema>;

export const vendorSettingsBaseSchema = z.object({
  vendor: z.string().min(1),
  apiKey: apiKeySchema.optional(),
  createdBy: z.string(),
  updatedBy: z.string(),
});

export type VendorSettingsBase = z.infer<typeof vendorSettingsBaseSchema>;

export const vendorSettingsSchema = vendorSettingsBaseSchema.merge(
  z.strictObject({
    id: objectIdSchema.optional(),
    createdAt: z.number().optional(),
    updatedAt: z.number().optional(),
    workspaceId: z.string(),
  })
);
export type VendorSettings = z.infer<typeof vendorSettingsSchema>;

export const VendorSettingsConnUpdateSchema = z.object({ apiKey: apiKeyBase });
export type VendorSettingsConnUpdate = z.infer<
  typeof VendorSettingsConnUpdateSchema
>;

export const VendorSettingsFilterBodySchema = z.union([
  z.literal('all'),
  z.literal('configured'),
  z.literal('notConfigured'),
]);
export type VendorSettingsFilterBody = z.infer<
  typeof VendorSettingsFilterBodySchema
>;
