import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';

type ServiceFieldsListSkeletonProps = {
  title?: string;
  rowsCount?: number;
};

export const ServiceFieldsListSkeleton = ({
  title = '',
  rowsCount = 3,
}: ServiceFieldsListSkeletonProps) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="text-color-text-subtext text-xs font-medium">{title}</p>
      <Table className="rounded-md border">
        <TableBody>
          {[...Array(rowsCount)].map((_, index) => (
            <TableRow key={index}>
              <TableCell className="w-[160px] p-4">
                <Skeleton className="h-3 w-full" />
              </TableCell>
              <TableCell className="p-4">
                <Skeleton className="h-3 w-full max-w-64" />
              </TableCell>
              <TableCell className="w-[200px] p-4">
                <Skeleton className="h-3 w-full" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
