import { ActionsApiClient } from './client';

import { ACTIONS_KEYS } from './query.keys';

import { GetAllActionsFilters, GetAllHitsFilters } from './types/Filters';

export function getAllActions(filters: GetAllActionsFilters) {
  return {
    queryKey: ACTIONS_KEYS.ALL(filters),
    queryFn: () =>
      ActionsApiClient.getClient().getAllActions({
        queries: {
          page: filters?.page ?? 1,
          limit: filters?.limit ?? 10,
          status: filters?.status ?? 'pending',
        },
      }),
  };
}

export function getActionById(actionId: string) {
  return {
    queryKey: ACTIONS_KEYS.BY_ID(actionId),
    queryFn: () =>
      ActionsApiClient.getClient().getActionById({
        params: {
          actionId,
        },
      }),
  };
}

export function getActionHits(actionId: string, filters: GetAllHitsFilters) {
  return {
    queryKey: ACTIONS_KEYS.ALL_HITS(actionId, filters),
    queryFn: () =>
      ActionsApiClient.getClient().getActionHits({
        params: {
          actionId,
        },
        queries: {
          page: filters.page ?? 1,
          limit: filters.limit ?? 10,
          field: filters.field,
        },
      }),
  };
}

export function getActionHitById(actionId: string, hitId: string) {
  return {
    queryKey: ACTIONS_KEYS.HIT_BY_ID(actionId, hitId),
    queryFn: () =>
      ActionsApiClient.getClient().getActionHitById({
        params: {
          actionId,
          hitId,
        },
      }),
  };
}
