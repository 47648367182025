import { Suspense } from 'react';

import { StickyVideoContainer } from '@spektr/client/components';
import { useDemo } from '@spektr/client/providers';

import { Connections } from './containers/Connections';
import { ExtractDatasetsFromSource } from './containers/ExtractDatasetsFromSource';

import { SkeletonDatasetList } from './components/SkeletonDatasetList';

export const ConnectionHubDataSources = () => {
  const { isDemo } = useDemo();

  return (
    <div className="mt-3 flex flex-col gap-6 overflow-y-auto">
      <Connections />

      <div className="flex flex-col gap-4">
        <p className="text-lg font-medium leading-none">Your datasets</p>
        <Suspense fallback={<SkeletonDatasetList />}>
          <ExtractDatasetsFromSource />
        </Suspense>
      </div>

      {isDemo && (
        <StickyVideoContainer
          title="Check out our video guide"
          source="https://platform.demo.spektr.com/videos/connection_hub.mp4"
          avatar="bg-avatar-jeremy"
        />
      )}
    </div>
  );
};
