import { z } from 'zod';

import { BaseField } from './BaseField';
import { FieldValidation } from './FieldValidation';

import { InputField } from './InputField';
import { DropdownField } from './DropdownField';
import { CheckboxField } from './CheckboxField';
import { FileField } from './FileField';
import { DateField } from './DateField';
import { CurrencyField } from './CurrencyField';
import { TitleField } from './TitleField';
import { ParagraphField } from './ParagraphField';
import { DividerField } from './DividerField';

// ? We are not using Field from './Field' because it will lead to circular dependency
export const EntitiesField = BaseField.merge(
  z.object({
    type: z.literal('entity'),
    attributes: z.object({
      label: z.string(),
      helperText: z.string().optional(),
      buttonText: z.string(),
      labelField: z.string().optional(),
    }),
    validation: z.array(FieldValidation).default([]),
    form: z.object({
      fields: z.record(
        z.string(),
        z.union([
          DateField,
          CurrencyField,
          InputField,
          DropdownField,
          CheckboxField,
          FileField,
          DropdownField,
          TitleField,
          ParagraphField,
          DividerField,
        ])
      ),
      order: z.array(z.string()),
    }),
  })
);

export type EntitiesField = z.infer<typeof EntitiesField>;

export function isEntitiesField(field: unknown): field is EntitiesField {
  return EntitiesField.safeParse(field).success;
}
