import { Node, Edge } from 'reactflow';

import { TopologyProcess } from '@spektr/shared/types';

import {
  createSourceGraphNode,
  createBasicGraphNode,
  createUnlinkedOutcomeGraphNode,
  createSegmentEdge,
  createLeafEdge,
} from './creators';

const isOutcomeLinkedToChildProcess = (
  process: TopologyProcess,
  outcomeId: string
) => process.links.some((link) => link.outcomeId === outcomeId);

const hasParentProcess = (processes: TopologyProcess[], processId: string) =>
  processes.some((p) =>
    p.links.some((link) => link.childProcessId === processId)
  );

export const generateGraph = (
  processes: TopologyProcess[]
): { nodes: Node[]; edges: Edge[] } => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  // Create the source node
  nodes.push(createSourceGraphNode());

  // Create nodes
  processes.forEach((process) => {
    nodes.push(createBasicGraphNode(process));

    // Create edges to connect the process to its parent process
    if (!hasParentProcess(processes, process.id)) {
      edges.push(createLeafEdge(process.id));
    }

    // Create segment edges to connect the process to its child process
    process.links.forEach((link) => {
      const outcome = process.outcomes.find(
        (outcome) => outcome.id === link.outcomeId
      );
      if (outcome) {
        const outcomeEdge = createSegmentEdge(
          process.id,
          link.childProcessId,
          outcome
        );

        if (edges.findIndex((edge) => edge.id === outcomeEdge.id) === -1) {
          edges.push(outcomeEdge);
        }
      }
    });

    // Create unlinked outcome nodes(not connected to a child node)
    process.outcomes.forEach((outcome) => {
      const isLinked = isOutcomeLinkedToChildProcess(process, outcome.id);

      if (!isLinked) {
        nodes.push(
          createUnlinkedOutcomeGraphNode({
            ...outcome,
            processType: process.type,
          })
        );

        // Create edges to connect the unlinked outcome node to the process
        edges.push(createSegmentEdge(process.id, outcome.id, outcome));
      }
    });
  });

  return { nodes, edges };
};
