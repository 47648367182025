import { cn } from '@spektr/client/utils';

import { NodeUpdateInput, SpektrField } from '@spektr/shared/types';
import { RBAC } from '@spektr/shared/rbac';

import {
  NodeDetailsDialogTitle,
  TitleInput,
  RuleMenuComboBox,
} from '@spektr/shared/components';

import { usePermissionsContext } from '@spektr/client/providers';

import {
  Button,
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogDescription,
  DialogHeader,
  RouterIcon,
} from '@spektr/client/components';

import { RuleGroupProvider, useIncompleteRouterNode } from './providers';
import { RoutingRule } from './router';

export type RouterNodeFormDialogContentProps = DialogContentProps & {
  spektrFields: SpektrField[];
  onUpdate: (data: NodeUpdateInput) => void;
  isPendingUpdate: boolean;
};

export const RouterNodeFormDialogContent = ({
  className,
  spektrFields,
  requestStartExitAnimation,
  onUpdate,
  isPendingUpdate,
  ...props
}: RouterNodeFormDialogContentProps) => {
  const {
    incomplete,
    routerNode,
    updateRuleGroup,
    removeRuleGroup,
    setTitle,
    addRuleGroup,
  } = useIncompleteRouterNode();
  const { hasPermission } = usePermissionsContext();

  return (
    <DialogContent
      modal={false}
      size="large"
      className={cn('flex flex-col', className)}
      requestStartExitAnimation={requestStartExitAnimation}
      {...props}
    >
      <DialogHeader>
        <NodeDetailsDialogTitle
          left={
            <div className="flex items-center gap-2">
              <RouterIcon iconClassName="h-4 w-4" />
              <TitleInput title={incomplete.title} onChange={setTitle} />
            </div>
          }
          right={
            <div className="flex justify-center gap-2">
              <Button
                color="cyan"
                type="button"
                size="sm"
                disabled={
                  !routerNode.success ||
                  isPendingUpdate ||
                  !hasPermission(RBAC.ACTIONS.NODE.UPDATE)
                }
                onClick={() => {
                  if (!routerNode.success) return;

                  onUpdate(routerNode.data);
                }}
              >
                {isPendingUpdate ? 'Saving...' : 'Save'}
              </Button>
              <DialogClose onClick={requestStartExitAnimation} />
            </div>
          }
        />
        {incomplete.groups.length === 0 && (
          <div className="pb-4">
            <DialogDescription>
              The Routing step is where the process branch out to different
              outcomes. Branches and subsequent outcomes are defined here by
              creating risk score thresholds. The Routing step sums all scores
              accumulated in prior Calculation steps and compares them to the
              defined thresholds.
            </DialogDescription>
          </div>
        )}
      </DialogHeader>
      <div className="-mr-3 flex flex-col gap-4 overflow-y-auto">
        {incomplete.groups.map((group) => (
          <RuleGroupProvider
            key={group.clientSideOnlyId}
            spektrFields={spektrFields}
            group={group}
            icon={(ruleId, innerGroupId) => (
              <RuleMenuComboBox
                className="mr-1"
                ruleId={ruleId}
                innerGroupId={innerGroupId}
              />
            )}
            onChange={(updated) => {
              if (updated) updateRuleGroup(updated);
              else removeRuleGroup(group.clientSideOnlyId);
            }}
          >
            <div className="mr-3">
              <RoutingRule />
            </div>
          </RuleGroupProvider>
        ))}
        <div className="mr-3">
          <div className="rounded-radius flex w-full justify-center border border-dashed py-5">
            <Button
              className="bg-color-bg-button-secondary hover:bg-color-bg-button-secondary active:bg-color-bg-button-secondary--hover text-color-text-button-primary"
              size="lg"
              onClick={addRuleGroup}
              children="Add Rule Group"
            />
          </div>
        </div>
      </div>
    </DialogContent>
  );
};
