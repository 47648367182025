import { useMemo } from 'react';
import { produce } from 'immer';

import { type SelectOption } from '@spektr/client/components';

import {
  isEntitiesField,
  isWritableField,
  type Field,
} from '@spektr/moonraker-types';
import { type SpektrField } from '@spektr/shared/types';

import { useFormEngine } from '../../../../hooks/useFormEngine';
import { useSpektrFields } from '../../../../hooks/useSpektrFields';

import { ComboboxField, SwitchField } from '../fields';

export type ConfigContentProps = {
  currentField: Field;
  spektrFields: SpektrField[];
};

export const ConfigContent = ({
  currentField,
  spektrFields,
}: ConfigContentProps) => {
  const { state, updateField } = useFormEngine();
  const customSpektrFields = useSpektrFields(state.fields, spektrFields);

  const spektrFieldsOptions = useMemo<SelectOption[]>(() => {
    return spektrFields.map((field) => ({
      value: field.key,
      label: field.label,
    }));
  }, [spektrFields]);
  const customSpektrFieldsOptions = useMemo<SelectOption[]>(() => {
    return customSpektrFields.map((field) => ({
      value: field,
      label: field,
    }));
  }, [customSpektrFields]);

  const handleUpdateField = (name: string, value: string) => {
    const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];
    let fieldToBeUpdated = currentField;

    if (fieldId) {
      fieldToBeUpdated = state.fields[rootFieldId];
    }

    if (!fieldToBeUpdated) {
      return;
    }

    const updatedField = produce(fieldToBeUpdated, (draft) => {
      if (isEntitiesField(draft)) {
        const field = draft.form.fields[fieldId];

        if (field && isWritableField(field)) {
          if (name === 'spektrDataField') {
            field.config.spektrDataField = value;
            return;
          }
        }
      }

      if (isWritableField(draft)) {
        if (name === 'spektrDataField') {
          draft.config.spektrDataField = value;
        }
        return;
      }
    });
    updateField(updatedField);
  };

  const handleChangePrefill = (value: boolean) => {
    const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];
    let fieldToBeUpdated = currentField;

    if (fieldId) {
      fieldToBeUpdated = state.fields[rootFieldId];
    }

    if (!fieldToBeUpdated) {
      return;
    }

    const updatedField = produce(fieldToBeUpdated, (draft) => {
      if (isEntitiesField(draft)) {
        const field = draft.form.fields[fieldId];

        if (field && isWritableField(field)) {
          field.config.prefillDefaultValue = value;
          return;
        }
      }

      if (isWritableField(draft)) {
        draft.config.prefillDefaultValue = value;
      }
    });

    updateField(updatedField);
  };

  const handleChangeRequired = (value: boolean) => {
    const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];
    let fieldToBeUpdated = currentField;

    if (fieldId) {
      fieldToBeUpdated = state.fields[rootFieldId];
    }

    if (!fieldToBeUpdated) {
      return;
    }

    const updatedField = produce(fieldToBeUpdated, (draft) => {
      if (isEntitiesField(draft)) {
        const field = draft.form.fields[fieldId];

        if (field && isWritableField(field)) {
          if (value) {
            field.validation.push({
              type: 'required',
              value: true,
              message: 'This field is required',
            });
          } else {
            field.validation = field.validation.filter(
              (validation) => validation.type !== 'required'
            );
          }
        }
      }

      if (isWritableField(draft)) {
        if (value) {
          draft.validation.push({
            type: 'required',
            value: true,
            message: 'This field is required',
          });
        } else {
          draft.validation = draft.validation.filter(
            (validation) => validation.type !== 'required'
          );
        }
      }
    });

    updateField(updatedField);
  };
  const hasConfig = isWritableField(currentField);

  if (!hasConfig) {
    return null;
  }

  return (
    <>
      <ComboboxField
        key={currentField.id}
        defaultValue={currentField.config.spektrDataField ?? ''}
        onChange={(value) => handleUpdateField('spektrDataField', value)}
        label="Custom data field"
        name="spektrDataField"
        helperText="Spektr value"
        inputProps={{
          placeholder: 'Select or create a custom data field',
          dimension: 'default',
        }}
        options={spektrFieldsOptions}
        customOptions={customSpektrFieldsOptions}
      />
      <div className="mt-2 flex flex-col gap-3">
        <SwitchField
          checked={currentField.config?.prefillDefaultValue}
          label="Pre-fill if data is available"
          onCheckedChange={handleChangePrefill}
        />
        <SwitchField
          checked={
            currentField.validation.findIndex(
              (rule) => rule.type === 'required'
            ) >= 0
          }
          label="Field is required to fill out"
          onCheckedChange={handleChangeRequired}
        />
      </div>
    </>
  );
};
