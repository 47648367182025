import { createContext, Dispatch, SetStateAction } from 'react';
import { Edge, Node } from 'reactflow';

export type SetHistoryFunction = Dispatch<SetStateAction<HistoryItem[]>>;

export type HistoryItem = {
  nodes: Node[];
  edges: Edge[];
};

export type UndoRedoContextType = {
  past: HistoryItem[];
  future: HistoryItem[];
  setPast: SetHistoryFunction;
  setFuture: SetHistoryFunction;
};

const noop: SetHistoryFunction = () => {
  return;
};

const emptyHistory: HistoryItem[] = [];

export const UndoRedoContext = createContext({
  past: emptyHistory,
  future: emptyHistory,
  setPast: noop,
  setFuture: noop,
});
