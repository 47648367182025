import { useParsedParams } from '@spektr/shared/hooks';
import {
  processDetailsParamsSchema,
  loopDetailsUrl,
} from '@spektr/shared/utils';

import { StrategyDialog } from '../StrategyDialog';

export const LoopStrategyDialog = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);

  return (
    <StrategyDialog
      processId={processId}
      processUrl={loopDetailsUrl(processId)}
    />
  );
};
