import { useMemo } from 'react';
import { ArrowUpDown, FileText, SearchCode } from 'lucide-react';

import { NodeType, ProcessType } from '@spektr/shared/validators';

import {
  CalculatorIcon,
  IconWithBackground,
  RouterIcon,
} from '@spektr/client/components';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { DrawerItemType } from '../types/DrawerItemType';
import { getAvailableItemsForProcess } from '../utils/getAvailableItemsForProcess';

export const useSteps = (processType?: ProcessType): DrawerItemType[] => {
  const { returningProcess, monitoringDataset } = useFeatureFlags();

  return useMemo(() => {
    const steps: DrawerItemType[] = [
      {
        type: NodeType.enum.actionForm,
        title: 'Create a form',
        variant: 'red',
        icon: (
          <IconWithBackground className="bg-color-red">
            <FileText className="stroke-color-stroke-white h-6 w-6" />
          </IconWithBackground>
        ),
        description:
          'Create a shareable form and get the details you need to be compliant',
      },
      {
        type: NodeType.enum.calculation,
        icon: (
          <IconWithBackground className="bg-color-cyan/10">
            <CalculatorIcon className="stroke-color-cyan h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Calculation step',
        variant: 'cyan' as const,
        description:
          'Create conditional rules and segment information into groups for later routing.',
      },
      {
        type: NodeType.enum.router,
        icon: (
          <RouterIcon
            className="bg-color-red/10"
            iconClassName="h-6 w-6 stroke-color-red"
          />
        ),
        title: 'Routing step',
        variant: 'red' as const,
        description:
          'Create rules that take previous steps and routes to outcomes or branches.',
      },
      {
        type: NodeType.enum.returningProcess,
        icon: (
          <IconWithBackground className="bg-color-cyan/10">
            <ArrowUpDown className="stroke-color-cyan h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Returning process step',
        variant: 'cyan' as const,
        description:
          'Calls another process to obtain its outcome and integrates the result into the current process.',
        hidden: !returningProcess,
      },
      {
        type: NodeType.enum.monitoringDataset,
        icon: (
          <IconWithBackground className="bg-color-cyan/10">
            <SearchCode className="stroke-color-cyan h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Field watch',
        variant: 'cyan' as const,
        description:
          'This step monitors a specific field in the dataset and triggers the process if any changes occur.',
        hidden: !monitoringDataset,
      },
    ];

    return getAvailableItemsForProcess(steps, processType);
  }, [processType, returningProcess, monitoringDataset]);
};
