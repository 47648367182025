import { useCallback, useEffect, useState } from 'react';
import { z } from 'zod';

import { Button, Input, Label } from '@spektr/client/components';
import { decodeSearchParams } from '@spektr/client/utils';

import { AuthError } from '../../errors/AuthError';

type LoginPropsType = {
  logIn: (email: string, password: string) => Promise<void>;
  onLoginSuccess: () => void;
};

const loginSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string(),
});

export function Login({ onLoginSuccess, logIn }: LoginPropsType) {
  const searchParams = decodeSearchParams(['email', 'pwd']);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const loginClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = loginSchema.safeParse({ email, password });
    if (!result.success) {
      const formatted = result.error.format();
      const firstEmailError = formatted.email?._errors.at(0);

      setError(firstEmailError ?? 'An error occurred. Please try again');
      return;
    }

    handleLogin(email, password);
  };

  const handleLogin = useCallback(async (email: string, password: string) => {
    try {
      setIsLoading(true);
      await logIn(email, password);
      onLoginSuccess();
    } catch (e) {
      setError(new AuthError(e).message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (searchParams?.['email'] && searchParams['pwd']) {
      handleLogin(searchParams['email'], searchParams['pwd']);
    }
  }, [handleLogin, searchParams?.['email'], searchParams?.['pwd']]);

  return (
    <form
      method="post"
      onSubmit={loginClick}
      onChange={() => {
        setError('');
      }}
    >
      <div className="flex w-full flex-col gap-3">
        <div>
          <Label className="text-sm" htmlFor="email">
            Email
          </Label>
          <Input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <Label className="text-sm" htmlFor="password">
            Password
          </Label>
          <Input
            id="password"
            name="password"
            type="password"
            autoComplete="new-password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <div className="text-color-red text-sm">{error}</div>}
      </div>
      <Button
        color="primary"
        type="submit"
        size="lg"
        className="mt-6 mb-2 w-full"
        disabled={isLoading || !email || !password}
      >
        {isLoading ? 'Logging in...' : 'Log in'}
      </Button>
    </form>
  );
}
