import { CirclePlay, ListTodo, Users } from 'lucide-react';

import {
  INSIGHTS_ACTIONS_FULL_URL,
  INSIGHTS_RECORDS_FULL_URL,
  INSIGHTS_RUNS_FULL_URL,
} from '@spektr/shared/utils';

import {
  NotificationBadge,
  Tabbar,
  TabbarItem,
} from '@spektr/client/components';

export type InsightsTabNavigationProps = {
  unresolvedActions?: number;
};

export const InsightsTabNavigation = ({
  unresolvedActions = 0,
}: InsightsTabNavigationProps) => {
  return (
    <div className="mb-4">
      <Tabbar>
        <TabbarItem
          icon={<Users className="h-4 w-4" />}
          to={INSIGHTS_RECORDS_FULL_URL}
        >
          Customers
        </TabbarItem>
        {unresolvedActions > 0 ? (
          <NotificationBadge badgeContent={unresolvedActions}>
            <TabbarItem
              icon={<ListTodo className="h-4 w-4" />}
              to={{ pathname: INSIGHTS_ACTIONS_FULL_URL }}
            >
              Actions
            </TabbarItem>
          </NotificationBadge>
        ) : (
          <TabbarItem
            icon={<ListTodo className="h-4 w-4" />}
            to={{ pathname: INSIGHTS_ACTIONS_FULL_URL }}
          >
            Actions
          </TabbarItem>
        )}
        <TabbarItem
          icon={<CirclePlay className="h-4 w-4" />}
          to={{ pathname: INSIGHTS_RUNS_FULL_URL }}
        >
          Runs
        </TabbarItem>
      </Tabbar>
    </div>
  );
};
