import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getAllActionsForRecord,
  getProcessesQuery,
} from '@spektr/client/services';
import { PaginationOptions } from '@spektr/shared/validators';

export const useGetRecordActions = (
  recordId: string,
  datasetId: string,
  filters?: PaginationOptions
) => {
  const { data: actions } = useSuspenseQuery({
    ...getAllActionsForRecord(recordId, datasetId, filters),
    refetchInterval: (data) => (data.state.data?.docs.length ? false : 10000),
  });
  const { data: processes } = useSuspenseQuery(getProcessesQuery());

  const parsedActions = useMemo(
    () =>
      actions.docs.map((action) => {
        const process = processes.find(
          (process) => process.id === action.processId
        );
        const node = process?.nodes.get(action.nodeId);

        return {
          id: action.id,
          processName: process?.name ?? '',
          processType: process?.type,
          sourceName: (node as any)?.title ?? '',
          sourceType: node?.nodeType,
          createdAt: action.createdAt,
          hitsCount: action.hits.length,
          resolvedHitsCount: action.hits.reduce((acc, hit) => {
            if (hit.decision.status !== 'pending') {
              return acc + 1;
            }
            return acc;
          }, 0),
        };
      }),
    [actions, processes]
  );

  return useMemo(
    () => ({ actions: parsedActions, totalPages: actions.totalPages }),
    [parsedActions, actions.totalPages]
  );
};
