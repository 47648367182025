import { z } from 'zod';

import {
  BrandingFormAssistingContent,
  BrandingFormTheme,
} from '@spektr/branding-types';
import { type SlateDescendant } from '@spektr/shared/slate-types';

import { Field } from './Field';

// TODO: (@Robert) - Add layout schema
export const MoonrakerForm = z.object({
  fields: z.record(z.string(), Field),
  order: z.array(z.string()),
  branding: BrandingFormTheme.optional(),
  assistingContent: BrandingFormAssistingContent.optional(),
});
export type MoonrakerForm = z.infer<typeof MoonrakerForm>;

export type DeserializedBrandingFormAssistingContent = {
  title: string;
  description: SlateDescendant[];
  support: {
    display: boolean;
    title: string;
    content: SlateDescendant[];
    backgroundColor: string;
    textColor: string;
  };
};

export type DeserializedMoonrakerForm = {
  fields: Record<string, Field>;
  order: string[];
  branding?: BrandingFormTheme;
  assistingContent?: DeserializedBrandingFormAssistingContent;
};
