import { useMemo } from 'react';
import { useMeasure } from 'react-use';

import { ScrollArea } from '@spektr/client/components';

import { useSuspenseQuery } from '@tanstack/react-query';

import { useGetUserById } from '@spektr/platform-hooks';

import {
  connectionDataSetByIdQuery,
  getRecordBySpektrId,
} from '@spektr/client/services';

import { SidebarHeader } from '../../components/SidebarHeader';
import { SidebarDatasetData } from '../../components/SidebarDatasetData';
import { SidebarDetailsTable } from '../../components/SidebarDetailsTable';
import { getRecordIdentifierValue } from '../../utils';

export type RecordDetailsPageSidebarProps = {
  datasetId: string;
  spektrId: string;
};

export const RecordDetailsPageSidebar = ({
  datasetId,
  spektrId,
}: RecordDetailsPageSidebarProps) => {
  const [ref, size] = useMeasure<HTMLDivElement>();

  const { data: dataset } = useSuspenseQuery(
    connectionDataSetByIdQuery(datasetId)
  );

  const { data: recordData } = useSuspenseQuery(
    getRecordBySpektrId(datasetId, spektrId)
  );

  const data = useMemo(() => {
    return Object.entries(recordData)
      .filter((entity) => entity[0] !== 'spektrId')
      .map(([key, value]) => {
        return [key, value as string];
      });
  }, [recordData]);

  const user = useGetUserById(dataset.createdBy || '');

  return (
    <div ref={ref} className="relative h-full w-full">
      <ScrollArea
        style={{
          height: size.height,
        }}
      >
        <div
          className="flex flex-col gap-6 p-6"
          data-cy="record-details-sidebar-data-cy"
        >
          <SidebarHeader
            firstName={getRecordIdentifierValue(recordData, dataset)}
            lastName=""
            recordName={getRecordIdentifierValue(recordData, dataset)}
            addedAt={dataset.createdAt}
          />
          <SidebarDatasetData
            datasetName={dataset.name}
            firstName={user?.firstName || ''}
            lastName={user?.lastName || ''}
          />
          <SidebarDetailsTable data={data} />
        </div>
      </ScrollArea>
    </div>
  );
};
