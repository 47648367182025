import { useMemo, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { produce } from 'immer';

import { updateFieldsFactory } from '@spektr/client/utils';

import { Button } from '@spektr/client/components';

import {
  ServiceNode as ServiceNodeType,
  SpektrFieldKey,
  SpektrField,
  TriggerFrequency,
  complyAdvantageKybFields,
  complyAdvantageKycFields,
  openCorporatesMonitoringFields,
  openCorporatesRiskFields,
  NodeUpdateInput,
  bodaccFields,
  kyckrFields,
  mitIdFields,
} from '@spektr/shared/types';
import { ClientTrigger } from '@spektr/client/types';

import { SERVICE_VARIANT_MAP } from '../../constants/services';

import { getIdentifiers } from '../../utils/getIdentifiers';
import { mapSpektrFieldToServiceField } from '../../utils/mapSpektrFieldToServiceField';
import { getServiceNodeDialogInformation } from '../../utils/getServiceNodeDialogInformation';
import { getServiceFieldsWithoutProperties } from '../../utils/getServiceFieldsWithoutProperties';
import { keepCommonField } from '../../utils/kyckr';

import { SectionList } from '../../components/SectionList';
import { IdentifierItem } from '../../components/IdentifierItem';
import { SectionWithTitle } from '../../components/SectionWithTitle';
import { ServiceItemWithFrequency } from '../../components/ServiceItemWithFrequency';
import { KyckrServiceFields } from '../../components/KyckrServiceFieldsWithFrequency';

import { ServiceFieldClient } from '../../types/ServiceFieldClient';

export type ServiceFieldsWithFrequencyListProps = {
  node: ServiceNodeType;
  nodeId: string;
  processId: string;
  spektrFields: SpektrField[];
  triggers: ClientTrigger[];
  onSave: (data: NodeUpdateInput, triggers: ClientTrigger[]) => void;
};

export const ServiceFieldsWithFrequencyList = ({
  node,
  nodeId,
  processId,
  spektrFields,
  triggers: propsTriggers,
  onSave,
}: ServiceFieldsWithFrequencyListProps) => {
  const { getValues, control } = useFormContext();
  const { isValid } = useFormState({
    control,
  });

  const [triggers, setTriggers] = useState(propsTriggers);

  const { attributesTitle, attributesHelp, categoriesTitle, categoriesHelp } =
    useMemo(
      () => getServiceNodeDialogInformation(node.nodeType, 'monitoring'),
      [node.nodeType]
    );

  const fields = useMemo(() => {
    switch (node.nodeType) {
      case 'complyAdvantageKyb':
        return mapSpektrFieldToServiceField(node, complyAdvantageKybFields);
      case 'complyAdvantageKyc':
        return mapSpektrFieldToServiceField(node, complyAdvantageKycFields);
      case 'openCorporatesMonitoring':
        return mapSpektrFieldToServiceField(
          node,
          openCorporatesMonitoringFields
        );
      case 'openCorporatesRisk':
        return mapSpektrFieldToServiceField(node, openCorporatesRiskFields);
      case 'bodacc':
        return mapSpektrFieldToServiceField(node, bodaccFields);
      case 'kyckr':
        return mapSpektrFieldToServiceField(node, kyckrFields);
      case 'mitId':
        return mapSpektrFieldToServiceField(node, mitIdFields);
      default:
        return [];
    }
  }, [node]);

  const updateFrequency = (
    triggers: ClientTrigger[],
    frequency: TriggerFrequency | undefined,
    fieldKey: SpektrFieldKey,
    kyckrKey?: SpektrFieldKey
  ) => {
    const triggersWithField = triggers.filter((trigger) =>
      trigger.fields?.includes(fieldKey)
    );

    if (triggersWithField.length > 0) {
      triggersWithField.forEach((currentTrigger) => {
        if (currentTrigger && currentTrigger.frequency !== frequency) {
          currentTrigger.fields = currentTrigger.fields?.filter(
            (currentField) => {
              const isFound =
                currentField === fieldKey ||
                currentField.startsWith(`${fieldKey}_fields.`);

              if (isFound) {
                return keepCommonField(
                  fieldKey,
                  currentTrigger,
                  fields,
                  kyckrKey
                );
              }

              return !isFound;
            }
          );
        }
      });
    }

    if (!frequency) {
      return;
    }

    const currentFrequencyTrigger = triggers.find(
      (trigger) => trigger.frequency === frequency
    );

    const fieldsWithProperties = fields
      .map((field) => field.key)
      .filter((key) => key.startsWith(`${fieldKey}_fields.`));

    if (currentFrequencyTrigger) {
      const isFieldAlreadyAdded =
        currentFrequencyTrigger.fields?.includes(fieldKey);

      if (!isFieldAlreadyAdded) {
        currentFrequencyTrigger.fields?.push(...fieldsWithProperties, fieldKey);
      }
    } else {
      const isFieldAlreadyAdded = triggers.some(
        (trigger) =>
          trigger.frequency === frequency && trigger.fields?.includes(fieldKey)
      );

      if (!isFieldAlreadyAdded) {
        triggers.push({
          nodeId,
          processId,
          frequency,
          fields: [...fieldsWithProperties, fieldKey],
        });
      }
    }

    return triggers;
  };

  const handleChangeFrequency = (
    fieldKey: SpektrFieldKey,
    frequency: TriggerFrequency | undefined
  ) => {
    setTriggers(
      produce(triggers, (draft) => {
        updateFrequency(draft, frequency, fieldKey);
      })
    );
  };

  const handleChangeFrequencies = (
    kyckrKey: SpektrFieldKey,
    fieldKeys: SpektrFieldKey[],
    frequency: TriggerFrequency | undefined
  ) => {
    setTriggers(
      produce(triggers, (draft) => {
        fieldKeys.forEach((fieldKey) => {
          updateFrequency(draft, frequency, fieldKey, kyckrKey);
        });
      })
    );
  };

  const handleSave = () => {
    const mapping = getValues();
    const hasFrequencyOnAnyField = !!triggers.some((trigger) =>
      fields.some((field) => trigger.fields?.includes(field.key))
    );

    const fieldsMap = fields.reduce<Record<string, boolean>>((acc, field) => {
      // When a "main" spektrField has a frequency set (e.g. has_sanctions), all it's subfields
      // (e.g. has_sanctions_fields.*) should have that frequency set as well
      const fieldStartingKey = field.key.split('_fields.').shift() as string;
      const hasFrequency = !!triggers.find((trigger) =>
        trigger.fields?.includes(fieldStartingKey)
      );

      acc[field.key] =
        isOcMonitoring && hasFrequencyOnAnyField ? true : hasFrequency;

      return acc;
    }, {});

    onSave(updateFieldsFactory(node, fieldsMap, mapping), triggers);
  };

  const getTriggerFrequency = (fieldKey: SpektrFieldKey) => {
    const trigger = triggers.find((trigger) =>
      trigger.fields?.includes(fieldKey)
    );

    return trigger?.frequency;
  };

  const renderServiceItem = (field: ServiceFieldClient) => {
    return (
      <ServiceItemWithFrequency
        key={field.key}
        field={field}
        frequency={getTriggerFrequency(field.key)}
        onChangeFrequency={handleChangeFrequency}
      />
    );
  };

  const serviceFields = useMemo(
    () => getServiceFieldsWithoutProperties(fields),
    [fields]
  );

  const isOcMonitoring = node.nodeType === 'openCorporatesMonitoring';

  return (
    <div className="flex flex-col gap-4">
      <div className="my-2 flex flex-col gap-4 rounded-md border p-4">
        <SectionWithTitle
          title={attributesTitle}
          additionalInfo={attributesHelp}
        >
          <SectionList
            className="max-h-[280px] overflow-y-auto"
            data={getIdentifiers(node.nodeType)}
          >
            {(field) => (
              <IdentifierItem
                key={field.key}
                field={field}
                spektrFields={spektrFields}
              />
            )}
          </SectionList>
        </SectionWithTitle>

        {!isOcMonitoring ? (
          <SectionWithTitle
            title={categoriesTitle}
            additionalInfo={categoriesHelp}
          >
            {fields.length > 0 ? (
              node.nodeType === 'kyckr' ? (
                <KyckrServiceFields
                  fields={serviceFields}
                  getFrequency={getTriggerFrequency}
                  onChangeFrequencies={handleChangeFrequencies}
                />
              ) : (
                <SectionList
                  className="max-h-[280px] overflow-y-auto"
                  data={serviceFields}
                >
                  {renderServiceItem}
                </SectionList>
              )
            ) : (
              <span className="text-color-text-subtext">No service fields</span>
            )}
          </SectionWithTitle>
        ) : (
          <SectionList
            className="max-h-[280px] overflow-y-auto"
            data={[
              {
                ...fields[0],
                label: 'Select frequency for trigger',
              },
            ]}
          >
            {renderServiceItem}
          </SectionList>
        )}
      </div>

      <Button
        className="ml-auto"
        disabled={!isValid}
        color={SERVICE_VARIANT_MAP[node.nodeType]}
        onClick={handleSave}
      >
        Save changes
      </Button>
    </div>
  );
};
