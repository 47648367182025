import { VariantProps, cva } from 'class-variance-authority';
import { icons } from 'lucide-react';

import { IconButton } from '@spektr/client/components';

export type FormNavigationItemProps = VariantProps<typeof variants> & {
  icon: keyof typeof icons;
  onClick?: () => void;
};

export const FormNavigationItem = ({
  icon,
  isActive = false,
  onClick,
}: FormNavigationItemProps) => {
  const Icon = icons[icon];
  return (
    <IconButton className={variants({ isActive })} onClick={onClick}>
      <Icon className="h-4 w-4" />
    </IconButton>
  );
};

const variants = cva(['h-10 w-10', 'p-0', 'rounded-md'], {
  variants: {
    isActive: {
      false: [
        'text-color-text-primary',
        'bg-color-bg-button-secondary',
        'dark:hover:bg-color-bg-button-secondary--hover',
        'hover:bg-color-bg-button-secondary/50',
        'active:bg-color-bg-button-secondary--active/50',
      ],
      true: [
        'text-color-green',
        'bg-color-green/30',
        'hover:bg-color-green/40',
        'active:bg-color-green/50',
      ],
    },
  },
  defaultVariants: {
    isActive: false,
  },
});
