import { memo } from 'react';
import { Cog } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { IconWithBackground } from '@spektr/client/components';

export const ProcessSettingsNode = memo(() => {
  return (
    <>
      <div>
        <IconWithBackground
          className={cn('ml-5 mr-4 h-10 w-10', `bg-color-stroke-default/20`)}
        >
          <Cog className="stroke-color-stroke-default h-5 w-5" />
        </IconWithBackground>
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-color-text-node text-sm font-medium">
          Process settings
        </h3>
      </div>
    </>
  );
});
