import { NodeType, SegmentInputSchema } from '@spektr/shared/validators';

export type SegmentConfig = {
  segmentTitlePlaceholderPrefix: string;
  shouldDisplaySegmentScore: boolean;
  shouldDisplayAddInnerGroup: boolean;
  shouldDisplayAddCondition: boolean;
  parser: typeof SegmentInputSchema;
};

export const segmentConfigs: Record<
  Extract<NodeType, 'calculation' | 'router' | 'filter'>,
  SegmentConfig
> = {
  calculation: {
    segmentTitlePlaceholderPrefix: 'Segment',
    shouldDisplaySegmentScore: true,
    shouldDisplayAddInnerGroup: true,
    shouldDisplayAddCondition: true,
    parser: SegmentInputSchema,
  },
  router: {
    segmentTitlePlaceholderPrefix: 'Route',
    shouldDisplaySegmentScore: false,
    shouldDisplayAddInnerGroup: false,
    shouldDisplayAddCondition: false,
    parser: SegmentInputSchema,
  },
  filter: {
    segmentTitlePlaceholderPrefix: 'Segment',
    shouldDisplaySegmentScore: false,
    shouldDisplayAddInnerGroup: true,
    shouldDisplayAddCondition: true,
    parser: SegmentInputSchema,
  },
};
