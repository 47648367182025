import { icons } from 'lucide-react';

import { Button, Tooltip } from '@spektr/client/components';

type GraphControlButtonProps = {
  onClick: () => void;
  iconName: keyof typeof icons;
  label: string;
};

export const GraphControlButton = ({
  onClick,
  iconName,
  label,
}: GraphControlButtonProps) => {
  const Icon = icons[iconName];
  return (
    <Tooltip
      content={label}
      tooltipTriggerProps={{
        asChild: true,
      }}
    >
      <Button
        className="h-4 w-4 p-0"
        onClick={onClick}
        variant="text"
        aria-label={label}
      >
        <Icon className="h-full w-full" />
      </Button>
    </Tooltip>
  );
};
