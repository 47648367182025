import z from 'zod';

import { unixTimestampSchema } from '../utils';

export const deliveryMethods = [
  'awsSqs',
  'slack',
  'httpsPush',
  'none',
] as const;
export type UserSettingsDeliveryMethods = (typeof deliveryMethods)[number];
export const userSettingsDeliveryMethodsSchema = z.enum(deliveryMethods);

export const maxRateLimitUnits = ['second', 'minute', 'hour', 'day'] as const;
export type UserSettingsMaxRateLimitUnits = (typeof maxRateLimitUnits)[number];
export const userSettingsMaxRateLimitUnitsSchema = z.enum(maxRateLimitUnits);

export const UserSettingsAwsSqsSettingsSchema = z
  .object({
    awsAccount: z.string().min(1),
  })
  .optional();
export type UserSettingsAwsSqsSettingsSchema = z.infer<
  typeof UserSettingsAwsSqsSettingsSchema
>;

export const UserSettingsSpektrIpsSchema = z
  .array(z.string().min(1))
  .optional();
export type UserSettingsSpektrIpsSchema = z.infer<
  typeof UserSettingsSpektrIpsSchema
>;

export const UserSettingsHttpsPushSettingsSchema = z
  .object({
    httpsEndpoint: z.string().min(1),
    apiKey: z.string().min(1),
  })
  .optional();
export type UserSettingsHttpsPushSettingsSchema = z.infer<
  typeof UserSettingsHttpsPushSettingsSchema
>;

export const UserSettingsSlackSettingsSchema = z
  .object({
    token: z.string().min(1),
    channelId: z.string().min(1),
  })
  .optional();
export type UserSettingsSlackSettingsSchema = z.infer<
  typeof UserSettingsSlackSettingsSchema
>;

export const UserSettingsSchema = z.object({
  id: z.string(),
  workspaceId: z.string().min(1),
  maxRateLimit: z.number(),
  maxRateLimitUnit: userSettingsMaxRateLimitUnitsSchema,
  deliveryMethod: userSettingsDeliveryMethodsSchema,
  awsSqsSettings: UserSettingsAwsSqsSettingsSchema,
  httpsPushSettings: UserSettingsHttpsPushSettingsSchema,
  slackSettings: UserSettingsSlackSettingsSchema,
  createdAt: unixTimestampSchema,
  updatedAt: unixTimestampSchema,
});
export type UserSettingsSchema = z.infer<typeof UserSettingsSchema>;

export const UserSettingsDtoSchema = z.object({
  id: z.string(),
  workspaceId: z.string().min(1),
  maxRateLimit: z.number(),
  maxRateLimitUnit: userSettingsMaxRateLimitUnitsSchema,
  deliveryMethod: userSettingsDeliveryMethodsSchema,
  awsSqsSettings: UserSettingsAwsSqsSettingsSchema,
  httpsPushSettings: UserSettingsHttpsPushSettingsSchema,
  slackSettings: UserSettingsSlackSettingsSchema,
  spektrIps: UserSettingsSpektrIpsSchema,
  createdAt: unixTimestampSchema,
  updatedAt: unixTimestampSchema,
});
export type UserSettingsDtoSchema = z.infer<typeof UserSettingsDtoSchema>;

export const UserSettingsCreateSchema = z.object({
  workspaceId: z.string().min(1),
  maxRateLimit: z.number(),
  maxRateLimitUnit: userSettingsMaxRateLimitUnitsSchema,
  deliveryMethod: userSettingsDeliveryMethodsSchema,
  awsSqsSettings: UserSettingsAwsSqsSettingsSchema,
  httpsPushSettings: UserSettingsHttpsPushSettingsSchema,
  slackSettings: UserSettingsSlackSettingsSchema,
});
export type UserSettingsCreateSchema = z.infer<typeof UserSettingsCreateSchema>;

export const UserSettingsUpdateSchema = z.object({
  workspaceId: z.string().min(1),
  maxRateLimit: z.number(),
  maxRateLimitUnit: userSettingsMaxRateLimitUnitsSchema,
  deliveryMethod: userSettingsDeliveryMethodsSchema,
  awsSqsSettings: UserSettingsAwsSqsSettingsSchema.optional(),
  httpsPushSettings: UserSettingsHttpsPushSettingsSchema.optional(),
  slackSettings: UserSettingsSlackSettingsSchema,
});
export type UserSettingsUpdateSchema = z.infer<typeof UserSettingsUpdateSchema>;

export const UserSettingsDeleteSchema = z.object({
  id: z.string().min(1),
});
export type UserSettingsDeleteSchema = z.infer<typeof UserSettingsDeleteSchema>;
