import { v4 as uuid } from 'uuid';

import type { Field } from '@spektr/moonraker-types';

import type { Block } from '../types';

export function createFieldsFromBlock(block: Block): Field[] {
  return block.components.map((component) => {
    if (component.type === 'entity') {
      const fields = Object.entries(component.form.fields).reduce(
        (acc, field) => {
          const [_, value] = field;
          const newId = uuid();
          return {
            ...acc,
            [newId]: {
              ...value,
              id: newId,
            },
          };
        },
        {}
      );
      return {
        ...component,
        id: uuid(),
        form: {
          fields,
          order: Object.keys(fields),
        },
      };
    }

    return {
      ...component,
      id: uuid(),
    };
  });
}
