import { X } from 'lucide-react';
import { useMemo } from 'react';

import { cn } from '@spektr/client/utils';
import { assertUnreachable } from '@spektr/shared/utils';

import {
  WidgetInstance,
  WidgetProperties,
  WidgetTypes,
  WidgetValidationItem,
} from '@spektr/shared/validators';

import { Button } from '@spektr/client/components';

import { useTemplateContext } from '../../providers/TemplateProvider';
import { filterFields } from '../../utils/filterFields';

import { IncompleteWidgetInstance } from '../../types/IncompleteWidgetInstance';
import { OnUpdateFn } from '../../types/UpdateFn';

import { ContentSettings } from './ContentSettings';
import { TextInputSettings } from './TextInputSettings';
import { UploadSettings } from './UploadSettings';

const SETTINGS_LABEL = {
  input: 'Edit Single line',
  textarea: 'Edit Multi line',
  upload: 'Edit Upload',
  headline: 'Edit Headline',
  paragraph: 'Edit Paragraph',
};

export type CanvasItemSettingsProps = {
  isExpanded?: boolean;
  properties: IncompleteWidgetInstance['properties'];
  originalWidget: WidgetInstance;
  validators: WidgetValidationItem[];
  type: WidgetTypes;
  onClose: () => void;
  onUpdate: OnUpdateFn;
  onSave: () => void;
  onDelete: () => void;
  onUpdateValidators: (validators: WidgetValidationItem[]) => void;
};

export const CanvasItemSettings = ({
  isExpanded = false,
  properties,
  validators,
  originalWidget,
  type,
  onClose,
  onUpdate,
  onSave,
  onDelete,
  onUpdateValidators,
}: CanvasItemSettingsProps) => {
  const { spektrFields } = useTemplateContext();

  const fieldOptions = useMemo(
    () =>
      filterFields(spektrFields, type).map((field) => ({
        label: field.label,
        value: field.key,
      })),
    [spektrFields, type]
  );

  const renderContentByType = () => {
    switch (type) {
      case 'paragraph':
      case 'headline':
        return <ContentSettings properties={properties} onUpdate={onUpdate} />;

      case 'input':
      case 'textarea':
        return (
          <TextInputSettings
            fieldOptions={fieldOptions}
            properties={properties}
            originalWidget={originalWidget}
            validators={validators}
            onUpdate={onUpdate}
            onRequiredChange={(value) => {
              onUpdateValidators([
                {
                  ...validators[0],
                  value: JSON.stringify(value),
                },
              ]);
            }}
          />
        );

      case 'upload':
        return (
          <UploadSettings
            fieldOptions={fieldOptions}
            originalWidget={originalWidget}
            properties={properties}
            validators={validators}
            onUpdate={onUpdate}
            onRequiredChange={(value) => {
              onUpdateValidators([
                {
                  ...validators[0],
                  value: JSON.stringify(value),
                },
              ]);
            }}
          />
        );

      default:
        assertUnreachable(type);
    }
  };

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center gap-4',
        'h-0',
        'overflow-hidden',
        'transition-all duration-300',
        {
          'h-[400px]':
            isExpanded &&
            (type === 'input' || type === 'textarea' || type === 'upload'),
          'h-30': isExpanded && type === 'headline',
          'h-[180px]': isExpanded && type === 'paragraph',
        }
      )}
    >
      <div className="mt-4 flex w-full items-center justify-between">
        <h3>{SETTINGS_LABEL[type]}</h3>
        <X className="h-5 w-5 cursor-pointer" onClick={onClose} />
      </div>
      {renderContentByType()}
      <div className="mt-auto flex w-full flex-row justify-end gap-4">
        <Button variant="text" color="red" onClick={onDelete}>
          Delete
        </Button>
        <Button
          color="cyan"
          className="text-color-text-button-secondary"
          disabled={!isFormValid(type, properties)}
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

function isFormValid(type: WidgetTypes, properties: WidgetProperties) {
  switch (type) {
    case 'input':
    case 'textarea':
    case 'upload': {
      if (properties.field.fieldType === 'custom') {
        return !!properties.field.name && !!properties.field.type;
      }

      if (properties.field.fieldType === 'dataset') {
        return !!properties.field.name;
      }

      return !!properties.label;
    }

    case 'headline':
    case 'paragraph':
      return !!properties.label;
    default:
      return true;
  }
}
