import {
  ServiceNode,
  SpektrFieldKey,
  SpektrFieldWithInfo,
} from '@spektr/shared/types';

import { ServiceFieldClient } from '../types/ServiceFieldClient';

export function mapSpektrFieldToServiceField(
  node: ServiceNode,
  spektrFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>>
) {
  return Object.entries(node.fields)
    .map<ServiceFieldClient>(([key, value]) => ({
      key: key as SpektrFieldKey,
      label: spektrFields.find((field) => field.key === key)?.label,
      value,
      help: spektrFields.find((field) => field.key === key)?.help,
    }))
    .filter((field) => !!field.label);
}
