import { useIncompleteRuleGroup } from '../providers';

import { SelectBooleanOperator } from './select-boolean-operator';

export const SelectWithinGroupsOperator = (props: {
  groupId: string;
  value: string | undefined;
  disabled?: boolean;
}) => {
  const { setOperatorWithinGroup } = useIncompleteRuleGroup();

  return (
    <SelectBooleanOperator
      trigger-data-testid={`within-group-operator-${props.groupId}`}
      required
      value={props.value}
      onValueChange={(operator) =>
        setOperatorWithinGroup(props.groupId, operator)
      }
      disabled={props.disabled}
    />
  );
};
