import { useDemo } from '@spektr/client/providers';
import {
  InsightsRootLayout,
  StickyVideoContainer,
} from '@spektr/client/components';
import {
  InsightsNavigation,
  InsightsRunsDashboard,
} from '@spektr/model-builder/containers';

export const RunsPage = () => {
  const { isDemo } = useDemo();

  const handleRenderDemoPopup = () => {
    if (isDemo) {
      return (
        <StickyVideoContainer
          title="Check out our video guide"
          source="https://platform.demo.spektr.com/videos/insights.mp4"
          avatar="bg-avatar-jeremy"
        />
      );
    }
    return null;
  };

  return (
    <InsightsRootLayout renderPopup={handleRenderDemoPopup}>
      <InsightsNavigation />
      <InsightsRunsDashboard />
    </InsightsRootLayout>
  );
};
