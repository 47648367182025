import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '../../Button';
import { BasicDialog } from '../../dialogs';
import { Input } from '../../forms';

type LinkFormatDialogProps = {
  onSave: (url: string) => void;
  onClose: () => void;
};

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:'];

const urlFormatSchema = z.object({
  url: z.string().refine(
    (value) => {
      let parsedUrl;

      try {
        parsedUrl = new URL(value);
      } catch {
        return false;
      }

      if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
        return true;
      }

      return false;
    },
    {
      message:
        'Invalid URL, make sure the URL starts with http://, https://, mailto:, or tel:',
      path: ['url'],
    }
  ),
});

export const LinkFormatDialog = ({
  onClose,
  onSave,
}: LinkFormatDialogProps) => {
  const {
    handleSubmit: handleFormSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<{ url: string }>({
    resolver: zodResolver(urlFormatSchema),
  });

  return (
    <BasicDialog
      title="Add link"
      dialogHeaderClassName="mb-2"
      className="w-[512px] rounded-2xl border-0"
      defaultOpen
      onClose={onClose}
      showCloseButton={false}
    >
      <form
        className="flex flex-col gap-4"
        onSubmit={handleFormSubmit((values) => {
          onSave(values.url);
          onClose();
        })}
      >
        <div className="flex flex-col gap-2">
          <Input
            id="link-url"
            placeholder="Paste or type a link"
            {...register('url', { required: true })}
            error={!!errors?.url}
          />
          {errors?.url && (
            <span className="text-color-red text-xs">{errors.url.message}</span>
          )}
        </div>

        <div className="ml-auto flex flex-row gap-4">
          <Button type="button" variant="text" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid}>
            Continue
          </Button>
        </div>
      </form>
    </BasicDialog>
  );
};
