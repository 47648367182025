import { NodeType, ProcessType } from '@spektr/shared/validators';

export const getServiceNodeDialogInformation = (
  nodeType: NodeType,
  processType: ProcessType = 'risk'
) => {
  switch (nodeType) {
    case 'kyckr':
      return {
        attributesTitle: 'Map company attributes',
        attributesHelp: '',
        categoriesTitle: 'Choose person of interest information',
        categoriesHelp:
          'Choose information you want to retrieve from Kyckr. Note that representatives and UBOs can be individual and/or companies.',
      };
    default:
      return {
        attributesTitle: 'Map attributes from your dataset',
        attributesHelp: '',
        categoriesTitle:
          processType === 'monitoring'
            ? 'Select categories and frequency'
            : 'Select categories',
        categoriesHelp: '',
      };
  }
};
