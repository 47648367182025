import { ListTodoIcon } from 'lucide-react';

import { cn } from '@spektr/client/utils';
import { IconWithBackground } from '@spektr/client/components';

export type ActionNodeProps = {
  title: string;
};

export const ActionNode = ({ title }: ActionNodeProps) => {
  return (
    <>
      <div>
        <IconWithBackground
          className={cn('ml-5 mr-4 h-10 w-10', `bg-color-pink`)}
        >
          <ListTodoIcon className="dark:stroke-color-stroke-primary stroke-color-stroke-default h-5 w-5" />
        </IconWithBackground>
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-color-text-node text-sm font-medium">{title}</h3>
        <span className="text-color-text-node-subtext text-xs">
          Action step
        </span>
      </div>
    </>
  );
};
