import { makeApi, makeEndpoint } from '@zodios/core';
import { z } from 'zod';

import {
  VendorSettingsFilterBodySchema,
  vendorSettingsSchema,
  vendorSettingsCreateSchema,
  VendorSettingsConnUpdateSchema,
} from './vendor-settings';

export const createVendorSettings = makeEndpoint({
  method: 'post',
  path: '/vendor-settings',
  alias: 'createVendorSettings',
  parameters: [
    {
      name: 'body',
      type: 'Body',
      schema: vendorSettingsCreateSchema,
    },
  ],
  response: vendorSettingsSchema,
});

export const getVendorSettingsByVendor = makeEndpoint({
  method: 'get',
  path: '/vendor-settings/:vendor',
  alias: 'getVendorSettingsByVendor',
  parameters: [
    {
      name: 'vendor',
      schema: z.string(),
      type: 'Path',
    },
  ],
  response: vendorSettingsSchema,
});

export const getFilteredVendorSettings = makeEndpoint({
  method: 'get',
  path: '/vendor-settings',
  alias: 'getFilteredVendorSettings',
  parameters: [
    {
      name: 'filter',
      schema: VendorSettingsFilterBodySchema,
      type: 'Query',
    },
  ],
  response: z.array(vendorSettingsSchema),
});

export const updateVendorSettingsByVendor = makeEndpoint({
  method: 'patch',
  path: '/vendor-settings/:vendor',
  alias: 'updateVendorSettingsByVendor',
  parameters: [
    {
      name: 'vendor',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'body',
      type: 'Body',
      schema: VendorSettingsConnUpdateSchema,
    },
  ],
  response: vendorSettingsSchema,
});

export const deleteVendorSettingsByVendor = makeEndpoint({
  method: 'delete',
  path: '/vendor-settings/:vendor',
  alias: 'deleteVendorSettingsByVendor',
  parameters: [
    {
      name: 'vendor',
      schema: z.string(),
      type: 'Path',
    },
  ],
  response: z.object({
    acknowledged: z.boolean(),
    deletedCount: z.number(),
  }),
});

export const vendorSettingsApi = makeApi([
  getVendorSettingsByVendor,
  getFilteredVendorSettings,
  updateVendorSettingsByVendor,
  createVendorSettings,
  deleteVendorSettingsByVendor,
]);
