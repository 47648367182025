import { useSuspenseQuery } from '@tanstack/react-query';
import { InboxIcon } from 'lucide-react';
import { MouseEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getVendorSettings } from '@spektr/client/services';
import { serviceDialogFullUrl } from '@spektr/shared/utils';

import { useVendorServices } from '@spektr/platform-hooks';

import { VendorServiceName } from '@spektr/client/types';

import { ServiceCardWithAction } from '../../components/ServiceCardWIthAction';
import { useFakeStorage } from '../../hooks/useFakeStorage';

import { ServiceCatalogFilterStates } from '../../types/ServiceCatalogStateFilter';
import { ServiceWithConfiguration } from '../../types/ServiceWithConfiguration';

export type ServiceListProps = {
  stateFilter: ServiceCatalogFilterStates;
};

export const ServiceList = ({ stateFilter }: ServiceListProps) => {
  const navigate = useNavigate();

  const { data: vendors } = useVendorServices();
  const { requests, addRequestService } = useFakeStorage();
  const { data: vendorsSettingsList } = useSuspenseQuery(getVendorSettings());

  const handleClickService = (
    ev: MouseEvent<HTMLButtonElement>,
    serviceName: VendorServiceName
  ) => {
    const vendor = vendors.filter((vendor) => vendor.id === serviceName)[0];
    if (!vendor) {
      return;
    }

    ev.stopPropagation();
    ev.preventDefault();

    if (vendor.available) {
      return navigate(serviceDialogFullUrl(serviceName));
    }

    addRequestService(serviceName);
  };

  const computedVendors = useMemo<ServiceWithConfiguration[]>(() => {
    const services = vendors
      .filter((vendor) => !vendor.hidden)
      .map((vendor) => ({
        ...vendor,
        requestedAt: requests[vendor.id],
        configuration: vendorsSettingsList.find(
          (settings) => settings.vendor === vendor.id
        ),
      }))
      .sort((a, b) => {
        if (a?.configuration?.apiKey?.key && !b?.configuration?.apiKey?.key) {
          return -1;
        }
        if (!a?.configuration?.apiKey?.key && b?.configuration?.apiKey?.key) {
          return 1;
        }
        return 0;
      });

    if (stateFilter === 'configured') {
      return services.filter((service) => service.configuration?.apiKey?.key);
    } else if (stateFilter === 'notConfigured') {
      return services.filter(
        (service) => !service.configuration?.apiKey?.key && service.available
      );
    }

    return services;
  }, [vendors, vendorsSettingsList, requests, stateFilter]);

  if (!computedVendors || computedVendors.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 p-8">
        <InboxIcon className="stroke-color-cyan h-8 w-8" />
        <span className="text-color-text-error-boundry">
          No services found.
        </span>
      </div>
    );
  }

  return (
    <div className="mb-4 flex flex-wrap gap-4" data-cy="vendor-settings-list">
      {computedVendors.map((settings) => (
        <ServiceCardWithAction
          key={settings.id}
          service={settings}
          onClick={handleClickService}
        />
      ))}
    </div>
  );
};
