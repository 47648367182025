import { SpektrFieldWithInfo } from '../../goldenRecord';

export const mitIdCprNumberFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'mitId.cprNumberIdentifier',
    label: 'CPR Number',
    type: 'string',
    source: 'mitId',
  },
];
export const mitIdForCitizenBaseFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'mitId.is_authentication_successful',
    label: 'Is Authentication Successful',
    type: 'boolean',
    source: 'mitId',
  },
  {
    key: 'mitId.birthdate',
    label: 'Birthdate',
    type: 'date',
    source: 'mitId',
  },
  {
    key: 'mitId.name',
    label: 'Name',
    type: 'string',
    source: 'mitId',
  },
  {
    key: 'mitId.country',
    label: 'Country',
    type: 'country',
    source: 'mitId',
  },
];
export const mitIdForCitizenAddressFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'mitId.address.common_name',
    label: 'Common Name',
    type: 'string',
    source: 'mitId',
  },
  {
    key: 'mitId.address.street_address',
    label: 'Street Address',
    type: 'string',
    source: 'mitId',
  },
  {
    key: 'mitId.address.postal_code',
    label: 'Postal Code',
    type: 'string',
    source: 'mitId',
  },
  {
    key: 'mitId.address.city',
    label: 'City',
    type: 'string',
    source: 'mitId',
  },
  {
    key: 'mitId.address.locality',
    label: 'Locality',
    type: 'string',
    source: 'mitId',
  },
  {
    key: 'mitId.address.region',
    label: 'Region',
    type: 'string',
    source: 'mitId',
  },
  {
    key: 'mitId.address.country',
    label: 'Country',
    type: 'country',
    source: 'mitId',
  },
];

export const mitIdSpektrFields = [
  ...mitIdForCitizenBaseFields,
  ...mitIdCprNumberFields,
  ...mitIdForCitizenAddressFields,
];
