import { Controller, useFormContext } from 'react-hook-form';
import { useMemo, useState } from 'react';

import {
  Combobox,
  DatasetField,
  SelectOption,
  TableCell,
  TableRow,
} from '@spektr/client/components';

import { cn } from '@spektr/client/utils';

const baseRowCellClasses = cn('px-4 w-1/3', 'border-b', 'text-xs');

type FieldMappingTableRowProps = {
  id: string;
  sources: SelectOption[];
  customField: string;
  type: string;
  isLastRow?: boolean;
};

export const FieldMappingTableRow = ({
  id,
  sources,
  customField,
  type,
  isLastRow,
}: FieldMappingTableRowProps) => {
  const {
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const [searchValue, setSearchValue] = useState('');
  const filteredSources = useMemo(
    () =>
      sources.filter((field) =>
        field.label.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue, sources]
  );

  const handleChange = (fieldName: string) => (value: string) => {
    const isFieldInDataset =
      sources.findIndex((source) => source.value === value) !== -1;

    if (!isFieldInDataset) {
      setValue(fieldName, value);
      setError(customField, {
        type: 'manual',
        message: 'Field not found in dataset',
      });

      return;
    }

    setValue(fieldName, value, {
      shouldValidate: isFieldInDataset,
      shouldDirty: true,
      shouldTouch: true,
    });
    clearErrors(customField);
  };

  return (
    <TableRow className="hover:bg-color-bg-table-row--hover h-[52px]">
      <TableCell className={cn(baseRowCellClasses, isLastRow && 'border-0')}>
        <div className="flex flex-row items-center gap-2">{customField}</div>
      </TableCell>
      <TableCell className={cn(baseRowCellClasses, isLastRow && 'border-0')}>
        <div className="text-color-text-subtext flex flex-row items-center gap-2">
          {type}
        </div>
      </TableCell>
      <TableCell className={cn(baseRowCellClasses, isLastRow && 'border-0')}>
        <Controller
          name={`fields.${id}.mapping`}
          render={({ field: renderField }) => (
            <>
              <Combobox
                defaultValue={getValues(renderField.name) || ''}
                onSearch={setSearchValue}
                onChange={handleChange(renderField.name)}
                inputProps={{
                  ...register(customField),
                  error: !!errors[customField],
                  placeholder: 'Select field',
                }}
                triggerProps={{ className: 'w-[200px]' }}
                noResults={filteredSources.length === 0}
                noResultsText="Field not found in dataset"
              >
                {filteredSources.map(({ value, label }) => (
                  <DatasetField key={value} value={value} children={label} />
                ))}
              </Combobox>
              {errors[customField] && (
                <p className="text-color-red mt-1">
                  {`${errors[customField]?.message}`}
                </p>
              )}
            </>
          )}
        />
      </TableCell>
    </TableRow>
  );
};
