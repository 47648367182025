import { useMemo } from 'react';
import { Trash2 } from 'lucide-react';

import { useSuspenseQuery } from '@tanstack/react-query';

import { getDatasetBySourceId } from '@spektr/shared/utils';

import { connectionDataSetsQuery } from '@spektr/client/services';

import {
  IncompleteRuleGroup,
  RuleGroupProvider,
  TitleInput,
  parseRuleGroup,
  RuleGroupBody,
  RuleGroupCard,
  RuleGroupHeader,
} from '@spektr/shared/components';

import {
  FilterNodeSourceDataInputSchema,
  SpektrField,
} from '@spektr/shared/types';

import { Button } from '@spektr/client/components';

import { DatasetActionButton } from '../../components/DatasetActionButton';
import { useSourceProvider } from '../../providers/SourceProvider';

const DEFAULT_RULE_GROUP: IncompleteRuleGroup = {
  id: 'custom-filter',
  clientSideOnlyId: 'custom-filter',
  title: 'Custom Filter',
  score: undefined,
  rule: {
    id: 'custom-filter-rule',
    isIntermediate: false,
    groupRoot: true,
    operator: undefined,
    type: undefined,
  },
};

export const FilterRuleGroup = () => {
  const { getFilterSource, updateFilterRuleGroup } = useSourceProvider();

  const source = getFilterSource();

  const { data: datasets } = useSuspenseQuery(connectionDataSetsQuery());

  const availableSpektrFields = useMemo(() => {
    if (!Array.isArray(datasets)) {
      return [];
    }

    if (source?.sourceId) {
      const dataset = getDatasetBySourceId(datasets, source.sourceId);

      if (dataset) {
        return dataset.fields.map(
          (field) =>
            ({
              key: field.name,
              type: field.type,
              label: field.name,
            }) as SpektrField
        );
      }
    }

    return [];
  }, [datasets, source?.sourceId]);

  const handleChangeRuleGroup = (value: IncompleteRuleGroup | undefined) => {
    updateFilterRuleGroup(value as FilterNodeSourceDataInputSchema['filter']);
  };

  const handleAddRuleGroup = () => {
    updateFilterRuleGroup(
      DEFAULT_RULE_GROUP as FilterNodeSourceDataInputSchema['filter']
    );
  };

  if (!source?.filter) {
    return (
      <DatasetActionButton onClick={handleAddRuleGroup}>
        Add filtering rule
      </DatasetActionButton>
    );
  }

  const parsed = parseRuleGroup(source.filter);
  return (
    <RuleGroupProvider
      group={parsed}
      spektrFields={availableSpektrFields}
      onChange={handleChangeRuleGroup}
    >
      <RuleGroupCard>
        <RuleGroupHeader>
          <TitleInput
            className="text-color-text-primary hover:ring-spektr-zinc-600 focus:ring-spektr-zinc-600 pl-1 text-sm"
            title={source.filter.title}
            onChange={(title) =>
              handleChangeRuleGroup({
                ...(source.filter as IncompleteRuleGroup),
                title,
              })
            }
          />
          <Button
            onClick={() => updateFilterRuleGroup(undefined)}
            className="group p-2"
            variant="text"
          >
            <Trash2 className="stroke-color-stroke-default group-hover:stroke-color-stroke-default--hover h-4 w-4" />
          </Button>
        </RuleGroupHeader>
        <RuleGroupBody />
      </RuleGroupCard>
    </RuleGroupProvider>
  );
};
