import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { LS_KEYS } from '@spektr/client/constants';

import {
  CONNECTION_HUB_DATA_SOURCES_FULL_URL,
  insightsRecordDatasetIdUrl,
} from '@spektr/shared/utils';
import { localStorageUtils } from '@spektr/client/utils';

import { connectionDataSetsQuery } from '@spektr/client/services';

export const EnsureDatasetData = () => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(connectionDataSetsQuery());
  const datasetId = localStorageUtils.getItem(LS_KEYS.INSIGHTS_DATASET_ID);

  useEffect(() => {
    if (datasetId) {
      navigate(insightsRecordDatasetIdUrl(datasetId));
    } else if (data.length > 0) {
      navigate(insightsRecordDatasetIdUrl(data[0].id));
    }
  }, [datasetId, data, navigate]);

  if (data.length === 0) {
    return (
      <div>
        You don't have any datasets. Please{' '}
        <a
          href={CONNECTION_HUB_DATA_SOURCES_FULL_URL}
          className="underline underline-offset-2"
        >
          create a dataset
        </a>{' '}
        to view the records.
      </div>
    );
  }

  return <div />;
};
