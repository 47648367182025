import { queryOptions } from '@tanstack/react-query';

import { getApiKeysQueryKey } from './query.keys';
import { ApiKeysApiClient } from './client';

export function getApiKeysQuery() {
  return queryOptions({
    queryKey: getApiKeysQueryKey(),
    queryFn: () => ApiKeysApiClient.getClient().getApiKeys(),
  });
}
