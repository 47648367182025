import { z } from 'zod';

export const deleteFileSchema = z.strictObject({
  key: z.string(),
});

export const fileUploadSchema = z.instanceof(FormData);
export const fileUploadResponseSchema = z.strictObject({
  key: z.string(),
});

export const fileResponseSchema = z.strictObject({
  hash: z.string(),
  key: z.string(),
  size: z.number(),
  timestamp: z.number(),
});
