import { Suspense } from 'react';
import axios from 'axios';
import { Unplug } from 'lucide-react';

import {
  BasicDialog,
  IconWithBackground,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { ProcessesList } from './components/ProcessesList';
import { ProcessesListSkeleton } from './components/ProcessesListSkeleton';
import { ProcessesListError } from './components/ProcessesListError';

type ProcessConnectDialogProps = {
  sourceProcessId: string;
  sourceOutcomeId: string;
  handleClose: () => void;
};

export const ProcessConnectDialog = ({
  sourceProcessId,
  sourceOutcomeId,
  handleClose,
}: ProcessConnectDialogProps) => {
  return (
    <BasicDialog
      title="Connect a Process"
      icon={
        <IconWithBackground className="bg-color-bg-accent mr-2 h-6 w-6">
          <Unplug className="text-color-text-icon-primary h-3 w-3" />
        </IconWithBackground>
      }
      dialogHeaderClassName="mb-2"
      className="w-[512px] rounded-2xl border-0"
      defaultOpen
      onClose={handleClose}
    >
      <SpektrErrorBoundary
        fallbackRender={({ error }) => {
          const errorMsg = axios.isAxiosError(error)
            ? error.response?.data?.message
            : 'Something went wrong when loading the available processes.';
          return <ProcessesListError message={errorMsg} />;
        }}
      >
        <Suspense fallback={<ProcessesListSkeleton />}>
          <ProcessesList
            sourceProcessId={sourceProcessId}
            sourceOutcomeId={sourceOutcomeId}
            closeDialog={handleClose}
          />
        </Suspense>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
