import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { useSuspenseQuery } from '@tanstack/react-query';

import { getProcessesQuery } from '@spektr/client/services';
import { PaginationCombobox } from '@spektr/client/components';

import { RecordExecutionsTable } from '../../components/RecordExecutionsTable';
import { useGetExecutionsBySpektrId } from '../../hooks';

export type RecordExecutionsListProps = {
  datasetId: string;
  recordId: string;
};

const allowedPageValues = [10, 20, 50];

const pageLimitSchema = z
  .number()
  .refine((value) => allowedPageValues.includes(value), {
    message: 'Value must be 10, 20, or 50',
  })
  .default(10);

export const RecordExecutionsList = ({
  datasetId,
  recordId,
}: RecordExecutionsListProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = pageLimitSchema.parse(
    Number(searchParams.get('limit') ?? '10')
  );
  const page = Number(searchParams.get('page') ?? '1');

  const { data: processes } = useSuspenseQuery(getProcessesQuery());

  const { executions, totalPages } = useGetExecutionsBySpektrId(
    datasetId,
    recordId,
    {
      limit: pageLimit,
      page,
    }
  );

  const handleChangeLimit = (limit: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
      limit: limit.toString(),
    });
  };

  const handleChangePage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    });
  };

  return (
    <div className="flex h-full flex-col items-start gap-6 overflow-auto">
      {executions.length === 0 ? (
        <div className="text-color-text-subtext w-full rounded-md border p-4 text-center">
          No executions found
        </div>
      ) : (
        <>
          <RecordExecutionsTable
            executions={executions}
            processes={processes}
          />
          <PaginationCombobox
            alignment="end"
            currentPage={page}
            totalPages={totalPages}
            pageLimit={pageLimit}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          />
        </>
      )}
    </div>
  );
};
