import { createContext } from 'react';

type DemoProviderContextType = {
  checkIfDemoEnv: () => boolean;
};

const DemoContext = createContext<DemoProviderContextType>({
  checkIfDemoEnv: () => false,
});

type DemoProviderProps = {
  children: React.ReactNode;
  isDemoEnv: boolean;
};

const DemoProvider = ({ isDemoEnv, children }: DemoProviderProps) => {
  const checkIfDemoEnv = () => {
    return isDemoEnv;
  };

  return (
    <DemoContext.Provider value={{ checkIfDemoEnv }}>
      {children}
    </DemoContext.Provider>
  );
};

export { DemoContext, DemoProvider };
