import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CircleX, CloudUpload, FileCog } from 'lucide-react';
import Papa from 'papaparse';
import { useCallback, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import { MATRIX_DASHBOARD_FULL_URL, matrixViewUrl } from '@spektr/shared/utils';

import { BasicDialog, iconBoxVariants } from '@spektr/client/components';
import {
  RiskMatrixApiClient,
  getRiskMatricesQueryKey,
} from '@spektr/client/services';
import { cn } from '@spektr/client/utils';

import { ParseResultData, isValidRiskMatrixCsv } from '../../../RiskMatrix';
import { DownloadExampleCsv } from '../../components';

type OnDropAcceptedFunction = NonNullable<DropzoneOptions['onDropAccepted']>;

export const UploadCsvDialog = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isValid, setIsValid] = useState(true);

  const uploadCsvMutation = useMutation({
    mutationFn: (formData: FormData) =>
      RiskMatrixApiClient.getClient().uploadRiskMatrix(formData),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getRiskMatricesQueryKey() });
      navigate(matrixViewUrl(data.id));
    },
    onError: () => {
      setIsValid(false);
    },
  });

  const handleDropAccepted: OnDropAcceptedFunction = useCallback(
    ([acceptedFile]) => {
      const nextFormData = new FormData();

      Papa.parse(acceptedFile, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          const isValid = isValidRiskMatrixCsv(results.data as ParseResultData);

          if (!isValid) {
            setIsValid(false);
            return;
          }

          nextFormData.append('file', acceptedFile);
          await uploadCsvMutation.mutateAsync(nextFormData);
        },
      });
    },
    [uploadCsvMutation]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
    onDropAccepted: handleDropAccepted,
  });

  const handleClose = () => navigate(MATRIX_DASHBOARD_FULL_URL);

  return (
    <BasicDialog
      title={'Upload a CSV'}
      dialogHeaderClassName="mb-6"
      className="text-color-text-dialog-default max-w-[500px]"
      icon={
        <CloudUpload
          className={cn(
            iconBoxVariants({ variant: 'cyan' }),
            'mr-4 h-6 w-6 p-1.5'
          )}
        />
      }
      defaultOpen
      onClose={handleClose}
      dialogContentDataCy="uploadRiskMatrixCsvDialog"
    >
      <div {...getRootProps()} className="h-[250px] outline-none">
        <input {...getInputProps()} />
        <div
          className={cn(
            'flex h-full flex-col items-center justify-center',
            'cursor-pointer rounded-md border border-dashed',
            isDragActive && isDragAccept && 'border-color-cyan',
            isDragReject && 'border-color-red'
          )}
        >
          <div className="flex flex-col items-center">
            {isDragActive && isDragAccept ? (
              <>
                <CloudUpload
                  className={cn(
                    iconBoxVariants({ variant: 'cyan' }),
                    'h-11 w-11 p-2.5'
                  )}
                />
                <span className="mt-4 text-base font-semibold">
                  Almost there, drop it here!
                </span>
              </>
            ) : isDragReject ? (
              <>
                <CircleX
                  className={cn(
                    iconBoxVariants({ variant: 'red' }),
                    'h-11 w-11 p-2.5'
                  )}
                />
                <span className="mt-4 text-base font-semibold">
                  We cannot accept this file!
                </span>
              </>
            ) : uploadCsvMutation.isPending ? (
              <>
                <FileCog
                  className={cn(
                    iconBoxVariants({ variant: 'cyan' }),
                    'h-11 w-11 p-2.5'
                  )}
                />
                <span className="mt-4 text-base font-semibold">
                  Extracting from CSV..
                </span>
                <span className="text-color-text-subtext mt-2 w-[198px] text-center text-xs">
                  Please wait while we extract data from the uploaded CSV.
                </span>
              </>
            ) : isValid ? (
              <>
                <CloudUpload
                  className={cn(
                    iconBoxVariants({ variant: 'cyan' }),
                    'h-11 w-11 p-2.5'
                  )}
                />
                <span className="mt-4 text-base font-semibold">
                  Drop your files here, or{' '}
                  <span className="text-color-cyan">Browse</span>
                </span>
                <span className="text-color-text-subtext mt-2 w-[203px] text-center text-xs">
                  You can easily get started on a matrix by uploading a CSV (
                  <DownloadExampleCsv>Example</DownloadExampleCsv>)
                </span>
              </>
            ) : (
              <>
                <FileCog
                  className={cn(
                    iconBoxVariants({ variant: 'red' }),
                    'h-11 w-11 p-2.5'
                  )}
                />
                <span className="mt-4 text-base font-semibold">
                  Failed to extract CSV
                </span>
                <div className="text-color-text-subtext mt-2 flex w-48 flex-col gap-2 text-center text-xs">
                  <span>
                    Take a look at our{' '}
                    <DownloadExampleCsv>example CSV</DownloadExampleCsv> on what
                    is supported.
                  </span>
                  <span>
                    Please try again by dropping your file here, or{' '}
                    <strong>click to browse</strong>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </BasicDialog>
  );
};
