import { MessageSquareText, Table } from 'lucide-react';

import {
  insightsActionHitCommentsUrl,
  insightsActionHitDetailsUrl,
} from '@spektr/shared/utils';

import { Tabbar, TabbarItem } from '@spektr/client/components';

import { useActionAndHitIdParam } from '../../hooks/useActionAndHitIdParam';
import { useGetMessageCount } from '../../hooks/useGetMessageCount';

export const HitDetailsTabNavigation = () => {
  const { actionId, hitId } = useActionAndHitIdParam();
  const messageCount = useGetMessageCount(hitId);

  return (
    <Tabbar>
      <TabbarItem
        icon={<Table className="h-4 w-4" />}
        to={{ pathname: insightsActionHitDetailsUrl(actionId, hitId) }}
      >
        Data fetched for this hit
      </TabbarItem>
      <TabbarItem
        icon={<MessageSquareText className="h-4 w-4" />}
        to={{ pathname: insightsActionHitCommentsUrl(actionId, hitId) }}
      >
        Comments {messageCount > 0 ? `(${messageCount})` : ''}
      </TabbarItem>
    </Tabbar>
  );
};
