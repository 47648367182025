import { useMemo } from 'react';

import { SpektrFieldKey } from '@spektr/shared/types';

import { cn } from '@spektr/client/utils';
import { Separator } from '@spektr/client/components';

import { ServiceFieldClient } from '../../types/ServiceFieldClient';
import { computeMitIdFields } from '../../utils/mitId';

import { SelectableServiceItem } from '../SelectableServiceItem';
import { MitIdLoginFields } from '../MitIdLoginFields';

type MitIdServiceFieldWithCheckboxProps = {
  loginType?: string;
  updateLoginType: (loginType: string) => void;
  fields: ServiceFieldClient[];
  isFieldSelected: (fieldKey: string) => boolean;
  handleSelectionChange: (fieldKey: SpektrFieldKey) => (value: boolean) => void;
};

export const MitIdServiceFieldWithCheckbox = ({
  loginType,
  updateLoginType,
  fields,
  isFieldSelected,
  handleSelectionChange,
}: MitIdServiceFieldWithCheckboxProps) => {
  const [cpr, address, ...login] = useMemo(
    () => computeMitIdFields(fields),
    [fields]
  );

  return (
    <div
      className={cn(
        'flex flex-col',
        'max-h-[280px] overflow-y-auto',
        'rounded-md border'
      )}
    >
      <div className="flex flex-col" key={cpr.key}>
        <div
          className={cn(
            'flex h-auto shrink-0 items-center justify-start px-4',
            'h-[52px]'
          )}
        >
          <SelectableServiceItem
            key={cpr.key}
            checked={isFieldSelected(cpr.key)}
            field={cpr}
            onChange={handleSelectionChange(cpr.key)}
          />
        </div>
        <Separator />
      </div>

      <div className="flex flex-col" key={address.key}>
        <div
          className={cn(
            'flex h-auto shrink-0 items-center justify-start px-4',
            'h-[52px]'
          )}
        >
          <SelectableServiceItem
            key={address.key}
            checked={isFieldSelected(address.key) && isFieldSelected(cpr.key)}
            field={address}
            onChange={handleSelectionChange(address.key)}
            disabled={!isFieldSelected(cpr.key)}
          />
        </div>
        <Separator />
      </div>
      <div className="flex items-start gap-2 p-4">
        <MitIdLoginFields
          key={loginType}
          selectedKey={loginType}
          fields={login}
          onKeyUpdate={updateLoginType}
        />
      </div>
    </div>
  );
};
