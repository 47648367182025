import { z } from 'zod';

import { InputField, InputFieldAttributes } from './InputField';

export const CurrencyField = InputField.merge(
  z.object({
    attributes: InputFieldAttributes.merge(
      z.object({
        type: z.literal('currency'),
        currency: z
          .object({
            symbol: z.string(),
            code: z.string(),
          })
          .optional(),
      })
    ),
  })
);

export type CurrencyField = z.infer<typeof CurrencyField>;

export function isCurrencyField(field: unknown): field is CurrencyField {
  return CurrencyField.safeParse(field).success;
}
