import { ReactNode, useState } from 'react';

import { cn } from '@spektr/client/utils';

import { Theme, ThemeProvider } from '@spektr/client/providers';

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './common';

export type BasicDialogProps = Omit<DialogContentProps, 'title'> & {
  children: ReactNode;
  className?: string;
  description?: ReactNode;
  icon?: ReactNode;
  defaultOpen?: boolean;
  title: ReactNode;
  dialogHeaderClassName?: string;
  dialogContentDataCy?: string;
  renderTitleSlot?: () => ReactNode;
  theme?: Theme;
  onClose?: () => void;
  showCloseButton?: boolean;
};

export function BasicDialog({
  children,
  className,
  description,
  icon,
  defaultOpen,
  renderTitleSlot,
  size,
  showCloseButton = true,
  title,
  dialogHeaderClassName,
  dialogContentDataCy,
  theme,
  onClose,
  ...props
}: BasicDialogProps) {
  const [open, setOpen] = useState(defaultOpen);
  const startExitAnimation = () => setOpen(false);

  return (
    <ThemeProvider defaultTheme={theme}>
      <Dialog {...props} open={open}>
        <DialogContent
          className={cn(
            'outline-none',
            'flex flex-col',
            'max-h-[90vh] overflow-y-auto',
            className
          )}
          size={size}
          requestStartExitAnimation={startExitAnimation}
          onEndExitAnimation={onClose}
          onEscapeKeyDown={onClose}
          data-cy={dialogContentDataCy}
        >
          <DialogHeader
            className={cn(
              'justify-center space-y-0',
              {
                'mb-4': !description,
              },
              dialogHeaderClassName
            )}
          >
            <div className="flex flex-row items-center">
              {icon}
              <DialogTitle
                className="text-color-text-dialog-title text-lg"
                data-cy="basicDialog-title"
              >
                {title}
              </DialogTitle>
              {showCloseButton && (
                <div className="ml-auto flex flex-row gap-4">
                  {renderTitleSlot && renderTitleSlot()}
                  <DialogClose onClick={startExitAnimation} />
                </div>
              )}
            </div>
          </DialogHeader>
          {description && (
            <DialogDescription className="my-4">
              {description}
            </DialogDescription>
          )}
          {children}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
