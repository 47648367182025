import { useRef, useState } from 'react';
import { ChevronDown } from 'lucide-react';

import { cn, localStorageUtils } from '@spektr/client/utils';

import { Skeleton } from '../Skeleton';

export type StickyVideoContainerProps = {
  source: string;
  title: string;
  avatar?: string;
};

export const StickyVideoContainer = ({
  source,
  title,
  avatar,
}: StickyVideoContainerProps) => {
  const lsKey = `spektr.demo.preview.${source}`;
  const lsValue = localStorageUtils.getItem(lsKey);
  const expandedByDefault =
    lsValue === null ? true : (JSON.parse(lsValue) as boolean);
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isExpanded && event.target === videoRef.current) {
      return;
    }

    if (
      isExpanded &&
      event.target !== videoRef.current &&
      !videoRef.current?.paused
    ) {
      videoRef.current?.pause();
    }

    setIsExpanded((wasExpanded) => {
      localStorageUtils.setItem(lsKey, (!wasExpanded).toString());
      return !wasExpanded;
    });
  };

  return (
    <div
      className={cn(
        'fixed bottom-0 right-0 z-50 m-6',
        'flex flex-col',
        'bg-color-bg-primary',
        'rounded-md border',
        'cursor-pointer'
      )}
      onClick={handleContainerClick}
    >
      <div className={isExpanded ? 'm-6' : 'm-4'}>
        <div className="flex w-full items-center justify-between text-sm font-medium leading-4">
          {title}
          <ChevronDown
            className={cn(
              'h-4 w-4',
              isExpanded ? 'rotate-0' : 'rotate-180',
              'transition-transform duration-200',
              'text-color-text-icon-secondary'
            )}
          />
        </div>
      </div>
      <div
        className={cn(
          'relative overflow-hidden',
          isExpanded
            ? 'mx-6 mb-6 h-[281px] w-[478px]'
            : 'ml-4 h-[75px] w-[295px]',
          'transition-all duration-200',
          'drop-shadow-[0_0_20px_rgba(0,0,0,0.5)]'
        )}
      >
        {Boolean(avatar) && (
          <span
            className={cn(
              (!isVideoLoaded || isExpanded) && 'hidden',
              'absolute right-4 top-4 z-10 h-11 w-11 rounded-full',
              'bg-contain bg-center',
              avatar
            )}
            style={{ backgroundColor: 'white' }}
            data-testid="sticky-video-container-avatar"
          />
        )}
        <div className="w-[478px]">
          {!isVideoLoaded && <Skeleton className="h-[281px] w-full" />}
          <div
            className={cn(
              'overflow-hidden rounded-md',
              !isExpanded && 'pointer-events-none'
            )}
          >
            <video
              controls
              ref={videoRef}
              height="281"
              width="478"
              onCanPlayThrough={() => setIsVideoLoaded(true)}
            >
              <source src={source}></source>
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};
