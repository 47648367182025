import { ReactNode } from 'react';

export type ActionRowValueProps = {
  icon?: ReactNode;
  name: ReactNode;
};

export const ActionRowValue = ({ icon, name }: ActionRowValueProps) => {
  return (
    <div className="flex flex-row items-center gap-2">
      {icon}
      <span className="text-sm font-medium">{name}</span>
    </div>
  );
};
