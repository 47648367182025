import { z } from 'zod';

export const FieldConfig = z.object({
  spektrDataField: z.string(),
  prefillDefaultValue: z.boolean().optional().default(true),
  value: z.union([z.string(), z.number(), z.boolean(), z.null()]).optional(),
});

export type FieldConfig = z.infer<typeof FieldConfig>;

export const FieldUiConfig = z.object({
  style: z.object({
    width: z.string().optional(),
  }),
});

export type FieldUiConfig = z.infer<typeof FieldUiConfig>;
