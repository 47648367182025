import { makeApi } from '@zodios/core';

import { z } from 'zod';

import {
  UserSettingsUpdateSchema,
  UserSettingsCreateSchema,
  UserSettingsDtoSchema,
} from './userSettings';

export const userSettingsApi = makeApi([
  {
    method: 'get',
    path: '/user-settings/',
    alias: 'getUserSettingsInWorkspace',
    description: 'Get the user settings in the workspace',
    response: UserSettingsDtoSchema,
  },
  {
    method: 'post',
    path: '/user-settings/',
    alias: 'createUserSettings',
    description: 'Create user settings',
    response: UserSettingsDtoSchema,
    parameters: [
      {
        name: 'input',
        type: 'Body',
        schema: UserSettingsCreateSchema,
      },
    ],
  },
  {
    method: 'put',
    path: '/user-settings/',
    alias: 'updateUserSettingsForWorkspace',
    description: 'Update user settings for your workspace',
    response: UserSettingsDtoSchema,
    parameters: [
      {
        name: 'input',
        type: 'Body',
        schema: UserSettingsUpdateSchema,
      },
    ],
  },
  {
    method: 'post',
    path: '/user-settings/validateEndpoint',
    alias: 'validateEndpointForWorkspace',
    description: 'Validate endpoint for your workspace',
    response: z.void(),
  },
]);
