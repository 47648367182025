import { useMemo } from 'react';

import { useFeatureFlags } from '@spektr/platform-hooks';
import { NodeType, ProcessType } from '@spektr/shared/validators';

import {
  BodaccIcon,
  ComplyAdvantageIcon,
  IconWithBackground,
  KyckrIcon,
  MitIdIcon,
  OpenCorporatesIcon,
  SlackIcon,
  VeriffIcon,
} from '@spektr/client/components';

import { DrawerItemType } from '../types/DrawerItemType';
import { getAvailableItemsForProcess } from '../utils/getAvailableItemsForProcess';

export const useServices = (processType?: ProcessType): DrawerItemType[] => {
  const { kycrAndCaoc, mitid, veriff } = useFeatureFlags();

  return useMemo(() => {
    const services: DrawerItemType[] = [
      {
        type: NodeType.enum.complyAdvantageKyb,
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Corporate',
        icon: <ComplyAdvantageIcon />,
        description:
          'Check for AML hits, sanctions and adverse media on your corporate customers.',
      },
      {
        type: NodeType.enum.complyAdvantageKyc,
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Individual',
        icon: <ComplyAdvantageIcon />,
        description:
          'Check for AML hits, sanctions, adverse media and PEP on your individual customers.',
      },
      {
        type: NodeType.enum.openCorporatesRisk,
        title: 'OpenCorporates',
        icon: <OpenCorporatesIcon />,
        variant: 'openCorporates',
        label: undefined,
        description:
          'Verify a company, and get access numerous datapoints to build logic upon.',
      },
      {
        type: NodeType.enum.openCorporatesMonitoring,
        title: 'OpenCorporates',
        icon: <OpenCorporatesIcon />,
        variant: 'openCorporates',
        label: undefined,
        description:
          'Verify a company, and get access numerous datapoints to build logic upon.',
      },
      {
        type: NodeType.enum.bodacc,
        title: 'BODACC',
        icon: <BodaccIcon />,
        variant: 'bodacc',
        label: undefined,
        description:
          'Check for changes in the legal status of a company, such as bankruptcy or liquidation.',
      },
      {
        type: NodeType.enum.kyckr,
        title: 'Kyckr',
        icon: <KyckrIcon />,
        variant: 'kyckr',
        label: undefined,
        description:
          'Discover Ultimate Beneficial Owners of your corporate customers.',
        hidden: !kycrAndCaoc,
      },
      {
        type: NodeType.enum.slack,
        icon: (
          <IconWithBackground className="bg-color-bg-white">
            <SlackIcon className="h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Slack',
        variant: 'cyan' as const,
        description:
          'Create slack push node that will forward results of the process execution',
      },
      {
        type: NodeType.enum.veriff,
        title: 'Veriff',
        icon: <VeriffIcon className="w-24" />,
        variant: 'veriff',
        label: undefined,
        description:
          'Perform identity verification and fraud prevention checks on individuals with Veriff.',
        hidden: !veriff,
      },
      {
        type: NodeType.enum.mitId,
        title: 'MitId',
        icon: <MitIdIcon className="w-20" />,
        variant: 'mitId',
        label: undefined,
        description: "Verify individuals' identity from Denmark with MitID.",
        hidden: !mitid,
      },
    ];

    return getAvailableItemsForProcess(services, processType);
  }, [processType, kycrAndCaoc, mitid, veriff]);
};
