import { getNumberOfOutcomesForProcesses } from '@spektr/shared/utils';

import { DialogDescription } from '@spektr/client/components';
import { ProcessOutcomes } from '@spektr/model-builder/components';

import { type ProcessSource, type Process } from '@spektr/shared/types';

import { TabView } from '../../components/TabView';

import { type SettingsTab } from '../../types/SettingsTab';

export type SourceTabViewProps = {
  activeTab: SettingsTab;
  process: Process;
  processes: Process[];
  onUpdateSelection: (sources: ProcessSource[]) => void;
};

export const SourceTabView = ({
  activeTab,
  process,
  processes,
  onUpdateSelection,
}: SourceTabViewProps) => {
  const numberOfOutcomes = getNumberOfOutcomesForProcesses(processes);

  return (
    <TabView activeTab={activeTab} name="source">
      <DialogDescription className="my-4">
        Choose the loop source among the branches of existing processes. More
        than one branch can be selected as the loop source, this way a loop can
        be the resolution of multiple use cases.
      </DialogDescription>
      <ProcessOutcomes
        key={numberOfOutcomes}
        process={process}
        processes={processes ?? []}
        onUpdateSelection={onUpdateSelection}
      />
    </TabView>
  );
};
