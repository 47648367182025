import { useCallback, useEffect, useMemo } from 'react';

import { LS_KEYS } from '@spektr/client/constants';

import { localStorageUtils } from '@spektr/client/utils';
import { insightsRecordDatasetParamsSchema } from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';

export function useDatasetIdAsParam(fallbackId?: string) {
  const lsDatasetId =
    localStorageUtils.getItem(LS_KEYS.INSIGHTS_DATASET_ID) ?? '';

  const defaultId = lsDatasetId || fallbackId;

  const { datasetId } = useParsedParams(insightsRecordDatasetParamsSchema);

  const setDatasetId = useCallback((datasetId: string) => {
    localStorageUtils.setItem(LS_KEYS.INSIGHTS_DATASET_ID, datasetId);
  }, []);

  useEffect(() => {
    if (datasetId !== defaultId) {
      localStorageUtils.setItem(LS_KEYS.INSIGHTS_DATASET_ID, datasetId);
    }
  }, []);

  return useMemo(
    () => ({ datasetId, setDatasetId }),
    [datasetId, setDatasetId]
  );
}
