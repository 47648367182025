import { cn } from '@spektr/client/utils';

export const dowJonesContained = cn(
  'bg-color-dowJones',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-dowJones',
  'active:bg-color-dowJones'
);
export const dowJonesOutlined = cn(
  dowJonesContained,
  'border-color-dowJones',
  'text-color-dowJones',
  'hover:bg-color-dowJones/10',
  'active:bg-color-dowJones/20',
  'disabled:border-color-dowJones/50'
);
export const dowJonesText = cn('text-color-dowJones');
