import { z } from 'zod';

export const s3Environment = z.object({
  S3_REGION: z.string().nonempty(),
  S3_BUCKET: z.string().nonempty(),
});

export const s3IngestEnv = z.object({
  S3_INGEST_BUCKET: z.string().nonempty(),
});

export const s3PublicStaticAssetsEnvironment = z.object({
  S3_REGION: z.string().min(1),
  S3_PUBLIC_STATIC_BUCKET: z.string().min(1),
});
