import { HTMLAttributes } from 'react';

export const KyckrIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="110"
    height="28"
    viewBox="0 0 110 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8521_8533)">
      <path
        d="M55.4781 2.08811C55.369 2.08317 55.3043 2.0774 55.2395 2.0774C53.7915 2.07685 52.3434 2.07905 50.8955 2.07355C50.7506 2.073 50.6583 2.12026 50.5664 2.22906C48.0814 5.17261 45.5932 8.11311 43.1068 11.0553C43.0776 11.09 43.0631 11.1372 43.0415 11.1787L42.9803 11.1353V2.08921H38.9039V22.1474H42.9855V21.9102C42.9855 20.1324 42.9847 18.3546 42.9879 16.5771C42.9879 16.4962 42.9997 16.3935 43.0478 16.3377C43.7873 15.4849 44.5334 14.6379 45.2933 13.7727C45.3439 13.8418 45.381 13.8906 45.4163 13.9406C47.2983 16.6284 49.1803 19.3164 51.0614 22.005C51.127 22.099 51.1842 22.1671 51.3227 22.1663C52.8141 22.1592 54.3054 22.1619 55.7965 22.1614C55.8411 22.1614 55.8857 22.1523 55.9512 22.1449C53.2913 18.3051 50.6462 14.4865 47.9984 10.6635C50.4885 7.80844 52.9693 4.96436 55.4781 2.08811Z"
        fill="#022A37"
      />
      <path
        d="M98.5562 7.46096C98.4771 7.45382 98.447 7.44887 98.4168 7.44887C96.9499 7.44832 95.483 7.44667 94.0168 7.45354C93.9392 7.45382 93.842 7.50904 93.7878 7.56924C92.4949 9.0014 91.2073 10.4377 89.9189 11.8733C89.5468 12.2875 89.1738 12.7013 88.8016 13.1154L88.7534 13.0967V2.09503H84.8831V22.1518H88.767V21.9024C88.767 20.5534 88.7692 19.2047 88.7639 17.8557C88.7639 17.7131 88.8069 17.6134 88.9048 17.5134C89.3953 17.0128 89.879 16.5054 90.3657 16.0009C90.4034 15.9622 90.4441 15.9268 90.4938 15.8795C90.5383 15.9455 90.5774 15.9998 90.6128 16.0559C91.8861 18.0393 93.1594 20.022 94.4289 22.0075C94.4998 22.1186 94.5713 22.1666 94.7077 22.1661C96.0692 22.1603 97.4306 22.1625 98.7913 22.1625H98.9879C97.0426 19.1002 95.1191 16.0712 93.1963 13.0431C94.9827 11.1826 96.757 9.3347 98.5562 7.46096Z"
        fill="#022A37"
      />
      <path
        d="M65.5227 7.44478C65.3656 7.44396 65.2986 7.49314 65.2491 7.64258C64.3214 10.4411 63.3882 13.2377 62.4555 16.0345C62.341 16.3782 62.224 16.7213 62.0931 17.1093C62.0546 17.0096 62.0314 16.9548 62.0122 16.8991C60.9587 13.822 59.9041 10.7452 58.8562 7.66619C58.799 7.49808 58.7225 7.44286 58.5446 7.44424C57.3566 7.45303 56.1687 7.44863 54.9807 7.44863H54.7454C54.7774 7.54452 54.7967 7.60905 54.8202 7.67224C55.5807 9.71306 56.3417 11.7535 57.1021 13.7943C58.0463 16.3276 58.9895 18.8613 59.9362 21.3938C59.982 21.5167 59.99 21.6224 59.9362 21.7472C59.7639 22.1458 59.6051 22.5502 59.439 22.9513C59.1426 23.6662 58.6061 24.0451 57.8379 24.072C57.3695 24.0885 56.9002 24.075 56.4198 24.075V27.2018C56.6205 27.2208 56.8162 27.2474 57.0127 27.2567C57.8481 27.2961 58.6823 27.3059 59.5053 27.1183C60.5925 26.8702 61.5 26.3402 62.1803 25.4342C62.6876 24.758 63.07 24.0162 63.3642 23.228C65.3065 18.0241 67.2447 12.8187 69.1838 7.61374C69.2006 7.56894 69.211 7.52144 69.232 7.44891H69.0122C67.8491 7.44891 66.6858 7.45193 65.5227 7.44478Z"
        fill="#022A37"
      />
      <path
        d="M79.1466 17.7536C78.8196 18.3489 78.3306 18.7761 77.6804 19.0006C77.2344 19.1547 76.7763 19.1916 76.3092 19.1465C75.3839 19.0572 74.636 18.6517 74.0924 17.8868C73.6468 17.2601 73.4177 16.553 73.3264 15.7944C73.1971 14.7196 73.2258 13.655 73.6206 12.6354C74.0921 11.4176 74.9222 10.6233 76.27 10.4648C77.5433 10.3153 78.6192 10.8516 79.2189 11.9876C79.3959 12.3233 79.515 12.6904 79.6649 13.0548C80.7453 12.544 81.8385 12.0272 82.9318 11.5101C82.9265 11.4695 82.9257 11.4444 82.9189 11.4214C82.6319 10.5035 82.158 9.69331 81.4709 9.0251C80.4055 7.98773 79.1232 7.39977 77.645 7.23163C76.331 7.08217 75.0529 7.21075 73.8157 7.68745C72.4528 8.21219 71.3925 9.11689 70.599 10.3365C69.8681 11.4599 69.516 12.7184 69.4174 14.0413C69.3086 15.4957 69.4827 16.9175 70.0696 18.2704C70.661 19.6339 71.5893 20.7046 72.8494 21.479C73.7348 22.0233 74.7128 22.3002 75.7381 22.417C76.7055 22.5272 77.6691 22.4761 78.6101 22.228C79.7033 21.9395 80.6805 21.4233 81.4935 20.6153C82.2175 19.896 82.7185 19.0501 82.995 18.0453C81.877 17.5442 80.7589 17.0434 79.6777 16.559C79.4954 16.9763 79.3507 17.3816 79.1466 17.7536Z"
        fill="#022A37"
      />
      <path
        d="M104.411 9.48376V7.46303H100.528V22.1533H104.418V21.9198C104.418 19.5455 104.418 17.1712 104.418 14.7969C104.418 14.2972 104.473 13.8057 104.639 13.3309C104.93 12.5015 105.488 11.9138 106.267 11.5347C106.906 11.2239 107.591 11.1476 108.291 11.1781C108.61 11.1918 108.928 11.2135 109.252 11.2319V7.4144C107.271 7.22043 105.561 7.63251 104.41 9.48376H104.411Z"
        fill="#022A37"
      />
      <path
        d="M20.1863 25.1264L14.3644 18.9601H22.062L27.8933 25.1264H20.1863Z"
        fill="#0CF191"
      />
      <path
        d="M20.8291 0.195956L6.07378 15.9984L6.09291 10.2044L0.602007 4.40687L0.572759 21.5638C0.5703 22.9471 1.38787 24.1683 2.6559 24.6747C3.04459 24.8299 3.45133 24.9084 3.86462 24.9084C4.756 24.9084 5.62167 24.5293 6.24025 23.868L28.4239 0.195679L20.8291 0.195956Z"
        fill="#022A37"
      />
    </g>
    <defs>
      <clipPath id="clip0_8521_8533">
        <rect width="110" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
