export const updateFieldsWithProperties = (
  fields: Record<string, boolean>,
  fieldKey: string,
  newValue: boolean
) => {
  if (!Object.prototype.hasOwnProperty.call(fields, fieldKey)) {
    return fields;
  }

  return Object.keys(fields).reduce((acc, key) => {
    const fieldKeys = key.split('_fields.');
    if (fieldKeys[0] === fieldKey) {
      return {
        ...acc,
        [key]: newValue,
      };
    }
    return {
      ...acc,
      [key]: fields[key],
    };
  }, {});
};
