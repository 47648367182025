import {
  useCreateNode,
  useInvalidateProcessDependencies,
  useUpdateNode,
} from '@spektr/shared/hooks';

import {
  FilterNodeSourceInputSchema,
  NodeCreateInput,
  NodeUpdateInput,
  Process,
} from '@spektr/shared/types';

export function useSaveProcessFilter(process: Process, onClose: () => void) {
  const createMutation = useCreateNode(process.id);
  const updateMutation = useUpdateNode(process.id);

  const { invalidateDependencies } = useInvalidateProcessDependencies();

  const handleCreateNode = (node: NodeCreateInput) => {
    return createMutation.mutateAsync(node);
  };

  const handleUpdateNode = (nodeId: string) => (data: NodeUpdateInput) => {
    return updateMutation.mutateAsync({
      nodeId: nodeId,
      node: data,
    });
  };

  const handleCreateOrUpdate = (source: FilterNodeSourceInputSchema) => {
    const mutation = process.rootId
      ? handleUpdateNode(process.rootId)
      : handleCreateNode;

    return mutation({
      nodeType: 'filter',
      source,
    }).then(() => {
      invalidateDependencies();
      onClose();
    });
  };

  return handleCreateOrUpdate;
}
