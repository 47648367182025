import { Tabbar, TabbarItem, Tooltip } from '@spektr/client/components';
import { useFeatureFlags } from '@spektr/platform-hooks';
import { useParsedParams } from '@spektr/shared/hooks';
import {
  insightsRecordActionsUrl,
  insightsRecordDetailsParamsSchema,
  insightsRecordOwnershipStructureUrl,
  insightsRecordRunsUrl,
} from '@spektr/shared/utils';
import { CirclePlay, ListTodo } from 'lucide-react';

export const InsightsRecordDetailsTabNavigation = () => {
  const { datasetId, recordId } = useParsedParams(
    insightsRecordDetailsParamsSchema
  );
  const { ownershipStructure } = useFeatureFlags();

  return (
    <div className="mb-6">
      <Tabbar>
        <TabbarItem
          icon={<CirclePlay className="h-4 w-4" />}
          to={{ pathname: insightsRecordRunsUrl(datasetId, recordId) }}
        >
          Runs
        </TabbarItem>
        <TabbarItem
          icon={<ListTodo className="h-4 w-4" />}
          to={{ pathname: insightsRecordActionsUrl(datasetId, recordId) }}
        >
          Actions
        </TabbarItem>

        <Tooltip
          content="Coming soon"
          side="right"
          align="end"
          alignOffset={80}
          sideOffset={-30}
          contentClassName="bg-color-bg-tooltip"
          tooltipTriggerProps={{
            disabled: true,
            className: 'ml-4',
          }}
        >
          <TabbarItem icon={<ListTodo className="h-4 w-4" />} to="" disabled>
            Documents
          </TabbarItem>
        </Tooltip>

        {ownershipStructure ? (
          <TabbarItem
            icon={<ListTodo className="h-4 w-4" />}
            to={{
              pathname: insightsRecordOwnershipStructureUrl(
                datasetId,
                recordId
              ),
            }}
          >
            Ownership
          </TabbarItem>
        ) : (
          <Tooltip
            content="Coming soon"
            side="right"
            align="end"
            alignOffset={80}
            sideOffset={-30}
            contentClassName="bg-color-bg-tooltip"
            tooltipTriggerProps={{
              disabled: true,
              className: 'ml-4',
            }}
          >
            <TabbarItem icon={<ListTodo className="h-4 w-4" />} to="" disabled>
              Ownership Structure
            </TabbarItem>
          </Tooltip>
        )}
      </Tabbar>
    </div>
  );
};
