import { VendorServiceName } from '@spektr/client/types';

import { Button, ButtonProps } from './Button';

const VENDOR_VARIANT_MAP: Record<VendorServiceName, ButtonProps['color']> = {
  bodacc: 'bodacc',
  companiesHouse: 'companiesHouse',
  complyAdvantage: 'complyAdvantage',
  creditSafe: 'creditSafe',
  csiWatchdog: 'white',
  dowJones: 'dowJones',
  dunBradstreet: 'dunBradstreet',
  experian: 'white',
  finScan: 'white',
  kyckr: 'kyckr',
  lexisNexis: 'lexisNexis',
  lseg: 'white',
  moody: 'white',
  openCorporates: 'openCorporates',
  transUnion: 'transUnion',
  veriff: 'veriff',
  mitId: 'mitId',
};

export type VendorServiceButtonProps = ButtonProps & {
  serviceName: VendorServiceName;
};

export const VendorServiceButton = ({
  serviceName,
  ...props
}: VendorServiceButtonProps) => {
  return <Button {...props} color={VENDOR_VARIANT_MAP[serviceName]} />;
};
