import { useLayoutEffect, useState } from 'react';
import { useReactFlow, Node, Edge, useStore, ReactFlowState } from 'reactflow';
import { stratify, tree } from 'd3-hierarchy';

import {
  NODE_WIDTH,
  NODE_HEIGHT,
  OUTCOME_NODE_HEIGHT,
} from '../constants/canvas';
import { getNodeById, getSelectedPathNodes } from '../utils/nodes';
import { useStrategyProviderContext } from '../providers';

const layout = tree<Node>().nodeSize([NODE_HEIGHT, NODE_WIDTH]);

const layoutNodes = (
  nodes: Node[],
  edges: Edge[],
  selectedNodeId?: string
): Node[] => {
  if (nodes.length === 0) return [];

  const hierarchy = stratify<Node>()
    .id((d) => d.id)
    .parentId((d: Node) => edges.find((e: Edge) => e.target === d.id)?.source)(
    nodes
  );

  const root = layout(hierarchy);
  const rootNodeHeightHalfDifference = 17;

  return root.descendants().map((d) => ({
    ...d.data,
    position: {
      x: d.y,
      y:
        d.data.type === 'rootNode'
          ? d.x + rootNodeHeightHalfDifference
          : d.data.type === 'unlinkedOutcomeNode'
            ? d.x + OUTCOME_NODE_HEIGHT
            : d.x,
    },
    data: {
      ...d.data.data,
      isSelected: d.id === selectedNodeId,
    },
  }));
};

const getStyledEdges = (
  nodes: Node[],
  edges: Edge[],
  selectedNodeId?: string
) => {
  const selectedNode = getNodeById(nodes, selectedNodeId);
  const selectedPath = selectedNode
    ? getSelectedPathNodes(selectedNode, nodes, edges)
    : [];

  return edges.map((edge) => {
    const isSourceInSelectedPath = selectedPath.some(
      (node) => node.id === edge.source
    );
    const isTargetInSelectedPath = selectedPath.some(
      (node) => node.id === edge.target
    );
    const shouldBeHighlighted =
      isSourceInSelectedPath && isTargetInSelectedPath;

    return {
      ...edge,
      style: {
        strokeOpacity: shouldBeHighlighted ? 1 : 0.4,
        stroke: shouldBeHighlighted ? '#2BBEAF' : '#FFFFFF',
        strokeWidth: shouldBeHighlighted ? 3 : 1,
      },
    };
  });
};

export const useGraphLayout = (selectedNodeId?: string) => {
  const nodeCount = useStore(
    (state: ReactFlowState) => state.nodeInternals.size
  );
  const { getNodes, getNode, setNodes, getEdges, setEdges, setCenter } =
    useReactFlow();
  const { isStrategyPage } = useStrategyProviderContext();
  const [wasCentered, setWasCentered] = useState(false);

  useLayoutEffect(() => {
    const nodesBeforeLayout = getNodes();
    const edgesBeforeLayout = getEdges();

    const nodesAfterLayout = layoutNodes(
      nodesBeforeLayout,
      edgesBeforeLayout,
      selectedNodeId
    );

    const selectedNode = nodesAfterLayout.find(
      (node) => node.id === selectedNodeId
    );

    setNodes(nodesAfterLayout);
    setEdges((prevEdges) =>
      getStyledEdges(nodesAfterLayout, prevEdges, selectedNodeId)
    );

    if (!isStrategyPage && selectedNode?.position && !wasCentered) {
      setCenter(
        selectedNode.position.x + NODE_WIDTH / 2,
        selectedNode.position.y + NODE_HEIGHT / 2,
        {
          zoom: 0.7,
        }
      );
      setWasCentered(true);
    }
  }, [
    nodeCount,
    getEdges,
    getNodes,
    getNode,
    setNodes,
    setEdges,
    selectedNodeId,
  ]);
};
