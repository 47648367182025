import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

import { SettingsTableHead } from '../SettingsTableHead';

export type ApiKeysTableProps = {
  children: React.ReactNode;
};

export const ApiKeysTable = ({ children }: ApiKeysTableProps) => (
  <Table>
    <TableHeader>
      <TableRow>
        <SettingsTableHead>
          <div className="w-52 shrink-0">Name</div>
        </SettingsTableHead>
        <SettingsTableHead className="w-full">API Key token</SettingsTableHead>
        <SettingsTableHead>Actions</SettingsTableHead>
      </TableRow>
    </TableHeader>
    <TableBody className="overflow-x-hidden">{children}</TableBody>
  </Table>
);
