import { Node, Edge } from 'reactflow';

import {
  ProcessNode,
  Process,
  isOutcomeNodeType,
  isRouterEdgeType,
  isRouterNodeType,
  ProcessLink,
  isKyckrNodeType,
  isActionFormNodeType,
} from '@spektr/shared/types';

import { mapToArray } from '../mapToArray';

import {
  createAddNewGraphNode,
  createLeafEdge,
  factoryGraphNode,
  createWorkflowGraphEdge,
  createSegmentEdge,
  createVirtualGraphNode,
} from './creators';
import { isLeaf } from './nodes';

/**
 * Use cases:
 *    1. Process has no nodes
 *    2. Process has only the filter node
 *    3. Process ends with a router node
 *    4. Process ends with a basic node
 */
export function generateGraph(process: Process, links: ProcessLink[]) {
  const nodes = mapToArray<ProcessNode>(process.nodes);

  if (nodes.length === 0) {
    return {
      nodes: [createVirtualGraphNode({ process })],
      edges: [],
    };
  }

  const graphNodes: Node[] = [];
  const graphEdges: Edge[] = [];

  nodes.forEach((node) => {
    const meta = {
      links: links.filter((link) => link.source.outcomeId === node.id),
    };

    graphNodes.push(factoryGraphNode({ process, node, meta }));

    node.adj.forEach((child) => {
      const predecessorNode = node;
      const successorNode = nodes.find((n) => n.id === child.id);

      const isRouterSegment = isRouterNodeType(node) && isRouterEdgeType(child);
      const isKyckrSegment = isKyckrNodeType(node) && child.isFinal;
      const isActionFormSegment = isActionFormNodeType(node) && child.isFinal;

      if (isRouterSegment || isKyckrSegment || isActionFormSegment) {
        graphEdges.push(
          createSegmentEdge(node.id, child.id, {
            process,
            outcome: child,
            predecessorNode,
            successorNode,
          })
        );
      } else {
        graphEdges.push(
          createWorkflowGraphEdge(node.id, child.id, {
            process,
            predecessorNode,
            successorNode,
          })
        );
      }
    });
    if (isLeaf(node) && !isOutcomeNodeType(node) && !isRouterNodeType(node)) {
      graphNodes.push(
        createAddNewGraphNode(node.id, {
          process,
          node,
          meta,
          predecessorNode: node,
        })
      );
      graphEdges.push(createLeafEdge(node.id, `new-leaf-${node.id}`));
    }
  });

  return {
    nodes: graphNodes,
    edges: graphEdges,
  };
}
