import { HTMLAttributes, forwardRef } from 'react';

export type DescriptionProps = HTMLAttributes<HTMLSpanElement>;

export const Description = forwardRef<HTMLSpanElement, DescriptionProps>(
  ({ children, ...props }, ref) => (
    <span
      ref={ref}
      {...props}
      className={'text-color-text-form-viewer-description text-xs font-medium'}
    >
      {children}
    </span>
  )
);
Description.displayName = 'Description';
