import { useRef } from 'react';
import { useDebounce, useIntersection } from 'react-use';

import { Spinner } from '../Spinner';

type InfiniteScrollProps = {
  className?: string;
  children: React.ReactNode;
  hasMoreItems: boolean;
  fetchMoreItems: () => void;
  isFetching?: boolean;
  loadingComponent?: React.ReactNode;
  loadMoreText?: string;
};

export const InfiniteScroll = ({
  className,
  children,
  hasMoreItems,
  fetchMoreItems,
  isFetching = false,
  loadMoreText = 'Loading more...',
}: InfiniteScrollProps) => {
  const lastElementRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(lastElementRef, {});
  const isLoading = isFetching || hasMoreItems;
  const isIntersecting = intersection?.isIntersecting && hasMoreItems;

  useDebounce(
    () => {
      if (isIntersecting) {
        fetchMoreItems();
      }
    },
    200,
    [isIntersecting]
  );

  return (
    <div className={className}>
      {children}
      {hasMoreItems && <div ref={lastElementRef} />}
      {isLoading && (
        <div className="flex items-center justify-center gap-1 p-4">
          <Spinner className="h-4 w-4" />
          {loadMoreText}
        </div>
      )}
    </div>
  );
};
