import { useEffect, useState } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { Info } from 'lucide-react';

import { extractDatasetParamsSchema } from '@spektr/shared/utils';

import { datasetFieldsUpdateSchema } from '@spektr/shared/validators';
import {
  DATASETS_KEYS,
  DatasetApiClient,
  connectionDataSetByIdQuery,
} from '@spektr/client/services';

import { cn } from '@spektr/client/utils';
import { useGoToConnectionHub, useParsedParams } from '@spektr/shared/hooks';

import { Button, toast } from '@spektr/client/components';

import { DatasetField, DatasetFieldsUpdate } from '@spektr/shared/types';

import { getDraftOrDefaultValue } from '../../utils/getDraftOrDefaultValue';
import { fieldsArrayToObject } from '../../utils/fieldsArrayToObject';

import { FormValues } from '../../validators/formValuesSchema';

import { DatasetTable } from '../DatasetTable';
import { Footer } from '../Footer';

export const DatasetDialogContent = () => {
  const queryClient = useQueryClient();
  const { datasetId } = useParsedParams(extractDatasetParamsSchema);
  const goToConnectionHub = useGoToConnectionHub();
  const [hasDraftChanges, setHasDraftChanges] = useState(
    !!sessionStorage.getItem(datasetId)
  );

  const { data } = useSuspenseQuery({
    ...connectionDataSetByIdQuery(datasetId),
  });

  const formMethods = useForm({
    defaultValues: getDraftOrDefaultValue(datasetId, data),
  });

  useEffect(() => {
    const subscription = formMethods.watch((value) => {
      sessionStorage.setItem(datasetId, JSON.stringify(value));
    });
    return () => subscription.unsubscribe();
  }, [datasetId, formMethods, formMethods.watch]);

  const updateDatasetMutation = useMutation({
    mutationFn: (data: DatasetFieldsUpdate) =>
      DatasetApiClient.getClient().updateDatasetFields(data, {
        params: {
          id: datasetId,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: DATASETS_KEYS.ALL() });
      queryClient.invalidateQueries({
        queryKey: DATASETS_KEYS.BY_ID(datasetId),
      });
      sessionStorage.removeItem(datasetId);
      goToConnectionHub();
    },
  });

  const handleUpdate = async (fields: DatasetField[], fieldId?: string) => {
    const result = datasetFieldsUpdateSchema.safeParse({
      fields,
      fieldId,
    });

    if (result.success === false) {
      toast.error({
        title: 'Your dataset cannot be saved!',
        description:
          'The dataset you are trying to create has an invalid format.',
      });

      console.error('Invalid dataset input', result.error);
    }

    updateDatasetMutation.mutate({
      fieldId: fieldId!,
      fields,
    });
  };

  const handleSubmit = ({ fields: entries, fieldId }: FormValues) => {
    const fields = Object.entries(entries).map(([name, values]) => {
      const isNullable =
        data.fields.find((field) => field.name === name)?.isNullable ?? false;

      return {
        name,
        type: values.type,
        isIncluded: values.isIncluded,
        isNullable,
      };
    });

    handleUpdate(fields, fieldId);
  };

  const handleDiscard = () => {
    sessionStorage.removeItem(datasetId);
    formMethods.setValue('fields', fieldsArrayToObject(data.fields));
    formMethods.setValue('fieldId', data.fieldId);
    setHasDraftChanges(false);
  };

  return (
    <>
      {hasDraftChanges && (
        <div
          className={cn(
            'flex flex-row items-center gap-2',
            'mb-3 rounded-md border p-2',
            'border-color-yellow',
            'text-color-yellow'
          )}
        >
          <Info className="h-5 w-5" />
          <div className={cn('flex flex-row items-center', 'w-full')}>
            <p className="text-sm">
              We have detected unsaved changes in your dataset. Do you want to
              discard them?
            </p>
            <Button
              className="ml-auto"
              color="yellow"
              variant="text"
              size="sm"
              onClick={handleDiscard}
            >
              Discard
            </Button>
          </div>
        </div>
      )}
      <form className="form" onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <FormProvider {...formMethods}>
          <div className="flex h-full grow flex-col">
            <DatasetTable
              isEditable={data.meta?.isEditable}
              fieldId={getDraftOrDefaultValue(datasetId, data).fieldId}
              fields={data.fields}
            />
            <Footer isEditable={data.meta?.isEditable} />
          </div>
        </FormProvider>
      </form>
    </>
  );
};
