import { cn } from '@spektr/client/utils';
import { Input } from '@spektr/client/components';

import { RightOperandInput } from '../right-operands';
import { SelectSpektrFieldCombobox } from '../left-operands';
import {
  SelectBetweenGroupsOperator,
  SelectSpektrfieldOperator,
  SelectWithinGroupsOperator,
} from '../selects';
import {
  IncompletePredicate,
  IncompleteRuleGroup,
  getLowestCommonAncestor,
  preorderRulesInGroup,
  preorderSortGroups,
} from '../tree-helpers';

type RuleGroupContentProps = {
  group: IncompleteRuleGroup;
  icon?: (ruleId: string, innerGroupId: string) => React.ReactNode;
};

export const RuleGroupContent = ({ group, icon }: RuleGroupContentProps) => {
  const sortedInnerGroups = preorderSortGroups(group.rule);

  const isMatrix = group.rule.type === 'matrix';

  return (
    <div className="flex flex-col gap-3 px-3 py-4">
      {sortedInnerGroups.map((innerGroup, index) => {
        const sortedRules = preorderRulesInGroup(innerGroup);
        const prevGroup: IncompletePredicate | undefined =
          sortedInnerGroups[index - 1];

        const lowestCommonAncestorOfGroups = getLowestCommonAncestor(
          group.rule,
          innerGroup.id,
          prevGroup?.id
        );

        return (
          <div key={innerGroup.id} className="flex flex-col gap-3">
            {lowestCommonAncestorOfGroups && (
              <div className="flex justify-center">
                <div className="flex flex-1 items-center pr-7">
                  <div className="border-color-border-primary flex-grow border-t" />
                  <div className="px">
                    <SelectBetweenGroupsOperator
                      id={lowestCommonAncestorOfGroups.id}
                      value={lowestCommonAncestorOfGroups.operator}
                    />
                  </div>
                  <div className="border-color-border-primary flex-grow border-t" />
                </div>
              </div>
            )}
            {sortedRules.map((rule, index) => {
              const prevRule: IncompletePredicate | undefined =
                sortedRules[index - 1];

              const lowestCommonAncestor = getLowestCommonAncestor(
                group.rule,
                rule.id,
                prevRule?.id
              );

              return (
                <div
                  key={rule.id}
                  className={cn(
                    'grid w-full grid-cols-[1fr,auto,1fr,auto] gap-3',
                    icon
                      ? 'grid-cols-[1fr,auto,1fr,auto]'
                      : 'grid-cols-[1fr,auto,1fr]'
                  )}
                >
                  <div className="flex w-full items-center gap-3">
                    {lowestCommonAncestor && (
                      <div className="min-w-fit">
                        <SelectWithinGroupsOperator
                          groupId={innerGroup.id}
                          value={lowestCommonAncestor.operator}
                          disabled={isMatrix}
                        />
                      </div>
                    )}

                    {isMatrix ? (
                      <Input
                        className="text-ellipsis"
                        dimension="small"
                        value={rule.left as string}
                        disabled
                      />
                    ) : (
                      <SelectSpektrFieldCombobox ruleId={rule.id} />
                    )}
                  </div>
                  <div className="min-w-9">
                    {isMatrix ? (
                      <Input
                        className="bg-color-bg-accent h-7 w-10 border-0 text-center"
                        dimension="small"
                        value="="
                        disabled
                      />
                    ) : (
                      <SelectSpektrfieldOperator ruleId={rule.id} />
                    )}
                  </div>
                  {isMatrix ? (
                    <Input
                      className="text-ellipsis"
                      dimension="small"
                      value={rule.right as string}
                      disabled
                    />
                  ) : (
                    <RightOperandInput ruleId={rule.id} />
                  )}
                  {!isMatrix && icon?.(rule.id, innerGroup.id)}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
