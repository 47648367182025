import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateNodeSchema } from '@spektr/shared/validators';

import {
  LoopApiClient,
  getProcessQueryKey,
  LOOPS_KEYS,
  getTopologyQueryKey,
  getProcessesQueryKey,
} from '@spektr/client/services';

import { NodeUpdateInput } from '@spektr/shared/types';
import { invalidateMultipleQueries } from '@spektr/client/utils';

export type UpdateLoopNodeMutationParams = {
  node: NodeUpdateInput;
  nodeId: string;
};

export function useUpdateLoopNode(loopId: string, handleSuccess?: () => void) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ nodeId, node }: UpdateLoopNodeMutationParams) => {
      const parsedBody = updateNodeSchema.safeParse(node);

      if (!parsedBody.success) {
        throw new Error('Invalid node body');
      }

      return LoopApiClient.getClient().updateNodeById(parsedBody.data, {
        params: { loopId, nodeId },
      });
    },
    onSuccess: async (_, variables) => {
      handleSuccess?.();

      if (variables.node.nodeType === 'router') {
        await queryClient.resetQueries({
          queryKey: getTopologyQueryKey(),
        });
      }

      const queriesToInvalidate = [
        // TODO: @Alex this should be a 'loop' query key
        { queryKey: getProcessQueryKey(loopId) },
        { queryKey: LOOPS_KEYS.DETAILS(loopId) },
      ];

      await invalidateMultipleQueries(queryClient, queriesToInvalidate);

      if (variables?.node.nodeType === 'loopSource') {
        await queryClient.resetQueries({
          queryKey: getTopologyQueryKey(),
        });
        await queryClient.resetQueries({ queryKey: getProcessesQueryKey() });
      }
    },
  });

  return mutation;
}
