import { FallbackProps } from 'react-error-boundary';
import { TableCell, TableRow } from '@spektr/client/components';

import { TeamTable } from '../TeamTable';

export type TableErrorProps = FallbackProps;

export const TableError = (_: TableErrorProps) => (
  <TeamTable>
    <TableRow>
      <TableCell className="p-4">There was an error!</TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  </TeamTable>
);
