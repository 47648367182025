import { Outlet, Navigate } from 'react-router-dom';

import { rootUrl } from '@spektr/shared/utils';
import { cn } from '@spektr/client/utils';
import { useDemo } from '@spektr/client/providers';

export const DemoLayout = () => {
  const { isDemo } = useDemo();

  if (!isDemo) {
    return <Navigate to={rootUrl()} replace />;
  }
  return (
    <main
      className={cn(
        'flex',
        'h-full w-full',
        `bg-auth bg-auto bg-center bg-no-repeat`,
        'overflow-auto'
      )}
    >
      <div className="bg-color-bg-primary m-auto flex flex-col items-center gap-4 rounded-xl p-6">
        <Outlet />
      </div>
    </main>
  );
};
