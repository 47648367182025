import { cn } from '@spektr/client/utils';

import { Tooltip } from '@spektr/client/components';

import { useFormRouter } from '../../hooks/useFormRouter';

import { FormNavigationItem } from '../../components/FormNavigationItem';
import { NAVIGATION_ITEMS } from '../../constants/visuals';

import { type FormNavigationItem as NavigationItemType } from '../../types';

export type FormNavigationProps = {
  items?: NavigationItemType[];
};

export const FormNavigation = ({
  items = NAVIGATION_ITEMS,
}: FormNavigationProps) => {
  const { isActiveRoute, navigateTo } = useFormRouter();

  return (
    <div
      className={cn(
        'flex shrink-0 flex-col gap-3',
        'h-full w-full',
        'border-r p-3',
        'bg-color-bg-moonraker'
      )}
    >
      {items.map(({ icon, title, route }) => (
        <Tooltip
          key={`${route}-${isActiveRoute(route)}`}
          content={title}
          side="right"
          sideOffset={6}
        >
          <FormNavigationItem
            isActive={isActiveRoute(route)}
            icon={icon}
            onClick={() => navigateTo(route)}
          />
        </Tooltip>
      ))}
    </div>
  );
};
