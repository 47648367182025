import { icons } from 'lucide-react';

import { BasicDialog, iconBoxVariants } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

type RiskMatrixDialogProps = {
  children: React.ReactNode;
  isUpdate?: boolean;
  onClose: () => void;
};

export const RiskMatrixDialog = ({
  children,
  isUpdate = false,
  onClose,
}: RiskMatrixDialogProps) => {
  const DialogIcon = isUpdate ? icons['Box'] : icons['CirclePlus'];

  return (
    <BasicDialog
      title={isUpdate ? 'Risk matrix' : 'New Risk Matrix'}
      dialogHeaderClassName="mb-6"
      className="text-color-text-dialog-default max-w-[721px]"
      icon={
        <DialogIcon
          className={cn(
            iconBoxVariants({ variant: 'cyan' }),
            'mr-2 h-6 w-6 p-1.5'
          )}
        />
      }
      defaultOpen
      onClose={onClose}
      dialogContentDataCy="riskMatrixDialog"
    >
      {children}
    </BasicDialog>
  );
};
