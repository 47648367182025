import { SelectProps } from '@radix-ui/react-select';
import { Select } from '@spektr/client/components';

export type SelectBooleanOperatorProps = SelectProps & {
  'trigger-data-testid'?: string;
};
export const SelectBooleanOperator = (props: SelectBooleanOperatorProps) => (
  <Select
    {...props}
    size="small"
    selectTriggerProps={{
      className: 'border-transparent hover:border-color-border-select-hover',
      showChevron: false,
      'data-testid': props['trigger-data-testid'],
    }}
    options={[
      { value: 'and', label: 'and' },
      { value: 'or', label: 'or' },
    ]}
  />
);
