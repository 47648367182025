import { CopyPlus, CreditCard, EllipsisVertical, Trash2 } from 'lucide-react';

import {
  DropdownMenuComboBox,
  DropdownOption,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

type SegmentMenuComboBoxProps = {
  onActionClick: (value: string) => void;
  isMatrixSegment?: boolean;
};

export const SegmentMenuComboBox = ({
  onActionClick,
  isMatrixSegment = false,
}: SegmentMenuComboBoxProps) => {
  const options: DropdownOption[] = [
    {
      label: 'Add Rule Group',
      type: 'item',
      value: 'add-rule-group',
      icon: <CreditCard className="h-4 w-4" />,
      variant: 'default',
      disabled: isMatrixSegment,
    },
    {
      type: 'separator',
      value: 'separator-1',
    },
    {
      label: 'Duplicate',
      type: 'item',
      value: 'duplicate',
      icon: <CopyPlus className="h-4 w-4" />,
      variant: 'default',
      disabled: true || isMatrixSegment,
    },
    {
      type: 'separator',
      value: 'separator-2',
    },
    {
      label: 'Delete',
      type: 'item',
      value: 'delete',
      icon: <Trash2 className="h-4 w-4" />,
      variant: 'danger',
    },
  ];

  return (
    <DropdownMenuComboBox
      modal
      trigger={
        <EllipsisVertical
          className={cn(
            'ml-auto h-6 w-6',
            'stroke-color-stroke-default',
            'h-5 cursor-pointer',
            'hover:stroke-color-stroke-default--hover',
            'stroke-2'
          )}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      }
      options={options}
      onClick={onActionClick}
    />
  );
};
