import { memo, useMemo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { RefreshCw, SearchIcon, SmilePlus, Unplug, icons } from 'lucide-react';

import { OutcomeNode as OutcomeNodeType } from '@spektr/shared/types';
import { RBAC } from '@spektr/shared/rbac';
import {
  useCreateProcessWithSource,
  useDeleteNode,
} from '@spektr/shared/hooks';

import { cn } from '@spektr/client/utils';
import { usePermissionsContext } from '@spektr/client/providers';
import {
  AlertDialog,
  CalculatorIcon,
  DropdownMenuComboBox,
  DropdownOption,
  toast,
} from '@spektr/client/components';
import { NODE } from '@spektr/shared/components';

import { ProcessConnectDialog } from '../ProcessConnectDialog';

import { GraphNodeProps } from './types';

import { NodeDeleteButton } from './NodeDeleteButton';

type OutcomeNodeProps = GraphNodeProps<OutcomeNodeType>;

const UNLINKED_OUTCOME_ACTIONS: DropdownOption[] = [
  {
    label: 'Connect a Process',
    type: 'item',
    value: 'connect',
    icon: <Unplug className="h-4 w-4" />,
  },
  {
    label: 'Create a Risk Process',
    type: 'item',
    value: 'create-risk-process',
    icon: <CalculatorIcon className="stroke-color-yellow h-4 w-4" />,
  },
  {
    label: 'Create a Monitoring Process',
    type: 'item',
    value: 'create-monitoring-process',
    icon: <SearchIcon className="stroke-color-cyan h-4 w-4" />,
  },
  {
    label: 'Create an Onboarding Process',
    type: 'item',
    value: 'create-onboarding',
    icon: <SmilePlus className="stroke-color-blue h-4 w-4" />,
  },
  {
    label: 'Create a Loop',
    type: 'item',
    value: 'create-loop',
    icon: <RefreshCw className="stroke-color-red h-4 w-4" />,
  },
];

export const OutcomeNode = memo(({ data }: OutcomeNodeProps) => {
  const { hasPermission } = usePermissionsContext();
  const { process, node, meta } = data;
  const deleteNodeMutation = useDeleteNode(
    process.id,
    node.id,
    process.type === 'loop'
  );
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [showConnectionDialog, setShowConnectionDialog] = useState(false);

  const hasProcessLink = meta.links.length > 0;
  const canDeleteNode =
    hasPermission(RBAC.ACTIONS.NODE.DELETE) && !hasProcessLink;
  const isLoopOutcome = process.type === 'loop';
  const processOutcomeIconName = hasProcessLink
    ? 'CircleCheck'
    : 'CircleDotDashed';
  const LinkIcon = icons[isLoopOutcome ? 'CircleStop' : processOutcomeIconName];

  const handleDeleteNode = async () => {
    await deleteNodeMutation.mutateAsync();
    setShowDeleteConfirmDialog(false);
  };
  const createProcess = useCreateProcessWithSource(process.id, node.id);

  const unlinkedActions = useMemo(() => {
    const actions =
      process.type !== 'onboarding'
        ? UNLINKED_OUTCOME_ACTIONS.filter(
            (action) => action.value !== 'create-onboarding'
          )
        : UNLINKED_OUTCOME_ACTIONS;

    return actions.reduce((acc, item, index) => {
      acc.push(item);

      if (index < actions.length - 1) {
        acc.push({ type: 'separator', value: `separator-${index}` });
      }

      return acc;
    }, [] as DropdownOption[]);
  }, [process.type]);

  const handleActionClick = (action: string) => {
    switch (action) {
      case 'create-risk-process':
        createProcess('risk');
        break;
      case 'create-monitoring-process':
        createProcess('monitoring');
        break;
      case 'create-loop':
        createProcess('loop');
        break;
      case 'create-onboarding':
        createProcess('onboarding');
        break;
      case 'connect':
        setShowConnectionDialog(true);
        break;
      default:
        break;
    }
  };

  const handleDelete = () => {
    if (canDeleteNode) {
      setShowDeleteConfirmDialog(true);
      return;
    }

    if (hasProcessLink) {
      toast.error({
        title: 'Error',
        description:
          'You cannot delete this outcomes because it is linked to another process, remove the link before deleting it.',
      });
    }
  };

  return (
    <div className="group relative flex h-[80px] items-center justify-center">
      <DropdownMenuComboBox
        modal
        trigger={
          <div
            className={cn(
              'flex items-center gap-2',
              `h-[${NODE.OUTCOME.HEIGHT}px] w-[${NODE.OUTCOME.WIDTH}px] p-2.5`,
              'border border-solid shadow-lg duration-200 sm:rounded-lg',
              hasProcessLink
                ? 'bg-color-cyan text-color-text-secondary'
                : 'bg-color-bg-accent text-color-text-primary',
              isLoopOutcome && 'border-color-cyan text-color-cyan'
            )}
          >
            <Handle
              type="target"
              id={node.id}
              position={Position.Left}
              className="opacity-0"
            />
            <LinkIcon className={cn('h-4 w-4')} />
            <p className="text-xs">
              {isLoopOutcome ? 'End' : hasProcessLink ? 'Linked' : 'Unlinked'}
            </p>
          </div>
        }
        triggerProps={{
          disabled: isLoopOutcome || hasProcessLink,
        }}
        options={unlinkedActions}
        onClick={handleActionClick}
      />
      <NodeDeleteButton iconClassName="h-4 w-4" onClick={handleDelete} />

      <AlertDialog
        open={!!showDeleteConfirmDialog}
        title="Are you sure?"
        paragraph="You will permanently delete this outcome from the process."
        onCancel={() => setShowDeleteConfirmDialog(false)}
        cancel="Cancel"
        onConfirm={handleDeleteNode}
        confirm="Yes, delete"
        confirmDataCy="Yes-delete"
        disableButtons={deleteNodeMutation.isPending}
      />

      {showConnectionDialog && (
        <ProcessConnectDialog
          sourceProcessId={process.id}
          sourceOutcomeId={node.id}
          handleClose={() => setShowConnectionDialog(false)}
        />
      )}
    </div>
  );
});
