import { z } from 'zod';

import { FieldUiConfig } from './FieldConfig';

export const DividerField = z.object({
  id: z.string(),
  ui: FieldUiConfig.optional(),
  type: z.literal('divider'),
});

export type DividerField = z.infer<typeof DividerField>;

export const isDividerField = (field: unknown): field is DividerField => {
  return DividerField.safeParse(field).success;
};
