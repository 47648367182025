import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import {
  ProcessApiClient,
  getLiveVersionByProcessIdQueryKey,
  getVersionOverviewByProcessId,
  getVersionOverviewByProcessIdQueryKey,
} from '@spektr/client/services';

import { PublishVersionItem } from '../../components/PublishVersionItem';

import { useGetPublishDisabledReason } from './useGetPublishDisabledReason';

export type PublishVersionProps = {
  processId: string;
  title: string;
  description: string;
};

export const PublishVersion = ({
  processId,
  title,
  description,
}: PublishVersionProps) => {
  const queryClient = useQueryClient();

  const { data: overview } = useSuspenseQuery(
    getVersionOverviewByProcessId(processId)
  );

  const disabledReason = useGetPublishDisabledReason(
    overview.hasUnpublishedChanges
  );

  const makeLiveVersionMutation = useMutation({
    mutationFn: ({ versionId }: { versionId: string }) =>
      ProcessApiClient.getClient().launchVersion(undefined, {
        params: { processId, versionId },
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: getLiveVersionByProcessIdQueryKey(processId),
      }),
  });
  const createProcessVersionMutation = useMutation({
    mutationFn: () =>
      ProcessApiClient.getClient().createProcessVersion(undefined, {
        params: {
          processId: processId,
        },
      }),
    onSuccess: (version) => {
      queryClient.invalidateQueries({
        queryKey: getVersionOverviewByProcessIdQueryKey(processId),
      });
      return makeLiveVersionMutation.mutate({ versionId: version.id });
    },
  });

  return (
    <PublishVersionItem
      title={title}
      description={description}
      hasUnreleasedChanges={overview?.hasUnpublishedChanges}
      isDisabled={
        createProcessVersionMutation.isPending ||
        makeLiveVersionMutation.isPending
      }
      disabledReason={disabledReason}
      onClick={() => createProcessVersionMutation.mutate()}
    />
  );
};
