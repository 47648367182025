import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { RecordFilters, getAllRecords } from '@spektr/client/services';

import { DatasetRecord } from '../types';

export const useGetDatasetRecords = (
  datasetId: string,
  filters?: RecordFilters
) => {
  const { data: records } = useSuspenseQuery({
    ...getAllRecords(datasetId, filters),
    refetchInterval: (data) => (data.state.data?.docs.length ? false : 10000),
  });

  const parsedRecords: DatasetRecord[] = useMemo(
    () =>
      records.docs.map((record) => ({
        id: record.spektrId,
        fields: Object.keys(record).reduce((acc, key) => {
          return key !== 'spektrId'
            ? {
                ...acc,
                [key]: record[key],
              }
            : acc;
        }, {}),
      })),
    [records]
  );

  return useMemo(
    () => ({ records: parsedRecords, totalPages: records.totalPages }),
    [parsedRecords, records.totalPages]
  );
};
