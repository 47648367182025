import axios from 'axios';
import { useNavigate, useRouteError } from 'react-router-dom';

import { rootUrl } from '@spektr/shared/utils';

import { ErrorPage } from './ErrorPage';

export function GlobalErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(rootUrl());
  };

  if (axios.isAxiosError(error)) {
    return (
      <ErrorPage
        title={error.response?.data?.message ?? 'Something went wrong!'}
        actionLabel="Get back to home page"
        onAction={handleBack}
      />
    );
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error;
}
