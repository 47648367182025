import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '@spektr/client/utils';
import { VariantProps, cva } from 'class-variance-authority';

const variants = cva(
  [
    'h-4 w-4 shrink-0',
    'border rounded-full',
    'aspect-square',
    'focus:outline-none',
    'focus-visible:ring-ring focus-visible:ring-1',
    'disabled:cursor-not-allowed disabled:opacity-50',
  ],
  {
    variants: {
      inverted: {
        true: ['border-color-border-input'],
        false: ['border-color-border-input', 'text-color-text-radio'],
      },
    },
    defaultVariants: {
      inverted: false,
    },
  }
);

const indicatorVariants = cva(['h-2 w-2', 'rounded-full'], {
  variants: {
    inverted: {
      true: 'bg-color-bg-primary',
      false: 'bg-color-bg-secondary',
    },
  },
  defaultVariants: {
    inverted: false,
  },
});

export type RadioGroupItemProps = ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Item
> &
  VariantProps<typeof variants>;

export const RadioGroupItem = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, inverted, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(variants({ inverted, className }))}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="border-color-border-input flex items-center justify-center">
        <div className={cn(indicatorVariants({ inverted }))} />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;
