import { useState } from 'react';
import { useFetcher, useSearchParams } from 'react-router-dom';
import { Button, Input, Label } from '@spektr/client/components';

export const ConfirmResetPasswordForm = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [allowError, setAllowError] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || '';
  const fetcher = useFetcher();

  const error =
    fetcher?.data?.action === 'error'
      ? fetcher?.data?.error?.message
      : undefined;

  const showError = error && allowError && fetcher.state === 'idle';

  return (
    <fetcher.Form
      method="post"
      onChange={() => setAllowError(false)}
      onSubmit={() => setAllowError(true)}
    >
      <input name="formId" hidden defaultValue="confirm-reset-password" />
      <input hidden type="email" name="email" defaultValue={email} />
      <div className="text-color-text-subtext space-y-6">
        <div>
          <Label htmlFor="confirmationCode">Confirmation code:</Label>
          <Input
            className="mt-2"
            id="confirmationCode"
            name="confirmationCode"
            type="text"
            required
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="newPassword">New password:</Label>
          <Input
            className="mt-2"
            id="newPassword"
            required
            type="password"
            name="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        {showError && <div className="text-color-red text-sm">{error}</div>}
      </div>
      <Button
        type="submit"
        className="mt-8 w-full"
        disabled={!confirmationCode || !newPassword || fetcher.state !== 'idle'}
      >
        {fetcher.state === 'submitting'
          ? 'Updating password...'
          : 'Update password'}
      </Button>
    </fetcher.Form>
    // </AuthLayout>
  );
};
