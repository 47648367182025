import { Suspense } from 'react';
import axios from 'axios';

import {
  InsightsErrorDashboard,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { RecordsProvider } from './providers';

import { SkeletonToolbar } from './components/SkeletonToolbar';
import { TableToolbar } from './components/TableToolbar';
import { RecordsTable } from './components/RecordsTable';
import { SkeletonTable } from './components/SkeletonTable';

export const InsightsRecordsDashboard = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        const errorMsg = axios.isAxiosError(error)
          ? error.message
          : 'An error has occurred while retrieving the customer records!';
        return <InsightsErrorDashboard message={errorMsg} />;
      }}
    >
      <RecordsProvider>
        <Suspense fallback={<SkeletonToolbar />}>
          <TableToolbar />
        </Suspense>
        <div className="mt-4">
          <Suspense fallback={<SkeletonTable />}>
            <RecordsTable />
          </Suspense>
        </div>
      </RecordsProvider>
    </SpektrErrorBoundary>
  );
};
