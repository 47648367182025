import { useNavigate } from 'react-router-dom';

import { Process } from '@spektr/shared/types';
import {
  getSourcesFromRootNode,
  getRootNode,
  loopDetailsUrl,
  processBuilderUrl,
} from '@spektr/shared/utils';
import { useSaveProcessFilter, useUpdateLoopNode } from '@spektr/shared/hooks';

import { FooterActions } from './FooterActions';

type ProcessConnectDialogFooterProps = {
  selectedProcess: Process;
  sourceProcessId: string;
  sourceOutcomeId: string;
  isConnectDisabled: boolean;
  onClose: () => void;
};

export const FooterWithSelectedProcess = ({
  selectedProcess,
  sourceProcessId,
  sourceOutcomeId,
  isConnectDisabled,
  onClose,
}: ProcessConnectDialogFooterProps) => {
  const navigate = useNavigate();
  const updateSelectedProcessFilterNode = useSaveProcessFilter(
    selectedProcess,
    onClose
  );
  const updateSelectedLoopNode = useUpdateLoopNode(selectedProcess.id, onClose);

  const newSource = {
    processId: sourceProcessId,
    outcomeId: sourceOutcomeId,
  };

  const handleConnect = async () => {
    if (selectedProcess.type === 'loop') {
      const rootNode = getRootNode(selectedProcess);

      if (!rootNode) {
        throw new Error('Root node not found');
      }

      const sources = getSourcesFromRootNode(selectedProcess);

      await updateSelectedLoopNode.mutateAsync({
        nodeId: rootNode.id,
        node: {
          nodeType: 'loopSource',
          sources: [...sources, newSource],
        },
      });

      navigate(loopDetailsUrl(selectedProcess.id));
    } else {
      await updateSelectedProcessFilterNode({
        ...newSource,
        type: 'process',
      });

      navigate(processBuilderUrl(selectedProcess.id));
    }
  };

  return (
    <FooterActions
      isConnectDisabled={isConnectDisabled}
      onClose={onClose}
      handleConnect={handleConnect}
    />
  );
};
