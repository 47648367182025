import { useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { FullPageDialog } from '@spektr/client/components';

import { type FormBranding } from '@spektr/shared/validators';
import { type MoonrakerForm as MoonrakerFormType } from '@spektr/moonraker-types';

import { useHandleSerializedSave } from './hooks/useHandleSerializedSave';
import { useDeserializedAssistingContent } from './hooks/useDeserializedAssistingContent';

import { FormRouter } from './providers/FormRouter';
import { FormEngine } from './providers/FormEngine';

import { FormDialogHeader } from './containers/FormDialogHeader';

import { FormDialogContent } from './components/FormDialogContent';
import { FormCanvas } from './containers/FormCanvas';
import { FormHelpSidebar } from './containers/FormHelpSidebar';
import { LayoutStylingSidebar } from './containers/LayoutStylingSidebar';

export type FormLayoutProps = {
  isSavePending?: boolean;
  theme: FormBranding;
  onClose: () => void;
  onSave: (data: MoonrakerFormType) => void;
};

export const FormLayout = ({
  isSavePending = false,
  theme,
  onClose,
  onSave,
}: FormLayoutProps) => {
  const handleSerializedSave = useHandleSerializedSave(onSave);

  const deserializedAssistingContent = useDeserializedAssistingContent(
    theme.assistingContent
  );
  const initialForm = useMemo(
    () => ({
      branding: {
        primaryColor: theme.primaryColor,
        backgroundColor: theme.backgroundColor,
        borderColor: theme.borderColor,
        textColor: theme.textColor,
        subTextColor: theme.subTextColor,
        borderRadius: theme.borderRadius,
        fontFamily: theme.fontFamily,
        gapSpacing: theme.gapSpacing,
      },
      assistingContent: deserializedAssistingContent,
    }),
    [theme, deserializedAssistingContent]
  );

  return (
    <FullPageDialog modal={false} className="h-full p-0" defaultOpen>
      <FormEngine initialForm={initialForm} onSave={handleSerializedSave}>
        <DndProvider backend={HTML5Backend}>
          <FormRouter route="layout">
            <div className="grid h-full w-full grid-rows-[48px_auto]">
              <FormDialogHeader
                isSavePending={isSavePending}
                processTitle="Form styling"
                onClickBack={onClose}
              />
              <FormDialogContent>
                <LayoutStylingSidebar />
                <FormHelpSidebar />
                <FormCanvas />
              </FormDialogContent>
            </div>
          </FormRouter>
        </DndProvider>
      </FormEngine>
    </FullPageDialog>
  );
};
