import { createContext } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { Process } from '@spektr/shared/types';
import { ProcessTrigger } from '@spektr/shared/validators';

import {
  getProcessByIdQuery,
  getTriggersForProcessQuery,
} from '@spektr/client/services';

type ProcessScheduleContextProps = {
  process: Process | null;
  triggers: ProcessTrigger[];
};

export const ProcessScheduleContext =
  createContext<ProcessScheduleContextProps>({
    process: null,
    triggers: [],
  });

type ProcessScheduleProviderProps = {
  processId: string;
  children: React.ReactNode;
};

export const ProcessScheduleProvider = ({
  processId,
  children,
}: ProcessScheduleProviderProps) => {
  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: triggers } = useSuspenseQuery(
    getTriggersForProcessQuery(processId)
  );

  return (
    <ProcessScheduleContext.Provider
      value={{
        process,
        triggers,
      }}
    >
      {children}
    </ProcessScheduleContext.Provider>
  );
};
