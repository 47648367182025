import { ReactNode, useCallback, useMemo, useReducer } from 'react';
import _defaultsDeep from 'lodash/defaultsDeep';

import {
  isEntitiesField,
  type Field,
  type DeserializedMoonrakerForm,
  type DeserializedBrandingFormAssistingContent,
} from '@spektr/moonraker-types';

import { type SpektrField } from '@spektr/shared/types';
import { type BrandingFormTheme } from '@spektr/branding-types';

import { useCurrencyList } from '@spektr/platform-hooks';

import { DEFAULT_STATE } from '../../constants/engine';

import { createFieldFromPrimitive } from '../../utils/createFieldFromPrimitive';
import { createFieldFromWidget } from '../../utils/createFieldFromWidget';
import { createFieldsFromBlock } from '../../utils/createFieldsFromBlock';

import { FormEngineContext } from './context';

import { reducer } from './reducer';
import {
  createDeleteFieldAction,
  createDropFieldAction,
  createReorderFieldAction,
  createSelectFieldAction,
  createUpdateAssistingContentAction,
  createUpdateBrandingAction,
  createUpdateFieldAction,
} from './actions';

import { FormEngineApi } from './types';
import { createCloneFieldAction } from './actions/cloneField';

import type {
  Primitive,
  Block,
  Widget,
  DraggableItem,
  DraggableItemType,
} from '../../types';

export type FormEngineProps = {
  children: ReactNode | ReactNode[];
  initialForm?: Partial<DeserializedMoonrakerForm>;
  spektrFields?: SpektrField[];
  onSave: (data: DeserializedMoonrakerForm) => void;
};

export const FormEngine = ({
  children,
  initialForm,
  spektrFields = [],
  onSave,
}: FormEngineProps) => {
  const { data: currencies } = useCurrencyList();
  const currencyList = useMemo(() => {
    return Object.entries(currencies).map(([code, currency]) => ({
      value: code,
      label: `${currency.name} (${currency.code})`,
      symbol: currency.symbol,
    }));
  }, [currencies]);

  const [state, dispatch] = useReducer(
    reducer,
    _defaultsDeep(initialForm, DEFAULT_STATE)
  );

  const handleDrop = useCallback(
    async (
      itemType: DraggableItemType,
      payload: DraggableItem,
      rootFieldId?: string
    ) => {
      if (itemType === 'primitive') {
        const instance = createFieldFromPrimitive(payload as Primitive);
        dispatch(createDropFieldAction(instance, rootFieldId));
      }

      if (itemType === 'widget') {
        const instance = await createFieldFromWidget(payload as Widget);
        dispatch(createDropFieldAction(instance, rootFieldId));
      }

      if (itemType === 'block') {
        const instances = createFieldsFromBlock(payload as Block);
        instances.forEach((instance) => {
          dispatch(createDropFieldAction(instance, rootFieldId));
        });
      }

      return;
    },
    [dispatch]
  );

  const handleSelectField = useCallback(
    (rootFieldId: string, fieldId?: string) => {
      dispatch(createSelectFieldAction(rootFieldId, fieldId));
    },
    [dispatch]
  );

  const handleDeleteField = useCallback(
    (rootFieldId: string, fieldId?: string) => {
      dispatch(createDeleteFieldAction(rootFieldId, fieldId));
    },
    [dispatch]
  );

  const handleUpdateField = useCallback(
    (field: Field) => {
      dispatch(createUpdateFieldAction(field));
    },
    [dispatch]
  );

  const handleCloneField = useCallback(
    (rootFieldId: string, fieldId?: string) => {
      dispatch(createCloneFieldAction(rootFieldId, fieldId));
    },
    [dispatch]
  );

  const handleUpdateAssistingContent = useCallback(
    (payload: DeserializedBrandingFormAssistingContent) => {
      dispatch(createUpdateAssistingContentAction(payload));
    },
    [dispatch]
  );

  const handleUpdateBranding = useCallback(
    (payload: BrandingFormTheme) => {
      dispatch(createUpdateBrandingAction(payload));
    },
    [dispatch]
  );

  const handleSaveForm = useCallback(() => {
    onSave({
      fields: state.fields,
      order: state.order,
      branding: state.branding,
      assistingContent: state.assistingContent,
    });
  }, [state, onSave]);

  const getCurrentField = useCallback(() => {
    if (!state.selectedFieldsId) return null;

    const [rootFieldId, fieldId] = state.selectedFieldsId;

    if (fieldId) {
      const rootField = state.fields[rootFieldId];
      if (isEntitiesField(rootField)) {
        return rootField.form.fields[fieldId];
      }
    }

    return state.fields[rootFieldId];
  }, [state]);

  const handleReorder = useCallback(
    (currentIndex: number, newIndex: number) => {
      dispatch(createReorderFieldAction(currentIndex, newIndex));
    },
    [dispatch]
  );

  const api = useMemo<FormEngineApi>(
    () => ({
      state,
      spektrFields,
      currencyList,
      getCurrentField,
      dropField: handleDrop,
      selectFieldId: handleSelectField,
      cloneField: handleCloneField,
      deleteField: handleDeleteField,
      updateField: handleUpdateField,
      saveForm: handleSaveForm,
      reorderFields: handleReorder,
      updateAssistingContent: handleUpdateAssistingContent,
      updateBranding: handleUpdateBranding,
    }),
    [
      state,
      spektrFields,
      currencyList,
      getCurrentField,
      handleDrop,
      handleSelectField,
      handleCloneField,
      handleDeleteField,
      handleUpdateField,
      handleSaveForm,
      handleReorder,
      handleUpdateAssistingContent,
      handleUpdateBranding,
    ]
  );

  return (
    <FormEngineContext.Provider value={api}>
      {children}
    </FormEngineContext.Provider>
  );
};
