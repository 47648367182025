import { format } from 'date-fns';

import { FULL_DATE_TIME_FORMAT } from '@spektr/client/constants';
import { cn } from '@spektr/client/utils';

import {
  ActionStatusBadge,
  ProcessIcon,
  SpektrRoutingNode,
  TableCell,
  TableRow,
} from '@spektr/client/components';

import { RecordAction } from '../../types';

export type ActionRowProps = {
  rowItem: RecordAction;
  isLastRow?: boolean;
  handleClick: (id: string) => void;
};

const baseRowCellClasses = cn('px-4', 'border-b', 'text-sm font-medium');

export const ActionsTableRow = ({
  rowItem,
  isLastRow = false,
  handleClick,
}: ActionRowProps) => {
  return (
    <TableRow
      onClick={() => handleClick(rowItem.id)}
      className="hover:bg-color-bg-table-row--hover h-[63px] cursor-pointer"
    >
      <TableCell className={cn(baseRowCellClasses, isLastRow && 'border-0')}>
        <div className="flex flex-row items-center gap-2">
          <ProcessIcon boxIcon processType={rowItem.processType} />
          {rowItem.processName}
        </div>
      </TableCell>
      <TableCell className={cn(baseRowCellClasses, isLastRow && 'border-0')}>
        <div className="flex flex-row items-center gap-2">
          {rowItem?.sourceName && (
            <>
              <div
                className={cn(
                  'flex items-center justify-center',
                  'h-5 w-5',
                  'rounded-md',
                  'bg-spektr-red'
                )}
              >
                <SpektrRoutingNode />
              </div>
              {rowItem.sourceName}
            </>
          )}
        </div>
      </TableCell>
      <TableCell className={cn(baseRowCellClasses, isLastRow && 'border-0')}>
        {format(rowItem.createdAt, FULL_DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell className={cn(baseRowCellClasses, isLastRow && 'border-0')}>
        <div className="flex justify-end">
          <ActionStatusBadge
            hitsCount={rowItem.hitsCount}
            resolvedHitsCount={rowItem.resolvedHitsCount}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};
