import { ButtonWithTooltip } from '@spektr/client/components';

import { SettingItem } from '../SettingItem';
import { StatusCheck } from '../StatusCheck';

export type PublishVersionItemProps = {
  description: string;
  isDisabled?: boolean;
  hasUnreleasedChanges?: boolean;
  title: string;
  disabledReason: string;
  onClick: () => void;
};

export const PublishVersionItem = ({
  title,
  description,
  hasUnreleasedChanges = false,
  isDisabled = false,
  disabledReason,
  onClick,
}: PublishVersionItemProps) => {
  return (
    <SettingItem title={title} description={description}>
      <div className="flex items-center gap-4">
        {hasUnreleasedChanges && (
          <StatusCheck animated color="red" label="Unreleased changes" />
        )}
        <ButtonWithTooltip
          color="cyan"
          disabled={isDisabled || !!disabledReason}
          onClick={onClick}
          showTooltip={!!disabledReason}
          tooltipProps={{
            content: disabledReason,
          }}
        >
          Publish version
        </ButtonWithTooltip>
      </div>
    </SettingItem>
  );
};
