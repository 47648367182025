import { ReactNode } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

interface CardListProps<T> extends VariantProps<typeof itemsVariants> {
  className?: string;
  emptyMessage?: ReactNode;
  items: T[];
  title: string;
  renderItems: (item: T) => JSX.Element;
}

export const CardList = <T,>({
  className,
  emptyMessage,
  items,
  maxItems,
  title,
  renderItems,
}: CardListProps<T>) => {
  return (
    <div
      className={cn(
        'bg-color-bg-card-default',
        'rounded border',
        'grow-1 grow-1 flex flex-col',
        className
      )}
    >
      <div
        className={cn(
          'text-color-text-primary text-xs',
          'px-4 py-3',
          'border-b'
        )}
      >
        <span>{title}</span>
      </div>
      <div className={cn(itemsVariants({ maxItems }))}>
        {items.length > 0 ? (
          <div className="flex w-full flex-col">{items.map(renderItems)}</div>
        ) : (
          <div className="p-4">{emptyMessage}</div>
        )}
      </div>
    </div>
  );
};

const itemsVariants = cva('overflow-y-auto', {
  variants: {
    maxItems: {
      all: '',
      '5': 'max-h-[263px]',
    },
  },
  defaultVariants: {
    maxItems: 'all',
  },
});
