import { Suspense } from 'react';
import axios from 'axios';

import {
  InsightsErrorDashboard,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { RecordActionsList } from './containers/RecordActionsList';

import { SkeletonActionsTable } from './components/SkeletonActionsTable';

export const InsightsRecordDetailsActionsDashboard = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        const errorMsg = axios.isAxiosError(error)
          ? error.message
          : 'An error has occurred while retrieving the actions!';
        return <InsightsErrorDashboard message={errorMsg} />;
      }}
    >
      <Suspense fallback={<SkeletonActionsTable />}>
        <RecordActionsList />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
