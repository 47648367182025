import { useNavigate } from 'react-router-dom';
import { Rocket, Route, Unplug } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';

import {
  processDetailsParamsSchema,
  processFieldMappingFullUrl,
  processScheduleUrl,
  processStrategyFullUrl,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import { Button } from '@spektr/client/components';
import { ProcessDemoButton } from '@spektr/model-builder/containers';
import { getProcessByIdQuery } from '@spektr/client/services';

export type ProcessActionsHeaderProps = { publicPageUrl: string };
export const ProcessActionsHeader = ({
  publicPageUrl,
}: ProcessActionsHeaderProps) => {
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const { data: process } = useQuery(getProcessByIdQuery(processId));

  const navigate = useNavigate();

  const handleClickSchedule = () => {
    navigate(processScheduleUrl(processId));
  };

  return (
    <div className="flex items-center gap-4">
      {process?.type === 'onboarding' && (
        <ProcessDemoButton
          processId={processId}
          publicPageUrl={publicPageUrl}
        />
      )}
      <Button
        variant="text"
        size="sm"
        className="gap-2"
        onClick={() => navigate(processStrategyFullUrl(processId))}
      >
        <Route className="h-4 w-4" />
        <span>Strategy</span>
      </Button>
      {/* {process?.type !== 'onboarding' && ( */}
      <Button
        variant="text"
        size="sm"
        className="gap-2"
        onClick={() => navigate(processFieldMappingFullUrl(processId))}
      >
        <Unplug className="h-4 w-4" />
        <span>Field mapping</span>
      </Button>
      {/* )} */}
      <Button
        startIcon={<Rocket className="h-4 w-4" />}
        color="cyan"
        onClick={handleClickSchedule}
      >
        Launch processs
      </Button>
    </div>
  );
};
