import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { produce } from 'immer';

import { LS_KEYS } from '@spektr/client/constants';

import { localStorageUtils } from '@spektr/client/utils';
import { insightsRecordDatasetIdUrl } from '@spektr/shared/utils';

import { useDatasetIdAsParam } from '@spektr/platform-hooks';

import { DatasetRecordFields } from '../types';

import { RecordsContext } from './RecordsContext';

type RecordsProviderProps = {
  children: React.ReactNode;
};

export const RecordsProvider = ({ children }: RecordsProviderProps) => {
  const navigate = useNavigate();

  const [fallbackDatasetId, updateFallbackDatasetId] = useState('');
  const { datasetId, setDatasetId } = useDatasetIdAsParam(fallbackDatasetId);
  const lsKey = useMemo(
    () => `${LS_KEYS.INSIGHTS_COLUMNS}_${datasetId}`,
    [datasetId]
  );
  const lsColumns = localStorageUtils.getItem(lsKey);
  const lsVisibleColumns = useMemo(
    () => JSON.parse(lsColumns ?? '[]') as string[],
    [lsKey]
  );
  const [visibleColumns, updateVisibleColumns] =
    useState<string[]>(lsVisibleColumns);
  const updateVisibleColumnsInLocalStorage = (newVisibleColumns: string[]) =>
    localStorageUtils.setItem(lsKey, JSON.stringify(newVisibleColumns));

  const initializeVisibleColumns = (datasetFields: DatasetRecordFields) => {
    if (!lsColumns) {
      const columns = Object.keys(datasetFields).slice(0, 4);
      updateVisibleColumns(columns);
      updateVisibleColumnsInLocalStorage(columns);
    }
  };

  const updateColumnVisibility = (
    name: string,
    isHidden: boolean,
    newIndex?: number
  ) =>
    updateVisibleColumns(
      produce(visibleColumns, (draft) => {
        if (isHidden) {
          draft.splice(draft.indexOf(name), 1);
        } else {
          if (draft.includes(name)) {
            draft.splice(draft.indexOf(name), 1);
          }
          if (newIndex !== undefined) {
            draft.splice(newIndex, 0, name);
          } else {
            draft.push(name);
          }
        }
        updateVisibleColumnsInLocalStorage(draft);
      })
    );

  const updateDatasetId = (id: string) => {
    setDatasetId(id);
    updateFallbackDatasetId(id);
    navigate(insightsRecordDatasetIdUrl(id));
  };

  useEffect(() => {
    updateVisibleColumns(lsVisibleColumns);
  }, [lsVisibleColumns]);

  return (
    <RecordsContext.Provider
      value={{
        selectedDataset: datasetId,
        visibleColumns,
        updateSelectedDataset: updateDatasetId,
        areVisibleColumnsInitialized: !!lsColumns,
        initializeVisibleColumns,
        updateFallbackDatasetId,
        updateColumnVisibility,
      }}
    >
      {children}
    </RecordsContext.Provider>
  );
};
