import type { FieldConfig, FieldUiConfig } from '@spektr/moonraker-types';

export function createConfig(spektrDataField = ''): FieldConfig {
  return {
    spektrDataField,
    prefillDefaultValue: true,
  };
}

export function createUiConfig(): FieldUiConfig {
  return {
    style: {
      width: '100%',
    },
  };
}
