import { useCallback, useMemo, useState } from 'react';

import { LS_KEYS } from '@spektr/client/constants';

import { localStorageUtils } from '@spektr/client/utils';

type StoredValue = Record<string, number>;

export function useFakeStorage() {
  const [requests, setRequests] = useState<StoredValue>(
    localStorageUtils.getNestedItem<StoredValue>(LS_KEYS.SERVICES_REQUESTS) ??
      {}
  );

  const addRequestService = useCallback(
    (serviceName: string) => {
      requests[serviceName] = new Date().getTime();

      setRequests({ ...requests });
      localStorageUtils.setItem(
        LS_KEYS.SERVICES_REQUESTS,
        JSON.stringify(requests)
      );
    },
    [requests]
  );

  const api = useMemo(() => {
    return {
      requests,
      addRequestService,
    };
  }, [requests, addRequestService]);

  return api;
}
