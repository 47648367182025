import { v4 as uuid } from 'uuid';

import { Field } from '@spektr/moonraker-types';

import type { Widget } from '../types';

type NaceCode = {
  section: string;
  code: string;
  name: string;
  level: string;
};

export async function createFieldFromWidget(widget: Widget): Promise<Field> {
  if (
    widget.component.id === 'naceCode' &&
    widget.component.type === 'select'
  ) {
    const url = new URL('/assets/data/nace-codes.json', window.location.origin);
    const codes = (await fetch(url.toString()).then((res) =>
      res.json()
    )) as NaceCode[];

    widget.component.attributes.options = codes.map((code) => {
      const value = `${code.section}${Number(code.code)}`;
      const label = `${value} - ${code.name}`;
      return { value, label };
    });
  }

  return {
    ...widget.component,
    id: uuid(),
  };
}
