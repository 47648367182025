import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { VariantProps } from 'class-variance-authority';

import { Content, Portal, Viewport } from '@radix-ui/react-select';

import { cn } from '@spektr/client/utils';

import { triggerContentVariants } from '../variants';

export type SelectContentProps = ComponentPropsWithoutRef<typeof Content> &
  VariantProps<typeof triggerContentVariants>;

export const SelectContent = forwardRef<
  ElementRef<typeof Content>,
  SelectContentProps
>(({ className, children, position = 'popper', inverted, ...props }, ref) => (
  <Portal>
    <Content
      ref={ref}
      className={cn(
        'relative z-50 min-w-[8rem]',
        'max-h-64',
        'rounded-md border',
        'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95',
        'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
        'data-[side=bottom]:slide-in-from-top-2',
        'data-[side=left]:slide-in-from-right-2',
        'data-[side=right]:slide-in-from-left-2',
        'data-[side=top]:slide-in-from-bottom-2',
        'overflow-auto shadow-md',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        triggerContentVariants({ inverted }),
        className
      )}
      position={position}
      {...props}
    >
      <Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'
        )}
      >
        {children}
      </Viewport>
    </Content>
  </Portal>
));
