import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RBAC } from '@spektr/shared/rbac';

import { useDemo, usePermissionsContext } from '@spektr/client/providers';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { useProcessScheduleContext } from '../../providers';

export const useGetPublishDisabledReason = (hasChanges: boolean) => {
  const { t } = useTranslation();
  const { t: tProcess } = useTranslation('process');
  const { isDemo } = useDemo();
  const { hasPermission } = usePermissionsContext();
  const {
    hasSource,
    isLoopProcess,
    isOnboardingProcess,
    areLoopChannelSettingsRequired,
  } = useProcessScheduleContext();
  const { loopSettings } = useFeatureFlags();

  return useMemo(() => {
    if (isDemo) {
      return t('errors.demoPlatform');
    }

    if (!isLoopProcess && !isOnboardingProcess) {
      if (!hasSource) {
        return tProcess('errors.publish.noSource');
      }
    }

    if (!hasPermission(RBAC.ACTIONS.PROCESS.LAUNCH)) {
      return tProcess('errors.permissions.publish');
    }

    if (loopSettings && areLoopChannelSettingsRequired) {
      return tProcess('errors.publish.noChannelSettings');
    }

    if (!hasChanges) {
      return tProcess('errors.publish.noChanges');
    }

    return '';
  }, [
    hasSource,
    isLoopProcess,
    isOnboardingProcess,
    hasChanges,
    hasPermission,
    isDemo,
    t,
    tProcess,
    loopSettings,
    areLoopChannelSettingsRequired,
  ]);
};
