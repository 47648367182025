import { assertUnreachable } from '@spektr/shared/utils';
import { ExecutionContextSchema } from '@spektr/shared/validators';

export function getStatusColor(execution: ExecutionContextSchema) {
  if (execution.status === 'completed') {
    return 'success' as const;
  }

  if (
    execution.status === 'failed' ||
    execution.status === 'timeout' ||
    execution.status === 'vendorError'
  ) {
    return 'error' as const;
  }

  if (execution.status === 'pending' || execution.status === 'running') {
    return 'warning' as const;
  }

  return 'default' as const;
}

export function getStatusText(execution: ExecutionContextSchema) {
  switch (execution.status) {
    case 'completed':
      return 'Completed';
    case 'failed':
      return 'Failed';
    case 'timeout':
      return 'Timed out';
    case 'pending':
      return 'Pending';
    case 'running':
      return 'Running';
    case 'waiting':
      return 'Waiting';
    case 'vendorError':
      return 'Vendor error';
    default:
      assertUnreachable(execution.status);
  }
}
