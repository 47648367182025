import { Suspense } from 'react';
import axios from 'axios';

import {
  InsightsErrorDashboard,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { useParsedParams } from '@spektr/shared/hooks';
import { insightsRecordDetailsParamsSchema } from '@spektr/shared/utils';

import { SkeletonRecordExecutionsTable } from './components/SkeletonRecordExecutionsTable';
import { RecordExecutionsList } from './containers/RecordExecutionsList';

export const InsightsRecordDetailsRunsDashboard = () => {
  const { datasetId, recordId } = useParsedParams(
    insightsRecordDetailsParamsSchema
  );

  return (
    <SpektrErrorBoundary
      fallbackRender={(error) => {
        const errorMsg = axios.isAxiosError(error)
          ? error.message
          : 'An error has occurred while retrieving the insights!';
        return <InsightsErrorDashboard message={errorMsg} />;
      }}
    >
      <Suspense fallback={<SkeletonRecordExecutionsTable />}>
        <RecordExecutionsList datasetId={datasetId} recordId={recordId} />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
