import { z } from 'zod';

import { BaseField } from './BaseField';
import { FieldValidation } from './FieldValidation';

export const CheckboxField = BaseField.merge(
  z.object({
    type: z.literal('radio').or(z.literal('checkbox')),
    attributes: z.object({
      label: z.string(),
      options: z.array(z.string()),
      placeholder: z.string().optional(),
      helperText: z.string().optional(),
    }),
    validation: z.array(FieldValidation),
  })
);

export type CheckboxField = z.infer<typeof CheckboxField>;

export function isCheckboxField(field: unknown): field is CheckboxField {
  return CheckboxField.safeParse(field).success;
}
