import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CommentMessageBlock,
  CommentParentType,
} from '@spektr/shared/validators';

import { useDemo } from '@spektr/client/providers';
import { cn } from '@spektr/client/utils';
import {
  ButtonWithTooltip,
  RichTextEditor,
  Spinner,
} from '@spektr/client/components';
import { type SlateDescendant } from '@spektr/shared/slate-types';

import { useAddComment } from '../../hooks/useAddComment';
import { isEmptyComment } from '../../utils/isEmptyComment';

type CommentBoxProps = {
  parentType: CommentParentType;
  parentId: string;
  onAddComment?: () => void;
};

export const CommentBox = ({
  parentType,
  parentId,
  onAddComment,
}: CommentBoxProps) => {
  const { t } = useTranslation();
  const { isDemo } = useDemo();
  const [resetKey, setResetKey] = useState(0);
  const [comment, updateComment] = useState<CommentMessageBlock[]>();
  const handleResetComment = () => {
    updateComment([]);
    setResetKey((prev) => prev + 1);
  };
  const addComment = useAddComment(parentType, parentId, handleResetComment);

  const handleAddComment = async () => {
    if (!comment || isEmptyComment(comment)) {
      return;
    }

    await addComment.mutateAsync(comment);
    onAddComment?.();
  };

  const isDisabled =
    !comment || isEmptyComment(comment) || addComment.isPending;

  return (
    <div className={cn('flex flex-col gap-3')}>
      <RichTextEditor
        key={resetKey}
        className="max-h-28 !min-h-14"
        placeholder="Leave a comment on this action"
        onChange={(value: SlateDescendant[]) =>
          updateComment(value as CommentMessageBlock[])
        }
      />
      <ButtonWithTooltip
        className="ml-auto"
        onClick={handleAddComment}
        disabled={isDisabled || isDemo}
        showTooltip={isDemo}
        tooltipProps={{
          content: t('errors.demoPlatform'),
        }}
      >
        {addComment.isPending ? (
          <div className="flex items-center gap-1">
            <Spinner className="h-4 w-4" />
            Adding comment...
          </div>
        ) : (
          'Add comment'
        )}
      </ButtonWithTooltip>
    </div>
  );
};
