import { useSuspenseQuery } from '@tanstack/react-query';

import { getAllActions } from '@spektr/client/services';

export function useCountUnresolvedActions() {
  const { data } = useSuspenseQuery({
    ...getAllActions({ page: 1, limit: 1, status: 'pending' }),
    refetchInterval: 10000,
  });

  return data.totalDocs ?? 0;
}
