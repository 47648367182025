import { Moon, Sun } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { useTheme } from '@spektr/client/providers';

import { Switch } from '../forms';

export type ThemeProps = {
  className?: string;
};

export const ThemeToggle = ({ className }: ThemeProps) => {
  const { theme, toggleTheme } = useTheme();

  const ThemeIcon = ({ className }: ThemeProps) =>
    theme === 'light' ? (
      <Sun className={className} />
    ) : (
      <Moon className={className} />
    );

  return (
    <Switch
      defaultChecked={theme === 'light'}
      onCheckedChange={toggleTheme}
      className={cn(
        'h-[28px] w-[48px]',
        '!bg-color-bg-theme-toggle',
        className
      )}
      thumbClassName={cn(
        'h-6 w-6 !bg-color-bg-theme-toggle-thumb',
        'flex items-center justify-center',
        'data-[state=checked]:translate-x-5',
        'data-[state=unchecked]:translate-x-0'
      )}
      icon={<ThemeIcon className="stroke-color-stroke-theme-toggle h-4 w-4" />}
    />
  );
};
