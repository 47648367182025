import { useTranslation } from 'react-i18next';
import { Info } from 'lucide-react';

import { NodeServiceType } from '@spektr/shared/validators';

import {
  ComboboxItem,
  ComboboxItemProps,
  Tooltip,
} from '@spektr/client/components';

type ServiceFieldProps = {
  value: ComboboxItemProps['value'];
  service: NodeServiceType;
};

export const ServiceField = ({ value, service }: ServiceFieldProps) => {
  const { t, i18n } = useTranslation();

  const translationKey = `fields:${service}.${value}`;
  const helpTranslationKey = `${translationKey}.help`;
  const helpText = i18n.exists(helpTranslationKey) ? t(helpTranslationKey) : '';

  return (
    <ComboboxItem
      className="flex items-center justify-between gap-1"
      value={value}
    >
      <p className="pointer-events-none line-clamp-1 overflow-hidden">
        {t(translationKey)}
      </p>
      {helpText && (
        <Tooltip content={helpText}>
          <Info className="text-color-text-icon-secondary h-3 w-3" />
        </Tooltip>
      )}
    </ComboboxItem>
  );
};
