import { jsx } from 'slate-hyperscript';

import {
  type MarkAttributes,
  type SlateDescendant,
} from '@spektr/shared/slate-types';

export function htmlToSlate(
  el: HTMLElement,
  markAttributes: MarkAttributes = {}
): SlateDescendant | SlateDescendant[] {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    throw new Error('Unsupported node type');
  }

  const nodeAttributes = { ...markAttributes };

  if (el.nodeName === 'STRONG') {
    nodeAttributes.bold = true;
  }
  if (el.nodeName === 'EM') {
    nodeAttributes.italic = true;
  }
  if (el.nodeName === 'U') {
    nodeAttributes.underline = true;
  }

  const children = Array.from(el.childNodes)
    .map((node) => htmlToSlate(node as HTMLElement, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    // case 'BR':
    //   return '\n';
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href') },
        children
      );
    default:
      return children;
  }
}
