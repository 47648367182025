export const DD_APP_ID = window._env.DD_APP_ID ?? '';
export const DD_CLIENT_TOKEN = window._env.DD_CLIENT_TOKEN ?? '';
export const DD_SITE = window._env.DD_SITE ?? 'datadoghq.eu';

export const DD_PLATFORM_ENV = window._env.DD_PLATFORM_ENV ?? 'production';
export const DD_PRIVACY_LEVEL = window._env.DD_PRIVACY_LEVEL ?? 'mask';
export const DD_TRACK_LONG_TASKS = window._env.DD_TRACK_LONG_TASKS ?? false;

export const DD_SESSION_SAMPLING_RATE =
  window._env.DD_SESSION_SAMPLING_RATE ?? 10;
export const DD_SESSION_REPLAY_SAMPLING_RATE =
  window._env.DD_SESSION_REPLAY_SAMPLING_RATE ?? 1;
