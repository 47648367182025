import { format } from 'date-fns';

import { SHORT_DATE_TIME_FORMAT } from '@spektr/client/constants';

import { Avatar } from '@spektr/client/components';

import { type SlateDescendant } from '@spektr/shared/slate-types';

import { CommentActions } from '../CommentActions';

type ActivityItemHeaderProps = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  date: number;
  showActions?: boolean;
  commentId?: string;
  commentMessage?: SlateDescendant[];
};

export const ActivityItemHeader = ({
  firstName,
  lastName,
  email,
  date,
  showActions = false,
  commentId,
  commentMessage,
}: ActivityItemHeaderProps) => {
  return (
    <div className="flex w-full flex-row items-start gap-3">
      <Avatar firstName={firstName ?? '?'} lastName={lastName ?? '?'} />
      <div className="flex flex-col items-start">
        <p className="font-medium">{`${firstName} ${lastName}`}</p>
        <span className="text-color-text-subtext text-xs">{email}</span>
      </div>
      <div className="ml-auto flex items-center gap-2">
        <span className="text-color-text-subtext text-xs">
          {format(date, SHORT_DATE_TIME_FORMAT)}
        </span>
        {showActions && commentId && commentMessage && (
          <CommentActions
            commentId={commentId}
            commentMessage={commentMessage}
          />
        )}
      </div>
    </div>
  );
};
