import { ZodError } from 'zod';

export function getFirstParsingError(error: ZodError) {
  const { formErrors, fieldErrors } = error.flatten();

  const firstFormError = formErrors.at(0);
  if (firstFormError) return firstFormError;

  const fieldErrorEntries = Object.entries(fieldErrors);
  const firstFieldErrorEntry = fieldErrorEntries.at(0);

  if (!firstFieldErrorEntry) return undefined;

  const [, errors] = firstFieldErrorEntry;

  return errors?.at(0);
}
