import { useParsedParams } from '@spektr/shared/hooks';
import {
  processBuilderUrl,
  processDetailsParamsSchema,
} from '@spektr/shared/utils';

import { StrategyDialog } from '../../StrategyDialog';

export const ProcessStrategyDialog = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);

  return (
    <StrategyDialog
      processId={processId}
      processUrl={processBuilderUrl(processId)}
    />
  );
};
