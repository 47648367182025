export type LoginProps = {
  email: string;
  password: string;
};

export type ConfirmForgotPasswordProps = {
  email: string;
  newPassword: string;
  confirmationCode: string;
};

export type ChangePasswordProps = {
  oldPassword: string;
  newPassword: string;
};
export type ConfirmEmailProps = {
  email: string;
  confirmationCode: string;
};

export class UserNotAuthenticatedError extends Error {
  constructor() {
    super('User is not authenticated');
    this.name = 'UserNotAuthenticatedError';
  }
}
