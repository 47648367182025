import { Skeleton } from '@spektr/client/components';

export const MatrixSegmentSkeleton = () => (
  <div className="flex flex-col overflow-hidden rounded-md border">
    <div className="bg-color-bg-accent flex h-[52px] justify-between p-3" />
    <div className="flex gap-2 p-4">
      <Skeleton className="h-7 w-full" />
      <Skeleton className="h-7 w-10 shrink-0" />
      <Skeleton className="h-7 w-full" />
    </div>
  </div>
);
