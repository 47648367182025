import { SpektrFieldKey } from '@spektr/shared/types';

import { ServiceFieldClient } from '../types/ServiceFieldClient';

import { getSelectedServiceFields } from './kyckr';
import { getSelectedMitIdFields } from './mitId';

export const getSelectedServiceFieldsByNodeType = (
  nodeType: string,
  fields: ServiceFieldClient[],
  selectedFields: SpektrFieldKey[]
): SpektrFieldKey[] => {
  switch (nodeType) {
    case `kyckr`:
      return getSelectedServiceFields(fields, selectedFields);
    case `mitId`:
      return getSelectedMitIdFields(selectedFields);
    default:
      return selectedFields;
  }
};
