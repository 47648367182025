import { SearchCode } from 'lucide-react';
import _isEqual from 'lodash/isEqual';
import { FormProvider, useForm } from 'react-hook-form';
import { useMemo } from 'react';

import { MonitoringDatasetNode, NodeUpdateInput } from '@spektr/shared/types';

import { cn } from '@spektr/client/utils';
import {
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogDescription,
  DialogHeader,
  IconWithBackground,
} from '@spektr/client/components';

import {
  getAllowedSpektrFieldsQuery,
  getTriggersForProcessQuery,
  getTriggersForProcessQueryKey,
} from '@spektr/client/services';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { ClientTrigger } from '@spektr/client/types';
import { NodeDetailsDialogTitle, TitleInput } from '@spektr/shared/components';

import { DatasetFieldsWithFrequencyList } from '../ServiceNodeFactory/containers/DatasetFieldsWithFrequencyList';

import { useTriggersOperations } from '../ServiceNodeFactory/hooks/useTriggersOperations';

import { useIncompleteMonitoringDatasetNode } from './hooks/monitoringDatasetProvider';

export type MonitoringDatasetNodeFormDialogContentProps = DialogContentProps & {
  node: MonitoringDatasetNode;
  nodeId: string;
  processId: string;
  onUpdate: (data: NodeUpdateInput) => void;
};

export const MonitoringDatasetNodeFormDialogContent = ({
  node,
  nodeId,
  processId,
  className,
  requestStartExitAnimation,
  onUpdate,
  ...props
}: MonitoringDatasetNodeFormDialogContentProps) => {
  const { incomplete, setTitle } = useIncompleteMonitoringDatasetNode();

  const formInstance = useForm<Record<string, unknown>>({
    defaultValues: {},
    mode: 'onChange',
  });

  const queryClient = useQueryClient();
  const { createTrigger, updateTrigger, deleteTrigger } =
    useTriggersOperations();
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, nodeId)
  );
  const { data: triggers } = useSuspenseQuery(
    getTriggersForProcessQuery(processId)
  );

  const handleSaveChanges = async (
    fields: NodeUpdateInput,
    nextTriggers: ClientTrigger[]
  ) => {
    const changedTriggers = nextTriggers.filter((trigger) => {
      if (!trigger.id) {
        return true;
      }

      const currentTrigger = triggers?.find(
        (currentTrigger) => currentTrigger.id === trigger.id
      );

      if (!currentTrigger) {
        return true;
      }

      return (
        currentTrigger.frequency !== trigger.frequency ||
        !_isEqual(currentTrigger.fields, trigger.fields)
      );
    });

    const deleteTriggers = changedTriggers.filter(
      (trigger) => trigger.id && !trigger.fields?.length
    );

    const updateTriggers = changedTriggers.filter(
      (trigger) =>
        trigger.id &&
        trigger.fields?.length &&
        !deleteTriggers.find((deleteTrigger) => deleteTrigger.id === trigger.id)
    );
    const createTriggers = changedTriggers.filter(
      (trigger) => !trigger.id && trigger.fields?.length
    );

    const promiseQueue = [
      ...deleteTriggers.map((trigger) => deleteTrigger(trigger.id as string)),
      ...updateTriggers.map((trigger) =>
        updateTrigger({
          triggerId: trigger.id as string,
          fields: trigger.fields,
          frequency: trigger.frequency,
        })
      ),
      ...createTriggers.map((trigger) => createTrigger(trigger)),
    ];

    await Promise.all(promiseQueue);

    onUpdate(fields);

    queryClient.invalidateQueries({
      queryKey: getTriggersForProcessQueryKey(processId),
    });
  };

  const currentNodeTriggers = useMemo(
    () => triggers?.filter((trigger) => trigger.nodeId === nodeId) ?? [],
    [nodeId, triggers]
  );

  return (
    <DialogContent
      modal={false}
      size="large"
      className={cn('flex min-h-fit flex-col', className)}
      requestStartExitAnimation={requestStartExitAnimation}
      {...props}
    >
      <DialogHeader className="space-y-0">
        <NodeDetailsDialogTitle
          left={
            <div className="flex items-center gap-2">
              <IconWithBackground className="bg-color-cyan p-1">
                <SearchCode className="stroke-color-stroke-white h-4 w-4" />
              </IconWithBackground>
              <TitleInput title={incomplete.title} onChange={setTitle} />
            </div>
          }
          right={
            <div className="flex items-center gap-2">
              <DialogClose onClick={requestStartExitAnimation} />
            </div>
          }
        />
      </DialogHeader>
      <DialogDescription className="mb-6">
        Continuously monitors a designated field within a dataset for any
        changes. If a change is detected, like an update of the dataset the
        process gets triggered.
      </DialogDescription>
      <div className="flex flex-col gap-6">
        <FormProvider {...formInstance}>
          <DatasetFieldsWithFrequencyList
            key={0}
            node={node}
            processId={processId}
            spektrFields={spektrFields ?? []}
            triggers={currentNodeTriggers}
            onSave={handleSaveChanges}
          />
        </FormProvider>
      </div>
    </DialogContent>
  );
};
