import z from 'zod';

import { unixTimestampSchema } from '../utils';

export const ProcessRunSchema = z.object({
  id: z.string(),
  processId: z.string(),
  versionId: z.string().nullable(),
  datasetId: z.string().nullable(),
  type: z.string(),
  startedCount: z.number(),
  completedCount: z.number(),
  createdAt: unixTimestampSchema,
  updatedAt: unixTimestampSchema,
});

export type ProcessRunSchema = z.infer<typeof ProcessRunSchema>;
