import { cn } from '@spektr/client/utils';

import { NodeUpdateInput, SpektrField } from '@spektr/shared/types';

import { NodeDetailsDialogTitle, TitleInput } from '@spektr/shared/components';

import {
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogDescription,
  DialogHeader,
  IconWithBackground,
  SlackIcon,
} from '@spektr/client/components';

import { useIncompleteSlackNode } from './providers';
import { SlackForm } from './slack/SlackForm';

export type SlackNodeFormDialogContentProps = DialogContentProps & {
  spektrFields: SpektrField[];
  onUpdate: (data: NodeUpdateInput) => void;
  isPendingUpdate: boolean;
};

export const SlackNodeFormDialogContent = ({
  className,
  spektrFields,
  requestStartExitAnimation,
  onUpdate,
  isPendingUpdate,
  ...props
}: SlackNodeFormDialogContentProps) => {
  const { incomplete, setTitle } = useIncompleteSlackNode();

  return (
    <DialogContent
      modal={false}
      size="large"
      className={cn('flex flex-col', className)}
      requestStartExitAnimation={requestStartExitAnimation}
      {...props}
    >
      <DialogHeader>
        <NodeDetailsDialogTitle
          left={
            <div className="flex items-center gap-2">
              <IconWithBackground className="bg-color-bg-white p-1">
                <SlackIcon className="h-4 w-4" />
              </IconWithBackground>
              <TitleInput title={incomplete.title} onChange={setTitle} />
            </div>
          }
          right={
            <div className="flex justify-center gap-2">
              <DialogClose onClick={requestStartExitAnimation} />
            </div>
          }
        />
        <DialogDescription className="mb-4">
          Send Slack notification to one of your channels. For this service to
          work the spektr Slack app needs to be installed in your Slack
          workspace and invited to the channel you want the notifications to
          appear in.
        </DialogDescription>
      </DialogHeader>
      <SlackForm
        spektrFields={spektrFields}
        isPendingUpdate={isPendingUpdate}
        onUpdate={onUpdate}
      />
    </DialogContent>
  );
};
