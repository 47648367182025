import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '@spektr/client/utils';

export const TabsList = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'flex flex-row items-center justify-center gap-4',
      'h-full w-full p-1',
      'rounded-md',
      'bg-color-bg-tab-trigger',
      'overflow-hidden',
      className
    )}
    {...props}
  />
));
