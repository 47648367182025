import { z } from 'zod';
import { makeApi, makeEndpoint } from '@zodios/core';

import {
  triggerCreateInputSchema,
  triggerResponseSchema,
  triggerUpdateInputSchema,
} from './trigger';

const createTrigger = makeEndpoint({
  method: 'post',
  path: '/processes/triggers',
  alias: 'createTrigger',
  response: triggerResponseSchema,
  parameters: [
    {
      name: 'triggerInput',
      type: 'Body',
      schema: triggerCreateInputSchema,
    },
  ],
});

const deleteTriggerById = makeEndpoint({
  method: 'delete',
  path: '/processes/triggers/:triggerId',
  alias: 'deleteTriggerById',
  response: z.void(),
});

const updateTriggerFrequency = makeEndpoint({
  method: 'put',
  path: '/processes/triggers/:triggerId',
  alias: 'updateTriggerFrequency',
  parameters: [
    {
      name: 'triggerInput',
      type: 'Body',
      schema: triggerUpdateInputSchema,
    },
  ],
  response: triggerResponseSchema,
});

const getTriggersForProcess = makeEndpoint({
  method: 'get',
  path: '/processes/:processId/triggers',
  alias: 'getTriggersForProcess',
  response: z.array(triggerResponseSchema),
});

export const triggerApi = makeApi([
  createTrigger,
  deleteTriggerById,
  updateTriggerFrequency,
  getTriggersForProcess,
]);
