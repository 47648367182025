import { useState } from 'react';

import { useUserInformationContext } from '@spektr/client/providers';
import { Button, RichTextEditor } from '@spektr/client/components';

import { cn } from '@spektr/client/utils';

import { ActivityItemHeader } from '../ActivityItemHeader';
import { ClientActivityCommentItem } from '../../types';

import { isLongComment } from '../../utils/isLongComment';

type ActivityCommentItemProps = {
  data: ClientActivityCommentItem;
};

export const ActivityCommentItem = ({ data }: ActivityCommentItemProps) => {
  const [showMoreOfComment, setShowMoreOfComment] = useState<boolean>(false);
  const currentUserInfo = useUserInformationContext();
  const needToHidePartOfComment = isLongComment(data.message);

  return (
    <div className="hover:bg-color-bg-accent/20 flex w-full flex-col gap-3 border-b p-6 last:border-b-0">
      <ActivityItemHeader
        firstName={data.user?.firstName}
        lastName={data.user?.lastName}
        email={data.user?.username}
        date={data.createdAt}
        commentId={data.id}
        commentMessage={data.message}
        showActions={data.user?.username === currentUserInfo?.email}
      />
      <RichTextEditor
        className={cn(
          'w-full border-none bg-transparent p-0',
          needToHidePartOfComment &&
            !showMoreOfComment &&
            'line-clamp-6 overflow-y-hidden'
        )}
        initialValue={data.message}
        resetOnUpdate
        isReadOnly
      />

      {needToHidePartOfComment && (
        <Button
          size="sm"
          variant="text"
          className="text-color-text-subtext w-min p-0"
          onClick={() => setShowMoreOfComment(!showMoreOfComment)}
        >
          {showMoreOfComment ? 'Read less' : 'Read more'}
        </Button>
      )}
    </div>
  );
};
