import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

export type SidebarDetailsTableProps = {
  data: string[][];
};

export const SidebarDetailsTable = ({ data }: SidebarDetailsTableProps) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="text-color-text-subtext text-sm font-medium">Details</p>
      <Table
        className="rounded-md border"
        data-cy="record-details-sidebar-table-data-cy"
      >
        <TableBody>
          {data.map(([key, value], index) => (
            <TableRow key={key}>
              <TableCell
                className={cn(
                  'border-r p-4',
                  'text-sm font-medium',
                  'text-color-text-subtext',
                  'max-w-48 overflow-hidden text-ellipsis',
                  index !== data.length - 1 && 'border-b'
                )}
              >
                {key}
              </TableCell>
              <TableCell
                className={cn(
                  'px-4',
                  'text-sm font-medium',
                  index !== data.length - 1 && 'border-b'
                )}
              >
                {value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
