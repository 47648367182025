import { useMemo, useState } from 'react';

import { SpektrFieldKey, TriggerFrequency } from '@spektr/shared/types';

import { cn } from '@spektr/client/utils';
import { Separator } from '@spektr/client/components';

import { ServiceFieldClient } from '../../types/ServiceFieldClient';
import {
  computeKyckrFields,
  getFirstAvailableFieldKey,
  getSelectedServiceFields,
} from '../../utils/kyckr';
import { UBO_FIELDS } from '../../constants/kyckr';

import { ServiceItemWithFrequency } from '../ServiceItemWithFrequency';
import { FrequencySelect } from '../FrequencySelect';
import { KyckrUboFields } from '../KyckrUboFields';

type KyckrServiceFieldsProps = {
  fields: ServiceFieldClient[];
  getFrequency: (key: SpektrFieldKey) => TriggerFrequency | undefined;
  onChangeFrequencies: (
    kyckrKey: SpektrFieldKey,
    keys: SpektrFieldKey[],
    frequency?: TriggerFrequency
  ) => void;
};

export const KyckrServiceFields = ({
  fields,
  getFrequency,
  onChangeFrequencies,
}: KyckrServiceFieldsProps) => {
  const getFrequencyForKyckrField = (key: string) => {
    const serviceField = getFirstAvailableFieldKey(fields, key);
    return getFrequency(serviceField);
  };

  const uboFieldWithFrequency = useMemo(
    () =>
      UBO_FIELDS.find(
        (field) => getFrequencyForKyckrField(field) !== undefined
      ) ?? 'first_layer_of_ownership',
    [fields]
  );
  const [uboKey, updateUboKey] = useState<string>(uboFieldWithFrequency);
  const [representatives, ...ubo] = useMemo(
    () => computeKyckrFields(fields),
    [fields]
  );

  const handleKeyUpdate = (key: string) => {
    updateUboKey((prev) => {
      if (prev) {
        handleFrequencyChange(prev as SpektrFieldKey, undefined);
      }
      return key;
    });
  };

  const handleFrequencyChange = (
    key: SpektrFieldKey,
    frequency?: TriggerFrequency
  ) => {
    const serviceFields = getSelectedServiceFields(fields, [key]);

    onChangeFrequencies(key, serviceFields, frequency);
  };

  return (
    <div
      className={cn(
        'flex flex-col',
        'max-h-[280px] overflow-y-auto',
        'rounded-md border'
      )}
    >
      <div className="flex flex-col">
        <div
          className={cn(
            'flex h-auto shrink-0 items-center justify-start px-4',
            'h-[52px]'
          )}
        >
          <ServiceItemWithFrequency
            field={representatives}
            frequency={getFrequencyForKyckrField(representatives.key)}
            onChangeFrequency={handleFrequencyChange}
          />
        </div>
        <Separator />
      </div>
      <div className="flex items-start gap-2 p-4">
        <KyckrUboFields
          selectedKey={uboKey}
          fields={ubo}
          showCheckbox={false}
          onKeyUpdate={handleKeyUpdate}
        />
        <FrequencySelect
          fieldKey={uboKey as SpektrFieldKey}
          frequency={getFrequencyForKyckrField(uboKey)}
          onChangeFrequency={handleFrequencyChange}
        />
      </div>
    </div>
  );
};
