import type { Field } from '@spektr/moonraker-types';

export function createUpdateFieldAction(field: Field) {
  return {
    type: 'update_field' as const,
    payload: field,
  };
}

export type UpdateFieldAction = ReturnType<typeof createUpdateFieldAction>;
