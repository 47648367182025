import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { Copy, CopyPlus, Inbox, SquarePen, Trash2 } from 'lucide-react';
import { MouseEvent, useMemo, useState } from 'react';

import {
  AlertDialog,
  DropdownOption,
  ProcessCard,
  toast,
} from '@spektr/client/components';
import {
  LOOPS_KEYS,
  LoopApiClient,
  ProcessApiClient,
  getLiveProcessVersions,
  getLoopsQuery,
} from '@spektr/client/services';
import { cn } from '@spektr/client/utils';

import { loopDetailsUrl } from '@spektr/shared/utils';

import { RenameProcessDialog } from '../../../RenameProcessDialog';
import { getNumberOfSources } from '../../utils';

export type LoopsListProps = {
  status: string;
};

const ACTIONS: DropdownOption[] = [
  {
    type: 'item',
    label: 'Rename loop',
    icon: <SquarePen className="h-4 w-4" />,
    value: 'rename',
  },
  {
    type: 'separator',
    value: 'separator',
  },
  {
    type: 'item',
    label: 'Duplicate loop',
    icon: <CopyPlus className="h-4 w-4" />,
    value: 'duplicate',
  },
  {
    type: 'separator',
    value: 'separator-2',
  },
  {
    type: 'item',
    label: 'Delete loop',
    icon: <Trash2 className="h-4 w-4" />,
    value: 'delete',
    variant: 'danger',
  },
];

export const LoopsList = ({ status }: LoopsListProps) => {
  const queryClient = useQueryClient();
  const [confirmDeleteLoopId, setConfirmDeleteLoopId] = useState<
    string | undefined
  >(undefined);
  const [renameLoopId, setRenameLoopId] = useState<string | undefined>(
    undefined
  );
  const { data: allLoops = [] } = useSuspenseQuery(getLoopsQuery());
  const { data: liveProcessVersions } = useSuspenseQuery(
    getLiveProcessVersions()
  );

  const loops = useMemo(() => {
    return allLoops.filter((loop) => {
      const isLive = liveProcessVersions?.some(
        (liveVersion) => liveVersion.processId === loop.id
      );

      if (status === 'live') return isLive;

      if (status === 'not-live') return !isLive;

      return true; // default to 'all'
    });
  }, [allLoops, liveProcessVersions, status]);

  const deleteLoop = useMutation({
    mutationFn: (id: string) =>
      ProcessApiClient.getClient().deleteProcessById(undefined, {
        params: { id },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: LOOPS_KEYS.ALL() });
      setConfirmDeleteLoopId(undefined);
    },
  });

  const updateLoop = useMutation({
    mutationFn: ({ loopId, name }: { loopId: string; name: string }) =>
      LoopApiClient.getClient().updateLoopById(
        {
          name,
        },
        {
          params: {
            id: loopId,
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: LOOPS_KEYS.ALL() });
      setRenameLoopId(undefined);
      toast.success({
        title: 'Successfully updated',
        description: 'The process was updated.',
      });
    },
  });

  const handleConfirmDelete = async () => {
    if (confirmDeleteLoopId) {
      await deleteLoop.mutateAsync(confirmDeleteLoopId);
    }
  };

  const duplicateLoop = useMutation({
    mutationFn: (loopId: string) =>
      ProcessApiClient.getClient().duplicateProcess(undefined, {
        params: {
          id: loopId,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: LOOPS_KEYS.ALL() });
    },
  });

  const onActionClick =
    (loopId: string) => (action: string, ev: MouseEvent) => {
      ev.stopPropagation();

      switch (action) {
        case 'rename':
          setRenameLoopId(loopId);
          break;
        case 'delete':
          setConfirmDeleteLoopId(loopId);
          break;
        case 'duplicate':
          duplicateLoop.mutateAsync(loopId);
          break;
      }
    };

  if (loops.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 p-8">
        <Inbox className="stroke-color-red h-8 w-8" />
        <span className="text-color-text-error-boundry">
          No loops found. Create a new loop to get started.
        </span>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {loops.map((loop) => (
        <ProcessCard
          actions={ACTIONS}
          key={loop.id}
          route={loopDetailsUrl(loop.id)}
          routeState={{ processType: loop.type }}
          name={loop.name}
          type={loop.type}
          isLive={liveProcessVersions?.some(
            (version) => version.processId === loop.id
          )}
          updatedAt={loop.updatedAt}
          onActionClick={onActionClick(loop.id)}
        >
          <div className="align-center flex	 items-center gap-2">
            <div
              className={cn(
                'h-5 w-5 shrink-0',
                'flex items-center justify-center',
                'bg-color-yellow/20',
                'rounded'
              )}
            >
              <Copy className="stroke-color-yellow h-3" />
            </div>
            <div className="flex items-center">
              <p>
                <span className="font-medium">
                  {getNumberOfSources(loop)} sources{' '}
                </span>
                <span className="text-color-text-subtext">
                  added to this loop
                </span>
              </p>
            </div>
          </div>
        </ProcessCard>
      ))}

      <AlertDialog
        open={!!confirmDeleteLoopId}
        title="Are you sure?"
        paragraph="You will loose unsaved changes not published in a loop version."
        onCancel={() => setConfirmDeleteLoopId(undefined)}
        cancel="Cancel"
        onConfirm={handleConfirmDelete}
        confirm="Delete"
        confirmDataCy="Yes-delete"
      />
      <RenameProcessDialog
        buttonLabel="Rename loop"
        buttonColor="red"
        process={loops?.find((loop) => loop.id === renameLoopId)}
        open={!!renameLoopId}
        title="Rename loop"
        onClose={() => {
          setRenameLoopId(undefined);
        }}
        onSave={(name: string) => {
          if (!renameLoopId) {
            return;
          }
          updateLoop.mutateAsync({ loopId: renameLoopId, name });
        }}
      />
    </div>
  );
};
