import { FieldMappingDialog } from '@spektr/model-builder/containers';
import { useParsedParams } from '@spektr/shared/hooks';
import {
  processBuilderUrl,
  processDetailsParamsSchema,
} from '@spektr/shared/utils';

export const ProcessFieldMappingDialog = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);

  return (
    <FieldMappingDialog
      processId={processId}
      processUrl={processBuilderUrl(processId)}
    />
  );
};
