import { useTheme } from '@spektr/client/providers';
import { Toaster } from 'sonner';

type ToastProviderProps = React.ComponentProps<typeof Toaster>;

export const ToastProvider = ({ ...props }: ToastProviderProps) => {
  const { theme } = useTheme();

  return (
    <Toaster
      position="bottom-right"
      theme={theme}
      toastOptions={{
        unstyled: true,
        classNames: {
          actionButton: 'bg-color-bg-toast-action',
          cancelButton: 'bg-color-bg-toast-cancel',
          closeButton: 'bg-color-bg-toast-close',
        },
      }}
      {...props}
    />
  );
};
