import { useMemo, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { produce } from 'immer';

import { updateFieldsFactory } from '@spektr/client/utils';
import { usePermissionsContext } from '@spektr/client/providers';

import { Button } from '@spektr/client/components';

import {
  ServiceNode as ServiceNodeType,
  SpektrFieldKey,
  SpektrField,
  NodeUpdateInput,
} from '@spektr/shared/types';
import { RBAC } from '@spektr/shared/rbac';
import { ConfigurableNode } from '@spektr/shared/validators';

import { SERVICE_VARIANT_MAP } from '../../constants/services';

import { ServiceFieldClient } from '../../types/ServiceFieldClient';

import { getIdentifiers } from '../../utils/getIdentifiers';
import { getServiceFieldsWithoutProperties } from '../../utils/getServiceFieldsWithoutProperties';
import { getServiceNodeDialogInformation } from '../../utils/getServiceNodeDialogInformation';
import {
  getSelectedKyckrFields,
  isKyckrFieldSelected,
} from '../../utils/kyckr';

import { SectionList } from '../../components/SectionList';
import { IdentifierItem } from '../../components/IdentifierItem';
import { SectionWithTitle } from '../../components/SectionWithTitle';
import { SelectableServiceItem } from '../../components/SelectableServiceItem';
import { KyckrServiceFieldsWithCheckbox } from '../../components/KyckrServiceFieldsWithCheckbox';
import { MitIdServiceFieldWithCheckbox } from '../../components/MitIdServiceFieldWithCheckbox';
import { getSelectedServiceFieldsByNodeType } from '../../utils/getSelectedServiceFieldsByNodeType';

export type ServiceFieldsWithCheckboxProps = {
  fields: ServiceFieldClient[];
  node: ServiceNodeType;
  spektrFields: SpektrField[];
  onSave: (fields: NodeUpdateInput) => void;
};

export const ServiceFieldsWithCheckbox = ({
  fields,
  spektrFields,
  node,
  onSave,
}: ServiceFieldsWithCheckboxProps) => {
  const { getValues, control } = useFormContext();
  const { isValid } = useFormState({
    control,
  });
  const [configuration, setConfiguration] = useState(
    (node as ConfigurableNode).configuration
  );

  const { hasPermission } = usePermissionsContext();
  const serviceFields = useMemo(
    () => getServiceFieldsWithoutProperties(fields),
    [fields]
  );

  const defaultSelectedFields =
    node.nodeType === 'kyckr'
      ? getSelectedKyckrFields(serviceFields)
      : serviceFields
          .filter((field) => {
            const fieldValue = node.fields[field.key] ?? false;

            return fieldValue;
          })
          .map((field) => field.key);

  const [selectedFields, setSelectedFields] = useState<SpektrFieldKey[]>(
    defaultSelectedFields as SpektrFieldKey[]
  );
  const { attributesTitle, attributesHelp, categoriesTitle, categoriesHelp } =
    useMemo(
      () => getServiceNodeDialogInformation(node.nodeType, 'monitoring'),
      [node.nodeType]
    );

  const handleChangeChecked = (fieldKey: SpektrFieldKey, value: boolean) => {
    setSelectedFields(
      produce((draft) => {
        if (value) {
          draft.push(fieldKey);
        } else {
          const index = draft.findIndex((item) => item === fieldKey);

          if (index !== -1) {
            draft.splice(index, 1);
          }
        }
      })
    );
  };

  const handleSave = () => {
    const mapping = getValues();

    const selectedServiceFields = getSelectedServiceFieldsByNodeType(
      node.nodeType,
      fields,
      selectedFields
    );

    const fieldsMap = fields.reduce<Record<string, boolean>>((acc, field) => {
      // when a "main" spektrField is selected (e.g. has_sanctions),
      // all it's subfields (e.g. has_sanctions_fields.*) should be selected as well
      const fieldStartingKey = field.key.split('_fields.').shift() as string;
      acc[field.key] = selectedServiceFields.includes(fieldStartingKey);

      return acc;
    }, {});

    onSave(updateFieldsFactory(node, fieldsMap, mapping, configuration));
  };

  const hideCategorySelection = [
    'openCorporatesRisk',
    'openCorporatesMonitoring',
    'veriff',
  ].includes(node.nodeType);

  const hideAttributesSelection = ['mitId'].includes(node.nodeType);

  return (
    <div className="flex flex-col gap-4">
      <div className="my-2 flex flex-col gap-4 rounded-md border p-4">
        {!hideAttributesSelection && (
          <SectionWithTitle
            title={attributesTitle}
            additionalInfo={attributesHelp}
          >
            <SectionList
              className="max-h-[280px] overflow-y-auto"
              data={getIdentifiers(node.nodeType)}
            >
              {(field) => (
                <IdentifierItem
                  key={field.key}
                  field={field}
                  spektrFields={spektrFields}
                />
              )}
            </SectionList>
          </SectionWithTitle>
        )}

        {!hideCategorySelection && (
          <SectionWithTitle
            title={categoriesTitle}
            additionalInfo={categoriesHelp}
          >
            {serviceFields.length > 0 ? (
              node.nodeType === 'kyckr' ? (
                <KyckrServiceFieldsWithCheckbox
                  fields={serviceFields}
                  isFieldSelected={(fieldKey) =>
                    selectedFields.includes(fieldKey) ||
                    isKyckrFieldSelected(fieldKey, selectedFields)
                  }
                  handleSelectionChange={(fieldKey) => (value) =>
                    handleChangeChecked(fieldKey, value)
                  }
                />
              ) : node.nodeType === 'mitId' ? (
                <MitIdServiceFieldWithCheckbox
                  fields={serviceFields}
                  loginType={configuration?.loginType}
                  updateLoginType={(value: string) =>
                    setConfiguration({ loginType: value })
                  }
                  isFieldSelected={(fieldKey) =>
                    selectedFields.includes(fieldKey)
                  }
                  handleSelectionChange={(fieldKey) => (value) =>
                    handleChangeChecked(fieldKey, value)
                  }
                />
              ) : (
                <SectionList
                  className="max-h-[280px] overflow-y-auto"
                  data={serviceFields}
                >
                  {(item) => (
                    <SelectableServiceItem
                      key={item.key}
                      checked={selectedFields.includes(item.key)}
                      field={item}
                      onChange={(value) => handleChangeChecked(item.key, value)}
                    />
                  )}
                </SectionList>
              )
            ) : (
              <span className="text-color-text-subtext">No service fields</span>
            )}
          </SectionWithTitle>
        )}
      </div>

      <Button
        className="ml-auto"
        disabled={!isValid || !hasPermission(RBAC.ACTIONS.NODE.UPDATE)}
        color={SERVICE_VARIANT_MAP[node.nodeType]}
        onClick={handleSave}
      >
        Save
      </Button>
    </div>
  );
};
