import { LabelHTMLAttributes, forwardRef } from 'react';

export type LabelProps = LabelHTMLAttributes<HTMLLabelElement>;

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ children, ...props }, ref) => (
    <label
      ref={ref}
      {...props}
      className={'text-color-text-form-viewer-label text-sm font-medium'}
    >
      {children}
    </label>
  )
);
Label.displayName = 'Label';
