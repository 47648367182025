import { ReactNode, createContext, useContext, useState } from 'react';

import { updateRouterNodeSchema } from '@spektr/shared/validators';

import {
  IncompleteRouter,
  IncompleteRuleGroup,
  makeIncompleteRuleGroup,
} from '@spektr/shared/components';

export type IncompleteRouterContextType = {
  incomplete: IncompleteRouter;
  routerNode: ReturnType<(typeof updateRouterNodeSchema)['safeParse']>;
  setTitle: (title: string) => void;
  addRuleGroup: () => void;
  removeRuleGroup: (ruleGroupId: string) => void;
  updateRuleGroup: (updates: Partial<IncompleteRuleGroup>) => void;
};

export const IncompleteRouterNodeContext = createContext(
  {} as IncompleteRouterContextType
);

export const useIncompleteRouterNode = () =>
  useContext(IncompleteRouterNodeContext);

export type RouterProviderProps = {
  children: ReactNode;
  initial: IncompleteRouter;
};

export const RouterNodeProvider = ({
  children,
  initial,
}: RouterProviderProps) => {
  const [incomplete, setIncomplete] = useState(initial);

  const routerNode = updateRouterNodeSchema.safeParse(incomplete);

  const setTitle = (title: string) =>
    setIncomplete((current) => ({ ...current, title }));

  const addRuleGroup = () => {
    setIncomplete((current) => {
      const next = makeIncompleteRuleGroup();

      return {
        ...current,
        groups: [...current.groups, next],
      };
    });
  };

  const removeRuleGroup = (ruleGroupId: string) => {
    setIncomplete((current) => {
      const removed = current.groups.filter(
        (group) => group.clientSideOnlyId !== ruleGroupId
      );
      return {
        ...current,
        groups: removed,
      };
    });
  };

  const updateRuleGroup = (updates: Partial<IncompleteRuleGroup>) => {
    setIncomplete((current) => {
      const groups = current.groups.map((group) => {
        if (group.clientSideOnlyId === updates.clientSideOnlyId)
          return { ...group, ...updates };
        else return group;
      });

      return { ...current, groups };
    });
  };

  return (
    <IncompleteRouterNodeContext.Provider
      value={{
        incomplete,
        routerNode,
        setTitle,
        addRuleGroup,
        removeRuleGroup,
        updateRuleGroup,
      }}
    >
      {children}
    </IncompleteRouterNodeContext.Provider>
  );
};
