import { ReactNode } from 'react';
import { uniqueId } from 'lodash';

import { cn } from '@spektr/client/utils';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../Accordion';

export type ComboboxGroupProps = {
  children: React.ReactNode;
  className?: string;
  label?: ReactNode;
  headerClassName?: string;
};

export const ComboboxGroup = ({
  className,
  children,
  label,
  headerClassName,
}: ComboboxGroupProps) => {
  const groupName = uniqueId('combobox-group-');

  return (
    <li className={className}>
      {label ? (
        <Accordion
          key={groupName}
          collapsible
          defaultValue={groupName}
          type="single"
          className="w-full"
        >
          <AccordionItem value={groupName} className="relative border-none">
            <AccordionTrigger
              iconPosition="right"
              className={cn('p-2', headerClassName)}
            >
              <p
                className={cn('text-color-text-subtext text-xs font-semibold')}
              >
                {label}
              </p>
            </AccordionTrigger>
            <AccordionContent>
              <ul className="list-none">{children}</ul>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ) : (
        <ul className="list-none">{children}</ul>
      )}
    </li>
  );
};
