import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { z } from 'zod';

import { insightsRecordDetailsUrl } from '@spektr/shared/utils';
import { SortOrder } from '@spektr/shared/validators';

import { cn } from '@spektr/client/utils';
import {
  PaginationCombobox,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

import { useRecordsContext } from '../../providers';
import { useGetDatasetRecords } from '../../hooks';
import { getVisibleRecords } from '../../utils';

import { TableHeaderItem } from './TableHeaderItem';

const allowedPageValues = [10, 20, 50];

const pageLimitSchema = z
  .number()
  .refine((value) => allowedPageValues.includes(value), {
    message: 'Value must be 10, 20, or 50',
  })
  .default(10);

export const RecordsTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = pageLimitSchema.parse(
    Number(searchParams.get('limit') ?? '10')
  );
  const page = Number(searchParams.get('page') ?? '1');
  const sortField = searchParams.get('sortField') ?? undefined;
  const sortOrder = (searchParams.get('sortOrder') as SortOrder) ?? undefined;
  const searchValueParam = searchParams.get('searchValue') ?? undefined;
  const fieldParam = searchParams.get('searchField') ?? undefined;

  const {
    selectedDataset,
    visibleColumns,
    areVisibleColumnsInitialized,
    initializeVisibleColumns,
    updateColumnVisibility,
  } = useRecordsContext();
  const { records, totalPages } = useGetDatasetRecords(selectedDataset, {
    limit: pageLimit,
    page,
    sortField,
    sortOrder,
    searchValue: searchValueParam,
    searchField: fieldParam,
  });

  const visibleData = useMemo(
    () => getVisibleRecords(records, visibleColumns),
    [records, visibleColumns]
  );

  const isEmptyTable =
    records.length === 0 || visibleData.every((record) => isEmpty(record));

  const handleActionClick = (column: string) => (action: string) => {
    switch (action) {
      case 'sort_asc':
        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          sortOrder: 'asc',
          sortField: column,
        });
        break;
      case 'sort_desc':
        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          sortOrder: 'desc',
          sortField: column,
        });
        break;
      case 'hide':
        updateColumnVisibility(column, true);
        break;
      default:
        break;
    }
  };

  const handleChangeLimit = (limit: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
      limit: limit.toString(),
    });
  };
  const handleChangePage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    });
  };

  const navigateToRecord = (recordId: string) => {
    navigate(insightsRecordDetailsUrl(selectedDataset, recordId));
  };

  useEffect(() => {
    if (
      !areVisibleColumnsInitialized &&
      visibleColumns.length === 0 &&
      !isEmpty(records?.[0]?.fields)
    ) {
      initializeVisibleColumns(records?.[0]?.fields);
    }
  }, [visibleColumns, records, initializeVisibleColumns]);

  return isEmptyTable ? (
    <div className="text-color-text-subtext rounded-md border p-4 text-center">
      No records found
    </div>
  ) : (
    <div className="flex flex-col gap-6">
      <div className="overflow-x-auto rounded-md border">
        <Table className="border-separate">
          <TableHeader>
            <TableRow className="hover:bg-inherit">
              {visibleColumns.map((column) => (
                <TableHeaderItem
                  key={column}
                  name={column}
                  sortType={sortField === column ? sortOrder : undefined}
                  onActionClick={handleActionClick(column)}
                />
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {visibleData.map((record, index) => (
              <TableRow
                key={record.id}
                className="hover:bg-color-bg-table-row--hover cursor-pointer"
                onClick={() => navigateToRecord(record.id)}
              >
                {Object.entries(record.fields).map(([key, value]) => (
                  <TableCell
                    key={key}
                    className={cn(
                      'max-w-48 px-4 py-6',
                      'border-b',
                      'leading-none',
                      index === visibleData.length - 1 && 'border-0'
                    )}
                  >
                    {String(value)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <PaginationCombobox
        currentPage={page}
        totalPages={totalPages}
        pageLimit={pageLimit}
        onChangeLimit={handleChangeLimit}
        onChangePage={handleChangePage}
      />
    </div>
  );
};
