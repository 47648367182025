import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { XIcon } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { useDebouncedSearch } from '@spektr/platform-hooks';
import { IconButton, Input, Select } from '@spektr/client/components';

import { useGetDatasets } from '../../hooks';
import { useRecordsContext } from '../../providers';

export const DatasetFilters = () => {
  const { selectedDataset } = useRecordsContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchValueParam = searchParams.get('searchValue') ?? '';
  const fieldParam = searchParams.get('searchField') ?? '';

  const { searchValue, setSearchValue } = useDebouncedSearch(
    searchValueParam,
    (value) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        searchField: datasetField,
        searchValue: value,
      });
    },
    500
  );
  const [datasetField, setDatasetField] = useState(fieldParam);

  const datasets = useGetDatasets();

  const handleChangeField = (field: string) => {
    setDatasetField(field);
    setSearchValue('');
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleClickClear = () => {
    setDatasetField('');
    setSearchValue('');

    searchParams.delete('searchValue');
    searchParams.delete('searchField');
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
    });
  };

  const datasetFields = useMemo(() => {
    if (!datasets) return [];

    const dataset = datasets.find(({ id }) => id === selectedDataset);
    if (!dataset) return [];

    return Object.values(dataset.fields).map((field) => ({
      label: field.name,
      value: field.name,
    }));
  }, [datasets, selectedDataset]);

  useEffect(() => {
    setDatasetField(fieldParam);
    setSearchValue(searchValueParam);
  }, [searchValueParam, fieldParam]);

  return (
    <div
      className={cn(
        'relative',
        'flex flex-row items-center',
        'border-color-border-primary rounded-md border'
      )}
    >
      <Select
        className="rounded-r-none border dark:border-0 dark:border-r"
        placeholder="Select field"
        options={datasetFields}
        value={datasetField}
        onValueChange={handleChangeField}
      />
      <Input
        disabled={!datasetField}
        className="min-w-20 rounded-l-none border-l-0 pr-8 focus-visible:outline-none focus-visible:ring-0"
        placeholder="Filter dataset"
        value={searchValue}
        onChange={handleChangeValue}
      />
      {datasetField && searchValue && (
        <IconButton
          className="absolute right-0 border-0"
          variant="text"
          size="sm"
          onClick={handleClickClear}
        >
          <XIcon className="h-4 w-4" />
        </IconButton>
      )}
    </div>
  );
};
