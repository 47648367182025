import { FieldMappingDialog } from '@spektr/model-builder/containers';
import { useParsedParams } from '@spektr/shared/hooks';
import {
  processDetailsParamsSchema,
  loopDetailsUrl,
} from '@spektr/shared/utils';

export const LoopFieldMappingDialog = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);

  return (
    <FieldMappingDialog
      processId={processId}
      processUrl={loopDetailsUrl(processId)}
    />
  );
};
