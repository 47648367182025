import { Accordion } from '../Accordion';

import { CollapseItem } from './components/CollapseItem';

import { ProcessItem } from './types';

export type ProcessCollapsibleListProps = {
  defaultCollapsed?: string;
  items: ProcessItem[];
  onCheck: (processId: string, itemId: string, value: boolean) => void;
};

export const ProcessCollapsibleList = ({
  defaultCollapsed,
  items,
  onCheck,
}: ProcessCollapsibleListProps) => {
  return (
    <Accordion
      className="flex flex-col gap-2"
      defaultValue={defaultCollapsed}
      collapsible
      type="single"
    >
      {items.map((item) => {
        const isSelected = item.children.some((child) => child.isSelected);
        return (
          <CollapseItem
            key={`${item.id}-${isSelected}`}
            children={item.children}
            id={item.id}
            label={item.label}
            type={item.type}
            isSelected={isSelected}
            onCheck={(itemId: string, value: boolean) =>
              onCheck(item.id, itemId, value)
            }
          />
        );
      })}
    </Accordion>
  );
};
