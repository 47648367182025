import { CirclePlus } from 'lucide-react';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from 'reactflow';

import { RBAC } from '@spektr/shared/rbac';
import {
  GraphEdgeData,
  loopSheetUrl,
  processBuilderSheetUrl,
} from '@spektr/shared/utils';

import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/client/utils';

import { useFeatureFlags } from '@spektr/platform-hooks';
import { EDGE } from '@spektr/shared/components';

export function EdgeIntermediary({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style,
}: EdgeProps<GraphEdgeData>) {
  const { moonraker } = useFeatureFlags();
  const navigate = useNavigate();
  const { hasPermission } = usePermissionsContext();

  const [path] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    centerX: sourceX + EDGE.CENTER.X, // bend the edge 'EDGE.CENTER.X' pixels from the source node if needed. The default is to bend the edge halfway between source and target but that makes little room for the label
  });

  const offsetY = Math.abs(Math.round(targetY - sourceY));
  const offsetX = Math.abs(Math.round(targetX - sourceX));
  const isHorizontal = offsetY === 0;

  const edge = data?.predecessorNode?.adj.find(
    (edge) => edge.id === data?.successorNode?.id
  );

  const labelX = isHorizontal
    ? sourceX + offsetX * 0.5 // sets x halfway between source and target
    : targetX - EDGE.LABEL.OFFSET_X; // sets x to the left of the target node

  const onClick = (ev: MouseEvent) => {
    ev.preventDefault();

    if (data?.process) {
      const href =
        data.process.type === 'loop'
          ? loopSheetUrl(data.process.id)
          : processBuilderSheetUrl(data.process.id);

      navigate(href, {
        state: {
          predecessorNode: data.predecessorNode,
          successorNode: data.successorNode,
        },
      });
    }
  };

  const showAsFallback =
    edge?.type === 'fallback' &&
    moonraker &&
    process.env['NODE_ENV'] === 'development';

  const edgeStyle = { ...style, strokeWidth: '2px' };

  if (!data?.process) return <BaseEdge id={id} style={edgeStyle} path={path} />;

  return (
    <>
      <BaseEdge id={id} style={edgeStyle} path={path} />
      <EdgeLabelRenderer>
        <button
          disabled={!hasPermission(RBAC.ACTIONS.NODE.CREATE)}
          className={cn(
            'nodrag nopan absolute',
            'flex h-7 w-7 items-center justify-center',
            'disabled:opacity-50'
          )}
          style={{
            pointerEvents: 'all',
            transform: `translate(-50%, -50%) translate(${labelX}px,${targetY}px)`,
          }}
          onClick={onClick}
        >
          {showAsFallback ? (
            'F'
          ) : (
            <CirclePlus
              className={cn(
                'bg-color-bg-primary h-4 w-4 rounded-full',
                'stroke-color-stroke-default hover:stroke-color-stroke-default--hover'
              )}
            />
          )}
        </button>
      </EdgeLabelRenderer>
    </>
  );
}
