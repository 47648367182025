import { ReactNode } from 'react';
import { MoreVertical } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import {
  DropdownMenuComboBox,
  DropdownMenuComboBoxProps,
  DropdownOption,
} from '../DropdownMenu';

export type CardWithContextMenuProps = {
  actions?: DropdownOption[];
  children: ReactNode;
  className?: string;
  footer: ReactNode;
  header: ReactNode;
  name?: string;
  onActionClick?: DropdownMenuComboBoxProps['onClick'];
  onClick?: () => void;
  disabled?: boolean;
};

export const CardWithContextMenu = ({
  actions = [],
  children,
  className,
  footer,
  header,
  name = '',
  disabled = false,
  onActionClick,
  onClick,
}: CardWithContextMenuProps) => {
  return (
    <div
      className={cn(
        'flex flex-col',
        'border-color-border-card rounded-md border',
        'overflow-hidden transition-colors',
        'bg-color-bg-card-default',
        !disabled && 'hover:bg-color-bg-card--hover',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        className
      )}
      onClick={!disabled && onClick ? onClick : undefined}
    >
      <div className="flex w-full px-4 pt-4">
        <div className="flex grow">{header}</div>
        {actions?.length > 0 && (
          <div className="ml-auto shrink-0">
            <DropdownMenuComboBox
              align="start"
              alignOffset={10}
              side="right"
              trigger={
                <MoreVertical
                  className={cn(
                    'ml-auto w-5',
                    'stroke-color-stroke-primary',
                    'h-5 cursor-pointer',
                    'hover:stroke-color-stroke-primary--hover'
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  data-cy={'card-more-options-' + name}
                />
              }
              options={actions}
              onClick={onActionClick}
            />
          </div>
        )}
      </div>
      <div className="text-color-text-primary grow p-4 text-base font-medium">
        {children}
      </div>
      <div
        className={cn(
          'border-color-border-primary border-t',
          'px-4 py-3',
          'text-xs font-medium leading-none',
          'text-color-text-subtext'
        )}
      >
        {footer}
      </div>
    </div>
  );
};
