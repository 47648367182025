export const parseRange = (range: string | undefined) => {
  const regex = /^\[(-?\d*)?,(-?\d*)?\]$/;
  const match = range?.match(regex);

  if (!match) return ['', ''] as const;

  const min = match[1] || '';
  const max = match[2] || '';
  return [min, max] as const;
};
