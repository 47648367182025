import axios from 'axios';
import { CircleX } from 'lucide-react';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

import { DashboardIcon } from '@radix-ui/react-icons';

import {
  processBuilderUrl,
  processDetailsParamsSchema,
} from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';
import { CognitoUserData } from '@spektr/shared/types';

import { BasicDialog, SpektrErrorBoundary } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

import { SettingItemSkeleton } from './components/SettingItemSkeleton';
import { ProcessSchedule } from './containers/ProcessSchedule';

export type ProcessScheduleDialogProps = {
  publicPageUrl: string;
  user?: CognitoUserData;
};

export const ProcessScheduleDialog = ({
  publicPageUrl,
  user,
}: ProcessScheduleDialogProps) => {
  const navigate = useNavigate();
  const { processId } = useParsedParams(processDetailsParamsSchema);

  const handleClose = () => {
    navigate(processBuilderUrl(processId));
  };

  return (
    <BasicDialog
      className="w-[620px] max-w-[620px]"
      defaultOpen
      title="Process settings"
      icon={<DashboardIcon className="text-color-cyan mr-2 h-5 w-5" />}
      description="Create a version of your process by publishing it. Version helps you save a state of your process. Set your version to live to begin its execution."
      onClose={handleClose}
    >
      <SpektrErrorBoundary
        fallbackRender={({ error }) => {
          const errorMsg = axios.isAxiosError(error)
            ? error.response?.data?.message
            : 'Something went wrong when loading the process settings.';
          return (
            <div
              className={cn(
                'flex items-center justify-center gap-1',
                'h-[100px]',
                'border-color-red rounded-md border'
              )}
            >
              <CircleX className="text-color-red h-4 w-4" />
              <p className="text-color-text-primary text-sm">{errorMsg}</p>
            </div>
          );
        }}
      >
        <Suspense fallback={<SettingItemSkeleton />}>
          <ProcessSchedule
            processId={processId}
            publicPageUrl={publicPageUrl}
            user={user}
          />
        </Suspense>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
