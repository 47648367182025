import { Zodios } from '@zodios/core';
import { pluginToken } from '@zodios/plugins';

import {
  ApiConfig,
  createBaseApiClient,
  getAccessToken,
} from '@spektr/client/utils';

import { userApi } from '@spektr/shared/validators';

function createUserApiClient(config: ApiConfig) {
  const instance = new Zodios(config.baseUrl, userApi);

  instance.use(
    pluginToken({
      getToken: () =>
        Promise.resolve(getAccessToken(config.clientId) ?? undefined),
    })
  );

  return instance;
}

function createPublicUserApiClient(config: ApiConfig) {
  const instance = new Zodios(config.baseUrl, userApi);

  return instance;
}

export const UsersApiClient =
  createBaseApiClient<typeof userApi>(createUserApiClient);

export const PublicUsersApiClient = createBaseApiClient<typeof userApi>(
  createPublicUserApiClient
);
