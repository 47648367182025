import { SpektrFieldTypedKeyWithMapping } from '@spektr/shared/validators';

export const convertFieldsArrayToObject = (
  fields: SpektrFieldTypedKeyWithMapping[]
): Record<string, SpektrFieldTypedKeyWithMapping> | null => {
  if (!fields || !fields.length) {
    return null;
  }

  return fields.reduce((obj, field) => {
    const { key, ...rest } = field;

    return { ...obj, [key]: rest };
  }, {});
};
