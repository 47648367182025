export const SPEKTR_FIELD_TYPES = [
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Boolean',
    value: 'boolean',
  },
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'File',
    value: 'file',
  },
];
