import { Button } from '@spektr/client/components';

type DatasetActionButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
};

export const DatasetActionButton = ({
  onClick,
  children,
  disabled = false,
}: DatasetActionButtonProps) => (
  <div className="flex justify-center rounded-md border border-dashed p-5">
    <Button color="secondary" size="lg" onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  </div>
);
