import type { Field } from '@spektr/moonraker-types';

export function createDropFieldAction(field: Field, rootFieldId?: string) {
  return {
    type: 'drop_field' as const,
    payload: {
      field,
      rootFieldId,
    },
  };
}

export type DropFieldAction = ReturnType<typeof createDropFieldAction>;
