import { z } from 'zod';

import { unixTimestampSchema } from '../utils';

export const CreateApiKeySchema = z.strictObject({
  name: z.string().min(1),
});
export type CreateApiKeySchema = z.infer<typeof CreateApiKeySchema>;

export const ApiKeySchema = z.strictObject({
  id: z.string().min(1),
  key: z.string().min(1),
  name: z.string().min(1),
  enabled: z.boolean(),
  createdAt: unixTimestampSchema,
});
export type ApiKeySchema = z.infer<typeof ApiKeySchema>;
