import { cn } from '@spektr/client/utils';

export const veriffContained = cn(
  'bg-color-veriff',
  'dark:text-color-text-button-contained-vendor text-color-white',
  'hover:bg-color-veriff',
  'active:bg-color-veriff'
);
export const veriffOutlined = cn(
  veriffContained,
  'border-color-veriff',
  'text-color-veriff',
  'hover:bg-color-veriff/10',
  'active:bg-color-veriff/20',
  'disabled:border-color-veriff/50'
);
export const veriffText = cn('text-color-veriff');
