import { ReactNode, useContext, useMemo } from 'react';
import { Link, useLocation, useMatches } from 'react-router-dom';
import { z } from 'zod';

import { ArrowLeft } from 'lucide-react';

import { Avatar, Button, DashboardHeader } from '@spektr/client/components';

import { AuthContext } from '../../auth/auth-context';

const handleSchema = z.object({
  backTo: z
    .object({
      url: z.string(),
      label: z.string(),
    })
    .optional(),
  renderLeft: z.function().returns(z.custom<ReactNode>()).optional(),
  renderRight: z.function().returns(z.custom<ReactNode>()).optional(),
});
export type HandleSchema = z.infer<typeof handleSchema>;

/**
 * @see https://reactrouter.com/en/main/hooks/use-matches
 * @see `apps/model-builder/src/app/routes/process-builder/:id/index.tsx:L38`
 *
 * @description
 *    This component is used to render the header for the dashboard pages.
 *  It will loop thru all the handle objects in the route and merge them
 *  in a single object. This means it will take the last handle object
 *  key in the array if there are multiple.
 *
 * @example
 * Given the route:
 *  {
 *    path: INSIGHTS_URL,
 *    element: <InsightsIndexPage />,
 *    children: [
 *      {
 *        index: true,
 *        element: <InsightsDashboard />,
 *      },
 *      {
 *        path: INSIGHTS_RUN_PARAM,
 *        element: <InsightsRunDetails />,
 *        handle: {
 *          backTo: {
 *            url: INSIGHTS_URL,
 *            label: 'Insight Engine',
 *          },
 *        },
 *        children: [
 *          {
 *            path: 'third-level-deep',
 *            element: <ThirdLevelDeepComponent>,
 *            handle: {
 *              renderLeft: () => <div>left</div>,
 *              renderRight: () => <div>right</div>,
 *            },
 *          },
 *        ],
 *      },
 *    ],
 *  };
 *
 *  It will compile into the following object:
 *  {
 *    backTo: {
 *      url: INSIGHTS_URL,
 *      label: 'Insight Engine',
 *    },
 *    renderLeft: () => <div>left</div>,
 *    renderRight: () => <div>right</div>,
 *  }
 */
export const HeaderWithHandler = () => {
  const matches = useMatches();
  const { user } = useContext(AuthContext);
  const location = useLocation();

  const handlers = useMemo<HandleSchema | null>(() => {
    const handle = matches.reduce((acc, match) => {
      if (match.handle) {
        return {
          ...acc,
          ...match.handle,
        };
      }
      return acc;
    }, {});

    const parsedHandle = handleSchema.safeParse(handle);

    if (!parsedHandle.success) {
      return null;
    }

    if (parsedHandle.data.backTo && location.state) {
      parsedHandle.data.backTo.url +=
        '?' + new URLSearchParams(location.state).toString();
    }

    return parsedHandle.data;
  }, [matches, location]);

  return (
    <DashboardHeader>
      <div className="flex w-full items-center gap-6">
        {handlers?.backTo && (
          <Link to={handlers.backTo.url}>
            <Button
              size="sm"
              color="secondary"
              startIcon={<ArrowLeft className="h-3 w-3" />}
            >
              {handlers.backTo.label}
            </Button>
          </Link>
        )}
        {handlers?.renderLeft && handlers.renderLeft()}
      </div>
      <div className="ml-auto flex items-center gap-6">
        {handlers?.renderRight && handlers.renderRight()}
        {user && (
          <Link to="/settings">
            <Avatar firstName={user.given_name} lastName={user.family_name} />
          </Link>
        )}
      </div>
    </DashboardHeader>
  );
};
