import { cn } from '@spektr/client/utils';
import { TableHead, TableHeader, TableRow } from '@spektr/client/components';

const baseThClasses = cn(
  'px-4 py-3.5',
  'border-b',
  'text-xs text-color-text-table-header'
);

export const ActionsTableHeader = () => (
  <TableHeader>
    <TableRow>
      <TableHead className={baseThClasses}>Process</TableHead>
      <TableHead className={baseThClasses}>Source</TableHead>
      <TableHead className={baseThClasses}>Action created</TableHead>
      <TableHead className={cn(baseThClasses, 'text-right')}>Status</TableHead>
    </TableRow>
  </TableHeader>
);
