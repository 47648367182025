import { DatePicker } from '@spektr/client/components';

import { useIncompleteRuleGroup } from '../providers';

export const RightOperandDatePickerInput = (props: { ruleId: string }) => {
  const { getPredicate, setPredicate } = useIncompleteRuleGroup();

  const rule = getPredicate(props.ruleId);

  if (!rule) return null;

  let epochOrToday: string | undefined;
  if (rule.right === '@today') {
    epochOrToday = '@today';
  } else if (typeof rule.right === 'number' || rule.right === 'string') {
    const epoch = Number(rule.right);
    const utc = epoch ? new Date(epoch) : undefined;
    const local = utc ? undoTimezoneOffset(utc) : undefined;
    epochOrToday = local?.getTime().toString();
  } else {
    epochOrToday = undefined;
  }

  return (
    <DatePicker
      className="h-7 px-2 py-1 text-xs"
      iconClassName="w-3 h-3"
      epochOrToday={epochOrToday}
      disabled={!rule.left || !rule.operator}
      onChange={(epochOrToday) => {
        if (epochOrToday === '@today') {
          setPredicate(props.ruleId, { right: '@today' });
        } else if (epochOrToday) {
          const offsetted = offsetTimezone(new Date(Number(epochOrToday)));
          const epochOffsetted = offsetted?.getTime();
          setPredicate(props.ruleId, { right: epochOffsetted });
        } else {
          setPredicate(props.ruleId, { right: undefined });
        }
      }}
    />
  );
};

/**
 *  IMPORTANT NOTE ON TIMEZONES
 *  `date` from `DatePicker` is a Date object having hour/minute/seconds set to 0 in the LOCAL timezone of the user.
 *  Thus we need to convert it to the UTC timezone.
 *  We *DONT* want to get the same unix epoch but represented in UTC instead of local timezone.
 *  Instead we *DO* want to get the unix epoch of the same date (year/month/day) but moved UTC timezone.
 */
const offsetTimezone = (date: Date) => {
  const utc = new Date(date);
  const offsetInHours = date.getTimezoneOffset() / 60;
  utc.setUTCHours(date.getUTCHours() - offsetInHours);

  return utc;
};

const undoTimezoneOffset = (date: Date) => {
  const local = new Date(date);
  const offsetInHours = date.getTimezoneOffset() / 60;
  local.setUTCHours(date.getUTCHours() + offsetInHours);

  return local;
};
