import { createElement } from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';
import { VendorServiceName, isServiceName } from '@spektr/client/types';

import {
  BodaccIcon,
  CSIWatchdogIcon,
  CompaniesHouseIcon,
  ComplyAdvantageIcon,
  CreditSafeIcon,
  DowJonesIcon,
  DunBradstreetIcon,
  ExperianIcon,
  FinScanIcon,
  KyckrIcon,
  LSEGIcon,
  LexisNexisIcon,
  MoodyIcon,
  OpenCorporatesIcon,
  TransUnionIcon,
  VeriffIcon,
} from '../Icons';
import { MitIdIcon } from '../Icons/MitId';

export type ServiceVendorLogoProps = {
  className?: string;
  serviceName: VendorServiceName;
};

const COMPONENTS_MAP: Record<VendorServiceName, any> = {
  complyAdvantage: ComplyAdvantageIcon,
  openCorporates: OpenCorporatesIcon,
  veriff: VeriffIcon,
  mitId: MitIdIcon,
  companiesHouse: CompaniesHouseIcon,
  dunBradstreet: DunBradstreetIcon,
  bodacc: BodaccIcon,
  kyckr: KyckrIcon,
  moody: MoodyIcon,
  dowJones: DowJonesIcon,
  lexisNexis: LexisNexisIcon,
  finScan: FinScanIcon,
  csiWatchdog: CSIWatchdogIcon,
  lseg: LSEGIcon,
  transUnion: TransUnionIcon,
  experian: ExperianIcon,
  creditSafe: CreditSafeIcon,
};

export const ServiceVendorLogo = ({
  serviceName,
  className,
}: ServiceVendorLogoProps) => {
  if (!isServiceName(serviceName)) {
    return null;
  }

  return (
    <div className={cn(variants({ service: serviceName }), className)}>
      {createElement(COMPONENTS_MAP[serviceName])}
    </div>
  );
};

const variants = cva('', {
  variants: {
    service: {
      complyAdvantage: 'bg-color-complyAdvantage',
      openCorporates: 'bg-color-openCorporates',
      veriff: 'bg-color-veriff',
      mitId: 'bg-color-mitId',
      companiesHouse: 'bg-color-companiesHouse',
      dunBradstreet: 'bg-color-dunBradstreet',
      bodacc: 'bg-color-bg-white',
      kyckr: 'bg-color-kyckr',
      moody: 'bg-color-bg-white',
      dowJones: 'bg-color-dowJones',
      lexisNexis: 'bg-color-lexisNexis',
      finScan: 'bg-color-bg-white',
      csiWatchdog: 'bg-color-bg-white',
      lseg: 'bg-color-bg-white',
      transUnion: 'bg-color-transUnion',
      experian: 'bg-color-bg-white',
      creditSafe: 'bg-color-creditSafe',
    },
  },
  defaultVariants: {
    service: 'openCorporates',
  },
});
