import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { BannerNotificationsContext } from './context';

import { createBannerNotification } from './utils/createBannerNotification';

import { NotificationItem } from './components/NotificationItem';

import { AddBannerFn } from './types/AddBannerFn';
import { BannerNotificationItem } from './types/BannerNotificationItem';
import { BannerNotificationsContextType } from './types/BannerNotificationsContext';

export type BannerNotificationsProviderProps = {
  children: ReactNode | ReactNode[];
  defaultNotifications?: BannerNotificationItem[];
};

const timeoutIds = new Map<string, NodeJS.Timeout>();

export const BannerNotifications = ({
  children,
  defaultNotifications = [],
}: BannerNotificationsProviderProps) => {
  const [notifications, setNotifications] =
    useState<BannerNotificationItem[]>(defaultNotifications);

  const handleRemoveNotification = useCallback((id: string) => {
    const timeoutId = timeoutIds.get(id);

    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutIds.delete(id);
    }

    setNotifications((prev) => prev.filter((n) => n.id !== id));
  }, []);

  const handleAddNotification = useCallback<AddBannerFn>(
    (message, type, meta) => {
      setNotifications((prev) => {
        const newNotification = createBannerNotification(message, type, meta);

        if (newNotification?.duration) {
          const timeoutId = setTimeout(() => {
            handleRemoveNotification(newNotification.id);
          }, newNotification.duration);

          timeoutIds.set(newNotification.id, timeoutId);
        }

        return [...prev, newNotification];
      });
    },
    [handleRemoveNotification]
  );

  const sortedBanners = useMemo(
    () => notifications.sort((a, b) => a.priority! - b.priority!),
    [notifications]
  );

  const api = useMemo<BannerNotificationsContextType>(() => {
    return {
      notifications: sortedBanners,
      push: handleAddNotification,
      remove: handleRemoveNotification,
    };
  }, [handleAddNotification, handleRemoveNotification, sortedBanners]);

  useEffect(() => {
    if (defaultNotifications.length > 0) {
      defaultNotifications.forEach((item) => {
        if (timeoutIds.get(item.id)) {
          return;
        }

        if (item?.duration && item.duration > 0) {
          const timeoutId = setTimeout(() => {
            handleRemoveNotification(item.id);
          }, item.duration);

          timeoutIds.set(item.id, timeoutId);
        }
      });
    }
  }, [defaultNotifications, handleRemoveNotification]);

  return (
    <BannerNotificationsContext.Provider value={api}>
      <div>
        {sortedBanners.map((item) => {
          return (
            <NotificationItem
              key={item.id}
              data={item}
              onClose={handleRemoveNotification}
            />
          );
        })}
      </div>
      {children}
    </BannerNotificationsContext.Provider>
  );
};
