import { Zodios } from '@zodios/core';
import { pluginToken } from '@zodios/plugins';

import {
  ApiConfig,
  createBaseApiClient,
  getAccessToken,
} from '@spektr/client/utils';

import { apiKeyApi } from '@spektr/shared/validators';

function createApiKeyClient(config: ApiConfig) {
  const instance = new Zodios(config.baseUrl, apiKeyApi);

  instance.use(
    pluginToken({
      getToken: () =>
        Promise.resolve(getAccessToken(config.clientId) ?? undefined),
    })
  );

  return instance;
}

export const ApiKeysApiClient =
  createBaseApiClient<typeof apiKeyApi>(createApiKeyClient);
