import { ProcessScheduleDialog } from '@spektr/model-builder/containers';
import { useContext } from 'react';

import { AuthContext } from '../../../auth/auth-context';

export const ProcessSchedulePage = ({
  publicPageUrl,
}: {
  publicPageUrl: string;
}) => {
  const { user } = useContext(AuthContext);

  return <ProcessScheduleDialog publicPageUrl={publicPageUrl} user={user} />;
};
