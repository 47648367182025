import { Button, ButtonGroup } from '@spektr/client/components';

export type LoopsToolbarProps = {
  status: string;
  onChangeStatus: (status: string) => void;
};

export const LoopsToolbar = ({ status, onChangeStatus }: LoopsToolbarProps) => {
  return (
    <div className="shrink-0 grow overflow-hidden">
      <div className="flex items-center gap-4">
        <p className="text-color-text-primary text-lg">Your loops</p>
        <ButtonGroup
          className="ml-auto"
          defaultActive={status}
          size="sm"
          onChange={onChangeStatus}
        >
          <Button value="all">View all</Button>
          <Button value="live">Live</Button>
          <Button value="not-live">Not Live</Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
