import { SpektrField } from '../../goldenRecord';

//TODO:  [ST-1237] Label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and retrieved by the field key
export const hasSanctionsFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_sanctions_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_sanctions_fields.last_updated_utc',
  },
  {
    key: 'has_sanctions_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_sanctions_fields.entity_type',
  },
  {
    key: 'has_sanctions_fields.name',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_sanctions_fields.name',
  },
  {
    key: 'has_sanctions_fields.types',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_sanctions_fields.types',
  },
];

export const hasWarningsFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_warnings_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_warnings_fields.last_updated_utc',
  },
  {
    key: 'has_warnings_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_warnings_fields.entity_type',
  },
  {
    key: 'has_warnings_fields.name',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_warnings_fields.name',
  },
  {
    key: 'has_warnings_fields.types',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_warnings_fields.types',
  },
];

export const hasFitnessAndProbityFields: Array<Omit<SpektrField, 'sourceId'>> =
  [];

export const hasAdverseMediaFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_adverse_media_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_adverse_media_fields.last_updated_utc',
  },
  {
    key: 'has_adverse_media_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_adverse_media_fields.entity_type',
  },
  {
    key: 'has_adverse_media_fields.name',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_adverse_media_fields.name',
  },
  {
    key: 'has_adverse_media_fields.types',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'has_adverse_media_fields.types',
  },
];

export const isPEPFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'is_PEP_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_fields.last_updated_utc',
  },
  {
    key: 'is_PEP_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_fields.entity_type',
  },
  {
    key: 'is_PEP_fields.name',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_fields.name',
  },
  {
    key: 'is_PEP_fields.types',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_fields.types',
  },
];

export const isPEPClass1Fields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'is_PEP_class_1_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_1_fields.last_updated_utc',
  },
  {
    key: 'is_PEP_class_1_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_1_fields.entity_type',
  },
  {
    key: 'is_PEP_class_1_fields.name',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_1_fields.name',
  },
  {
    key: 'is_PEP_class_1_fields.types',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_1_fields.types',
  },
];

export const isPEPClass2Fields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'is_PEP_class_2_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_2_fields.last_updated_utc',
  },
  {
    key: 'is_PEP_class_2_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_2_fields.entity_type',
  },
  {
    key: 'is_PEP_class_2_fields.name',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_2_fields.name',
  },
  {
    key: 'is_PEP_class_2_fields.types',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_2_fields.types',
  },
];

export const isPEPClass3Fields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'is_PEP_class_3_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_3_fields.last_updated_utc',
  },
  {
    key: 'is_PEP_class_3_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_3_fields.entity_type',
  },
  {
    key: 'is_PEP_class_3_fields.name',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_3_fields.name',
  },
  {
    key: 'is_PEP_class_3_fields.types',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_3_fields.types',
  },
];

export const isPEPClass4Fields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'is_PEP_class_4_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_4_fields.last_updated_utc',
  },
  {
    key: 'is_PEP_class_4_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_4_fields.entity_type',
  },
  {
    key: 'is_PEP_class_4_fields.name',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_4_fields.name',
  },
  {
    key: 'is_PEP_class_4_fields.types',
    type: 'string',
    source: 'complyAdvantageKyc',
    label: 'is_PEP_class_4_fields.types',
  },
];

export const complyAdvantageKycCalculatedFields: Array<
  Omit<SpektrField, 'sourceId'>
> = [
  {
    key: 'has_sanctions',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label: 'Person is on sanctions lists',
  },
  {
    key: 'has_warnings',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label: 'Person is on warning lists',
  },
  {
    key: 'has_fitness_and_probity',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label: 'Person is on exclusion lists',
  },
  {
    key: 'has_adverse_media',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label: 'Person has adverse media mentions',
  },
  {
    key: 'is_PEP',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label: 'Is Politically Exposed Person',
  },
  {
    key: 'is_PEP_class_1',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label:
      'PEP Class 1 - Heads of State, National Parliaments, National Goverments',
  },
  {
    key: 'is_PEP_class_2',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label: 'PEP Class 2 - Regional Goverments, Regional Parliaments',
  },
  {
    key: 'is_PEP_class_3',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label: 'PEP Class 3 - Senior Management & Boards of SOEs',
  },
  {
    key: 'is_PEP_class_4',
    type: 'boolean',
    source: 'complyAdvantageKyc',
    label: 'PEP Class 4 - Mayors and Local City Councils',
  },
];

export const complyAdvantageKycSpektrFields: Array<
  Omit<SpektrField, 'sourceId'>
> = [
  ...hasSanctionsFields,
  ...hasWarningsFields,
  ...hasFitnessAndProbityFields,
  ...hasAdverseMediaFields,
  ...isPEPFields,
  ...isPEPClass1Fields,
  ...isPEPClass2Fields,
  ...isPEPClass3Fields,
  ...isPEPClass4Fields,
  ...complyAdvantageKycCalculatedFields,
];
