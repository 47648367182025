import { Equal } from 'lucide-react';
import { useRef, useState } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';

import { cn } from '@spektr/client/utils';

import {
  WidgetProperties,
  WidgetValidationItem,
} from '@spektr/shared/validators';

import { IncompleteWidgetInstance } from '../../types/IncompleteWidgetInstance';
import { OnUpdateFn } from '../../types/UpdateFn';

import { CanvasItemSettings } from '../CanvasItemSettings';
import { WidgetPreview } from '../WidgetPreview';

export type CanvasItemProps = {
  index: number;
  isExpanded?: boolean;
  widget: IncompleteWidgetInstance;
  onReorder: (dragIndex: number, hoverIndex: number) => void;
  onExpand: (id: string) => void;
  onCollapse: () => void;
  onSave: (
    id: string,
    properties: WidgetProperties,
    validators?: WidgetValidationItem[]
  ) => void;
  onDelete: (id: string) => void;
};

export const CanvasItem = ({
  index,
  isExpanded,
  widget,
  onReorder,
  onExpand,
  onCollapse,
  onSave,
  onDelete,
}: CanvasItemProps) => {
  const [temporaryProperties, setTemporaryProperties] = useState(
    widget.properties
  );
  const [temporaryValidators, setTemporaryValidators] = useState(
    widget.validation
  );

  const [isHovering, setIsHovering] = useState(false);
  const [isClickHolding, setIsClickHolding] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleUpdateItem: OnUpdateFn = (key, value) => {
    setTemporaryProperties((properties) => ({
      ...properties,
      [key]: value,
    }));
  };

  const handleUpdateValidators = (validators: WidgetValidationItem[]) => {
    setTemporaryValidators(validators);
  };

  const handleMouseIn = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    if (isDragging) return;
    setIsHovering(false);
  };

  const handleSave = () => {
    onSave(widget.id, temporaryProperties, temporaryValidators);
  };

  const [_, drop] = useDrop<IncompleteWidgetInstance>({
    accept: 'reorder',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onReorder(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'reorder',
    item: () => {
      return { id: widget.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setIsClickHolding(false);
    },
  });

  drag(drop(ref));

  return (
    <div
      className={cn(
        'flex flex-col p-4',
        'rounded-lg border border-dashed border-transparent',
        {
          'opacity-0': isDragging,
          'border-color-green/70': isExpanded,
        }
      )}
      onMouseEnter={handleMouseIn}
      onMouseLeave={handleMouseOut}
    >
      <div ref={preview} className="relative">
        <div
          className={cn(
            'absolute -left-10 top-2',
            'flex h-full w-5',
            'opacity-0',
            'cursor-grab transition-opacity duration-300',
            {
              'opacity-100': isHovering && !isExpanded,
              'cursor-grabbing': isClickHolding,
            }
          )}
          ref={ref}
          onMouseDown={() => setIsClickHolding(true)}
          onMouseUp={() => setIsClickHolding(false)}
        >
          <Equal className="h-4 w-4" />
        </div>
        <WidgetPreview
          properties={temporaryProperties}
          type={widget.type}
          onClick={() => onExpand(widget.id)}
        />
      </div>

      <CanvasItemSettings
        isExpanded={isExpanded}
        properties={temporaryProperties}
        validators={temporaryValidators}
        type={widget.type}
        originalWidget={widget}
        onClose={onCollapse}
        onUpdate={handleUpdateItem}
        onSave={handleSave}
        onDelete={() => onDelete(widget.id)}
        onUpdateValidators={handleUpdateValidators}
      />
    </div>
  );
};
