import { useEffect, useState } from 'react';

import { cn } from '@spektr/client/utils';
import { ProcessType } from '@spektr/shared/validators';

import { TextTruncate } from '../TextTruncate';
import { Input } from '../forms';

export interface ProcessNameProps {
  name?: string;
  processType?: ProcessType;
  onNameUpdate?: (name: string) => void;
}

const processLabels = {
  risk: 'Risk process',
  score: 'Customer Score process',
  monitoring: 'Monitoring process',
  loop: 'Loop',
  onboarding: 'Onboarding process',
  custom: 'Custom process',
};

export function ProcessName({
  name = '',
  processType,
  onNameUpdate,
}: ProcessNameProps) {
  const [processName, updateProcessName] = useState(name);
  const isNameEditable = !!onNameUpdate;

  const handleNameUpdate = () => {
    if (!processName.trim() || processName === name) {
      return updateProcessName(name);
    }

    onNameUpdate?.(processName);
  };

  useEffect(() => {
    updateProcessName(name);
  }, [name]);

  if (name) {
    return (
      <div className="mr-4 flex w-full items-center gap-1 text-sm">
        {processType && (
          <span className="text-color-text-subtext shrink-0 font-semibold">{`${processLabels[processType]}: `}</span>
        )}
        {isNameEditable ? (
          <Input
            className={cn(
              'flex-1 px-2 py-0',
              'border-color-border-input max-w-sm text-ellipsis border',
              'hover:border-color-border-input--hover focus:border-color-border-input--focus active:border-color-border-input--active'
            )}
            value={processName}
            onBlur={handleNameUpdate}
            onChange={(e) => updateProcessName(e.target.value)}
            data-cy="process-name-input"
          />
        ) : (
          <TextTruncate className="line-clamp-1" text={name} />
        )}
      </div>
    );
  }

  return null;
}
