import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { useParsedParams } from '@spektr/shared/hooks';
import { processDetailsParamsSchema } from '@spektr/shared/utils';

import {
  LOOPS_KEYS,
  LoopApiClient,
  getLoopByIdQuery,
} from '@spektr/client/services';
import { ProcessName, toast } from '@spektr/client/components';
import { invalidateMultipleQueries } from '@spektr/client/utils';

export const LoopProcessNameWrapper = () => {
  const queryClient = useQueryClient();
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const { data: loop } = useSuspenseQuery(getLoopByIdQuery(processId));

  const updateLoop = useMutation({
    mutationFn: (name: string) =>
      LoopApiClient.getClient().updateLoopById(
        {
          name,
        },
        {
          params: {
            id: processId,
          },
        }
      ),
    onSuccess: async () => {
      await invalidateMultipleQueries(queryClient, [
        {
          queryKey: LOOPS_KEYS.DETAILS(processId),
        },
        {
          queryKey: LOOPS_KEYS.ALL(),
        },
      ]);

      toast.success({
        title: 'Successfully updated',
        description: 'The loop name was updated.',
      });
    },
  });

  return (
    <ProcessName
      name={loop.name}
      processType={loop.type}
      onNameUpdate={updateLoop.mutateAsync}
    />
  );
};
