import { useNavigate } from 'react-router-dom';

import { useParsedParams } from '@spektr/shared/hooks';
import {
  processBuilderUrl,
  processNodeParamsSchema,
} from '@spektr/shared/utils';

import { NodeDetailsPreloader } from '../../../components';

import { NodeDetails } from './NodeDetails';

export const NodeDialog = () => {
  const navigate = useNavigate();
  const { processId } = useParsedParams(processNodeParamsSchema);

  const handleClose = () => {
    navigate(processBuilderUrl(processId));
  };

  return (
    <NodeDetailsPreloader onClose={handleClose}>
      <NodeDetails onClose={handleClose} />
    </NodeDetailsPreloader>
  );
};
