import { useMemo } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useMeasure } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { Primitive } from 'utility-types';

import {
  ACTIONS_KEYS,
  DATASETS_KEYS,
  ActionsApiClient,
  connectionDataSetByIdQuery,
  getActionById,
  getExecutionById,
  getNodeByIdQuery,
  getProcessByIdQuery,
  getTeamMembersQuery,
} from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';

import { insightsActionsUrl } from '@spektr/shared/utils';

import { ScrollArea } from '@spektr/client/components';

import { getIdentifierValue } from '../../utils';

import { SidebarHeader } from '../../components/SidebarHeader';
import { ActionDetailsTable } from '../../components/ActionDetailsTable';
import { ActionRecordTable } from '../../components/ActionRecordTable';
import { ActionActivityLog } from '../../components/ActionActivityLog';

import { useAggregateRecordMapping } from '../../hooks/useAggregateRecordMapping';

export type ActionPageSidebarProps = {
  actionId: string;
};

export const ActionPageSidebar = ({ actionId }: ActionPageSidebarProps) => {
  const navigate = useNavigate();
  const [ref, size] = useMeasure<HTMLDivElement>();

  const queryClient = useQueryClient();
  const { data: action } = useSuspenseQuery(getActionById(actionId));
  const { data: process } = useSuspenseQuery(
    getProcessByIdQuery(action.processId)
  );
  const { data: node } = useSuspenseQuery(
    getNodeByIdQuery(action.processId, action.nodeId)
  );
  const { data: dataset } = useSuspenseQuery(
    connectionDataSetByIdQuery(action.datasetId)
  );
  const { data: execution } = useSuspenseQuery(
    getExecutionById(action.contextId)
  );
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());

  const mapping = useAggregateRecordMapping(actionId);

  const updateActionMutation = useMutation({
    mutationFn: async (reason?: string) => {
      await ActionsApiClient.getClient().updateActionById(
        {
          status: 'resolved',
          reason: reason,
        },
        {
          params: { actionId },
        }
      );

      await invalidateMultipleQueries(queryClient, [
        { queryKey: ACTIONS_KEYS.ALL() },
        { queryKey: ACTIONS_KEYS.BY_ID(actionId) },
        { queryKey: DATASETS_KEYS.ALL_ACTIONS(dataset.id, action.spektrId) },
      ]);
    },
  });

  const handleClickResolve = async (reason?: string) => {
    await updateActionMutation.mutateAsync(reason);
    navigate(insightsActionsUrl());
  };

  const data = useMemo(() => {
    if (!node) {
      return {};
    }

    return Object.entries(mapping).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: execution.data[value],
      }),
      {} as Record<string, Primitive>
    );
  }, [node, mapping, execution.data]);

  return (
    <div ref={ref} className="relative h-full w-full">
      <ScrollArea
        style={{
          height: size.height,
        }}
      >
        <div className="flex flex-col gap-6 p-6">
          <SidebarHeader
            identifier={getIdentifierValue(execution.data, dataset)}
            isResolveDisabled={
              updateActionMutation.isPending || action.status !== 'pending'
            }
            onResolve={handleClickResolve}
          />
          <ActionRecordTable data={data} />
          <ActionDetailsTable
            action={action}
            dataset={dataset}
            process={process}
          />
          <ActionActivityLog action={action} users={users} />
        </div>
      </ScrollArea>
    </div>
  );
};
