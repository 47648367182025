import { useMemo } from 'react';
import ReactFlow, {
  Node,
  Edge,
  useEdgesState,
  useNodesState,
  Background,
} from 'reactflow';

import { GraphControls } from '@spektr/client/components';

import { useGraphLayout } from '../hooks';
import { useStrategyProviderContext } from '../providers';

import {
  RootNode,
  BasicNode,
  UnlinkedOutcomeNode,
  EdgeToSegment,
  EdgeLeaf,
} from './components';

const nodeTypes = {
  rootNode: RootNode,
  basicNode: BasicNode,
  unlinkedOutcomeNode: UnlinkedOutcomeNode,
};

const edgeTypes = {
  default: EdgeLeaf,
  edgeSegment: EdgeToSegment,
};

type StrategyTreeProps = {
  initialNodes: Node[];
  initialEdges: Edge[];
  width: number;
  height: number;
};

export const StrategyTree = ({
  initialNodes,
  initialEdges,
  width,
  height,
}: StrategyTreeProps) => {
  const { selectedNode, setSelectedNode, activeView, isStrategyPage } =
    useStrategyProviderContext();
  const [nodes, _setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, _setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useGraphLayout(selectedNode);

  const defaultViewport = useMemo(
    () => ({
      zoom: 0.5,
      x: width,
      y: height,
    }),
    [width, height]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      proOptions={{ account: 'paid-pro', hideAttribution: true }}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      defaultViewport={defaultViewport}
      minZoom={0}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      deleteKeyCode={null}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView={activeView === 'path' || isStrategyPage}
      onSelectionChange={({ nodes }) => {
        if (nodes?.[0]?.id) {
          setSelectedNode(nodes?.[0]?.id);
        }
      }}
    >
      <Background
        id="strategy-background"
        className="dark:bg-color-bg-primary bg-transparent"
        gap={30}
      />
      <GraphControls />
    </ReactFlow>
  );
};
