import { useWatch } from 'react-hook-form';

import { isSupportedDataType } from '@spektr/shared/utils';
import { RBAC } from '@spektr/shared/rbac';

import { Button } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { TableFields } from '../../validators/tableFieldsSchema';

export type FooterProps = {
  isEditable?: boolean;
};

export const Footer = ({ isEditable = false }: FooterProps) => {
  const fieldId: string = useWatch({ name: 'fieldId' });
  const fields: TableFields = useWatch({ name: 'fields' });
  const { hasPermission } = usePermissionsContext();

  const includedFieldsWithInvalidType = Object.values(fields).some((field) => {
    if (field.isIncluded) {
      return !isSupportedDataType(field.type);
    }

    return false;
  });

  return (
    <Button
      className="ml-auto mt-4"
      disabled={
        !isEditable ||
        !fieldId ||
        includedFieldsWithInvalidType ||
        !hasPermission(RBAC.ACTIONS.DATASET.UPDATE)
      }
      color="yellow"
      type="submit"
      data-cy="save-dataset"
    >
      Save dataset
    </Button>
  );
};
