import { useTranslation } from 'react-i18next';

export const EmptyList = () => {
  const { t } = useTranslation('moonraker-form');
  return (
    <div className="my-5 flex h-full items-center justify-center">
      <p className="text-color-text-subtext text-center text-sm font-medium">
        {t('noResults')}
      </p>
    </div>
  );
};
