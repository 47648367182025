import { useQuery } from '@tanstack/react-query';
import { Search } from 'lucide-react';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import { BasicDialog } from '@spektr/client/components';

import {
  NodeUpdateInput,
  ServiceNode as ServiceNodeType,
  bodaccFields,
  complyAdvantageKybFields,
  complyAdvantageKycFields,
  kyckrFields,
  mitIdFields,
  openCorporatesMonitoringFields,
  openCorporatesRiskFields,
  veriffFields,
} from '@spektr/shared/types';

import { mapSpektrFieldToServiceField } from '../../utils/mapSpektrFieldToServiceField';

import { ServiceFieldsWithCheckbox } from '../ServiceFieldsWithCheckbox';

export type RiskServiceNodeProps = {
  processId: string;
  node: ServiceNodeType;
  onClose: () => void;
  onUpdate: (data: NodeUpdateInput) => void;
};

export const RiskServiceNode = ({
  processId,
  node,
  onClose,
  onUpdate,
}: RiskServiceNodeProps) => {
  const formInstance = useForm<Record<string, unknown>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });
  const { data: spektrFields } = useQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );

  const fields = useMemo(() => {
    switch (node.nodeType) {
      case 'complyAdvantageKyb':
        return mapSpektrFieldToServiceField(node, complyAdvantageKybFields);
      case 'complyAdvantageKyc':
        return mapSpektrFieldToServiceField(node, complyAdvantageKycFields);
      case 'openCorporatesMonitoring':
        return mapSpektrFieldToServiceField(
          node,
          openCorporatesMonitoringFields
        );
      case 'openCorporatesRisk':
        return mapSpektrFieldToServiceField(node, openCorporatesRiskFields);
      case 'bodacc':
        return mapSpektrFieldToServiceField(node, bodaccFields);
      case 'kyckr':
        return mapSpektrFieldToServiceField(node, kyckrFields);
      case 'veriff':
        return mapSpektrFieldToServiceField(node, veriffFields);
      case 'mitId':
        return mapSpektrFieldToServiceField(node, mitIdFields);
    }
  }, [node]);

  const getNodeDescription = () => {
    switch (node.nodeType) {
      case 'complyAdvantageKyb':
        return 'Check for AML hits, sanctions and adverse media on your corporate customers.';
      case 'complyAdvantageKyc':
        return 'Check for AML hits, sanctions, adverse media and PEP on your individual customers.';
      case 'openCorporatesRisk':
        return 'Verify a company’s information with OpenCorporate. Map your fields here and build rules and logic on the OpenCorporate response in downstream Calculation or Routing step(s).';
      case 'bodacc':
        return 'Check for changes in the legal status of a company, such as bankruptcy or liquidation.';
      case 'kyckr':
        return 'Use Kyckr to get some information about owners and representatives of your corporate customers. Note that Kyckr propose a service to discover UBOs even if they are indirect owners.';
      case 'veriff':
        return 'Perform identity verification and fraud prevention checks on individuals with Veriff.';
      case 'mitId':
        return "Verify individuals' identity from Denmark with MitID.";
      default:
        return '';
    }
  };

  return (
    <BasicDialog
      className="max-w-[700px]"
      defaultOpen
      title={node.title}
      description={getNodeDescription()}
      icon={<Search className="text-color-text-icon-primary mr-3 h-5 w-5" />}
      onClose={onClose}
    >
      <FormProvider {...formInstance}>
        <ServiceFieldsWithCheckbox
          key={spektrFields?.length}
          spektrFields={spektrFields ?? []}
          fields={fields}
          node={node}
          onSave={onUpdate}
        />
      </FormProvider>
    </BasicDialog>
  );
};
