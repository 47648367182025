import _flow from 'lodash/flow';

import {
  Action,
  ExecutionContextWithDatasetSchema,
} from '@spektr/shared/validators';

import { Process } from '@spektr/shared/types';

import { ActionTableRowData } from '../types/ActionTableRowData';

export function aggregatedActionData(
  actions: Action[],
  executionContexts: ExecutionContextWithDatasetSchema[],
  processes: Process[]
): ActionTableRowData[] {
  return actions
    .map((action) => {
      const currentProcess = processes.find(
        (process) => process.id === action.processId
      );
      const currentExecution = executionContexts.find(
        (execution) => execution.id === action.contextId
      );

      const baseOutput: Partial<ActionTableRowData> = {
        id: action.id,
        createdAt: action.createdAt,
        hitsCount: action.hits.length,
        resolvedHitsCount: 0,
      };

      const setResolvedHits = (data: Partial<ActionTableRowData>) => {
        return {
          ...data,
          resolvedHitsCount: action.hits.reduce((acc, hit) => {
            if (hit.decision.status !== 'pending') {
              return acc + 1;
            }
            return acc;
          }, 0),
        };
      };

      const setSubjectName = (data: Partial<ActionTableRowData>) => {
        if (!currentExecution) return data;

        const identifier = currentExecution.dataset?.fieldId;
        let recordName: string | undefined;

        if (identifier && currentExecution?.data[identifier] !== null) {
          recordName = currentExecution.data[identifier]!.toString();
        }

        return { ...data, recordName };
      };

      const setProcess = (data: Partial<ActionTableRowData>) => {
        if (!currentProcess) return data;

        return {
          ...data,
          processName: currentProcess.name,
          processType: currentProcess.type,
        };
      };

      const setSource = (data: Partial<ActionTableRowData>) => {
        if (!currentProcess) return data;

        const processNode = currentProcess.nodes.get(action.nodeId);

        return {
          ...data,
          sourceName: (processNode as any)?.title,
          sourceNodeType: processNode?.nodeType,
        };
      };

      return _flow(
        setSubjectName,
        setProcess,
        setSource,
        setResolvedHits
      )(baseOutput);
    })
    .filter((action) => action !== null) as ActionTableRowData[];
}
