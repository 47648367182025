import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  connectionDataSetByIdQuery,
  getActionById,
  getExecutionById,
} from '@spektr/client/services';

import { useActionIdParam } from '../../hooks/useActionIdParam';
import { ActionDetailsHeader } from '../../components/ActionDetailsHeader';
import { getIdentifierValue, computeHitCategories } from '../../utils';

export const ActionPageHeader = () => {
  const actionId = useActionIdParam();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: action } = useSuspenseQuery(getActionById(actionId));
  const { data: dataset } = useSuspenseQuery(
    connectionDataSetByIdQuery(action.datasetId)
  );
  const { data: execution } = useSuspenseQuery(
    getExecutionById(action.contextId)
  );

  const categories = useMemo(() => computeHitCategories(action), [action]);

  useEffect(() => {
    const service = searchParams.get('service');

    if (!service) {
      if (categories.length > 0) {
        const searchParams = new URLSearchParams();
        searchParams.set('service', categories[0].service);
        categories[0]?.field && searchParams.set('field', categories[0].field);

        setSearchParams(searchParams);
      }
    }
  }, [categories, searchParams, setSearchParams]);

  return (
    <ActionDetailsHeader
      subject={getIdentifierValue(execution.data, dataset)}
      categories={categories}
    />
  );
};
