import { CircleX } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { insightsActionsUrl } from '@spektr/shared/utils';

import { cn } from '@spektr/client/utils';

import { Button, iconBoxVariants } from '@spektr/client/components';

export const ActionDetailsError = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('insights');

  return (
    <div
      className={cn(
        'absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2 transform',
        'flex flex-col items-center gap-6'
      )}
    >
      <CircleX
        className={cn(iconBoxVariants({ variant: 'red' }), 'h-12 w-12 p-3')}
      />
      <div className="flex flex-col gap-2 text-center">
        <p className="font-semibold">
          {t('actions.details.errors.generic.title')}
        </p>
        <p className="text-color-text-subtext text-sm">
          {t('actions.details.errors.generic.description')}
          <br />
          {t('tryAgain', {
            email: 'support@spektr.com',
          })}
        </p>
      </div>
      <Button size="sm" onClick={() => navigate(insightsActionsUrl())}>
        {t('backToActionsList')}
      </Button>
    </div>
  );
};
