import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { cn } from '@spektr/client/utils';

export type DialogTitleProps = ComponentPropsWithoutRef<
  typeof DialogPrimitive.Title
>;

export const DialogTitle = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  DialogTitleProps
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      'flex items-center text-base font-semibold leading-none tracking-tight',
      className
    )}
    {...props}
  />
));
