import { createConfig } from '../utils/creators';

import type { Widget } from '../types';

export const DEFAULT_WIDGETS: Widget[] = [
  {
    id: 'vatNumber',
    category: 'companyInformation',
    title: 'fields.vatNumber',
    icon: 'Binary',
    component: {
      id: 'vatNumber',
      type: 'input',
      config: createConfig('vat_number'),
      attributes: {
        label: 'VAT Number',
        type: 'text',
        placeholder: 'IE1234567T',
      },
      validation: [],
    },
  },
  {
    id: 'companyNumber',
    category: 'companyInformation',
    title: 'fields.companyNumber',
    icon: 'Binary',
    component: {
      id: 'companyNumber',
      type: 'input',
      config: createConfig('company_number'),
      attributes: {
        label: 'Company Number',
        type: 'company_number',
        placeholder: 'IE1234567T',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Company number is required',
        },
      ],
    },
  },
  {
    id: 'countryCode',
    category: 'companyInformation',
    title: 'fields.countryCode',
    icon: 'TextCursorInput',
    component: {
      id: 'countryCode',
      type: 'input',
      config: createConfig('country_code'),
      attributes: {
        label: 'Country Code',
        type: 'text',
        placeholder: 'dk',
      },
      validation: [],
    },
  },
  {
    id: 'incorporationDate',
    category: 'companyInformation',
    title: 'fields.incorporationDate',
    icon: 'CalendarPlus2',
    component: {
      id: 'incorporationDate',
      type: 'input',
      config: createConfig('incorporation_date'),
      attributes: {
        label: 'Incorporation Date',
        type: 'date',
        placeholder: '01/01/2000',
      },
      validation: [],
    },
  },
  {
    id: 'naceCode',
    category: 'companyInformation',
    title: 'fields.naceCode',
    icon: 'List',
    component: {
      id: 'naceCode',
      type: 'select',
      config: createConfig('nace_code'),
      attributes: {
        label: 'NACE code',
        placeholder: 'Select NACE code',
        options: [], // these will be overwritten lazily by createFieldFromWidget
      },
      validation: [],
    },
  },
  {
    id: 'firstName',
    category: 'pii',
    title: 'fields.firstName',
    icon: 'TextCursorInput',
    component: {
      id: 'firstName',
      type: 'input',
      config: createConfig('first_name'),
      attributes: {
        label: 'First Name',
        type: 'text',
        placeholder: 'John',
      },
      validation: [],
    },
  },
  {
    id: 'middleName',
    category: 'pii',
    title: 'fields.middleName',
    icon: 'TextCursorInput',
    component: {
      id: 'middleName',
      type: 'input',
      config: createConfig('middle_name'),
      attributes: {
        label: 'Middle name',
        type: 'text',
        placeholder: 'Middle',
      },
      validation: [],
    },
  },
  {
    id: 'lastName',
    category: 'pii',
    title: 'fields.lastName',
    icon: 'TextCursorInput',
    component: {
      id: 'lastName',
      type: 'input',
      config: createConfig('last_name'),
      attributes: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Doe',
      },
      validation: [],
    },
  },
  {
    id: 'fullName',
    category: 'pii',
    title: 'fields.fullName',
    icon: 'TextCursorInput',
    component: {
      id: 'fullName',
      type: 'input',
      config: createConfig('full_name'),
      attributes: {
        label: 'Full name',
        type: 'text',
        placeholder: 'John Doe',
      },
      validation: [],
    },
  },
  {
    id: 'companyName',
    category: 'companyInformation',
    title: 'fields.companyName',
    icon: 'TextCursorInput',
    component: {
      id: 'companyName',
      type: 'input',
      config: createConfig('company_name'),
      attributes: {
        label: 'Company name',
        type: 'text',
        placeholder: 'Acme Inc.',
      },
      validation: [],
    },
  },
  {
    id: 'dateOfBirth',
    category: 'pii',
    title: 'fields.dateOfBirth',
    icon: 'CalendarPlus2',
    component: {
      id: 'dateOfBirth',
      type: 'input',
      config: createConfig('date_of_birth'),
      attributes: {
        label: 'Date Of Birth',
        type: 'date',
        placeholder: '01/01/2000',
      },
      validation: [],
    },
  },
  {
    id: 'country',
    category: 'addresses',
    title: 'fields.country',
    icon: 'MapPinned',
    component: {
      id: 'country',
      type: 'input',
      config: createConfig('country'),
      // TODO: (Robert) - transform this to dropdown after ST-1782
      attributes: {
        label: 'Country',
        type: 'text',
        placeholder: 'United States',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Country is required',
        },
      ],
    },
  },
  {
    id: 'state',
    category: 'addresses',
    title: 'fields.state',
    icon: 'MapPinned',
    component: {
      id: 'state',
      type: 'input',
      config: createConfig('state'),
      attributes: {
        label: 'State',
        type: 'text',
        placeholder: 'California',
      },
      validation: [],
    },
  },
  {
    id: 'region',
    category: 'addresses',
    title: 'fields.region',
    icon: 'MapPinned',
    component: {
      id: 'region',
      type: 'input',
      config: createConfig('region'),
      attributes: {
        label: 'Region',
        type: 'text',
        placeholder: 'Bay Area',
      },
      validation: [],
    },
  },
  {
    id: 'city',
    category: 'addresses',
    title: 'fields.city',
    icon: 'MapPinned',
    component: {
      id: 'city',
      type: 'input',
      config: createConfig('city'),
      attributes: {
        label: 'City',
        type: 'text',
        placeholder: 'San Francisco',
      },
      validation: [],
    },
  },
  {
    id: 'street',
    category: 'addresses',
    title: 'fields.street',
    icon: 'MapPinned',
    component: {
      id: 'street',
      type: 'input',
      config: createConfig('street'),
      attributes: {
        label: 'Street',
        type: 'text',
        placeholder: '123 Main St',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Street is required',
        },
      ],
    },
  },
  {
    id: 'zipCode',
    category: 'addresses',
    title: 'fields.zipCode',
    icon: 'MapPinned',
    component: {
      id: 'zipCode',
      type: 'input',
      config: createConfig('zip_code'),
      attributes: {
        label: 'Postal Code',
        type: 'number',
        placeholder: '12345',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Zip code is required',
        },
      ],
    },
  },
  {
    id: 'floor',
    category: 'addresses',
    title: 'fields.floor',
    icon: 'MapPinned',
    component: {
      id: 'floor',
      type: 'input',
      config: createConfig('floor'),
      attributes: {
        label: 'Floor / Unit',
        type: 'text',
        placeholder: '3rd',
      },
      validation: [],
    },
  },
  {
    id: 'fullLegalName',
    category: 'companyInformation',
    title: 'fields.fullLegalName',
    icon: 'TextCursorInput',
    component: {
      id: 'fullLegalName',
      type: 'input',
      config: createConfig('fullLegalName'),
      attributes: {
        label: 'Full Legal Name',
        type: 'text',
        placeholder: 'Acme Inc.',
      },
      validation: [],
    },
  },
];
