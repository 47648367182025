import { produce } from 'immer';

import {
  isEntitiesField,
  isParagraphField,
  isReadOnlyField,
  isTitleField,
  type Field,
} from '@spektr/moonraker-types';

import { useFormEngine } from '../../../../hooks/useFormEngine';

import { FormField, TextareaField } from '../fields';

export type ReadOnlyContentProps = {
  currentField: Field;
};

export const ReadOnlyContent = ({ currentField }: ReadOnlyContentProps) => {
  const { state, updateField } = useFormEngine();

  const handleUpdateField = (name: string, value: string) => {
    const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];
    let fieldToBeUpdated = currentField;

    if (fieldId) {
      fieldToBeUpdated = state.fields[rootFieldId];
    }

    if (!fieldToBeUpdated) {
      return;
    }

    const updatedField = produce(fieldToBeUpdated, (draft) => {
      if (isEntitiesField(draft)) {
        const field = draft.form.fields[fieldId];
        if (!field) {
          return;
        }

        if (isReadOnlyField(field)) {
          if (field.type === 'divider') {
            return;
          }

          if (name === 'content') {
            field.attributes['content'] = value;
          }
          return;
        }
      }

      if (isReadOnlyField(draft)) {
        if (draft.type === 'divider') {
          return;
        }

        if (name === 'content') {
          draft.attributes['content'] = value;
        }
      }
    });
    updateField(updatedField);
  };

  if (!isReadOnlyField(currentField)) {
    return null;
  }

  if (isTitleField(currentField)) {
    return (
      <FormField
        value={currentField.attributes.content}
        label="Content"
        name="content"
        placeholder="Enter a title for the field"
        onChange={(ev) => handleUpdateField('content', ev.target.value)}
      />
    );
  }

  if (isParagraphField(currentField)) {
    return (
      <TextareaField
        value={currentField.attributes.content}
        label="Content"
        name="content"
        rows={5}
        placeholder="Enter a paragraph for the field"
        onChange={(ev) => handleUpdateField('content', ev.target.value)}
      />
    );
  }

  return null;
};
