import { ProcessType } from '../process';
import { NodeType } from '../node/node';

export const NODE_ACCESS_MATRIX: Readonly<
  Record<NodeType, Array<ProcessType>>
> = {
  action: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  actionForm: ['loop', 'onboarding'],
  bodacc: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  calculation: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  complyAdvantageKyb: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  complyAdvantageKyc: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  filter: ['risk', 'monitoring', 'score'],
  kyckr: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  loopSource: ['loop'],
  onboardingProcessSource: ['onboarding'],
  mitId: ['loop', 'onboarding'],
  monitoringDataset: ['risk', 'monitoring', 'score'],
  openCorporatesMonitoring: ['monitoring'],
  openCorporatesRisk: ['risk', 'loop', 'score', 'onboarding'],
  outcome: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  returningProcess: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  router: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  slack: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  veriff: ['loop', 'onboarding'],
  manualReview: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  aiActionConfig: ['loop'],
};
