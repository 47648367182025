import { TopologyProcess, TopologyProcessOutcome } from '@spektr/shared/types';

import {
  GraphEdgeType,
  GraphNodeType,
  UnlinkedOutcomeGraphNode,
} from '../types';

const createGraphNode = <T>(id: string, type: GraphNodeType, data: T) => ({
  id,
  type,
  data,
  position: { x: 0, y: 0 },
});

export const createSourceGraphNode = () =>
  createGraphNode('rootId', 'rootNode', {});

export const createBasicGraphNode = (data: TopologyProcess) =>
  createGraphNode(data.id, 'basicNode', data);

export const createUnlinkedOutcomeGraphNode = (
  data: UnlinkedOutcomeGraphNode
) => createGraphNode(data.id, 'unlinkedOutcomeNode', data);

export const createGraphEdge = <T>(
  type: GraphEdgeType,
  source: string,
  target: string,
  data?: T
) => ({
  id: `edge-${source}-${target}`,
  className: 'strategy-lines',
  source,
  target,
  type,
  animated: true,
  data,
});

export const createLeafEdge = (target: string) =>
  createGraphEdge('default', 'rootId', target);

export const createSegmentEdge = (
  source: string,
  target: string,
  data: TopologyProcessOutcome
) =>
  createGraphEdge<TopologyProcessOutcome>('edgeSegment', source, target, data);
