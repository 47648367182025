import * as React from 'react';

import { cn } from '@spektr/client/utils';

export type PaginationWrapperProps = React.ComponentProps<'nav'>;

export const PaginationWrapper = ({
  className,
  ...props
}: PaginationWrapperProps) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
);
