import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import {
  Label,
  RichTextEditor,
  Select,
  SpektrfieldCombobox,
} from '@spektr/client/components';

import { SpektrField } from '@spektr/shared/types';
import { ApiKeySchema } from '@spektr/shared/validators';
import { SETTINGS_DEVELOPER_FULL_URL } from '@spektr/shared/utils';

const channelTypes = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'sms',
    label: 'SMS',
    disabled: true,
  },
  {
    value: 'api',
    label: 'API',
    disabled: true,
  },
];

type LoopChannelSettingsProps = {
  spektrFields: SpektrField[];
  apiKeys: ApiKeySchema[];
};

export const LoopChannelSettings = ({
  spektrFields,
  apiKeys,
}: LoopChannelSettingsProps) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const channelType = watch('type');
  const isApi = channelType === 'api';

  return (
    <form className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-2">
          <Label>Type</Label>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Select
                required
                {...field}
                defaultValue={'email'}
                placeholder="Select from dropdown..."
                options={channelTypes}
                onValueChange={field.onChange}
              >
                {channelTypes.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
        </div>
        {!isApi && (
          <div className="flex flex-col gap-2">
            <Label>Recipient Field</Label>
            <Controller
              name="recipientField"
              render={({ field }) => (
                <SpektrfieldCombobox
                  fields={spektrFields}
                  defaultValue={field.value ?? ''}
                  inputProps={{
                    dimension: 'default',
                    required: true,
                    placeholder: 'Select from dropdown...',
                    error: !!errors?.recipientField?.message,
                  }}
                  onChange={(value) => {
                    const isSpektrField = spektrFields.some(
                      (field) => field.key === value
                    );

                    field.onChange(isSpektrField ? value : '');
                  }}
                />
              )}
              control={control}
            />
          </div>
        )}
      </div>
      {!isApi && (
        <div className="flex flex-col gap-2">
          <Label>Message Body</Label>
          <Controller
            name="messageBody"
            render={({ field }) => (
              <RichTextEditor
                formats={
                  channelType === 'email' ? ['bold', 'italic', 'underline'] : []
                }
                floatingToolbar={false}
                className="h-40"
                placeholder="Please enter the content of your message"
                initialValue={field.value}
                mentionOptions={spektrFields.map((field) => field.key)}
                onChange={field.onChange}
              />
            )}
            control={control}
          />
        </div>
      )}
      {isApi &&
        (apiKeys.length > 0 ? (
          <div className="flex flex-col gap-2">
            <Label>API Key</Label>
            <Controller
              name="apiKey"
              render={({ field }) => (
                <Select
                  required
                  {...field}
                  placeholder="Select from dropdown..."
                  options={apiKeys.map((key) => ({
                    value: key.id,
                    label: key.name,
                  }))}
                  onValueChange={field.onChange}
                />
              )}
              control={control}
            />
          </div>
        ) : (
          <p className="text-sm">
            You don't have any API keys. Please create one in{' '}
            <Link
              className="text-color-text-link-secondary hover:text-color-text-link-secondary/80"
              to={SETTINGS_DEVELOPER_FULL_URL}
            >
              Developer settings
            </Link>
          </p>
        ))}
    </form>
  );
};
