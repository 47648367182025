import { Predicate } from '@spektr/shared/types';
import { SpektrData } from '@spektr/shared/validators';

import { parseRange } from '../parse-range';
import { parseCountry } from '../country';

export const executePredicate = (
  predicate: Predicate,
  substitutions: SpektrData
): boolean => {
  if (predicate.operator === 'and') {
    const leftEvaluated = executePredicate(predicate.left, substitutions);
    const rightEvaluated = executePredicate(predicate.right, substitutions);
    return leftEvaluated && rightEvaluated;
  }

  if (predicate.operator === 'or') {
    const leftEvaluated = executePredicate(predicate.left, substitutions);
    const rightEvaluated = executePredicate(predicate.right, substitutions);
    return leftEvaluated || rightEvaluated;
  }

  if (typeof predicate.left !== 'string')
    throw new RuleExecutionError(
      `Rule Execution failed. Left operand of leaf rule must be of type string but received left operand '${predicate.left}' of type '${typeof predicate.left}'`
    );

  const substituted = substitutions[predicate.left];
  if (substituted === undefined) return false;

  if (
    predicate.type === 'date' &&
    predicate.operator !== 'is_empty' &&
    predicate.operator !== 'is_not_empty' &&
    (typeof predicate.right === 'number' || typeof predicate.right === 'string')
  ) {
    const spektrFieldDate = dateCompareAgainst(Number(substituted));

    if (predicate.operator === 'is_after') {
      return spektrFieldDate.isAfterDate(predicate.right);
    }

    if (predicate.operator === 'is_before') {
      return spektrFieldDate.isBeforeDate(predicate.right);
    }

    if (predicate.operator === 'equals') {
      return spektrFieldDate.isSameDate(predicate.right);
    }

    if (predicate.operator === 'not_equals') {
      return !spektrFieldDate.isSameDate(predicate.right);
    }
  }

  if (predicate.type === 'country' && typeof predicate.right === 'string') {
    // Compare substituted left side against right-side. Both sides can be either
    // - full country name (e.g Denmark)
    // - iso2: (e.g DK)
    // - iso3: (e.g DNK)
    //right side of a country rule can be a plain country name, a country list or a risk matrix.

    const right = parseCountry(predicate.right);
    const left = parseCountry(String(substituted));

    if (left && right) {
      const match = left.isoAlpha2 === right.isoAlpha2;
      if (predicate.operator === 'equals') return match;
      if (predicate.operator === 'not_equals') return !match;
    }
  }

  if (predicate.operator === 'equals') {
    return substituted == predicate.right && !Number.isNaN(substituted);
  }

  if (predicate.operator === 'not_equals') {
    return substituted != predicate.right && !Number.isNaN(substituted);
  }

  if (predicate.operator === 'is_empty') {
    return substituted === null;
  }

  if (predicate.operator === 'is_not_empty') {
    return substituted !== null;
  }

  if (predicate.operator === 'less_than') {
    const converted = Number(substituted);
    return converted < predicate.right && !Number.isNaN(converted);
  }

  if (predicate.operator === 'greater_than') {
    const converted = Number(substituted);
    return converted > predicate.right && !Number.isNaN(converted);
  }

  if (predicate.operator === 'between') {
    const [min, max] = parseRange(predicate.right);

    return (
      min !== '' &&
      max !== '' &&
      Number(substituted) >= Number(min) &&
      Number(substituted) <= Number(max)
    );
  }

  if (predicate.operator === 'outside') {
    const [min, max] = parseRange(predicate.right);

    return (
      min !== '' &&
      max !== '' &&
      (Number(substituted) < Number(min) || Number(substituted) > Number(max))
    );
  }

  if (predicate.type === 'matrix') {
    const matrix = predicate.right;
    assertIsMatrix(matrix);

    const match = matrix.some((row) =>
      row.some((cell) => cell.trim() === substituted)
    );

    if (predicate.operator === 'is_in') return match;
    if (predicate.operator === 'is_not_in') return !match;
  }

  throw new RuleExecutionError(
    `Rule Execution failed for predicate ${JSON.stringify(predicate)}}.`
  );
};

function assertIsMatrix(matrix: unknown): asserts matrix is string[][] {
  if (!Array.isArray(matrix))
    throw new RuleExecutionError(
      `Rule Execution failed. Expected matrix to be of type 'array' but received '${matrix}' of type '${typeof matrix}'`
    );

  for (const row of matrix) {
    if (!Array.isArray(row))
      throw new RuleExecutionError(
        `Rule Execution failed. Expected row in matrix to be of type 'array' but received '${row}' of type '${typeof row}'`
      );
    for (const cell of row) {
      if (typeof cell !== 'string')
        throw new RuleExecutionError(
          `Rule Execution failed. Expected cell in matrix row to be of type 'string' but received '${cell}' of type '${typeof cell}'`
        );
    }
  }
}

const dateCompareAgainst = (leftEpoch: number) => {
  leftEpoch = new Date(leftEpoch).setUTCHours(0, 0, 0, 0);

  const isSameDate = (rightEpochOrToday: number | string | '@today') => {
    const rightEpoch = epochOrTodayToEpoch(rightEpochOrToday);

    return leftEpoch === rightEpoch;
  };

  const isAfterDate = (rightEpochOrToday: number | string | '@today') => {
    const rightEpoch = epochOrTodayToEpoch(rightEpochOrToday);

    return leftEpoch > rightEpoch;
  };

  const isBeforeDate = (rightEpochOrToday: number | string | '@today') => {
    const rightEpoch = epochOrTodayToEpoch(rightEpochOrToday);

    return leftEpoch < rightEpoch;
  };

  return {
    isSameDate,
    isAfterDate,
    isBeforeDate,
  };
};

function epochOrTodayToEpoch(epochOrToday: number | string | '@today') {
  if (epochOrToday === '@today') {
    // compare against the current date (set to midnight)
    return new Date().setUTCHours(0, 0, 0, 0);
  } else {
    return new Date(Number(epochOrToday)).setUTCHours(0, 0, 0, 0);
  }
}

class RuleExecutionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RuleExecutionError';
  }
}
