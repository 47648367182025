import { QueryClient, InvalidateQueryFilters } from '@tanstack/react-query';

export const invalidateMultipleQueries = (
  queryClient: QueryClient,
  queryFilters: InvalidateQueryFilters[] = []
): Promise<void[]> =>
  Promise.all(
    queryFilters.map((queryFilter) =>
      queryClient.invalidateQueries(queryFilter)
    )
  );
