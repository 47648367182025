import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  processDetailsParamsSchema,
  loopDetailsUrl,
  loopNodeDetails,
} from '@spektr/shared/utils';
import {
  createNodeFactory,
  invalidateMultipleQueries,
} from '@spektr/client/utils';

import {
  LOOPS_KEYS,
  LoopApiClient,
  getProcessQueryKey,
} from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';

import { ProcessDrawer } from '@spektr/client/components';

import { NodeCreateInput } from '@spektr/shared/types';

import { useActions, useSteps, useServices } from './config';

import { DrawerListItem } from './components/DrawerListItem';

import { DrawerItemType } from './types/DrawerItemType';

const LOOP_TABS = [
  {
    id: 'steps',
    label: 'Steps',
  },
  {
    id: 'services',
    label: 'Services',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];

export const LoopSheet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { processId } = useParsedParams(processDetailsParamsSchema);

  const actions = useActions('loop');
  const steps = useSteps('loop');
  const services = useServices('loop');

  const createNode = useMutation({
    mutationFn: (node: NodeCreateInput) =>
      LoopApiClient.getClient().createNode(node, {
        params: {
          loopId: processId,
        },
      }),
    onSuccess: async () => {
      const queriesToInvalidate = [
        // TODO: @Alex this should be a 'loop' query key
        { queryKey: getProcessQueryKey(processId) },
        { queryKey: LOOPS_KEYS.DETAILS(processId) },
      ];

      await invalidateMultipleQueries(queryClient, queriesToInvalidate);
    },
  });

  const handleClose = () => {
    navigate(loopDetailsUrl(processId));
  };

  const handleClick = (item: DrawerItemType) => {
    const bodyInput = createNodeFactory(item.type, {
      predecessorId: location.state?.predecessorNode?.id,
      successorId: location.state?.successorNode?.id,
      edgeType: location.state?.edgeType,
    }) as NodeCreateInput;

    createNode
      .mutateAsync(bodyInput)
      .then((response) => {
        navigate(loopNodeDetails(processId, response.id));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderTabContent = (tabId: string) => {
    let items: DrawerItemType[] = [];

    if (tabId === 'steps') {
      items = steps;
    }

    if (tabId === 'services') {
      items = services;
    }

    if (tabId === 'actions') {
      items = actions;
    }

    return <DrawerListItem items={items} onClick={handleClick} />;
  };

  return (
    <ProcessDrawer
      defaultTab="steps"
      description="The catalog shows all steps and services that can be added to a process. Build process logic by adding and configuring steps and services."
      renderTabContent={renderTabContent}
      tabs={LOOP_TABS}
      title="Loops steps, services and actions"
      onClose={handleClose}
    />
  );
};
