import { useMemo } from 'react';

import {
  getDescendantsForProcess,
  getNumberOfOutcomesForProcess,
} from '@spektr/shared/utils';

import { Process } from '@spektr/shared/types';

export function useGeneratePossibleSources(
  currentProcess: Process,
  processList: Process[]
) {
  const possibleSources = useMemo(() => {
    const descendants = getDescendantsForProcess(currentProcess, processList);

    return processList.filter((process: Process) => {
      // Filter out the current process
      if (process.id === currentProcess.id) {
        return false;
      }

      // and the processes that don't have any outcomes (to connect to)
      if (getNumberOfOutcomesForProcess(process) === 0) {
        return false;
      }

      return !descendants[process.id];
    });
  }, [currentProcess, processList]);

  return possibleSources;
}
