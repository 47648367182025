import { z } from 'zod';

export const argoEnvironment = z.object({
  INTERNAL_MAPPING_URL: z.string().min(1),
  ARGO_SERVER_URL: z.string().min(1),
  ARGO_NAMESPACE: z.string().min(1),
  ARGO_API_KEY: z.string().min(1),
});

export const argoGrpcEnvironment = z.object({
  ARGOWF_GRPC_URL: z.string().min(1),
});
