import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { useParsedParams } from '@spektr/shared/hooks';
import { insightsRecordDetailsParamsSchema } from '@spektr/shared/utils';

import { PaginationCombobox } from '@spektr/client/components';

import { useGetRecordActions } from '../../hooks';

import { ActionsTable } from '../../components/ActionsTable';

const allowedPageValues = [10, 20, 50];

const pageLimitSchema = z
  .number()
  .refine((value) => allowedPageValues.includes(value), {
    message: 'Value must be 10, 20, or 50',
  })
  .default(10);

export const RecordActionsList = () => {
  const { datasetId, recordId } = useParsedParams(
    insightsRecordDetailsParamsSchema
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = pageLimitSchema.parse(
    Number(searchParams.get('limit') ?? '10')
  );
  const page = Number(searchParams.get('page') ?? '1');
  const { actions, totalPages } = useGetRecordActions(recordId, datasetId, {
    limit: pageLimit,
    page,
  });

  const handleChangeLimit = (limit: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
      limit: limit.toString(),
    });
  };

  const handleChangePage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    });
  };

  return (
    <div className="flex h-full flex-col items-end gap-4">
      {actions.length === 0 ? (
        <div className="text-color-text-subtext w-full rounded-md border p-4 text-center">
          No actions found
        </div>
      ) : (
        <>
          <ActionsTable actions={actions} />
          <PaginationCombobox
            currentPage={page}
            totalPages={totalPages}
            pageLimit={pageLimit}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          />
        </>
      )}
    </div>
  );
};
