import { assertUnreachable } from '@spektr/shared/utils';

import {
  ComplyAdvantageKybNode,
  UpdateComplyAdvantageKybNode,
  ComplyAdvantageKycNode,
  UpdateComplyAdvantageKycNode,
  OpenCorporatesRiskNode,
  UpdateOpenCorporatesRiskNode,
  OpenCorporatesMonitoringNode,
  UpdateOpenCorporatesMonitoringNode,
  KyckrNode,
  UpdateKyckrNode,
  BodaccNode,
  UpdateBodaccNode,
  VeriffNode,
  UpdateVeriffNode,
  mitIdNodeSchema,
  updateMitIdNodeSchema,
  MonitoringDatasetNode,
  monitoringDatasetNodeSchema,
  updateMonitoringDatasetNodeSchema,
} from '@spektr/shared/validators';

import { ServiceNode } from '@spektr/shared/types';

export function updateFieldsFactory(
  node: ServiceNode | MonitoringDatasetNode,
  fields: Record<string, boolean>,
  mapping: Record<string, string>,
  configuration?: Record<string, string>
) {
  if (node.nodeType === 'complyAdvantageKyb') {
    const parsedNode = ComplyAdvantageKybNode.parse(node);

    return UpdateComplyAdvantageKybNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fuzziness: parsedNode.fuzziness,
      fields,
      mapping,
    });
  }

  if (node.nodeType === 'complyAdvantageKyc') {
    const parsedNode = ComplyAdvantageKycNode.parse(node);
    return UpdateComplyAdvantageKycNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fuzziness: parsedNode.fuzziness,
      fields,
      mapping,
    });
  }

  if (node.nodeType === 'openCorporatesMonitoring') {
    const parsedNode = OpenCorporatesMonitoringNode.parse(node);
    return UpdateOpenCorporatesMonitoringNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields,
      mapping,
    });
  }

  if (node.nodeType === 'openCorporatesRisk') {
    const parsedNode = OpenCorporatesRiskNode.parse(node);
    return UpdateOpenCorporatesRiskNode.parse({
      nodeType: parsedNode.nodeType,
      title: parsedNode.title,
      fields,
      mapping,
    });
  }
  if (node.nodeType === 'bodacc') {
    const parsedNode = BodaccNode.parse(node);
    return UpdateBodaccNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields,
      mapping,
    });
  }
  if (node.nodeType === 'kyckr') {
    const parsedNode = KyckrNode.parse(node);
    return UpdateKyckrNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields,
      mapping,
    });
  }

  if (node.nodeType === 'veriff') {
    const parsedNode = VeriffNode.parse(node);
    return UpdateVeriffNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields,
      mapping,
    });
  }

  if (node.nodeType === 'mitId') {
    const parsedNode = mitIdNodeSchema.parse(node);
    return updateMitIdNodeSchema.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields,
      configuration,
    });
  }

  if (node.nodeType === 'monitoringDataset') {
    const parsedNode = monitoringDatasetNodeSchema.parse(node);
    return updateMonitoringDatasetNodeSchema.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
    });
  }

  assertUnreachable(node);
}
