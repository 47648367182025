import {
  Children,
  MouseEvent,
  ReactNode,
  cloneElement,
  isValidElement,
  useState,
} from 'react';

import { cn } from '@spektr/client/utils';

import { withKeyPattern } from '../WithKeyPattern';

import { ButtonProps } from './Button';

export type ButtonGroupProps = {
  children: ReactNode;
  className?: string;
  defaultActive: string;
  size?: ButtonProps['size'];
  onChange?: (value: string) => void;
};

export const ButtonGroup = withKeyPattern<ButtonGroupProps>(
  'defaultActive',
  ({
    children,
    className,
    defaultActive = '',
    onChange,
    ...props
  }: ButtonGroupProps) => {
    const [active, setActive] = useState(defaultActive);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (!(event.target instanceof HTMLButtonElement)) return;
      if (!event.target.value) return;

      setActive(event.target.value);
      onChange && onChange(event.target.value);
    };

    return (
      <div role="group" className={className}>
        {Children.map(children, (child, index) => {
          if (!isValidElement(child)) {
            return child;
          }

          const baseBtnClasses = cn(
            'text-color-text-subtext',
            'bg-color-bg-button-default',
            'hover:bg-color-bg-button-default--hover',
            'active:bg-color-bg-button-default--active'
          );

          const extraProps: Partial<ButtonProps> = {
            ...props,
            color: 'secondary',
            role: 'tab',
            'aria-selected': child.props.value === active,
            className: cn(
              'rounded-none border border-r-0',
              child.props.value === active
                ? cn(baseBtnClasses, 'text-color-text-button-primary')
                : baseBtnClasses
            ),
            onClick: handleClick,
          };

          if (Children.count(children) > 1) {
            if (index === 0) {
              extraProps.className = cn(extraProps.className, 'rounded-l');
            }
            if (index === Children.count(children) - 1) {
              extraProps.className = cn(
                extraProps.className,
                'rounded-r border-r-1'
              );
            }
          }
          return cloneElement(child, extraProps);
        })}
      </div>
    );
  }
);
