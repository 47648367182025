import { z } from 'zod';

export const SandboxExecutionPartial = z.object({
  id: z.string(),
  workspaceId: z.string(),
  executionContextId: z.string().nullable(),
  token: z.string().nullable(),
});
export type SandboxExecutionPartial = z.infer<typeof SandboxExecutionPartial>;

export const SandboxExecution = z.object({
  id: z.string(),
  workspaceId: z.string(),
  executionContextId: z.string(),
  token: z.string(),
});
export type SandboxExecution = z.infer<typeof SandboxExecution>;
