import { Suspense } from 'react';
import { useMeasure } from 'react-use';
import { ServerCrash } from 'lucide-react';

import {
  Button,
  ButtonGroup,
  ScrollArea,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { useServiceCatalogFilters } from './hooks/useServiceCatalogFilters';

import { ServiceList } from './containers/ServiceList';
import { SkeletonServiceList } from './components/SkeletonServiceList';

export const ConnectionHubServiceCatalog = () => {
  const { stateFilter, setStateFilter } = useServiceCatalogFilters();

  const [ref, size] = useMeasure<HTMLDivElement>();

  return (
    <>
      <div className="mb-6 flex justify-between ">
        <span className="text-lg">Services</span>

        <ButtonGroup
          defaultActive={stateFilter}
          size="sm"
          onChange={setStateFilter}
        >
          <Button value="all">View all</Button>
          <Button value="configured">Configured</Button>
          <Button value="notConfigured">Not Configured</Button>
        </ButtonGroup>
      </div>

      <div ref={ref} className="relative h-full w-full overflow-hidden">
        <div className="absolute inset-0">
          <ScrollArea
            style={{
              height: size.height,
            }}
          >
            <SpektrErrorBoundary
              fallbackRender={() => (
                <div className="flex flex-col items-center justify-center gap-2 p-8">
                  <ServerCrash className="stroke-color-cyan h-8 w-8" />
                  <span className="text-color-text-error-boundry">
                    There was an error while loading the services.
                  </span>
                </div>
              )}
            >
              <Suspense fallback={<SkeletonServiceList />}>
                <ServiceList stateFilter={stateFilter} />
              </Suspense>
            </SpektrErrorBoundary>
          </ScrollArea>
        </div>
      </div>
    </>
  );
};
