import {
  RuleGroup,
  filterNodeSourceDataInputSchema,
  filterNodeSourceProcessInputSchema,
  loopSourceNodeSchema,
  onboardingProcessSourceNodeSchema,
} from '@spektr/shared/validators';

import {
  ProcessNode,
  Process,
  isFilterNodeType,
  isRouterNodeType,
  OutcomeNodeWithTitle,
  isRouterEdgeType,
  isOutcomeNodeType,
  isServiceNodeType,
  OutcomeNode,
} from '@spektr/shared/types';

export type ProcessWithNodes = Pick<Process, 'rootId' | 'nodes' | 'name'>;

export function getRootNode<TProcess extends ProcessWithNodes>(
  process: TProcess
) {
  return process.rootId ? process.nodes.get(process.rootId) : undefined;
}

export function getExistingSource<TProcess extends ProcessWithNodes>(
  process: TProcess
) {
  const rootNode = getRootNode(process);

  if (!rootNode || !isFilterNodeType(rootNode)) {
    return undefined;
  }

  const processMethodResult = filterNodeSourceProcessInputSchema.safeParse(
    rootNode.source
  );

  if (processMethodResult.success) {
    return processMethodResult.data;
  }

  const sourceMethodResult = filterNodeSourceDataInputSchema.safeParse(
    rootNode.source
  );

  if (sourceMethodResult.success) {
    return sourceMethodResult.data;
  }

  return undefined;
}

export function hasDatasetSourceWithFilter<TProcess extends ProcessWithNodes>(
  process: TProcess
) {
  const filter = getExistingSource(process);

  if (filter?.type === 'source') {
    return filter.filter !== undefined;
  }

  return false;
}

export function getFilterNodeSource<TProcess extends ProcessWithNodes>(
  process: TProcess
) {
  const rootNode = getRootNode(process);

  if (rootNode?.nodeType === 'filter' && rootNode.source) {
    return rootNode.source.type;
  }

  return 'process';
}

export function getServiceNodes<TProcess extends ProcessWithNodes>(
  process: TProcess
) {
  const serviceNodes = [];
  for (const node of process.nodes.values()) {
    if (isServiceNodeType(node)) {
      serviceNodes.push(node);
    }
  }

  return serviceNodes;
}

export function getEnabledServiceFields(node: ProcessNode) {
  if (isServiceNodeType(node)) {
    return Object.entries(node.fields)
      .filter(([_, value]) => value === true)
      .map(([key]) => ({ key, nodeType: node.nodeType }));
  }

  return [];
}

/**
 * @description
 *    Returns an array of all possible rule-groups for a given process
 *  by iterating through all router nodes.
 *
 * @param process - The process to get the router rule-groups for.
 * @returns An array of all possible rule-groups for the given process.
 */
export function getRouterRuleGroups<TProcess extends ProcessWithNodes>(
  process: TProcess
) {
  const groups: RuleGroup[] = [];
  for (const node of process.nodes.values()) {
    if (isRouterNodeType(node)) {
      groups.push(...node.groups);
    }
  }

  return groups;
}

export function getProcessOutcomesWithTitle<TProcess extends ProcessWithNodes>(
  process: TProcess
) {
  const outcomes: OutcomeNodeWithTitle[] = [];

  for (const node of process.nodes.values()) {
    if (isOutcomeNodeType(node)) {
      const title = getOutcomeTitle(process, node);

      outcomes.push({
        ...node,
        title,
      });
    }
  }

  return outcomes;
}

export const getNumberOfOutcomesForProcess = (process: Process): number => {
  let outcomes = 0;

  for (const node of process.nodes.values()) {
    if (isOutcomeNodeType(node)) {
      outcomes++;
    }
  }

  return outcomes;
};

export const getNumberOfOutcomesForProcesses = (
  processes: Process[] | undefined
): number =>
  processes?.reduce((acc, process) => {
    return acc + getNumberOfOutcomesForProcess(process);
  }, 0) || 0;

type BfsQueueElement = {
  id: string;
  children: string[];
  path: string[];
};
export function breadthFirstSearch<TProcess extends ProcessWithNodes>(
  process: TProcess,
  targetId: string
) {
  function getChildrenIds(node: ProcessNode) {
    return node.adj.map((e) => e.id);
  }

  const root = getRootNode(process);
  if (!root) return undefined;

  const queue: BfsQueueElement[] = [];
  const visited = new Set<string>();

  queue.push({
    id: root.id,
    path: [root.id],
    children: getChildrenIds(root),
  });

  while (queue.length) {
    const next = queue.shift();
    if (!next) continue;

    if (next.id === targetId) return next.path;

    for (const childId of next.children) {
      const child = process.nodes.get(childId);
      if (!child) continue;

      if (!visited.has(childId)) {
        visited.add(childId);
        queue.push({
          id: childId,
          children: getChildrenIds(child),
          path: [...next.path, childId],
        });
      }
    }
  }

  return undefined;
}

export function getParentNodeByChildId<TProcess extends ProcessWithNodes>(
  process: TProcess,
  childId: string
) {
  for (const node of process.nodes.values()) {
    if (node.adj.find((adjNode) => adjNode.id === childId)) {
      return node;
    }
  }

  return undefined;
}

export function getOutcomeTitle<TProcess extends ProcessWithNodes>(
  process: TProcess,
  outcome: OutcomeNode
) {
  let title = `Outcome of "${process.name}"`;

  if (outcome.route) {
    const routerNode = process.nodes.get(outcome.route.routerId);

    if (routerNode && isRouterNodeType(routerNode)) {
      const segment = routerNode.groups.find(
        (group) => group.id === outcome.route?.ruleGroupId
      );

      if (segment?.title) {
        title = segment.title;
      }
    }
  } else {
    for (const node of process.nodes.values()) {
      const adjNode = node.adj.find((adjNode) => adjNode.id === outcome.id);

      if (adjNode?.name) {
        title = adjNode.name;
      }
    }
  }

  return title;
}

export function isKyckrOutcome<TProcess extends ProcessWithNodes>(
  process: TProcess | undefined,
  outcomeId: string | undefined
) {
  if (!process || !outcomeId) {
    return false;
  }

  for (const node of process.nodes.values()) {
    const adjNode = node.adj.find((adjNode) => adjNode.id === outcomeId);

    if (adjNode?.isFinal) {
      return true;
    }
  }
  return false;
}

export function isKyckrShareholdingOutcome<TProcess extends ProcessWithNodes>(
  process: TProcess | undefined,
  outcomeId: string | undefined
) {
  if (!process || !outcomeId) {
    return false;
  }

  for (const node of process.nodes.values()) {
    const adjNode = node.adj.find((adjNode) => adjNode.id === outcomeId);

    if (
      adjNode?.isFinal &&
      adjNode.name === 'all_potential_layers_of_ownership'
    ) {
      return true;
    }
  }
  return false;
}

export function getRouterGroupByOutcomeId<TProcess extends ProcessWithNodes>(
  process: TProcess,
  outcomeId: string
) {
  const node = getParentNodeByChildId(process, outcomeId);
  const edgeToOutcomeNode = node?.adj.find(
    (adjNode) => adjNode.id === outcomeId
  );

  if (
    node &&
    edgeToOutcomeNode &&
    isRouterEdgeType(edgeToOutcomeNode) &&
    isRouterNodeType(node)
  ) {
    return node.groups.find((group) => group.id === edgeToOutcomeNode.routeId);
  }

  return undefined;
}

type ProcessNodes = Process['nodes'];
export const getAllOutcomeNodes = (nodes: ProcessNodes) =>
  Array.from(nodes.values()).filter(isOutcomeNodeType);

export const getAllServiceNodes = (nodesMap: ProcessNodes) => {
  const nodes = Array.from(nodesMap.values());

  return nodes.filter((node) => isServiceNodeType(node));
};

export function getChannelSettingsFromRootNode(process: Process) {
  const rootNode = getRootNode(process);

  if (!rootNode) {
    return undefined;
  }

  const onboardingProcessSettings =
    onboardingProcessSourceNodeSchema.safeParse(rootNode);

  if (!onboardingProcessSettings.success) {
    const loopSettings = loopSourceNodeSchema.safeParse(rootNode);

    if (!loopSettings.success) {
      return undefined;
    }

    return loopSettings.data.channelSettings;
  }

  return onboardingProcessSettings.data.channelSettings;
}
