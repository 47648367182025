import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import {
  getAllExecutionsQuery,
  getProcessesQuery,
} from '@spektr/client/services';
import { PaginationCombobox } from '@spektr/client/components';
import { useDemo } from '@spektr/client/providers';

import { ExecutionsTable } from '../../components/ExecutionsTable';

const allowedPageValues = [10, 20, 50];

const pageLimitSchema = z
  .number()
  .refine((value) => allowedPageValues.includes(value), {
    message: 'Value must be 10, 20, or 50',
  })
  .default(10);

type ExecutionsListProps = {
  selectedDatasetId: string;
};

// TODO: (@Robert) - Extract the search params as custom hook since we use them in multiple places
export const ExecutionsList = ({
  selectedDatasetId = '',
}: ExecutionsListProps) => {
  const { isDemo } = useDemo();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = pageLimitSchema.parse(
    Number(searchParams.get('limit') ?? '10')
  );
  const page = Number(searchParams.get('page') ?? '1');

  const { data: processes } = useSuspenseQuery(getProcessesQuery());
  const { data: executions } = useSuspenseQuery({
    ...getAllExecutionsQuery(selectedDatasetId, {
      // filter: searchValue,
      limit: pageLimit,
      page,
    }),
    refetchInterval: 10000,
  });

  const processAndLoopsList = useMemo(
    () => [...(processes ?? [])],
    [processes]
  );

  const handleChangeLimit = (limit: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
      limit: limit.toString(),
    });
  };

  const handleChangePage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    });
  };

  return (
    <div className="flex h-full flex-col items-end gap-4">
      <ExecutionsTable
        executions={executions.docs}
        processes={processAndLoopsList}
      />
      <PaginationCombobox
        currentPage={executions.page}
        totalPages={executions.totalPages}
        pageLimit={executions.limit}
        alignment={isDemo ? 'start' : 'end'}
        onChangeLimit={handleChangeLimit}
        onChangePage={handleChangePage}
      />
    </div>
  );
};
