import { ReactEditor } from 'slate-react';

import { type SlateElement } from '@spektr/shared/slate-types';

import { isUrl } from '../utils';
import { wrapLink } from '../utils/link';

export const withInlines = (editor: ReactEditor) => {
  const { insertData, insertText, isInline, isElementReadOnly, isSelectable } =
    editor;

  editor.isInline = (element) =>
    ['link'].includes((element as SlateElement).type) || isInline(element);

  editor.isElementReadOnly = (element) => isElementReadOnly(element);

  editor.isSelectable = (element) => isSelectable(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
