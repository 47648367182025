import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { HitDecisionStatus } from '@spektr/shared/validators';

import { cn } from '@spektr/client/utils';
import { Button, Select, Spinner, Textarea } from '@spektr/client/components';

import { useHitsProviderContext } from '../../providers';

export const MultipleHitsResolver = () => {
  const { t } = useTranslation('insights');
  const {
    selectedHitsCount,
    makeDecisionForHits,
    decisionStatus,
    reason,
    updateDecisionStatus,
    updateReason,
    resetSelectedHits,
    isPendingUpdate,
    actionType,
  } = useHitsProviderContext();

  const handleActionUpdate = (value: HitDecisionStatus) => {
    updateDecisionStatus(value);
  };

  const isVisible = selectedHitsCount > 0;

  const isDisabledForOc = actionType === 'kyb' && selectedHitsCount > 1;
  const isApplyDisabled =
    !decisionStatus ||
    (isDisabledForOc && decisionStatus === 'true-positive') ||
    isPendingUpdate;

  useEffect(() => {
    if (!isVisible) {
      resetSelectedHits();
    }
  }, [isVisible, resetSelectedHits]);

  return (
    <div
      className={cn(
        'flex items-center gap-2 p-3',
        'fixed left-1/2 -translate-x-1/2 transform',
        'transition-all duration-200 ease-in-out',
        'bg-color-bg-primary rounded-md border shadow-xl',
        'z-50',
        selectedHitsCount === 0
          ? 'bottom-0 translate-y-full'
          : 'bottom-5 translate-y-0'
      )}
    >
      <span className="whitespace-nowrap text-xs font-semibold">
        Apply to all selected
      </span>
      <Select
        className="w-36"
        value={decisionStatus}
        options={[
          {
            label: t(`actions.hit.action.${actionType}.false-positive`),
            value: actionType === 'aml' ? 'true-positive' : 'false-positive',
          },
          {
            label: t(`actions.hit.action.${actionType}.true-positive`),
            value: actionType === 'aml' ? 'false-positive' : 'true-positive',
            disabled: isDisabledForOc,
          },
        ]}
        placeholder="Action"
        onValueChange={handleActionUpdate}
        disabled={isPendingUpdate}
      />
      <Textarea
        className="h-9 min-h-0 w-72 resize-x py-2"
        placeholder="Add a comment"
        value={reason}
        onChange={(e) => updateReason(e.target.value)}
        disabled={isPendingUpdate}
      />
      <Button
        disabled={isApplyDisabled}
        onClick={decisionStatus ? () => makeDecisionForHits() : undefined}
      >
        {isPendingUpdate ? (
          <>
            <Spinner className="mr-1 h-3 w-3" /> Applying
          </>
        ) : (
          'Apply'
        )}
      </Button>
    </div>
  );
};
