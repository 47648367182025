import { CircleAlert } from 'lucide-react';

import { Button } from '@spektr/client/components';

type Props = {
  title: string;
  actionLabel?: string;
  onAction?: () => void;
};

export function ErrorPage({ title, actionLabel, onAction }: Props) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <CircleAlert className="text-color-red h-12 w-12" />

      <h1 className="my-4">{title}</h1>

      {actionLabel && onAction && (
        <Button onClick={onAction}>{actionLabel}</Button>
      )}
    </div>
  );
}
