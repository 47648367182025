import { Zodios } from '@zodios/core';
import { pluginToken } from '@zodios/plugins';

import {
  ApiConfig,
  createBaseApiClient,
  getAccessToken,
} from '@spektr/client/utils';
import { moneypennyApi } from '@spektr/shared/validators';

function createMoneypennyApiClient(config: ApiConfig) {
  const instance = new Zodios(config.baseUrl, moneypennyApi);
  instance.use(
    pluginToken({
      getToken: () =>
        Promise.resolve(getAccessToken(config.clientId) ?? undefined),
    })
  );

  return instance;
}

export const MoneypennyApiClient = createBaseApiClient(
  createMoneypennyApiClient
);
