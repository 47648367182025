import { Process } from '@spektr/shared/types';
import { getExistingSource } from '@spektr/shared/utils';

export const getAvailableProcessesForConnection = (
  processes: Process[],
  sourceProcessId: string
) => {
  return processes.filter((process) => {
    if (process.id === sourceProcessId) {
      return false;
    }

    if (process.type === 'loop') {
      return true;
    }

    const source = getExistingSource(process);
    return source === undefined;
  });
};
