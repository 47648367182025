import { Layers3 } from 'lucide-react';

import {
  BasicDialog,
  Button,
  CardItem,
  CardList,
} from '@spektr/client/components';

import { useGoToConnectionHub } from '@spektr/shared/hooks';

import { CONNECTIONS } from './constants/connections';

export const DirectConnectionDialog = () => {
  const goToConnectionHub = useGoToConnectionHub();

  const handleClose = () => goToConnectionHub();

  return (
    <BasicDialog
      description={
        <span>
          <strong className="text-color-text-primary">
            Explore our direct connections
          </strong>{' '}
          - easily import your data to spektr using pre-built connectors.
        </span>
      }
      icon={<Layers3 className="stroke-color-yellow mr-3 h-5 w-5" />}
      defaultOpen
      title="Choose direct connection"
      onClose={handleClose}
    >
      <CardList
        className="shadow-md"
        items={CONNECTIONS}
        title="Choose your data integration vendor"
        renderItems={(item) => {
          return (
            <CardItem
              key={item.title}
              id={item.id}
              description={item.description}
              icon={item.icon}
              title={item.title}
              renderAction={() => (
                <Button color="yellow" size="sm">
                  Continue
                </Button>
              )}
              // onClick={() => handleClick(item.link)}
            />
          );
        }}
      />
    </BasicDialog>
  );
};
