import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ServerCrash } from 'lucide-react';

import { serviceDialogParamsSchema } from '@spektr/shared/utils';

import { useVendorServices } from '@spektr/platform-hooks';
import { useGoToServiceCatalog, useParsedParams } from '@spektr/shared/hooks';
import {
  BasicDialog,
  Button,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import {
  ServiceDialogContent,
  SkeletonServiceDialogContent,
} from './components';

export const ConnectionHubServiceDialog = () => {
  const { t } = useTranslation('vendors');
  const { serviceName } = useParsedParams(serviceDialogParamsSchema);
  const goToServiceCatalog = useGoToServiceCatalog();
  const { data: vendors } = useVendorServices();

  const handleOnCancel = () => goToServiceCatalog();

  const vendor = useMemo(() => {
    return vendors?.find((vendor) => vendor.id === serviceName);
  }, [vendors]);

  return (
    <BasicDialog
      title={`Set up ${t(`${vendor!.id}.name`)}`}
      dialogHeaderClassName="mb-2"
      className="w-[512px] rounded-2xl border-0"
      defaultOpen
      onClose={handleOnCancel}
      showCloseButton={false}
    >
      <SpektrErrorBoundary
        fallbackRender={() => (
          <div className="mt-2 flex flex-col items-center justify-center gap-2">
            <ServerCrash className="stroke-color-cyan h-8 w-8" />
            <span className="text-color-text-error-boundry">
              There was an error while loading this service.
            </span>
            <Button variant="text" onClick={() => handleOnCancel()}>
              Close
            </Button>
          </div>
        )}
      >
        <Suspense fallback={<SkeletonServiceDialogContent />}>
          <ServiceDialogContent
            serviceName={serviceName}
            onCancel={() => handleOnCancel()}
          />
        </Suspense>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
