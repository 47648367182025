import { useFlags } from 'launchdarkly-react-client-sdk';

export type FeatureFlags = {
  moonraker: boolean;
  kycrAndCaoc: boolean;
  loopFormV2: boolean;
  returningProcess: boolean;
  monitoringDataset: boolean;
  veriff: boolean;
  spektrAi: boolean;
  mitid: boolean;
  loopSettings: boolean;
  ownershipStructure: boolean;
  spektrTheme: boolean;
  fallbackNode: boolean;
  onboardingProcessType: boolean;
  branding: boolean;
  manualReview: boolean;
  customersListView: boolean;
  spektrAiNode: boolean;
};

export function useFeatureFlags() {
  const flags = useFlags<FeatureFlags>();

  return flags;
}
