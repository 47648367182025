import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import { cn } from '@spektr/client/utils';

export type AccordionContentProps = ComponentPropsWithoutRef<
  typeof Accordion.Content
>;

export const AccordionContent = forwardRef<
  ElementRef<typeof Accordion.Content>,
  AccordionContentProps
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={cn(
      'data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp',
      'overflow-hidden',
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Content>
));
