import { useMemo } from 'react';

import { SpektrField, spektrOperators } from '@spektr/shared/types';
import { SpektrFieldTypedKey } from '@spektr/shared/validators';

import { SpektrfieldCombobox } from '@spektr/client/components';

import { useIncompleteRuleGroup } from '../providers';
import { useNodeCustomFields } from '../hooks';

export const SelectSpektrFieldCombobox = (props: { ruleId: string }) => {
  const nodeCustomFields = useNodeCustomFields();
  const { getPredicate, setPredicate, spektrFields } = useIncompleteRuleGroup();

  const spektrFieldsAndCustomFields = useMemo(
    () => [...nodeCustomFields, ...spektrFields],
    [nodeCustomFields, spektrFields]
  );

  const rule = getPredicate(props.ruleId);
  const value = typeof rule?.left === 'string' ? rule.left : undefined;
  const selectedField = useMemo(
    () => spektrFields.find((field) => field.key === value),
    [value, spektrFields]
  );
  const selectedCustomField = useMemo(() => {
    if (selectedField || !rule) return undefined;

    return {
      key: rule.left as string,
      type: rule.type,
    };
  }, [rule, selectedField]);

  if (!rule) return null;

  const handleChange = (fieldKey: string) => {
    let field: SpektrField | SpektrFieldTypedKey | undefined =
      spektrFields.find((f) => f.key === fieldKey);

    if (!field)
      field = nodeCustomFields.find(
        (customField) => customField.key === fieldKey
      );

    if (!field) {
      setPredicate(props.ruleId, {
        left: fieldKey,
        type: undefined,
        operator: undefined,
      });

      return;
    }

    const allowedOperators = spektrOperators.filter(
      (operator) => operator.type === field.type
    );

    setPredicate(props.ruleId, {
      left: field.key,
      type: field.type,
      operator: allowedOperators.at(0)?.key,
      right: field.type === 'boolean' ? true : undefined,
    });
  };

  return (
    <SpektrfieldCombobox
      fields={spektrFieldsAndCustomFields}
      defaultValue={selectedField?.label ?? selectedCustomField?.key ?? ''}
      inputProps={{ required: true, placeholder: 'Select or create a field' }}
      onChange={handleChange}
      triggerProps={{ 'data-testid': `select-spektr-field-${props.ruleId}` }}
    />
  );
};
