import { SpektrField } from '@spektr/shared/types';
import { WidgetTypes } from '@spektr/shared/validators';

export const filterFields = (
  fields: SpektrField[],
  type: WidgetTypes
): SpektrField[] => {
  return type === 'upload'
    ? fields.filter((field) => field.type === 'file')
    : fields.filter((field) => field.type !== 'file');
};
