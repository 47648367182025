import { AlertDialog } from '@spektr/client/components';
import { useUpdateCurrentRoute } from '@spektr/client/providers';
import { UseMutationResult } from '@tanstack/react-query';

type RefreshSessionDialogProps = {
  open: boolean;
  handleRefresh: UseMutationResult<void, Error, void, unknown>;
  onDeclineRefresh: () => void;
};

export const RefreshSessionDialog = ({
  open,
  handleRefresh,
  onDeclineRefresh,
}: RefreshSessionDialogProps) => {
  const updateState = useUpdateCurrentRoute();

  const handleConfirmRefresh = async () => {
    await handleRefresh.mutateAsync();
    updateState();
  };

  const handleCancel = () => {
    onDeclineRefresh();
    updateState();
  };

  return (
    <AlertDialog
      open={open}
      title="Your session is about to expire"
      paragraph="Do you want to refresh your session?"
      onCancel={handleCancel}
      cancel="No"
      onConfirm={handleConfirmRefresh}
      confirm="Refresh session"
      disableButtons={handleRefresh.isPending}
      confirmButtonColor="white"
    />
  );
};
