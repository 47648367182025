import { Suspense, useState } from 'react';
import {
  QueryErrorResetBoundary,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  UsersApiClient,
  getTeamMembersQueryKey,
} from '@spektr/client/services';

import { UserRoles, UserSchema } from '@spektr/shared/validators';
import { CognitoUserData } from '@spektr/shared/types';

import {
  AlertDialog,
  SpektrErrorBoundary,
  toast,
} from '@spektr/client/components';
import { Card } from '@spektr/shared/components';

import { ListTeamMembers } from '../../components/ListTeamMembers';
import { TableSkeleton } from '../../components/TableSkeleton';
import { TableError } from '../../components/TableError';

export type TeamMembersProps = {
  className?: string;
  user?: CognitoUserData;
};

export const TeamMembers = ({ user, className }: TeamMembersProps) => {
  const queryClient = useQueryClient();
  const [pendingDeleteId, setPendingDeleteId] = useState('');

  const deleteMember = useMutation({
    mutationFn: async (userId: string) => {
      await queryClient.cancelQueries({
        queryKey: getTeamMembersQueryKey(['userRole']),
      });

      queryClient.setQueryData(
        getTeamMembersQueryKey(['userRole']),
        (members: UserSchema[]) => {
          return members.filter((member) => member.id !== userId);
        }
      );

      return UsersApiClient.getClient().deleteUser(undefined, {
        params: {
          id: userId,
        },
      });
    },
    onError: () => {
      toast.error({
        title: "Your team member hasn't been deleted!",
        description: 'An error occured, please try again.',
      });
    },
    onSuccess: () => {
      toast.success({
        title: 'Team member successfully deleted!',
        description: 'The team member has been deleted from your workspace.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getTeamMembersQueryKey(['userRole']),
      });
    },
  });

  const updateMember = useMutation({
    mutationFn: async ({
      userId,
      role,
    }: {
      userId: string;
      role: UserRoles;
    }) => {
      await queryClient.cancelQueries({
        queryKey: getTeamMembersQueryKey(['userRole']),
      });

      queryClient.setQueryData(
        getTeamMembersQueryKey(['userRole']),
        (members: UserSchema[]) => {
          return members.map((member) => {
            if (member.id === userId) {
              return { ...member, role };
            }

            return member;
          });
        }
      );

      return UsersApiClient.getClient().updateUserAsAdmin(
        { role },
        {
          params: {
            id: userId,
          },
        }
      );
    },
    onError: () => {
      toast.error({
        title: "Your changes haven't been saved!",
        description: 'An error occured, please try again.',
      });
    },
    onSuccess: () => {
      toast.success({
        title: 'Team member successfully updated!',
        description: 'The team member role has been updated.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getTeamMembersQueryKey(['userRole']),
      });
    },
  });

  const onConfirmDeleteTeamMember = () => {
    deleteMember.mutate(pendingDeleteId);
    setPendingDeleteId('');
  };

  return (
    <div className={className}>
      <AlertDialog
        open={!!pendingDeleteId}
        title="Are you sure?"
        paragraph="You will have to re-invite your team member."
        onCancel={() => setPendingDeleteId('')}
        cancel="Cancel"
        onConfirm={onConfirmDeleteTeamMember}
        confirm="Yes, delete"
        confirmDataCy="Yes-delete"
      />
      <Card className="flex w-full max-w-7xl flex-col gap-4">
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <SpektrErrorBoundary
              onReset={reset}
              fallbackRender={(props) => <TableError {...props} />}
            >
              <Suspense fallback={<TableSkeleton />}>
                <ListTeamMembers
                  currentUserEmail={user?.email}
                  onDelete={setPendingDeleteId}
                  onUpdate={updateMember.mutateAsync}
                />
              </Suspense>
            </SpektrErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </Card>
    </div>
  );
};
