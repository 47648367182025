import axios from 'axios';
import { Suspense, useState } from 'react';

import {
  InsightsErrorDashboard,
  Skeleton,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { ExecutionsTable } from './components/ExecutionsTable';
import { SkeletonExecutionsTable } from './components/SkeletonExecutionsTable';
import { DatasetFilter } from './containers/DatasetFilter';
import { ExecutionsList } from './containers/ExecutionsList';
import { useDatasetId } from './hooks';

export const InsightsRunsDashboard = () => {
  const [fallbackDatasetId, updateFallbackDatasetId] = useState('');
  const { datasetId, setDatasetId } = useDatasetId(fallbackDatasetId);

  return (
    <SpektrErrorBoundary
      fallbackRender={(error) => {
        const errorMsg = axios.isAxiosError(error)
          ? error.message
          : 'An error has occurred while retrieving the insights!';
        return <InsightsErrorDashboard message={errorMsg} />;
      }}
    >
      <>
        <div className="mb-4 flex justify-end">
          <Suspense fallback={<Skeleton className="h-9 w-48 rounded-md" />}>
            <DatasetFilter
              selectedDatasetId={datasetId}
              updateDatasetId={setDatasetId}
              updateFallbackDatasetId={updateFallbackDatasetId}
            />
          </Suspense>
        </div>
        {datasetId ? (
          <Suspense fallback={<SkeletonExecutionsTable />}>
            <ExecutionsList selectedDatasetId={datasetId} />
          </Suspense>
        ) : (
          <ExecutionsTable executions={[]} processes={[]} />
        )}
      </>
    </SpektrErrorBoundary>
  );
};
