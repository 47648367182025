import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

import { ParsedRepresentativeData } from '../../utils/computeOwnershipData';

import { RecordOwnershipRepresentativesTableHeader } from './RecordOwnershipRepresentativesTableHeader';

type RecordOwnershipUboTableProps = {
  representativesList: ParsedRepresentativeData[];
};

const baseRowCellClasses = cn('px-4', 'border-b', 'text-sm font-medium');

export const RecordOwnershipRepresentativesTable = ({
  representativesList,
}: RecordOwnershipUboTableProps) => {
  return (
    <Table className="border-separate">
      <RecordOwnershipRepresentativesTableHeader />
      <TableBody>
        {representativesList.map((item, index) => {
          const classes = cn(
            baseRowCellClasses,
            index === representativesList.length - 1 && 'border-0'
          );
          return (
            <TableRow key={`${item.name}-${index}`}>
              <TableCell className={classes}>{item.type}</TableCell>
              <TableCell className={classes}>{item.name}</TableCell>
              <TableCell className={classes}>{item.address}</TableCell>
              <TableCell className={classes}>{item.role ?? ''}</TableCell>
              <TableCell className={classes}>
                {item.isActive ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
