import { z } from 'zod';

export const spektrAIStatusSchema = z.enum(['disabled', 'pending', 'enabled']);
export type SpektrAIStatus = z.infer<typeof spektrAIStatusSchema>;

export const spektrAIDetailsNotEnabledSchema = z.object({
  status: z.union([z.literal('disabled'), z.literal('pending')]),
});
export const spektrAIDetailsEnabledSchema = z.object({
  status: z.literal('enabled'),
  mediaCategory: z.string(),
  relatedToTarget: z.boolean(),
  relatedToTargetReason: z.string(),
  isAdverseMedia: z.boolean(),
  isAdverseMediaReason: z.string(),
});

export const spektrAIDetailsSchema = z.union([
  spektrAIDetailsNotEnabledSchema,
  spektrAIDetailsEnabledSchema,
]);

export type SpektrAIDetailsNotEnabled = z.infer<
  typeof spektrAIDetailsNotEnabledSchema
>;
export type SpektrAIDetailsEnabled = z.infer<
  typeof spektrAIDetailsEnabledSchema
>;
export type SpektrAIDetails = z.infer<typeof spektrAIDetailsSchema>;

export type SpektrAIProps = {
  details?: SpektrAIDetails;
  isPending: boolean;
  performSearch: (hitId: string) => void;
};
