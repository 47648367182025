import { BaseEditor, Element, Node, Transforms } from 'slate';

import { isSlateElement } from '@spektr/shared/slate-types';

export const transformNodesToParagraphs = (editor: BaseEditor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (isSlateElement(node)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && !editor.isInline(child)) {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  return editor;
};
