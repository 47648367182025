import { Suspense } from 'react';

import { CognitoUserData } from '@spektr/shared/types';

import { ProcessScheduleProvider } from '../../providers';

import { SettingItemSkeleton } from '../../components/SettingItemSkeleton';
import { VersionsSkeleton } from '../../components/VersionsSkeleton';
import { PublishVersion } from '../PublishVersion';
import { ProcessVersions } from '../ProcessVersions';
import { ProcessLaunchOnboarding } from '../ProcessLaunchOnboarding';

export type ProcessScheduleProps = {
  processId: string;
  publicPageUrl: string;
  user?: CognitoUserData;
};

export const ProcessSchedule = ({
  processId,
  publicPageUrl,
  user,
}: ProcessScheduleProps) => {
  return (
    <ProcessScheduleProvider processId={processId}>
      <ProcessLaunchOnboarding
        processId={processId}
        publicPageUrl={publicPageUrl}
        workspaceId={user?.['custom:workspaceId']}
      />
      <div className="divide-color-border-primary flex flex-col">
        <Suspense fallback={<SettingItemSkeleton />}>
          <PublishVersion
            processId={processId}
            title="Publish a version"
            description="Make a new live version of the current process"
          />
        </Suspense>
      </div>

      <Suspense fallback={<VersionsSkeleton />}>
        <ProcessVersions processId={processId} />
      </Suspense>
    </ProcessScheduleProvider>
  );
};
