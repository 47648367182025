import { z } from 'zod';

export const validationTypes = ['required', 'regex'] as const;

export const validationTypesEnum = z.enum(validationTypes);

export const RequiredFieldValidation = z.object({
  type: z.literal('required'),
  value: z.literal(true),
  message: z.string(),
});

export const RegexFieldValidation = z.object({
  type: z.literal('regex'),
  value: z.any(),
  preset: z.string().optional(),
  message: z.string(),
});

export const FieldValidation = z.union([
  RequiredFieldValidation,
  RegexFieldValidation,
]);
