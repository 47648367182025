import { assertUnreachable } from '@spektr/shared/utils';

import {
  createCalculationNodeSchema,
  createReturningProcessNodeSchema,
  createMonitoringDatasetNodeSchema,
  CreateComplyAdvantageKybNode,
  CreateComplyAdvantageKycNode,
  CreateOpenCorporatesRiskNode,
  CreateOpenCorporatesMonitoringNode,
  createSlackNodeSchema,
  createRouterNodeSchema,
  CreateActionFormNode,
  CreateBodaccNode,
  createActionNodeSchema,
  CreateKyckrNode,
  CreateVeriffNode,
  createMitIdNodeSchema,
  NodeType,
  createFilterNodeSchema,
  createLoopSourceNodeSchema,
  createOnboardingProcessSourceNodeSchema,
  createOutcomeNodeSchema,
  createManualReviewNodeSchema,
} from '@spektr/shared/validators';
import { CreateAiActionConfigNodeSchema } from 'libs/shared/validators/src/lib/node/aiActionConfig';

type RelationshipArg = {
  predecessorId?: string;
  successorId?: string;
  edgeType?: string;
};

export function createNodeFactory(
  nodeType: NodeType,
  relationship: RelationshipArg
) {
  switch (nodeType) {
    case 'calculation':
      return createCalculationNode(relationship);
    case 'returningProcess':
      return createReturningProcessNode(relationship);
    case 'monitoringDataset':
      return createMonitoringDatasetNode(relationship);
    case 'slack':
      return createSlackNode(relationship);
    case 'router':
      return createRouterNode(relationship);
    case 'complyAdvantageKyb':
      return createComplyAdvantageKybService(relationship);
    case 'complyAdvantageKyc':
      return createComplyAdvantageKycService(relationship);
    case 'openCorporatesMonitoring':
      return createOpenCorporatesMonitoringService(relationship);
    case 'openCorporatesRisk':
      return createOpenCorporatesRiskService(relationship);
    case 'bodacc':
      return createBodaccService(relationship);
    case 'kyckr':
      return createKyckrService(relationship);
    case 'action':
      return createActionStepNode(relationship);
    case 'manualReview':
      return createManualReviewNode(relationship);
    case 'actionForm':
      return createActionFormNode(relationship);
    case 'mitId':
      return createMitIdService(relationship);
    case 'veriff':
      return createVeriffService(relationship);
    case 'filter':
      return createFilterNodeSchema.parse(
        createBaseNode('filter', 'New filter node', relationship)
      );
    case 'loopSource':
      return createLoopSourceNodeSchema.parse(
        createBaseNode('loopSource', 'New loop source node', relationship)
      );
    case 'onboardingProcessSource':
      return createOnboardingProcessSourceNodeSchema.parse(
        createBaseNode(
          'onboardingProcessSource',
          'New onboarding process source node',
          relationship
        )
      );
    case 'aiActionConfig':
      return CreateAiActionConfigNodeSchema.parse(
        createBaseNode('aiActionConfig', 'spektrAi Config', relationship)
      );
    case 'outcome':
      return createOutcomeNodeSchema.parse(
        createBaseNode('outcome', 'New outcome node', relationship)
      );
    default:
      assertUnreachable(nodeType);
  }
}

function createBaseNode(
  nodeType: NodeType,
  title: string,
  relationship: RelationshipArg
) {
  return {
    nodeType,
    predecessorId: relationship?.predecessorId,
    successorId: relationship?.successorId,
    edgeType: relationship?.edgeType,
    title,
  };
}

function createCalculationNode(relationship: RelationshipArg) {
  return createCalculationNodeSchema.parse({
    ...createBaseNode('calculation', 'New calculation node', relationship),
    segments: [],
  });
}

function createReturningProcessNode(relationship: RelationshipArg) {
  return createReturningProcessNodeSchema.parse({
    ...createBaseNode(
      'returningProcess',
      'New returning process node',
      relationship
    ),
  });
}

function createMonitoringDatasetNode(relationship: RelationshipArg) {
  return createMonitoringDatasetNodeSchema.parse({
    ...createBaseNode(
      'monitoringDataset',
      'New field watch node',
      relationship
    ),
  });
}

function createSlackNode(relationship: RelationshipArg) {
  return createSlackNodeSchema.parse({
    ...createBaseNode('slack', 'New slack node', relationship),
  });
}

function createRouterNode(relationship: RelationshipArg) {
  return createRouterNodeSchema.parse({
    ...createBaseNode('router', 'New router node', relationship),
    groups: [],
  });
}

function createComplyAdvantageKybService(relationship: RelationshipArg) {
  return CreateComplyAdvantageKybNode.parse(
    createBaseNode(
      'complyAdvantageKyb',
      'ComplyAdvantage (Company) Service',
      relationship
    )
  );
}

function createComplyAdvantageKycService(relationship: RelationshipArg) {
  return CreateComplyAdvantageKycNode.parse(
    createBaseNode(
      'complyAdvantageKyc',
      'ComplyAdvantage (Individual) Service',
      relationship
    )
  );
}

function createOpenCorporatesMonitoringService(relationship: RelationshipArg) {
  return CreateOpenCorporatesMonitoringNode.parse(
    createBaseNode(
      'openCorporatesMonitoring',
      'OpenCorporates Monitoring Service',
      relationship
    )
  );
}

function createOpenCorporatesRiskService(relationship: RelationshipArg) {
  return CreateOpenCorporatesRiskNode.parse(
    createBaseNode(
      'openCorporatesRisk',
      'OpenCorporates Risk Service',
      relationship
    )
  );
}

function createBodaccService(relationship: RelationshipArg) {
  return CreateBodaccNode.parse(
    createBaseNode('bodacc', 'BODACC', relationship)
  );
}

function createKyckrService(relationship: RelationshipArg) {
  return CreateKyckrNode.parse(createBaseNode('kyckr', 'Kyckr', relationship));
}

function createVeriffService(relationship: RelationshipArg) {
  return CreateVeriffNode.parse(
    createBaseNode('veriff', 'Veriff', relationship)
  );
}

function createMitIdService(relationship: RelationshipArg) {
  return createMitIdNodeSchema.parse(
    createBaseNode('mitId', 'MitId', relationship)
  );
}

function createActionFormNode(relationship: RelationshipArg) {
  return CreateActionFormNode.parse({
    ...createBaseNode('actionForm', 'Action Form node', relationship),
    form: [],
  });
}

function createActionStepNode(relationship: RelationshipArg) {
  return createActionNodeSchema.parse({
    ...createBaseNode('action', 'Action node', relationship),
    actions: [],
  });
}

function createManualReviewNode(relationship: RelationshipArg) {
  return createManualReviewNodeSchema.parse({
    ...createBaseNode('manualReview', 'Manual Review node', relationship),
  });
}
