import { useMemo, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { produce } from 'immer';

import { updateFieldsFactory } from '@spektr/client/utils';

import { Button } from '@spektr/client/components';

import {
  SpektrFieldKey,
  SpektrField,
  TriggerFrequency,
  NodeUpdateInput,
  ServiceNode,
  MonitoringDatasetNode,
} from '@spektr/shared/types';
import { ClientTrigger } from '@spektr/client/types';

import { SERVICE_VARIANT_MAP } from '../../constants/services';

import { getServiceNodeDialogInformation } from '../../utils/getServiceNodeDialogInformation';
import { keepCommonField } from '../../utils/kyckr';

import { SectionList } from '../../components/SectionList';
import { SectionWithTitle } from '../../components/SectionWithTitle';
import { ServiceItemWithFrequency } from '../../components/ServiceItemWithFrequency';

import { ServiceFieldClient } from '../../types/ServiceFieldClient';

export type DatasetFieldsWithFrequencyListProps = {
  node: ServiceNode | MonitoringDatasetNode;
  processId: string;
  spektrFields: SpektrField[];
  triggers: ClientTrigger[];
  onSave: (data: NodeUpdateInput, triggers: ClientTrigger[]) => void;
};

export const DatasetFieldsWithFrequencyList = ({
  node,
  processId,
  spektrFields,
  triggers: propsTriggers,
  onSave,
}: DatasetFieldsWithFrequencyListProps) => {
  const { getValues, control } = useFormContext();
  const { isValid } = useFormState({
    control,
  });

  const [triggers, setTriggers] = useState(propsTriggers);

  const { categoriesTitle, categoriesHelp } = useMemo(
    () => getServiceNodeDialogInformation(node.nodeType, 'monitoring'),
    [node]
  );

  const updateFrequency = (
    node: ServiceNode | MonitoringDatasetNode,
    triggers: ClientTrigger[],
    frequency: TriggerFrequency | undefined,
    fieldKey: SpektrFieldKey,
    kyckrKey?: SpektrFieldKey
  ) => {
    const triggersWithField = triggers.filter((trigger) =>
      trigger.fields?.includes(fieldKey)
    );

    if (triggersWithField.length > 0) {
      triggersWithField.forEach((currentTrigger) => {
        if (currentTrigger && currentTrigger.frequency !== frequency) {
          currentTrigger.fields = currentTrigger.fields?.filter(
            (currentField) => {
              const isFound =
                currentField === fieldKey ||
                currentField.startsWith(`${fieldKey}_fields.`);

              if (isFound) {
                return keepCommonField(
                  fieldKey,
                  currentTrigger,
                  fields,
                  kyckrKey
                );
              }

              return !isFound;
            }
          );
        }
      });
    }

    if (!frequency) {
      return;
    }

    const currentFrequencyTrigger = triggers.find(
      (trigger) => trigger.frequency === frequency
    );

    const fieldsWithProperties = fields
      .map((field) => field.key)
      .filter((key) => key.startsWith(`${fieldKey}_fields.`));

    if (currentFrequencyTrigger) {
      const isFieldAlreadyAdded =
        currentFrequencyTrigger.fields?.includes(fieldKey);

      if (!isFieldAlreadyAdded) {
        currentFrequencyTrigger.fields?.push(...fieldsWithProperties, fieldKey);
      }
    } else {
      const isFieldAlreadyAdded = triggers.some(
        (trigger) =>
          trigger.frequency === frequency && trigger.fields?.includes(fieldKey)
      );

      if (!isFieldAlreadyAdded) {
        triggers.push({
          nodeId: node.id,
          processId,
          frequency,
          fields: [...fieldsWithProperties, fieldKey],
        });
      }
    }

    return triggers;
  };

  const handleChangeFrequency = (
    fieldKey: SpektrFieldKey,
    frequency: TriggerFrequency | undefined
  ) => {
    setTriggers(
      produce(triggers, (draft) => {
        updateFrequency(node, draft, frequency, fieldKey);
      })
    );
  };

  const handleSave = () => {
    const mapping = getValues();

    const fieldsMap = fields.reduce<Record<string, boolean>>((acc, field) => {
      const hasFrequency = !!triggers.find((trigger) =>
        trigger.fields?.includes(field.key)
      );

      acc[field.key] = hasFrequency;

      return acc;
    }, {});

    const a = updateFieldsFactory(node, fieldsMap, mapping);

    onSave(a, triggers);
  };

  const getTriggerFrequency = (fieldKey: SpektrFieldKey) => {
    const trigger = triggers.find((trigger) =>
      trigger.fields?.includes(fieldKey)
    );

    return trigger?.frequency;
  };

  const datasetIdentifiers = spektrFields;

  const fields = datasetIdentifiers.map((i) => {
    return {
      key: i.key,
      help: '',
      label: i.label,
    } as ServiceFieldClient;
  });

  const renderItem = (field: ServiceFieldClient) => {
    return (
      <ServiceItemWithFrequency
        key={field.key}
        field={field}
        frequency={getTriggerFrequency(field.key)}
        onChangeFrequency={handleChangeFrequency}
      />
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <SectionWithTitle title={categoriesTitle} additionalInfo={categoriesHelp}>
        {fields.length > 0 ? (
          <SectionList className="max-h-[280px] overflow-y-auto" data={fields}>
            {renderItem}
          </SectionList>
        ) : (
          <span className="text-color-text-primary">No dataset fields</span>
        )}
      </SectionWithTitle>

      <Button
        className="ml-auto"
        disabled={!isValid}
        color={SERVICE_VARIANT_MAP[node.nodeType]}
        onClick={handleSave}
      >
        Save changes
      </Button>
    </div>
  );
};
