import { useMemo } from 'react';

import { Select } from '@spektr/client/components';

import { useIncompleteRuleGroup } from '../providers';

export const SelectSpektrfieldOperator = (props: { ruleId: string }) => {
  const { getPredicate, setPredicate, getPossibleOperatorsForRule } =
    useIncompleteRuleGroup();

  const predicate = getPredicate(props.ruleId);
  const operators = getPossibleOperatorsForRule(props.ruleId);
  const options = useMemo(
    () =>
      operators.map(({ label }) => ({
        value: label,
        label,
      })),
    [operators]
  );

  if (!predicate) return null;

  const operator = operators.find(
    (operator) => operator.key === predicate.operator
  );

  return (
    <Select
      key={predicate.operator}
      value={operator?.label}
      required
      onValueChange={(spektrFieldOperatorLabel) => {
        const operator = operators.find(
          (operator) => operator.label === spektrFieldOperatorLabel
        );
        if (!operator) return;

        const changes = {
          operator: operator.key,
          type: operator.type,
          right: operator.right ?? undefined,
        };
        setPredicate(props.ruleId, changes);
      }}
      size="small"
      selectTriggerProps={{
        className: 'bg-color-bg-accent min-w-[40px] justify-center',
        showChevron: false,
        'data-testid': `select-spektrfield-operator-${props.ruleId}`,
      }}
      options={options}
      disabled={predicate.left === undefined}
    />
  );
};
