import { BookUp, HardDriveDownload } from 'lucide-react';

import { Tabbar, TabbarItem } from '@spektr/client/components';

import {
  CONNECTION_HUB_DATA_SOURCES_FULL_URL,
  CONNECTION_HUB_SERVICE_CATALOG_FULL_URL,
} from '@spektr/shared/utils';

export const ConnectionHubTabNavigation = () => {
  return (
    <div className="mb-4">
      <Tabbar>
        <TabbarItem
          icon={<HardDriveDownload className="h-4 w-4" />}
          to={{ pathname: CONNECTION_HUB_DATA_SOURCES_FULL_URL }}
        >
          Data sources
        </TabbarItem>
        <TabbarItem
          icon={<BookUp className="h-4 w-4" />}
          to={{ pathname: CONNECTION_HUB_SERVICE_CATALOG_FULL_URL }}
        >
          Service catalog
        </TabbarItem>
      </Tabbar>
    </div>
  );
};
