import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import {
  processBuilderUrl,
  processDetailsParamsSchema,
} from '@spektr/shared/utils';

import { getProcessByIdQuery } from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';

import { ProcessDrawer } from '@spektr/client/components';

import { useServices, useSteps, useActions } from './config';

import { useCreateNodeFactory } from './hooks/useCreateNodeFactory';

import { DrawerListItem } from './components/DrawerListItem';

import { DrawerItemType } from './types/DrawerItemType';

const PROCESS_TABS = [
  {
    id: 'steps',
    label: 'Steps',
  },
  {
    id: 'services',
    label: 'Services',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];

export const ProcessSheet = () => {
  const navigate = useNavigate();
  const createNode = useCreateNodeFactory();
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const { data: process } = useQuery(getProcessByIdQuery(processId));
  const services = useServices(process?.type);
  const steps = useSteps(process?.type);
  const actions = useActions(process?.type);

  const handleClose = () => {
    navigate(processBuilderUrl(processId));
  };

  const handleClick = (item: DrawerItemType) => {
    createNode(item.type);
  };

  const renderTabContent = (tabId: string) => {
    let items: DrawerItemType[] = [];

    if (tabId === 'steps') {
      items = steps;
    }

    if (tabId === 'services' && process) {
      items = services;
    }

    if (tabId === 'actions') {
      items = actions;
    }

    return <DrawerListItem items={items} onClick={handleClick} />;
  };

  return (
    <ProcessDrawer
      defaultTab="steps"
      description="The catalog shows all steps and services that can be added to a process. Build process logic by adding and configuring steps and services."
      renderTabContent={renderTabContent}
      tabs={PROCESS_TABS}
      title="Process steps and services"
      onClose={handleClose}
    />
  );
};
