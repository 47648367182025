import { createContext } from 'react';

import { ActionFieldSchema } from '@spektr/shared/validators';

export type ContextType = {
  getSelectedFields: (
    processId: string,
    nodeId: string
  ) => ActionFieldSchema | null;
  onUpdateField: (
    processId: string,
    nodeId: string,
    fields: Record<string, boolean>
  ) => void;
};

export const SelectedFieldsContext = createContext<ContextType>({
  getSelectedFields: () => null,
  onUpdateField: () => null,
});
