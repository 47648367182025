import { Field, isWritableField } from '@spektr/moonraker-types';
import { SpektrField } from '@spektr/shared/types';

export const getCustomSpektrFields = (
  fields: Field[],
  spektrFields: SpektrField[]
) => {
  const customFields: string[] = [];

  Object.values(fields).forEach((field) => {
    if (isWritableField(field)) {
      const spektrDataField = field.config.spektrDataField;

      if (
        spektrDataField &&
        spektrFields.findIndex(
          (spektrField) => spektrField.key === spektrDataField
        ) === -1
      ) {
        customFields.push(spektrDataField);
      }
    }
  });

  return customFields;
};
