import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

export type StatusProps = VariantProps<typeof variants> & {
  label: string;
};

export const Status = ({ color, label }: StatusProps) => (
  <span
    className={cn(
      'text-[10px] font-semibold dark:font-medium',
      variants({ color })
    )}
  >
    {label}
  </span>
);

const variants = cva(['py-1 px-2', 'rounded-[55px]'], {
  variants: {
    color: {
      success:
        'dark:bg-color-secondary-green/10 bg-color-secondary-green/20 dark:text-color-secondary-green text-color-green',
      error: 'dark:bg-color-red/10 bg-color-red/20 text-color-red',
      warning: 'dark:bg-color-yellow/10 bg-color-yellow/20 text-color-yellow',
      default:
        'dark:bg-color-bg-primary/10 bg-color-bg-primary/20 text-color-text-primary',
    },
  },
  defaultVariants: {
    color: 'default',
  },
});
