import { RouteObject } from 'react-router-dom';

import {
  LOOPS_NEW_URL,
  LOOPS_NODE_DETAILS_URL,
  LOOPS_SCHEDULE_URL,
  LOOPS_SOURCE_URL,
  LOOPS_URL,
  PROCESS_DETAILS_PARAM,
  SHEET_URL,
  STRATEGY_URL,
  FIELD_MAPPING_URL,
} from '@spektr/shared/utils';

import {
  LoopSheet,
  LoopProcessDetailsHeader,
  LoopScheduleDialog,
  LoopActionsHeader,
} from '@spektr/model-builder/containers';

import { LoopsIndexPage } from './IndexPage';
import { LoopsDashboardPage } from './LoopsDashboard';
import { CreateLoopDialogPage } from './CreateLoopDialog';
import { UpdateLoopSourceDialogPage } from './UpdateLoopSourceDialog';
import { LoopNodeDialog } from './LoopNodeDialog';
import { LoopDetailsPage } from './LoopDetailsPage';
import { LoopStrategyDialog } from './LoopStrategyDialog';
import { LoopFieldMappingDialog } from './LoopFieldMappingDialog';

export const loopsRoutes: RouteObject = {
  path: LOOPS_URL,
  element: <LoopsIndexPage />,
  handle: {
    title: 'Loops',
  },
  children: [
    {
      index: true,
      element: <LoopsDashboardPage />,
    },
    {
      path: LOOPS_NEW_URL,
      element: <CreateLoopDialogPage />,
    },
    {
      path: PROCESS_DETAILS_PARAM,
      element: <LoopDetailsPage />,
      handle: {
        backTo: {
          url: LOOPS_URL,
          label: 'Loops',
        },
        renderLeft: () => <LoopProcessDetailsHeader />,
        renderRight: () => (
          <LoopActionsHeader publicPageUrl={window._env.PUBLIC_PAGE_URL} />
        ),
      },
      children: [
        {
          path: LOOPS_SCHEDULE_URL,
          element: <LoopScheduleDialog />,
        },
        {
          path: LOOPS_SOURCE_URL,
          element: <UpdateLoopSourceDialogPage />,
        },
        {
          path: SHEET_URL,
          element: <LoopSheet />,
        },
        {
          path: LOOPS_NODE_DETAILS_URL,
          element: <LoopNodeDialog />,
        },
        {
          path: STRATEGY_URL,
          element: <LoopStrategyDialog />,
        },
        {
          path: FIELD_MAPPING_URL,
          element: <LoopFieldMappingDialog />,
        },
      ],
    },
  ],
};
