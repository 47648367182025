import { forwardRef } from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

export type DropAreaProps = {
  isActive?: boolean;
  canDrop?: boolean;
  canvasLength?: number;
};

export const DropArea = forwardRef<HTMLDivElement, DropAreaProps>(
  ({ isActive = false, canDrop = false, canvasLength = 0 }, ref) => {
    const colors = {
      'bg-color-green/[.02]': !isActive && canDrop,
      'bg-color-green/10': isActive,
    };
    const message = isActive
      ? 'Release to drop'
      : 'Drag your first field on to this form';

    if (canvasLength === 0) {
      return (
        <div ref={ref} className={cn('h-48', colors, boxVariants())}>
          <span>{message}</span>
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={cn(
          'h-0',
          'overflow-hidden opacity-0',
          { ...colors, 'opacity-100': canDrop, 'h-9': canDrop },
          boxVariants()
        )}
      >
        <span>{message}</span>
      </div>
    );
  }
);

const boxVariants = cva([
  'flex w-full items-center justify-center',
  'rounded border border-dashed border-color-green',
  'text-center text-xs font-medium text-color-green',
  'transition-all duration-300',
]);
