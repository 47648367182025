import { Process } from '@spektr/shared/types';

/**
 * @description Channel settings should be set if loop has at least
 * one form or mitId or veriff node.
 * @param process - loop process
 */
export const shouldLoopHaveChannelSettings = (process: Process) => {
  for (const node of process.nodes.values()) {
    if (['actionForm', 'mitId', 'veriff'].includes(node.nodeType)) {
      return true;
    }
  }

  return false;
};
