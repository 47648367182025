import { cva } from 'class-variance-authority';
import { icons } from 'lucide-react';

import { cn } from '@spektr/client/utils';
import { ProcessType } from '@spektr/shared/validators';

import { BoxIcon } from '../BoxIcon';

export type ProcessIconProps = {
  className?: string;
  iconClassName?: string;
  boxIcon?: boolean;
  processType?: ProcessType;
};

export const ProcessIcon = ({
  className,
  iconClassName,
  boxIcon,
  processType = 'risk',
}: ProcessIconProps) => {
  const isLoopType = processType === 'loop';
  const colorVariant = isLoopType ? 'red' : 'cyan';

  const Icon = isLoopType ? icons['RefreshCw'] : icons['LayoutGrid'];

  if (!boxIcon) {
    return (
      <Icon
        className={cn(
          variants({
            stroke: colorVariant,
            color: 'transparent',
          }),
          'h-3.5 w-3.5 shrink-0',
          iconClassName
        )}
      />
    );
  }

  return (
    <BoxIcon
      className={cn(
        variants({
          color: colorVariant,
          stroke: colorVariant,
          className,
        })
      )}
      renderIcon={({ className }) => (
        <Icon
          className={cn(
            'h-2.5 w-2.5 shrink-0 stroke-inherit',
            className,
            iconClassName
          )}
        />
      )}
    />
  );
};

const variants = cva('', {
  variants: {
    color: {
      red: 'bg-color-red/20',
      cyan: 'bg-color-cyan/20',
      transparent: 'bg-transparent',
    },
    stroke: {
      cyan: 'stroke-color-cyan',
      red: 'stroke-color-red',
    },
  },
  defaultVariants: {
    color: 'cyan',
    stroke: 'cyan',
  },
});
