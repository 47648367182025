import { Skeleton } from '@spektr/client/components';

export const ProcessesListSkeleton = () => {
  return (
    <div className="mt-6 flex flex-col gap-2">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className="flex items-center gap-3 rounded-md border px-4 py-2"
        >
          <Skeleton className="h-4 w-4 rounded-full" />
          <Skeleton className="w-1/3" />
          <Skeleton className="ml-auto w-1/4" />
        </div>
      ))}
    </div>
  );
};
