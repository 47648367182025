import { SpektrFieldWithInfo } from '../../goldenRecord';

export const veriffSpektrFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> =
  [
    {
      key: 'veriff.verification.status',
      type: 'string',
      source: 'veriff',
      label: 'Status',
    },
    {
      key: 'veriff.verification.reason',
      type: 'string',
      source: 'veriff',
      label: 'Reason',
    },
    {
      key: 'veriff.person.firstName',
      type: 'string',
      source: 'veriff',
      label: 'First name',
    },
    {
      key: 'veriff.person.lastName',
      type: 'string',
      source: 'veriff',
      label: 'Last name',
    },
    {
      key: 'veriff.person.dateOfBirth',
      type: 'date',
      source: 'veriff',
      label: 'Date of birth',
    },
    {
      key: 'veriff.person.gender',
      type: 'string',
      source: 'veriff',
      label: 'Gender',
    },
    {
      key: 'veriff.person.nationality',
      type: 'string',
      source: 'veriff',
      label: 'Nationality',
    },
    {
      key: 'veriff.person.idNumber',
      type: 'string',
      source: 'veriff',
      label: 'ID Number',
    },
    {
      key: 'veriff.person.yearOfBirth',
      type: 'string',
      source: 'veriff',
      label: 'Year of Birth',
    },
    {
      key: 'veriff.person.fullName',
      type: 'string',
      source: 'veriff',
      label: 'Full Name',
    },
    {
      key: 'veriff.person.occupation',
      type: 'string',
      source: 'veriff',
      label: 'Occupation',
    },
    {
      key: 'veriff.person.employer',
      type: 'string',
      source: 'veriff',
      label: 'Employer',
    },
    {
      key: 'veriff.document.number',
      type: 'string',
      source: 'veriff',
      label: 'Document Number',
    },
    {
      key: 'veriff.document.validFrom',
      type: 'date',
      source: 'veriff',
      label: 'Valid From',
    },
    {
      key: 'veriff.document.validUntil',
      type: 'date',
      source: 'veriff',
      label: 'Valid Until',
    },
    {
      key: 'veriff.document.type',
      type: 'string',
      source: 'veriff',
      label: 'Document Type',
    },
    {
      key: 'veriff.document.country',
      type: 'string',
      source: 'veriff',
      label: 'Document Country',
    },
    {
      key: 'veriff.document.firstIssue',
      type: 'date',
      source: 'veriff',
      label: 'First Issue Date',
    },
    {
      key: 'veriff.document.nfcValidated',
      type: 'boolean',
      source: 'veriff',
      label: 'NFC Validated',
    },
    {
      key: 'veriff.additionalVerifiedData.driverLicenseNumber',
      type: 'string',
      source: 'veriff',
      label: 'Driver License Number',
    },
    {
      key: 'veriff.additionalVerifiedData.driversLicenseCategoryUntil.B',
      type: 'date',
      source: 'veriff',
      label: 'Drivers License Category Until B',
    },
  ];
