import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/client/utils';

import { RBAC } from '@spektr/shared/rbac';
import { NodeUpdateInput, SpektrField } from '@spektr/shared/types';

import {
  NodeDetailsDialogTitle,
  RiskMatrixSegment,
  TitleInput,
} from '@spektr/shared/components';

import {
  Button,
  CalculatorIcon,
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogHeader,
  IconWithBackground,
} from '@spektr/client/components';

import { AddMatrix } from './add-matrix';
import { AddSegmentButton } from './buttons';
import { useIncompleteCalculationNode } from './providers';
import { MatrixSegmentContainer, SegmentContainer } from './segment';

export type CalculationNodeFormDialogContentProps = DialogContentProps & {
  spektrFields: SpektrField[];
  onUpdate: (data: NodeUpdateInput) => void;
  isPendingUpdate: boolean;
};

export const CalculationNodeFormDialogContent = ({
  className,
  spektrFields,
  requestStartExitAnimation,
  onUpdate,
  isPendingUpdate,
  ...props
}: CalculationNodeFormDialogContentProps) => {
  const { incomplete, calculationNode, setTitle, processType } =
    useIncompleteCalculationNode();

  const { hasPermission } = usePermissionsContext();

  const isRiskProcess = ['risk', 'score'].includes(processType);

  return (
    <DialogContent
      modal={false}
      size="large"
      className={cn('flex min-h-fit flex-col', className)}
      requestStartExitAnimation={requestStartExitAnimation}
      {...props}
    >
      <DialogHeader className="space-y-0">
        <NodeDetailsDialogTitle
          left={
            <div className="flex items-center gap-2">
              <IconWithBackground className="bg-color-cyan p-1">
                <CalculatorIcon className="stroke-color-stroke-white h-4 w-4" />
              </IconWithBackground>
              <TitleInput title={incomplete.title} onChange={setTitle} />
            </div>
          }
          right={
            <div className="flex items-center gap-2">
              <Button
                color="cyan"
                type="button"
                size="sm"
                disabled={
                  !calculationNode.success ||
                  isPendingUpdate ||
                  !hasPermission(RBAC.ACTIONS.NODE.UPDATE)
                }
                onClick={() => {
                  if (!calculationNode.success) return;

                  onUpdate(calculationNode.data);
                }}
              >
                {isPendingUpdate ? 'Saving...' : 'Save'}
              </Button>
              <DialogClose onClick={requestStartExitAnimation} />
            </div>
          }
        />
      </DialogHeader>
      <div className="-mr-3 flex flex-col gap-6 overflow-y-auto">
        {incomplete.segments.map((segment) => (
          <div className="mr-3" key={segment.clientSideOnlyId}>
            {(segment as RiskMatrixSegment).riskMatrixId ? (
              <MatrixSegmentContainer
                segment={segment as RiskMatrixSegment}
                spektrFields={spektrFields}
              />
            ) : (
              <SegmentContainer segment={segment} spektrFields={spektrFields} />
            )}
          </div>
        ))}

        <div className="mr-3">
          <div
            className={cn(
              'flex items-center gap-4',
              !isRiskProcess && 'justify-center'
            )}
          >
            <AddSegmentButton className="basis-1/2" />
            {isRiskProcess && <AddMatrix className="basis-1/2" />}
          </div>
        </div>
      </div>
    </DialogContent>
  );
};
