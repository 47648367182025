import { Action, HitBaseWithId } from '@spektr/shared/validators';

export const computeExtendedHits = (
  hits: HitBaseWithId[],
  nodeType: string,
  action: Action
) =>
  hits.map((hit) => ({
    ...hit,
    nodeType,
    nodeId: action.nodeId,
    processId: action.processId,
    spektrId: action.spektrId,
    datasetId: action.datasetId,
  }));
