import { z } from 'zod';

import { objectIdSchema } from '../common';
import { unixTimestampSchema } from '../utils';

export const triggerFrequency = ['daily', 'weekly', 'monthly'] as const;
export const triggerFrequencySchema = z.enum(triggerFrequency);

const triggerCoreSchema = z.strictObject({
  processId: objectIdSchema,
  nodeId: objectIdSchema.optional(),
  fields: z.array(z.string()).optional(),
});

export const triggerCreateInputSchema = triggerCoreSchema
  .merge(
    z.strictObject({
      frequency: triggerFrequencySchema,
    })
  )
  .refine(
    (data) => {
      // Ensure nodeId and fields are both set together or both omitted
      return (
        (data.nodeId !== undefined && data.fields !== undefined) ||
        (data.nodeId === undefined && data.fields === undefined)
      );
    },
    {
      message: 'nodeId and fields must be set together or both omitted',
    }
  );

export const triggerUpdateInputSchema = z.strictObject({
  frequency: triggerFrequencySchema,
  fields: z.array(z.string()).optional(),
});

export const triggerBaseSchema = triggerCoreSchema.merge(
  z.strictObject({
    cronFrequency: z.string(),
    nextTriggerTimestamp: unixTimestampSchema,
  })
);

export const triggerResponseSchema = triggerCoreSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    frequency: triggerFrequencySchema,
  })
);

export type ProcessTrigger = z.infer<typeof triggerResponseSchema>;
