import { localStorageUtils } from '../localStorage';

export function getAccessToken(clientId: string) {
  const lastAuthUserId = localStorageUtils.getItem(
    `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
  );

  if (!lastAuthUserId) {
    console.error(new Error('No last auth user found'));
  }

  const accessToken = localStorageUtils.getItem(
    `CognitoIdentityServiceProvider.${clientId}.${lastAuthUserId}.idToken`
  );

  return accessToken;
}
