import { ReactNode } from 'react';

export type HitsLayoutProps = {
  children: ReactNode;
};

export const HitsLayout = ({ children }: HitsLayoutProps) => {
  return (
    <div className="relative flex h-full w-full flex-col gap-2">{children}</div>
  );
};
