/* eslint-disable react/jsx-no-useless-fragment */
import { JSXElementConstructor, ReactNode } from 'react';

export type WrapperProps = {
  shouldWrap: boolean;
  WrapIn: JSXElementConstructor<Children>;
  children: ReactNode | ReactNode[];
};
type Children = { children: ReactNode | ReactNode[] };

export const Wrapper = ({ shouldWrap, WrapIn, children }: WrapperProps) => {
  const childrenEl = Array.isArray(children) ? children : [children];

  if (shouldWrap) {
    return <WrapIn>{childrenEl}</WrapIn>;
  }

  return <>{childrenEl}</>;
};
