import { cn } from '@spektr/client/utils';

import { Button, ButtonProps } from '@spektr/client/components';
import { Trash2 } from 'lucide-react';

type NodeDeleteButtonProps = ButtonProps & {
  iconClassName?: string;
};

export const NodeDeleteButton = ({
  className,
  iconClassName,
  ...props
}: NodeDeleteButtonProps) => {
  return (
    <div
      className={cn(
        'absolute right-0',
        'flex items-center justify-center',
        'opacity-0 group-hover:opacity-100',
        'translate-x-full transform transition-opacity'
      )}
    >
      <Button
        className={cn(
          'bg-transparent hover:bg-transparent active:bg-transparent',
          'text-color-text-subtext hover:text-color-red disabled:text-color-text-subtext',
          'cursor-pointer disabled:cursor-not-allowed',
          'disabled:opacity-50',
          'transition-colors',
          'p-2',
          className
        )}
        {...props}
      >
        <Trash2 className={cn('h-5 w-5', iconClassName)} />
      </Button>
    </div>
  );
};
