import { Dataset } from '@spektr/shared/types';
import { DatasetRecordSchema } from '@spektr/shared/validators';

export function getRecordIdentifierValue(
  data: DatasetRecordSchema,
  dataset: Dataset
) {
  if (!dataset?.fieldId) {
    throw new Error('Dataset identifier is missing');
  }

  return String(data[dataset.fieldId]);
}
