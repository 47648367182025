import { useSuspenseQuery } from '@tanstack/react-query';

import { processNodeParamsSchema } from '@spektr/shared/utils';

import {
  getNodeByIdQuery,
  getAllowedSpektrFieldsQuery,
  getProcessByIdQuery,
} from '@spektr/client/services';

import { useParsedParams, useUpdateNode } from '@spektr/shared/hooks';

import { NodeDetailsFactoryDialog } from '@spektr/model-builder/containers';

import { NodeUpdateInput } from '@spektr/shared/types';

export const NodeDetails = ({ onClose }: { onClose: () => void }) => {
  const { processId, nodeId } = useParsedParams(processNodeParamsSchema);

  const updateMutation = useUpdateNode(processId, onClose);
  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: node } = useSuspenseQuery(getNodeByIdQuery(processId, nodeId));
  const { data: allowedSpektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, nodeId)
  );

  if (!node || !process) {
    return null;
  }

  const handleUpdateNode = (data: NodeUpdateInput) => {
    updateMutation.mutateAsync({
      nodeId: node.id,
      node: data,
    });
  };

  return (
    <NodeDetailsFactoryDialog
      allowedSpektrFields={allowedSpektrFields ?? []}
      process={process}
      node={node}
      onClose={onClose}
      onUpdate={handleUpdateNode}
      isPendingUpdate={updateMutation.isPending}
    />
  );
};
