import { useSuspenseQuery } from '@tanstack/react-query';

import { getRiskMatrixQuery } from '@spektr/client/services';

import { RiskMatrixDialogContent } from '../../components';
import { extractData, RiskMatrixData } from '../../../RiskMatrix';

type UpdateRiskMatrixContainerProps = {
  id: string;
  handleUpdate: (name: string, data: RiskMatrixData) => void;
  isUpdating: boolean;
};

export const UpdateRiskMatrixContainer = ({
  id,
  handleUpdate,
  isUpdating,
}: UpdateRiskMatrixContainerProps) => {
  const { data } = useSuspenseQuery(getRiskMatrixQuery(id));

  return (
    <RiskMatrixDialogContent
      data={extractData(data)}
      title={data?.title}
      onSubmit={handleUpdate}
      isSubmitting={isUpdating}
      isUpdate={true}
    />
  );
};
