import { Switch, SwitchProps } from '@spektr/client/components';

import { HelperTextTooltip } from '../../HelperTextTooltip';

export type SwitchFieldProps = SwitchProps & {
  label: string;
  helperText?: string;
};

export const SwitchField = ({
  label,
  helperText,
  ...props
}: SwitchFieldProps) => {
  return (
    <div className="flex flex-row items-center justify-between px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={props.name}
        >
          {label}
        </label>
        {helperText && <HelperTextTooltip helperText={helperText} />}
      </div>
      <Switch {...props} />
    </div>
  );
};
