import { createContext, useContext, useEffect, useState } from 'react';

import { GraphView } from '../types';

export type StrategyProviderContextType = {
  processId: string | undefined;
  selectedNode: string | undefined;
  activeView: GraphView;
  isStrategyPage?: boolean;
  setSelectedNode: (nodeId: string | undefined) => void;
};

const StrategyProviderContext = createContext({
  processId: undefined,
  selectedNode: undefined,
  activeView: 'map',
  setSelectedNode: () => null,
} as StrategyProviderContextType);

export const useStrategyProviderContext = () =>
  useContext(StrategyProviderContext);

type StrategyProviderProps = {
  defaultSelected: string | undefined;
  activeView: GraphView;
  isStrategyPage?: boolean;
  children: React.ReactNode;
};

export const StrategyProvider = ({
  defaultSelected,
  activeView,
  isStrategyPage,
  children,
}: StrategyProviderProps) => {
  const [selectedNode, setSelectedNode] = useState(defaultSelected);

  useEffect(() => {
    setSelectedNode(defaultSelected);
  }, [activeView, defaultSelected]);

  return (
    <StrategyProviderContext.Provider
      value={{
        processId: defaultSelected,
        selectedNode,
        activeView,
        setSelectedNode,
        isStrategyPage,
      }}
    >
      {children}
    </StrategyProviderContext.Provider>
  );
};
