import { ReactNode, createContext, useContext, useState } from 'react';

import { updateMonitoringDatasetNodeSchema } from '@spektr/shared/validators';
import { IncompleteMonitoringDataset } from '@spektr/shared/components';

export type IncompleteMonitoringDatasetNodeContextType = {
  incomplete: IncompleteMonitoringDataset;
  monitoringDatasetNode: ReturnType<
    (typeof updateMonitoringDatasetNodeSchema)['safeParse']
  >;
  setTitle: (title: string) => void;
};

export const useIncompleteMonitoringDatasetNode = () =>
  useContext(IncompleteMonitoringDatasetNodeContext);

const IncompleteMonitoringDatasetNodeContext = createContext(
  {} as IncompleteMonitoringDatasetNodeContextType
);

export type MonitoringDatasetNodeProviderProps = {
  initial: IncompleteMonitoringDataset;
};

export const MonitoringDatasetNodeProvider = ({
  initial,
  children,
}: MonitoringDatasetNodeProviderProps & { children: ReactNode }) => {
  const [incomplete, setIncomplete] = useState(initial);

  const monitoringDatasetNode =
    updateMonitoringDatasetNodeSchema.safeParse(incomplete);

  const setTitle = (title: string) =>
    setIncomplete((current) => ({ ...current, title }));

  return (
    <IncompleteMonitoringDatasetNodeContext.Provider
      value={{
        incomplete,
        monitoringDatasetNode,
        setTitle,
      }}
    >
      {children}
    </IncompleteMonitoringDatasetNodeContext.Provider>
  );
};
