import { z } from 'zod';

export const widgetFieldType = z.enum(['dataset', 'custom']);
export type WidgetFieldType = z.infer<typeof widgetFieldType>;

export function isValidWidgetFieldType(
  value: string
): value is WidgetFieldType {
  return value === 'dataset' || value === 'custom';
}
