import { Button } from '@spektr/client/components';

type FooterActionsProps = {
  isConnectDisabled: boolean;
  onClose: () => void;
  handleConnect?: () => void;
};

export const FooterActions = ({
  isConnectDisabled,
  onClose,
  handleConnect,
}: FooterActionsProps) => {
  return (
    <div className="ml-auto flex items-center gap-4">
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button color="cyan" disabled={isConnectDisabled} onClick={handleConnect}>
        Connect
      </Button>
    </div>
  );
};
