import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useState,
} from 'react';
import { VariantProps } from 'class-variance-authority';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { cn } from '@spektr/client/utils';

import { useTheme } from '@spektr/client/providers';

import { Wrapper } from '../../Wrapper';

import { DialogOverlay, NonModalDialogOverlay } from './DialogOverlay';
import { dialogContentVariants } from './variants';

export type DialogContentProps = ComponentPropsWithoutRef<
  typeof DialogPrimitive.Content
> &
  VariantProps<typeof dialogContentVariants> & {
    asChild?: boolean;
    modal?: boolean;
    requestStartExitAnimation?: () => void;
    onEndExitAnimation?: () => void;
  };

export const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(
  (
    {
      className,
      size,
      onAnimationStartCapture,
      onAnimationEndCapture,
      children,
      modal = true,
      requestStartExitAnimation,
      onEndExitAnimation,
      ...props
    },
    ref
  ) => {
    const [dataState, setDataState] = useState('open');
    const { theme } = useTheme();

    return (
      <Wrapper shouldWrap={modal} WrapIn={DialogPrimitive.Portal}>
        {modal ? (
          <DialogOverlay onClick={requestStartExitAnimation} />
        ) : (
          <NonModalDialogOverlay
            data-state={dataState}
            onClick={requestStartExitAnimation}
          />
        )}
        <DialogPrimitive.Content
          ref={ref}
          className={cn(
            dialogContentVariants({ size, theme, className }),
            'bg-color-bg-dialog-default'
          )}
          onAnimationStartCapture={(event) => {
            setDataState(event.animationName === 'exit' ? 'closed' : 'open');
            onAnimationStartCapture?.(event);
          }}
          onAnimationEndCapture={(event) => {
            if (event.animationName === 'exit') onEndExitAnimation?.();
            onAnimationEndCapture?.(event);
          }}
          {...props}
        >
          {children}
        </DialogPrimitive.Content>
      </Wrapper>
    );
  }
);
