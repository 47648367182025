import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { PLATFORM_ASIDE_NAV_WIDTH } from '@spektr/client/constants';
import { insightsActionsParamsSchema } from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';

import { SpektrErrorBoundary } from '@spektr/client/components';

import { DETAILS_WIDTH } from './constants/layout';

import { ActionPageHeader } from './containers/ActionPageHeader';
import { ActionPageSidebar } from './containers/ActionPageSidebar';

import { SkeletonActionDetailsSidebar } from './components/SkeletonActionDetailsSidebar';
import { SkeletonActionPageHeader } from './components/SkeletonActionPageHeader';
import { ActionDetailsError } from './components/ActionDetailsError';

export const InsightsActionDetails = () => {
  const { actionId } = useParsedParams(insightsActionsParamsSchema);

  const { width } = useWindowSize();

  const maxContainerWidth = width - PLATFORM_ASIDE_NAV_WIDTH - DETAILS_WIDTH;

  return (
    <div className="absolute bottom-0 top-0 flex w-full overflow-hidden">
      <SpektrErrorBoundary fallback={<ActionDetailsError />}>
        <div
          className="flex h-full w-full flex-col px-6 pt-6"
          style={{
            maxWidth: maxContainerWidth,
          }}
        >
          <Suspense fallback={<SkeletonActionPageHeader />}>
            <ActionPageHeader />
          </Suspense>

          <Outlet />
        </div>
        <div
          className="h-full shrink-0 border-l"
          style={{ width: DETAILS_WIDTH }}
        >
          <Suspense fallback={<SkeletonActionDetailsSidebar />}>
            <ActionPageSidebar actionId={actionId} />
          </Suspense>
        </div>
      </SpektrErrorBoundary>
    </div>
  );
};
