import {
  InsightsActionsDashboard,
  InsightsNavigation,
} from '@spektr/model-builder/containers';
import { InsightsRootLayout } from '@spektr/client/components';

export const ActionsPage = () => {
  return (
    <InsightsRootLayout>
      <InsightsNavigation />
      <InsightsActionsDashboard />
    </InsightsRootLayout>
  );
};
