import { InsightsRootLayout } from '@spektr/client/components';
import {
  InsightsNavigation,
  InsightsRecordsDashboard,
} from '@spektr/model-builder/containers';

export const RecordsByDataset = () => (
  <InsightsRootLayout>
    <InsightsNavigation />
    <InsightsRecordsDashboard />
  </InsightsRootLayout>
);
