import { useNavigate } from 'react-router-dom';

import { useParsedParams } from '@spektr/shared/hooks';
import { loopDetailsUrl, processNodeParamsSchema } from '@spektr/shared/utils';

import { NodeDetailsPreloader } from '../../components';

import { LoopNodeDetails } from './LoopNodeDetails';

export const LoopNodeDialog = () => {
  const navigate = useNavigate();
  const { processId } = useParsedParams(processNodeParamsSchema);

  const handleClose = () => {
    navigate(loopDetailsUrl(processId));
  };

  return (
    <NodeDetailsPreloader onClose={handleClose}>
      <LoopNodeDetails onClose={handleClose} />
    </NodeDetailsPreloader>
  );
};
