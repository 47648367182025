import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getVersionOverviewByProcessId } from '@spektr/client/services';

import { Label, Textarea } from '@spektr/client/components';

import { useProcessScheduleContext } from '../../providers';

export type ProcessLaunchOnboardingProps = {
  processId: string;
  publicPageUrl: string;
  workspaceId?: string;
  isLiveActionPending?: boolean;
};

export const ProcessLaunchOnboarding = ({
  processId,
  publicPageUrl,
  workspaceId,
  isLiveActionPending = false,
}: ProcessLaunchOnboardingProps) => {
  const { isOnboardingProcess } = useProcessScheduleContext();

  const { data: overview } = useSuspenseQuery(
    getVersionOverviewByProcessId(processId)
  );

  const versions = useMemo(() => {
    if (overview.versions.length === 0) {
      return [];
    }

    return overview.versions;
  }, [overview.versions]);

  if (!isOnboardingProcess || versions.length === 0) {
    return null;
  }

  if (isLiveActionPending || !workspaceId) {
    return null;
  }
  // afraid ill brake something if i dont do this
  const baseUrl = publicPageUrl.replace('form', '');

  return (
    <div className="mb-4 flex flex-col gap-2">
      <Label htmlFor="launch-process-link" className="text-color-text-subtext">
        Launch process link
      </Label>
      <Textarea
        autoFocus
        readOnly
        id="launch-process-link"
        defaultValue={`${baseUrl}process?processId=${processId}&workspaceId=${workspaceId}`}
      />
    </div>
  );
};
