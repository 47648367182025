import { RichTextEditor, RichTextEditorProps } from '@spektr/client/components';

import { HelperTextTooltip } from '../../HelperTextTooltip';

export type RichTextEditorFieldProps = RichTextEditorProps & {
  name: string;
  label: string;
  helperText?: string;
};

export const RichTextEditorField = ({
  label,
  helperText,
  ...props
}: RichTextEditorFieldProps) => {
  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={props.name}
        >
          {label}
        </label>
        {helperText && <HelperTextTooltip helperText={helperText} />}
      </div>
      <RichTextEditor
        floatingToolbar={false}
        className="h-28 text-xs"
        formats={['bold', 'italic', 'underline', 'link']}
        {...props}
      />
    </div>
  );
};
