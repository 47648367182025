import { useCallback } from 'react';
import { format } from 'date-fns';
import { Info } from 'lucide-react';

import { Process } from '@spektr/shared/types';
import {
  ProcessIcon,
  Status,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from '@spektr/client/components';
import { ExecutionContextWithDatasetId } from '@spektr/shared/validators';
import { FULL_DATE_TIME_FORMAT } from '@spektr/client/constants';
import {
  getStatusColor,
  getStatusText,
  isExecutionSuccessful,
} from '@spektr/client/utils';

export type RecordExecutionsTableProps = {
  executions: ExecutionContextWithDatasetId[];
  processes: Process[];
};

export const RecordExecutionsTable = ({
  executions,
  processes,
}: RecordExecutionsTableProps) => {
  const renderRow = useCallback(
    (row: ExecutionContextWithDatasetId) => {
      const currentProcess = processes.find(
        (process) => process.id === row.processId
      );

      return (
        <TableRow
          key={row.id}
          className="hover:bg-color-bg-table-row--hover h-[64px] border-b"
        >
          <TableCell className="p-4">
            <div className="flex items-center gap-3">
              <ProcessIcon processType={currentProcess?.type} />
              <p className="text-color-text-primary text-sm font-medium">
                {row.process.name}
              </p>
            </div>
          </TableCell>
          <TableCell className="p-4">
            <p className="text-color-text-subtext text-xs font-medium">
              {format(row.createdAt, FULL_DATE_TIME_FORMAT)}
            </p>
          </TableCell>
          <TableCell className="p-4">
            {isExecutionSuccessful(row) ? (
              <p className="text-color-text-primary text-xs font-medium">
                {row.process.output === 'unknown'
                  ? 'Finished'
                  : row.process.output}
              </p>
            ) : undefined}
          </TableCell>
          <TableCell className="p-4">
            <Status color={getStatusColor(row)} label={getStatusText(row)} />
          </TableCell>
        </TableRow>
      );
    },
    [processes]
  );

  return (
    <Table className="border-collapse">
      <TableHeader>
        <TableRow className="h-[42px] hover:bg-inherit">
          <TableHead className="text-color-text-table-header w-4/12 px-4 text-xs">
            Name
          </TableHead>
          <TableHead className="text-color-text-table-header px-4 text-xs">
            Run started at
          </TableHead>
          <TableHead className="text-color-text-table-header flex items-center gap-1.5 px-4 text-xs">
            Output
            <Tooltip
              content="Last branch"
              side="right"
              align="end"
              alignOffset={80}
              sideOffset={-10}
              contentClassName="bg-color-bg-tooltip mb-1"
            >
              <Info className="h-3 w-3" />
            </Tooltip>
          </TableHead>
          <TableHead className="text-color-text-table-header px-4 text-xs">
            Status
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>{executions.map(renderRow)}</TableBody>
    </Table>
  );
};
