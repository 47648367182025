import { Suspense } from 'react';

import { Tag } from 'lucide-react';

import { useGoToConnectionHub } from '@spektr/shared/hooks';

import { BasicDialog, SpektrErrorBoundary } from '@spektr/client/components';

import { DatasetDialogContent } from './containers/DatasetDialogContent';

import { DatasetError } from './components/DatasetError';
import { SkeletonFieldsDataTable } from './components/SkeletonFieldsDataTable';

export const ExtractDatasetDialog = () => {
  const goToConnectionHub = useGoToConnectionHub();

  const handleClose = () => goToConnectionHub();

  return (
    <BasicDialog
      description={
        <span>
          <span className="text-color-text-primary">
            Extract the right data
          </span>{' '}
          - select the attributes needed for your process, select the correct
          data type (critical for building rules) and mark which ones (max. two)
          are the key identifiers of your records.
        </span>
      }
      icon={<Tag className="stroke-color-yellow mr-2 h-5 w-5" />}
      defaultOpen
      size="large"
      title="Extract dataset"
      onClose={handleClose}
    >
      <SpektrErrorBoundary fallbackRender={() => <DatasetError />}>
        <Suspense fallback={<SkeletonFieldsDataTable />}>
          <DatasetDialogContent />
        </Suspense>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
