import { createContext } from 'react';

import { DEFAULT_STATE } from '../../constants/engine';

import type { FormEngineApi } from './types';

export const FormEngineContext = createContext<FormEngineApi>({
  state: DEFAULT_STATE,
  spektrFields: [],
  currencyList: [],
  getCurrentField: () => null,
  dropField: () => null,
  selectFieldId: () => null,
  cloneField: () => null,
  deleteField: () => null,
  updateField: () => null,
  saveForm: () => null,
  reorderFields: () => null,
  updateAssistingContent: () => null,
  updateBranding: () => null,
});
