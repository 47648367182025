import { ExecutionContextSchema } from '@spektr/shared/validators';

export function isExecutionFinished(execution: ExecutionContextSchema) {
  return (
    execution.status === 'completed' ||
    execution.status === 'failed' ||
    execution.status === 'timeout'
  );
}

export function isExecutionSuccessful(execution: ExecutionContextSchema) {
  return execution.status === 'completed';
}
