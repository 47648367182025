import { cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

export const selectVariants = cva('', {
  variants: {
    size: {
      default: 'px-3 py-2 h-9 text-sm',
      small: 'px-2 py-1 h-7 text-xs',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export const triggerVariants = cva('', {
  variants: {
    inverted: {
      true: cn(
        'border rounded-md',
        'border-color-border-select-inverted',
        'bg-color-bg-select-inverted-trigger',
        'text-color-text-select-inverted-trigger',
        'ring-offset-background',
        'placeholder:text-color-text-placeholder',
        'disabled:text-color-text-select-inverted-trigger--disabled disabled:opacity-50'
      ),
      false: cn(
        'border rounded-md',
        'border-color-border-select',
        'bg-color-bg-select-trigger',
        'text-color-text-select-trigger',
        'ring-offset-background',
        'placeholder:text-color-text-placeholder',
        'disabled:bg-color-bg-select-trigger--disabled disabled:text-color-text-select-trigger--disabled disabled:opacity-50'
      ),
    },
  },
  defaultVariants: {
    inverted: true,
  },
});

export const triggerContentVariants = cva('', {
  variants: {
    inverted: {
      true: cn(
        'dark:border-color-border-secondary border-color-border-primary',
        'bg-color-bg-select-inverted-content',
        'text-color-text-select-inverted-content'
      ),
      false: cn(
        'dark:border-color-border-primary border-color-border-secondary',
        'bg-color-bg-select-content',
        'text-color-text-select-content'
      ),
    },
  },
  defaultVariants: {
    inverted: true,
  },
});

export const triggerItemVariants = cva('', {
  variants: {
    inverted: {
      true: cn(
        'focus:bg-color-bg-select-inverted-item--focus focus:text-color-text-select-inverted-item--focus'
      ),
      false: cn(
        'focus:bg-color-bg-select-item--focus focus:text-color-text-select-item--focus'
      ),
    },
  },
  defaultVariants: {
    inverted: true,
  },
});
