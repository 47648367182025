//TODO:  [ST-1237] Label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and retrieved by the field key
import { SpektrFieldWithInfo } from '@spektr/shared/types';

const calculatedFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> = [
  {
    key: 'is_sold',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is sold or transferred',
    help: 'Get information about the sale of the company or how its ownership got transferred.',
  },

  {
    key: 'is_registered',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is registered',
    help: 'Get information about the registration of the company.',
  },
  {
    key: 'has_branch',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company has branch',
    help: 'Get information about the branches of the company.',
  },
  {
    key: 'has_changes',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company has changes',
    help: 'Get information about the changes in the company.',
  },
  {
    key: 'is_deregistered',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is deregistered',
    help: 'Get information about the deregistration of the company.',
  },
  {
    key: 'in_collective_proceedings',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is in collective proceedings',
    help: 'Get information about the potential bankruptcy processes the company has been involved in.',
  },
  {
    key: 'in_conciliation_proceedings',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is in conciliation proceedings',
    help: 'Get information about the agreement reached between the company facing financial difficulties and its creditors.',
  },
];

const isSoldFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> = [
  {
    key: 'is_sold_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'is_sold_fields.dateparution',
    help: 'Date of publication.',
  },
  {
    key: 'is_sold_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'is_sold_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  {
    key: 'is_sold_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'is_sold_fields.commercant',
    help: 'Name of the merchant or individual involved.',
  },
  {
    key: 'is_sold_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'is_sold_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
];

const isRegisteredFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> = [
  {
    key: 'is_registered_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'is_registered_fields.dateparution',
    help: 'Date of publication',
  },
  {
    key: 'is_registered_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'is_registered_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  {
    key: 'is_registered_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'is_registered_fields.commercant',
    help: 'Name of the merchant or individual involved.',
  },
  {
    key: 'is_registered_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'is_registered_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
];

const hasBranchFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> = [
  {
    key: 'has_branch_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'has_branch_fields.dateparution',
    help: 'Date of publication.',
  },
  {
    key: 'has_branch_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'has_branch_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  {
    key: 'has_branch_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'has_branch_fields.commercant',
    help: 'Name of the merchant or individual involved.',
  },
  {
    key: 'has_branch_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'has_branch_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
];

const hasChangesFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> = [
  {
    key: 'has_changes_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.dateparution',
    help: 'Date of publication.',
  },
  {
    key: 'has_changes_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  {
    key: 'has_changes_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.commercant',
    help: 'Name of the merchant or individual involved.',
  },
  {
    key: 'has_changes_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
  {
    key: 'has_changes_fields.modificationsgenerales.descriptif',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.modificationsgenerales.descriptif',
    help: 'Description of the general modifications.',
  },
];

const isDeregisteredFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> = [
  {
    key: 'is_deregistered_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'is_deregistered_fields.dateparution',
    help: 'Date of publication.',
  },
  {
    key: 'is_deregistered_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'is_deregistered_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  {
    key: 'is_deregistered_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'is_deregistered_fields.commercant',
    help: 'Name of the merchant or individual involved.',
  },
  {
    key: 'is_deregistered_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'is_deregistered_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
];

const inCollectiveProceedingsFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'in_collective_proceedings_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'in_collective_proceedings_fields.dateparution',
    help: 'Date of publication.',
  },
  {
    key: 'in_collective_proceedings_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'in_collective_proceedings_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  {
    key: 'in_collective_proceedings_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'in_collective_proceedings_fields.commercant',
    help: 'Name of the merchant or individual involved.',
  },
  {
    key: 'in_collective_proceedings_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'in_collective_proceedings_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
];

const inConciliationProceedingsFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'in_conciliation_proceedings_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'in_conciliation_proceedings_fields.dateparution',
    help: 'Date of publication.',
  },
  {
    key: 'in_conciliation_proceedings_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'in_conciliation_proceedings_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  {
    key: 'in_conciliation_proceedings_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'in_conciliation_proceedings_fields.commercant',
    help: 'Name of the merchant or individual involved.',
  },
  {
    key: 'in_conciliation_proceedings_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'in_conciliation_proceedings_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
];

export const bodaccSpektrFields = [
  ...calculatedFields,
  ...isSoldFields,
  ...isRegisteredFields,
  ...hasBranchFields,
  ...hasChangesFields,
  ...isDeregisteredFields,
  ...inCollectiveProceedingsFields,
  ...inConciliationProceedingsFields,
];
