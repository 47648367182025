import { cn } from '@spektr/client/utils';

type ResponsiveVideoContainerProps = {
  className?: string;
  children: React.ReactNode;
};

export const ResponsiveVideoContainer = ({
  className,
  children,
  ...props
}: ResponsiveVideoContainerProps) => {
  return (
    <div className={cn('relative h-0 w-full pb-[52%]', className)} {...props}>
      {children}
    </div>
  );
};
