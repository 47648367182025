import { useUpdateCurrentRoute } from '@spektr/client/providers';

export const DemoBanner = () => {
  const updateState = useUpdateCurrentRoute();

  return (
    <div className="mx-auto flex items-center gap-1 ">
      <span className="font-medium">
        You are on a demo workspace. Do you want your own?
      </span>
      <button
        className="flex items-center justify-center gap-2"
        onClick={() =>
          updateState({
            state: {
              show_book_meeting: true,
            },
          })
        }
      >
        <span className="text-color-text-white font-semibold underline">
          Talk with Erik
        </span>
        <div className="bg-avatar-erik h-6 w-6 bg-cover bg-center" />
      </button>
    </div>
  );
};
