import { ReactNode } from 'react';
import { useWindowSize } from 'react-use';

import { useBannerNotifications } from '@spektr/client/providers';

type DashboardLayoutProps = {
  children: ReactNode;
  sidebar: ReactNode;
};

export const DashboardLayout = ({
  children,
  sidebar,
}: DashboardLayoutProps) => {
  const { height } = useWindowSize();
  const { notifications } = useBannerNotifications();

  const maxHeight = height - notifications.length * 44;

  return (
    <main
      className="flex grow flex-row"
      style={{
        height: maxHeight,
      }}
    >
      {sidebar}
      <section className="flex grow flex-col">{children}</section>
    </main>
  );
};
