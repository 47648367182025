import { cn } from '@spektr/client/utils';

export type PageIntroProps = {
  title: string;
  description: string;
  children: React.ReactNode;
  renderIcon: (className: string) => React.ReactNode;
};

export const PageIntro = ({
  title,
  description,
  renderIcon,
  children,
}: PageIntroProps) => {
  return (
    <div
      className={cn(
        'flex flex-wrap justify-between gap-6 p-4',
        'border-color-border-card rounded-md border',
        'bg-color-bg-card-default'
      )}
    >
      <div className="flex flex-col gap-4">
        {renderIcon(cn('h-11 w-11', 'p-3'))}
        <div className={cn('flex flex-col gap-1', 'max-w-[270px]')}>
          <h3 className="text-lg font-medium">{title}</h3>
          <p className="text-color-text-subtext text-sm">{description}</p>
        </div>
      </div>
      <div className={cn('flex flex-row flex-wrap items-center gap-4')}>
        {children}
      </div>
    </div>
  );
};
