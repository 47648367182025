import { cn } from '@spektr/client/utils';

import { SkeletonHitCard } from './SkeletonHitCard';

export const SkeletonHitsList = () => (
  <div className="relative h-full w-full">
    <div
      className={cn(
        'relative',
        'bg-color-bg-skeleton-card',
        'grid grid-cols-1 gap-6 py-6 2xl:grid-cols-2 min-[2160px]:grid-cols-3',
        'mr-2 mt-5'
      )}
    >
      <SkeletonHitCard />
      <SkeletonHitCard />
      <SkeletonHitCard />
      <SkeletonHitCard />
    </div>
  </div>
);
