import { Suspense } from 'react';

import { ActionHitsList } from '../../containers/ActionHitsList';
import { SkeletonHitsList } from '../../components/SkeletonHitsList';

export const ActionPageDetails = () => {
  return (
    <Suspense fallback={<SkeletonHitsList />}>
      <ActionHitsList />
    </Suspense>
  );
};
