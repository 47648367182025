import { z } from 'zod';

export const SpektrFieldPrimitive = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.null(),
]);
export type SpektrFieldPrimitive = z.infer<typeof SpektrFieldPrimitive>;

export const SpektrFieldType = z.enum([
  'string',
  'number',
  'date',
  'country',
  'boolean',
  'file',
  'matrix',
]);
export type SpektrFieldType = z.infer<typeof SpektrFieldType>;

export const SpektrFieldTypedKey = z.strictObject({
  key: z.string(),
  type: SpektrFieldType,
});
export type SpektrFieldTypedKey = z.infer<typeof SpektrFieldTypedKey>;

export const SpektrData = z.record(
  z.string(),
  SpektrFieldPrimitive.or(z.undefined())
);
export type SpektrData = z.infer<typeof SpektrData>;
