import { cn } from '@spektr/client/utils';

export const creditSafeContained = cn(
  'bg-color-creditSafe',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-creditSafe',
  'active:bg-color-creditSafe'
);
export const creditSafeOutlined = cn(
  creditSafeContained,
  'border-color-creditSafe',
  'text-color-creditSafe',
  'hover:bg-color-creditSafe/10',
  'active:bg-color-creditSafe/20',
  'disabled:border-color-creditSafe/50'
);
export const creditSafeText = cn('text-color-creditSafe');
