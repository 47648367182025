import { useMemo } from 'react';

import { SpektrFieldTypedKey } from '@spektr/shared/validators';
import { uniqueByProperty } from '@spektr/shared/utils';

import {
  useIncompleteCalculationNode,
  useIncompleteRouterNode,
  useIncompleteRuleGroup,
} from '../providers';
import { extractLeftOperandSpektrfieldsFromRule } from '../utils';
import { IncompleteRuleGroup } from '../tree-helpers';

export const useNodeCustomFields = (): SpektrFieldTypedKey[] => {
  const { incomplete: incompleteCalculation } = useIncompleteCalculationNode();
  const { incomplete: incompleteRouter } = useIncompleteRouterNode();
  const { spektrFields } = useIncompleteRuleGroup();

  const groups = useMemo(() => {
    if (incompleteCalculation) {
      return incompleteCalculation.segments.reduce((acc, segment) => {
        return [...acc, ...segment.groups];
      }, [] as IncompleteRuleGroup[]);
    }
    if (incompleteRouter) {
      return incompleteRouter.groups;
    }
    return [];
  }, [incompleteCalculation, incompleteRouter]);

  const customFields = useMemo(() => {
    const customs = groups
      .map((group) => extractLeftOperandSpektrfieldsFromRule(group.rule))
      .flat();

    return customs.filter(
      (custom) => {
        const spektrField = spektrFields.find(
          (spektrField) => spektrField.key === custom.key
        );
        return !spektrField;
      },
      [spektrFields]
    );
  }, [groups]);

  return uniqueByProperty(customFields, 'key');
};
