import { z } from 'zod';

import { unixTimestampSchema } from '../utils';
import { objectIdSchema } from '../common';

export const liveProcessVersionSchema = z.strictObject({
  id: objectIdSchema,
  processId: objectIdSchema,
  versionId: objectIdSchema,
  createdAt: unixTimestampSchema,
  updatedAt: unixTimestampSchema,
});
