import { createContext } from 'react';

import { UserRoles } from '@spektr/shared/validators';

import { RBAC } from '@spektr/shared/rbac';

type PermissionsProviderContextType = {
  hasPermission: (action: string) => boolean;
};

const PermissionsContext = createContext<PermissionsProviderContextType>({
  hasPermission: () => false,
});

type PermissionsProviderProps = {
  children: React.ReactNode;
  userRole?: UserRoles;
};

const PermissionsProvider = ({
  userRole,
  children,
}: PermissionsProviderProps) => {
  const hasPermission = (action: string): boolean => {
    if (!userRole) {
      return false;
    }

    return RBAC.hasPermission(userRole, action);
  };

  return (
    <PermissionsContext.Provider value={{ hasPermission }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export { PermissionsContext, PermissionsProvider };
