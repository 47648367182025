import { ButtonHTMLAttributes, forwardRef } from 'react';
import { VariantProps } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

import { buttonVariants } from './variants';

import { BaseVariantProps } from './types/BaseVariantProps';

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  BaseVariantProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      className,
      variant: variantProps = 'contained',
      color = 'primary',
      size = 'default',
      ...props
    },
    ref
  ) => {
    const variant = `${variantProps}-${color}`;

    return (
      <button
        {...props}
        ref={ref}
        className={cn(
          buttonVariants({
            variant: variant as VariantProps<typeof buttonVariants>['variant'],
            size,
            className,
          })
        )}
      >
        {children}
      </button>
    );
  }
);
