import { Suspense } from 'react';

import { CASES_URL, getAppEnv } from '@spektr/shared/utils';

import { BadgeNotifications } from '@spektr/client/providers';
import { AppNav, SpektrErrorBoundary } from '@spektr/client/components';

import { AppNavItem } from '@spektr/client/types';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { withNotifications } from './components/withNotifications';

export type AppSidebarProps = {
  navItems: AppNavItem[];
};

const AppNavWithNotifications = withNotifications(AppNav);

export const AppSidebar = ({ navItems }: AppSidebarProps) => {
  const { customersListView } = useFeatureFlags();

  const items =
    getAppEnv() === 'production'
      ? navItems.filter((item) => item.released)
      : customersListView
        ? navItems
        : navItems.filter((item) => item.link !== CASES_URL);

  return (
    <SpektrErrorBoundary fallbackRender={() => <AppNav items={items} />}>
      <Suspense fallback={<AppNav items={items} />}>
        <BadgeNotifications>
          <AppNavWithNotifications items={items} />
        </BadgeNotifications>
      </Suspense>
    </SpektrErrorBoundary>
  );
};
