import { useIncompleteSegment } from '../providers';

import { TitleInput } from './title-input';

export const SegmentTitleInput = () => {
  const { segment, updateSegment } = useIncompleteSegment();

  return (
    <TitleInput
      title={segment?.title ?? ''}
      onChange={(title) => updateSegment({ title: title.trimStart() })}
    />
  );
};
