import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  CommentMessageBlock,
  CommentParentType,
} from '@spektr/shared/validators';

import { COMMENTS_KEYS, CommentsApiClient } from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';

export const useAddComment = (
  parentType: CommentParentType,
  parentId: string,
  handleSuccess: () => void
) => {
  const queryClient = useQueryClient();
  const addComment = useMutation({
    mutationFn: (message: CommentMessageBlock[]) =>
      CommentsApiClient.getClient().createComment({
        parentType,
        parentId,
        message,
      }),
    onSuccess: async () => {
      await invalidateMultipleQueries(queryClient, [
        { queryKey: COMMENTS_KEYS.ALL({ parentId, parentType }) },
        { queryKey: COMMENTS_KEYS.COUNT_BY_PARENT_ID(parentId, parentType) },
      ]);
      handleSuccess();
    },
  });

  return addComment;
};
