import { z } from 'zod';
import { makeApi } from '@zodios/core';

import {
  deleteFileSchema,
  fileResponseSchema,
  fileUploadResponseSchema,
  fileUploadSchema,
} from './files';

export const ingestApi = makeApi([
  {
    method: 'post',
    path: '/files',
    alias: 'uploadFile',
    description: 'Upload a file',
    parameters: [
      {
        name: 'file',
        type: 'Body',
        schema: fileUploadSchema,
      },
    ],
    response: fileUploadResponseSchema,
  },
  {
    method: 'delete',
    path: '/files',
    alias: 'deleteFile',
    description: 'Delete a file by key',
    parameters: [
      {
        name: 'key',
        type: 'Body',
        schema: deleteFileSchema,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/files',
    alias: 'getFiles',
    description: 'Get all files',
    response: z.array(fileResponseSchema),
  },
]);
