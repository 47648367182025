import { useRef, ReactNode } from 'react';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { GripVertical } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import {
  isEntitiesField,
  isWritableField,
  type Field,
} from '@spektr/moonraker-types';

import { Toolbar } from './components/Toolbar';

type ReorderableField = {
  id: string;
  index: number;
};

export type CanvasFieldProps = {
  children: ReactNode;
  field: Field;
  index: number;
  isSelected?: boolean;
  level?: number;
  onClone: () => void;
  onClick: () => void;
  onDelete: () => void;
  onReorder: (sourceIndex: number, destinationIndex: number) => void;
  onDoubleClick?: () => void;
};

export const CanvasField = ({
  children,
  field,
  index,
  isSelected,
  level = 1,
  onClone,
  onClick,
  onDelete,
  onReorder,
  onDoubleClick,
}: CanvasFieldProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ opacity }, drag] = useDrag({
    type: 'form-field',
    item: () => {
      return {
        id: field.id,
        index,
      };
    },
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });
  const [_, drop] = useDrop<ReorderableField, void>({
    accept: 'form-field',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onReorder(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      className={cn('relative', 'w-full', 'group', 'transition-colors')}
      ref={level === 1 ? ref : null}
      style={{ opacity }}
    >
      <div
        className={cn(
          'absolute',
          '-left-8 top-1/2 -translate-y-1/2',
          'transition-opacity',
          'opacity-0',
          'cursor-grab',
          {
            'opacity-100': isSelected && level === 1,
            'group-hover:opacity-100': level === 1,
          }
        )}
      >
        <GripVertical className="text-color-text-icon-secondary h-4 w-4" />
      </div>
      <button
        className={cn(
          'flex flex-col gap-2',
          'w-full px-6 py-3',
          'text-left',
          'hover:bg-color-bg-button-secondary/40',
          {
            'pr-0': isEntitiesField(field),
          }
        )}
        onClick={onClick}
        onDoubleClickCapture={onDoubleClick}
      >
        {isWritableField(field) && (
          <span
            className={cn('text-color-text-subtext', 'text-sm font-semibold')}
          >
            {field.attributes.label}
          </span>
        )}
        {children}
      </button>
      <div
        className={cn(
          'absolute',
          'flex flex-row gap-1.5',
          'h-full w-9',
          '-right-9 top-1/2 -translate-y-1/2',
          'transition-opacity',
          'opacity-0',
          {
            'z-10': isSelected && level === 1,
            'z-20': isSelected && level === 2,
            'opacity-100': isSelected,
          }
        )}
      >
        <div className="bg-color-cyan h-full w-0.5 shrink-0" />
        <Toolbar onClone={onClone} onDelete={onDelete} />
      </div>
    </div>
  );
};
