import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { getAllActions, getProcessesQuery } from '@spektr/client/services';

import { PaginationCombobox } from '@spektr/client/components';

import { aggregatedActionData } from '../../utils/aggregateActionData';

import { useActionsFilters } from '../../hooks/useActionsFilters';

import { ActionsTable } from '../../components/ActionsTable/ActionsTable';
import { useExecutionsWithDataset } from '../../hooks/useExecutionsWithDataset';

const allowedPageValues = [10, 20, 50];

const pageLimitSchema = z
  .number()
  .refine((value) => allowedPageValues.includes(value), {
    message: 'Value must be 10, 20, or 50',
  })
  .default(10);

export const ActionsDataTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { stateFilter } = useActionsFilters();
  const pageLimit = pageLimitSchema.parse(
    Number(searchParams.get('limit') ?? '10')
  );
  const page = Number(searchParams.get('page') ?? '1');

  const { data: actions } = useSuspenseQuery(
    getAllActions({ page, limit: pageLimit, status: stateFilter })
  );
  const { data: processes } = useSuspenseQuery(getProcessesQuery());

  const contextsToBeFetched = useMemo(
    () => actions.docs.map((action) => action.contextId),
    [actions]
  );
  const executions = useExecutionsWithDataset(contextsToBeFetched);

  const handleChangeLimit = (limit: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
      limit: limit.toString(),
    });
  };

  const handleChangePage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    });
  };

  return (
    <div className="flex h-full flex-col items-end gap-4">
      <ActionsTable
        actions={aggregatedActionData(actions.docs, executions, processes)}
      />
      <PaginationCombobox
        currentPage={actions.page}
        totalPages={actions.totalPages}
        pageLimit={actions.limit}
        onChangeLimit={handleChangeLimit}
        onChangePage={handleChangePage}
      />
    </div>
  );
};
