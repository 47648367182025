import { Suspense } from 'react';

import { SkeletonHitComments } from '../../components/SkeletonHitComments';
import { HitComments } from '../../containers/HitComments';

export const HitDetailsComments = () => {
  return (
    <Suspense fallback={<SkeletonHitComments />}>
      <HitComments />
    </Suspense>
  );
};
