import { cn } from '@spektr/client/utils';

import { Skeleton } from '@spektr/client/components';

import { SkeletonTable } from '../SkeletonTable';

export const SkeletonHitCard = () => (
  <div className={cn('w-full rounded-md', 'px-6', 'bg-color-bg-skeleton-card')}>
    <div className={cn('flex flex-row', 'py-6')}>
      <Skeleton className="h-6 w-[160px]" />

      <div className={cn('flex flex-row gap-4', 'ml-auto')}>
        <Skeleton className="h-5 w-[100px]" />
      </div>
    </div>
    <SkeletonTable />
    <div className={cn('flex grow flex-row', 'pb-5 pt-4')}>
      <div className="ml-auto flex flex-row gap-4">
        <Skeleton className="h-9 w-24" />
        <Skeleton className="h-9 w-24" />
      </div>
    </div>
  </div>
);
