import { z } from 'zod';

import { objectIdSchema } from '../common';
import { PaginationFields } from '../pagination';

export const recordFields = z.record(z.string(), z.unknown());

export const datasetRecordSchema = recordFields.refine(
  (obj): obj is Record<string, unknown> & { spektrId: string } => {
    if (!obj['spektrId']) return false;
    const evaluation = objectIdSchema.safeParse(obj['spektrId']);

    return evaluation.success;
  },
  {
    message: 'Record must have a valid spektrId.',
  }
);
export type DatasetRecordSchema = z.infer<typeof datasetRecordSchema>;

export const datasetRecordResponsePaginatedSchema = PaginationFields.merge(
  z.strictObject({
    docs: z.array(datasetRecordSchema),
  })
);

export type DatasetRecordsResponsePaginated = z.infer<
  typeof datasetRecordResponsePaginatedSchema
>;
