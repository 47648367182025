import { Skeleton, Tabbar } from '@spektr/client/components';

export const SkeletonActionPageHeader = () => (
  <div className="flex flex-col gap-4">
    <Skeleton className="h-6 w-[310px]" />
    <Tabbar>
      <Skeleton className="mr-4 w-40" />
      <Skeleton className="mr-4 w-40" />
      <Skeleton className="w-40" />
    </Tabbar>
  </div>
);
