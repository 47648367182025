import { z } from 'zod';
import { ActionFunctionArgs, redirect } from 'react-router-dom';

import { AuthError } from '@spektr/model-builder/auth';
import { formDataToRecord } from '@spektr/shared/utils';

const schema = z.object({
  oldPassword: z.string(),
  newPassword: z.string(),
});

export default async function changePasswordAction({
  request,
}: ActionFunctionArgs) {
  const formData = await request.formData();
  try {
    const { oldPassword, newPassword } = schema.parse(
      formDataToRecord(formData)
    );

    await import('../auth/authService').then((module) =>
      module.default.changePassword({ newPassword, oldPassword })
    );

    return redirect('/');
  } catch (error) {
    return { action: 'error', error: new AuthError(error) };
  }
}
