import { Avatar, SpektrDatasetIcon } from '@spektr/client/components';

export type SidebarDatasetDataProps = {
  datasetName: string;
  firstName: string;
  lastName: string;
};

export const SidebarDatasetData = ({
  datasetName,
  firstName,
  lastName,
}: SidebarDatasetDataProps) => {
  return (
    <div className="bg-color-bg-card-accent/40 grid auto-cols-min grid-cols-3 items-center gap-x-0 gap-y-4 rounded-md p-4">
      <p className="col-span-1 max-w-32 text-sm font-medium">Dataset</p>
      <div className="col-span-2 flex items-center gap-2">
        <SpektrDatasetIcon className="text-color-yellow h-4 w-4" />
        <p className="text-sm font-medium">{datasetName}</p>
      </div>

      {firstName !== '' && lastName !== '' && (
        <>
          <p className="col-span-1 max-w-32 text-sm font-medium">Added by</p>
          <div className="col-span-2 flex items-center gap-2">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="h-5 w-5"
              fallbackClass="text-[8px]"
            />
            <p className="text-sm font-medium">{firstName + ' ' + lastName}</p>
          </div>
        </>
      )}
    </div>
  );
};
