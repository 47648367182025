import { z } from 'zod';

import { objectIdSchema } from '../common';

import { processSchema } from './process';

export const versionSchema = z.strictObject({
  id: objectIdSchema,
  versionNumber: z.number(),
  publishedAt: z.number(),
  process: processSchema.optional(),
});

export const versionOverviewResponseSchema = z.strictObject({
  versions: z.array(versionSchema),
  latestVersionId: objectIdSchema.optional(),
  hasUnpublishedChanges: z.boolean(),
});
