import {
  isInputField,
  isFileField,
  isCheckboxField,
  isDividerField,
  isTitleField,
  isParagraphField,
  type Field,
  isDropdownField,
  CurrencyField,
} from '@spektr/moonraker-types';

import { CheckboxContent } from './components/CheckboxContent';
import { FileContent } from './components/FileContent';
import { InputContent } from './components/InputContent';
import { PhoneInputContent } from './components/PhoneInputContent';
import { DateInputContent } from './components/DateInputContent';

import { DropdownContent } from './components/DropdownContent';
import { TitleContent } from './components/TitleContent';
import { ParagraphContent } from './components/ParagraphContent';

export type BaseContentProps = {
  field: Field;
};

export const BaseContent = ({ field }: BaseContentProps) => {
  if (isInputField(field)) {
    switch (field.attributes.type) {
      case 'phone':
        return <PhoneInputContent field={field} />;
      case 'date':
        return <DateInputContent field={field} />;
      case 'currency':
        return (
          <InputContent
            field={field}
            before={(field as CurrencyField).attributes.currency?.symbol}
          />
        );
      case 'percentage':
        return <InputContent field={field} after="%" />;
      default:
        return <InputContent field={field} />;
    }
  }

  if (isCheckboxField(field)) {
    return (
      <CheckboxContent field={field} isSingleSelect={field.type === 'radio'} />
    );
  }

  if (isFileField(field)) {
    return <FileContent field={field} />;
  }

  if (isTitleField(field)) {
    return <TitleContent>{field.attributes.content}</TitleContent>;
  }

  if (isParagraphField(field)) {
    return <ParagraphContent>{field.attributes.content}</ParagraphContent>;
  }

  if (isDropdownField(field)) {
    return <DropdownContent field={field} />;
  }

  if (isDividerField(field)) {
    return <div className="w-full border-t" />;
  }

  return null;
};
