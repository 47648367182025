import { useState } from 'react';
import { Trash2 } from 'lucide-react';

import { Button, Input, TableRow } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

type RiskMatrixTableBodyRowProps = {
  row: string[];
  score: string;
  onRowUpdate: (index: number, value: string) => void;
  onScoreUpdate: (value: string) => void;
  onRowDelete: () => void;
  onUpdateAllScores: (value: string) => void;
};

export const RiskMatrixTableBodyRow = ({
  row,
  score,
  onRowUpdate,
  onScoreUpdate,
  onRowDelete,
  onUpdateAllScores,
}: RiskMatrixTableBodyRowProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TableRow
      className={cn(
        'h-[52px] border-t',
        isHovered && 'bg-color-bg-table-row--hover'
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {row.map((cell, index) => (
        <td key={index} className={cn('px-2 text-xs', index === 0 && 'pl-5')}>
          <Input
            className={cn(
              'bg-transparent',
              'text-ellipsis',
              !isHovered && 'border-transparent'
            )}
            dimension="small"
            value={row[index]}
            placeholder="Enter a value"
            onChange={(e) => onRowUpdate(index, e.target.value)}
          />
        </td>
      ))}
      <td>
        <div className="flex items-center justify-end pr-1">
          <Button
            tabIndex={isHovered && score ? 0 : -1}
            className={cn(
              isHovered && score ? 'opacity-100' : 'opacity-0',
              'px-2.5'
            )}
            color="secondary"
            variant="outlined"
            size="sm"
            onClick={() => onUpdateAllScores(score)}
          >{`Apply ${score} to all scores`}</Button>
        </div>
      </td>
      <td className={cn('w-12 text-xs')}>
        <Input
          type="number"
          dimension="small"
          onKeyDown={(e) => {
            if (e.key === '.' || e.key === 'e') e.preventDefault();
          }}
          className={cn(
            'h-7 bg-transparent',
            !isHovered && 'border-transparent'
          )}
          value={score}
          onChange={(e) => onScoreUpdate(e.target.value)}
        />
      </td>
      <td className="w-8">
        <div className="flex items-center justify-center">
          <button
            tabIndex={isHovered ? 0 : -1}
            className={cn(
              'text-color-text-subtext hover:text-color-red cursor-pointer transition-colors',
              'disabled:text-color-text-subtext disabled:cursor-not-allowed disabled:opacity-50',
              isHovered ? 'opacity-100' : 'opacity-0'
            )}
            onClick={onRowDelete}
          >
            <Trash2 className="h-3 w-3" />
          </button>
        </div>
      </td>
    </TableRow>
  );
};
