import { TextareaHTMLAttributes, forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  VariantProps<typeof textareaVariants> & {
    resizable?: boolean;
  };

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, inverted, resizable = false, ...props }, ref) => (
    <textarea
      className={cn(
        'flex min-h-[60px] w-full p-3',
        'rounded-md border',
        'text-xs font-medium',
        'focus-visible:outline-none focus-visible:ring-0',
        'disabled:cursor-not-allowed disabled:opacity-50',
        'resize-none',
        {
          resize: resizable,
        },
        textareaVariants({ inverted }),
        className
      )}
      ref={ref}
      {...props}
    />
  )
);

const textareaVariants = cva('', {
  variants: {
    inverted: {
      true: cn(
        'border-color-border-textarea-inverted',
        'bg-color-bg-secondary',
        'text-color-text-textarea-inverted',
        'placeholder:text-color-text-placeholder',
        'disabled:color-text-textarea-inverted--disabled disabled:bg-color-bg-accent',
        'focus-visible:ring-current'
      ),
      false: cn(
        'border-color-border-textarea',
        'bg-color-bg-primary',
        'text-color-text-textarea',
        'placeholder-color-text-placeholder',
        'disabled:text-color-text-textarea--disabled disabled:opacity-50',
        'focus-visible:ring-current'
      ),
    },
  },
  defaultVariants: {
    inverted: false,
  },
});
