import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { produce } from 'immer';
import { ListTodo } from 'lucide-react';

import { useDemo } from '@spektr/client/providers';

import {
  BasicDialog,
  ButtonWithTooltip,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { ActionFieldSchema } from '@spektr/shared/validators';
import {
  ActionNode as ActionNodeType,
  NodeUpdateInput,
  Process,
} from '@spektr/shared/types';

import { SelectedFieldsContext } from './contexts/SelectedFieldsContext';
import { ActionRowSkeleton } from './components/ActionRowSkeleton';
import { ActionFieldsList } from './containers/ActionFieldsList';

import { getActionRecordKey, getActionsAsRecord } from './utils';

export type ActionNodeDialogProps = {
  process: Process;
  node: ActionNodeType;
  onClose: () => void;
  onUpdate: (data: NodeUpdateInput) => void;
};

export const ActionNodeDialog = ({
  process,
  node,
  onClose,
  onUpdate,
}: ActionNodeDialogProps) => {
  const [selectedFieldActions, setSelectedFieldActions] = useState<
    Record<string, ActionFieldSchema>
  >(getActionsAsRecord(node));
  const { t } = useTranslation();
  const { isDemo } = useDemo();

  const handleUpdateFieldAction = (
    processId: string,
    nodeId: string,
    fields: Record<string, boolean>
  ) => {
    setSelectedFieldActions(
      produce((draft) => {
        const key = getActionRecordKey(processId, nodeId);

        if (Object.values(fields).every((value) => !value)) {
          delete draft[key];
        } else {
          draft[key] = {
            processId,
            nodeId,
            fields,
          };
        }
      })
    );
  };

  const handleClickSave = () => {
    onUpdate({
      nodeType: node.nodeType,
      title: node.title,
      actions: Object.values(selectedFieldActions),
    });
  };

  const getSelectedFields = (processId: string, nodeId: string) => {
    const key = getActionRecordKey(processId, nodeId);

    if (Object.keys(selectedFieldActions).includes(key)) {
      return selectedFieldActions[key];
    }

    return null;
  };

  return (
    <BasicDialog
      className="max-w-[700px]"
      defaultOpen
      title={node.title}
      description="Select which fields you want to manually take actions on."
      icon={<ListTodo className="text-color-text-icon-primary mr-3 h-5 w-5" />}
      renderTitleSlot={() => (
        <ButtonWithTooltip
          size="sm"
          color="red"
          disabled={isDemo}
          showTooltip={isDemo}
          tooltipProps={{
            content: t('errors.demoPlatform'),
          }}
          onClick={handleClickSave}
        >
          Save
        </ButtonWithTooltip>
      )}
      onClose={onClose}
    >
      <SelectedFieldsContext.Provider
        value={{
          getSelectedFields,
          onUpdateField: handleUpdateFieldAction,
        }}
      >
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <SpektrErrorBoundary
              onReset={reset}
              fallbackRender={() => (
                <div className="flex h-64 items-center justify-center">
                  <div className="text-center">
                    <h2 className="text-2xl font-semibold">
                      There was an error!
                    </h2>
                    <p className="text-color-text-error-boundry">
                      We couldn&apos;t load this node.
                    </p>
                  </div>
                </div>
              )}
            >
              <Suspense fallback={<ActionRowSkeleton />}>
                <ActionFieldsList process={process} node={node} />
              </Suspense>
            </SpektrErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </SelectedFieldsContext.Provider>
    </BasicDialog>
  );
};
