import { ReactNode } from 'react';

import { PageHeadline } from '../../PageHeadline';

export type InsightsRootLayoutProps = {
  children: ReactNode | ReactNode[];
  renderPopup?: () => ReactNode;
};

export const InsightsRootLayout = ({
  children,
  renderPopup,
}: InsightsRootLayoutProps) => {
  return (
    <div className="absolute inset-0">
      <div className="relative h-full w-full overflow-y-auto">
        <div className="box-border flex h-full flex-col px-5 pt-5">
          <div className="mb-5 flex shrink-0 flex-row items-center">
            <PageHeadline
              headline="Insight Engine"
              paragraph="Explore customers process results"
            />
          </div>
          {children}
        </div>
      </div>
      {renderPopup && renderPopup()}
    </div>
  );
};
