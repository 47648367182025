import { useLocation } from 'react-router-dom';

import { CreateRiskMatrixDialog, UploadCsvDialog } from './containers';

export const NewRiskMatrix = () => {
  const { state } = useLocation();
  const isCsvUpload = state?.fromCSV;

  return isCsvUpload ? <UploadCsvDialog /> : <CreateRiskMatrixDialog />;
};
