import z from 'zod';

export const PaginationFields = z.object({
  totalDocs: z.number().nonnegative(),
  totalPages: z.number().nonnegative(),
  limit: z.number().nonnegative(),
  page: z.number().nonnegative(),
  pagingCounter: z.number().nonnegative(),
  hasPrevPage: z.boolean(),
  hasNextPage: z.boolean(),
  prevPage: z.union([z.number().nonnegative(), z.null()]),
  nextPage: z.union([z.number().nonnegative(), z.null()]),
});

export const PaginationOptions = z.object({
  limit: z.coerce.number().nonnegative(),
  page: z.coerce.number().nonnegative(),
});

export type PaginationFields = z.infer<typeof PaginationFields>;
export type PaginationOptions = z.infer<typeof PaginationOptions>;
