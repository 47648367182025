import { SquarePen, Trash2 } from 'lucide-react';

import { DropdownOption } from '@spektr/client/components';

import { useDeleteHitComment } from './useDeleteHitComment';

const CommentActions: DropdownOption[] = [
  {
    type: 'item',
    label: 'Delete this comment',
    icon: <Trash2 className="h-3 w-3" />,
    value: 'delete',
    variant: 'danger',
  },
  {
    type: 'item',
    label: 'Edit comment',
    icon: <SquarePen className="h-3 w-3" />,
    value: 'edit',
  },
];

export const useCommentActions = (
  commentId: string,
  onEditCommentActionClick: () => void,
  onDeleteCommentActionClick: () => void,
  commentParentId?: string
) => {
  const deleteHitCommentMutation = useDeleteHitComment(commentParentId);

  const onActionClick = async (value: string) => {
    if (value === 'delete') {
      await deleteHitCommentMutation.mutateAsync(commentId);
      onDeleteCommentActionClick();
    } else if (value === 'edit') {
      onEditCommentActionClick();
    }
  };

  return {
    actions: CommentActions,
    onActionClick,
  };
};
