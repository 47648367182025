import { useParams } from 'react-router-dom';
import { ZodType, ZodTypeDef } from 'zod';

export function useParsedParams<
  Output = any,
  Def extends ZodTypeDef = ZodTypeDef,
  Input = Output,
>(schema: ZodType<Output, Def, Input>) {
  const params = useParams();
  return schema.parse(params);
}
