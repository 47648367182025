import { ReactNode, createContext, useContext, useMemo } from 'react';

import { StrictOmit } from '@spektr/shared/utils';
import { SpektrField } from '@spektr/shared/types';
import { NodeType } from '@spektr/shared/validators';

import {
  IncompleteRuleGroup,
  IncompleteSegment,
  makeIncompleteRuleGroup,
} from '../tree-helpers';

import { SegmentConfig, segmentConfigs } from './segment-config';

export type IncompleteSegmentContextType = {
  segment: IncompleteSegment | undefined;
  config: SegmentConfig;
  spektrFields: SpektrField[];
  updateSegment: (
    changes: Partial<StrictOmit<IncompleteSegment, 'id' | 'clientSideOnlyId'>>
  ) => void;
  removeSegment: () => void;
  updateRuleGroup: (id: string, updates: Partial<IncompleteRuleGroup>) => void;
  addRuleGroup: () => void;
  removeRuleGroup: (ruleGroupId: string) => void;
};

export const useIncompleteSegment = () => useContext(IncompleteSegmentContext);

const IncompleteSegmentContext = createContext(
  {} as IncompleteSegmentContextType
);

export type ProcessNodeSegmentProviderProps = {
  type: Extract<NodeType, 'calculation' | 'router' | 'filter'>;
  spektrFields: SpektrField[];
  segment?: IncompleteSegment;
  onChange?: (segment: IncompleteSegment | undefined) => void;
};

export const SegmentProvider = ({
  segment,
  type,
  spektrFields,
  children,
  onChange,
}: ProcessNodeSegmentProviderProps & { children: ReactNode }) => {
  const config = useMemo(() => segmentConfigs[type], [type]);

  const updateSegment = (
    props: Partial<StrictOmit<IncompleteSegment, 'id' | 'clientSideOnlyId'>>
  ) => {
    if (!segment) return;
    else onChange?.({ ...segment, ...props });
  };

  const removeSegment = () => onChange?.(undefined);

  const updateRuleGroup = (
    id: string,
    updates: Partial<IncompleteRuleGroup>
  ) => {
    if (!segment) return;

    const groups = segment.groups.map((group) => {
      if (group.clientSideOnlyId === id) return { ...group, ...updates };
      else return group;
    });

    onChange?.({ ...segment, groups });
  };

  const removeRuleGroup = (ruleGroupId: string) => {
    if (!segment) return;

    const groups = segment.groups.filter(
      (group) => group.clientSideOnlyId !== ruleGroupId
    );

    onChange?.({ ...segment, groups });
  };

  const addRuleGroup = () => {
    if (!segment) return;
    const next = makeIncompleteRuleGroup();

    onChange?.({ ...segment, groups: [...segment.groups, next] });
  };

  return (
    <IncompleteSegmentContext.Provider
      value={{
        segment,
        spektrFields,
        config,
        updateSegment,
        removeSegment,
        updateRuleGroup,
        removeRuleGroup,
        addRuleGroup,
      }}
    >
      {children}
    </IncompleteSegmentContext.Provider>
  );
};
