import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  ActionHit,
  HitDecisionStatus,
  UserSchema,
} from '@spektr/shared/validators';

import { getTeamMembersQuery } from '@spektr/client/services';

import { useUpdateAction } from '../hooks/useUpdateAction';
import { useActionIdParam } from '../hooks/useActionIdParam';
import { useUpdateHit } from '../hooks/useUpdateHit';

type HitsContextValue = {
  selectedHitsCount: number;
  selectHit: (hitId: string) => void;
  deselectHit: (hitId: string) => void;
  makeDecisionForHits: () => void;
  isHitSelected: (hitId: string) => boolean;
  isPendingUpdate: boolean;
  resetSelectedHits: () => void;
  decisionStatus: HitDecisionStatus | undefined;
  reason: string;
  updateDecisionStatus: (decisionStatus: HitDecisionStatus | undefined) => void;
  updateReason: (reason: string) => void;
  users: UserSchema[];
  actionType: 'aml' | 'kyb';
};

const HitsContext = createContext<HitsContextValue>({
  selectedHitsCount: 0,
  selectHit: () => null,
  deselectHit: () => null,
  makeDecisionForHits: () => null,
  isHitSelected: () => false,
  isPendingUpdate: false,
  resetSelectedHits: () => null,
  decisionStatus: undefined,
  reason: '',
  updateDecisionStatus: () => null,
  updateReason: () => null,
  users: [],
  actionType: 'aml',
});

export const useHitsProviderContext = () => {
  return useContext(HitsContext);
};

type HitsProviderProps = {
  hits: ActionHit[];
  actionStatus: 'resolved' | 'pending';
  actionType: 'aml' | 'kyb';
  children: React.ReactNode;
  datasetId: string;
  spektrId: string;
};

export const HitsProvider = ({
  hits,
  actionStatus,
  actionType,
  datasetId,
  spektrId,
  children,
}: HitsProviderProps) => {
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());
  const [selectedHits, setSelectedHits] = useState<string[]>([]);
  const [decisionStatus, updateDecisionStatus] = useState<HitDecisionStatus>();
  const [reason, updateReason] = useState<string>('');
  const actionId = useActionIdParam();
  const updateMultipleHits = useUpdateAction(actionId, datasetId, spektrId);
  const updateSingleHit = useUpdateHit(actionId, datasetId, spektrId);

  const selectHit = useCallback(
    (hitId: string) => setSelectedHits((prev) => [...prev, hitId]),
    []
  );

  const deselectHit = useCallback(
    (hitId: string) =>
      setSelectedHits((prev) => prev.filter((id) => id !== hitId)),
    []
  );

  const isHitSelected = useCallback(
    (hitId: string) => selectedHits.includes(hitId),
    [selectedHits]
  );

  const resetSelectedHits = useCallback(() => {
    setSelectedHits([]);
    updateDecisionStatus(undefined);
    updateReason('');
  }, [setSelectedHits, updateDecisionStatus, updateReason]);

  const makeDecisionForHits = useCallback(async () => {
    if (!decisionStatus || decisionStatus === 'pending') return;

    if (selectedHits.length === 1) {
      await updateSingleHit.mutateAsync({
        actionId,
        hitId: selectedHits[0],
        status: decisionStatus,
        reason,
      });

      resetSelectedHits();
      return;
    }

    const updatedHits = hits
      .filter((hit) => hit.id && selectedHits.includes(hit.id))
      .map((hit) => ({
        id: hit.id,
        data: hit.data,
        nodeType: hit.nodeType,
        nodeId: hit.nodeId,
        processId: hit.processId,
        spektrId: hit.spektrId,
        datasetId: hit.datasetId,
        decision: {
          ...hit.decision,
          status: decisionStatus,
          userId: '53703e52-7b70-45de-a9a1-1b4e9ba5a29c',
          createdAt: Date.now(),
          reason,
        },
      }));

    await updateMultipleHits.mutateAsync({
      hits: updatedHits,
      status: actionStatus,
    });

    resetSelectedHits();
  }, [
    hits,
    selectedHits,
    actionStatus,
    updateMultipleHits,
    updateSingleHit,
    actionId,
    resetSelectedHits,
    decisionStatus,
    reason,
  ]);

  const values = useMemo(
    () => ({
      selectedHitsCount: selectedHits.length,
      selectHit,
      deselectHit,
      makeDecisionForHits,
      isHitSelected,
      isPendingUpdate: updateMultipleHits.isPending,
      resetSelectedHits,
      decisionStatus,
      reason,
      updateDecisionStatus,
      updateReason,
      users,
      actionType,
    }),
    [
      selectedHits,
      selectHit,
      deselectHit,
      makeDecisionForHits,
      isHitSelected,
      updateMultipleHits.isPending,
      resetSelectedHits,
      decisionStatus,
      reason,
      updateDecisionStatus,
      updateReason,
      users,
      actionType,
    ]
  );

  return <HitsContext.Provider value={values}>{children}</HitsContext.Provider>;
};
