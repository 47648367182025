import { cva } from 'class-variance-authority';

export const iconBoxVariants = cva('p-2 rounded', {
  variants: {
    variant: {
      yellow: 'bg-color-yellow/20 text-color-yellow',
      red: 'bg-color-red/20 text-color-red',
      cyan: 'bg-color-cyan/20 text-color-cyan',
      blue: 'bg-color-blue/20 text-color-blue',
      white: 'bg-color-bg-secondary/20 text-color-text-icon-primary',
      pink: 'bg-color-pink/20 text-color-pink',
    },
    box: {
      outline: 'border border-border-color-tertiary',
      default: 'border-0',
    },
  },
  defaultVariants: {
    variant: 'white',
    box: 'default',
  },
});
