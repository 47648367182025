import { VariantProps } from 'class-variance-authority';
import { ChevronDown } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
} from 'react';

import { Icon, Trigger } from '@radix-ui/react-select';

import { cn } from '@spektr/client/utils';

import { selectVariants, triggerVariants } from '../variants';

export type SelectTriggerProps = ComponentPropsWithoutRef<typeof Trigger> &
  VariantProps<typeof selectVariants> &
  VariantProps<typeof triggerVariants> & {
    icon?: ReactNode;
    showChevron?: boolean;
  };

export const SelectTrigger = forwardRef<
  ElementRef<typeof Trigger>,
  SelectTriggerProps
>(
  (
    { className, children, size, inverted, icon, showChevron = true, ...props },
    ref
  ) => (
    <Trigger
      ref={ref}
      className={cn(
        'flex w-full items-center gap-2',
        'font-medium',
        'focus-visible:outline-none focus-visible:ring-0',
        'disabled:cursor-not-allowed',
        selectVariants({ size }),
        triggerVariants({ inverted }),
        className
      )}
      {...props}
    >
      {icon && (
        <Icon asChild className="shrink-0">
          {icon}
        </Icon>
      )}
      <span className="line-clamp-1">{children}</span>
      {showChevron && (
        <Icon asChild className="ml-auto shrink-0">
          <ChevronDown className="text-color-text-icon-secondary h-4 w-4" />
        </Icon>
      )}
    </Trigger>
  )
);
