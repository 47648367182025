import { useSuspenseQuery } from '@tanstack/react-query';

import { getOwnersForRecord } from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';
import { insightsRecordDetailsParamsSchema } from '@spektr/shared/utils';

import { computeOwnershipData } from '../../utils/computeOwnershipData';

import { RecordOwnershipGroup } from '../../components/RecordOwnershipGroup';
import { RecordOwnershipUboTable } from '../../components/RecordOwnershipUboTable';
import { RecordOwnershipRepresentativesTable } from '../../components/RecordOwnershipRepresentativesTable';

export const RecordOwnershipList = () => {
  const { datasetId, recordId } = useParsedParams(
    insightsRecordDetailsParamsSchema
  );
  const { data: owners } = useSuspenseQuery({
    ...getOwnersForRecord(datasetId, recordId),
    meta: { skipGlobalErrorHandling: true },
  });

  const { ubo, representatives } = computeOwnershipData(owners);

  return (
    <div className="flex flex-col gap-4">
      {!ubo.length && !representatives.length && (
        <div className="text-spektr-zinc-400">No ownership data available</div>
      )}
      {ubo.length > 0 && (
        <RecordOwnershipGroup label="Owners">
          <RecordOwnershipUboTable uboList={ubo} />
        </RecordOwnershipGroup>
      )}
      {representatives.length > 0 && (
        <RecordOwnershipGroup label="Controllers & Representatives">
          <RecordOwnershipRepresentativesTable
            representativesList={representatives}
          />
        </RecordOwnershipGroup>
      )}
    </div>
  );
};
