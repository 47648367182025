import { useEffect, useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { connectionDataSetsQuery } from '@spektr/client/services';
import { Select, SpektrDatasetIcon } from '@spektr/client/components';

type DatasetFilterProps = {
  selectedDatasetId?: string;
  updateDatasetId: (id: string) => void;
  updateFallbackDatasetId: (id: string) => void;
};

export const DatasetFilter = ({
  selectedDatasetId = '',
  updateDatasetId,
  updateFallbackDatasetId,
}: DatasetFilterProps) => {
  const { data: datasets } = useSuspenseQuery(connectionDataSetsQuery());
  const datasetsWithMapping = useMemo(() => {
    if (!datasets) return [];

    return datasets.filter((dataset) => Boolean(dataset.fieldId) || true); // TODO: handle the filter properly. Currently, it filters out datasets from onboarding processes bc they dont have fieldId (to be implemented)
  }, [datasets]);

  const handleChangeDataset = (id: string) => {
    updateDatasetId(id);
  };

  const options = useMemo(() => {
    if (!datasetsWithMapping) return [];

    return datasetsWithMapping.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [datasetsWithMapping]);

  useEffect(() => {
    const firstDatasetId = datasetsWithMapping?.[0]?.id;

    if (!firstDatasetId) {
      return;
    }

    if (!selectedDatasetId) {
      updateFallbackDatasetId(firstDatasetId);
      return;
    }

    if (
      datasetsWithMapping?.findIndex(
        (dataset) => dataset.id === selectedDatasetId
      ) === -1
    ) {
      updateDatasetId(firstDatasetId);
    }
  }, [
    selectedDatasetId,
    datasetsWithMapping,
    updateFallbackDatasetId,
    updateDatasetId,
  ]);

  if (!datasetsWithMapping || !datasetsWithMapping?.[0]?.id) {
    return null;
  }

  return (
    <div className="w-[198px]">
      <Select
        value={selectedDatasetId}
        placeholder="Select a dataset"
        icon={
          <SpektrDatasetIcon className="stroke-color-stroke-white h-4 w-4" />
        }
        options={options}
        onValueChange={handleChangeDataset}
      />
    </div>
  );
};
