import { useMemo, useState } from 'react';

import { monthNames } from './constants';
import { SelectOption } from './types';

export const useCalendar = (selectedDate: Date) => {
  const [month, setMonth] = useState(selectedDate.getMonth());
  const [year, setYear] = useState(selectedDate.getFullYear());

  return useMemo(
    () => ({
      updateMonth: (newMonth: number) => {
        if (month !== newMonth) {
          setMonth(newMonth);
        }
      },
      updateYear: (newYear: number) => {
        if (year !== newYear) {
          setYear(newYear);
        }
      },
      getDate: (type: SelectOption, value: string) => {
        if (type === 'months') {
          const newMonth = monthNames.indexOf(value);
          setMonth(newMonth);
          return new Date(year, newMonth);
        } else {
          setYear(Number(value));
          return new Date(Number(value), month);
        }
      },
    }),
    [month, year]
  );
};
