import { cn } from '@spektr/client/utils';

import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

const baseThClasses = 'text-xs text-color-text-table-header p-3';
const baseRowCellClasses = cn('px-4', 'border-b', 'text-sm font-medium');

export const SkeletonActionsTable = () => {
  return (
    <Table className="border-separate">
      <TableHeader>
        <TableRow className="rounded-tl-md rounded-tr-md hover:bg-inherit">
          <TableHead
            className={cn('rounded-tl-md border border-r-0', baseThClasses)}
          >
            Name
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-y')}>
            Process
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-y')}>
            Source
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-y')}>
            Created at
          </TableHead>
          <TableHead
            className={cn('rounded-tr-md border border-l-0', baseThClasses)}
          />
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow className="border-b">
          <TableCell className={cn(baseRowCellClasses, 'border-l')}>
            <Skeleton className="w-[109px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[183px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[203px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[203px]" />
          </TableCell>
          <TableCell className={cn(baseRowCellClasses, 'border-r')}>
            <Skeleton className="w-[157px]" />
          </TableCell>
        </TableRow>
        <TableRow className="border-b">
          <TableCell className={cn(baseRowCellClasses, 'border-l')}>
            <Skeleton className="w-[87px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[183px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[203px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[203px]" />
          </TableCell>
          <TableCell className={cn(baseRowCellClasses, 'border-r')}>
            <Skeleton className="w-[157px]" />
          </TableCell>
        </TableRow>
        <TableRow className="border-b">
          <TableCell
            className={cn(baseRowCellClasses, 'rounded-bl-md border-l')}
          >
            <Skeleton className="w-[87px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[183px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[203px]" />
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <Skeleton className="w-[203px]" />
          </TableCell>
          <TableCell
            className={cn(baseRowCellClasses, 'rounded-br-md border-r')}
          >
            <Skeleton className="w-[157px]" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
