import { useSuspenseQuery } from '@tanstack/react-query';

import { getTeamMembersQuery } from '@spektr/client/services';
import { InfiniteScroll } from '@spektr/client/components';

import { useGetComments } from '../../hooks/useGetComments';
import { useActionAndHitIdParam } from '../../hooks/useActionAndHitIdParam';

import { HitComment } from '../../components/HitComment';

type HitCommentListProps = {
  onDeleteComment: () => void;
};

export const HitCommentList = ({ onDeleteComment }: HitCommentListProps) => {
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());
  const { hitId } = useActionAndHitIdParam();

  const {
    comments,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useGetComments(hitId, 'hit');

  return (
    <InfiniteScroll
      className="flex flex-col gap-6"
      fetchMoreItems={fetchNextPage}
      hasMoreItems={hasNextPage}
      isFetching={isFetching || isFetchingNextPage}
      loadMoreText="Loading more comments..."
    >
      {comments.length > 0 &&
        comments.map((comment) => (
          <HitComment
            key={comment.id}
            id={comment.id}
            message={comment.message}
            user={users.find((user) => user.id === comment.userId)}
            updated={comment.updatedAt}
            parentId={comment.parentId}
            onDeleteComment={onDeleteComment}
          />
        ))}
    </InfiniteScroll>
  );
};
