import { Filters } from './types/Filters';

import { InsightsApiClient } from './client';
import { INSIGHTS_KEYS } from './query.keys';

export function getAllExecutionsQuery(datasetId: string, filters?: Filters) {
  return {
    queryKey: INSIGHTS_KEYS.ALL(datasetId, filters),
    queryFn: () =>
      InsightsApiClient.getClient().getAllExecutionsForDataset({
        params: {
          datasetId,
        },
        queries: {
          page: filters?.page ?? 1,
          limit: filters?.limit ?? 10,
        },
      }),
  };
}

export function getMostRecentExecutionsQuery(filters?: Filters) {
  return {
    queryKey: INSIGHTS_KEYS.MOST_RECENT,
    queryFn: () =>
      InsightsApiClient.getClient().getMostRecentExecutions({
        queries: {
          page: filters?.page ?? 0,
          limit: filters?.limit ?? 10,
        },
      }),
  };
}

export function getExecutionById(executionId: string) {
  return {
    queryKey: INSIGHTS_KEYS.EXECUTION_BY_ID(executionId),
    queryFn: () =>
      InsightsApiClient.getClient().getExecutionById({
        params: {
          id: executionId,
        },
      }),
  };
}

export function getOwnersForRecord(datasetId: string, recordId: string) {
  return {
    queryKey: INSIGHTS_KEYS.ALL_OWNERS(datasetId, recordId),
    queryFn: () =>
      InsightsApiClient.getClient().getOwnersById({
        params: {
          datasetId,
          recordId,
        },
      }),
  };
}
