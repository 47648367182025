import { cn } from '@spektr/client/utils';
import { TableHead, TableHeader, TableRow } from '@spektr/client/components';

const baseThClasses = cn(
  'px-4 py-3.5',
  'border-b',
  'text-xs text-color-text-table-header'
);

export const RecordOwnershipUboTableHeader = () => (
  <TableHeader>
    <TableRow>
      <TableHead className={baseThClasses}>Type</TableHead>
      <TableHead className={baseThClasses}>Name</TableHead>
      <TableHead className={baseThClasses}>Address</TableHead>
      <TableHead className={cn(baseThClasses)}>Percentage</TableHead>
    </TableRow>
  </TableHeader>
);
