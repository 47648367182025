import { z } from 'zod';

import {
  BrandingFormTheme,
  BrandingFormAssistingContent,
} from '@spektr/branding-types';

export const FormBrandingCreate = BrandingFormTheme.extend({
  assistingContent: BrandingFormAssistingContent,
});

export type FormBrandingCreate = z.infer<typeof FormBrandingCreate>;

export const FormBranding = FormBrandingCreate.extend({
  id: z.string(),
  updatedAt: z.number(),
  createdAt: z.number(),
});
export type FormBranding = z.infer<typeof FormBranding>;

export const FormBrandingUpdate = FormBrandingCreate.extend({
  logoUrl: z.string(),
}).deepPartial(); // NOTE: .deepPartial has been marked deprecated in zod v3.21.0, though it's not marked as deprecated in their docs. According to [this long-running issue ](https://github.com/colinhacks/zod/issues/2854) there has been no update on what should replace it.

export type FormBrandingUpdate = z.infer<typeof FormBrandingUpdate>;
