import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

export const SkeletonTable = () => {
  const cellBaseClasses = 'px-4 border-b';

  return (
    <Table className="rounded-md border">
      <TableHeader>
        <TableRow className="h-9 hover:bg-inherit">
          <TableHead className={cellBaseClasses}>
            <Skeleton className="h-3 w-52" />
          </TableHead>
          <TableHead className={cellBaseClasses}>
            <Skeleton className="h-3 w-48" />
          </TableHead>
          <TableHead className={cellBaseClasses}>
            <Skeleton className="h-3 w-32" />
          </TableHead>
          <TableHead className={cellBaseClasses}>
            <Skeleton className="h-3 w-32" />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {new Array(5).fill(null).map((_, index) => (
          <TableRow className="h-16" key={index}>
            <TableCell className={cellBaseClasses}>
              <Skeleton className="h-5 w-52" />
            </TableCell>
            <TableCell className={cellBaseClasses}>
              <Skeleton className="h-5 w-48" />
            </TableCell>
            <TableCell className={cellBaseClasses}>
              <Skeleton className="h-5 w-32" />
            </TableCell>
            <TableCell className={cellBaseClasses}>
              <Skeleton className="h-5 w-32" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
