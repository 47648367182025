import { SpektrField } from '@spektr/shared/types';

import { SegmentProvider, useIncompleteCalculationNode } from '../providers';
import { IncompleteSegment } from '../tree-helpers';

import { Segment } from './segment';

type SegmentContainerProps = {
  segment: IncompleteSegment;
  spektrFields: SpektrField[];
};

export const SegmentContainer = ({
  segment,
  spektrFields,
}: SegmentContainerProps) => {
  const { incomplete, updateSegment, removeSegment } =
    useIncompleteCalculationNode();

  return (
    <SegmentProvider
      type={incomplete.nodeType}
      spektrFields={spektrFields}
      segment={segment}
      onChange={(updated) => {
        if (updated) updateSegment(updated);
        else removeSegment(segment.clientSideOnlyId);
      }}
    >
      <Segment />
    </SegmentProvider>
  );
};
