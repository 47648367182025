import { Filters } from './types/Filters';

export const INSIGHTS_KEYS = {
  ALL: (datasetId: string, filters: Filters = {}) =>
    ['insights', 'list', datasetId, filters] as const,
  DETAILS: (executionId: string) =>
    ['insights', 'details', executionId] as const,
  MOST_RECENT: ['most-recent-executions'] as const,
  EXECUTION_BY_ID: (executionId: string) =>
    ['insights', 'execution', executionId] as const,
  ALL_OWNERS: (datasetId: string, recordId: string) =>
    ['insights', 'owners', datasetId, recordId] as const,
};
