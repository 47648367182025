import { CircleAlert } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { blurOnEnter } from '../../utils';

export type TitleInputProps = {
  title: string;
  onChange: (title: string) => void;
  className?: string;
};

export const TitleInput = ({ title, className, onChange }: TitleInputProps) => {
  const isInvalid = title.trim() === '';

  return (
    <div className="flex items-center gap-4">
      <div className="relative rounded-sm">
        <input
          type="text"
          name="ast-node-title"
          id="ast-node-title"
          className={cn(
            'hover:ring-border focus:ring-color-border-default text-color-text-input block w-80 rounded-sm border-0 bg-transparent py-1 pr-10 text-sm hover:ring-1 hover:ring-inset focus:ring-2 focus:ring-inset',
            className
          )}
          value={title}
          aria-invalid={isInvalid ? 'false' : 'true'}
          aria-describedby={isInvalid ? '' : 'title-error'}
          onChange={(event) => onChange(event.target.value)}
          onKeyUp={blurOnEnter}
        />
        {isInvalid && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <CircleAlert
              className="text-color-red h-5 w-5"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {isInvalid && (
        <p className="text-color-red text-sm" id="title-error">
          Title is required
        </p>
      )}
    </div>
  );
};
