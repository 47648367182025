import { type Field, isEntitiesField } from '@spektr/moonraker-types';

import { BlockContent } from '../BlockContent';
import { BaseContent } from '../BaseContent';

export type CanvasFieldFactoryProps = {
  field: Field;
};

export const CanvasFieldFactory = ({ field }: CanvasFieldFactoryProps) => {
  if (isEntitiesField(field)) {
    return <BlockContent field={field} />;
  }

  return <BaseContent field={field} />;
};
