import { useCallback } from 'react';

import { serializeSlate } from '@spektr/client/components';

import {
  DeserializedMoonrakerForm,
  MoonrakerForm as MoonrakerFormType,
} from '@spektr/moonraker-types';

// TODO: (@Robert) - figure out the types here
export function useHandleSerializedSave(
  onSave: (data: MoonrakerFormType) => void
) {
  return useCallback(
    (data: DeserializedMoonrakerForm) => {
      if (data?.assistingContent) {
        const deserializedDescription = data.assistingContent?.description
          ? serializeSlate(data.assistingContent.description)
          : '';
        const deserializedSupportContent = data.assistingContent?.support
          ?.content
          ? serializeSlate(data.assistingContent.support.content)
          : '';

        return onSave({
          ...data,
          assistingContent: {
            ...data?.assistingContent,
            description: deserializedDescription,
            support: {
              ...data.assistingContent.support,
              content: deserializedSupportContent,
            },
          },
        });
      }

      return onSave(data as MoonrakerFormType);
    },
    [onSave]
  );
}
