import { useForm, FormProvider } from 'react-hook-form';

import { WidgetInstance } from '@spektr/shared/validators';

import { Button } from '../Button';

import { FieldFactory } from './FieldFactory';

export type FormValue = string | number | boolean | File | null;
export type EntityValue = Record<string, FormValue>;

export type FormValues = Record<string, FormValue | EntityValue[]>;
export type FormViewerOnSubmit = (data: FormValues) => void;
export type FormViewerProps = {
  widgets: WidgetInstance[];
  onSubmit: FormViewerOnSubmit;
};

export const FormViewer = ({ widgets, onSubmit }: FormViewerProps) => {
  const formInstance = useForm<FormValues>();

  return (
    <FormProvider {...formInstance}>
      <div className="bg-color-bg-primary border-color-border-form-viewer-primary rounded-lg border p-8 shadow-md">
        <form
          className="flex flex-col gap-4"
          onSubmit={formInstance.handleSubmit(onSubmit)}
        >
          {widgets.map((widget) => (
            <FieldFactory key={widget.id} widget={widget} />
          ))}
          <Button
            className="mx-auto"
            type="submit"
            disabled={formInstance.formState.isSubmitting}
          >
            {formInstance.formState.isSubmitting
              ? 'Submitting...'
              : 'Submit form'}
          </Button>
        </form>
      </div>
    </FormProvider>
  );
};
