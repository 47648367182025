import { ReactNode } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

interface GroupedCardsProps extends VariantProps<typeof gridVariants> {
  children: ReactNode[] | ReactNode;
  className?: string;
}

export const GroupedCards = ({
  children,
  columns,
  className,
}: GroupedCardsProps) => (
  <div className={cn('gap-5', gridVariants({ columns }), className)}>
    {children}
  </div>
);

const gridVariants = cva('grid', {
  variants: {
    columns: {
      '1': 'grid-cols-1',
      '2': 'grid-cols-1 md:grid-cols-2',
      '3': 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
      '4': 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4',
    },
  },
  defaultVariants: {
    columns: '1',
  },
});
