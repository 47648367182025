import axios from 'axios';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

import { toast } from '@spektr/client/components';

import {
  REQUEST_RETRY_COUNT,
  RESPONSE_CACHE_TIME,
  RESPONSE_STALE_TIME,
} from './config/network';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: RESPONSE_STALE_TIME,
      gcTime: RESPONSE_CACHE_TIME,
      retry(failureCount, error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status) {
            return error.response.status < 400;
          }
        }

        return failureCount < REQUEST_RETRY_COUNT;
      },
    },
  },
  queryCache: new QueryCache({
    onError(error, query) {
      if (query?.meta?.skipGlobalErrorHandling) {
        return;
      }

      if (axios.isAxiosError(error)) {
        toast.error({
          title: 'Something went wrong!',
          description: error.response?.data?.message ?? 'Something went wrong!',
        });
      }
    },
  }),
  mutationCache: new MutationCache({
    onError(error, variables, context, mutation) {
      if (mutation?.meta?.skipGlobalErrorHandling) {
        return;
      }

      if (axios.isAxiosError(error)) {
        toast.error({
          title: 'Something went wrong!',
          description: error.response?.data?.message ?? 'Something went wrong!',
        });
      }
    },
  }),
});
