import { useState } from 'react';
import { format } from 'date-fns';
import { CalendarDays } from 'lucide-react';

import { cn } from '@spektr/client/utils';
import { Button, Popover, Calendar } from '@spektr/client/components';

import { triggerContentVariants, triggerVariants } from '../Select/variants';

export type DatePickerProps = {
  iconClassName?: string;
  className?: string;
  epochOrToday: string | number | '@today' | undefined;
  disabled?: boolean;
  placeholder?: string;
  inverted?: boolean;
  onChange?: (epochOrToday: string | number | '@today' | undefined) => void;
  showToday?: boolean;
};

export const DatePicker = ({
  iconClassName,
  className,
  epochOrToday,
  disabled = false,
  placeholder = 'Pick a date',
  inverted = false,
  showToday = true,
  onChange,
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      className={cn('w-auto', triggerContentVariants({ inverted }))}
      trigger={
        <Button
          disabled={disabled}
          variant="text"
          className={cn(
            'shrink-0 justify-start',
            'border',
            'text-left text-sm font-normal',
            triggerVariants({ inverted }),
            className
          )}
        >
          <CalendarDays
            className={cn('mr-2 h-4 w-4 shrink-0', iconClassName)}
          />
          {epochOrToday === '@today' ? (
            <span className="shrink-0" children="@today" />
          ) : epochOrToday ? (
            format(new Date(Number(epochOrToday)), 'PPP')
          ) : (
            <span className="shrink-0">{placeholder}</span>
          )}
        </Button>
      }
      triggerProps={{
        asChild: true,
      }}
      open={open}
      onOpenChange={setOpen}
    >
      <div className="flex flex-col gap-1 pt-2">
        {showToday && (
          <Button
            variant="contained"
            color={inverted ? 'white' : 'secondary'}
            children="@today"
            className={cn(
              'mx-auto h-auto w-fit px-3 py-1',
              'bg-transparent',
              'text-xs font-medium',
              'hover:opacity-100 aria-selected:opacity-100',
              'focus-visible:outline-none focus-visible:ring-1',
              'ring-offset-background focus-visible:ring-ring',
              'disabled:cursor-not-allowed disabled:border-none disabled:opacity-50',
              inverted
                ? 'hover:bg-color-bg-foreground focus:bg-color-bg-foreground'
                : 'hover:bg-color-bg-accent focus:bg-color-bg-accent',
              epochOrToday === '@today' &&
                (inverted
                  ? 'text-color-text-calendar-selected-day-inverted bg-color-bg-calendar-selected-day-inverted hover:bg-color-bg-primary/20 focus:bg-color-bg-primary/20'
                  : 'text-color-text-calendar-selected-day bg-color-bg-calendar-selected-day hover:bg-color-bg-primary/60 focus:bg-color-bg-primary/60')
            )}
            onClick={() => {
              onChange?.('@today');
              setOpen(false);
            }}
          />
        )}
        <Calendar
          mode="single"
          selected={
            epochOrToday && epochOrToday !== '@today'
              ? new Date(Number(epochOrToday))
              : undefined
          }
          onSelect={(date) => {
            onChange?.(date?.getTime()?.toString());
            setOpen(false);
          }}
          className="text-color-text-primary pt-0"
          inverted={inverted}
        />
      </div>
    </Popover>
  );
};
