import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import {
  insightsRecordDetailsParamsSchema,
  insightsRecordDetailsUrl,
  insightsRecordRunsUrl,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import {
  DETAILS_SIDEBAR_WIDTH,
  PLATFORM_ASIDE_NAV_WIDTH,
} from '@spektr/client/constants';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import {
  RecordDetailsError,
  RecordDetailsPageHeader,
  RecordDetailsPageSidebar,
  SkeletonRecordDetailsPageHeader,
  SkeletonRecordDetailsPageSidebar,
} from '@spektr/model-builder/containers';

export const RecordDetailsPage = () => {
  const navigate = useNavigate();
  const { datasetId, recordId } = useParsedParams(
    insightsRecordDetailsParamsSchema
  );
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  const maxContainerWidth =
    width - PLATFORM_ASIDE_NAV_WIDTH - DETAILS_SIDEBAR_WIDTH;

  useEffect(() => {
    if (pathname === insightsRecordDetailsUrl(datasetId, recordId)) {
      navigate(insightsRecordRunsUrl(datasetId, recordId));
    }
  }, [pathname, recordId]);

  return (
    <div className="absolute bottom-0 top-0 flex w-full overflow-hidden">
      <SpektrErrorBoundary fallback={<RecordDetailsError />}>
        <ResizablePanelGroup
          autoSaveId="spektr.record-details"
          direction="horizontal"
        >
          <ResizablePanel defaultSize={75} minSize={50} className="border-r">
            <div
              className="flex h-full w-full flex-col px-6 pt-6"
              data-cy="record-details-data-cy"
              style={{
                maxWidth: maxContainerWidth,
              }}
            >
              <Suspense fallback={<SkeletonRecordDetailsPageHeader />}>
                <RecordDetailsPageHeader
                  datasetId={datasetId}
                  spektrId={recordId}
                />
              </Suspense>

              <Outlet />
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel defaultSize={25} minSize={25}>
            <div className="h-full">
              <Suspense fallback={<SkeletonRecordDetailsPageSidebar />}>
                <RecordDetailsPageSidebar
                  datasetId={datasetId}
                  spektrId={recordId}
                />
              </Suspense>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </SpektrErrorBoundary>
    </div>
  );
};
