import { Outlet } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { processDetailsParamsSchema } from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import { getLoopByIdQuery } from '@spektr/client/services';

import { ProcessBuilder } from '@spektr/model-builder/process-builder';

export const LoopDetails = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const { data } = useSuspenseQuery(getLoopByIdQuery(processId));

  return (
    <div className="flex h-full w-full flex-col justify-between">
      <div className="relative flex-grow overflow-hidden">
        <ProcessBuilder process={data} />
        <Outlet />
      </div>
    </div>
  );
};
