import { ReactNode, MouseEvent, ComponentPropsWithoutRef } from 'react';
import { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemProps,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './DropdownMenu';

export type DropdownOption = {
  type: 'item' | 'separator';
  label?: string | ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  value: string;
  variant?: DropdownMenuItemProps['variant'];
};

export type DropdownMenuComboBoxProps = ComponentPropsWithoutRef<
  typeof DropdownMenu
> &
  Pick<
    DropdownMenuContentProps,
    'align' | 'alignOffset' | 'side' | 'sideOffset'
  > & {
    itemClassName?: string;
    options: DropdownOption[];
    trigger: ReactNode;
    triggerProps?: ComponentPropsWithoutRef<typeof DropdownMenuTrigger>;
    onClick?: (value: string, ev: MouseEvent) => void;
  };

export const DropdownMenuComboBox = ({
  align,
  alignOffset,
  itemClassName,
  side,
  sideOffset,
  modal = false,
  options,
  trigger,
  triggerProps,
  onClick,
  onOpenChange,
}: DropdownMenuComboBoxProps) => {
  const renderOption = (option: DropdownOption) => {
    if (option.type === 'separator') {
      return <DropdownMenuSeparator key={option.value} />;
    }

    return (
      <DropdownMenuItem
        className={itemClassName}
        key={option.value}
        variant={option?.variant}
        disabled={option?.disabled}
        onClick={(ev) => onClick && onClick(option.value, ev)}
        data-cy={'card-more-options-' + option.value}
      >
        <div className="flex items-center gap-2">
          {option.icon}
          {option.label}
        </div>
      </DropdownMenuItem>
    );
  };
  return (
    <DropdownMenu modal={modal} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger {...triggerProps}>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent
        align={align}
        alignOffset={alignOffset}
        side={side}
        sideOffset={sideOffset}
        className="w-full min-w-48"
        onClick={(e) => e.stopPropagation()}
      >
        {options.map(renderOption)}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
