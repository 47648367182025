import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

import { SettingsTableHead } from '../SettingsTableHead';

export type ExportedSettingsTableProps = {
  children: React.ReactNode;
};

export const ExportedSettingsTable = ({
  children,
}: ExportedSettingsTableProps) => (
  <Table className="rounded-md border">
    <TableHeader>
      <TableRow>
        <SettingsTableHead>
          <div className="w-52 shrink-0">Process name</div>
        </SettingsTableHead>
        <SettingsTableHead>Dataset ID</SettingsTableHead>
        <SettingsTableHead className="w-max">Date</SettingsTableHead>
      </TableRow>
    </TableHeader>
    <TableBody className="overflow-x-hidden">{children}</TableBody>
  </Table>
);
