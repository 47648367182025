import { createContext } from 'react';

import { CognitoUserData } from '@spektr/shared/types';

import { UserInformation } from './types';

type UserInformationProviderContextType = {
  userInformation: UserInformation | null;
};

const UserInformationContext =
  createContext<UserInformationProviderContextType>({
    userInformation: null,
  });

type UserInformationProviderProps = {
  children: React.ReactNode;
  user: CognitoUserData;
};

const UserInformationProvider = ({
  user,
  children,
}: UserInformationProviderProps) => {
  const userInformation = {
    firstName: user['firstName'] ?? '',
    lastName: user['lastName'] ?? '',
    email: user['email'] ?? '',
    role: user['role'] ?? '',
    workspaceId: user['workspaceId'] ?? '',
  };

  return (
    <UserInformationContext.Provider value={{ userInformation }}>
      {children}
    </UserInformationContext.Provider>
  );
};

export { UserInformationContext, UserInformationProvider };
