import { useTranslation } from 'react-i18next';
import { Primitive } from 'utility-types';

import { SidebarSubtitle } from '../SidebarSubtitle';
import { BasicDetailsTable } from '../BasicDetailsTable';

export type ActionRecordTableProps = {
  data: Record<string, Primitive>;
};

export const ActionRecordTable = ({ data }: ActionRecordTableProps) => {
  const { t } = useTranslation('fields');

  const renderColumn = (key: string) => t(key);

  const renderValue = (key: string, value: unknown) => {
    if (typeof value === 'string') {
      return value;
    }

    if (typeof value === 'number') {
      return value;
    }

    return JSON.stringify(value);
  };

  return (
    <div className="flex flex-col gap-4">
      <SidebarSubtitle title="Record" iconName="ListTodo" />
      <BasicDetailsTable
        data={data}
        columnClassName="w-[150px]"
        renderColumn={renderColumn}
        renderValue={renderValue}
      />
    </div>
  );
};
