import { useState } from 'react';
import { useDebounce } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { CirclePlus, Search } from 'lucide-react';

import { MATRIX_NEW_FULL_URL } from '@spektr/shared/utils';
import { RiskMatrixSlim } from '@spektr/shared/validators';
import {
  DropdownMenuSeparator,
  Input,
  Popover,
} from '@spektr/client/components';

import { useIncompleteCalculationNode } from '../providers';
import { AddButton } from '../buttons/AddButton';

import { MatrixButton } from './MatrixButton';

export const AddMatrix = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  const { riskMatrices, addMatrixSegment } = useIncompleteCalculationNode();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredMatrices, setFilteredMatrices] = useState(riskMatrices);

  const onMatrixClick = (matrix: RiskMatrixSlim) => {
    addMatrixSegment(matrix);
    setPopoverOpen(false);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setSearchValue(event.target.value);

  useDebounce(
    () => {
      const filtered = riskMatrices.filter(({ title }) =>
        title.toLowerCase().includes(searchValue.toLowerCase())
      );

      setFilteredMatrices(filtered);
    },
    300,
    [searchValue]
  );

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={setPopoverOpen}
      trigger={
        <AddButton
          className={className}
          title="Add Matrix"
          description={
            <span>
              Add one of your pre-defined
              <br />
              matrices to your calculation node
            </span>
          }
          iconName="Box"
        />
      }
      triggerProps={{ asChild: true }}
    >
      <Input
        value={searchValue}
        startIcon={<Search className="h3 w-3" />}
        placeholder="Search for Matrix"
        onChange={handleSearchInputChange}
        className="border-0"
        data-cy="search-matrix-input"
      />
      <DropdownMenuSeparator className="m-0" />
      <div className="max-h-[200px] overflow-y-auto">
        {filteredMatrices.length ? (
          filteredMatrices.map((matrix) => (
            <MatrixButton key={matrix.id} onClick={() => onMatrixClick(matrix)}>
              <span className="line-clamp-1">{matrix.title}</span>
            </MatrixButton>
          ))
        ) : (
          <div className="text-color-text-subtext flex h-10 items-center justify-center text-sm">
            No matrices found
          </div>
        )}
      </div>
      <DropdownMenuSeparator className="m-0" />
      <MatrixButton
        className="gap-2"
        onClick={() => {
          navigate(MATRIX_NEW_FULL_URL);
          setPopoverOpen(false);
        }}
      >
        <CirclePlus className="stroke-color-stroke-icon h-3 w-3" /> Add a new
        Matrix
      </MatrixButton>
    </Popover>
  );
};
