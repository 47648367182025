import { SpektrFieldTypedKey } from '@spektr/shared/validators';

/**
 * @description "main" spektrFields (e.g has_sanctions) are visible and available
 * for the user to select but their subfields (e.g. has_sanctions_fields.*) are not
 * and should be filtered out
 *
 * @param fields - service fields
 * @returns - service fields without subfields
 */
export function getServiceFieldsWithoutSubfields<T extends SpektrFieldTypedKey>(
  fields: T[]
): T[] {
  return fields.filter((field) => !field.key.includes('_fields.'));
}
