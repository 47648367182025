import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEqual, uniqWith } from 'lodash';

import { NodesApiClient, getActionById } from '@spektr/client/services';
import { isServiceNodeType } from '@spektr/shared/types';

export function useAggregateRecordMapping(actionId: string) {
  const { data: action } = useSuspenseQuery(getActionById(actionId));

  const sourceNodes = useMemo(() => {
    return uniqWith(
      action.hits.map((hit) => ({
        processId: hit.processId,
        nodeId: hit.nodeId,
      })),
      isEqual
    );
  }, [action.hits]);

  const { data: nodes } = useSuspenseQuery({
    queryKey: sourceNodes.reduce(
      (acc, source) => [...acc, `${source.processId}-${source.nodeId}`],
      ['service-mapping'] as string[]
    ),
    queryFn: () => {
      return Promise.all(
        sourceNodes.map(({ processId, nodeId }) => {
          return NodesApiClient.getClient().getNodeById({
            params: { processId, nodeId },
          });
        })
      );
    },
  });

  const mapping = useMemo(() => {
    return nodes.reduce(
      (acc, node) => {
        if (isServiceNodeType(node) && node?.mapping) {
          return {
            ...acc,
            ...node.mapping,
          };
        }

        return acc;
      },
      {} as Record<string, string>
    );
  }, [nodes]);

  return mapping;
}
