import { useMemo, useContext } from 'react';

import { DemoContext } from './demo-context';

export const useDemo = (disabledRoutes?: string[]) => {
  const { checkIfDemoEnv } = useContext(DemoContext);

  return useMemo(
    () => ({
      isDemo: checkIfDemoEnv(),
      isAccessRestricted: (pathname: string) => {
        return (
          checkIfDemoEnv() &&
          disabledRoutes?.some((route) => pathname.includes(route))
        );
      },
    }),
    [checkIfDemoEnv, disabledRoutes]
  );
};
