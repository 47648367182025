import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
} from 'react';

import * as SheetPrimitive from '@radix-ui/react-dialog';

import { cn } from '@spektr/client/utils';

const sheetOverlayClasses =
  'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-color-bg-overlay !duration-300';

export const NonModalSheetOverlay = ({
  className,
  ...props
}: ComponentProps<'button'>) => (
  <button
    className={cn(sheetOverlayClasses, 'absolute', className)}
    {...props}
  />
);

export const SheetOverlay = forwardRef<
  ElementRef<typeof SheetPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(sheetOverlayClasses, className)}
    {...props}
    ref={ref}
  />
));
