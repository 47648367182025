import { cva } from 'class-variance-authority';
import { X } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { BannerNotificationItem } from '../../types/BannerNotificationItem';

export type NotificationItemProps = {
  data: BannerNotificationItem;
  onClose: (id: string) => void;
};

export const NotificationItem = ({ data, onClose }: NotificationItemProps) => {
  return (
    <div className={cn(variants({ type: data.type }))}>
      <div className="mx-auto">{data.message}</div>
      {data?.closable && (
        <button
          aria-label={`dismiss ${data.id}`}
          onClick={() => onClose(data.id)}
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

const variants = cva(
  [
    'flex items-center',
    'px-3',
    'h-[44px]',
    'text-color-text-subtext',
    'text-sm leading-4 font-semibold',
  ],
  {
    variants: {
      type: {
        success: 'bg-color-green text-color-text-secondary',
        error: 'bg-color-red',
        info: 'bg-color-bg-accent',
        warning: 'bg-color-yellow text-color-text-secondary',
      },
    },
    defaultVariants: {
      type: 'info',
    },
  }
);
