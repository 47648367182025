import { Skeleton, TableCell, TableRow } from '@spektr/client/components';

import { TeamTable } from '../TeamTable';

export const TableSkeleton = () => (
  <TeamTable>
    <TableRow>
      <TableCell className="p-4">
        <Skeleton className="h-3 w-full max-w-36" />
      </TableCell>
      <TableCell className="p-4">
        <Skeleton className="h-3 w-full max-w-64" />
      </TableCell>
      <TableCell className="p-4">
        <div className="grow-1 flex justify-end">
          <Skeleton className="h-5 w-5" />
        </div>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell className="p-4">
        <Skeleton className="h-3 w-full max-w-36" />
      </TableCell>
      <TableCell className="p-4">
        <Skeleton className="h-3 w-full max-w-64" />
      </TableCell>
      <TableCell className="p-4">
        <div className="grow-1 flex justify-end">
          <Skeleton className="h-5 w-5" />
        </div>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell className="p-4">
        <Skeleton className="h-3 w-full max-w-36" />
      </TableCell>
      <TableCell className="p-4">
        <Skeleton className="h-3 w-full max-w-64" />
      </TableCell>
      <TableCell className="p-4">
        <div className="grow-1 flex justify-end">
          <Skeleton className="h-5 w-5" />
        </div>
      </TableCell>
    </TableRow>
  </TeamTable>
);
