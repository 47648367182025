import { WidgetProperties } from '@spektr/shared/validators';

import { Textarea } from '@spektr/client/components';

import { OnUpdateFn } from '../../types/UpdateFn';

export type ContentSettingsProps = {
  properties: WidgetProperties;
  onUpdate: OnUpdateFn;
};

export const ContentSettings = ({
  properties,
  onUpdate,
}: ContentSettingsProps) => {
  return (
    <div className="w-full">
      <Textarea
        defaultValue={properties.label}
        placeholder="Enter label"
        onChange={(ev) => onUpdate('label', ev.target.value)}
      />
    </div>
  );
};
