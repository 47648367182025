import { createContext } from 'react';

import { BannerNotificationsContextType } from './types/BannerNotificationsContext';

export const BannerNotificationsContext =
  createContext<BannerNotificationsContextType>({
    notifications: [],
    push() {
      throw Error('Missing provider <BannerNotifications>!');
    },
    remove() {
      throw Error('Missing provider <BannerNotifications>!');
    },
  });
