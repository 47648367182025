import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as AccordionPrimitive from '@radix-ui/react-accordion';

export type AccordionProps = ComponentPropsWithoutRef<
  typeof AccordionPrimitive.Root
>;

export const Accordion = forwardRef<
  ElementRef<typeof AccordionPrimitive.Root>,
  AccordionProps
>(({ children, ...props }, forwardedRef) => (
  <AccordionPrimitive.Root {...props} ref={forwardedRef}>
    {children}
  </AccordionPrimitive.Root>
));
