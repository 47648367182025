import { cn } from '@spektr/client/utils';

import { WidgetInstance } from '@spektr/shared/validators';

import { SpektrField } from '@spektr/shared/types';

import { TemplateProvider } from './providers/TemplateProvider';

import { BLOCKS } from './config/blocks';

import { WidgetToolbar } from './containers/WidgetToolbar';
import { Canvas } from './containers/Canvas';

export type LoopFormBuilderProps = {
  spektrFields: SpektrField[];
  widgets: WidgetInstance[];
  onUpdate: (widgets: WidgetInstance[]) => void;
};

export const LoopFormBuilder = ({
  spektrFields,
  widgets,
  onUpdate,
}: LoopFormBuilderProps) => (
  <div
    className={cn(
      'grid h-[474px] grow grid-cols-[178px_auto]',
      'mt-4 rounded border'
    )}
  >
    <TemplateProvider spektrFields={spektrFields}>
      <WidgetToolbar blocks={BLOCKS} />
      <Canvas widgets={widgets} onUpdate={onUpdate} />
    </TemplateProvider>
  </div>
);
