import { useMemo, useState } from 'react';
import { Info } from 'lucide-react';

import { Checkbox, Input, Label, Tooltip } from '@spektr/client/components';

import { usePercentageInput } from '@spektr/platform-hooks';

import { useSourceProvider } from '../../providers/SourceProvider';

import { KyckrFilterItem } from '../KyckrFilterItem';

export const KyckrShareholderFilter = () => {
  const { getFilterProcess, updateProcessFilterRuleGroup } =
    useSourceProvider();
  const processFilter = useMemo(() => getFilterProcess()?.filter, []);
  const isShareholdingFilter = processFilter?.id === 'shareholding';
  const defaultPercentage = useMemo(
    () =>
      isShareholdingFilter
        ? (processFilter?.rule?.right as string) ?? '25'
        : '25',
    []
  );
  const [isChecked, setIsChecked] = useState(isShareholdingFilter);
  const updateFilter = (value?: string) => {
    updateProcessFilterRuleGroup({
      id: 'shareholding',
      title: 'Shareholding',
      score: undefined,
      rule: {
        groupRoot: true,
        left: 'percentage',
        operator: 'greater_than',
        right: +(value || percentageValue),
        type: 'number',
      },
    });
  };
  const { percentageValue, handlePercentageChange } = usePercentageInput(
    defaultPercentage,
    (value) => {
      updateFilter(value);
    }
  );

  const handleCheckChange = (isChecked: boolean) => {
    setIsChecked(isChecked);
    if (!isChecked) {
      updateProcessFilterRuleGroup(undefined);
    } else {
      updateFilter();
    }
  };

  return (
    <KyckrFilterItem>
      <Checkbox
        className="gap-2"
        id="ubo"
        label="Shareholding"
        checked={!!isChecked}
        onCheckedChange={handleCheckChange}
      />
      <Tooltip content="Shareholding is the allocation of shares held in a company.">
        <Info className="text-color-text-icon-secondary h-3.5 w-3.5" />
      </Tooltip>
      <div className="ml-auto flex items-center gap-2">
        <Label
          htmlFor="shareholding_percentage"
          className="text-color-text-subtext"
        >
          Percentage of parent company
        </Label>
        <Tooltip content="The percentage of shareholding in the parent company.">
          <Info className="text-color-text-icon-secondary h-3.5 w-3.5" />
        </Tooltip>
        <Input
          id="shareholding_percentage"
          disabled={!isChecked}
          className="ml-3 w-16"
          dimension="small"
          value={percentageValue}
          type="number"
          onChange={handlePercentageChange}
          endIcon="%"
        />
      </div>
    </KyckrFilterItem>
  );
};
