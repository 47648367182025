import { BaseEdge, EdgeProps, getSmoothStepPath } from 'reactflow';

import { GraphOutcomeEdgeData } from '@spektr/shared/utils';
import { EDGE } from '@spektr/shared/components';

export function EdgeLeaf({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
}: EdgeProps<GraphOutcomeEdgeData>) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    centerX: sourceX + EDGE.CENTER.X, // bend the edge 'EDGE.CENTER.X' pixels from the source node if needed. The default is to bend the edge halfway between source and target but that makes little room for the label
  });

  return <BaseEdge id={id} style={style} path={edgePath} />;
}
