import { useMutation } from '@tanstack/react-query';

import { ProcessApiClient } from '@spektr/client/services';
import { BatchUpdateDependencyMappings } from '@spektr/shared/validators';

import { useInvalidateProcessDependencies } from '@spektr/shared/hooks';

export const useUpdateFieldMappingDependencies = (
  onSuccessCallback: () => void
) => {
  const { invalidateDependencies } = useInvalidateProcessDependencies();

  const mutation = useMutation({
    mutationFn: async (updates: BatchUpdateDependencyMappings) => {
      await ProcessApiClient.getClient().updateDependencyMappings(updates);
    },
    onSuccess: () => {
      invalidateDependencies();
      onSuccessCallback();
    },
  });

  return mutation;
};
