import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { LONG_DATE_FORMAT } from '@spektr/client/constants';

import { ProcessIcon } from '@spektr/client/components';

import { Action } from '@spektr/shared/validators';

import { Dataset, Process } from '@spektr/shared/types';

import { SidebarSubtitle } from '../SidebarSubtitle';
import { BasicDetailsTable } from '../BasicDetailsTable';

export type ActionDetailsTableProps = {
  action: Action;
  dataset: Dataset;
  process: Process;
};

export const ActionDetailsTable = ({
  action,
  dataset,
  process,
}: ActionDetailsTableProps) => {
  const { t } = useTranslation('insights');

  const renderValue = (key: string, value: string | number) => {
    if (key === 'actionCreated') {
      return format(value as number, LONG_DATE_FORMAT);
    }

    if (key === 'process') {
      return (
        <div className="flex gap-2">
          <ProcessIcon boxIcon processType={process.type} />
          <span>{value as string}</span>
        </div>
      );
    }

    return <span>{value}</span>;
  };

  const renderColumn = (key: string) => t(`actions.columns.${key}`);

  const data = useMemo(
    () => ({
      dataset: dataset.name,
      process: process.name,
      actionCreated: action.createdAt,
    }),
    [action.createdAt, dataset.name, process.name]
  );

  return (
    <div className="flex flex-col gap-4">
      <SidebarSubtitle title="Action details" iconName="ListTodo" />

      <BasicDetailsTable
        data={data}
        columnClassName="w-[150px]"
        renderColumn={renderColumn}
        renderValue={renderValue}
      />
    </div>
  );
};
