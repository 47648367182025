import { format } from 'date-fns';

import { Avatar } from '@spektr/client/components';
import { LONG_DATE_FORMAT } from '@spektr/client/constants';

export type SidebarHeaderProps = {
  firstName: string;
  lastName: string;
  recordName: string;
  addedAt: number;
};

export const SidebarHeader = ({
  firstName,
  lastName,
  recordName,
  addedAt,
}: SidebarHeaderProps) => {
  const getFormattedTime = (number?: number) => {
    return number ? format(new Date(number), LONG_DATE_FORMAT) : null;
  };

  return (
    <div className="flex items-center gap-4">
      <Avatar firstName={firstName} lastName={lastName} />

      <div className="flex flex-col gap-1">
        <p className="text-base font-medium leading-5">{recordName}</p>
        <p className="text-xs font-medium">
          <span className="text-color-text-subtext">Added </span>
          {getFormattedTime(addedAt)}
        </p>
      </div>
    </div>
  );
};
