import { useContext } from 'react';

import { PermissionsContext } from './PermissionsContext';

export const usePermissionsContext = () => {
  const { hasPermission } = useContext(PermissionsContext);

  return {
    hasPermission,
  };
};
