import { Label } from '@spektr/client/components';

type RecordOwnershipGroupProps = {
  label: string;
  children: React.ReactNode;
};

export const RecordOwnershipGroup = ({
  label,
  children,
}: RecordOwnershipGroupProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Label>{label}</Label>
      {children}
    </div>
  );
};
