import { datadogRum } from '@datadog/browser-rum';

import * as DD_CONFIG from '../../config/datadog';

if (DD_CONFIG.DD_PLATFORM_ENV !== 'local') {
  datadogRum.init({
    applicationId: DD_CONFIG.DD_APP_ID,
    clientToken: DD_CONFIG.DD_CLIENT_TOKEN,
    site: DD_CONFIG.DD_SITE,
    service: 'process-builder',
    env: DD_CONFIG.DD_PLATFORM_ENV,
    sessionSampleRate: DD_CONFIG.DD_SESSION_SAMPLING_RATE,
    sessionReplaySampleRate: DD_CONFIG.DD_SESSION_REPLAY_SAMPLING_RATE,
    trackViewsManually: true,
    trackUserInteractions: true,
    trackResources: true,
    startSessionReplayRecordingManually: true,
    trackLongTasks: DD_CONFIG.DD_TRACK_LONG_TASKS,
    defaultPrivacyLevel: DD_CONFIG.DD_PRIVACY_LEVEL,
  });
}
