import { Skeleton } from '@spektr/client/components';

export const SkeletonServiceDialogContent = () => {
  return (
    <>
      <Skeleton className="bg-color-bg-skeleton mb-6 h-9 w-full" />

      <Skeleton className="bg-color-bg-skeleton mb-1.5 h-6 w-20" />
      <Skeleton className="bg-color-bg-skeleton mb-4 h-8 w-full" />

      <div className="flex justify-end gap-2">
        <Skeleton className="bg-color-bg-skeleton w-18 h-9" />
        <Skeleton className="bg-color-bg-skeleton w-18 h-9" />
      </div>
    </>
  );
};
