import { CasesRootLayout } from '@spektr/client/components';

import {
  CasesCustomersIndexPage,
  CasesNavigation,
} from '@spektr/model-builder/containers';

export const CustomersIndexPage = () => {
  return (
    <CasesRootLayout>
      <CasesNavigation />
      <CasesCustomersIndexPage />
    </CasesRootLayout>
  );
};
