import { ReactNode, useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { cn } from '@spektr/client/utils';
import { useDemo } from '@spektr/client/providers';
import { SET_UP_ACCOUNT_URL, rootUrl } from '@spektr/shared/utils';

import { LogoExtended } from '@spektr/client/components';

import { AuthContext } from '../auth/auth-context';

export const AuthLayout = () => {
  return (
    <main
      className={cn(
        'flex',
        'h-full w-full',
        `bg-auth bg-auto bg-center bg-no-repeat`,
        'overflow-auto'
      )}
    >
      <div
        className={cn(
          'm-auto flex w-full max-w-[802px] gap-6 p-6',
          'rounded-xl',
          'bg-color-bg-primary'
        )}
      >
        <div
          className={cn(
            'flex flex-col justify-between',
            'min-h-[437px] w-1/2 p-6',
            'bg-auth-side bg-cover bg-center',
            'rounded-xl'
          )}
        >
          <LogoExtended className="h-12" />
        </div>
        <div className="flex w-1/2 flex-col justify-between gap-6 p-6">
          <Outlet />
        </div>
      </div>
    </main>
  );
};

const Title = (props: { children: ReactNode }) => (
  <h1 className="text-color-text-primary text-lg font-semibold tracking-tight">
    {props.children}
  </h1>
);

const SubTitle = (props: { children: ReactNode }) => (
  <div className="text-color-text-subtext flex flex-col gap-6 text-center text-sm">
    {props.children}
  </div>
);

const Container = (props: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { user, isPasswordRequired } = useContext(AuthContext);
  const { isDemo } = useDemo();

  useEffect(() => {
    if (user) {
      if (isDemo && !user['given_name']) {
        navigate(SET_UP_ACCOUNT_URL);
        return;
      }

      if (isPasswordRequired) {
        return;
      }

      navigate(rootUrl());
    }
  }, [user, navigate, isDemo, isPasswordRequired]);

  return <div className="flex flex-col gap-7">{props.children}</div>;
};

AuthLayout.Title = Title;
AuthLayout.SubTitle = SubTitle;
AuthLayout.Container = Container;
