import { ArrowLeft } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { ButtonWithTooltip, IconButton } from '@spektr/client/components';

import { useFormEngine } from '../../hooks/useFormEngine';
import { areCustomDataFieldsSet } from '../../utils/areCustomDataFieldsSet';

import { Title } from '../../components/Title';

export type FormDialogHeaderProps = {
  isSavePending?: boolean;
  processTitle: string;
  nodeTitle?: string;
  onClick?: () => void;
  onClickBack?: () => void;
};

export const FormDialogHeader = ({
  isSavePending = false,
  processTitle,
  nodeTitle,
  onClickBack,
}: FormDialogHeaderProps) => {
  const { saveForm, state } = useFormEngine();

  const areCustomFieldsSet = areCustomDataFieldsSet(
    Object.values(state.fields)
  );

  return (
    <div className={cn('border-b p-1.5', 'bg-color-bg-moonraker')}>
      <div className="flex flex-row justify-between">
        <div className={cn('flex flex-row gap-3')}>
          <IconButton
            color="secondary"
            className="h-9 w-9 rounded-md p-0"
            onClick={onClickBack}
          >
            <ArrowLeft className="h-4 w-4 shrink-0" />
            <span className="sr-only">Close</span>
          </IconButton>
          <Title processTitle={processTitle} nodeTitle={nodeTitle} />
        </div>
        <div className={cn('mt-0 flex flex-row items-center gap-6')}>
          <ButtonWithTooltip
            color="secondary"
            className="h-full transition-colors"
            disabled={isSavePending || !areCustomFieldsSet}
            showTooltip={!areCustomFieldsSet}
            tooltipProps={{
              content: 'Please fill out all required fields before saving.',
            }}
            onClick={saveForm}
          >
            {isSavePending ? 'Saving...' : 'Save form'}
            <span className="sr-only">Save</span>
          </ButtonWithTooltip>
        </div>
      </div>
    </div>
  );
};
