import { makeApi, makeEndpoint } from '@zodios/core';

import {
  CompanyOwnersSchema,
  ExecutionContextSchema,
  ExecutionContextWithDatasetSchema,
  GetExecutionContextsByIdList,
  datasetIdSchema,
  executionIdSchema,
  insightsResponseSchema,
  insightsResponseWithoutDatasetSchema,
  spektrIdSchema,
} from '../executionContext';
import { paginatedParameters } from '../pagination';

const getAllExecutions = makeEndpoint({
  method: 'get',
  path: '/executions',
  alias: 'getAllExecutions',
  response: insightsResponseSchema,
});

const getMostRecentExecutions = makeEndpoint({
  method: 'get',
  path: '/most-recent-executions',
  alias: 'getMostRecentExecutions',
  parameters: paginatedParameters,
  response: insightsResponseWithoutDatasetSchema,
});

const getAllExecutionsForDataset = makeEndpoint({
  method: 'get',
  path: '/executions/:datasetId',
  alias: 'getAllExecutionsForDataset',
  parameters: [
    {
      name: 'datasetId',
      schema: datasetIdSchema,
      type: 'Path',
      description: 'The ID of the dataset to get executions for',
    },
    ...paginatedParameters,
  ],
  response: insightsResponseSchema,
});

const getExecutionById = makeEndpoint({
  method: 'get',
  path: '/executions/id/:id',
  alias: 'getExecutionById',
  parameters: [
    {
      name: 'id',
      schema: executionIdSchema,
      type: 'Path',
      description: 'The ID of the execution to get',
    },
  ],
  response: ExecutionContextSchema,
});

const getOwnersById = makeEndpoint({
  method: 'get',
  path: '/owners/:datasetId/:recordId',
  alias: 'getOwnersById',
  parameters: [
    {
      name: 'datasetId',
      schema: datasetIdSchema,
      type: 'Path',
      description:
        'The dataset ID for which to search owners information of latest execution',
    },
    {
      name: 'recordId',
      schema: spektrIdSchema,
      type: 'Path',
      description:
        'The record (spektr) ID for which to search owners information of latest execution',
    },
  ],
  response: CompanyOwnersSchema,
});

const getAggregateRuns = makeEndpoint({
  method: 'get',
  path: '/aggregateRuns/:spektrId',
  alias: 'getAggregateRuns',
  parameters: [
    {
      name: 'spektrId',
      schema: spektrIdSchema,
      type: 'Path',
      description: 'client ID generated by us',
    },
  ],
  response: insightsResponseSchema,
});

const getExecutionsByIdList = makeEndpoint({
  method: 'post',
  path: '/executions/list',
  alias: 'getExecutionsByIdList',
  parameters: [
    {
      name: 'ids',
      schema: GetExecutionContextsByIdList,
      type: 'Body',
      description: 'List of execution IDs to get',
    },
  ],
  response: ExecutionContextWithDatasetSchema.array(),
});

export const insightsApi = makeApi([
  getAllExecutions,
  getAllExecutionsForDataset,
  getExecutionById,
  getOwnersById,
  getAggregateRuns,
  getMostRecentExecutions,
  getExecutionsByIdList,
]);
