import { DatasetRecord } from '../types';

export const getVisibleRecords = (
  records: DatasetRecord[],
  visibleColumns: string[]
): DatasetRecord[] => {
  if (!records.length || !visibleColumns.length) {
    return [];
  }

  return records.map((record) => {
    const visibleRecord = visibleColumns.reduce((acc, column) => {
      return {
        ...acc,
        [column]: record.fields[column],
      };
    }, {});

    return {
      id: record.id,
      fields: { ...visibleRecord },
    };
  });
};
