export const LogoExtended = ({
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="30"
      viewBox="0 0 107 30"
      fill="none"
      className={className}
    >
      <rect
        opacity="0.5"
        x="106.958"
        y="18.3721"
        width="27.3558"
        height="6.83991"
        rx="3.41995"
        transform="rotate(180 106.958 18.3721)"
        fill="#2BBEAF"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.758 27.0379C102.813 28.6738 100.722 29.2342 99.0859 28.2897L81.3182 18.0301C79.6827 17.0857 79.1224 14.9944 80.0666 13.3588C81.0109 11.7229 83.1027 11.1624 84.7384 12.107L102.506 22.3666C104.142 23.311 104.702 25.4023 103.758 27.0379Z"
        fill="#FDA7D9"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.744 2.96217C104.688 4.59777 104.128 6.68909 102.492 7.63347L84.7245 17.8931C83.0887 18.8376 80.997 18.2771 80.0526 16.6413C79.1085 15.0057 79.6688 12.9143 81.3043 11.97L99.072 1.71035C100.708 0.765811 102.8 1.32629 103.744 2.96217Z"
        fill="#FCAC04"
      />
      <ellipse
        cx="83.021"
        cy="14.9521"
        rx="3.41948"
        ry="3.41995"
        transform="rotate(180 83.021 14.9521)"
        fill="white"
      />
      <ellipse
        cx="103.538"
        cy="14.9521"
        rx="3.41948"
        ry="3.41995"
        transform="rotate(180 103.538 14.9521)"
        fill="#2BBEAF"
      />
      <ellipse
        cx="100.802"
        cy="25.226"
        rx="3.41948"
        ry="3.41995"
        transform="rotate(180 100.802 25.226)"
        fill="#FDA7D9"
      />
      <ellipse
        cx="100.802"
        cy="4.70651"
        rx="3.41948"
        ry="3.41995"
        transform="rotate(180 100.802 4.70651)"
        fill="#FCAC04"
      />
      <path
        d="M5.29125 21.4922C4.50004 21.4922 3.75827 21.3768 3.06596 21.1461C2.37365 20.8988 1.76375 20.5527 1.23627 20.1076C0.708797 19.6461 0.296706 19.0856 0 18.4263L2.79398 17.1405C3.04123 17.5362 3.37915 17.8823 3.80772 18.179C4.2363 18.4592 4.73081 18.5994 5.29125 18.5994C5.83521 18.5994 6.25554 18.5252 6.55225 18.3768C6.84896 18.212 6.99731 17.9812 6.99731 17.6845C6.99731 17.3878 6.87368 17.1735 6.62643 17.0416C6.39566 16.8933 6.07422 16.7697 5.66213 16.6708L4.52476 16.3741C3.33794 16.0609 2.39837 15.5664 1.70606 14.8905C1.03023 14.1982 0.692313 13.407 0.692313 12.5169C0.692313 11.2476 1.09616 10.2669 1.90386 9.57455C2.72804 8.88224 3.89014 8.53608 5.39015 8.53608C6.16488 8.53608 6.88192 8.65147 7.54127 8.88224C8.2171 9.11301 8.79403 9.43444 9.27205 9.84653C9.75008 10.2586 10.0798 10.7367 10.2611 11.2806L7.566 12.5169C7.45061 12.1872 7.17039 11.9235 6.72533 11.7257C6.28027 11.5114 5.83521 11.4042 5.39015 11.4042C4.94509 11.4042 4.59894 11.5031 4.35168 11.7009C4.12091 11.8823 4.00553 12.1378 4.00553 12.4674C4.00553 12.6817 4.12091 12.863 4.35168 13.0114C4.58245 13.1433 4.91213 13.2586 5.3407 13.3576L6.94786 13.7532C7.75556 13.951 8.39842 14.2724 8.87644 14.7175C9.37095 15.146 9.72535 15.6323 9.93964 16.1763C10.1704 16.7037 10.2858 17.223 10.2858 17.734C10.2858 18.4757 10.0633 19.1351 9.61821 19.712C9.18963 20.2724 8.59622 20.7093 7.83797 21.0224C7.09621 21.3356 6.2473 21.4922 5.29125 21.4922Z"
        fill="white"
      />
      <path
        d="M12.3088 26.1406V8.83279H15.3748L15.5726 11.1075V26.1406H12.3088ZM19.3062 21.4922C18.2677 21.4922 17.3858 21.2285 16.6605 20.701C15.9517 20.1735 15.4078 19.4235 15.0286 18.451C14.6495 17.4785 14.46 16.3328 14.46 15.0142C14.46 13.679 14.6495 12.5334 15.0286 11.5773C15.4078 10.6048 15.9517 9.85478 16.6605 9.3273C17.3858 8.79982 18.2677 8.53608 19.3062 8.53608C20.4435 8.53608 21.4243 8.79982 22.2485 9.3273C23.0891 9.85478 23.7403 10.6048 24.2018 11.5773C24.6633 12.5334 24.8941 13.679 24.8941 15.0142C24.8941 16.3328 24.6633 17.4785 24.2018 18.451C23.7403 19.4235 23.0891 20.1735 22.2485 20.701C21.4243 21.2285 20.4435 21.4922 19.3062 21.4922ZM18.4655 18.5004C19.0589 18.5004 19.5781 18.3521 20.0232 18.0554C20.4847 17.7587 20.8474 17.3466 21.1111 16.8191C21.3748 16.2916 21.5067 15.69 21.5067 15.0142C21.5067 14.3383 21.3748 13.7367 21.1111 13.2092C20.8639 12.6817 20.5095 12.2779 20.0479 11.9977C19.6029 11.7009 19.0836 11.5526 18.4902 11.5526C17.9298 11.5526 17.427 11.7009 16.982 11.9977C16.5369 12.2944 16.1907 12.7064 15.9435 13.2339C15.6962 13.7449 15.5726 14.3383 15.5726 15.0142C15.5726 15.69 15.6962 16.2916 15.9435 16.8191C16.1907 17.3466 16.5287 17.7587 16.9572 18.0554C17.4023 18.3521 17.905 18.5004 18.4655 18.5004Z"
        fill="white"
      />
      <path
        d="M32.6229 21.4922C31.4032 21.4922 30.3235 21.2203 29.3839 20.6763C28.4443 20.1158 27.7026 19.3494 27.1586 18.3768C26.6311 17.4043 26.3674 16.2834 26.3674 15.0142C26.3674 13.7449 26.6394 12.624 27.1833 11.6515C27.7438 10.679 28.502 9.92071 29.4581 9.37675C30.4306 8.81631 31.5433 8.53608 32.796 8.53608C33.8675 8.53608 34.8565 8.82455 35.7631 9.40148C36.6862 9.96192 37.4279 10.7943 37.9884 11.8987C38.5488 12.9867 38.829 14.3136 38.829 15.8795H35.5158C35.5323 14.7916 35.4169 13.9262 35.1697 13.2834C34.9389 12.624 34.6092 12.1542 34.1806 11.874C33.7521 11.5773 33.2493 11.429 32.6724 11.429C32.0295 11.429 31.4856 11.5608 31.0405 11.8246C30.5955 12.0883 30.2575 12.4922 30.0268 13.0361C29.8125 13.5636 29.7053 14.2559 29.7053 15.1131C29.7053 15.8548 29.8537 16.473 30.1504 16.9675C30.4471 17.462 30.8427 17.8411 31.3372 18.1048C31.8317 18.3521 32.3757 18.4757 32.9691 18.4757C33.612 18.4757 34.1312 18.3356 34.5268 18.0554C34.9389 17.7752 35.2521 17.4125 35.4664 16.9675L38.5323 18.1543C38.1862 18.8466 37.7329 19.4483 37.1724 19.9593C36.6285 20.4538 35.9774 20.8329 35.2191 21.0966C34.4609 21.3604 33.5955 21.4922 32.6229 21.4922ZM28.4938 15.8795L28.5185 13.3328H37.2219V15.8795H28.4938Z"
        fill="white"
      />
      <path
        d="M43.8412 18.0554L41.8878 15.8054L48.2176 8.83279H52.0995L43.8412 18.0554ZM40.7257 21.1955V3.8877H43.9895V21.1955H40.7257ZM48.6379 21.1955L44.5335 15.212L46.6599 12.9619L52.4703 21.1955H48.6379Z"
        fill="white"
      />
      <path
        d="M59.1236 21.4922C57.7555 21.4922 56.717 21.1543 56.0082 20.4785C55.3159 19.8027 54.9697 18.8384 54.9697 17.5856V4.97562H58.2335V16.8686C58.2335 17.396 58.3489 17.7999 58.5797 18.0801C58.8104 18.3439 59.1483 18.4757 59.5934 18.4757C59.7582 18.4757 59.9396 18.4428 60.1374 18.3768C60.3352 18.2944 60.5412 18.179 60.7555 18.0307L61.8929 20.4785C61.4973 20.7752 61.0522 21.0142 60.5577 21.1955C60.0797 21.3933 59.6017 21.4922 59.1236 21.4922ZM52.967 11.602V8.83279H61.3242V11.602H52.967Z"
        fill="white"
      />
      <path
        d="M66.2778 14.6927C66.2778 13.3576 66.5333 12.2531 67.0443 11.3795C67.5718 10.5059 68.2393 9.85478 69.047 9.4262C69.8712 8.99763 70.7284 8.78334 71.6185 8.78334V11.9482C70.8602 11.9482 70.1432 12.0389 69.4674 12.2202C68.808 12.4015 68.2723 12.6982 67.8602 13.1103C67.4481 13.5224 67.2421 14.0499 67.2421 14.6927H66.2778ZM63.9783 21.1955V8.83279H67.2421V21.1955H63.9783Z"
        fill="white"
      />
    </svg>
  );
};
