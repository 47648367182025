import { useRef } from 'react';
import {
  Button,
  BasicDialog,
  Label,
  Input,
  ButtonProps,
} from '@spektr/client/components';

import { Process } from '@spektr/shared/types';

export type RenameProcessDialogProps = {
  buttonLabel?: string;
  buttonColor?: ButtonProps['color'];
  open: boolean;
  process?: Process;
  title?: string;
  onClose: () => void;
  onSave: (name: string) => void;
};

export const RenameProcessDialog = ({
  buttonLabel = 'Rename process',
  buttonColor = 'cyan',
  open,
  process,
  title = 'Rename process',
  onClose,
  onSave,
}: RenameProcessDialogProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = () => {
    if (!inputRef.current) {
      return;
    }

    onSave(inputRef.current.value);
  };

  return (
    <BasicDialog
      className="w-[512px] rounded-2xl border-0"
      key={`${open}`}
      defaultOpen={open}
      title={title}
      onClose={onClose}
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-col gap-2">
          <Label
            htmlFor="rename-process-input"
            className="text-color-text-subtext"
          >
            Process name
          </Label>
          <Input
            autoFocus
            ref={inputRef}
            id="rename-process-input"
            defaultValue={process?.name}
          />
        </div>

        <div className="ml-auto flex flex-row gap-4">
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button color={buttonColor} onClick={handleSave}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </BasicDialog>
  );
};
