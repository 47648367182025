import { ReactNode, MouseEvent } from 'react';

import { cva } from 'class-variance-authority';

const methodVariants = cva(
  ['relative', 'rounded-md border', 'p-6', 'transition-all', 'cursor-pointer'],
  {
    variants: {
      disabled: {
        true: 'opacity-50 pointer-events-none',
        false: '',
      },
      selected: {
        true: ' border-color-green',
        false: '',
      },
      open: {
        true: 'grow',
        false: '',
      },
    },
    defaultVariants: {
      selected: false,
      open: false,
    },
  }
);

export type MethodItemProps = {
  children: ReactNode;
  className?: string;
  icon: ReactNode;
  renderExtra?: () => ReactNode;
  isSelected?: boolean;
  isOpen?: boolean;
  title: string;
  description: string;
  disabled?: boolean;
  onClick: (ev: MouseEvent<HTMLElement>) => void;
};

export const MethodItem = ({
  children,
  className,
  icon,
  renderExtra,
  isSelected = false,
  isOpen = false,
  title,
  description,
  onClick,
  disabled = false,
}: MethodItemProps) => (
  <div
    className={methodVariants({
      selected: isSelected && !disabled,
      disabled,
      open: isOpen,
      className,
    })}
    onClick={onClick}
    data-cy="method-item"
  >
    <div className="flex items-center gap-3">
      <div className="bg-color-bg-card-accent flex h-9 w-9 shrink-0 items-center justify-center rounded">
        {icon}
      </div>
      <div className="flex select-none flex-col">
        <span className="text-color-text-primary text-sm">{title}</span>
        <span className="text-color-text-subtext text-xs font-light">
          {description}
        </span>
      </div>
      {renderExtra && <div className="ml-auto">{renderExtra()}</div>}
    </div>
    {isOpen && <div className="mt-6">{children}</div>}
  </div>
);
