import { createContext, useContext } from 'react';

import { DatasetRecordFields } from '../types';

type RecordsContextType = {
  selectedDataset: string;
  visibleColumns: string[];
  areVisibleColumnsInitialized: boolean;
  initializeVisibleColumns: (datasetFields: DatasetRecordFields) => void;
  updateSelectedDataset: (datasetId: string) => void;
  updateFallbackDatasetId: (datasetId: string) => void;
  updateColumnVisibility: (
    name: string,
    isHidden: boolean,
    newIndex?: number
  ) => void;
};

export const RecordsContext = createContext<RecordsContextType>({
  selectedDataset: '',
  visibleColumns: [],
  areVisibleColumnsInitialized: false,
  initializeVisibleColumns: () => null,
  updateSelectedDataset: () => null,
  updateFallbackDatasetId: () => null,
  updateColumnVisibility: () => null,
});

export const useRecordsContext = () => useContext(RecordsContext);
