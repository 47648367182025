import { HTMLAttributes } from 'react';

export const FinScanIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="110"
    height="23"
    viewBox="0 0 110 23"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_8423_1544)">
      <path
        d="M0.186401 0.325745H16.3439V5.035H5.77096V8.64019H16.3421V13.3806H5.77096V21.9862H0.186401V0.325745Z"
        fill="#0B1929"
      />
      <path
        d="M18.0303 2.74203C18.0303 1.3589 19.1309 0.26001 20.5123 0.26001C21.8937 0.26001 22.9944 1.36062 22.9944 2.74203C22.9944 4.12344 21.8937 5.22405 20.5123 5.22405C19.1309 5.22405 18.0303 4.12517 18.0303 2.74203ZM18.0009 6.30216H23.0013V21.9864H18.0009V6.30216Z"
        fill="#0B1929"
      />
      <path
        d="M35.3074 12.9245C35.3074 11.0092 34.3004 10.3263 32.7422 10.3263C31.2481 10.3263 30.274 11.1375 29.6899 11.8533V21.9842H24.6895V6.3017H29.6899V8.21693C30.6311 7.11288 32.4822 5.91168 35.1133 5.91168C38.6855 5.91168 40.3079 7.9899 40.3079 10.8792V21.9842H35.3074V12.9245Z"
        fill="#0B1929"
      />
      <path
        d="M44.343 14.7431C45.9012 16.3013 48.3052 17.6012 51.2586 17.6012C53.1739 17.6012 54.3439 16.7901 54.3439 15.7501C54.3439 14.516 52.9486 14.029 50.6417 13.5419C47.0694 12.8278 42.0689 11.9179 42.0689 6.78743C42.0689 3.15106 45.1541 0 50.7057 0C54.1792 0 57.2003 1.03995 59.5053 3.01933L56.4531 7.01272C54.635 5.51869 52.264 4.77165 50.3487 4.77165C48.4976 4.77165 47.7835 5.51869 47.7835 6.45983C47.7835 7.59688 49.1146 7.98684 51.5187 8.44095C55.0909 9.18798 60.0255 10.2262 60.0255 15.1296C60.0255 19.4818 56.8105 22.3711 50.9987 22.3711C46.6153 22.3711 43.563 21.0071 41.386 18.9289L44.343 14.7431Z"
        fill="#0B1929"
      />
      <path
        d="M69.7331 5.91168C73.1114 5.91168 75.2206 7.4058 76.1636 8.80103L72.9156 11.8204C72.2987 10.9104 71.2913 10.3263 69.9601 10.3263C67.8823 10.3263 66.291 11.7545 66.291 14.1256C66.291 16.495 67.8823 17.9579 69.9601 17.9579C71.2913 17.9579 72.2987 17.3079 72.9156 16.4309L76.1636 19.4831C75.2228 20.8472 73.1114 22.3725 69.7331 22.3725C64.83 22.3725 61.1609 19.0602 61.1609 14.1239C61.1626 9.22399 64.8317 5.91168 69.7331 5.91168Z"
        fill="#0B1929"
      />
      <path
        d="M87.2724 21.1834C85.8841 21.998 84.4142 22.206 82.4018 22.206C79.9995 22.206 77.0116 20.7501 77.0116 17.2108C77.0116 13.4115 79.9995 12.2745 82.4018 12.2745H87.2724V12.1115C87.2724 10.7475 86.1039 9.83924 84.0898 9.83924C82.5315 9.83924 80.9078 10.4233 79.6426 11.4962L77.8556 8.34691C79.8697 6.62582 82.5645 5.91168 84.9668 5.91168C88.7333 5.91168 92.2727 7.30697 92.2727 12.1792V21.9859H87.2724V21.1834ZM87.2724 15.0078L84.4802 15.0182C83.149 15.0182 81.9789 15.6665 81.9789 17.0548C81.9789 18.3911 83.1474 19.0948 84.4802 19.0948C85.5843 19.0948 87.2724 19.0844 87.2724 19.0844V15.0078Z"
        fill="#0B1929"
      />
      <path
        d="M104.579 12.9245C104.579 11.0092 103.572 10.3263 102.014 10.3263C100.519 10.3263 99.5455 11.1375 98.961 11.8533V21.9842H93.9607V6.3017H98.961V8.21693C99.9024 7.11288 101.754 5.91168 104.385 5.91168C107.957 5.91168 109.579 7.9899 109.579 10.8792V21.9842H104.579V12.9245Z"
        fill="#0B1929"
      />
    </g>
    <defs>
      <clipPath id="clip0_8423_1544">
        <rect width="110" height="22.3729" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
