/**
 * @description
 *    Converts a Map to an array of its values.
 *  It also covers the case where the Map is
 *  an Object from JSON.
 *
 * @param value - The Map to convert.
 * @returns An array of the values in the Map or object.
 */
export function mapToArray<ValueType>(value: Map<string, ValueType>) {
  if (value instanceof Map) {
    return Array.from(value.values());
  }

  return Object.values(value) as ValueType[];
}
