import { Card } from '@spektr/shared/components';
import { Skeleton } from '@spektr/client/components';

export const VersionsSkeleton = () => (
  <Card className="flex max-h-[200px] flex-col gap-2 overflow-y-auto p-4">
    <div className="flex h-9 shrink-0 items-center justify-between">
      <div className="flex items-center gap-14">
        <Skeleton className="w-40" />
        <Skeleton className="w-40" />
      </div>
      <Skeleton />
    </div>
    <div className="flex h-9 shrink-0 items-center justify-between">
      <div className="flex items-center gap-14">
        <Skeleton className="w-40" />
        <Skeleton className="w-40" />
      </div>
    </div>
    <div className="flex h-9 shrink-0 items-center justify-between">
      <div className="flex items-center gap-14">
        <Skeleton className="w-40" />
        <Skeleton className="w-40" />
      </div>
    </div>
  </Card>
);
