import { z } from 'zod';

import { objectIdSchema } from '../common';
import { NodeServiceType } from '../node';

export const VendorConsumerStartDownstreamCallMessage = z.strictObject({
  type: z.literal('new'),
  vendor: NodeServiceType,
  executionContextId: objectIdSchema,
  vendorSearchId: objectIdSchema,
  workspaceId: z.string().min(1),
  processRunId: objectIdSchema,
  versionId: z.string().min(1).nullable(),
  processId: z.string().min(1),
  pollingId: z.string().optional(),
  mode: z.enum(['sandbox', 'live']),
});

export type VendorConsumerStartDownstreamCallMessage = z.infer<
  typeof VendorConsumerStartDownstreamCallMessage
>;

const preprocessRawKafkaMessageValue = (data: unknown) => {
  const stringified = data instanceof Buffer ? data.toString() : data;

  if (typeof stringified !== 'string') return stringified;

  try {
    return JSON.parse(stringified);
  } catch {
    return data;
  }
};

export const VendorConsumerRawMessage = z.preprocess(
  preprocessRawKafkaMessageValue,
  VendorConsumerStartDownstreamCallMessage
);
