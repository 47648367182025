import { ReactNode, useMemo, useState } from 'react';

import { Viewpoint } from '../../types/ViewpointsApi';

import { ViewpointsContext } from './context';

export type ViewpointsProviderProps = {
  children: ReactNode | ReactNode[];
};

export const ViewpointsProvider = ({ children }: ViewpointsProviderProps) => {
  const [viewpoint, setViewpoint] = useState<Viewpoint>('desktop');

  const api = useMemo(
    () => ({
      viewpoint,
      isViewpointActive: (value: Viewpoint) => viewpoint === value,
      setViewpoint,
    }),
    [viewpoint, setViewpoint]
  );

  return (
    <ViewpointsContext.Provider value={api}>
      {children}
    </ViewpointsContext.Provider>
  );
};
