import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import { getProcessDependenciesQuery } from '@spektr/client/services';

import {
  BatchUpdateDependencyMappings,
  SpektrFieldTypedKeyWithMapping,
} from '@spektr/shared/validators';

import { convertFieldsArrayToObject } from '../utils/convertFieldsArrayToObject';

import { FieldMappingTable } from '../components/FieldMappingTable';
import { FieldMappingFooter } from '../components/FieldMappingFooter';

import { useUpdateFieldMappingDependencies } from '../hooks';

type FieldMappingDialogContentProps = {
  processId: string;
  goBackToProcess: () => void;
};

export const FieldMappingDialogContent = ({
  processId,
  goBackToProcess,
}: FieldMappingDialogContentProps) => {
  const {
    data: { dependencies, upstream },
  } = useSuspenseQuery(getProcessDependenciesQuery(processId));

  const batchUpdateDependenciesMutation =
    useUpdateFieldMappingDependencies(goBackToProcess);

  const sourceFields = useMemo(
    () => upstream.map((supplier) => supplier.key),
    [upstream]
  );

  const formMethods = useForm({
    defaultValues: {
      fields: convertFieldsArrayToObject(dependencies),
    },
  });

  const handleSubmit = (submission: {
    fields: Record<string, SpektrFieldTypedKeyWithMapping> | null;
  }) => {
    if (!submission.fields) return;

    const updates: BatchUpdateDependencyMappings = [];

    for (const [key, value] of Object.entries(submission.fields)) {
      if (key === value.mapping) continue; // TODO: what to do about irrelevant mappings? should they be removed client-side or server-side?

      const supplier = upstream.find(
        (supplier) => supplier.key === value.mapping
      );

      if (!supplier) continue;

      updates.push({
        dependency: key,
        supplier: supplier.key,
        source: supplier.source === 'dataset' ? 'dataset' : 'process',
        sourceId: supplier.sourceId,
      });
    }

    batchUpdateDependenciesMutation.mutate(updates);
  };

  return (
    <form
      className="flex h-full grow flex-col gap-4"
      onSubmit={formMethods.handleSubmit(handleSubmit)}
    >
      <FormProvider {...formMethods}>
        <FieldMappingTable sourceFields={sourceFields} fields={dependencies} />
        <FieldMappingFooter />
      </FormProvider>
    </form>
  );
};
