import {
  Field,
  isEntitiesField,
  isWritableField,
} from '@spektr/moonraker-types';

export const areCustomDataFieldsSet = (fields: Field[]) =>
  fields.every((field) => {
    if (isWritableField(field)) {
      if (isEntitiesField(field)) {
        return Object.values(field.form.fields).every((f) => {
          if (isWritableField(f)) {
            return f.config.spektrDataField !== '';
          }
          return true;
        });
      }
      return field.config.spektrDataField !== '';
    }
    return true;
  });
