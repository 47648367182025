import { Suspense } from 'react';
import axios from 'axios';

import { SpektrErrorBoundary } from '@spektr/client/components';
import { ErrorPage } from '@spektr/shared/components';

import { EnsureDatasetData } from './containers/EnsureDatasetData';

export const InsightsRecordsIndex = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        const errorMsg = axios.isAxiosError(error)
          ? error.message
          : 'An error has occurred while retrieving the customer records!';
        return <ErrorPage title={errorMsg} />;
      }}
    >
      <Suspense fallback={<div>Loading</div>}>
        <EnsureDatasetData />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
