import { z } from 'zod';

export const kafkaEnvironment = z.object({
  KAFKA_BOOTSTRAP_SERVER: z.string().min(1),
  KAFKA_TOPIC: z.string().min(1),
});

export const kafkaBootstrapServer = z.object({
  KAFKA_BOOTSTRAP_SERVER: z.string().min(1),
});

export const kafkaIOEnvironment = kafkaEnvironment.merge(
  z.object({
    KAFKA_GROUP_ID: z.string().min(1),
  })
);
