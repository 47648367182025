import { useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { Button, Input, Label } from '@spektr/client/components';

export const ResetPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [allowError, setAllowError] = useState(false);
  const fetcher = useFetcher();

  const error =
    fetcher?.data?.action === 'error'
      ? fetcher?.data?.error?.message
      : undefined;

  const showError = error && allowError && fetcher.state === 'idle';

  return (
    <fetcher.Form
      method="post"
      onChange={() => setAllowError(false)}
      onSubmit={() => setAllowError(true)}
    >
      <input name="formId" hidden defaultValue="reset-password" />

      <Label htmlFor="email" className="text-sm">
        Email
      </Label>
      <Input
        id="email"
        name="email"
        type="email"
        autoComplete="email"
        required
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      {showError && <div className="text-color-red mt-6 text-sm">{error}</div>}
      <Button
        className="mt-6 w-full"
        type="submit"
        disabled={!email || fetcher.state !== 'idle'}
      >
        {fetcher.state === 'submitting'
          ? 'Sending reset code to email...'
          : 'Send reset code to email'}
      </Button>
    </fetcher.Form>
  );
};
