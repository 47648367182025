import axios from 'axios';
import { CircleX, Search } from 'lucide-react';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { BasicDialog } from '@spektr/client/components';

import {
  NodeUpdateInput,
  ServiceNode as ServiceNodeType,
} from '@spektr/shared/types';

import { ServiceFieldsListSkeleton } from '../../components/ServiceFieldsListSkeleton';

import { MonitoringServiceNodeContent } from './MonitoringServiceNodeContent';

export type MonitoringServiceNodeProps = {
  processId: string;
  node: ServiceNodeType;
  onClose: () => void;
  onUpdate: (data: NodeUpdateInput) => void;
};

export const MonitoringServiceNode = ({
  processId,
  node,
  onClose,
  onUpdate,
}: MonitoringServiceNodeProps) => {
  return (
    <BasicDialog
      className="max-w-[700px]"
      defaultOpen
      title={node.title}
      description="Select the specific checks you want the service to execute and determine their frequency. Frequencies are defined for each check category, allowing you to apply different timings to various checks for maximum cost efficiency."
      icon={<Search className="text-color-text-icon-primary mr-3 h-5 w-5" />}
      onClose={onClose}
    >
      <ErrorBoundary
        fallbackRender={(error) => {
          const errorMsg = axios.isAxiosError(error)
            ? error.response?.data?.message
            : 'Something went wrong when loading the loop details.';
          return (
            <div className="my-2 flex min-h-[200px]  gap-4 rounded-md border p-4">
              <div className="m-auto flex items-center justify-center gap-2">
                <CircleX className="text-color-red h-6 w-6" />
                <p className="text-color-red">{errorMsg}</p>
              </div>
            </div>
          );
        }}
      >
        <Suspense
          fallback={
            <div className="my-2 flex min-h-[200px] flex-col gap-4 rounded-md border p-4">
              <ServiceFieldsListSkeleton title="Map attributes from your dataset" />
              <ServiceFieldsListSkeleton title="Select categories and frequency" />
            </div>
          }
        >
          <MonitoringServiceNodeContent
            processId={processId}
            node={node}
            onUpdate={onUpdate}
          />
        </Suspense>
      </ErrorBoundary>
    </BasicDialog>
  );
};
