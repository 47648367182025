import { useSuspenseQuery } from '@tanstack/react-query';

export type Currency = {
  symbol: string;
  name: string;
  symbol_native: string;
  decimal_digits: number;
  rounding: number;
  code: string;
  name_plural: string;
};

type Currencies = Record<string, Currency>;

export function useCurrencyList() {
  // TODO: @Alex - make this functionality reusable
  const currenciesUrl = new URL(
    '/assets/data/currencies.json',
    window.location.origin
  );

  return useSuspenseQuery({
    queryKey: ['currencies'] as const,
    queryFn: () =>
      fetch(currenciesUrl.toString()).then((response) =>
        response.json()
      ) as Promise<Currencies>,
  });
}
