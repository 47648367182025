import { PageHeadline } from '@spektr/client/components';
import { ReactNode } from 'react';

import { ConnectionHubTabNavigation } from '../ConnectionHubTabNavigation';

export type ConnectionHubRootLayoutProps = {
  children: ReactNode | ReactNode[];
};

export const ConnectionHubRootLayout = ({
  children,
}: ConnectionHubRootLayoutProps) => {
  return (
    <div className="absolute inset-0">
      <div className="relative h-full w-full overflow-y-auto">
        <div className="box-border flex h-full flex-col px-4 pt-5">
          <div className="mb-5 flex shrink-0 flex-row items-center">
            <PageHeadline headline="Connection Hub" />
          </div>

          <ConnectionHubTabNavigation />

          {children}
        </div>
      </div>
    </div>
  );
};
