import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { cn } from '@spektr/client/utils';

import { ComboboxGroup, Dropdown } from '@spektr/client/components';

import { CompanyNumberFormat } from '@spektr/moonraker-types';

export type CompanyNameFormatDropdownProps = {
  value?: string;
  label: string;
  onChangeFormat: (format: CompanyNumberFormat) => void;
};

export const CompanyNameFormatDropdown = ({
  value,
  label,
  onChangeFormat,
}: CompanyNameFormatDropdownProps) => {
  const [searchValue, setSearch] = useState('');
  const { data } = useQuery({
    queryKey: ['assets', 'registration-number'],
    queryFn: async () => {
      const url = new URL(
        '/assets/data/business-registration-number.json',
        window.location.origin
      );
      return fetch(url.toString()).then((response) =>
        response.json()
      ) as Promise<CompanyNumberFormat[]>;
    },
  });

  const handleSelect =
    (toggleOpen: (value: boolean) => void) => (format: CompanyNumberFormat) => {
      toggleOpen(false);
      onChangeFormat(format);
    };

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter((item) =>
      item.label.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [data, searchValue]);

  const categories = useMemo(() => {
    if (!filteredData) {
      return [];
    }

    return filteredData.reduce((acc, item) => {
      if (!acc.includes(item.category)) {
        acc.push(item.category);
      }
      return acc;
    }, [] as string[]);
  }, [filteredData]);

  const selectedItem = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data.find((item) => item.id === value);
  }, [data, value]);

  return (
    <div className={cn('flex flex-col gap-2', 'px-3')}>
      <div className="flex flex-row gap-2">
        <label className="text-color-text-subtext text-xs font-medium">
          {label}
        </label>
      </div>
      <Dropdown
        hasSearch
        inputProps={{
          placeholder: 'Search for a company number format',
        }}
        renderTrigger={(isOpen, setOpen) => (
          <button
            className={cn(
              'flex items-center gap-2',
              'h-9 w-full px-3',
              'rounded-md border',
              'text-color-text-link-secondary',
              'text-left text-xs font-medium'
            )}
            onClick={() => setOpen(!isOpen)}
          >
            {selectedItem?.label ?? 'Select a company number format'}
          </button>
        )}
        renderContent={(setOpen) => (
          <div>
            {categories.map((category) => (
              <ComboboxGroup label={category} key={category}>
                <ul>
                  {filteredData
                    ?.filter((item) => item.category === category)
                    .map((item) => (
                      <li
                        key={item.label}
                        className={cn(
                          'w-full',
                          'rounded-sm',
                          'text-sm font-medium',
                          'color-color-text-tertiary',
                          'hover:bg-color-bg-accordion-item--hover'
                        )}
                      >
                        <button
                          className={cn('w-full p-1 pl-2', 'text-left', {
                            'font-semibold': item.id === selectedItem?.id,
                          })}
                          onClick={() => handleSelect(setOpen)(item)}
                        >
                          {item.label}
                        </button>
                      </li>
                    ))}
                </ul>
              </ComboboxGroup>
            ))}
          </div>
        )}
        onSearch={setSearch}
      />
    </div>
  );
};
