import { Info } from 'lucide-react';

import { Tooltip } from '@spektr/client/components';

type HelperTextTooltip = {
  helperText: string;
};

export const HelperTextTooltip = ({ helperText }: HelperTextTooltip) => {
  return (
    <Tooltip
      content={helperText}
      side="right"
      align="end"
      contentClassName="bg-color-bg-tooltip text-color-text-primary mb-1"
    >
      <Info className="text-color-text-primary/60 h-3 w-3" />
    </Tooltip>
  );
};
