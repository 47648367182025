import { ReactNode } from 'react';

export type ParagraphFieldProps = {
  children: ReactNode;
};

export const ParagraphField = ({ children }: ParagraphFieldProps) => (
  <p className="text-color-text-form-viewer-paragraph text-sm font-medium">
    {children}
  </p>
);
ParagraphField.displayName = 'ParagraphField';
