import {
  ProcessNode,
  isFilterNodeType,
  isLoopSourceNodeType,
  isOutcomeNodeType,
} from '@spektr/shared/types';

import {
  GraphEdgeData,
  GraphEdgeType,
  GraphNodeData,
  GraphNodeType,
  GraphOutcomeEdgeData,
  BaseGraphNodeData,
} from './types';

function createGraphNode<T>(id: string, type: GraphNodeType, data: T) {
  return {
    id,
    type,
    data,
    position: { x: 0, y: 0 },
  };
}

function createFilterGraphNode(data: GraphNodeData<ProcessNode>) {
  return createGraphNode(data.node.id, 'processFilter', data);
}

function createBasicGraphNode(data: GraphNodeData<ProcessNode>) {
  return createGraphNode(data.node.id, 'basicNode', data);
}

function createOutcomeGraphNode(data: GraphNodeData<ProcessNode>) {
  return createGraphNode(data.node.id, 'outcomeNode', data);
}

function createLoopSourceGraphNode(data: GraphNodeData<ProcessNode>) {
  return createGraphNode(data.node.id, 'loopSource', data);
}

// TODO: turn this into phantom node
export function createVirtualGraphNode(data: BaseGraphNodeData) {
  return createGraphNode<BaseGraphNodeData>(data.process.id, 'virtual', data);
}

export function factoryGraphNode(data: GraphNodeData<ProcessNode>) {
  if (isFilterNodeType(data.node)) {
    return createFilterGraphNode(data);
  }

  if (isOutcomeNodeType(data.node)) {
    return createOutcomeGraphNode(data);
  }

  if (isLoopSourceNodeType(data.node)) {
    return createLoopSourceGraphNode(data);
  }

  return createBasicGraphNode(data);
}

function createGraphEdge<T>(
  type: GraphEdgeType,
  source: string,
  target: string,
  data?: T
) {
  return {
    id: `edge-${source}-${target}`,
    source,
    target,
    type,
    animated: true,
    data,
  };
}

export function createAddNewGraphNode(
  id: string,
  data: GraphNodeData<ProcessNode>
) {
  return createGraphNode(`new-leaf-${id}`, 'addNew', data);
}

export function createWorkflowGraphEdge(
  source: string,
  target: string,
  data: GraphEdgeData
) {
  return createGraphEdge<GraphEdgeData>(
    'edgeIntermediary',
    source,
    target,
    data
  );
}

export function createLeafEdge(source: string, target: string) {
  return createGraphEdge('default', source, target);
}

export function createSegmentEdge(
  source: string,
  target: string,
  data: GraphOutcomeEdgeData
) {
  return createGraphEdge<GraphOutcomeEdgeData>(
    'edgeSegment',
    source,
    target,
    data
  );
}
