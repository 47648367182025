import { SkeletonProcessCard } from '@spektr/client/components';

import { ProcessType } from '@spektr/shared/validators';

export type SkeletonProcessCardProps = {
  hasTimestamp?: boolean;
  type: ProcessType;
};

export const SkeletonProcessList = () => {
  const rows = new Array(4).fill(null).map((_, index) => ({
    key: 'row-' + index,
  }));

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {rows.map(({ key }) => (
        <SkeletonProcessCard key={key} hasTimestamp />
      ))}
    </div>
  );
};
