import { useNavigate, useSearchParams } from 'react-router-dom';

import { cn } from '@spektr/client/utils';
import { insightsActionDetailsUrl } from '@spektr/shared/utils';

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

import { ActionTableRowData } from '../../types/ActionTableRowData';

import { ActionRow } from '../ActionRow';

const baseThClasses = 'text-xs text-color-text-table-header p-3';

export type ActionsTableProps = {
  actions: ActionTableRowData[];
};

export const ActionsTable = ({ actions }: ActionsTableProps) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const handleClickReview = (id: string) => {
    navigate(insightsActionDetailsUrl(id), {
      state: { state: queryParams.get('state') },
    });
  };

  return (
    <Table className="border-separate">
      <TableHeader>
        <TableRow className="rounded-tl-md rounded-tr-md hover:bg-inherit">
          <TableHead
            className={cn('rounded-tl-md border border-r-0', baseThClasses)}
          >
            Name
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-y')}>
            Process
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-y')}>
            Source
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-y')}>
            Created at
          </TableHead>
          <TableHead
            className={cn('rounded-tr-md border border-l-0', baseThClasses)}
          />
        </TableRow>
      </TableHeader>
      <TableBody>
        {actions.map((row, index) => (
          <ActionRow
            key={row.id}
            rowItem={row}
            isLastRow={index === actions.length - 1}
            onClickReview={handleClickReview}
          />
        ))}
      </TableBody>
    </Table>
  );
};
