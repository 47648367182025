import { Info } from 'lucide-react';

import {
  Label,
  RadioCheckbox,
  RadioGroup,
  Tooltip,
} from '@spektr/client/components';

import { ServiceFieldClient } from '../../types/ServiceFieldClient';

type MitIdLoginFieldsProps = {
  selectedKey?: string;
  fields: ServiceFieldClient[];
  onKeyUpdate: (key: string) => void;
};

export const MitIdLoginFields = ({
  selectedKey,
  fields,
  onKeyUpdate,
}: MitIdLoginFieldsProps) => {
  const handleLoginRadioChange = (value: string) => {
    onKeyUpdate(value);
  };
  return (
    <div className="flex flex-col gap-2">
      <Label>Login Type</Label>

      <RadioGroup
        value={selectedKey}
        onValueChange={handleLoginRadioChange}
        className="flex flex-col gap-2 pl-6"
      >
        {fields.map((field) => (
          <RadioCheckbox
            key={field.key}
            id={field.key}
            value={field.key}
            label={
              <span className="flex items-center gap-2 whitespace-normal">
                {field.label}
                {field.help && (
                  <Tooltip content={field.help}>
                    <Info className="text-color-text-icon-secondary h-3 w-3" />
                  </Tooltip>
                )}
              </span>
            }
          />
        ))}
      </RadioGroup>
    </div>
  );
};
