import { z } from 'zod';
import { ActionFunctionArgs, redirect } from 'react-router-dom';

import { AuthError } from '@spektr/model-builder/auth';
import {
  CONFIRM_RESET_PASSWORD_URL,
  formDataToRecord,
} from '@spektr/shared/utils';

const schema = z.object({
  email: z.string().email(),
});

export default async function resetPasswordAction({
  request,
}: ActionFunctionArgs) {
  const formData = await request.formData();

  try {
    const { email } = schema.parse(formDataToRecord(formData));

    await import('../auth/authService').then((module) =>
      module.default.sendForgotPasswordCodeToUserEmail(email)
    );

    return redirect(
      `${CONFIRM_RESET_PASSWORD_URL}?email=${encodeURIComponent(email)}`
    );
  } catch (error) {
    return { action: 'error', error: new AuthError(error) };
  }
}
