import { cn } from '@spektr/client/utils';

export const mitIdContained = cn(
  'bg-color-mitId',
  'dark:text-color-text-button-contained-vendor text-color-white',
  'hover:bg-color-mitId',
  'active:bg-color-mitId'
);
export const mitIdOutlined = cn(
  mitIdContained,
  'border-color-mitId',
  'text-color-mitId',
  'hover:bg-color-mitId/10',
  'active:bg-color-mitId/20',
  'disabled:border-color-mitId/50'
);
export const mitIdText = cn('text-color-mitId');
