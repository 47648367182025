/**
 * @example ['riskMatrix']
 */
export function getRiskMatricesQueryKey() {
  return ['riskMatrix'];
}

/**
 * @example ['riskMatrix', '123']
 */
export function getRiskMatrixQueryKey(riskMatrixId: string) {
  return ['riskMatrix', riskMatrixId];
}

/**
 * @example ['riskMatrixTemplate']
 */
export function getAllRiskMatricesTemplatesQueryKey() {
  return ['riskMatrixTemplate'];
}

/**
 * @example ['riskMatrixTemplate', '123']
 */
export function getRiskMatrixTemplateQueryKey(riskMatrixTemplateId: string) {
  return ['riskMatrixTemplate', riskMatrixTemplateId];
}
