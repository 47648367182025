import { z } from 'zod';

import { BaseField } from './BaseField';
import { FieldValidation } from './FieldValidation';

export const FileField = BaseField.merge(
  z.object({
    type: z.literal('file'),
    attributes: z.object({
      label: z.string(),
      fileType: z.string(),
      placeholder: z.string().optional(),
      helperText: z.string().optional(),
    }),
    validation: z.array(FieldValidation),
  })
);

export type FileField = z.infer<typeof FileField>;

export function isFileField(field: unknown): field is FileField {
  return FileField.safeParse(field).success;
}
