import { cn } from '@spektr/client/utils';

type KyckrFilterItemProps = {
  children: React.ReactNode;
};

export const KyckrFilterItem = ({ children }: KyckrFilterItemProps) => {
  return (
    <div
      className={cn(
        'flex items-center gap-3',
        'h-[53px] pl-5 pr-2.5',
        'border-b last:border-0'
      )}
    >
      {children}
    </div>
  );
};
