import { ArrowUpDown } from 'lucide-react';

import { IconWithBackground } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

export type ReturningProcessNodeProps = {
  title: string;
};

export const ReturningProcessNode = ({ title }: ReturningProcessNodeProps) => {
  return (
    <>
      <div>
        <IconWithBackground
          className={cn('ml-5 mr-4 h-10 w-10', 'bg-color-cyan')}
        >
          <ArrowUpDown className="stroke-color-stroke-white h-5 w-5" />
        </IconWithBackground>
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-color-text-node text-sm font-medium">{title}</h3>
        <span className="text-color-text-node-subtext text-xs">
          Returning process step
        </span>
      </div>
    </>
  );
};
