import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Map, Network, Route } from 'lucide-react';

import {
  BasicDialog,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@spektr/client/components';

import { cn } from '@spektr/client/utils';

import { GraphView, StrategyView } from '@spektr/strategy-view';

export const StrategyDialog = ({
  processId,
  processUrl,
}: {
  processId: string;
  processUrl: string;
}) => {
  const navigate = useNavigate();
  const [graphView, setGraphView] = useState<GraphView>('map');

  const goBackToProcess = () => {
    navigate(processUrl);
  };

  return (
    <BasicDialog
      defaultOpen
      className="text-color-text-dialog-default h-full max-h-[76%] max-w-[76%]"
      title="Strategy"
      icon={
        <div
          className={cn(
            'flex items-center justify-center',
            'mr-2 h-6 w-6',
            'bg-color-bg-accent rounded-md'
          )}
        >
          <Route className="text-color-text-icon-primary h-3 w-3" />
        </div>
      }
      onClose={goBackToProcess}
    >
      <div className="relative -mx-4 -mb-4 mt-2 h-full">
        <Tabs
          value={graphView}
          className="absolute left-4 top-4 z-10 w-[240px] text-xs"
          onValueChange={(value) => setGraphView(value as GraphView)}
        >
          <TabsList className="w-full">
            <TabsTrigger value="map" className="gap-1.5">
              <Map className="h-4 w-4" /> Map
            </TabsTrigger>
            <TabsTrigger value="path" className="gap-1.5">
              <Network className="h-4 w-4" /> Path
            </TabsTrigger>
          </TabsList>
        </Tabs>

        <StrategyView
          key={graphView}
          defaultSelected={processId}
          activeView={graphView}
        />
      </div>
    </BasicDialog>
  );
};
