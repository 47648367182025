export const LOOPS_KEYS = {
  ALL: () => ['loops', 'list'] as const,
  DETAILS: (loopId: string) => ['loops', 'details', loopId] as const,
  DETAILS_SANDBOX_EXECUTION: (loopId: string, sandboxId: string) =>
    ['loops', 'details', loopId, 'sandboxExecution', sandboxId] as const,
  NODE_DETAILS: (loopId: string, nodeId: string) =>
    ['loops', 'details', loopId, 'node', nodeId] as const,
  NODE_ALLOWED_FIELDS: (loopId: string, nodeId: string) =>
    ['loops', 'details', loopId, 'node', nodeId, 'allowedFields'] as const,
  NODE_ACTION_FIELDS: (loopId: string, nodeId: string) =>
    ['loops', 'details', loopId, 'node', nodeId, 'actionFields'] as const,
};
