import { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { CodeXml, Cog, PaintBucket, User } from 'lucide-react';

import {
  Button,
  PageHeadline,
  Tabbar,
  TabbarItem,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import {
  LOGIN_URL,
  SETTINGS_BRANDING_FULL_URL,
  SETTINGS_DEVELOPER_FULL_URL,
  SETTINGS_WORKSPACE_FULL_URL,
} from '@spektr/shared/utils';
import { RBAC } from '@spektr/shared/rbac';
import { useFeatureFlags } from '@spektr/platform-hooks';

import { AuthContext } from '../../auth/auth-context';
import { HeaderWithHandler } from '../../components/HeaderWithHandler';

export function SettingsIndexPage() {
  const { logOut } = useContext(AuthContext);
  const { hasPermission } = usePermissionsContext();
  const { branding } = useFeatureFlags();
  const navigate = useNavigate();

  const navigateToLogin = () =>
    navigate(LOGIN_URL, {
      state: {
        redirectFrom: null,
      },
    });

  const handleLogOut = () => logOut(navigateToLogin);

  return (
    <>
      <HeaderWithHandler />

      <div className="relative flex flex-grow flex-col overflow-hidden">
        <div className="px-5 pt-5">
          <div className="flex flex-row justify-between">
            <PageHeadline headline="Settings" paragraph="" />
            <Button color="red" onClick={handleLogOut}>
              Log out
            </Button>
          </div>
          <Tabbar>
            <TabbarItem
              icon={<User className="h-4 w-4" />}
              to={{ pathname: '/settings' }}
            >
              Account
            </TabbarItem>
            <TabbarItem
              icon={<Cog className="h-4 w-4" />}
              to={SETTINGS_WORKSPACE_FULL_URL}
            >
              Workspace
            </TabbarItem>
            {hasPermission(RBAC.ACTIONS.API_KEYS.VIEW) && (
              <TabbarItem
                icon={<CodeXml className="h-4 w-4" />}
                to={SETTINGS_DEVELOPER_FULL_URL}
              >
                Developer
              </TabbarItem>
            )}
            {hasPermission(RBAC.ACTIONS.BRANDING.VIEW) && branding && (
              <TabbarItem
                icon={<PaintBucket className="h-4 w-4" />}
                to={SETTINGS_BRANDING_FULL_URL}
              >
                Default Form Styling
              </TabbarItem>
            )}
          </Tabbar>
        </div>

        <Outlet />
      </div>
    </>
  );
}
