import { Skeleton, TableRow } from '@spektr/client/components';

import { SettingsTableCell } from '../SettingsTableCell';
import { ApiKeysTable } from '../ApiKeysTable';

export const TableSkeleton = () => (
  <ApiKeysTable>
    {[...Array(3)].map((_, index) => (
      <TableRow key={index}>
        <SettingsTableCell>
          <Skeleton className="h-3 w-full max-w-36" />
        </SettingsTableCell>
        <SettingsTableCell>
          <Skeleton className="h-3 w-full max-w-64" />
        </SettingsTableCell>
        <SettingsTableCell>
          <div className="grow-1 flex justify-end">
            <Skeleton className="h-5 w-5" />
          </div>
        </SettingsTableCell>
      </TableRow>
    ))}
  </ApiKeysTable>
);
