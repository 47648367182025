import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { connectionDataSetsQuery } from '@spektr/client/services';

export const useGetDatasets = () => {
  const { data: datasets } = useSuspenseQuery(connectionDataSetsQuery());

  const datasetsWithMapping = useMemo(() => {
    if (!datasets) return [];

    return datasets.filter((dataset) => Boolean(dataset.fieldId));
  }, [datasets]);

  return datasetsWithMapping;
};
