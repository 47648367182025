import { Filter } from 'lucide-react';
import { useState } from 'react';
import { useMeasure } from 'react-use';

import * as ScrollArea from '@radix-ui/react-scroll-area';

import { useDialogClose } from '@spektr/shared/hooks';

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@spektr/client/components';

export type ProcessDrawerProps = {
  defaultTab: string;
  description?: string;
  renderTabContent: (tabId: string) => JSX.Element;
  tabs: Array<{ id: string; label: string }>;
  title: string;
  onClose: () => void;
};

export const ProcessDrawer = ({
  defaultTab,
  description,
  renderTabContent,
  tabs,
  title,
  onClose,
}: ProcessDrawerProps) => {
  const [drawerRef, { height: drawerHeight }] = useMeasure<HTMLDivElement>();
  const [drawerHeaderRef, { height: drawerHeaderHeight }] =
    useMeasure<HTMLDivElement>();

  const [isOpen, startExitAnimation] = useDialogClose();
  const [activeTab, setActiveTab] = useState(defaultTab);

  // TODO: uncomment after activating the search
  // const location = useLocation();
  // const [searchParams, setSearchParams] = useSearchParams();

  // const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
  //   setSearchParams(
  //     { search: event.target.value },
  //     {
  //       state: location.state,
  //     }
  //   );
  // };

  const contentHeight = drawerHeight - drawerHeaderHeight;

  return (
    <Sheet open={isOpen} modal={false}>
      <SheetContent
        ref={drawerRef}
        modal={false}
        className="absolute max-w-[400px] overflow-hidden p-0"
        requestStartExitAnimation={startExitAnimation}
        onEndExitAnimation={onClose}
      >
        <SheetHeader ref={drawerHeaderRef}>
          <section className="p-4 pb-0">
            <div className="flex items-center gap-4">
              <Filter className="stroke-color-stroke-icon h-6 w-6" />
              <SheetTitle>{title}</SheetTitle>
              <SheetClose className="ml-auto" onClick={startExitAnimation} />
            </div>
            <section className="grid gap-4 pt-4">
              {/* Inactive at the moment */}
              {/* <Input
                placeholder="Search"
                name="search"
                defaultValue={searchParams.get('search') ?? undefined}
                onChange={handleChangeSearch}
                autoComplete="off"
              /> */}

              {description && (
                <SheetDescription>{description}</SheetDescription>
              )}

              <Tabs
                value={activeTab}
                className="text-xs"
                onValueChange={setActiveTab}
              >
                <TabsList>
                  {tabs.map((tab) => (
                    <TabsTrigger key={tab.id} value={tab.id}>
                      {tab.label}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            </section>
          </section>
        </SheetHeader>

        <ScrollArea.Root
          className={`h-[${contentHeight}px] overflow-hidden`}
          style={{
            height: `${contentHeight}px`,
          }}
        >
          <ScrollArea.Viewport className="h-full w-full">
            {renderTabContent(activeTab)}
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="bg-color-bg-scrollbar hover:bg-color-bg-scrollbar--hover flex touch-none select-none p-0.5 transition-colors duration-150 ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="bg-color-bg-scrollbar--hover relative flex-1 rounded-[10px] before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Corner />
        </ScrollArea.Root>
      </SheetContent>
    </Sheet>
  );
};
