import { z } from 'zod';
import { rgbaColor } from '@spektr/branding-types';
import { objectIdSchema } from '../common';

export const customerStatuses = ['onboarded', 'pending', 'rejected'] as const;
export const CustomerStatuses = z.enum(customerStatuses);
export type CustomerStatus = z.infer<typeof CustomerStatuses>;

export const CustomerTags = z.enum([
  'approved',
  'tested',
  'verified',
  'highRisk',
]);
export type CustomerTags = z.infer<typeof CustomerTags>;
export const CustomerTagCreate = z.object({
  label: z.string().min(1),
  color: rgbaColor,
});
export type CustomerTagCreate = z.infer<typeof CustomerTagCreate>;

export const ClientRecordTag = z.object({
  tagId: objectIdSchema,
  sourceProcessId: z.string().optional(), //tags that are set manually do not have a sourceProcessId
  createdAt: z.number(),
  updatedAt: z.number(),
});
export type ClientRecordTag = z.infer<typeof ClientRecordTag>;

export const TagUpdate = z.object({
  tagId: z.string().min(1),
  action: z.union([z.literal('add'), z.literal('remove')]),
});

export type TagUpdate = z.infer<typeof TagUpdate>;

export const CustomerTypes = z.enum(['individual', 'company']);
export type CustomerTypes = z.infer<typeof CustomerTypes>;

export const IndicatorTypes = z.enum([
  'has_sanctions',
  'has_adverse_media',
  'is_pep',
  'has_id_verification',
]);
export type IndicatorTypes = z.infer<typeof IndicatorTypes>;

export const AlertPresenceFilters = z.enum([
  'showAll',
  'showAlertsOnly',
  'showNoAlertsOnly',
]);
export type AlertPresenceFilters = z.infer<typeof AlertPresenceFilters>;

export const AlertTypeFilters = z.enum(['sanctions', 'adverse_media', 'AML']);
export type AlertTypeFilters = z.infer<typeof AlertTypeFilters>;
