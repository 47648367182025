import { CircleArrowUp, CircleX, FileUp, X } from 'lucide-react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { cn, formatBytes } from '@spektr/client/utils';

import { iconBoxVariants } from '@spektr/client/components';

export type FileUploaderProps = DropzoneOptions & {
  currentFile?: File;
  onRemove: () => void;
};

export const FileUploader = ({
  currentFile,
  onRemove,
  ...dropZoneProps
}: FileUploaderProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    ...dropZoneProps,
    maxFiles: 1,
  });

  if (currentFile) {
    return (
      <div
        className={cn(
          'bg-color-yellow/20',
          'rounded-md',
          'flex flex-row items-start p-4'
        )}
      >
        <FileUp
          className={cn(
            'mr-3 h-10 w-10',
            iconBoxVariants({ variant: 'yellow' })
          )}
        />
        <div className={cn('flex h-full flex-col justify-center')}>
          <span className={cn('text-color-text-primary text-sm')}>
            {currentFile.name}
          </span>
          <span className="text-color-text-error-boundry text-xs">
            {formatBytes(currentFile.size)}
          </span>
        </div>
        <X
          className={cn(
            'text-color-text-icon-primary',
            'ml-auto h-5 w-5',
            'cursor-pointer'
          )}
          onClick={onRemove}
        />
      </div>
    );
  }

  if (isDragReject) {
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={cn(
            'border-color-border-file rounded border border-dashed',
            'flex h-28 w-full flex-col items-center justify-center',
            'cursor-pointer'
          )}
        >
          <CircleX className="stroke-spektr-red mb-1 h-9 w-9" />
          <span className="text-color-text-error-boundry text-sm">
            We cannot accept this file!
          </span>
        </div>
      </div>
    );
  }

  if (isDragActive && isDragAccept) {
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={cn(
            'border-color-border-file rounded border border-dashed',
            'flex h-28 w-full flex-col items-center justify-center',
            'cursor-pointer'
          )}
        >
          <CircleArrowUp className="stroke-spektr-yellow mb-1 h-9 w-9" />
          <span className="text-color-text-error-boundry text-sm">
            Almost there, drop it here!
          </span>
        </div>
      </div>
    );
  }

  return (
    <div {...getRootProps()} className="outline-none">
      <input {...getInputProps()} />
      <div
        className={cn(
          'border-color-border-file rounded border border-dashed',
          'flex h-28 w-full flex-col items-center justify-center',
          'cursor-pointer'
        )}
      >
        <CircleArrowUp className="stroke-spektr-yellow mb-1 h-9 w-9" />
        <span className="text-color-text-error-boundry text-sm">
          Drop your file here, or{' '}
          <span className="text-color-yellow">Browse</span>
        </span>
      </div>
    </div>
  );
};
