import type { FormState } from '../providers/FormEngine/types';

export const DEFAULT_STATE: FormState = {
  fields: {},
  order: [],
  selectedFieldsId: null,
  branding: {
    primaryColor: 'rgba(0, 0, 0, 1)',
    borderColor: 'rgba(114, 114, 126, 1)',
    textColor: 'rgba(0, 0, 0, 1)',
    subTextColor: 'rgba(114, 114, 126, 1)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Arial',
    borderRadius: 0,
    gapSpacing: 0,
  },
  assistingContent: {
    title: 'Lorem ipsum dolor sit amet',
    description: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
        ],
      },
    ],

    support: {
      display: true,
      title: 'Lorem ipsum dolor sit amet',
      content: [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
          ],
        },
      ],
      backgroundColor: 'rgba(0, 46, 115, 1)',
      textColor: 'rgba(255, 255, 255, 1)',
    },
  },
};
