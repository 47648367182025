import { HTMLAttributes } from 'react';

export const CSIWatchdogIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="110"
    height="43"
    viewBox="0 0 110 43"
    fill="none"
    {...props}
  >
    <path
      d="M91.1932 13.2345L77.5188 14.7925V26.084V36.349L110 42.5812V11.1082L91.1932 13.2345Z"
      fill="#F15D22"
    />
    <path
      d="M89.4334 13.4361V0L67.4554 6.08565V24.1593L77.5188 24.3793V14.7925L89.4334 13.4361Z"
      fill="#F8971D"
    />
    <path
      d="M89.4335 13.4361L77.5188 14.7925V24.3793L89.4335 24.6542V13.4361Z"
      fill="url(#paint0_linear_8423_1552)"
    />
    <path
      d="M91.1932 13.2345L89.4335 13.4361V24.6542L77.5188 24.3793V26.084L91.1932 26.3773V13.2345Z"
      fill="url(#paint1_linear_8423_1552)"
    />
    <path
      d="M0 20.5482C0 27.9353 6.61723 33.911 16.4973 33.911C18.1836 33.911 20.0533 33.7277 21.8314 33.4161L23.6277 28.9069C21.7214 29.5667 19.5767 29.915 17.2855 29.915C9.53175 29.915 7.27712 25.7174 7.27712 20.5299C7.27712 15.3425 9.55008 11.1448 17.2855 11.1448C19.5584 11.1448 21.7214 11.4931 23.6277 12.153L25.0758 8.50526C22.7295 7.66206 19.2835 7.18547 16.4973 7.18547C6.61723 7.2038 0 13.1611 0 20.5482ZM53.6527 7.46043H60.5449V33.4711H53.6527V7.46043ZM42.8195 18.3669L39.5934 16.9189C36.8439 15.6174 35.1208 14.8109 35.1208 13.5644C35.1208 12.1713 37.1005 11.0898 39.8867 11.0898C42.3429 11.0898 45.0742 11.6214 47.2555 12.208L47.3104 12.1163L48.7585 8.46859C45.8807 7.6254 42.8195 7.16714 39.74 7.16714C31.2531 7.16714 26.8722 10.4299 26.8722 14.6459C26.8722 18.6236 31.803 20.9515 34.7359 22.3629L37.8154 23.7194C41.4264 25.3691 42.6179 26.2306 42.6179 27.5321C42.6179 28.9618 40.6382 29.9883 37.4854 29.9883C34.1126 29.9883 31.2715 29.1635 28.6686 28.302L27.1655 32.0597C30.5749 33.2695 34.0577 33.8927 37.7787 33.8927C47.2005 33.8927 50.8482 30.19 50.8482 26.2306C50.8849 22.253 46.5039 20.1083 42.8195 18.3669Z"
      fill="#717073"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8423_1552"
        x1="78.633"
        y1="15.0762"
        x2="88.4674"
        y2="22.8625"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1017" stopColor="#DF241B" />
        <stop offset="0.1848" stopColor="#E32F1C" />
        <stop offset="0.2734" stopColor="#E63A1D" />
        <stop offset="0.4795" stopColor="#EC4D20" />
        <stop offset="0.6883" stopColor="#F05921" />
        <stop offset="0.9024" stopColor="#F15D22" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8423_1552"
        x1="83.2966"
        y1="18.6056"
        x2="91.1328"
        y2="26.4417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F15D22" />
        <stop offset="0.3895" stopColor="#F37120" />
        <stop offset="1" stopColor="#F8971D" />
      </linearGradient>
    </defs>
  </svg>
);
