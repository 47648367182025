import { Share } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { WidgetProperties, WidgetTypes } from '@spektr/shared/validators';

export type WidgetPreviewProps = {
  properties: WidgetProperties;
  type: WidgetTypes;
  onClick: () => void;
};

export const WidgetPreview = ({
  type,
  properties,
  onClick,
}: WidgetPreviewProps) => {
  if (type === 'headline') {
    return (
      <h1
        className="text-color-text-subtext cursor-pointer text-lg font-semibold"
        onClick={onClick}
      >
        {properties.label}
      </h1>
    );
  }

  if (type === 'paragraph') {
    return (
      <p
        className="text-color-text-subtext cursor-pointer text-sm font-medium"
        onClick={onClick}
      >
        {properties.label}
      </p>
    );
  }

  if (type === 'upload') {
    return (
      <div
        className={cn(
          'flex flex-col items-center justify-center',
          'p-6',
          'rounded-lg border border-dashed',
          'text-color-text-primary',
          'cursor-pointer'
        )}
        onClick={onClick}
      >
        <Share className="h-8 w-8" />
        <h3 className=" mt-4 font-medium">{properties.label}</h3>
        <p className="mt-2 text-center text-xs">{properties.description}</p>
      </div>
    );
  }

  return (
    <div
      className="text-color-text-subtext flex cursor-pointer flex-col gap-1"
      onClick={onClick}
    >
      <p className="text-sm">{properties.label}</p>
      <p className="text-xs">{properties.description}</p>
      <div
        className={cn(
          'flex flex-col items-start justify-center',
          'h-9 px-4',
          'light-primary-border rounded-md border',
          'text-xxs'
        )}
      >
        <span className="light-primary-placeholder text-xs">
          {properties.placeholder}
        </span>
      </div>
    </div>
  );
};
