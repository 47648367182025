import { Theme, ThemeProvider } from '@spektr/client/providers';

import { Button, ButtonProps } from '../Button';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './common';

export interface AlertDialogProps {
  open: boolean;
  title: string;
  paragraph: string;
  onCancel: () => void;
  cancel: string;
  onConfirm: () => void;
  confirm: string;
  confirmDataCy?: string;
  disableButtons?: boolean;
  confirmButtonColor?: ButtonProps['color'];
  theme?: Theme;
}

export const AlertDialog = ({
  open,
  title,
  paragraph,
  onCancel,
  cancel,
  onConfirm,
  confirm,
  disableButtons = false,
  confirmButtonColor = 'red',
  confirmDataCy = '',
  theme,
}: AlertDialogProps) => {
  return (
    <ThemeProvider defaultTheme={theme}>
      <Dialog open={open}>
        <DialogContent className="w-[512px] rounded-2xl border-0">
          <DialogHeader>
            <DialogTitle className="text-color-text-dialog-title text-base font-semibold">
              {title}
            </DialogTitle>
          </DialogHeader>
          <DialogDescription className="bg-color-bg-primary text-color-text-dialog-description px-0 pb-4 pt-2 text-sm">
            {paragraph}
          </DialogDescription>
          <DialogFooter>
            <Button
              className="text-color-text-subtext text-xs"
              variant="text"
              disabled={disableButtons}
              onClick={onCancel}
            >
              {cancel}
            </Button>
            <Button
              className="text-xs"
              color={confirmButtonColor}
              onClick={onConfirm}
              disabled={disableButtons}
              data-cy={confirmDataCy}
            >
              {confirm}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};
