import { Edge, Node, getIncomers, getOutgoers } from 'reactflow';

export const getNodeById = (nodes: Node[], id?: string) => {
  return nodes.find((node) => node.id === id);
};

export const getAllParentNodes = (
  node: Node,
  nodes: Node[],
  edges: Edge[]
): Node[] =>
  getIncomers(node, nodes, edges).reduce<Node[]>(
    (memo, incomer) => [
      ...memo,
      incomer,
      ...getAllParentNodes(incomer, nodes, edges),
    ],
    []
  );

export const getAllChildNodes = (
  node: Node,
  nodes: Node[],
  edges: Edge[]
): Node[] =>
  getOutgoers(node, nodes, edges).reduce<Node[]>(
    (memo, outgoer) => [
      ...memo,
      outgoer,
      ...getAllChildNodes(outgoer, nodes, edges),
    ],
    []
  );

export const getSelectedPathNodes = (
  node: Node,
  nodes: Node[],
  edges: Edge[]
) => [
  ...getAllParentNodes(node, nodes, edges).reverse(),
  node,
  ...getAllChildNodes(node, nodes, edges),
];
