import { cn } from '@spektr/client/utils';

import { Skeleton } from '@spektr/client/components';

import { SkeletonTable } from '../SkeletonTable';

export const SkeletonHitDetails = () => (
  <div
    className={cn(
      'flex flex-col gap-6',
      'rounded-md p-6',
      'mb-6 mr-6',
      'bg-color-bg-skeleton-card'
    )}
  >
    <div className={cn('flex flex-row')}>
      <Skeleton className="h-6 w-[160px]" />

      <div className={cn('flex flex-row gap-4', 'ml-auto')}>
        <Skeleton className="h-5 w-[100px]" />
      </div>
    </div>
    <div className="flex flex-col gap-4">
      <SkeletonTable />
      <div className={cn('flex grow flex-row', 'pb-5 pt-4')}>
        <div className="ml-auto flex flex-row gap-4">
          <Skeleton className="h-9 w-24" />
          <Skeleton className="h-9 w-24" />
        </div>
      </div>
    </div>
  </div>
);
