import { useNavigate } from 'react-router-dom';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { processBuilderUrl } from '@spektr/shared/utils';

import {
  ProcessApiClient,
  getLiveProcessVersionByProcessId,
  getLiveVersionByProcessIdQueryKey,
  getVersionOverviewByProcessId,
} from '@spektr/client/services';
import { toast } from '@spektr/client/components';

import { VersionsItem } from '../../components/VersionsItem';

export type ProcessVersionsProps = {
  processId: string;
};

export const ProcessVersions = ({ processId }: ProcessVersionsProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: overview } = useSuspenseQuery(
    getVersionOverviewByProcessId(processId)
  );

  const { data: liveVersion } = useSuspenseQuery(
    getLiveProcessVersionByProcessId(processId)
  );

  const makeLiveVersionMutation = useMutation({
    mutationFn: ({ versionId }: { versionId: string }) =>
      ProcessApiClient.getClient().launchVersion(undefined, {
        params: { processId, versionId },
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: getLiveVersionByProcessIdQueryKey(processId),
      }),
  });

  const removeLiveVersionMutation = useMutation({
    mutationFn: () =>
      ProcessApiClient.getClient().removeLaunchedVersion(undefined, {
        params: { processId },
      }),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: getLiveVersionByProcessIdQueryKey(processId),
      }),
  });

  const executeProcessMutation = useMutation({
    mutationFn: () =>
      ProcessApiClient.getClient().executeProcessById(undefined, {
        params: { id: processId },
      }),
    onSuccess: () => {
      toast.success({
        title: 'Your process is now running!',
        description:
          'Your process is running, check Insights to see how things are progressing.',
      });
      navigate(processBuilderUrl(processId));
    },
  });

  const handleClickGoLive = (versionId: string) =>
    makeLiveVersionMutation.mutate({ versionId });
  const handleClickStopLive = async () =>
    await removeLiveVersionMutation.mutateAsync();
  const handleClickRunNow = async () =>
    await executeProcessMutation.mutateAsync();

  return (
    <VersionsItem
      isLiveActionPending={
        removeLiveVersionMutation.isPending || makeLiveVersionMutation.isPending
      }
      isRunActionPending={executeProcessMutation.isPending}
      overview={overview}
      liveVersion={liveVersion}
      onClickGoLive={handleClickGoLive}
      onClickStopLive={handleClickStopLive}
      onClickRunNow={handleClickRunNow}
    />
  );
};
