import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  ACTIONS_KEYS,
  DATASETS_KEYS,
  ActionsApiClient,
} from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';

import { UpdateHitMutationArg } from '../types';

export function useUpdateHit(
  actionId: string,
  datasetId: string,
  spektrId: string
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ actionId, hitId, ...body }: UpdateHitMutationArg) => {
      return ActionsApiClient.getClient().updateActionHitById(body, {
        params: {
          actionId: actionId,
          hitId: hitId,
        },
      });
    },
    onSuccess: async () => {
      await invalidateMultipleQueries(queryClient, [
        {
          queryKey: ACTIONS_KEYS.BY_ID(actionId),
        },
        { queryKey: DATASETS_KEYS.ALL_ACTIONS(datasetId, spektrId) },
      ]);
    },
  });
}
