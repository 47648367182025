import { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

import { BannerNotificationItem, BannerNotificationType } from '../types';
import { AddBannerFnMetaArg } from '../types/AddBannerFn';

export function createBannerNotification(
  message: ReactNode,
  type: BannerNotificationType = 'info',
  meta?: AddBannerFnMetaArg
): BannerNotificationItem {
  return {
    id: uuid(),
    type,
    message,
    ...meta,
    priority: meta?.priority ?? 3,
  };
}
