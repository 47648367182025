import { useSuspenseQuery } from '@tanstack/react-query';

import { ProcessFilterDialog } from '@spektr/model-builder/containers';

import { processDetailsParamsSchema } from '@spektr/shared/utils';

import { getProcessByIdQuery } from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';

export const FilterNodeDialog = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const { data } = useSuspenseQuery(getProcessByIdQuery(processId));

  if (!data) return null;

  return <ProcessFilterDialog process={data} />;
};
