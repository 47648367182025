import { Suspense } from 'react';

import { BadgeNotifications } from '@spektr/client/providers';
import { InsightsTabNavigation } from '@spektr/client/components';

import { InsightsTabNavigationWithNotifications } from './components/InsightsTabNavigationWithNotifications';

export const InsightsNavigation = () => {
  return (
    <Suspense fallback={<InsightsTabNavigation />}>
      <BadgeNotifications>
        <InsightsTabNavigationWithNotifications />
      </BadgeNotifications>
    </Suspense>
  );
};
