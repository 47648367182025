import { z } from 'zod';

import { spektrFieldTypeSchema } from '../common';

import { widgetFieldType } from './WidgetFieldType';
import { WidgetTypes } from './WidgetTypes';

const baseField = z.object({
  type: spektrFieldTypeSchema,
  name: z.string(),
  defaultValue: z.string().optional(),
});

const datasetField = baseField.extend({
  fieldType: z.literal(widgetFieldType.Values.dataset),
});

const customField = baseField.extend({
  fieldType: z.literal(widgetFieldType.Values.custom),
});

export type ActionDatasetField = z.infer<typeof datasetField>;
export type ActionCustomField = z.infer<typeof customField>;
export type ActionField = ActionDatasetField | ActionCustomField;

export const WidgetProperties = z.strictObject({
  label: z.string(),
  description: z.string(),
  placeholder: z.string(),
  field: z.union([datasetField, customField]),
});

export type WidgetProperties = z.infer<typeof WidgetProperties>;

export const WidgetValidationItem = z.strictObject({
  key: z.string(),
  message: z.string(),
  value: z.string(),
});
export type WidgetValidationItem = z.infer<typeof WidgetValidationItem>;

export const WidgetInstance = z.object({
  id: z.string(),
  index: z.number(),
  type: WidgetTypes,
  properties: WidgetProperties,
  validation: z.array(WidgetValidationItem),
});
export type WidgetInstance = z.infer<typeof WidgetInstance>;

export const ActionForm = z.array(WidgetInstance);
export type ActionForm = z.infer<typeof ActionForm>;
