import { ReactNode, createContext, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { SpektrField } from '@spektr/shared/types';

export type TemplateContextType = {
  spektrFields: SpektrField[];
};

const TemplateContext = createContext<TemplateContextType>({
  spektrFields: [],
});

export function useTemplateContext() {
  return useContext(TemplateContext);
}

export type TemplateProviderProps = {
  children: ReactNode[];
  spektrFields: SpektrField[];
};

export const TemplateProvider = ({
  children,
  spektrFields,
}: TemplateProviderProps) => {
  return (
    <TemplateContext.Provider
      value={{
        spektrFields,
      }}
    >
      <DndProvider backend={HTML5Backend}>{children}</DndProvider>
    </TemplateContext.Provider>
  );
};
