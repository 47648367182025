import { useSearchParams } from 'react-router-dom';

import { includes } from '@spektr/shared/utils';

import { ProcessType, processTypes } from '@spektr/shared/validators';

export function useProcessTypeFromUrl(
  defaultProcessType: ProcessType = 'risk'
): ProcessType {
  const [params] = useSearchParams();
  const processTypeFromParams = params.get('processType');

  return includes(processTypes, processTypeFromParams)
    ? processTypeFromParams
    : defaultProcessType;
}

export default useProcessTypeFromUrl;
