import { Suspense } from 'react';
import axios from 'axios';

import {
  InsightsErrorDashboard,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { RecordOwnershipList } from './containers/RecordOwnershipList';

import { RecordOwnershipGroup } from './components/RecordOwnershipGroup';
import { SkeletonTable } from './components/SkeletonTable';

export const InsightsRecordDetailsOwnershipDashboard = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          return (
            <div className="text-spektr-zinc-400">
              There are no ownership records at the moment
            </div>
          );
        }

        const errorMsg = axios.isAxiosError(error)
          ? error.message
          : 'An error has occurred while retrieving the actions!';
        return <InsightsErrorDashboard message={errorMsg} />;
      }}
    >
      <Suspense
        fallback={
          <div className="flex flex-col gap-4">
            <RecordOwnershipGroup label="Owners">
              <SkeletonTable rows={3} />
            </RecordOwnershipGroup>
            <RecordOwnershipGroup label="Controllers & Representatives">
              <SkeletonTable rows={3} />
            </RecordOwnershipGroup>
          </div>
        }
      >
        <RecordOwnershipList />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
