import { Textarea, type TextareaProps } from '@spektr/client/components';

import { HelperTextTooltip } from '../../HelperTextTooltip';

export type TextareaFieldProps = TextareaProps & {
  label: string;
  helperText?: string;
};

export const TextareaField = ({
  label,
  helperText,
  ...props
}: TextareaFieldProps) => {
  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={props.name}
        >
          {label}
        </label>
        {helperText && <HelperTextTooltip helperText={helperText} />}
      </div>
      <Textarea id={props.name} {...props} />
    </div>
  );
};
