import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';

import { cn } from '@spektr/client/utils';

export type AccordionTriggerProps = ComponentPropsWithoutRef<
  typeof Accordion.Trigger
> & {
  iconPosition?: 'left' | 'right';
  iconClassName?: string;
};

export const AccordionTrigger = forwardRef<
  ElementRef<typeof Accordion.Trigger>,
  AccordionTriggerProps
>(
  (
    { children, className, iconPosition = 'left', iconClassName, ...props },
    forwardedRef
  ) => (
    <Accordion.Header className="flex">
      <Accordion.Trigger
        className={cn(
          'group',
          'flex flex-1 items-center justify-between p-4',
          'cursor-pointer leading-none outline-none',
          {
            'flex-row-reverse': iconPosition === 'right',
          },
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <ChevronDown
          data-test-id="accordion-trigger-icon"
          className={cn(
            'h-4 w-4',
            'text-color-text-icon-secondary',
            '-rotate-90',
            'transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-0',
            iconClassName
          )}
          aria-hidden
        />
        {children}
      </Accordion.Trigger>
    </Accordion.Header>
  )
);
