import { ElementRef, ReactNode, forwardRef } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { Label } from '../Label';

import {
  RadioGroupItem,
  RadioGroupItemProps,
} from './components/RadioGroupItem';

export type RadioCheckboxProps = RadioGroupItemProps & {
  id: string;
  label?: ReactNode;
};

export const RadioCheckbox = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioCheckboxProps
>(({ label, ...props }, ref) => {
  if (label) {
    return (
      <div className="flex items-center space-x-2">
        <RadioGroupItem ref={ref} {...props} />
        <Label htmlFor={props.id}>{label}</Label>
      </div>
    );
  }
  return <RadioGroupItem ref={ref} {...props} />;
});
