import { useContext } from 'react';

import { SettingsWorkspace } from '@spektr/model-builder/containers';

import authService from '../../auth/authService';
import { AuthContext } from '../../auth/auth-context';

export const SettingsWorkspaceLoader = () => {
  return authService.getUser();
};

export function SettingsWorkspacePage() {
  const { user } = useContext(AuthContext);

  return <SettingsWorkspace user={user} />;
}
