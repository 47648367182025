import { z } from 'zod';

export const DataImportInput = z.array(z.record(z.unknown()));
export type DataImportInput = z.infer<typeof DataImportInput>;

export const DataUpdateInputSchema = z.strictObject({
  data: DataImportInput,
});
export type DataUpdateInputSchema = z.infer<typeof DataUpdateInputSchema>;

export const DataImportInputSchema = z.strictObject({
  data: DataImportInput,
  name: z.string().min(1),
});
export type DataImportInputSchema = z.infer<typeof DataImportInputSchema>;

export const DataImportResponseSchema = z.strictObject({
  datasetId: z.string().uuid(),
});
export type DataImportResponseSchema = z.infer<typeof DataImportResponseSchema>;
