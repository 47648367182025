import { FixedToolbar } from './FixedToolbar';
import { FloatingToolbar } from './FloatingToolbar';

type ToolbarProps = {
  floating?: boolean;
  children: React.ReactNode;
};

export const Toolbar = ({ floating = false, ...props }: ToolbarProps) => {
  return floating ? (
    <FloatingToolbar {...props} />
  ) : (
    <FixedToolbar {...props} />
  );
};
