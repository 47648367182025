import { Dataset } from '@spektr/shared/types';
import { SpektrData } from '@spektr/shared/validators';

export function getIdentifierValue(data: SpektrData, dataset: Dataset) {
  if (!dataset?.fieldId) {
    throw new Error('Dataset identifier is missing');
  }

  return String(data[dataset.fieldId]);
}
