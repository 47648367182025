import z from 'zod';

import {
  AlertPresenceFilters,
  AlertTypeFilters,
  CustomerStatuses,
  CustomerTags,
} from '../customer/customer';

export const SortOrder = z.enum(['asc', 'desc']);
export type SortOrder = z.infer<typeof SortOrder>;

export const FilterOptions = z
  .object({
    sortOrder: SortOrder.optional(),
    sortField: z.string().optional(),
    searchField: z.string().optional(),
    searchValue: z.coerce.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.searchValue && !val.searchField) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'searchField is required when searchValue is defined',
        path: ['searchField'],
      });
    }
  });
export type FilterOptions = z.infer<typeof FilterOptions>;

export const CustomerFilter = z.object({
  status: z.array(CustomerStatuses).optional(),
  tags: z.array(CustomerTags).optional(),
  alertPresence: z.array(AlertPresenceFilters).optional(),
  alertType: z.array(AlertTypeFilters).optional(),
  isUbo: z.boolean().optional().optional(),
  datasetId: z.string().optional(),
  createdAtStart: z.string().optional(),
  createdAtEnd: z.string().optional(),
  updatedAtStart: z.string().optional(),
  updatedAtEnd: z.string().optional(),
  searchQuery: z.string().optional(),
});
export type CustomerFilter = z.infer<typeof CustomerFilter>;
