import { useMemo } from 'react';

export const useValidateMatrix = (
  name: string,
  columns: string[],
  rows: string[][],
  scores: string[]
) => {
  return useMemo(() => {
    if (!name.trim()) return 'Matrix name is required';

    if (columns.some((column) => !column.trim()))
      return 'Header names are required';

    if (rows.length === 0) return 'At least one row is required';

    if (
      scores.some((score) => !score.trim()) ||
      rows.some((row) => row.some((cell) => !cell.trim()))
    )
      return 'Please fill in all cells';

    return '';
  }, [name, columns, rows, scores]);
};
