export const InsightsRunDetails = () => {
  return (
    <div className="flex h-full w-full">
      <div className="h-full w-[368px] shrink-0 border-r">
        <div className="h-[191px] border-b p-7">user details</div>
        <div className="p-7">customer journey</div>
      </div>
      <div className="w-full p-6">runs</div>
    </div>
  );
};
