import { RiskMatrix } from '@spektr/model-builder/containers';

import { DashboardWrapper } from './DashboardWrapper';

export const RiskMatrixDashboard = () => {
  return (
    <DashboardWrapper>
      <RiskMatrix />
    </DashboardWrapper>
  );
};
