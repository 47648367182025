import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';

import { FieldMappingTableHeader } from './FieldMappingTableHeader';

const baseRowCellClasses = 'px-4 w-1/3 border-b text-xs';

export const SkeletonFieldMappingTable = () => {
  return (
    <div className="relative w-full rounded-md border">
      <Table className="border-separate">
        <FieldMappingTableHeader />
        <TableBody>
          {new Array(4).fill(null).map((_, index) => (
            <TableRow className="h-[52px]" key={index}>
              <TableCell className={baseRowCellClasses}>
                <div className="w-1/3">
                  <Skeleton className="h-5 w-52" />
                </div>
              </TableCell>
              <TableCell className={baseRowCellClasses}>
                <div className="w-1/3">
                  <Skeleton className="h-5 w-48" />
                </div>
              </TableCell>
              <TableCell className={baseRowCellClasses}>
                <div className="w-1/3">
                  <Skeleton className="h-5 w-32" />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
