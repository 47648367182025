import { Outlet } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { ProcessBuilder } from '@spektr/model-builder/process-builder';

import { processDetailsParamsSchema } from '@spektr/shared/utils';

import {
  getProcessByIdQuery,
  getProcessLinksByIdQuery,
} from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';

export const ProcessBuilderDetail = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const { data } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: links } = useSuspenseQuery(getProcessLinksByIdQuery(processId));

  return (
    <div className="flex h-full w-full flex-col justify-between">
      <div className="relative flex-grow overflow-hidden">
        <ProcessBuilder process={data} links={links} />
        <Outlet />
      </div>
    </div>
  );
};
