import { useCallback, useMemo } from 'react';

import { cn } from '@spektr/client/utils';

import { Widget } from '../../components/Widget';

import { WidgetBlock } from '../../types/WidgetBlock';
import { WidgetBlockGroups } from '../../types/WidgetBlockGroups';

export type WidgetToolbarProps = {
  blocks: WidgetBlock[];
};

export const WidgetToolbar = ({ blocks }: WidgetToolbarProps) => {
  const groups = useMemo(() => {
    return blocks.reduce<Record<WidgetBlockGroups, WidgetBlock[]>>(
      (acc, block) => {
        if (!acc[block.group]) {
          acc[block.group] = [];
        }
        acc[block.group].push(block);
        return acc;
      },
      {}
    );
  }, [blocks]);

  const renderGroup = useCallback(
    (blocks: WidgetBlock[]) => (
      <div className="flex flex-col gap-2">
        {blocks.map((block) => (
          <Widget
            key={block.type}
            icon={block.icon}
            label={block.label}
            type={block.type}
          />
        ))}
      </div>
    ),
    []
  );

  return (
    <div
      className={cn(
        'flex h-full flex-col gap-6 px-6 py-4',
        'border-color-border-secondary border-r',
        'text-color-text-widget',
        'shadow-spektr-sidebar'
      )}
    >
      <div>
        <p className="text-color-text-primary text-sm font-medium">
          Drag & Drop fields
        </p>
        <p className="text-color-text-widget-toolbar-description text-xs">
          Drag onto form
        </p>
      </div>
      {Object.entries(groups).map(([group, blocks]) => (
        <div className="flex flex-col gap-1" key={group}>
          <p className="text-xxs font-semibold uppercase">{group}</p>
          {renderGroup(blocks)}
        </div>
      ))}
    </div>
  );
};
