import { cn } from '@spektr/client/utils';

export const openCorporatesContained = cn(
  'bg-color-openCorporates',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-openCorporates',
  'active:bg-color-openCorporates',
  'border'
);
export const openCorporatesOutlined = cn(
  openCorporatesContained,
  'border-color-openCorporates',
  'text-color-openCorporates',
  'hover:bg-color-openCorporates/10',
  'active:bg-color-openCorporates/20',
  'disabled:border-color-openCorporates/50'
);
export const openCorporatesText = cn('text-color-openCorporates');
