import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';

import { cn } from '@spektr/client/utils';

import {
  insightsActionHitDetailsUrl,
  insightsActionsParamsSchema,
} from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';
import { ScrollArea } from '@spektr/client/components';

import {
  AnyHitData,
  GetActionHitByIdResponse,
  HitDecisionStatus,
  HitWithVendorDetails,
} from '@spektr/shared/validators';

import { useHitsProviderContext } from '../../providers';
import { ClientActionType, UpdateHitMutationArg } from '../../types';

import { HitCard } from '../HitCard';

export type HitsListProps = {
  actionType: ClientActionType;
  subject: string;
  data: HitWithVendorDetails[];
  isMutationPending?: boolean;
  onUpdateHit: (
    payload: UpdateHitMutationArg
  ) => Promise<GetActionHitByIdResponse>;
};

export const HitsList = ({
  actionType,
  data,
  subject,
  isMutationPending,
  onUpdateHit,
}: HitsListProps) => {
  const navigate = useNavigate();
  const { actionId } = useParsedParams(insightsActionsParamsSchema);
  const [ref, size] = useMeasure<HTMLDivElement>();
  const { selectHit, deselectHit, isHitSelected, isPendingUpdate } =
    useHitsProviderContext();

  const [pendingActionsIds, setPendingActionsIds] = useState<string[]>([]);

  const handleUpdateHit =
    (decision: HitDecisionStatus) =>
    (reason: string) =>
    async (hitId: string) => {
      if (decision === 'pending') return;

      setPendingActionsIds((prev) => [...prev, hitId]);

      const response = await onUpdateHit({
        actionId: actionId,
        hitId: hitId,
        status: decision,
        reason,
      });

      setPendingActionsIds((prev) => prev.filter((id) => id !== response.id));
    };

  const handleClickHit = (hitId: string) => {
    navigate(insightsActionHitDetailsUrl(actionId, hitId));
  };

  const handleCheckedChange = (hitId: string, isChecked: boolean) => {
    if (isChecked) {
      selectHit(hitId);
    } else {
      deselectHit(hitId);
    }
  };

  return (
    <div ref={ref} className="relative h-full w-full overflow-hidden">
      <ScrollArea
        style={{
          height: size.height,
        }}
      >
        <div
          className={cn(
            'relative',
            'grid grid-cols-1 gap-6 py-6 2xl:grid-cols-2 min-[2160px]:grid-cols-3',
            'mr-2'
          )}
        >
          {data.map((hit) => {
            return (
              <HitCard
                key={hit.id}
                id={hit.id}
                actionId={actionId}
                className="w-full rounded-md"
                data={hit as AnyHitData}
                commentCount={hit.commentCount}
                decision={hit.decision}
                vendorDetails={hit.vendorDetails}
                subject={subject}
                hitType={actionType}
                isDisabled={
                  hit.decision.status !== 'pending' ||
                  (pendingActionsIds.includes(hit.id) && isMutationPending) ||
                  isPendingUpdate
                }
                spektrAI={hit.spektrAIDetails}
                onClickHit={handleClickHit}
                isChecked={isHitSelected(hit.id)}
                onCheckedChange={handleCheckedChange}
                onFalsePositiveClick={handleUpdateHit('false-positive')}
                onTruePositiveClick={handleUpdateHit('true-positive')}
              />
            );
          })}
        </div>
      </ScrollArea>
    </div>
  );
};
