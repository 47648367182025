import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { icons } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { UnlinkedOutcomeGraphNode } from '../../types';

export const UnlinkedOutcomeNode = memo(
  ({ data }: NodeProps<UnlinkedOutcomeGraphNode>) => {
    const isLoopOutcome = data.processType === 'loop';
    const LinkIcon = icons[isLoopOutcome ? 'CircleStop' : 'CircleDotDashed'];

    return (
      <div
        className={cn(
          'h-[26px] w-[80px]',
          'border border-solid shadow-lg duration-200 sm:rounded-lg',
          'bg-color-bg-accent text-color-text-primary',
          'cursor-not-allowed',
          isLoopOutcome && 'w-[60px]'
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <Handle
          type="target"
          id={data.id}
          position={Position.Left}
          className="opacity-0"
        />
        <div
          className={cn(
            'h-full w-full',
            'flex items-center justify-center gap-2',
            'opacity-50',
            isLoopOutcome &&
              'border-color-cyan text-color-cyan rounded-md border'
          )}
        >
          <LinkIcon className={cn('h-2.5 w-2.5')} />
          <p className="text-xs">{isLoopOutcome ? 'End' : 'Unlinked'}</p>
        </div>
      </div>
    );
  }
);
