import { HTMLAttributes, forwardRef } from 'react';

import { cn } from '@spektr/client/utils';

type TableRowProps = HTMLAttributes<HTMLTableRowElement>;

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn(
        'hover:bg-color-bg-table-row--hover',
        'font-sm',
        'transition-colors',
        className
      )}
      {...props}
    />
  )
);
