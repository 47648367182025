import { RenderElementProps } from 'slate-react';

import {
  type SlateElement,
  type SlateLinkElement,
  type SlateMentionElement,
} from '@spektr/shared/slate-types';

import { Mention } from './Mention';
import { LinkComponent } from './LinkComponent';

export const Element = ({
  element,
  attributes,
  children,
}: RenderElementProps) => {
  switch ((element as SlateElement).type) {
    case 'mention':
      return (
        <Mention
          attributes={attributes}
          element={element as SlateMentionElement}
          children={children}
        />
      );
    case 'link':
      return (
        <LinkComponent
          attributes={attributes}
          element={element as SlateLinkElement}
          children={children}
        />
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};
