import { cn } from '@spektr/client/utils';

import { Accordion } from '@spektr/client/components';

import { useFormEngine } from '../../hooks/useFormEngine';
import { useFormRouter } from '../../hooks/useFormRouter';

import { EmptyContent } from './components/EmptyContent';
import { ConfigHeader } from './components/ConfigHeader';
import { FormHelpContent } from './components/FormHelpContent';
import { Content } from './components/Content';
import { Validation } from './components/Validation';

export const FieldConfigSidebar = () => {
  const { currentRoute } = useFormRouter();
  const { spektrFields, getCurrentField, deleteField } = useFormEngine();
  const currentField = getCurrentField();

  if (currentRoute !== 'design' && currentRoute !== 'layout') {
    return (
      <div className={cn('h-full w-full border-l', 'bg-color-bg-moonraker')}>
        {currentField === null ? (
          <EmptyContent />
        ) : (
          <Accordion defaultValue={['content', 'validation']} type="multiple">
            <div>
              <ConfigHeader
                title={currentField.type}
                onDelete={() => deleteField(currentField.id)}
              />
              <Content
                currentField={currentField}
                spektrFields={spektrFields}
              />
              <Validation currentField={currentField} />
            </div>
          </Accordion>
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(
        'relative',
        'h-full w-full',
        'border-l',
        'bg-color-bg-moonraker'
      )}
    >
      <Accordion defaultValue="content" type="single">
        <FormHelpContent />
      </Accordion>
    </div>
  );
};
