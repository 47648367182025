import { includes } from '@spektr/shared/utils';

import { AuthErrorStrings, AuthErrorType } from './AuthErrorStrings';

export class AuthError extends Error {
  constructor(error: unknown) {
    super(getErrorMessage(error));
    this.name = 'AuthError';
  }
}

export function getErrorMessage(error: unknown): string {
  if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }

  if (hasErrorCode(error)) {
    return AuthErrorStrings[error.code as AuthErrorType];
  }

  if (hasErrorType(error)) {
    return AuthErrorStrings[error.__type as AuthErrorType];
  }

  return AuthErrorStrings['Default'];
}

export function hasErrorCode(error: unknown): error is { code: AuthErrorType } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    includes(Object.keys(AuthErrorStrings), error.code)
  );
}

export function hasErrorType(
  error: unknown
): error is { __type: AuthErrorType } {
  return (
    typeof error === 'object' &&
    error !== null &&
    '__type' in error &&
    includes(Object.keys(AuthErrorStrings), error.__type)
  );
}
