import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { Unplug } from 'lucide-react';

import {
  BasicDialog,
  IconWithBackground,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { FieldMappingDialogContent } from './containers/FieldMappingDialogContent';
import { SkeletonFieldMappingTable } from './components/SkeletonFieldMappingTable';

export const FieldMappingDialog = ({
  processId,
  processUrl,
}: {
  processId: string;
  processUrl: string;
}) => {
  const navigate = useNavigate();

  const goBackToProcess = () => {
    navigate(processUrl);
  };

  return (
    <BasicDialog
      defaultOpen
      title="Field mapping"
      description="To make use of the custom fields that is introduced in this process, you need to connect them to your source fields."
      icon={
        <IconWithBackground className="bg-color-bg-accent mr-2 h-6 w-6">
          <Unplug className="text-color-text-icon-primary h-3 w-3" />
        </IconWithBackground>
      }
      size="large"
      onClose={goBackToProcess}
    >
      <SpektrErrorBoundary
        fallbackRender={() => (
          <div>Something went wrong when loading the custom fields.</div>
        )}
      >
        <Suspense fallback={<SkeletonFieldMappingTable />}>
          <FieldMappingDialogContent
            processId={processId}
            goBackToProcess={goBackToProcess}
          />
        </Suspense>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
