import { FormNavigationItem, FormRoutes } from '../types';

export const DEFAULT_ROUTE: FormRoutes = 'blocks';

export const NAVIGATION_ITEMS: FormNavigationItem[] = [
  {
    icon: 'Blocks',
    title: 'Blocks',
    route: 'blocks',
  },
  {
    icon: 'FilePlus',
    title: 'Fields',
    route: 'fields',
  },
  {
    icon: 'FileCog',
    title: 'Primitives',
    route: 'primitives',
  },
  {
    icon: 'PencilRuler',
    title: 'Visuals',
    route: 'visuals',
  },
  {
    icon: 'Palette',
    title: 'Design',
    route: 'design',
  },
];
