import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  LOOPS_KEYS,
  LoopApiClient,
  getProcessesQueryKey,
  getTopologyQueryKey,
} from '@spektr/client/services';

import { NodeUpdateInput, Process } from '@spektr/shared/types';
import { getRootNode } from '@spektr/shared/utils';

export const useUpdateLoop = (loop: Process, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (body: NodeUpdateInput) => {
      const node = loop ? getRootNode(loop) : undefined;

      if (!node) {
        throw new Error('Root node not found');
      }

      return LoopApiClient.getClient().updateNodeById(body, {
        params: {
          loopId: loop.id,
          nodeId: node.id,
        },
      });
    },
    onSuccess: async (_, variables) => {
      if (variables?.nodeType === 'loopSource') {
        await queryClient.resetQueries({
          queryKey: getTopologyQueryKey(),
        });
      }

      await queryClient.invalidateQueries({ queryKey: LOOPS_KEYS.ALL() });
      await queryClient.resetQueries({ queryKey: getProcessesQueryKey() });
      await queryClient.resetQueries({
        queryKey: LOOPS_KEYS.DETAILS(loop.id),
      });

      onSuccess?.();
    },
  });

  return mutation;
};
