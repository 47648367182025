type SectionProps = {
  title: string;
  description: string;
  children: React.ReactNode;
};

export const Section = ({ title, description, children }: SectionProps) => (
  <div className="mb-5 flex flex-col gap-6 p-5">
    <div className="flex flex-col">
      <h1 className="text-color-text-primary text-lg font-medium">{title}</h1>
      <p className="text-sm">{description}</p>
    </div>
    <div className="grid grid-cols-12 items-start gap-6">{children}</div>
  </div>
);
