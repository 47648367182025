import { SectionLabel } from '../SectionLabel';
import { KyckrShareholderFilter } from '../KyckrShareholderFilter';

export const KyckrFiltersList = () => {
  return (
    <div className="mt-4 flex flex-col gap-4">
      <SectionLabel>Define the owners you’re interested in.</SectionLabel>
      <div className="rounded-md border">
        <KyckrShareholderFilter />
      </div>
    </div>
  );
};
