import { z } from 'zod';

import { messageBlockWithMentions } from '@spektr/shared/validators';

const ChannelSettingsFormValues = z.object({
  type: z.string(),
  recipientField: z.string().optional(),
  messageBody: z.array(messageBlockWithMentions).optional(),
  apiKey: z.string().optional(),
});

export type ChannelSettingsFormValues = z.infer<
  typeof ChannelSettingsFormValues
>;

export const ChannelSettingsValidationSchema =
  ChannelSettingsFormValues.superRefine((data, ctx) => {
    if (
      (data.type === 'email' || data.type === 'sms') &&
      !data.recipientField
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Recipient field is required',
        path: ['recipientField'],
      });
    }

    if (data.type === 'api' && !data.apiKey) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'API key is required',
      });
    }
  });
