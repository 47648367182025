import { z } from 'zod';
import { ActionFunctionArgs, redirect } from 'react-router-dom';

import { AuthError } from '@spektr/model-builder/auth';
import { formDataToRecord } from '@spektr/shared/utils';

const schema = z.object({
  email: z.string().email(),
  confirmationCode: z.string(),
  password: z.string(),
});

export default async function confirmResetPasswordAction({
  request,
}: ActionFunctionArgs) {
  const formData = await request.formData();

  try {
    const { email, confirmationCode, password } = schema.parse(
      formDataToRecord(formData)
    );

    await import('../auth/authService').then((module) =>
      module.default.changeForgottenPassword({
        email,
        confirmationCode,
        newPassword: password,
      })
    );

    await import('../auth/authService').then((module) =>
      module.default.logIn({ email, password })
    );

    return redirect(`/`);
  } catch (error) {
    return { action: 'error', error: new AuthError(error) };
  }
}
