import { queryOptions } from '@tanstack/react-query';

import { UsersApiClient } from './client';
import { getTeamMembersQueryKey, USERS_KEYS } from './query.keys';

export function getTeamMembersQuery(customAttr?: string[]) {
  return queryOptions({
    queryKey: getTeamMembersQueryKey(customAttr),
    queryFn: () =>
      UsersApiClient.getClient().getUsers({
        queries: {
          customAttr: customAttr ? customAttr.join(',') : undefined,
        },
      }),
  });
}

export function getDefaultBrandingStyle() {
  return queryOptions({
    queryKey: USERS_KEYS.BRANDING_THEME(),
    queryFn: () => UsersApiClient.getClient().getBrandingFormsDefault(),
  });
}
