import { useNavigate } from 'react-router-dom';

import { insightsActionDetailsUrl } from '@spektr/shared/utils';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';

import { RecordAction } from '../../types';

import { ActionsTableHeader } from '../ActionsTableHeader';
import { ActionsTableRow } from '../ActionsTableRow';

type ActionsTableProps = {
  actions: RecordAction[];
};

export const ActionsTable = ({ actions }: ActionsTableProps) => {
  const navigate = useNavigate();

  const handleClickActionRow = (id: string) => {
    navigate(insightsActionDetailsUrl(id));
  };

  return (
    <Table className="border-separate">
      <ActionsTableHeader />
      <TableBody>
        {actions.length === 0 ? (
          <TableRow>
            <TableCell colSpan={4} className="py-4 text-center">
              No actions found
            </TableCell>
          </TableRow>
        ) : (
          actions.map((row, index) => (
            <ActionsTableRow
              key={row.id}
              rowItem={row}
              isLastRow={index === actions.length - 1}
              handleClick={handleClickActionRow}
            />
          ))
        )}
      </TableBody>
    </Table>
  );
};
