import { DrawerItemType } from '../../types/DrawerItemType';

import { DrawerItem } from '../DrawerItem';

export interface DrawerListItemProps<T> {
  items: T[];
  onClick: (item: T) => void;
}

export const DrawerListItem = <T extends DrawerItemType>({
  items,
  onClick,
}: DrawerListItemProps<T>) => {
  return (
    <div className="grid grid-cols-2 gap-4 p-4">
      {items.map((item) => (
        <DrawerItem
          key={item.type}
          highlight={item.icon}
          label={item?.label}
          title={item.title}
          description={item.description}
          variant={item.variant}
          onClick={() => onClick(item)}
          type={item.type}
          isDisabled={item.disabled}
        />
      ))}
    </div>
  );
};
