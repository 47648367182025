import { z } from 'zod';

import { rgbaColor } from './colors';

export const BrandingFormAssistingContent = z.object({
  title: z.string(),
  description: z.string(),
  support: z.object({
    display: z.boolean(),
    title: z.string(),
    content: z.string(),
    backgroundColor: rgbaColor,
    textColor: rgbaColor,
  }),
});
export type BrandingFormAssistingContent = z.infer<
  typeof BrandingFormAssistingContent
>;
