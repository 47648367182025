import { cn } from '@spektr/client/utils';
import { IconWithBackground, SlackIcon } from '@spektr/client/components';

export type RouterNodeProps = {
  title: string;
};

export const SlackNode = ({ title }: RouterNodeProps) => {
  return (
    <>
      <div>
        <IconWithBackground
          className={cn('ml-5 mr-4 h-10 w-10', `bg-color-bg-white`)}
        >
          <SlackIcon className="h-5 w-5" />
        </IconWithBackground>
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-color-text-node text-sm font-medium">{title}</h3>
        <span className="text-color-text-node-subtext text-xs">Slack step</span>
      </div>
    </>
  );
};
