import { z } from 'zod';

import { rgbaColor } from './colors';

export const BrandingFormTheme = z.object({
  logoSrc: z.string().optional(),
  primaryColor: rgbaColor,
  backgroundColor: rgbaColor,
  borderColor: rgbaColor,
  borderRadius: z.number().min(0),
  textColor: rgbaColor,
  subTextColor: rgbaColor,
  fontFamily: z.string(),
  gapSpacing: z.number().min(0),
});
export type BrandingFormTheme = z.infer<typeof BrandingFormTheme>;
