import { Button, Skeleton } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

export const TemplateListSkeleton = () => {
  return (
    <div className="rounded-md border">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className={cn(
            'flex h-[52px] items-center justify-between',
            index !== 0 && 'border-t'
          )}
        >
          <div className="flex flex-col gap-1 px-4">
            <Skeleton className="h-3.5 w-32" />
            <Skeleton className="h-3 w-28" />
          </div>
          <div className="mr-3 flex items-center gap-6">
            <Skeleton className="h-3.5 w-32" />
            <Button className="h-7 w-[92px]" color="cyan" disabled>
              Choose
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};
