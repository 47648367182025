import { ReactNode, createContext, useContext, useState } from 'react';

import { updateSlackNodeSchema } from '@spektr/shared/validators';

import { IncompleteSlack } from '@spektr/shared/components';

export type IncompleteSlackContextType = {
  incomplete: IncompleteSlack;
  slackNode: ReturnType<(typeof updateSlackNodeSchema)['safeParse']>;
  setTitle: (title: string) => void;
};

export const IncompleteSlackNodeContext = createContext(
  {} as IncompleteSlackContextType
);

export const useIncompleteSlackNode = () =>
  useContext(IncompleteSlackNodeContext);

export type SlackProviderProps = {
  children: ReactNode;
  initial: IncompleteSlack;
};

export const SlackNodeProvider = ({
  children,
  initial,
}: SlackProviderProps) => {
  const [incomplete, setIncomplete] = useState(initial);

  const slackNode = updateSlackNodeSchema.safeParse(incomplete);

  const setTitle = (title: string) =>
    setIncomplete((current) => ({ ...current, title }));

  return (
    <IncompleteSlackNodeContext.Provider
      value={{
        incomplete,
        slackNode,
        setTitle,
      }}
    >
      {children}
    </IncompleteSlackNodeContext.Provider>
  );
};
