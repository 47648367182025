import { HTMLAttributes } from 'react';

import { cn } from '@spektr/client/utils';
import { RichTextEditor } from '@spektr/client/components';

import { type SlateDescendant } from '@spektr/shared/slate-types';

export type PageHelpPopupProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  title: string;
  text: SlateDescendant[] | string;
};

export const PageHelpPopup = ({
  className,
  title,
  text,
  ...props
}: PageHelpPopupProps) => {
  return (
    <div
      {...props}
      className={cn(
        'flex flex-col gap-2',
        'p-6',
        'rounded-2xl',
        'text-base',
        className
      )}
    >
      <h3>{title}</h3>
      <RichTextEditor
        className="max-h-32"
        initialValue={
          // TODO: @Alex - remove check after migration is done
          typeof text === 'string'
            ? [{ type: 'paragraph', children: [{ text }] }]
            : (text as SlateDescendant[])
        }
        isReadOnly
        resetOnUpdate
      />
    </div>
  );
};
