import { Outlet } from 'react-router-dom';

import { DataSourcesPage } from './DataSourcesPage';

export const DataSourcesIndexPage = () => {
  return (
    <>
      <DataSourcesPage />
      <Outlet />
    </>
  );
};
