import { produce } from 'immer';

import { cn } from '@spektr/client/utils';

import { useFormEngine } from '../../hooks/useFormEngine';

import { ColorField } from '../FieldConfigSidebar/components/fields';

type BrandingKeys =
  | 'primaryColor'
  | 'backgroundColor'
  | 'borderColor'
  | 'textColor'
  | 'subTextColor';

export const LayoutStylingSidebar = () => {
  const { state, updateBranding } = useFormEngine();

  const handleChangeBranding = (field: BrandingKeys, value: string) => {
    updateBranding(
      produce(state.branding, (draft) => {
        if (field === 'primaryColor') {
          draft.primaryColor = value;
        }
        if (field === 'backgroundColor') {
          draft.backgroundColor = value;
        }
        if (field === 'borderColor') {
          draft.borderColor = value;
        }
        if (field === 'textColor') {
          draft.textColor = value;
        }
        if (field === 'subTextColor') {
          draft.subTextColor = value;
        }
      })
    );
  };

  return (
    <div className={cn('h-full w-full border-r', 'bg-color-bg-moonraker')}>
      <div className={cn('p-3')}>
        <label className="text-color-text-primary text-sm font-semibold capitalize">
          Default styling
        </label>
      </div>
      <div className="flex flex-col gap-4 pb-2">
        <ColorField
          label="Primary color"
          value={state.branding.primaryColor}
          onChange={(value) =>
            handleChangeBranding('primaryColor', value as string)
          }
        />
        <ColorField
          label="Background color"
          value={state.branding.backgroundColor}
          onChange={(value) =>
            handleChangeBranding('backgroundColor', value as string)
          }
        />
        <ColorField
          label="Border color"
          value={state.branding.borderColor}
          onChange={(value) =>
            handleChangeBranding('borderColor', value as string)
          }
        />
        <ColorField
          label="Text color"
          value={state.branding.textColor}
          onChange={(value) =>
            handleChangeBranding('textColor', value as string)
          }
        />
        <ColorField
          label="Subtext color"
          value={state.branding.subTextColor}
          onChange={(value) =>
            handleChangeBranding('subTextColor', value as string)
          }
        />
      </div>
    </div>
  );
};
