import { useMemo, useState } from 'react';

import { SpektrFieldKey } from '@spektr/shared/types';

import { cn } from '@spektr/client/utils';
import { Separator } from '@spektr/client/components';

import { UBO_FIELDS } from '../../constants/kyckr';
import { computeKyckrFields } from '../../utils/kyckr';
import { ServiceFieldClient } from '../../types/ServiceFieldClient';

import { SelectableServiceItem } from '../SelectableServiceItem';
import { KyckrUboFields } from '../KyckrUboFields';

type KyckrServiceFieldsWithCheckboxProps = {
  fields: ServiceFieldClient[];
  isFieldSelected: (fieldKey: string) => boolean;
  handleSelectionChange: (fieldKey: SpektrFieldKey) => (value: boolean) => void;
};

export const KyckrServiceFieldsWithCheckbox = ({
  fields,
  isFieldSelected,
  handleSelectionChange,
}: KyckrServiceFieldsWithCheckboxProps) => {
  const defaultSelectedUboField = useMemo(
    () => UBO_FIELDS.find((fieldKey) => isFieldSelected(fieldKey)) ?? '',
    [fields]
  );
  const [uboKey, updateUboKey] = useState<string>(defaultSelectedUboField);
  const [representatives, ...ubo] = useMemo(
    () => computeKyckrFields(fields),
    [fields]
  );

  const handleKeyUpdate = (key: string) => {
    updateUboKey((prev) => {
      if (prev) {
        handleSelectionChange(prev as SpektrFieldKey)(false);
      }
      handleSelectionChange(key as SpektrFieldKey)(true);
      return key;
    });
  };

  return (
    <div
      className={cn(
        'flex flex-col',
        'max-h-[280px] overflow-y-auto',
        'rounded-md border'
      )}
    >
      <div className="flex flex-col" key={representatives.key}>
        <div
          className={cn(
            'flex h-auto shrink-0 items-center justify-start px-4',
            'h-[52px]'
          )}
        >
          <SelectableServiceItem
            key={representatives.key}
            checked={isFieldSelected(representatives.key)}
            field={representatives}
            onChange={handleSelectionChange(representatives.key)}
          />
        </div>
        <Separator />
      </div>

      <div className="flex items-start gap-2 p-4">
        <KyckrUboFields
          selectedKey={uboKey}
          fields={ubo}
          onKeyUpdate={handleKeyUpdate}
        />
      </div>
    </div>
  );
};
