import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

export const RiskMatrixTableSkeleton = () => {
  return (
    <div
      className={cn(
        'relative flex flex-col',
        'h-[433px] w-full',
        'overflow-hidden rounded-md border'
      )}
    >
      <div className="bg-color-bg-accent h-10"></div>
      <Table>
        <TableBody>
          {new Array(5).fill(0).map((_, index) => (
            <TableRow key={index}>
              <TableCell className="p-4">
                <Skeleton className="max-w-50 h-7 w-full" />
              </TableCell>
              <TableCell className="p-4">
                <Skeleton className="max-w-50 h-7 w-full" />
              </TableCell>
              <TableCell className="w-18 p-4">
                <Skeleton className="h-7 w-full" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div
        className={cn(
          'flex h-10 items-center gap-3',
          'mt-auto p-2.5',
          'bg-color-bg-accent'
        )}
      ></div>
    </div>
  );
};
