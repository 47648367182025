import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';

import { cn } from '@spektr/client/utils';

export const PopoverContent = forwardRef<
  ElementRef<typeof RadixPopover.Content>,
  ComponentPropsWithoutRef<typeof RadixPopover.Content>
>(({ className, ...props }, ref) => (
  <RadixPopover.Portal>
    <RadixPopover.Content
      ref={ref}
      {...props}
      className={cn(
        'z-50 w-72 rounded-md border',
        'bg-color-bg-primary text-color-text-primary',
        'shadow-md outline-none',
        'data-[state=open]:animate-in data-[state=closed]:animate-out',
        'data-[state=open]:fade-in-0 data-[state=closed]:fade-out-0',
        'data-[state=open]:zoom-in-95 data-[state=closed]:zoom-out-95',
        'data-[side=bottom]:slide-in-from-top-2',
        'data-[side=left]:slide-in-from-right-2',
        'data-[side=right]:slide-in-from-left-2',
        'data-[side=top]:slide-in-from-bottom-2',
        className
      )}
    />
  </RadixPopover.Portal>
));
