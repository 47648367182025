import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { InsightsApiClient } from '@spektr/client/services';

import { ExecutionContextWithDatasetSchema } from '@spektr/shared/validators';

export function useExecutionsWithDataset(executionIds: string[]) {
  const [data, setData] = useState<ExecutionContextWithDatasetSchema[]>([]);
  const [fetchedIds, setFetchedIds] = useState<string[]>([]);

  const fetchExecutionsWithDatasets = useMutation({
    mutationFn: (ids: string[]) => {
      setFetchedIds((prev) => [...prev, ...ids]);
      return InsightsApiClient.getClient().getExecutionsByIdList({ ids });
    },
    onSuccess: (res) => {
      setData((prev) => [...prev, ...res]);
    },
  });

  useEffect(() => {
    if (fetchExecutionsWithDatasets.isPending) return;

    if (executionIds.every((id) => fetchedIds.includes(id))) return;

    const newExecutionIds = executionIds.filter(
      (id) => !fetchedIds.includes(id)
    );

    if (newExecutionIds.length > 0) {
      fetchExecutionsWithDatasets.mutateAsync(newExecutionIds);
    }
  }, [data, executionIds, fetchExecutionsWithDatasets, fetchedIds]);

  return data;
}
