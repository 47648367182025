import { useFormState, useWatch } from 'react-hook-form';

import { Button } from '@spektr/client/components';
import { SpektrFieldTypedKeyWithMapping } from '@spektr/shared/validators';

export const FieldMappingFooter = () => {
  const fields: Array<SpektrFieldTypedKeyWithMapping> | null = useWatch({
    name: 'fields',
  });
  const { errors, isSubmitting } = useFormState();

  return (
    <Button
      className="ml-auto mt-auto"
      disabled={
        isSubmitting ||
        Object.keys(errors).length !== 0 ||
        fields === null ||
        fields.length === 0
      }
      color="cyan"
      type="submit"
      data-cy="save-field-mapping-button"
    >
      Save mapping
    </Button>
  );
};
