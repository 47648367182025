import { useEffect, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import { BellMinus } from 'lucide-react';

import { SpektrFieldKey, TriggerFrequency } from '@spektr/shared/types';

import { Select } from '@spektr/client/components';
import { TRIGGER_FREQUENCIES } from '@spektr/client/constants';
import { cn } from '@spektr/client/utils';

type FrequencySelectProps = {
  fieldKey: SpektrFieldKey;
  frequency?: TriggerFrequency;
  onChangeFrequency: (
    key: SpektrFieldKey,
    frequency?: TriggerFrequency
  ) => void;
};

export const FrequencySelect = ({
  fieldKey,
  frequency,
  onChangeFrequency,
}: FrequencySelectProps) => {
  const prevFieldKeyRef = useRef(fieldKey);
  const [selectKey, setSelectKey] = useState<string>(uniqueId('select-'));

  const resetFrequency = () => {
    onChangeFrequency(fieldKey, undefined);
    setSelectKey(uniqueId('select-'));
  };

  useEffect(() => {
    const prevFieldKey = prevFieldKeyRef.current;

    if (prevFieldKey !== fieldKey) {
      resetFrequency();
    }
  }, [fieldKey]);

  return (
    <div className="ml-auto flex items-center gap-2">
      <Select
        key={selectKey}
        disabled={!fieldKey}
        className="w-40 shrink-0"
        options={TRIGGER_FREQUENCIES}
        name="risk_process_schedule"
        placeholder="Select frequency"
        value={frequency}
        size="small"
        onValueChange={(value) =>
          onChangeFrequency(fieldKey, value as TriggerFrequency)
        }
      />
      <button
        disabled={!frequency}
        className="h-4 w-4"
        onClick={resetFrequency}
      >
        <BellMinus
          className={cn(
            'h-full w-full',
            'text-color-text-icon-secondary hover:text-color-red',
            !frequency &&
              'hover:text-color-text-icon-secondary cursor-not-allowed opacity-50'
          )}
        />
      </button>
    </div>
  );
};
