import { Text } from 'slate';

import {
  isSlateElement,
  type SlateDescendant,
} from '@spektr/shared/slate-types';

export const isNotEmpty = (elem: SlateDescendant) => {
  return (
    isSlateElement(elem) &&
    elem.children.some(
      (child) =>
        isSlateElement(child, ['link']) ||
        (Text.isText(child) && child.text.trim() !== '')
    )
  );
};
