export const AuthErrorStrings = {
  InvalidParameterException: 'Invalid parameter.',
  NotAuthorizedException: 'Incorrect email or password.',
  UserNotFoundException: 'Account not found.',
  NetworkError: 'Network Error.',
  InvalidPasswordException: 'Invalid password.',
  InternalErrorException: 'Internal error.',
  CodeDeliveryFailureException: 'Verification code failed to be delivered.',
  ForbiddenException: 'Access denied.',
  ResourceNotFoundException: 'Resource not found.',
  TooManyRequestsException: 'Request limit reached.',
  UsernameExistsException: 'The email is already taken.',
  LimitExceededException: 'Attempt limit exceeded, please try after some time.',
  CodeMismatchException: 'Invalid verification code provided.',
  Default: 'Unexpected error.',
} as const;

export type AuthErrorType = keyof typeof AuthErrorStrings;
