import { useContext } from 'react';

import { SettingsDeveloper } from '@spektr/model-builder/containers';

import { AuthContext } from '../../auth/auth-context';

export function SettingsDeveloperPage() {
  const { user } = useContext(AuthContext);

  return <SettingsDeveloper user={user} />;
}
