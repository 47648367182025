import { useTranslation } from 'react-i18next';

import {
  NotificationBadge,
  Tabbar,
  TabbarItem,
} from '@spektr/client/components';

import { ClientHitCategory } from '../../types/ClientHitCategory';

import { ServiceIcon } from '../ServiceIcon';

export type ActionDetailsHeaderProps = {
  subject: string;
  categories: ClientHitCategory[];
};

// TODO: (@Robert) - add logos
export const ActionDetailsHeader = ({
  subject,
  categories,
}: ActionDetailsHeaderProps) => {
  const { t } = useTranslation('insights');

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-color-text-subtext text-xl font-semibold leading-tight">
        Action on <span className="text-color-text-primary">{subject}</span>
      </h1>
      <Tabbar>
        {categories.map((category) => {
          const key = category.field
            ? `${category.service}.${category.field}`
            : category.service;

          if (category.unresolvedCount > 0) {
            return (
              <NotificationBadge
                key={key}
                badgeContent={category.unresolvedCount}
              >
                <TabbarItem
                  icon={<ServiceIcon service={category.service} />}
                  to={category.url}
                >
                  {t(`actions.field.${key}.label`)}
                </TabbarItem>
              </NotificationBadge>
            );
          }
          return (
            <TabbarItem
              key={key}
              icon={<ServiceIcon service={category.service} />}
              to={category.url}
            >
              {t(`actions.field.${key}.label`)}
            </TabbarItem>
          );
        })}
      </Tabbar>
    </div>
  );
};
