import { ReactElement, useContext, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { LOGIN_URL, SET_UP_ACCOUNT_URL } from '@spektr/shared/utils';
import { UserRoles } from '@spektr/shared/validators';

import {
  BannerNotifications,
  PermissionsProvider,
  UserInformationProvider,
  createBannerNotification,
  useDemo,
} from '@spektr/client/providers';
import { DashboardLayout } from '@spektr/client/components';
import { AppSidebar } from '@spektr/model-builder/containers';

import { APP_NAVIGATION_ITEMS } from '../config/navigation';

import { AuthContext } from '../auth/auth-context';

import { ProductVideoDialog } from '../routes/demo/ProductVideo';

import { BookMeetingDialog } from '../routes/demo/BookMeeting';

import { DemoBanner } from './DemoBanner';
import { RefreshSessionDialog, useSessionExpiration } from './RefreshSession';

type ProtectedRouteProps = {
  redirectPath?: string;
  children?: ReactElement;
};

export const ProtectedRoute = ({
  redirectPath = LOGIN_URL,
  children,
}: ProtectedRouteProps) => {
  const { user, isLoading, isPasswordRequired } = useContext(AuthContext);
  const { isDemo } = useDemo();
  const { state, pathname } = useLocation();
  const { handleRefresh, onDeclineRefresh } = useSessionExpiration();

  const defaultNotifications = useMemo(() => {
    if (isDemo) {
      return [
        createBannerNotification(<DemoBanner />, 'info', { priority: 0 }),
      ];
    }

    return [];
  }, [isDemo]);

  if (isLoading) {
    return <div></div>;
  }

  if (!user) {
    return (
      <Navigate
        to={redirectPath}
        state={{ redirectedFrom: pathname }}
        replace
      />
    );
  }

  if (isDemo && !user?.['given_name']) {
    return <Navigate to={SET_UP_ACCOUNT_URL} replace />;
  }

  if (isPasswordRequired) {
    return <Navigate to={redirectPath} replace />;
  }

  datadogRum.startSessionReplayRecording();

  return children ? (
    children
  ) : (
    <UserInformationProvider user={user}>
      <PermissionsProvider userRole={user?.role as UserRoles}>
        <BannerNotifications defaultNotifications={defaultNotifications}>
          <DashboardLayout
            sidebar={<AppSidebar navItems={APP_NAVIGATION_ITEMS} />}
          >
            <Outlet />
            <ProductVideoDialog open={state?.show_product_video} />
            <BookMeetingDialog open={state?.show_book_meeting} />
            <RefreshSessionDialog
              open={state?.show_refresh_session}
              handleRefresh={handleRefresh}
              onDeclineRefresh={onDeclineRefresh}
            />
          </DashboardLayout>
        </BannerNotifications>
      </PermissionsProvider>
    </UserInformationProvider>
  );
};
