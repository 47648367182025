import { Suspense } from 'react';
import { Share2 } from 'lucide-react';

import { extractDatasetParamsSchema } from '@spektr/shared/utils';

import { useGoToConnectionHub, useParsedParams } from '@spektr/shared/hooks';

import { BasicDialog, SpektrErrorBoundary } from '@spektr/client/components';

import { ProcessList } from './containers/ProcessList';

import { SkeletonList } from './components/SkeletonList';
import { ErrorList } from './components/ErrorList';

export const DatasetProcessesDialog = () => {
  const { datasetId } = useParsedParams(extractDatasetParamsSchema);
  const goToConnectionHub = useGoToConnectionHub();

  const handleClose = () => goToConnectionHub();
  return (
    <BasicDialog
      className="h-[374px]"
      icon={<Share2 className="stroke-color-yellow mr-3 h-5 w-5" />}
      defaultOpen
      title="Related processes"
      onClose={handleClose}
    >
      <div className="mt-6 h-full">
        <SpektrErrorBoundary fallbackRender={() => <ErrorList />}>
          <Suspense fallback={<SkeletonList />}>
            <ProcessList datasetId={datasetId} />
          </Suspense>
        </SpektrErrorBoundary>
      </div>
    </BasicDialog>
  );
};
