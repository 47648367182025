import { type ChannelSettings } from '@spektr/shared/validators';

import { type ChannelSettingsFormValues } from '../types/ChannelSettingsFormValues';

export function apiToFormValues(
  settings?: ChannelSettings
): ChannelSettingsFormValues {
  switch (settings?.type) {
    case 'email':
      return {
        type: settings.type,
        recipientField: settings.mapping.email,
        messageBody: settings.messageBody,
      };

    case 'sms':
      return {
        type: settings.type,
        recipientField: settings.mapping.sms,
        messageBody: settings.messageBody,
      };

    case 'api':
      return {
        type: settings.type,
        apiKey: settings.apiKey,
      };

    default:
      return { type: 'email' };
  }
}
