import { useQueryClient } from '@tanstack/react-query';

export const useInvalidateProcessDependencies = () => {
  const queryClient = useQueryClient();

  const invalidateDependencies = () => {
    queryClient.invalidateQueries({
      queryKey: ['process'],
    });
  };

  return { invalidateDependencies };
};
