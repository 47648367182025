import { useQueryClient } from '@tanstack/react-query';

import { ACTIONS_KEYS } from '@spektr/client/services';

import { useActionAndHitIdParam } from '../../hooks/useActionAndHitIdParam';

import { CommentBox } from '../../components/CommentBox';

import { HitCommentList } from '../HitCommentList';

export const HitComments = () => {
  const { actionId, hitId } = useActionAndHitIdParam();
  const queryClient = useQueryClient();

  const invalidateActionsByIdQueryKey = async () =>
    await queryClient.invalidateQueries({
      queryKey: ACTIONS_KEYS.BY_ID(actionId),
    });

  return (
    <div className="flex flex-col gap-6">
      <CommentBox
        parentType="hit"
        parentId={hitId}
        onAddComment={invalidateActionsByIdQueryKey}
      />
      <HitCommentList onDeleteComment={invalidateActionsByIdQueryKey} />
    </div>
  );
};
