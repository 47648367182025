import { Users } from 'lucide-react';

import { Tabbar, TabbarItem } from '@spektr/client/components';
import { CASES_CUSTOMERS_FULL_URL } from '@spektr/shared/utils';

export const CasesTabNavigation = () => {
  return (
    <div className="mb-4">
      <Tabbar>
        <TabbarItem
          icon={<Users className="h-4 w-4" />}
          to={CASES_CUSTOMERS_FULL_URL}
        >
          Customers
        </TabbarItem>
      </Tabbar>
    </div>
  );
};
