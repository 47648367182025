import { useSuspenseQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { ExecutionContextSchema } from '@spektr/shared/validators';

import { SHORT_DATE_TIME_FORMAT } from '@spektr/client/constants';
import { getMostRecentExecutionsQuery } from '@spektr/client/services';
import { TableRow } from '@spektr/client/components';

import { ExportedSettingsTable } from '../ExportedSettingsTable';
import { SettingsTableCell } from '../SettingsTableCell';

export const ExportedSettingsList = () => {
  const { data } = useSuspenseQuery({
    ...getMostRecentExecutionsQuery(),
    refetchInterval: 10000,
  });

  const renderTableRows = (row: ExecutionContextSchema) => {
    return (
      <TableRow key={row.id}>
        <SettingsTableCell>{row.process.name}</SettingsTableCell>
        <SettingsTableCell>{row.datasetId}</SettingsTableCell>
        <SettingsTableCell>
          {format(row.createdAt, SHORT_DATE_TIME_FORMAT)}
        </SettingsTableCell>
      </TableRow>
    );
  };

  return (
    <ExportedSettingsTable>
      {data?.length > 0 ? (
        data.map(renderTableRows)
      ) : (
        <TableRow>
          <SettingsTableCell>No exports found</SettingsTableCell>
          <SettingsTableCell />
          <SettingsTableCell />
        </TableRow>
      )}
    </ExportedSettingsTable>
  );
};
