import { useEffect, useMemo } from 'react';

import { Select } from '@spektr/client/components';

import { useRecordsContext } from '../../providers';
import { useGetDatasets } from '../../hooks';

export const DatasetSelect = () => {
  const { selectedDataset, updateSelectedDataset, updateFallbackDatasetId } =
    useRecordsContext();
  const datasets = useGetDatasets();

  const handleChangeDataset = (id: string) => {
    updateSelectedDataset(id);
  };

  const options = useMemo(() => {
    if (!datasets) return [];

    return datasets.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [datasets]);

  useEffect(() => {
    const firstDatasetId = datasets?.[0]?.id;

    if (!firstDatasetId) {
      return;
    }

    if (!selectedDataset) {
      updateFallbackDatasetId(firstDatasetId);
      return;
    }

    if (
      datasets?.findIndex((dataset) => dataset.id === selectedDataset) === -1
    ) {
      updateSelectedDataset(firstDatasetId);
    }
  }, [
    selectedDataset,
    datasets,
    updateFallbackDatasetId,
    updateSelectedDataset,
  ]);

  return (
    <Select
      className="w-60"
      value={selectedDataset}
      placeholder="Select a dataset"
      options={options}
      onValueChange={handleChangeDataset}
    />
  );
};
