import { forwardRef } from 'react';
import { icons } from 'lucide-react';

import { cn } from '@spektr/client/utils';

type AddButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  description: React.ReactNode;
  iconName: keyof typeof icons;
};

export const AddButton = forwardRef<HTMLButtonElement, AddButtonProps>(
  (
    { className, title, description, iconName, disabled = false, ...props },
    ref
  ) => {
    const Icon = icons[iconName];

    return (
      <button
        className={cn(
          'flex w-full items-center gap-6 px-6 py-4',
          'rounded-md border border-dashed',
          'text-color-text-button-primary bg-transparent',
          !disabled &&
            'hover:bg-color-bg-button-secondary/20 active:bg-color-bg-button-secondary/20',
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
          className
        )}
        {...props}
        ref={ref}
      >
        <Icon className="h-4 w-4" />
        <div className="flex flex-col gap-1 text-left">
          <p className="text-sm font-semibold">{title}</p>
          <p className="text-color-text-subtext line-clamp-2 text-xs">
            {description}
          </p>
        </div>
      </button>
    );
  }
);
