import { GetAllActionsFilters, GetAllHitsFilters } from './types/Filters';

export const ACTIONS_KEYS = {
  ALL: (filters: GetAllActionsFilters = {}) =>
    ['actions', 'list', filters] as const,
  BY_ID: (actionId: string) => ['actions', 'details', actionId] as const,
  ALL_HITS: (actionId: string, filters: GetAllHitsFilters) =>
    ['actions', 'details', actionId, 'hits', filters] as const,
  HIT_BY_ID: (actionId: string, hitId: string) =>
    ['actions', 'details', actionId, 'hits', hitId] as const,
};
