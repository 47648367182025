import { z } from 'zod';

export const jobRunnerEnvironment = z.object({
  ENVIRONMENT: z.string().min(1),
  AWS_REGION: z.string().min(1),
  USER_POOL_ID: z.string().min(1),
  NOTIFY_SENDER_EMAIL: z.string().min(1),
  INCOMPLETE_PROCESS_RUN_THRESHOLD_DAYS: z.coerce.number().min(1),
  INCOMPLETE_PROCESS_RUN_ALERT_THRESHOLD_DAYS: z.coerce.number().min(1),
  EXPORT_BATCH_SIZE: z.coerce.number().min(1),
});
