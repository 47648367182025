import { Input } from '@spektr/client/components';

import { usePercentageInput } from '@spektr/platform-hooks';

import { useIncompleteMatrixSegment } from '../providers';

export const MatrixSegmentWeightInput = () => {
  const { segment, updateSegment } = useIncompleteMatrixSegment();
  const updateWeight = (weight: string) => updateSegment({ weight });
  const { percentageValue, handlePercentageChange } = usePercentageInput(
    segment?.weight ?? '100',
    updateWeight
  );

  return (
    <Input
      className="w-14 [&>input]:text-right"
      id="weight"
      name="weight"
      type="number"
      dimension="small"
      value={percentageValue}
      onChange={handlePercentageChange}
      endIcon="%"
    />
  );
};
