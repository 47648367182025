import { useIncompleteCalculationNode } from '../providers';

import { AddButton } from './AddButton';

export const AddSegmentButton = ({ className }: { className?: string }) => {
  const { addSegment } = useIncompleteCalculationNode();

  return (
    <AddButton
      className={className}
      title="Add Segment"
      description={
        <span>
          Add a new custom segment
          <br />
          to your calculation node
        </span>
      }
      iconName="ArrowDownNarrowWide"
      onClick={addSegment}
    />
  );
};
