import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

export type SpektrErrorBoundaryProps = ErrorBoundaryProps;

export const SpektrErrorBoundary = (props: SpektrErrorBoundaryProps) => {
  return (
    <ErrorBoundary
      {...props}
      onError={(error, info) => {
        props?.onError && props.onError(error, info);
      }}
    />
  );
};
