import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { VariantProps } from 'class-variance-authority';

import { Slot } from '@radix-ui/react-slot';

import { cn } from '@spektr/client/utils';

import { buttonVariants } from './variants';

import { BaseVariantProps } from './types/BaseVariantProps';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  BaseVariantProps & {
    asChild?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    fullWidth?: boolean;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      className,
      children,
      startIcon,
      endIcon,
      variant: variantProps = 'contained',
      color = 'primary',
      size = 'default',
      fullWidth = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    const variant = `${variantProps}-${color}`;
    return (
      <Comp
        {...props}
        ref={ref}
        className={cn(
          buttonVariants({
            variant: variant as VariantProps<typeof buttonVariants>['variant'],
            size,
          }),
          'whitespace-nowrap',
          fullWidth ? 'w-full' : undefined,
          className
        )}
      >
        <>
          {startIcon && <span className="mr-2">{startIcon}</span>}
          {children}
          {endIcon && <span className="ml-2">{endIcon}</span>}
        </>
      </Comp>
    );
  }
);
