import { useState } from 'react';
import { EyeOff, MoreVertical, MoveDown, MoveUp, icons } from 'lucide-react';

import { SortOrder } from '@spektr/shared/validators';

import { cn } from '@spektr/client/utils';
import {
  Button,
  DropdownMenuComboBox,
  DropdownOption,
  TableHead,
  TextTruncate,
} from '@spektr/client/components';

type TableHeaderItemProps = {
  name: string;
  sortType?: SortOrder;
  onActionClick: any;
};

const ACTIONS: DropdownOption[] = [
  {
    type: 'item',
    label: 'Sort Ascending',
    icon: <MoveUp className="h-3.5 w-3.5" />,
    value: 'sort_asc',
  },
  {
    type: 'separator',
    value: 'separator',
  },
  {
    type: 'item',
    label: 'Sort Descending',
    icon: <MoveDown className="h-3.5 w-3.5" />,
    value: 'sort_desc',
  },
  {
    type: 'separator',
    value: 'separator-2',
  },
  {
    type: 'item',
    label: 'Hide Column',
    icon: <EyeOff className="h-3.5 w-3.5" />,
    value: 'hide',
  },
];

export const TableHeaderItem = ({
  name,
  sortType,
  onActionClick,
}: TableHeaderItemProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const SortIcon = icons[sortType === 'asc' ? 'MoveUp' : 'MoveDown'];

  return (
    <TableHead
      className={cn('group relative', 'max-w-48 py-3 pl-3 pr-6', 'border-b')}
    >
      <div className="flex items-center gap-2">
        <TextTruncate text={name} className="text-xs font-medium" />

        {sortType && (
          <SortIcon className="stroke-color-stroke-default h-2.5 w-2.5 shrink-0" />
        )}
      </div>
      <DropdownMenuComboBox
        onOpenChange={setIsDropdownOpen}
        align="start"
        side="bottom"
        sideOffset={-2}
        itemClassName="text-xs"
        trigger={
          <Button
            variant="text"
            className={cn(
              'group hidden p-0',
              'absolute right-2 top-1/2 -translate-y-1/2',
              'group-hover:block',
              isDropdownOpen && 'block'
            )}
          >
            <MoreVertical
              className={cn(
                'h-3 w-3',
                'stroke-color-stroke-default',
                'group-hover:stroke-color-stroke-default--hover'
              )}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </Button>
        }
        triggerProps={{
          asChild: true,
        }}
        options={ACTIONS}
        onClick={onActionClick}
      />
    </TableHead>
  );
};
