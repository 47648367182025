export const DownloadExampleCsv = ({ children }: { children: string }) => (
  <a
    className="underline hover:font-semibold"
    href="/assets/data/riskMatrixExample.csv"
    download
    onClick={(e) => e.stopPropagation()}
  >
    {children}
  </a>
);
