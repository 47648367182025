import { ReactNode } from 'react';

export type HeadlineFieldProps = {
  children: ReactNode;
};

export const HeadlineField = ({ children }: HeadlineFieldProps) => (
  <h1 className="text-color-text-form-viewer-headline text-xl font-semibold">
    {children}
  </h1>
);
HeadlineField.displayName = 'HeadlineField';
