import { cn } from '@spektr/client/utils';

import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';

export const SkeletonTable = () => (
  <Table className="rounded-md border">
    <TableBody>
      <TableRow className="hover:bg-inherit">
        <TableCell
          className={cn(
            'border-b border-r p-4',
            'text-sm font-medium',
            'text-color-text-subtext'
          )}
        >
          <Skeleton className="w-20" />
        </TableCell>
        <TableCell className={cn('border-b px-4', 'text-sm font-medium')}>
          <Skeleton className="w-32" />
        </TableCell>
      </TableRow>
      <TableRow className="hover:bg-inherit">
        <TableCell
          className={cn(
            'border-b border-r p-4',
            'text-sm font-medium',
            'text-color-text-subtext'
          )}
        >
          <Skeleton className="w-20" />
        </TableCell>
        <TableCell className={cn('border-b px-4', 'text-sm font-medium')}>
          <Skeleton className="w-32" />
        </TableCell>
      </TableRow>
      <TableRow className="hover:bg-inherit">
        <TableCell
          className={cn(
            'border-r p-4',
            'text-sm font-medium',
            'text-color-text-subtext'
          )}
        >
          <Skeleton className="w-20" />
        </TableCell>
        <TableCell className={cn('px-4', 'text-sm font-medium')}>
          <Skeleton className="w-32" />
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);
