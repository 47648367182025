import { CopyPlus, EllipsisVertical, Trash2, Ungroup } from 'lucide-react';

import {
  DropdownMenuComboBox,
  DropdownOption,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

import { useIncompleteRuleGroup, useIncompleteSegment } from '../providers';

export const RuleGroupMenuComboBox = () => {
  const { addInnerGroup, group } = useIncompleteRuleGroup();
  const { removeRuleGroup } = useIncompleteSegment();

  const options: DropdownOption[] = [
    {
      label: 'Add Inner Rule Group',
      type: 'item',
      value: 'add-inner-group',
      icon: <Ungroup className="h-4 w-4" />,
      variant: 'default',
    },
    {
      type: 'separator',
      value: 'separator-1',
    },
    {
      label: 'Duplicate',
      type: 'item',
      value: 'duplicate',
      icon: <CopyPlus className="h-4 w-4" />,
      variant: 'default',
      disabled: true,
    },
    {
      type: 'separator',
      value: 'separator-2',
    },
    {
      label: 'Delete',
      type: 'item',
      value: 'delete',
      icon: <Trash2 className="h-4 w-4" />,
      variant: 'danger',
    },
  ];

  return (
    <DropdownMenuComboBox
      modal
      trigger={
        <EllipsisVertical
          className={cn(
            'ml-auto w-6',
            'stroke-color-stroke-default',
            'h-5 cursor-pointer',
            'hover:stroke-color-stroke-default--hover',
            'stroke-2'
          )}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      }
      options={options}
      onClick={(value) => {
        if (value === 'delete') removeRuleGroup(group.clientSideOnlyId);
        else if (value === 'add-inner-group') addInnerGroup();
      }}
    />
  );
};
