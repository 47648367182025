import { Suspense } from 'react';

import { SpektrField } from '@spektr/shared/types';

import { SpektrErrorBoundary } from '@spektr/client/components';

import {
  MatrixSegmentProvider,
  useIncompleteCalculationNode,
} from '../providers';
import { RiskMatrixSegment } from '../tree-helpers';

import { MatrixSegmentError, MatrixSegmentSkeleton } from './components';
import { MatrixSegment } from './matrixSegment';

type MatrixSegmentContainerProps = {
  segment: RiskMatrixSegment;
  spektrFields: SpektrField[];
};

export const MatrixSegmentContainer = ({
  segment,
  spektrFields,
}: MatrixSegmentContainerProps) => {
  const { incomplete, updateSegment, removeSegment } =
    useIncompleteCalculationNode();

  return (
    <SpektrErrorBoundary fallback={<MatrixSegmentError />}>
      <Suspense fallback={<MatrixSegmentSkeleton />}>
        <MatrixSegmentProvider
          segment={segment as RiskMatrixSegment}
          type={incomplete.nodeType}
          spektrFields={spektrFields}
          onChange={(updated) => {
            if (updated) updateSegment(updated);
            else removeSegment(segment.clientSideOnlyId);
          }}
        >
          <MatrixSegment />
        </MatrixSegmentProvider>
      </Suspense>
    </SpektrErrorBoundary>
  );
};
