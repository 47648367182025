import { useFocused, useSelected } from 'slate-react';

import { cn } from '@spektr/client/utils';

import { type SlateMentionElement } from '@spektr/shared/slate-types';

type MentionProps = {
  attributes: any;
  children: any;
  element: SlateMentionElement;
};

export const Mention = ({ attributes, children, element }: MentionProps) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <span
      {...attributes}
      contentEditable={false}
      className={cn(
        'px-1 py-0.5',
        'text-xs',
        'rounded-md',
        'bg-color-bg-accent',
        'hover:bg-color-bg-accent/60',
        {
          italic: element.children[0]?.italic,
          'font-semibold': element?.children[0]?.bold,
          underline: element?.children[0]?.underline,
          'bg-color-bg-accent/60 ring-1': selected && focused,
        }
      )}
    >
      @{element.entity}
      {children}
    </span>
  );
};
