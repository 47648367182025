import { ConnectionHubDataSources } from '@spektr/model-builder/containers';

import { ConnectionHubRootLayout } from '../../../components/ConnectionHubRootLayout';

export const DataSourcesPage = () => {
  return (
    <ConnectionHubRootLayout>
      <ConnectionHubDataSources />
    </ConnectionHubRootLayout>
  );
};
