import { ColumnsDropdown } from '../ColumnsDropdown';
import { DatasetFilters } from '../DatasetFilters';
import { DatasetSelect } from '../DatasetSelect';

export const TableToolbar = () => {
  return (
    <div className="flex items-center gap-4">
      <DatasetSelect />
      <div className="ml-auto flex items-center gap-4">
        <DatasetFilters />
        <ColumnsDropdown />
      </div>
    </div>
  );
};
