import { useMemo } from 'react';
import { useMeasure } from 'react-use';
import { useSuspenseQuery } from '@tanstack/react-query';
import { ReactFlowProvider } from 'reactflow';
import { cloneDeep } from 'lodash';

import { TopologyProcess } from '@spektr/shared/types';

import {
  getLiveProcessVersions,
  getTopologyQuery,
} from '@spektr/client/services';

import { generateGraph, getSelectedNodePath } from '../utils';
import { LiveVersionsProvider, useStrategyProviderContext } from '../providers';

import { StrategyTree } from './StrategyTree';

export const StrategyTreeWrapper = () => {
  const { processId, activeView } = useStrategyProviderContext();
  const { data: topologyGraph } = useSuspenseQuery(getTopologyQuery());
  const { data: liveProcessVersions } = useSuspenseQuery(
    getLiveProcessVersions()
  );
  const [canvasRef, { height, width }] = useMeasure<HTMLDivElement>();

  const topology: TopologyProcess[] = useMemo(
    () =>
      activeView === 'map'
        ? topologyGraph
        : getSelectedNodePath(cloneDeep(topologyGraph), processId),
    [activeView, topologyGraph, processId]
  );

  const { nodes, edges } = useMemo(() => generateGraph(topology), [topology]);

  return (
    <div ref={canvasRef} className="h-full w-full" key={activeView}>
      <ReactFlowProvider>
        <LiveVersionsProvider liveVersions={liveProcessVersions}>
          <StrategyTree
            initialNodes={nodes}
            initialEdges={edges}
            width={width}
            height={height}
          />
        </LiveVersionsProvider>
      </ReactFlowProvider>
    </div>
  );
};
