import { useMemo } from 'react';

import { insightsActionHitParamsSchema } from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';

export function useActionAndHitIdParam() {
  const { actionId, hitId } = useParsedParams(insightsActionHitParamsSchema);

  const output = useMemo(() => {
    return {
      actionId,
      hitId,
    };
  }, [actionId, hitId]);

  return output;
}
