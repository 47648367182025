import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
  ISignUpResult,
  UserData,
  IAuthenticationCallback,
} from 'amazon-cognito-identity-js';
import {
  CognitoIdentityProviderClient,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
  ResendConfirmationCodeCommand,
  ConfirmSignUpCommand,
} from '@aws-sdk/client-cognito-identity-provider';

import { CognitoUserData } from '@spektr/shared/types';

import {
  ChangePasswordProps,
  ConfirmEmailProps,
  ConfirmForgotPasswordProps,
  LoginProps,
  UserNotAuthenticatedError,
} from './types';

// LIST OF COGNITO ERRORS: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors

type AwsConfig = {
  userPoolId: string;
  clientId: string;
  region: string;
};

export const createAuth = ({ userPoolId, clientId, region }: AwsConfig) => {
  let cognitoUserInstance: CognitoUser | null = null;

  const pool = new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: clientId,
  });

  const cognito = new CognitoIdentityProviderClient({
    region: region,
  });

  let user = pool.getCurrentUser();

  const _setCognitoUserInstance = (email: string) => {
    cognitoUserInstance = new CognitoUser({
      Username: email,
      Pool: pool,
    });
    return cognitoUserInstance;
  };

  const _getCognitoUserInstance = (email: string) => {
    if (!cognitoUserInstance) {
      return _setCognitoUserInstance(email);
    }
    return cognitoUserInstance;
  };

  const logIn = (
    { email, password }: LoginProps,
    callbacks?: Omit<IAuthenticationCallback, 'onSuccess' | 'onFailure'>
  ) => {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      const details = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      const instance = _setCognitoUserInstance(email);

      instance.authenticateUser(details, {
        onSuccess: (session: CognitoUserSession) => {
          user = instance;
          resolve(session);
        },
        onFailure: (err: Error) => reject(err),
        ...callbacks,
      });
    });
  };

  const logInWithNewPassword = (
    email: string,
    newPassword: string,
    userAttr: CognitoUserData,
    callbacks: Omit<IAuthenticationCallback, 'onSuccess' | 'onFailure'>
  ) => {
    const instance = _getCognitoUserInstance(email);

    return new Promise((resolve, reject) => {
      delete userAttr['email'];

      instance.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: (session) => resolve(session),
          onFailure: (err) => reject(err),
          ...callbacks,
        }
      );
    });
  };

  const signUp = ({ email, password }: LoginProps) => {
    const attributes = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
    ];

    const validations: CognitoUserAttribute[] = [];

    return new Promise<ISignUpResult>((resolve, reject) => {
      const username = email;
      pool.signUp(
        username,
        password,
        attributes,
        validations,
        (error, result) => {
          if (error || !result) reject(error);
          else resolve(result);
        }
      );
    });
  };

  const logOut = () => {
    const cognitoUser = pool.getCurrentUser();
    return new Promise<void>((resolve) => {
      if (cognitoUser)
        cognitoUser.signOut(() => {
          user = null;
          resolve();
        });
      else {
        resolve();
      }
    });
  };

  const getSession = () => {
    return new Promise<CognitoUserSession | null>((resolve) => {
      if (!user) return resolve(null);

      user.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err) resolve(null);
          else resolve(session);
        }
      );
    });
  };

  const getCurrentUser = async (): Promise<CognitoUserData | undefined> => {
    return new Promise((resolve, reject) => {
      const cognitoUser = pool.getCurrentUser();

      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }

      cognitoUser.getSession((err: Error | null) => {
        if (err) {
          reject(err);
          return;
        }
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
            return;
          }

          const role = cognitoUser
            .getSignInUserSession()
            ?.getAccessToken()
            // The cognito:groups claim in AWS Cognito is a part of the user's JWT (JSON Web Token)
            // that contains an array of group names that the user belongs to.
            // e.g. ["admin"], ["viewer"], ["editor"]
            // In our app we only use one group per user, so we take the first one.
            ?.decodePayload()?.['cognito:groups']?.[0];

          const userData = attributes?.reduce(
            (acc: CognitoUserData, attribute) => {
              acc[attribute.Name] = attribute.Value;
              acc['role'] = role;
              return acc;
            },
            {}
          );

          resolve(userData);
        });
      });
    });
  };

  const getUser = async () => {
    const session = await getSession();
    return new Promise<UserData | null>((resolve) => {
      if (!session || !user) return resolve(null);

      user.getUserData((_err, data) => resolve(data ?? null));
    });
  };

  const sendForgotPasswordCodeToUserEmail = (email: string) => {
    const command = new ForgotPasswordCommand({
      ClientId: clientId,
      Username: email,
    });

    return cognito.send(command);
  };

  const changeForgottenPassword = ({
    email,
    newPassword,
    confirmationCode,
  }: ConfirmForgotPasswordProps) => {
    const command = new ConfirmForgotPasswordCommand({
      ClientId: clientId,
      ConfirmationCode: confirmationCode,
      Username: email,
      Password: newPassword,
    });

    return cognito.send(command);
  };

  const changePassword = ({
    oldPassword,
    newPassword,
  }: ChangePasswordProps) => {
    return new Promise<'SUCCESS'>(function (resolve, reject) {
      if (!user) return reject(new UserNotAuthenticatedError());

      user.changePassword(oldPassword, newPassword, (err, res) => {
        if (err || !res) reject(err);
        else resolve(res);
      });
    });
  };

  const confirmEmail = ({ email, confirmationCode }: ConfirmEmailProps) => {
    const command = new ConfirmSignUpCommand({
      ClientId: clientId,
      ConfirmationCode: confirmationCode,
      Username: email,
    });
    return cognito.send(command);
  };

  const resendConfirmationCode = (email: string) => {
    const command = new ResendConfirmationCodeCommand({
      ClientId: clientId,
      Username: email,
    });

    return cognito.send(command);
  };
  const changeName = (given_name: string, family_name: string) => {
    return new Promise<string>(function (resolve, reject) {
      if (!user) return reject(new UserNotAuthenticatedError());

      const givenNameAttr = new CognitoUserAttribute({
        Name: 'given_name',
        Value: given_name,
      });
      const familyNameAttr = new CognitoUserAttribute({
        Name: 'family_name',
        Value: family_name,
      });
      user.updateAttributes([givenNameAttr, familyNameAttr], (err, res) => {
        if (err || !res) reject(err);
        else resolve(res);
      });
    });
  };

  const getSessionExpiration = () =>
    new Promise<number | null>((resolve, reject) => {
      const cognitoUser = pool.getCurrentUser();

      if (!cognitoUser) {
        return resolve(null);
      }

      cognitoUser?.getSession((err: Error | null) => {
        if (err) {
          reject(err);
          return;
        }

        cognitoUser.getUserAttributes(() => {
          const token = cognitoUser
            .getSignInUserSession()
            ?.getAccessToken()
            ?.decodePayload();
          const expiration = token?.['exp'];

          return resolve(expiration);
        });
      });
    });

  const refreshSession = () => {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      if (!user) return reject(new UserNotAuthenticatedError());

      user.getSession((err: Error | null, session: CognitoUserSession) => {
        if (err || !session) {
          reject(err);
          return;
        }

        user?.refreshSession(session.getRefreshToken(), (err, newSession) => {
          if (err || !newSession) {
            reject(err);
            return;
          }
          resolve(newSession);
        });
      });
    });
  };

  return {
    logIn,
    logInWithNewPassword,
    signUp,
    logOut,
    getUser,
    getCurrentUser,
    sendForgotPasswordCodeToUserEmail,
    changeForgottenPassword,
    changePassword,
    confirmEmail,
    resendConfirmationCode,
    changeName,
    getSessionExpiration,
    refreshSession,
  };
};
