import { VariantProps, cva } from 'class-variance-authority';
import { AlertTriangle, CheckCircle2, CircleX } from 'lucide-react';
import { createElement } from 'react';

const ICON_MAP = {
  warning: AlertTriangle,
  success: CheckCircle2,
  error: CircleX,
};

const iconColorVariants = cva('rounded-lg p-2', {
  variants: {
    type: {
      warning: 'bg-color-yellow/20 text-color-yellow',
      success: 'bg-color-green/20 text-color-green',
      error: 'bg-color-red/20 text-color-red',
    },
  },
  defaultVariants: {
    type: 'success',
  },
});

export type ToastTypeVariant = VariantProps<typeof iconColorVariants>['type'];

type ToastIconProps = {
  type: ToastTypeVariant;
};

export const ToastIcon = ({ type }: ToastIconProps) => {
  return (
    <div className={iconColorVariants({ type })}>
      {createElement(ICON_MAP[type ?? 'success'], { className: 'h-4 w-4' })}
    </div>
  );
};
