import { SpektrField } from '../../goldenRecord';

//TODO: label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and FE retrieves them by the field key
export const openCorporatesRiskSpektrFields: Array<
  Omit<SpektrField, 'sourceId'>
> = [
  {
    key: 'company_name',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Company name',
  },
  {
    key: 'company_number',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Company number',
  },
  {
    key: 'jurisdiction_code',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Company jurisdiction code',
  },
  {
    key: 'incorporation_date',
    type: 'date',
    source: 'openCorporatesRisk',
    label: 'Company incorporation date',
  },
  {
    key: 'dissolution_date',
    type: 'date',
    source: 'openCorporatesRisk',
    label: 'Company dissolution date',
  },
  {
    key: 'company_type',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Company type',
  },
  {
    key: 'current_status',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Current status',
  },

  //Address
  {
    key: 'registered_address.street_address',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address street',
  },
  {
    key: 'registered_address.locality',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address locality',
  },
  {
    key: 'registered_address.region',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address region',
  },
  {
    key: 'registered_address.postal_code',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address postcode',
  },
  {
    key: 'registered_address.country',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address country',
  },
  {
    key: 'registered_address_in_full',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Complete address',
  },
];
