import { Zodios } from '@zodios/core';
import { pluginToken } from '@zodios/plugins';

import { insightsApi } from '@spektr/shared/validators';

import {
  ApiConfig,
  createBaseApiClient,
  getAccessToken,
} from '@spektr/client/utils';

function createInsightsApiClient(config: ApiConfig) {
  const instance = new Zodios(config.baseUrl, insightsApi);

  instance.use(
    pluginToken({
      getToken: async () => {
        return Promise.resolve(getAccessToken(config.clientId) ?? undefined);
      },
    })
  );

  return instance;
}

export const InsightsApiClient = createBaseApiClient(createInsightsApiClient);
