import { cn } from '@spektr/client/utils';

import { SpektrDataflowIcon } from './SpektrDataflow';
import { IconWithBackground } from './IconWithBackground';

type RouterIconProps = {
  className?: string;
  iconClassName?: string;
};

export const RouterIcon = ({ className, iconClassName }: RouterIconProps) => {
  return (
    <IconWithBackground
      className={cn('bg-color-red ml-5 mr-4 h-10 w-10 p-1', className)}
    >
      <SpektrDataflowIcon
        className={cn('stroke-color-stroke-white h-4 w-4', iconClassName)}
      />
    </IconWithBackground>
  );
};
