import { useMemo } from 'react';

import { deserializeSlate } from '@spektr/client/components';

import {
  type MoonrakerForm,
  type DeserializedMoonrakerForm,
} from '@spektr/moonraker-types';
import { FormBranding } from '@spektr/shared/validators';

export function useDeserializedForm(
  initialForm?: MoonrakerForm,
  defaultBranding?: FormBranding
): DeserializedMoonrakerForm | undefined {
  return useMemo(() => {
    let deserializedDescription;
    let deserializedSupportContent;

    if (initialForm?.assistingContent) {
      if (initialForm.assistingContent.description) {
        deserializedDescription =
          typeof initialForm.assistingContent.description === 'string'
            ? deserializeSlate(initialForm.assistingContent.description)
            : initialForm.assistingContent.description;
      }
      if (initialForm.assistingContent.support.content) {
        deserializedSupportContent =
          typeof initialForm.assistingContent.support.content === 'string'
            ? deserializeSlate(initialForm.assistingContent.support.content)
            : initialForm.assistingContent.support.content;
      }
      return {
        ...initialForm,
        branding: defaultBranding,
        assistingContent: {
          ...initialForm.assistingContent,
          description: deserializedDescription,
          support: {
            ...initialForm.assistingContent.support,
            content: deserializedSupportContent,
          },
        },
      } as DeserializedMoonrakerForm;
    }

    if (defaultBranding?.assistingContent) {
      if (defaultBranding?.assistingContent?.description) {
        deserializedDescription =
          typeof defaultBranding.assistingContent.description === 'string'
            ? deserializeSlate(defaultBranding.assistingContent.description)
            : defaultBranding.assistingContent.description;
      }
      if (defaultBranding?.assistingContent?.support?.content) {
        deserializedSupportContent =
          typeof defaultBranding.assistingContent.support.content === 'string'
            ? deserializeSlate(defaultBranding.assistingContent.support.content)
            : defaultBranding.assistingContent.support.content;
      }

      return {
        ...initialForm,
        branding: defaultBranding,
        assistingContent: {
          ...defaultBranding.assistingContent,
          description: deserializedDescription,
          support: {
            ...defaultBranding.assistingContent.support,
            content: deserializedSupportContent,
          },
        },
      } as DeserializedMoonrakerForm;
    }

    return initialForm as DeserializedMoonrakerForm;
  }, [defaultBranding, initialForm]);
}
