import { useSuspenseQuery } from '@tanstack/react-query';

import { StaticVendorService } from '@spektr/client/types';

const key = ['vendors'] as const;

export function useVendorServices() {
  const vendorsUrl = new URL(
    '/assets/config/vendors.json',
    window.location.origin
  );

  return useSuspenseQuery({
    queryKey: key,
    queryFn: () =>
      fetch(vendorsUrl.toString()).then((response) =>
        response.json()
      ) as Promise<StaticVendorService[]>,
  });
}
