import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import _isEqual from 'lodash/isEqual';

import {
  getAllowedSpektrFieldsQuery,
  getTriggersForProcessQuery,
  getTriggersForProcessQueryKey,
} from '@spektr/client/services';
import { ClientTrigger } from '@spektr/client/types';

import {
  NodeUpdateInput,
  ServiceNode as ServiceNodeType,
} from '@spektr/shared/types';

import { useTriggersOperations } from '../../hooks/useTriggersOperations';

import { ServiceFieldsWithFrequencyList } from '../ServiceFieldsWithFrequencyList';

type MonitoringServiceNodeContentProps = {
  processId: string;
  node: ServiceNodeType;
  onUpdate: (data: NodeUpdateInput) => void;
};

export const MonitoringServiceNodeContent = ({
  processId,
  node,
  onUpdate,
}: MonitoringServiceNodeContentProps) => {
  const formInstance = useForm<Record<string, unknown>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });
  const queryClient = useQueryClient();
  const { createTrigger, updateTrigger, deleteTrigger } =
    useTriggersOperations();
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );
  const { data: triggers } = useSuspenseQuery(
    getTriggersForProcessQuery(processId)
  );

  const handleSaveChanges = async (
    fields: NodeUpdateInput,
    nextTriggers: ClientTrigger[]
  ) => {
    const changedTriggers = nextTriggers.filter((trigger) => {
      if (!trigger.id) {
        return true;
      }

      const currentTrigger = triggers?.find(
        (currentTrigger) => currentTrigger.id === trigger.id
      );

      if (!currentTrigger) {
        return true;
      }

      return (
        currentTrigger.frequency !== trigger.frequency ||
        !_isEqual(currentTrigger.fields, trigger.fields)
      );
    });

    const deleteTriggers = changedTriggers.filter(
      (trigger) => trigger.id && !trigger.fields?.length
    );

    const updateTriggers = changedTriggers.filter(
      (trigger) =>
        trigger.id &&
        trigger.fields?.length &&
        !deleteTriggers.find((deleteTrigger) => deleteTrigger.id === trigger.id)
    );
    const createTriggers = changedTriggers.filter(
      (trigger) => !trigger.id && trigger.fields?.length
    );

    const promiseQueue = [
      ...deleteTriggers.map((trigger) => deleteTrigger(trigger.id as string)),
      ...updateTriggers.map((trigger) =>
        updateTrigger({
          triggerId: trigger.id as string,
          fields: trigger.fields,
          frequency: trigger.frequency,
        })
      ),
      ...createTriggers.map((trigger) => createTrigger(trigger)),
    ];

    await Promise.all(promiseQueue);

    onUpdate(fields);

    queryClient.invalidateQueries({
      queryKey: getTriggersForProcessQueryKey(processId),
    });
  };

  const currentNodeTriggers = useMemo(
    () => triggers?.filter((trigger) => trigger.nodeId === node.id) ?? [],
    [node.id, triggers]
  );

  return (
    <FormProvider {...formInstance}>
      <ServiceFieldsWithFrequencyList
        key={spektrFields?.length}
        node={node}
        nodeId={node.id}
        processId={processId}
        spektrFields={spektrFields ?? []}
        triggers={currentNodeTriggers}
        onSave={handleSaveChanges}
      />
    </FormProvider>
  );
};
