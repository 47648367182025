import { CirclePlus } from 'lucide-react';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from 'reactflow';
import { useTranslation } from 'react-i18next';

import { RBAC } from '@spektr/shared/rbac';
import {
  GraphOutcomeEdgeData,
  loopSheetUrl,
  processBuilderSheetUrl,
} from '@spektr/shared/utils';

import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/client/utils';
import { EDGE } from '@spektr/shared/components';

export function EdgeToSegment({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style,
}: EdgeProps<GraphOutcomeEdgeData>) {
  const navigate = useNavigate();
  const [path] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    centerX: sourceX + EDGE.CENTER.X, // bend the edge 'EDGE.CENTER.X' pixels from the source node if needed. The default is to bend the edge halfway between source and target but that makes little room for the label
  });
  const { hasPermission } = usePermissionsContext();
  const { t } = useTranslation();

  const onClick = (ev: MouseEvent) => {
    ev.preventDefault();

    if (data?.process) {
      const href =
        data.process.type === 'loop'
          ? loopSheetUrl(data.process.id)
          : processBuilderSheetUrl(data.process.id);

      navigate(href, {
        state: {
          predecessorNode: data.predecessorNode,
          successorNode: data.successorNode,
        },
      });
    }
  };

  const isFinalOutcome = data?.outcome?.isFinal;
  const isKyckr = data?.predecessorNode?.nodeType === 'kyckr';
  const isActionForm = data?.predecessorNode?.nodeType === 'actionForm';

  const edgeStyle = { ...style, strokeWidth: '2px' };

  if (!data?.outcome) return <BaseEdge id={id} style={edgeStyle} path={path} />;

  let label = data.outcome.name;
  if (isFinalOutcome && isKyckr) {
    label = t(`process:nodes.kyckr.outcome.label.${data.outcome.name}`);
  } else if (isFinalOutcome && isActionForm) {
    label = data.outcome.name;
  }

  return (
    <>
      <BaseEdge id={id} style={edgeStyle} path={path} />
      <EdgeLabelRenderer>
        <div
          className="absolute flex items-center pr-3"
          style={{
            transform: `translate(-100%, -50%) translate(${targetX}px,${targetY}px)`,
          }}
        >
          <p
            className={cn(
              'rounded border dark:border-0',
              'dark:bg-spektr-gray-200 bg-color-bg-accent',
              'line-clamp-1 px-2.5 py-1.5',
              'text-color-text-primary/60 text-xs shadow-lg'
            )}
          >
            {label}
          </p>
          {!isFinalOutcome && (
            <button
              disabled={!hasPermission(RBAC.ACTIONS.NODE.CREATE)}
              className={cn(
                'nodrag nopan',
                'flex h-7 w-7 items-center justify-center',
                'disabled:opacity-50',
                'pointer-events-all'
              )}
              style={{ pointerEvents: 'all' }}
              onClick={onClick}
            >
              <CirclePlus className="stroke-color-stroke-default bg-color-bg-primary hover:stroke-color-stroke-default--hover h-4 w-4 rounded-full" />
            </button>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
