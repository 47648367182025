import { Box } from 'lucide-react';
import { format } from 'date-fns';

import { RiskMatrixSlim } from '@spektr/shared/validators';

import { cn } from '@spektr/client/utils';
import {
  CardWithContextMenu,
  CardWithContextMenuProps,
  iconBoxVariants,
} from '@spektr/client/components';
import { SHORT_DATE_TIME_FORMAT } from '@spektr/client/constants';

type RixkMatrixCardProps = Omit<
  CardWithContextMenuProps,
  'children' | 'header' | 'footer'
> & {
  matrix: RiskMatrixSlim;
};

export const RiskMatrixCard = ({ matrix, ...props }: RixkMatrixCardProps) => {
  return (
    <CardWithContextMenu
      className="w-full"
      name={matrix.title}
      header={
        <Box className={cn(iconBoxVariants({ variant: 'cyan' }), 'h-8 w-8')} />
      }
      footer={
        <span>{`Added: ${format(
          matrix.createdAt,
          SHORT_DATE_TIME_FORMAT
        )}`}</span>
      }
      {...props}
    >
      <div className="flex h-full flex-col gap-3">
        <p className="line-clamp-1 overflow-hidden text-ellipsis break-all leading-5">
          {matrix.title}
        </p>
        <div className="inline-flex">
          <div className="flex basis-1/2 flex-col">
            <span className="text-sm font-semibold leading-none">
              {matrix?.columns?.length || 0}
            </span>
            <span className="text-color-text-subtext text-xs font-semibold">
              Columns
            </span>
          </div>
          <div className="flex basis-1/2 flex-col">
            <span className="text-sm font-semibold leading-none">
              {matrix?.rowCount || 0}
            </span>
            <span className="text-color-text-subtext text-xs font-semibold">
              Rows
            </span>
          </div>
        </div>
      </div>
    </CardWithContextMenu>
  );
};
