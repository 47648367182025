import { CommentMessageBlock } from '@spektr/shared/validators';

import {
  isSlateElement,
  type SlateDescendant,
} from '@spektr/shared/slate-types';

import { isNotEmpty } from './isNotEmpty';

export const trimRichTextEditorContent = (comment: SlateDescendant[]) => {
  if (comment.find((value) => !isSlateElement(value))) {
    return comment;
  }

  if (
    comment.length > 0 &&
    isNotEmpty(comment[0] as SlateDescendant) &&
    isNotEmpty(comment[comment.length - 1] as SlateDescendant)
  ) {
    return comment;
  }

  const indexOfFirstNotEmpty = comment.findIndex((p) => isNotEmpty(p));
  const indexOfLastNotEmpty = [...comment]
    .reverse()
    .findIndex((p) => isNotEmpty(p));

  const trimmedComment = [...comment].slice(
    indexOfFirstNotEmpty,
    comment.length - indexOfLastNotEmpty
  );

  return trimmedComment as CommentMessageBlock[];
};
