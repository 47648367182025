import { useMemo, useState } from 'react';
import { produce } from 'immer';
import { uniqueId } from 'lodash';

import { useDebouncedSearch } from '@spektr/platform-hooks';

import { DatasetResponse } from '@spektr/shared/types';

import { DragItem } from '../types';

import { ColumnsContext } from './ColumnsContext';
import { useRecordsContext } from './RecordsContext';

type ColumnsProviderProps = {
  dataset: DatasetResponse | undefined;
  children: React.ReactNode;
};

export const ColumnsProvider = ({
  dataset,
  children,
}: ColumnsProviderProps) => {
  const { visibleColumns, updateColumnVisibility } = useRecordsContext();
  const columnItems = useMemo(
    () =>
      dataset?.fields
        .sort((a, b) => {
          const aIndex = visibleColumns.indexOf(a.name);
          const bIndex = visibleColumns.indexOf(b.name);

          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;
          return aIndex - bIndex;
        })
        .map((field) => ({
          id: uniqueId(`${field.name}-`),
          name: field.name,
          isHidden: !visibleColumns.includes(field.name),
        })) ?? [],
    [dataset?.fields]
  );
  const [columns, updateColumns] = useState(columnItems);
  const { searchValue, debouncedValue, setSearchValue } = useDebouncedSearch();

  const handleItemVisibilityChange = (name: string, isHidden: boolean) => {
    updateColumns(
      produce(columns, (draft) => {
        const item = draft.find((column) => column.name === name);
        if (item) {
          item.isHidden = isHidden;
          const index = draft.findIndex((column) => column.name === name);
          draft.splice(index, 1);
          const firstHiddenIndex = draft.findIndex((column) => column.isHidden);
          draft.splice(firstHiddenIndex, 0, item);
        }
      })
    );
    updateColumnVisibility(name, isHidden);
  };

  const handleItemMove = (
    item: DragItem,
    newIndex: number,
    newHiddenValue: boolean
  ) => {
    updateColumns(
      produce(columns, (draft) => {
        const indexOfItem = draft.findIndex(
          (column) => column.name === item.name
        );
        draft.splice(indexOfItem, 1);
        draft.splice(newIndex, 0, {
          ...item,
          isHidden: newHiddenValue,
        });
      })
    );

    updateColumnVisibility(item.name, newHiddenValue, newIndex);
  };

  const providedValue = useMemo(
    () => ({
      columns,
      searchColumnValue: searchValue,
      columnFilter: debouncedValue,
      canDrag: searchValue.length === 0,
      setSearchColumnValue: setSearchValue,
      handleItemVisibilityChange,
      handleItemMove,
    }),
    [columns, searchValue, debouncedValue, setSearchValue]
  );

  return (
    <ColumnsContext.Provider value={providedValue}>
      {children}
    </ColumnsContext.Provider>
  );
};
