import { NavLink } from 'react-router-dom';

import { cn } from '@spektr/client/utils';

import { AppNavItem, appNavColorVariants } from '@spektr/client/types';
import { NotificationBadge, Tooltip } from '@spektr/client/components';

type AppNavLinkProps = Pick<
  AppNavItem,
  'color' | 'icon' | 'label' | 'link' | 'count'
> & {
  className?: string;
  isCollapsed?: boolean;
  disabled?: boolean;
};

export const AppNavLink = ({
  color,
  count,
  className,
  icon,
  isCollapsed,
  label,
  link,
  disabled,
}: AppNavLinkProps) => {
  const linkComponent = (
    <div
      className={cn(
        'h-8.5 w-8.5 rounded-lg',
        'flex items-center justify-center gap-2',
        'text-sm'
      )}
    >
      <span>{icon}</span>
      {!isCollapsed && <span>{label}</span>}
    </div>
  );
  return (
    <Tooltip
      content={label}
      side="right"
      sideOffset={6}
      delayDuration={isCollapsed ? 0 : 1000000}
    >
      <div className="w-full">
        <NavLink
          className={({ isActive }) =>
            cn(
              'h-8.5 w-8.5 rounded-lg',
              'flex items-center justify-center gap-2',
              'text-sm',
              'cursor-pointer',
              'text-color-text-navbar-default',
              'bg-transparent',
              appNavColorVariants[color].hover,
              isActive &&
                `dark:bg-color-bg-nav-link--active ${appNavColorVariants[color].color}`,
              disabled && 'pointer-events-none cursor-not-allowed opacity-50',
              className
            )
          }
          aria-label={`Navigate to ${label}`}
          to={link}
        >
          {count && count > 0 ? (
            <NotificationBadge badgeContent={count ?? 0}>
              {linkComponent}
            </NotificationBadge>
          ) : (
            linkComponent
          )}
        </NavLink>
      </div>
    </Tooltip>
  );
};
