import { toast as sonnerToast, ToastT } from 'sonner';

import { ToastItem, ToastItemProps } from './ToastItem';

type Toast = {
  info: (props: ToastItemProps, options?: ToastT) => void;
  error: (props: ToastItemProps, options?: ToastT) => void;
  warning: (props: ToastItemProps, options?: ToastT) => void;
  success: (props: ToastItemProps, options?: ToastT) => void;
};

export const toast: Toast = {
  info: (props, options) =>
    sonnerToast.custom(
      (toastId) => <ToastItem {...props} id={toastId} />,
      options
    ),
  error: (props, options) =>
    sonnerToast.custom(
      (toastId) => <ToastItem {...props} id={toastId} variant="error" />,
      options
    ),
  warning: (props, options) =>
    sonnerToast.custom(
      (toastId) => <ToastItem {...props} id={toastId} variant="warning" />,
      options
    ),
  success: (props, options) =>
    sonnerToast.custom(
      (toastId) => <ToastItem {...props} id={toastId} variant="success" />,
      options
    ),
};
