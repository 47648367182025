import { useCallback } from 'react';
import { useLocation, useNavigate, NavigateOptions } from 'react-router-dom';

export const useUpdateCurrentRoute = () => {
  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  return useCallback(
    (options?: NavigateOptions) => {
      navigate(pathname + search, {
        ...options,
        replace: true,
      });
    },
    [navigate, pathname, search]
  );
};
