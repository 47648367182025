import {
  loopSourceNodeSchema,
  onboardingProcessSourceNodeSchema,
} from '@spektr/shared/validators';

import { type ProcessSource, type Process } from '@spektr/shared/types';

import { getRootNode } from '../nodes';

/**
 * @description   Returns an array of process sources from the given process.
 * @param   process  The process to get the sources from.
 * @returns       An array of process sources.
 */
export function getSourcesFromRootNode(process: Process) {
  const rootNode = getRootNode(process);

  if (!rootNode) {
    return [];
  }

  const loop = loopSourceNodeSchema.safeParse(rootNode);
  if (loop.success) {
    return loop.data.sources;
  }

  const onboarding = onboardingProcessSourceNodeSchema.safeParse(rootNode);
  if (onboarding.success) {
    return onboarding.data.sources;
  }

  return [];
}

/**
 * @description       Returns an array of unique process ids from the given loop sources.
 * @param   sources   The loop sources to get the process ids from.
 * @returns           An array of unique process ids.
 */
export function getProcessIdsFromSources(sources: ProcessSource[]) {
  const loopsProcessIds = new Set();

  sources.forEach((source) => loopsProcessIds.add(source.processId));

  return Array.from(loopsProcessIds);
}
