import { Tooltip, TooltipProps } from '@spektr/client/components';

import { Select, SelectProps } from './Select';

type SelectWithTooltopProps = SelectProps & {
  showTooltip: boolean;
  tooltipProps: Omit<TooltipProps, 'children'>;
};

export const SelectWithTooltip = ({
  showTooltip,
  tooltipProps,
  disabled,
  ...props
}: SelectWithTooltopProps) => {
  return showTooltip ? (
    <Tooltip
      {...tooltipProps}
      tooltipTriggerProps={{
        ...tooltipProps.tooltipTriggerProps,
        disabled,
        className: 'cursor-not-allowed disabled:pointer-events-auto',
        asChild: false,
      }}
    >
      <Select {...props} disabled />
    </Tooltip>
  ) : (
    <Select {...props} />
  );
};
