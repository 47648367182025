import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
} from 'react';
import {
  Root,
  Viewport,
  Scrollbar,
  Thumb,
  Corner,
} from '@radix-ui/react-scroll-area';

import { cn } from '@spektr/client/utils';

export type ScrollAreaProps = {
  children: ReactNode;
  className?: string;
};

export const ScrollArea = forwardRef<
  ElementRef<typeof Root>,
  ComponentPropsWithoutRef<typeof Root>
>(({ className, children, ...props }, ref) => (
  <Root
    ref={ref}
    className={cn('relative overflow-hidden', className)}
    {...props}
  >
    <Viewport className="h-full w-full">{children}</Viewport>
    <Scrollbar
      className={cn(
        'flex',
        'p-0.5',
        'bg-transparent',
        'touch-none select-none',
        'transition-colors duration-150 ease-out',
        'data-[orientation=vertical]:w-2.5',
        'data-[orientation=horizontal]:h-2.5 data-[orientation=horizontal]:flex-col',
        'hover:bg-color-bg-scrollbar--hover'
      )}
      orientation="vertical"
    >
      <Thumb className="bg-color-bg-scrollbar-thumb relative flex-1 cursor-pointer rounded-[10px] before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
    </Scrollbar>
    <Corner />
  </Root>
));
