import { CognitoUserData } from '@spektr/shared/types';

import { GenerateNewApiKey } from './containers/GenerateNewApiKey';
import { DeveloperApiKeys } from './containers/DeveloperApiKeys';
import { ExportMethod } from './containers/ExportMethod';
import { ExportedSettings } from './containers/ExportedSettings';

import { Section } from './components/Section';

export function SettingsDeveloper({
  user,
}: {
  user: CognitoUserData | undefined;
}) {
  const leftSideClasses =
    'col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3';
  const rightSideClasses =
    'col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9';

  return (
    <div className="relative h-full w-full">
      <div className="absolute inset-0 overflow-y-auto">
        <Section
          title="API Keys"
          description="Create API keys to interact with spektr externally"
        >
          <GenerateNewApiKey className={leftSideClasses} />
          <DeveloperApiKeys className={rightSideClasses} />
        </Section>

        <Section
          title="Export Settings"
          description="Define how to export information outside spektr"
        >
          <ExportMethod
            className={leftSideClasses}
            workspaceId={user?.['custom:workspaceId']}
          />
          <ExportedSettings className={rightSideClasses} />
        </Section>
      </div>
    </div>
  );
}
