export const getFieldsWithoutProperties = (
  fields: Record<string, boolean>
): Record<string, boolean> => {
  return Object.keys(fields).reduce((acc, key) => {
    const fieldKeys = key.split('_fields.');

    if (
      fieldKeys.length > 1 &&
      Object.prototype.hasOwnProperty.call(fields, fieldKeys[0])
    ) {
      return acc;
    }

    return {
      ...acc,
      [key]: fields[key],
    };
  }, {});
};
