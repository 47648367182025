import { Info } from 'lucide-react';

import { Tooltip } from '@spektr/client/components';

import { SpektrFieldKey, TriggerFrequency } from '@spektr/shared/types';

import { ServiceFieldClient } from '../../types/ServiceFieldClient';
import { FrequencySelect } from '../FrequencySelect';

export type ServiceItemWithFrequencyProps = {
  field: ServiceFieldClient;
  frequency?: TriggerFrequency | undefined;
  onChangeFrequency: (
    field: SpektrFieldKey,
    frequency: TriggerFrequency | undefined
  ) => void;
};

export const ServiceItemWithFrequency = ({
  field,
  frequency,
  onChangeFrequency,
}: ServiceItemWithFrequencyProps) => {
  return (
    <>
      <span className="text-color-text-primary flex items-center gap-2 whitespace-normal text-xs">
        {field.label}
        {field.help && (
          <Tooltip content={field.help}>
            <Info className="text-color-text-icon-secondary h-3 w-3" />
          </Tooltip>
        )}
      </span>
      <FrequencySelect
        fieldKey={field.key}
        frequency={frequency}
        onChangeFrequency={onChangeFrequency}
      />
    </>
  );
};
