import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DropzoneOptions } from 'react-dropzone';

import { useGoToConnectionHub } from '@spektr/shared/hooks';

import { Button, toast } from '@spektr/client/components';

import { DATASETS_KEYS, IngestApiClient } from '@spektr/client/services';

import { FileUploader } from '../../components/FileUploader';

type CsvUploadProps = {
  maxSizeInBytes?: number;
};

export const CsvUpload = ({ maxSizeInBytes }: CsvUploadProps) => {
  const [formData, setFormData] = useState<FormData>(new FormData());
  const goToConnectionHub = useGoToConnectionHub();

  const queryClient = useQueryClient();
  const uploadMutation = useMutation({
    mutationFn: (data: FormData) => {
      return IngestApiClient.getClient().uploadFile(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: DATASETS_KEYS.ALL() });
      toast.success({
        title: 'File successfully uploaded!',
        description: 'Your file has been uploaded.',
      });
      goToConnectionHub();
    },
  });

  const handleClickUpload = () => {
    uploadMutation.mutate(formData);
  };

  const handleClickRemove = async () => {
    setFormData(new FormData());
  };

  const handleDropAccepted: OnDropAcceptedFunction = useCallback(
    ([acceptedFile]) => {
      const nextFormData = new FormData();
      nextFormData.append('file', acceptedFile, acceptedFile.name);
      setFormData(nextFormData);
    },
    []
  );

  const isDisabled = !formData.get('file');

  return (
    <>
      <FileUploader
        accept={{ 'text/csv': ['.csv'] }}
        currentFile={formData.get('file') as File}
        maxSize={maxSizeInBytes}
        onDropAccepted={handleDropAccepted}
        onRemove={handleClickRemove}
      />
      <div className="mt-4">
        <Button
          disabled={isDisabled || uploadMutation.isPending}
          fullWidth
          color="yellow"
          onClick={handleClickUpload}
          data-cy="csv-upload-button"
        >
          Continue
        </Button>
      </div>
    </>
  );
};

type OnDropAcceptedFunction = NonNullable<DropzoneOptions['onDropAccepted']>;
