import { ProcessApiClient } from '../processes';

import { LOOPS_KEYS } from './query.keys';
import { LoopApiClient } from './client';

export function getLoopNodeByIdQuery(loopId: string, nodeId: string) {
  return {
    queryKey: LOOPS_KEYS.NODE_DETAILS(loopId, nodeId),
    queryFn: () =>
      LoopApiClient.getClient().getNodeById({ params: { loopId, nodeId } }),
  };
}

export function getLoopByIdQuery(id: string) {
  return {
    queryKey: LOOPS_KEYS.DETAILS(id),
    queryFn: () => LoopApiClient.getClient().getLoopById({ params: { id } }),
  };
}

export function getLoopsQuery() {
  return {
    queryKey: LOOPS_KEYS.ALL(),
    queryFn: () => LoopApiClient.getClient().getAllLoops(),
  };
}

export function getLoopAllowedSpektrFieldsQuery(
  loopId: string,
  nodeId: string
) {
  return {
    queryKey: LOOPS_KEYS.NODE_ALLOWED_FIELDS(loopId, nodeId),
    queryFn: () =>
      LoopApiClient.getClient().getAllowedSpektrfieldsForNode({
        params: { loopId, nodeId },
      }),
  };
}

// TODO (@Robert) - This should be moved to the processes service???
export function getActionFieldsQuery(processId: string, nodeId: string) {
  return {
    queryKey: LOOPS_KEYS.NODE_ACTION_FIELDS(processId, nodeId),
    queryFn: () =>
      ProcessApiClient.getClient().getActionFields({
        params: { id: processId, nid: nodeId },
      }),
  };
}

export function getSandboxExecutionById(processId: string, sandboxId: string) {
  return {
    queryKey: LOOPS_KEYS.DETAILS_SANDBOX_EXECUTION(processId, sandboxId),
    queryFn: () =>
      ProcessApiClient.getClient().getSandboxExecutionById({
        params: { processId, sandboxId },
      }),
  };
}
