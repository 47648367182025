import { ReactNode } from 'react';

import { useFormRouter } from '../../hooks/useFormRouter';

import { FormRoutes } from '../../types';

export type ViewProps = {
  route: FormRoutes;
  children: ReactNode | ReactNode[];
};

export const View = ({ children, route }: ViewProps) => {
  const { currentRoute } = useFormRouter();

  if (currentRoute !== route) {
    return null;
  }

  return children;
};
