import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@spektr/client/components';

import { SpektrAIProps } from '@spektr/shared/validators';
import { useFeatureFlags } from '@spektr/platform-hooks';

import { useHitsProviderContext } from '../../providers';

export type HitResolutionButtonsProps = {
  id: string;
  hitType: 'aml' | 'kyb';
  isDisabled?: boolean;
  spektrAI?: SpektrAIProps;
  onFalsePositiveClick: (hitId: string) => void;
  onTruePositiveClick: (hitId: string) => void;
};

export const HitResolutionButtons = ({
  id,
  hitType,
  isDisabled,
  spektrAI,
  onFalsePositiveClick,
  onTruePositiveClick,
}: HitResolutionButtonsProps) => {
  const { t } = useTranslation();
  const { resetSelectedHits } = useHitsProviderContext();
  const featureFlags = useFeatureFlags();

  const falsePositiveLabel = t(
    `insights:actions.hit.action.${hitType}.false-positive`
  );
  const truePositiveLabel = t(
    `insights:actions.hit.action.${hitType}.true-positive`
  );

  const handleClickDecision = (event: MouseEvent<HTMLButtonElement>) => {
    resetSelectedHits?.();

    if (event.currentTarget.name === 'true-positive') {
      return onTruePositiveClick(id);
    }

    if (event.currentTarget.name === 'false-positive') {
      return onFalsePositiveClick(id);
    }

    throw new Error('Invalid decision');
  };

  return (
    <div className="ml-auto flex flex-row gap-4">
      {featureFlags.spektrAi &&
        spektrAI &&
        spektrAI.details?.status !== 'enabled' && (
          <Button
            color="white"
            name="ask-spektr-ai"
            disabled={spektrAI.isPending}
            onClick={() => spektrAI.performSearch(id)}
          >
            {!spektrAI.isPending ? 'Ask SpektrAI' : 'Fetching...'}
          </Button>
        )}
      <Button
        color="red"
        name={hitType === 'aml' ? 'true-positive' : 'false-positive'}
        variant="outlined"
        disabled={isDisabled}
        onClick={handleClickDecision}
      >
        {falsePositiveLabel}
      </Button>
      <Button
        color="success"
        disabled={isDisabled}
        name={hitType === 'aml' ? 'false-positive' : 'true-positive'}
        variant="outlined"
        onClick={handleClickDecision}
      >
        {truePositiveLabel}
      </Button>
    </div>
  );
};
