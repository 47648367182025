import { createContext } from 'react';

import { DEFAULT_ROUTE } from '../../constants/visuals';

import type { FormRouterApi } from '../../types/FormRouterApi';

export const FormRouterContext = createContext<FormRouterApi>({
  currentRoute: DEFAULT_ROUTE,
  isActiveRoute: () => false,
  navigateTo: () => null,
});
