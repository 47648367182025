import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Input,
} from '@spektr/client/components';

import {
  RuleGroupCard,
  RuleGroupHeader,
  RuleGroupMenuComboBox,
  RuleGroupContent,
} from '../ruleGroup';
import { useIncompleteMatrixSegment } from '../providers';
import { MatrixSegmentWeightInput, TitleInput } from '../text-inputs';
import {
  getUpdatedMatrixRuleGroups,
  makeIncompleteMatrixRuleGroups,
} from '../tree-helpers';

import { SegmentMenuComboBox, MatrixSegmentHeader } from './components';

export const MatrixSegment = () => {
  const {
    segment,
    config,
    updateSegment,
    removeSegment,
    updateRuleGroup,
    spektrFields,
    scoredRows,
  } = useIncompleteMatrixSegment();

  if (!segment) return null;

  const handleSegmentMenuActionClick = (value: string) => {
    if (value === 'delete') removeSegment();
  };

  const handleFieldChange = (fieldValue: string) => {
    const groups =
      segment.groups.length === 0
        ? makeIncompleteMatrixRuleGroups(scoredRows, fieldValue)
        : getUpdatedMatrixRuleGroups(segment.groups, fieldValue);

    updateSegment({ groups });
  };

  return (
    <Accordion
      key={segment.clientSideOnlyId}
      collapsible
      defaultValue={`segment-${segment.clientSideOnlyId}`}
      type="single"
      className="w-full overflow-y-auto"
    >
      <AccordionItem
        value={`segment-${segment.clientSideOnlyId}`}
        className="relative border-none"
      >
        <AccordionTrigger
          className="justify-start px-3 pb-3 pt-2 hover:no-underline"
          iconPosition="left"
        >
          <MatrixSegmentHeader
            title={segment.title ?? ''}
            spektrFields={spektrFields}
            onFieldChange={handleFieldChange}
          />
        </AccordionTrigger>

        <div className="absolute right-3 top-2 flex items-center gap-2">
          {config.shouldDisplaySegmentScore && <MatrixSegmentWeightInput />}
          <SegmentMenuComboBox
            onActionClick={handleSegmentMenuActionClick}
            isMatrixSegment={true}
          />
        </div>
        <AccordionContent>
          <div className="flex flex-col gap-4">
            {segment.groups.map((group, index) => (
              <RuleGroupCard key={index}>
                <RuleGroupHeader>
                  <TitleInput
                    className="text-color-text-input hover:ring-spektr-zinc-600 focus:ring-spektr-zinc-600 pl-1 text-sm"
                    title={group.title ?? ''}
                    onChange={(title) =>
                      updateRuleGroup(group.clientSideOnlyId, { title })
                    }
                  />
                  <div className="flex items-center gap-3">
                    <p className="text-color-text-subtext text-xs font-normal">
                      Score
                    </p>
                    <Input
                      type="number"
                      name="score"
                      dimension="small"
                      className="w-12 text-right"
                      disabled
                      value={group.score ?? '0'}
                    />
                    {group.rule.type !== 'matrix' && <RuleGroupMenuComboBox />}
                  </div>
                </RuleGroupHeader>
                <RuleGroupContent group={group} />
              </RuleGroupCard>
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
