import { IconWithBackground } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';
import { FileText } from 'lucide-react';

export const FormActionNode = () => {
  return (
    <>
      <div>
        <IconWithBackground
          className={cn('ml-5 mr-4 h-10 w-10', `bg-color-red`)}
        >
          <FileText className="stroke-color-stroke-white h-5 w-5" />
        </IconWithBackground>
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-color-text-node text-sm font-medium">
          Create a form
        </h3>
        <span className="text-color-text-node-subtext text-xs">
          Action step
        </span>
      </div>
    </>
  );
};
