import { StrategyView } from '@spektr/strategy-view';

import { PageHeadline } from '@spektr/client/components';

import { HeaderWithHandler } from '../components/HeaderWithHandler';

export const StrategyPage = () => {
  return (
    <>
      <HeaderWithHandler />

      <div className="relative h-full w-full">
        <div className="absolute left-5 top-5 z-10">
          <PageHeadline
            headline="Strategy"
            paragraph="See an overview of all your processes"
          />
        </div>
        <StrategyView isStrategyPage={true} />
      </div>
    </>
  );
};
