import isEmpty from 'lodash/isEmpty';

import {
  CompanyOwnersCorporationFirstLayerUboSchema,
  CompanyOwnersCorporationRepresentativeSchema,
  CompanyOwnersIndividualFirstLayerUboSchema,
  CompanyOwnersIndividualRepresentativeSchema,
  CompanyOwnersSchema,
} from '@spektr/shared/validators';

type RepresentativeValue =
  | CompanyOwnersIndividualRepresentativeSchema
  | CompanyOwnersCorporationRepresentativeSchema;
type UboValue =
  | CompanyOwnersIndividualFirstLayerUboSchema
  | CompanyOwnersCorporationFirstLayerUboSchema;

export type ParsedUboData = {
  type: string;
  name: string;
  address: string;
  percentage: number;
};

export type ParsedRepresentativeData = {
  type: string;
  name: string;
  address: string;
  role: string;
  isActive: boolean;
};

type ParsedOwnershipData = {
  ubo: ParsedUboData[];
  representatives: ParsedRepresentativeData[];
};

/**
 * @description Parse the ownership data and return an object with
 * UBO and Representatives data
 * @param data - ownership data
 */
export const computeOwnershipData = (
  data: CompanyOwnersSchema
): ParsedOwnershipData => {
  return Object.entries(data).reduce(
    (acc, [key, structure]) => {
      const type = key.includes('corporation') ? 'Company' : 'Individual';
      if (key.includes('Representatives')) {
        const values = (structure as RepresentativeValue[]).map((item) => ({
          type,
          name: item.name ?? '',
          address: item.fullAddress ?? '',
          role: item.role ?? '',
          isActive: item.isActive ?? false,
        }));
        acc.representatives = [...acc.representatives, ...values];
      } else {
        const values = Array.isArray(structure)
          ? structure.map((item: UboValue) => ({
              type,
              name: item.name ?? '',
              address: item.fullAddress ?? '',
              percentage: item.percentage ?? 0,
            }))
          : !isEmpty(structure)
            ? [
                {
                  type,
                  name: Object.keys(structure)[0] ?? '',
                  address: '',
                  percentage: Object.values(structure)[0],
                },
              ]
            : [];

        acc.ubo = [...acc.ubo, ...values];
      }

      return acc;
    },
    {
      ubo: [] as ParsedUboData[],
      representatives: [] as ParsedRepresentativeData[],
    }
  );
};
