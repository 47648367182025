type NodeEnv = 'development' | 'production' | 'test';

export function getAppEnv(): NodeEnv {
  try {
    const env = process.env['NODE_ENV'];
    if (env) {
      return env as NodeEnv;
    }
    return 'development';
  } catch (err) {
    return 'development';
  }
}
