import { Outlet } from 'react-router-dom';

import { HeaderWithHandler } from '../../components/HeaderWithHandler';

export function ProcessesIndexPage() {
  return (
    <>
      <HeaderWithHandler />
      <div className="relative flex-grow">
        <Outlet />
      </div>
    </>
  );
}
