import { ClientActionType } from '../types';

/**
 * @description
 *  Hits are divided into two categories: AML and KYB.
 *
 * @param service - service name
 * @returns Action type in the client
 */
export function getActionTypeBasedOnService(
  service: string | null
): ClientActionType {
  if (service === 'complyAdvantageKyb' || service === 'complyAdvantageKyc') {
    return 'aml';
  }

  return 'kyb';
}
