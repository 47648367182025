import { HTMLAttributes } from 'react';

export const VeriffIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="123"
    height="34"
    viewBox="0 0 123 34"
    fill="none"
    {...props}
  >
    <path
      d="M10.4768 28.57C10.0341 26.3321 9.03808 23.2596 5.68107 13.7011H0L7.78378 33.5388H13.0958L20.4739 13.7009H14.9034C11.7309 23.0698 10.8457 26.0665 10.4768 28.5699V28.57Z"
      fill="#03282C"
    />
    <path
      d="M30.5005 13.2837C24.672 13.2837 20.54 17.7216 20.54 23.6769C20.54 29.8215 24.8193 33.9181 30.7954 33.9181C34.5922 33.9181 37.8375 31.8934 39.38 29.0057L35.4804 26.8252C34.7056 28.3802 33.1195 29.4802 30.8324 29.4802C28.1396 29.4802 26.0367 27.7353 25.7786 25.3079H40.4236C40.6819 17.3423 36.3658 13.2837 30.5005 13.2837ZM25.7417 21.5525C25.9261 19.2389 27.8813 17.6079 30.5005 17.6079C33.1197 17.6079 35.0009 19.2389 35.1116 21.5525H25.7417Z"
      fill="#03282C"
    />
    <path
      d="M61.4508 13.7012H56.2125V33.5391H61.4508V13.7012Z"
      fill="#03282C"
    />
    <path
      d="M86.9081 9.30115C87.6089 9.30115 88.4207 9.41488 89.2321 9.64232V5.31831C88.3469 5.01499 87.1295 4.78711 85.9122 4.78711C82.4446 4.78711 78.9768 6.68373 78.9768 12.2975V13.701H71.3374V13.0561C71.0421 10.2112 72.1488 9.30115 74.0302 9.30115C74.7311 9.30115 75.5428 9.41488 76.3543 9.64232V5.31831C75.469 5.01499 74.2517 4.78711 73.0344 4.78711C69.5668 4.78711 66.099 6.68373 66.099 12.2975V13.701H63.8118V17.9097H66.099V33.5388H71.3374V17.9097H78.9768V33.5388H84.2152V17.9097H89.2321V13.701H84.2152V13.0561C83.9201 10.2112 85.0267 9.30115 86.9081 9.30115Z"
      fill="#03282C"
    />
    <path
      d="M48.2629 19.7799V19.0053L47.6931 13.7012H43.1353V33.539H48.2629V25.5001C48.2629 21.3067 51.0787 18.7061 54.1685 18.7061V13.4736C50.5407 13.8448 48.263 15.9923 48.2629 19.78V19.7799Z"
      fill="#03282C"
    />
    <path d="M61.493 6.5354H56.1703V10.8529H61.493V6.5354Z" fill="#03282C" />
    <path
      d="M112.359 13.5279V26.9183L112.403 26.9178C113.278 20.1537 117.169 15.7328 123 13.6283V-0.000488281C116.295 1.20202 112.359 5.56111 112.359 13.5279Z"
      fill="#03282C"
    />
    <path
      d="M99.8551 24.9807L110.496 33.9995V16.1118L99.8551 7.09302V24.9807Z"
      fill="#03282C"
    />
  </svg>
);
