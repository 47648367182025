import { PaginationOptions } from '@spektr/shared/validators';

import { DatasetApiClient } from './client';
import {
  DATASETS_KEYS,
  getAllSpektrFieldsQueryKey,
  getConnectionStatsQueryKey,
} from './query.keys';
import { RecordFilters } from './types';

export function connectionStatsQuery() {
  return {
    queryKey: getConnectionStatsQueryKey(),
    queryFn: () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          return resolve({});
        }, 1000);
      });
    },
  };
}

export function getAllSpektrFieldsQuery() {
  return {
    queryKey: getAllSpektrFieldsQueryKey(),
    queryFn: () => DatasetApiClient.getClient().getAllSpektrFields(),
  };
}

export function connectionDataSetsQuery() {
  return {
    queryKey: DATASETS_KEYS.ALL(),
    queryFn: () => DatasetApiClient.getClient().getAllDatasets(),
  };
}

export function connectionDataSetByIdQuery(id: string) {
  return {
    queryKey: DATASETS_KEYS.BY_ID(id),
    queryFn: () =>
      DatasetApiClient.getClient().getDatasetById({
        params: { id },
      }),
  };
}

export function getRecordBySpektrId(datasetId: string, spektrId: string) {
  return {
    queryKey: DATASETS_KEYS.RECORD_BY_ID(datasetId, spektrId),
    queryFn: () =>
      DatasetApiClient.getClient().getDatasetRecordById({
        params: { id: datasetId, recordId: spektrId },
      }),
  };
}

export function getExecutionsBySpektrId(
  datasetId: string,
  spektrId: string,
  filters?: PaginationOptions
) {
  return {
    queryKey: DATASETS_KEYS.ALL_RUNS(datasetId, spektrId, filters),
    queryFn: () =>
      DatasetApiClient.getClient().getExecutionsByRecordId({
        params: {
          id: datasetId,
          recordId: spektrId,
        },
        queries: {
          page: filters?.page ?? 1,
          limit: filters?.limit ?? 10,
        },
      }),
  };
}

export function getAllRecords(datasetId: string, filters?: RecordFilters) {
  return {
    queryKey: DATASETS_KEYS.ALL_RECORDS(datasetId, filters),
    queryFn: () =>
      DatasetApiClient.getClient().getRecordsByDatasetId({
        params: {
          id: datasetId,
        },
        queries: {
          page: filters?.page ?? 1,
          limit: filters?.limit ?? 10,
          sortOrder: filters?.sortOrder,
          sortField: filters?.sortField,
          searchValue: filters?.searchValue,
          searchField: filters?.searchField,
        },
      }),
  };
}

export function getAllActionsForRecord(
  recordId: string,
  datasetId: string,
  filters?: PaginationOptions
) {
  return {
    queryKey: DATASETS_KEYS.ALL_ACTIONS(datasetId, recordId, filters),
    queryFn: () =>
      DatasetApiClient.getClient().getActionsByRecordId({
        params: { id: datasetId, recordId },
        queries: {
          page: filters?.page ?? 1,
          limit: filters?.limit ?? 10,
        },
      }),
  };
}
