import { TriggerFrequency } from '@spektr/shared/types';

export const TRIGGER_FREQUENCIES: Array<{
  value: TriggerFrequency;
  label: string;
}> = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
];
