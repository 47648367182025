import { useSuspenseQuery } from '@tanstack/react-query';

import { InsightsRecordDetailsTabNavigation } from '@spektr/client/components';
import {
  connectionDataSetByIdQuery,
  getRecordBySpektrId,
} from '@spektr/client/services';

import { getRecordIdentifierValue } from '../../utils';

export type RecordDetailsPageHeaderProps = {
  datasetId: string;
  spektrId: string;
};

export const RecordDetailsPageHeader = ({
  datasetId,
  spektrId,
}: RecordDetailsPageHeaderProps) => {
  const { data: dataset } = useSuspenseQuery(
    connectionDataSetByIdQuery(datasetId)
  );

  const { data: recordData } = useSuspenseQuery(
    getRecordBySpektrId(datasetId, spektrId)
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="text-color-text-subtext text-xl font-semibold leading-tight">
        Insights on
        <span className="text-color-text-primary">
          {' ' + getRecordIdentifierValue(recordData, dataset)}
        </span>
      </div>

      <InsightsRecordDetailsTabNavigation />
    </div>
  );
};
