import { useMemo } from 'react';
import { z } from 'zod';
import { useSearchParams } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  connectionDataSetByIdQuery,
  getActionById,
  getExecutionById,
  getNodeByIdQuery,
} from '@spektr/client/services';

import { PaginationCombobox } from '@spektr/client/components';

import { getIdentifierValue, computeExtendedHits } from '../../utils';

import { useActionIdParam } from '../../hooks/useActionIdParam';
import { useUpdateHit } from '../../hooks/useUpdateHit';
import { useGetActionType } from '../../hooks/useGetActionType';

import { HitsProvider } from '../../providers';

import { HitsLayout } from '../../components/HitsLayout';
import { HitsList } from '../../components/HitsList';
import { MultipleHitsResolver } from '../../components/MultipleHitsResolver';

const allowedPageValues = [10, 20, 50];
const pageLimitSchema = z
  .number()
  .refine((value) => allowedPageValues.includes(value), {
    message: 'Value must be 10, 20, or 50',
  })
  .default(10);

export const ActionHitsList = () => {
  const actionId = useActionIdParam();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: action } = useSuspenseQuery(getActionById(actionId));
  const { data: node } = useSuspenseQuery(
    getNodeByIdQuery(action.processId, action.nodeId)
  );
  const { data: dataset } = useSuspenseQuery(
    connectionDataSetByIdQuery(action.datasetId)
  );
  const { data: execution } = useSuspenseQuery(
    getExecutionById(action.contextId)
  );
  const actionType = useGetActionType();

  const subject = getIdentifierValue(execution.data, dataset);
  const hitsWithExtendedInfo = useMemo(
    () => computeExtendedHits(action.hits, node.nodeType, action),
    [node.nodeType, action]
  );

  const updateHitMutation = useUpdateHit(
    actionId,
    action.datasetId,
    action.spektrId
  );

  const service = searchParams.get('service');
  const field = searchParams.get('field');
  const pageLimit = pageLimitSchema.parse(
    Number(searchParams.get('limit') ?? '10')
  );
  const page = Number(searchParams.get('page') ?? '1');
  const totalPages = Math.ceil(action.hits.length / pageLimit);

  const handleChangeLimit = (limit: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
      limit: limit.toString(),
    });
  };

  const handleChangePage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    });
  };

  const paginatedData = useMemo(() => {
    const start = (page - 1) * pageLimit;
    const end = start + pageLimit;
    let filteredData = [...action.hits].sort((a, b) => {
      if (a.decision.status !== 'pending' && b.decision.status === 'pending') {
        return 1;
      }
      if (a.decision.status === 'pending' && b.decision.status !== 'pending') {
        return -1;
      }
      return 0;
    });

    if (service) {
      if (field) {
        filteredData = filteredData.filter(
          (hit) =>
            hit.vendorDetails.service === service &&
            hit.vendorDetails.field === field
        );
      } else {
        filteredData = filteredData.filter(
          (hit) => hit.vendorDetails.service === service
        );
      }
    }

    return filteredData.slice(start, end);
  }, [action.hits, field, page, pageLimit, service]);

  return (
    <HitsProvider
      key={field}
      hits={hitsWithExtendedInfo}
      actionStatus={action.status}
      actionType={actionType}
      datasetId={action.datasetId}
      spektrId={action.spektrId}
    >
      <HitsLayout>
        <HitsList
          actionType={actionType}
          data={paginatedData}
          subject={subject}
          isMutationPending={updateHitMutation.isPending}
          onUpdateHit={updateHitMutation.mutateAsync}
        />
        <PaginationCombobox
          currentPage={page}
          totalPages={totalPages}
          pageLimit={pageLimit}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        />
      </HitsLayout>
      <MultipleHitsResolver />
    </HitsProvider>
  );
};
