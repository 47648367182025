import { cn } from '@spektr/client/utils';
import { TableHead, TableHeader, TableRow } from '@spektr/client/components';

const baseThClasses = cn(
  'px-4 py-3.5',
  'border-b',
  'text-xs text-color-text-table-header font-medium'
);

export const FieldMappingTableHeader = () => (
  <TableHeader className="sticky top-0">
    <TableRow className="bg-color-bg-table-row hover:bg-color-bg-table-row--hover h-[38px]">
      <TableHead className={baseThClasses}>Custom field</TableHead>
      <TableHead className={baseThClasses}>Type</TableHead>
      <TableHead className={baseThClasses}>Source field</TableHead>
    </TableRow>
  </TableHeader>
);
