import {
  actionNodeSchema,
  calculationNodeSchema,
  monitoringDatasetNodeSchema,
  onboardingProcessSourceNodeSchema,
  processNodeSchema,
  returningProcessNodeSchema,
  routerNodeSchema,
  slackNodeSchema,
} from '@spektr/shared/validators';

import { useDialogClose } from '@spektr/shared/hooks';
import { useFeatureFlags } from '@spektr/platform-hooks';

import {
  CalculationNodeProvider,
  ReturningProcessNodeProvider,
  RouterNodeProvider,
  CalculationNodeFormDialogContent,
  ReturningProcessNodeFormDialogContent,
  parseCalculation,
  parseReturningProcess,
  parseMonitoringDataset,
  parseRouter,
  RouterNodeFormDialogContent,
  SlackNodeProvider,
  parseSlack,
  SlackNodeFormDialogContent,
} from '@spektr/shared/components';

import { Dialog, DialogContent } from '@spektr/client/components';

import {
  NodeUpdateInput,
  ProcessNode,
  SpektrField,
  isActionFormNodeType,
  Process,
  isServiceNodeType,
  isMonitoringDatasetNodeType,
} from '@spektr/shared/types';

import { FormBuilder } from './containers/FormBuilder';
import { ServiceNodeFactory } from './containers/ServiceNodeFactory';
import { ActionNodeDialog } from './containers/ActionNodeDialog';
import { ActionFormDialog } from './containers/ActionFormDialog';
import { OnboardingSourceDialog } from './containers/OnboardingSourceDialog';
import { MonitoringDatasetNodeFormDialogContent } from './containers/MonitoringDatasetDialog/MonitoringDatasetNodeFormDialogContent';
import { MonitoringDatasetNodeProvider } from './containers/MonitoringDatasetDialog/hooks/monitoringDatasetProvider';

export type NodeDetailsFactoryDialogProps = {
  allowedSpektrFields: SpektrField[];
  process: Process;
  node: ProcessNode;
  onClose: () => void;
  onUpdate: (data: NodeUpdateInput) => void;
  isPendingUpdate: boolean;
};

export const NodeDetailsFactoryDialog = ({
  allowedSpektrFields,
  process,
  node,
  onClose,
  onUpdate,
  isPendingUpdate,
}: NodeDetailsFactoryDialogProps) => {
  const [open, startExitAnimation] = useDialogClose();
  const { loopFormV2 } = useFeatureFlags();
  const nodeResult = processNodeSchema.safeParse(node);

  if (nodeResult.success) {
    const calculationNodeResult = calculationNodeSchema.safeParse(
      nodeResult.data
    );

    if (calculationNodeResult.success) {
      return (
        <CalculationNodeProvider
          initial={parseCalculation(calculationNodeResult.data)}
          processType={process.type}
        >
          <Dialog open={open} modal={false}>
            <CalculationNodeFormDialogContent
              requestStartExitAnimation={startExitAnimation}
              spektrFields={allowedSpektrFields ?? []}
              onEndExitAnimation={onClose}
              onUpdate={onUpdate}
              onEscapeKeyDown={onClose}
              isPendingUpdate={isPendingUpdate}
            />
          </Dialog>
        </CalculationNodeProvider>
      );
    }

    const returningProcessNodeResult = returningProcessNodeSchema.safeParse(
      nodeResult.data
    );
    if (returningProcessNodeResult.success) {
      return (
        <ReturningProcessNodeProvider
          initial={parseReturningProcess(returningProcessNodeResult.data)}
        >
          <Dialog open={open} modal={false}>
            <ReturningProcessNodeFormDialogContent
              requestStartExitAnimation={startExitAnimation}
              onEndExitAnimation={onClose}
              onUpdate={onUpdate}
              process={process}
              onEscapeKeyDown={onClose}
              isPendingUpdate={isPendingUpdate}
            />
          </Dialog>
        </ReturningProcessNodeProvider>
      );
    }

    const monitoringDatasetNodeResult = monitoringDatasetNodeSchema.safeParse(
      nodeResult.data
    );
    if (
      monitoringDatasetNodeResult.success &&
      isMonitoringDatasetNodeType(node)
    ) {
      return (
        <MonitoringDatasetNodeProvider
          initial={parseMonitoringDataset(monitoringDatasetNodeResult.data)}
        >
          <Dialog open={open} modal={false}>
            <MonitoringDatasetNodeFormDialogContent
              nodeId={node.id}
              node={node}
              processId={process.id}
              requestStartExitAnimation={startExitAnimation}
              onEndExitAnimation={onClose}
              onUpdate={onUpdate}
              onEscapeKeyDown={onClose}
            />
          </Dialog>
        </MonitoringDatasetNodeProvider>
      );
    }

    const routerNodeResult = routerNodeSchema.safeParse(nodeResult.data);
    if (routerNodeResult.success) {
      return (
        <RouterNodeProvider initial={parseRouter(routerNodeResult.data)}>
          <Dialog open={open} modal={false}>
            <RouterNodeFormDialogContent
              requestStartExitAnimation={startExitAnimation}
              spektrFields={allowedSpektrFields ?? []}
              onEndExitAnimation={onClose}
              onUpdate={onUpdate}
              isPendingUpdate={isPendingUpdate}
              onEscapeKeyDown={onClose}
            />
          </Dialog>
        </RouterNodeProvider>
      );
    }

    const slackNodeResult = slackNodeSchema.safeParse(nodeResult.data);
    if (slackNodeResult.success) {
      return (
        <SlackNodeProvider initial={parseSlack(slackNodeResult.data)}>
          <Dialog open={open} modal={false}>
            <SlackNodeFormDialogContent
              requestStartExitAnimation={startExitAnimation}
              spektrFields={allowedSpektrFields ?? []}
              onEndExitAnimation={onClose}
              onUpdate={onUpdate}
              isPendingUpdate={isPendingUpdate}
              onEscapeKeyDown={onClose}
            />
          </Dialog>
        </SlackNodeProvider>
      );
    }

    const actionNodeResult = actionNodeSchema.safeParse(nodeResult.data);
    if (actionNodeResult.success) {
      return (
        <ActionNodeDialog
          process={process}
          node={actionNodeResult.data}
          onClose={onClose}
          onUpdate={onUpdate}
        />
      );
    }

    const processSourceNodeResult = onboardingProcessSourceNodeSchema.safeParse(
      nodeResult.data
    );

    if (processSourceNodeResult.success) {
      return (
        <OnboardingSourceDialog
          process={process}
          node={processSourceNodeResult.data}
        />
      );
    }

    if (isServiceNodeType(node)) {
      return (
        <ServiceNodeFactory
          process={process}
          node={node}
          onClose={onClose}
          onUpdate={onUpdate}
        />
      );
    }

    if (isActionFormNodeType(node)) {
      if (loopFormV2) {
        return (
          <ActionFormDialog
            node={node}
            process={process}
            spektrFields={allowedSpektrFields ?? []}
            onClose={onClose}
          />
        );
      }
      return <FormBuilder node={node} onClose={onClose} />;
    }

    return (
      <Dialog open={open} modal={false}>
        <DialogContent
          modal={false}
          className="text-color-text-dialog-default"
          requestStartExitAnimation={startExitAnimation}
          onEndExitAnimation={onClose}
          onEscapeKeyDown={onClose}
        >
          Not implemented yet
        </DialogContent>
      </Dialog>
    );
  }

  // TODO: Some 404 page?
  return null;
};
