import { Skeleton } from '@spektr/client/components';

export const SkeletonToolbar = () => (
  <div className="flex items-center gap-4">
    <Skeleton className="h-9 w-60 rounded-md" />
    <div className="ml-auto flex items-center gap-4">
      <Skeleton className="h-9 w-60 rounded-md" />
      <Skeleton className="h-9 w-28 rounded-md" />
    </div>
  </div>
);
