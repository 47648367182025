import { ServiceSpektrField } from '../types/ServiceSpektrField';

export const CA_COMPANY_DETAILS: ServiceSpektrField[] = [
  {
    key: 'companyName',
    label: 'Company Name',
    type: 'string',
    description: '* Mandatory',
  },
  {
    key: 'companyNumber',
    label: 'Company Number',
    type: 'string',
    description: '',
  },
  {
    key: 'countryCode',
    label: 'Jurisdiction code',
    type: 'string',
    description: '',
  },
];

export const CA_INDIVIDUAL_DETAILS: ServiceSpektrField[] = [
  {
    key: 'firstName',
    label: 'First Name',
    type: 'string',
    description: '* Mandatory',
  },
  {
    key: 'lastName',
    label: 'Last Name',
    type: 'string',
    description: '* Mandatory',
  },
  {
    key: 'fullName',
    label: 'Full Name',
    type: 'string',
    description: '* Mandatory if no first and last name',
  },
  {
    key: 'dateOfBirth',
    label: 'Date of Birth',
    type: 'date',
    description: 'Recommended',
  },
  {
    key: 'middleName',
    label: 'Middle Name',
    type: 'string',
    description: '',
  },
];

export const OC_COMPANY_DETAILS: ServiceSpektrField[] = [
  {
    key: 'companyNumber',
    label: 'Company Number',
    type: 'string',
    description: '',
  },
  {
    key: 'countryCode',
    label: 'Jurisdiction code',
    type: 'string',
    description: '* Mandatory',
  },
  {
    key: 'companyName',
    label: 'Company Name',
    type: 'string',
    description: '',
  },
];

export const BODACC_DETAILS: ServiceSpektrField[] = [
  {
    key: 'siretNumber',
    label: 'SIREN or SIRET',
    description: '* Mandatory',
  },
  {
    key: 'companyName',
    label: 'Company Name',
    type: 'string',
    description: '',
  },
];

export const KYCKR_DETAILS: ServiceSpektrField[] = [
  {
    key: 'companyName',
    label: 'Company Name',
    type: 'string',
    description: '',
  },
  {
    key: 'companyNumber',
    label: 'Company Number',
    type: 'string',
    description: '* Mandatory',
  },
  {
    key: 'countryCode',
    label: 'Jurisdiction code',
    type: 'string',
    description: '* Mandatory',
  },
];

export const VERIFF_DETAILS: ServiceSpektrField[] = [
  {
    key: 'firstName',
    label: 'First Name',
    type: 'string',
    description: '',
  },
  {
    key: 'lastName',
    label: 'Last Name',
    type: 'string',
    description: '',
  },
];

export const MONITORING_DATASET_DETAILS: ServiceSpektrField[] = [];
export const MITID_DETAILS: ServiceSpektrField[] = [];
