import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import { cn } from '@spektr/client/utils';
import { useState } from 'react';

import { Dialog, DialogContent, Spinner } from '@spektr/client/components';
import { useUpdateCurrentRoute } from '@spektr/client/providers';

import { MeetingAvatar } from './MeetingAvatar';

type BookMeetingDialogProps = {
  open: boolean;
};

export const BookMeetingDialog = ({ open }: BookMeetingDialogProps) => {
  const [isCalendlyLoaded, setIsCalendlyLoaded] = useState(false);
  const updateState = useUpdateCurrentRoute();

  useCalendlyEventListener({
    onEventTypeViewed: () => setIsCalendlyLoaded(true),
  });

  const handleClose = () => {
    updateState();
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className="bg-color-bg-primary m-auto flex max-w-[802px] flex-col items-center gap-4 rounded-xl p-6"
        requestStartExitAnimation={handleClose}
        onEscapeKeyDown={handleClose}
      >
        <div className="flex flex-row gap-2">
          <div
            className={cn(
              'flex flex-col justify-between',
              'max-w-[275px]  p-6',
              'bg-color-bg-accent rounded-xl'
            )}
          >
            <div className="relative self-end">
              <MeetingAvatar className="bg-avatar-david right-[80px]" />
              <MeetingAvatar className="bg-avatar-erik right-0" />
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-color-text-primary font-semibold">
                Book a meeting with Erik or David
              </p>
              <p className="text-color-text-subtext text-xs">
                Get insights into how we can onboard you and your organisation
                to the spektr platform.
              </p>
            </div>
          </div>
          <div className="h-[450px] w-[484px] overflow-auto rounded-xl">
            {!isCalendlyLoaded && (
              <div className="flex h-full w-full items-center justify-center">
                <Spinner className="text-color-text-icon-primary" />
              </div>
            )}
            <div className={isCalendlyLoaded ? 'block' : 'hidden'}>
              <InlineWidget
                styles={{
                  height: '450px',
                }}
                url="https://calendly.com/d/3px-3w9-yg5/spektr-introduction"
                pageSettings={{
                  hideEventTypeDetails: true,
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
