import { z } from 'zod';
import { makeParameters } from '@zodios/core';

export const paginatedParameters = makeParameters([
  {
    name: 'limit',
    schema: z.number().nonnegative().default(10),
    type: 'Query',
  },
  {
    name: 'page',
    schema: z.number().nonnegative().default(1),
    type: 'Query',
  },
]);
