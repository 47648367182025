import { ReactNode } from 'react';

import { DialogTitle } from '@spektr/client/components';

export type NodeDetailsDialogTitleProps = {
  left: ReactNode;
  right: ReactNode;
};
export const NodeDetailsDialogTitle = ({
  left,
  right,
}: NodeDetailsDialogTitleProps) => {
  return (
    <DialogTitle className="mb-6">
      <div className="flex w-full items-center justify-between gap-2">
        {left}
        {right}
      </div>
    </DialogTitle>
  );
};
