import { HTMLAttributes } from 'react';

import { cn } from '@spektr/client/utils';

export const SpektrRoutingNode = ({
  className,
  ...props
}: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(
        'text-color-white/90 stroke-color-stroke-white/90',
        className
      )}
      {...props}
    >
      <g id="RoutingNode" clipPath="url(#clip0_6760_1742)">
        <path
          id="Icon"
          d="M1.44446 7.16047V7.07405C1.44446 6.34805 1.44446 5.98506 1.58978 5.70776C1.71761 5.46385 1.92159 5.26554 2.17247 5.14126C2.45769 4.99997 2.83105 4.99997 3.57779 4.99997H6.42224C7.16897 4.99997 7.54234 4.99997 7.82756 5.14126C8.07844 5.26554 8.28241 5.46385 8.41024 5.70776C8.55557 5.98506 8.55557 6.34805 8.55557 7.07405V7.16047M1.44446 7.16047C0.953538 7.16047 0.555569 7.54738 0.555569 8.02466C0.555569 8.50195 0.953538 8.88886 1.44446 8.88886C1.93538 8.88886 2.33335 8.50195 2.33335 8.02466C2.33335 7.54738 1.93538 7.16047 1.44446 7.16047ZM8.55557 7.16047C8.06465 7.16047 7.66668 7.54738 7.66668 8.02466C7.66668 8.50195 8.06465 8.88886 8.55557 8.88886C9.04649 8.88886 9.44446 8.50195 9.44446 8.02466C9.44446 7.54738 9.04649 7.16047 8.55557 7.16047ZM5.00001 2.83948L5.00001 7.16047M5.00001 2.83948C4.50909 2.83948 4.11112 2.45256 4.11112 1.97528C4.11112 1.498 4.50909 1.11108 5.00001 1.11108C5.49093 1.11108 5.8889 1.498 5.8889 1.97528C5.8889 2.45256 5.49093 2.83948 5.00001 2.83948ZM5.00001 7.16047C4.50909 7.16047 4.11112 7.54738 4.11112 8.02466C4.11112 8.50195 4.50909 8.88886 5.00001 8.88886C5.49093 8.88886 5.8889 8.50195 5.8889 8.02466C5.8889 7.54738 5.49093 7.16047 5.00001 7.16047Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6760_1742">
          <rect width="10" height="10" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
