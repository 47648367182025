import { ReactNode, useState } from 'react';
import { UserRoundSearch } from 'lucide-react';

import { Button } from '@spektr/client/components';

import { InsightsResolveActionDialog } from '../InsightsResolveActionDialog';

export type SidebarHeaderProps = {
  identifier: ReactNode;
  isResolveDisabled?: boolean;
  onResolve: (reason?: string) => void;
};

export const SidebarHeader = ({
  identifier,
  isResolveDisabled = false,
  onResolve,
}: SidebarHeaderProps) => {
  const [showResolveActionDialog, setShowResolveActionDialog] = useState(false);

  return (
    <div className="flex flex-row items-center gap-4">
      <UserRoundSearch className="h-6 w-6" />
      <div className="flex flex-col">
        <span className="text-color-text-subtext text-sm font-medium leading-4">
          Details about
        </span>
        <span className="text-base font-medium ">{identifier}</span>
      </div>
      <Button
        className="ml-auto w-36"
        color="success"
        size="lg"
        disabled={isResolveDisabled}
        onClick={() => setShowResolveActionDialog(true)}
      >
        Resolve
      </Button>

      {showResolveActionDialog ? (
        <InsightsResolveActionDialog
          onResolve={onResolve}
          onCancel={() => setShowResolveActionDialog(false)}
          areActionsDisabled={isResolveDisabled}
        />
      ) : null}
    </div>
  );
};
