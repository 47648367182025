import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LS_KEYS } from '@spektr/client/constants';
import { localStorageUtils } from '@spektr/client/utils';

export const useDatasetId = (fallBackId = '') => {
  const lsDatasetId =
    localStorageUtils.getItem(LS_KEYS.INSIGHTS_DATASET_ID) ?? '';
  const defaultId = lsDatasetId || fallBackId;
  const [searchParams, setSearchParams] = useSearchParams();
  const paramDatasetId = searchParams.get('dataset') ?? '';
  const datasetId = useMemo(
    () => paramDatasetId || defaultId,
    [paramDatasetId, defaultId]
  );

  const setDatasetId = useCallback(
    (id: string) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        dataset: id,
      });
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (!paramDatasetId) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        dataset: defaultId,
      });
    }
  }, [defaultId, searchParams, paramDatasetId, setSearchParams]);

  useEffect(() => {
    if (paramDatasetId) {
      localStorageUtils.setItem(LS_KEYS.INSIGHTS_DATASET_ID, paramDatasetId);
    }
  }, [paramDatasetId]);

  return useMemo(
    () => ({ datasetId, setDatasetId }),
    [datasetId, setDatasetId]
  );
};
