import { RouteObject } from 'react-router-dom';

import { CASES_CUSTOMERS, CASES_URL } from '@spektr/shared/utils';

import { CasesIndexPage } from './IndexPage';
import { CustomersIndexPage } from './customers';

export const casesRoutes: RouteObject = {
  path: CASES_URL,
  element: <CasesIndexPage />,
  handle: {
    title: 'Cases',
  },
  children: [
    {
      path: CASES_CUSTOMERS,
      children: [
        {
          index: true,
          element: <CustomersIndexPage />,
        },
      ],
    },
  ],
};
