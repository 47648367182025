import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { RBAC } from '@spektr/shared/rbac';
import { NodeUpdateInput, SpektrField } from '@spektr/shared/types';

import {
  Button,
  Input,
  Label,
  PasswordInput,
  Select,
  Textarea,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { useIncompleteSlackNode } from '../providers';
import { getServiceFieldsWithoutSubfields } from '../utils';

import { SlackFieldRow } from './components';

type FormValues = {
  entityName: string;
  channelId: string;
  customMessage: string;
  botUserOauthToken: string;
};

type SlackFormProps = {
  spektrFields: SpektrField[];
  isPendingUpdate: boolean;
  onUpdate: (data: NodeUpdateInput) => void;
};

export const SlackForm = ({
  spektrFields,
  isPendingUpdate,
  onUpdate,
}: SlackFormProps) => {
  const { incomplete } = useIncompleteSlackNode();
  const {
    control,
    register,
    handleSubmit: handleFormSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    defaultValues: incomplete,
  });
  const { hasPermission } = usePermissionsContext();

  const identifierOptions = useMemo(() => {
    return getServiceFieldsWithoutSubfields(spektrFields).map((field) => ({
      label: field.label,
      value: field.key,
    }));
  }, [spektrFields]);

  const handleSubmit = ({
    entityName,
    channelId,
    customMessage,
    botUserOauthToken,
  }: FormValues) => {
    onUpdate({
      ...incomplete,
      entityName,
      channelId,
      customMessage,
      botUserOauthToken,
    });
  };

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)} className="container">
      {/* TODO: @Alex Remove as part of ST-658. Issue https://issues.chromium.org/issues/41239842 */}
      <input
        name="fake-field"
        type="password"
        className="hidden"
        autoComplete="new-password"
      ></input>

      <div className="mt-4 flex flex-col gap-2 overflow-y-auto">
        <span className="text-color-text-subtext text-xs">
          Select your channel and customize your message
        </span>
        <section className="flex min-w-[500px] flex-col rounded-md border">
          <Controller
            name="entityName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <SlackFieldRow title="Entity name">
                <Select
                  {...field}
                  required
                  size="small"
                  placeholder="Select field"
                  options={identifierOptions}
                  onValueChange={field.onChange}
                />
              </SlackFieldRow>
            )}
          />
          <SlackFieldRow title="Channel ID">
            <Input
              {...register('channelId', {
                required: true,
              })}
              dimension="small"
              data-testid="spektr-field-input"
              placeholder="Type Slack Channel Id Value"
              autoComplete="slack-channel-id"
            />
          </SlackFieldRow>
          <SlackFieldRow title="Bot User OAuth Token">
            <PasswordInput
              {...register('botUserOauthToken', {
                required: true,
              })}
              dimension="small"
              placeholder="Type Bot User OAuth Token"
              autoComplete="slack-bot-user-oauth-token"
            />
          </SlackFieldRow>
          <div className="flex flex-col gap-1.5 p-4 pb-0">
            <Label>Custom message</Label>
            <Textarea
              {...register('customMessage', {
                required: true,
              })}
              className="bg-transparent"
              placeholder="Write your message here, use {{outputField}} for fields from dataset or from process output."
              rows={5}
              cols={10}
              data-testid="slack-field-custom-message"
            />
          </div>
          <Button
            className="m-4 ml-auto"
            disabled={
              !isValid ||
              isPendingUpdate ||
              !hasPermission(RBAC.ACTIONS.NODE.UPDATE)
            }
          >
            {isPendingUpdate ? 'Saving...' : 'Save'}
          </Button>
        </section>
      </div>
    </form>
  );
};
