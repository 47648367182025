import { Info } from 'lucide-react';

import {
  Checkbox,
  Label,
  RadioCheckbox,
  RadioGroup,
  Tooltip,
} from '@spektr/client/components';

import { ServiceFieldClient } from '../../types/ServiceFieldClient';

type KyckrUboFieldsProps = {
  showCheckbox?: boolean;
  selectedKey: string;
  fields: ServiceFieldClient[];
  onKeyUpdate: (key: string) => void;
};

export const KyckrUboFields = ({
  showCheckbox = true,
  selectedKey,
  fields,
  onKeyUpdate,
}: KyckrUboFieldsProps) => {
  const handleUboCheck = (value: boolean) => {
    onKeyUpdate(value ? 'first_layer_of_ownership' : '');
  };

  const handleUboRadioChange = (value: string) => {
    onKeyUpdate(value);
  };

  return (
    <div className="flex flex-col gap-2">
      {showCheckbox ? (
        <Checkbox
          className="gap-3"
          id="ubo"
          label={<span className="text-color-text-checkbox text-xs">UBO</span>}
          checked={!!selectedKey}
          onCheckedChange={handleUboCheck}
        />
      ) : (
        <Label>UBO</Label>
      )}

      <RadioGroup
        value={selectedKey}
        onValueChange={handleUboRadioChange}
        className="flex flex-col gap-2 pl-6"
      >
        {fields.map((field) => (
          <RadioCheckbox
            key={field.key}
            id={field.key}
            value={field.key}
            label={
              <span className="flex items-center gap-2 whitespace-normal">
                {field.label}
                {field.help && (
                  <Tooltip content={field.help}>
                    <Info className="text-color-text-icon-secondary h-3 w-3" />
                  </Tooltip>
                )}
              </span>
            }
          />
        ))}
      </RadioGroup>
    </div>
  );
};
