import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { icons } from 'lucide-react';

import { getSourcesFromRootNode } from '@spektr/shared/utils';

import {
  Button,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@spektr/client/components';

import {
  type ApiKeySchema,
  type ChannelSettings,
} from '@spektr/shared/validators';
import {
  type ProcessSource,
  type Process,
  type SpektrField,
} from '@spektr/shared/types';

import { apiToFormValues } from './utils/apiToFormValues';
import { formValuesToApi } from './utils/formValuesToApi';

import { SourceTabView } from './containers/SourceTabView';
import { ChannelTabView } from './containers/ChannelTabView';

import { TabNavigation } from './components/TabNavigation';

import { type SettingsTab } from './types/SettingsTab';
import {
  ChannelSettingsValidationSchema,
  type ChannelSettingsFormValues,
} from './types/ChannelSettingsFormValues';

export type SourceSettingsProps = {
  apiKeys: ApiKeySchema[];
  defaultTab?: SettingsTab;
  channelSettings?: ChannelSettings;
  isUpdatePending?: boolean;
  hasUpdatePermission?: boolean;
  nodeTitle: string;
  nodeIcon: keyof typeof icons;
  process: Process;
  processes: Process[];
  spektrFields: SpektrField[];
  onClickUpdate: (sources: ProcessSource[], settings: ChannelSettings) => void;
};

export const SourceSettings = ({
  apiKeys,
  defaultTab = 'source',
  channelSettings,
  isUpdatePending = false,
  hasUpdatePermission = false,
  nodeTitle,
  nodeIcon,
  process,
  processes,
  spektrFields,
  onClickUpdate,
}: SourceSettingsProps) => {
  const Icon = icons[nodeIcon];

  const defaultSettings = useMemo(
    () => apiToFormValues(channelSettings),
    [channelSettings]
  );

  const formInstance = useForm<ChannelSettingsFormValues>({
    defaultValues: defaultSettings,
    resolver: zodResolver(ChannelSettingsValidationSchema),
  });

  const [settingsTab, setSettingsTab] = useState<SettingsTab>(defaultTab);
  const [selectedSources, updateSelectedSources] = useState<ProcessSource[]>(
    getSourcesFromRootNode(process)
  );

  const handleClickUpdate = () => {
    const settings = formValuesToApi(formInstance.getValues());
    onClickUpdate(selectedSources, settings);
  };

  const isGenerallyDisabled = isUpdatePending || !hasUpdatePermission;

  return (
    <div>
      <DialogHeader className="flex flex-col gap-4 space-y-0">
        <div className="flex items-center gap-2">
          <Icon className="h-4 w-4" />
          <DialogTitle className="text-color-text-dialog-title">
            {nodeTitle}
          </DialogTitle>
        </div>
        <TabNavigation activeTab={settingsTab} onChangeTab={setSettingsTab} />
      </DialogHeader>
      <SourceTabView
        activeTab={settingsTab}
        process={process}
        processes={processes}
        onUpdateSelection={updateSelectedSources}
      />
      <ChannelTabView
        activeTab={settingsTab}
        apiKeys={apiKeys}
        formInstance={formInstance}
        spektrFields={spektrFields}
      />

      <DialogFooter>
        <Button
          disabled={
            isGenerallyDisabled ||
            !formInstance.formState.isValid ||
            !selectedSources.length
          }
          color="red"
          className="mt-4"
          fullWidth
          onClick={handleClickUpdate}
        >
          Update node
        </Button>
      </DialogFooter>
    </div>
  );
};
