import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SpektrfieldCombobox } from '@spektr/client/components';

import { SpektrField } from '@spektr/shared/types';

import { ServiceSpektrField } from '../../types/ServiceSpektrField';

export type IdentifierItemProps = {
  field: ServiceSpektrField;
  spektrFields: SpektrField[];
};

export const IdentifierItem = ({
  field,
  spektrFields,
}: IdentifierItemProps) => {
  const { setValue, getValues, setError } = useFormContext();

  const fieldsMatchingType = useMemo(
    () =>
      spektrFields.filter(
        (spektrField) =>
          field.type === undefined || spektrField.type === field.type
      ),
    [field.type, spektrFields]
  );

  const handleChange = (fieldName: string) => (value: string) => {
    const valid = fieldsMatchingType.some((field) => field.key === value);
    if (valid) {
      setValue(fieldName, value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else if (value.trim() === '') {
      // Allow clearing the field
      setValue(fieldName, undefined, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      setError(fieldName, { message: 'Invalid field', type: 'manual' });
    }
  };

  return (
    <div className="text-color-text-subtext grid w-full grid-cols-[105px_auto_1fr_auto] items-center gap-3 text-xs">
      <span className="text-color-text-primary">{field.label}</span>
      <span className="capitalize">{field.type}</span>
      <span className="min-w-fit text-right">{field.description}</span>
      <div className="ml-auto">
        <Controller
          name={field.key}
          render={({ field: renderField }) => (
            <SpektrfieldCombobox
              defaultValue={getValues(renderField.name)}
              fields={fieldsMatchingType}
              onChange={handleChange(renderField.name)}
              inputProps={{ placeholder: 'Select field' }}
              triggerProps={{ className: 'w-[200px]' }}
            />
          )}
        />
      </div>
    </div>
  );
};
