import { getRootNode } from '@spektr/shared/utils';
import { Process } from '@spektr/shared/types';

export const getNumberOfSources = (loop: Process) => {
  let noOfSources = 0;
  const root = getRootNode(loop);

  if (root?.nodeType === 'loopSource') {
    noOfSources = root?.sources?.length;
  }

  return noOfSources;
};
