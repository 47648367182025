import { RouteObject } from 'react-router-dom';

import {
  INSIGHTS_ACTIONS,
  INSIGHTS_ACTION_HITS,
  INSIGHTS_ACTION_HITS_PARAM,
  INSIGHTS_ACTION_HIT_COMMENTS,
  INSIGHTS_ACTION_PARAM,
  INSIGHTS_DATASET_PARAM,
  INSIGHTS_RECORDS,
  INSIGHTS_RECORD_ACTIONS,
  INSIGHTS_RECORD_DETAILS_URL,
  INSIGHTS_RECORD_OWNERSHIP_STRUCTURE,
  INSIGHTS_RECORD_RUNS,
  INSIGHTS_RUNS,
  INSIGHTS_RUN_PARAM,
  INSIGHTS_URL,
} from '@spektr/shared/utils';

import {
  ActionPageDetails,
  HitDetailsComments,
  HitDetails,
  HitPageDetails,
  InsightsActionDetails,
  InsightsRunDetails,
} from '@spektr/model-builder/containers';

import { InsightsIndexPage } from './IndexPage';
import { RunsPage } from './RunsPage';
import { ActionsPage } from './ActionsPage';
import { RecordsIndexPage } from './records/RecordsIndexPage';
import { RecordDetailsPage } from './records/RecordDetailsPage';
import { RecordDetailsActionsPage } from './records/RecordDetailsActionsPage';
import { RecordDetailsRunsPage } from './records/RecordDetailsRunsPage';
import { RecordsByDataset } from './records/RecordsByDataset';
import { RecordDetailsOwnershipPage } from './records/RecordDetailsOwnershipPage';

export const insightsRoutes: RouteObject = {
  path: INSIGHTS_URL,
  element: <InsightsIndexPage />,
  handle: {
    title: 'Insights',
  },
  children: [
    // Insights Run
    {
      path: INSIGHTS_RUNS,
      children: [
        {
          index: true,
          element: <RunsPage />,
        },
        {
          path: INSIGHTS_RUN_PARAM,
          element: <InsightsRunDetails />,
          handle: {
            backTo: {
              url: INSIGHTS_URL,
              label: 'Insight Engine',
            },
          },
        },
      ],
    },

    // Insights Actions
    {
      path: INSIGHTS_ACTIONS,
      children: [
        {
          index: true,
          element: <ActionsPage />,
        },
        {
          path: INSIGHTS_ACTION_PARAM,
          element: <InsightsActionDetails />,
          handle: {
            backTo: {
              url: INSIGHTS_ACTIONS,
              label: 'Insight Engine',
            },
          },
          children: [
            { index: true, element: <ActionPageDetails /> },
            {
              path: `${INSIGHTS_ACTION_HITS}/${INSIGHTS_ACTION_HITS_PARAM}`,
              element: <HitPageDetails />,
              children: [
                { index: true, element: <HitDetails /> },
                {
                  path: INSIGHTS_ACTION_HIT_COMMENTS,
                  element: <HitDetailsComments />,
                },
              ],
            },
          ],
        },
      ],
    },

    // Insights Records
    {
      path: INSIGHTS_RECORDS,
      children: [
        {
          index: true,
          element: <RecordsIndexPage />,
        },
        {
          path: INSIGHTS_DATASET_PARAM,
          element: <RecordsByDataset />,
        },
        {
          path: INSIGHTS_RECORD_DETAILS_URL,
          handle: {
            backTo: {
              url: INSIGHTS_RECORDS,
              label: 'Insight Engine',
            },
          },
          element: <RecordDetailsPage />,
          children: [
            {
              path: INSIGHTS_RECORD_RUNS,
              element: <RecordDetailsRunsPage />,
            },
            {
              path: INSIGHTS_RECORD_ACTIONS,
              element: <RecordDetailsActionsPage />,
            },
            {
              path: INSIGHTS_RECORD_OWNERSHIP_STRUCTURE,
              element: <RecordDetailsOwnershipPage />,
            },
          ],
        },
      ],
    },
  ],
};
