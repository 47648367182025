import { z } from 'zod';

import { BaseField } from './BaseField';
import { FieldValidation } from './FieldValidation';

export const InputFieldAttributes = z.object({
  label: z.string(),
  type: z.string().optional().default('text'),
  placeholder: z.string().optional(),
  helperText: z.string().optional(),
});

export const InputField = BaseField.merge(
  z.object({
    type: z.literal('input'),
    value: z.string().optional(),
    attributes: InputFieldAttributes,
    validation: z.array(FieldValidation).default([]),
  })
);

export type InputField = z.infer<typeof InputField>;

export function isInputField(field: unknown): field is InputField {
  return InputField.safeParse(field).success;
}
