import { useMemo } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';
import { SpektrFieldTypedKeyWithMapping } from '@spektr/shared/validators';

import { FieldMappingTableRow } from './FieldMappingTableRow';
import { FieldMappingTableHeader } from './FieldMappingTableHeader';

type FieldMappingTableProps = {
  fields: SpektrFieldTypedKeyWithMapping[];
  sourceFields: string[];
};

export const FieldMappingTable = ({
  fields,
  sourceFields,
}: FieldMappingTableProps) => {
  const sourceOptions = useMemo(
    () => sourceFields.map((field) => ({ label: field, value: field })),
    [sourceFields]
  );

  return (
    <div className="relative max-h-[300px] w-full overflow-y-auto rounded-md border">
      <Table className="border-separate">
        <FieldMappingTableHeader />
        <TableBody>
          {fields.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4} className="py-4 text-center">
                No custom fields found
              </TableCell>
            </TableRow>
          ) : (
            fields.map((row: any, index: number) => (
              <FieldMappingTableRow
                sources={sourceOptions}
                key={row.key}
                id={row.key}
                customField={row.key}
                type={row.type}
                isLastRow={index === fields.length - 1}
              />
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};
