import { useReactFlow } from 'reactflow';

import { GraphControlButton } from './GraphControlButton';

type GraphControlsProps = {
  showFitView?: boolean;
  showZoom?: boolean;
};

export const GraphControls = ({
  showFitView = true,
  showZoom = true,
}: GraphControlsProps) => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();

  return (
    <div className="absolute bottom-0 right-0 z-10 flex items-center gap-4 p-6">
      {showFitView && (
        <GraphControlButton
          onClick={fitView}
          iconName="Fullscreen"
          label="Fit View"
        />
      )}
      {showZoom && (
        <>
          <GraphControlButton
            onClick={zoomOut}
            iconName="Minus"
            label="Zoom Out"
          />
          <GraphControlButton
            onClick={zoomIn}
            iconName="Plus"
            label="Zoom In"
          />
        </>
      )}
    </div>
  );
};
