import { Process } from '@spektr/shared/types';
import {
  getChannelSettingsFromRootNode,
  shouldLoopHaveChannelSettings,
} from '@spektr/shared/utils';

export const areLoopChannelSettingsRequired = (process: Process | null) => {
  if (process?.type === 'loop' && shouldLoopHaveChannelSettings(process)) {
    const settings = getChannelSettingsFromRootNode(process);

    return !settings;
  }

  return false;
};
