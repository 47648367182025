import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

import { ParsedUboData } from '../../utils/computeOwnershipData';

import { RecordOwnershipUboTableHeader } from './RecordOwnershipUboTableHeader';

type RecordOwnershipUboTableProps = {
  uboList: ParsedUboData[];
};

const baseRowCellClasses = cn('px-4', 'border-b', 'text-sm font-medium');

export const RecordOwnershipUboTable = ({
  uboList,
}: RecordOwnershipUboTableProps) => {
  return (
    <Table className="border-separate">
      <RecordOwnershipUboTableHeader />
      <TableBody>
        {uboList.map((item, index) => {
          const classes = cn(
            baseRowCellClasses,
            index === uboList.length - 1 && 'border-0'
          );
          return (
            <TableRow key={`${item.name}-${index}`}>
              <TableCell className={classes}>{item.type}</TableCell>
              <TableCell className={classes}>{item.name}</TableCell>
              <TableCell className={classes}>{item.address}</TableCell>
              <TableCell className={classes}>{item.percentage}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
