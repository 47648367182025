import { assertUnreachable } from '@spektr/shared/utils';

import { useIncompleteRuleGroup } from '../providers';

import { RightOperandTextInput } from './rightOperandTextInput';
import { RightOperandDatePickerInput } from './rightOperandDatePickerInput';
import { RightOperandRangeInput } from './rightOperandRangeInput';

export const RightOperandInput = (props: { ruleId: string }) => {
  const { getRightOperandInputType, getPredicate } = useIncompleteRuleGroup();

  const predicate = getPredicate(props.ruleId);

  if (!predicate) return null;

  const type = getRightOperandInputType(props.ruleId, predicate.operator);

  switch (type) {
    case 'text':
      return <RightOperandTextInput {...props} />;
    case 'date-picker':
      return <RightOperandDatePickerInput {...props} />;
    case 'range':
      return <RightOperandRangeInput {...props} />;
    case 'none':
      return <div className="bg-transparent" />;
    default:
      assertUnreachable(type);
  }
};
