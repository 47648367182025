import { CommentMessageBlock } from '@spektr/shared/validators';

export const isLongComment = (comment: CommentMessageBlock[]) => {
  const flatComment = comment.flatMap((p) => p.children[0].text);
  const commentLength = flatComment.toString().length;

  if (comment.length > 6 || commentLength > 350) {
    return true;
  }

  return false;
};
