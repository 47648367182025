import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { cn } from '@spektr/client/utils';

import {
  connectionDataSetByIdQuery,
  getActionById,
  getActionHitById,
  getExecutionById,
  getTeamMembersQuery,
} from '@spektr/client/services';
import { HitDecisionStatus } from '@spektr/shared/validators';

import { useTranslation } from 'react-i18next';

import {
  computeHitCardDetails,
  getActionTypeBasedOnService,
  getIdentifierValue,
} from '../../utils';

import { useActionAndHitIdParam } from '../../hooks/useActionAndHitIdParam';
import { useUpdateHit } from '../../hooks/useUpdateHit';
import { useHitResolutionReason } from '../../hooks/useHitResolutionReason';
import { useRenderDetailsTable } from '../../hooks/useRenderDetailsTable';

import { HitStatus } from '../../components/HitStatus';
import { BasicDetailsTable } from '../../components/BasicDetailsTable';
import { HitResolutionButtons } from '../../components/HitResolutionButtons';
import { HitResolutionComment } from '../../components/HitResolutionComment';
import { HitDetailsTabNavigation } from '../../components/HitDetailsTabNavigation';
import { useSpektrAISearch } from '../../hooks/useSpektrAISearch';
import { formatSpektrAIDetails } from '../../utils/computeSpektrAIDetails';

export const HitPageContent = () => {
  const { t } = useTranslation();
  const { actionId, hitId } = useActionAndHitIdParam();

  const { data: action } = useSuspenseQuery(getActionById(actionId));
  const { data: hit } = useSuspenseQuery(getActionHitById(actionId, hitId));
  const { data: dataset } = useSuspenseQuery(
    connectionDataSetByIdQuery(action.datasetId)
  );
  const { data: execution } = useSuspenseQuery(
    getExecutionById(action.contextId)
  );
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());

  const updateSpektrAIMutation = useSpektrAISearch(
    actionId,
    action.datasetId,
    action.spektrId
  );
  const updateHitMutation = useUpdateHit(
    actionId,
    action.datasetId,
    action.spektrId
  );
  const { reason, updateReason, user, resolvedAt } = useHitResolutionReason(
    hit.decision,
    users
  );
  const { renderColumn, renderValue } = useRenderDetailsTable();
  const actionType = getActionTypeBasedOnService(hit.vendorDetails.service);
  const subject = getIdentifierValue(execution.data, dataset);

  const handleSpektrAISearch = async (hitId: string) => {
    return updateSpektrAIMutation.mutate({
      hitId: hitId,
    });
  };
  const handleUpdateHit = async (decision: HitDecisionStatus) => {
    if (decision === 'pending') return;

    return updateHitMutation.mutateAsync({
      actionId: actionId,
      hitId: hitId,
      status: decision,
      reason,
    });
  };

  const simplifiedData = useMemo(() => {
    const item = action.hits.find((hit) => hit.id === hitId);
    return item ? computeHitCardDetails(item.vendorDetails, item) : {};
  }, [action.hits, hitId]);

  return (
    <div
      className={cn(
        'flex flex-col gap-6',
        'rounded-md p-6',
        'mb-6 mr-6',
        'bg-color-bg-primary'
      )}
    >
      <div className={cn('flex flex-row')}>
        <p className="text-color-text-subtext text-base font-medium">
          Hit on <strong className="text-color-text-primary">{subject}</strong>
        </p>
        <div className={cn('flex flex-row gap-4', 'ml-auto')}>
          <HitStatus hitType={actionType} decision={hit.decision.status} />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {simplifiedData && (
          <BasicDetailsTable
            data={simplifiedData}
            columnClassName="w-[320px]"
            renderColumn={renderColumn}
            renderValue={renderValue}
          />
        )}
        {hit.spektrAIDetails && hit.spektrAIDetails.status === 'enabled' && (
          <div className="flex flex-col gap-4">
            <p className="text-color-text-primary text-base font-medium">
              SpektrAI details
            </p>
            <BasicDetailsTable
              data={formatSpektrAIDetails(hit.spektrAIDetails, t, subject)}
              columnClassName="w-[320px]"
              renderValue={renderValue}
            />
          </div>
        )}
        <div className={cn('flex flex-row flex-wrap gap-4 lg:flex-nowrap')}>
          <HitResolutionComment
            user={user}
            resolvedAt={resolvedAt}
            value={reason}
            onUpdateComment={updateReason}
          />
          <HitResolutionButtons
            id={hitId}
            hitType={actionType}
            isDisabled={
              updateHitMutation.isPending || hit.decision.status !== 'pending'
            }
            spektrAI={{
              details: hit.spektrAIDetails,
              isPending: updateSpektrAIMutation.isPending,
              performSearch: handleSpektrAISearch,
            }}
            onFalsePositiveClick={() => handleUpdateHit('false-positive')}
            onTruePositiveClick={() => handleUpdateHit('true-positive')}
          />
        </div>
      </div>
      <HitDetailsTabNavigation />
      <Outlet />
    </div>
  );
};
