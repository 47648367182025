import { z } from 'zod';

import { deliveryMethods, maxRateLimitUnits } from '@spektr/shared/validators';

import { validateRateLimit } from './utils/rateLimit';

export const userSettingsValidationSchema = z
  .object({
    numberOfCalls: z.string(),
    interval: z.enum(maxRateLimitUnits),
    deliveryMethod: z.enum(deliveryMethods),
    apiKey: z.string().optional(),
    httpsEndpoint: z.string().optional(),
    slackToken: z.string().optional(),
    slackChannelId: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    const httpsRegExpTest = new RegExp('^https://', 'i').test(
      data.httpsEndpoint ?? ''
    );

    const slackTokenRegExpTest = new RegExp('^xoxb-', 'i').test(
      data.slackToken ?? ''
    );

    if (data.deliveryMethod === 'httpsPush' && !httpsRegExpTest) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Endpoint should start with "https://"',
        path: ['httpsEndpoint'],
      });
    } else if (data.deliveryMethod === 'slack' && !slackTokenRegExpTest) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Slack token should start with "xoxb-"',
        path: ['slackToken'],
      });
    }
    const rateLimitError = validateRateLimit(
      Number(data.numberOfCalls),
      data.interval
    );

    if (rateLimitError) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: rateLimitError,
        path: ['numberOfCalls'],
      });
    }
  });

export type UserSettings = z.infer<typeof userSettingsValidationSchema> & {
  spektrIps?: string[];
};
