import { cn } from '@spektr/client/utils';
import { TableCell } from '@spektr/client/components';

type SettingsTableCellProps = {
  children?: React.ReactNode;
  className?: string;
};

export const SettingsTableCell = ({
  children,
  className,
}: SettingsTableCellProps) => {
  return (
    <TableCell className={cn('border-t p-5', className)}>{children}</TableCell>
  );
};
