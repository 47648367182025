import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { RBAC } from '@spektr/shared/rbac';
import {
  processDetailsParamsSchema,
  loopDetailsUrl,
  getChannelSettingsFromRootNode,
  getRootNode,
} from '@spektr/shared/utils';

import {
  getAllowedSpektrFieldsQuery,
  getApiKeysQuery,
  getLoopByIdQuery,
  getProcessesQuery,
} from '@spektr/client/services';

import {
  useDialogClose,
  useParsedParams,
  useUpdateLoop,
} from '@spektr/shared/hooks';

import { Dialog, DialogContent } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { type ProcessSource, type NodeUpdateInput } from '@spektr/shared/types';
import { type ChannelSettings } from '@spektr/shared/validators';

import { SourceSettings } from '../processes/SourceSettings';

export const UpdateLoopSourceDialog = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissionsContext();
  const [open, startExitAnimation] = useDialogClose();
  const { processId } = useParsedParams(processDetailsParamsSchema);

  const { data: apiKeys } = useSuspenseQuery(getApiKeysQuery());
  const { data: processes } = useSuspenseQuery(
    getProcessesQuery({
      types: ['risk', 'score', 'monitoring', 'onboarding'],
    })
  );
  const { data: loop } = useSuspenseQuery(getLoopByIdQuery(processId));
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, loop.rootId ?? '')
  );

  const channelSettings = useMemo(() => {
    return getChannelSettingsFromRootNode(loop)?.[0];
  }, [loop]);

  const updateLoopMutation = useUpdateLoop(loop, () =>
    navigate(loopDetailsUrl(processId))
  );

  const handleClose = () => {
    navigate(loopDetailsUrl(processId));
  };

  const handleUpdateLoop = (
    sources: ProcessSource[],
    settings: ChannelSettings
  ) => {
    const rootNode = loop ? getRootNode(loop) : undefined;
    const result = {
      nodeType: rootNode?.nodeType,
      sources,
      channelSettings: [settings],
    } as NodeUpdateInput;

    updateLoopMutation.mutate(result);
  };

  return (
    <Dialog open={open} modal={false}>
      <DialogContent
        modal={false}
        className="bg-color-bg-dialog-default absolute flex flex-col"
        requestStartExitAnimation={startExitAnimation}
        onEndExitAnimation={handleClose}
        onEscapeKeyDown={startExitAnimation}
        data-cy="add-new-process-dialog"
      >
        <SourceSettings
          apiKeys={apiKeys}
          channelSettings={channelSettings}
          isUpdatePending={updateLoopMutation.isPending}
          hasUpdatePermission={hasPermission(RBAC.ACTIONS.PROCESS.UPDATE)}
          nodeTitle="Loop settings"
          nodeIcon="Cog"
          process={loop}
          processes={processes}
          spektrFields={spektrFields}
          onClickUpdate={handleUpdateLoop}
        />
      </DialogContent>
    </Dialog>
  );
};
