import { z } from 'zod';

import { tableFieldsSchema } from './tableFieldsSchema';

export const formValuesSchema = z.strictObject({
  fields: tableFieldsSchema,
  fieldId: z.string().optional(),
});

export type FormValues = z.infer<typeof formValuesSchema>;
