export const SpektrDataflowIcon = ({
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M3.33317 15V14.8333C3.33317 13.4332 3.33317 12.7331 3.60565 12.1983C3.84534 11.7279 4.22779 11.3455 4.69819 11.1058C5.23297 10.8333 5.93304 10.8333 7.33317 10.8333H12.6665C14.0666 10.8333 14.7667 10.8333 15.3015 11.1058C15.7719 11.3455 16.1543 11.7279 16.394 12.1983C16.6665 12.7331 16.6665 13.4332 16.6665 14.8333V15M3.33317 15C2.4127 15 1.6665 15.7462 1.6665 16.6666C1.6665 17.5871 2.4127 18.3333 3.33317 18.3333C4.25365 18.3333 4.99984 17.5871 4.99984 16.6666C4.99984 15.7462 4.25365 15 3.33317 15ZM16.6665 15C15.746 15 14.9998 15.7462 14.9998 16.6666C14.9998 17.5871 15.746 18.3333 16.6665 18.3333C17.587 18.3333 18.3332 17.5871 18.3332 16.6666C18.3332 15.7462 17.587 15 16.6665 15ZM9.99984 15C9.07936 15 8.33317 15.7462 8.33317 16.6666C8.33317 17.5871 9.07936 18.3333 9.99984 18.3333C10.9203 18.3333 11.6665 17.5871 11.6665 16.6666C11.6665 15.7462 10.9203 15 9.99984 15ZM9.99984 15V6.66663M4.99984 6.66663H14.9998C15.7764 6.66663 16.1647 6.66663 16.471 6.53976C16.8794 6.3706 17.2038 6.04615 17.373 5.63777C17.4998 5.33148 17.4998 4.9432 17.4998 4.16663C17.4998 3.39006 17.4998 3.00177 17.373 2.69549C17.2038 2.28711 16.8794 1.96265 16.471 1.79349C16.1647 1.66663 15.7764 1.66663 14.9998 1.66663H4.99984C4.22327 1.66663 3.83498 1.66663 3.5287 1.79349C3.12032 1.96265 2.79586 2.28711 2.6267 2.69549C2.49984 3.00177 2.49984 3.39006 2.49984 4.16663C2.49984 4.9432 2.49984 5.33148 2.6267 5.63777C2.79586 6.04615 3.12032 6.3706 3.5287 6.53976C3.83498 6.66663 4.22327 6.66663 4.99984 6.66663Z"
        stroke="inherit"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
