import { Input } from '@spektr/client/components';

import { useIncompleteRuleGroup } from '../providers';

import { blurOnEnter } from '../../utils';

export const RightOperandTextInput = (props: { ruleId: string }) => {
  const { setPredicate, getPredicate } = useIncompleteRuleGroup();
  const rule = getPredicate(props.ruleId);

  if (!rule) return null;

  const disabled = !rule.left || !rule.operator;

  return (
    <Input
      data-testid={`right-operand-text-input-${props.ruleId}`}
      type="text"
      dimension="small"
      value={
        rule.right !== null && rule.right !== undefined
          ? String(rule.right)
          : ''
      }
      placeholder={!disabled ? 'write something' : ''}
      className="h-7"
      disabled={disabled}
      onKeyDown={blurOnEnter}
      onChange={(event) =>
        setPredicate(props.ruleId, {
          right: event.target.value,
        })
      }
    />
  );
};
