import { createContext, useContext, useEffect, useState } from 'react';

export type Theme = 'dark' | 'light';

export const DEFAULT_THEME = 'dark';

type ThemeProviderContextType = {
  theme: Theme;
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeProviderContextType>(
  {} as ThemeProviderContextType
);

export const useTheme = () => {
  return useContext(ThemeContext);
};

export type ThemeProviderProps = {
  defaultTheme?: Theme;
  children: React.ReactNode;
};

export const ThemeProvider = ({
  defaultTheme = DEFAULT_THEME,
  children,
}: ThemeProviderProps) => {
  const [theme, setPlatformTheme] = useState(
    (localStorage.getItem('spektr-theme') as Theme) ?? defaultTheme
  );

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleThemeChange = () => {
    const currentTheme =
      document.documentElement.getAttribute('data-theme') ?? 'dark';

    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';

    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('spektr-theme', newTheme);
    setPlatformTheme(newTheme);
  };

  const themeValue = {
    theme,
    toggleTheme: toggleThemeChange,
  };

  return (
    <ThemeContext.Provider value={themeValue}>{children}</ThemeContext.Provider>
  );
};
