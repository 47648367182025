import { FormProvider, UseFormReturn } from 'react-hook-form';

import { DialogDescription } from '@spektr/client/components';
import { LoopChannelSettings } from '@spektr/model-builder/components';

import { type ApiKeySchema } from '@spektr/shared/validators';
import { type SpektrField } from '@spektr/shared/types';

import { TabView } from '../../components/TabView';

import { type SettingsTab } from '../../types/SettingsTab';
import { type ChannelSettingsFormValues } from '../../types/ChannelSettingsFormValues';

export type ChannelTabViewProps = {
  activeTab: SettingsTab;
  apiKeys: ApiKeySchema[];
  formInstance: UseFormReturn<ChannelSettingsFormValues>;
  spektrFields: SpektrField[];
};

export const ChannelTabView = ({
  activeTab,
  apiKeys,
  formInstance,
  spektrFields,
}: ChannelTabViewProps) => {
  return (
    <TabView activeTab={activeTab} name="channel">
      <DialogDescription className="my-4">
        Choose how your customers receive a notification to this loop. Email or
        SMS will send the custom message you've define here. The API channel
        will use the export defined in Settings.
      </DialogDescription>

      <FormProvider {...formInstance}>
        <LoopChannelSettings spektrFields={spektrFields} apiKeys={apiKeys} />
      </FormProvider>
    </TabView>
  );
};
