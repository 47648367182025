import { TriangleAlert } from 'lucide-react';

import {
  ComboboxGroup,
  ComboboxItem,
  Tooltip,
} from '@spektr/client/components';
import { SpektrFieldTypedKey } from '@spektr/shared/validators';

type CustomFieldsProps = {
  fields: SpektrFieldTypedKey[];
};

export const CustomFieldsComboboxGroup = ({ fields }: CustomFieldsProps) => {
  return (
    <ComboboxGroup
      label={
        <span className="flex items-center gap-2">
          Custom fields
          <Tooltip
            content="Any created custom fields will need to be mapped to a source."
            tooltipTriggerProps={{
              asChild: true,
            }}
          >
            <TriangleAlert className="h-3 w-3" />
          </Tooltip>
        </span>
      }
    >
      <div className="max-h-52 w-full overflow-y-auto px-2 py-1">
        {fields.length > 0 ? (
          fields.map((field) => (
            <ComboboxItem
              key={field.key}
              value={field.key}
              className="group flex items-center gap-2"
            >
              {field.key}
            </ComboboxItem>
          ))
        ) : (
          <li className="text-color-text-subtext p-2 text-xs">
            No custom fields found.
          </li>
        )}
      </div>
    </ComboboxGroup>
  );
};
