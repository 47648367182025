import { Editor, Range, Transforms } from 'slate';

import { type SlateMentionElement } from '@spektr/shared/slate-types';

export const insertMention = (editor: Editor, entity: string) => {
  const mention: SlateMentionElement = {
    type: 'mention',
    entity,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export const selectMention = (
  editor: Editor,
  chars: string[],
  index: number,
  target: Range | null
) => {
  Transforms.select(editor, target as Range);
  if (chars[index]) {
    insertMention(editor, chars[index] as string);
  } else {
    throw new Error('No mention found');
  }
  return null;
};

export const getMatchesForMention = (editor: Editor) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection);
    const wordBefore = Editor.before(editor, start, { unit: 'word' });
    const before = wordBefore && Editor.before(editor, wordBefore);
    const beforeRange = before && Editor.range(editor, before, start);
    const beforeText = beforeRange && Editor.string(editor, beforeRange);
    const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
    const after = Editor.after(editor, start);
    const afterRange = Editor.range(editor, start, after);
    const afterText = Editor.string(editor, afterRange);
    const afterMatch = afterText.match(/^(\s|$)/);

    if (beforeMatch && afterMatch) {
      return { target: beforeRange, substring: beforeMatch[1] };
    }
  }

  return null;
};
