import { RiskMatrix } from '@spektr/shared/validators';

import { ParseResultData, RiskMatrixData } from '../types';

export const isValidRiskMatrixCsv = (data: ParseResultData): boolean => {
  if (data.length === 0) return false;

  let isValid = true;

  for (let i = 0; i < data.length; i++) {
    const record = data[i];
    const headers = Object.keys(record);

    if (headers.length < 2 || headers.length > 3) {
      isValid = false;
      break;
    }

    const indexOfScore = headers.findIndex(
      (header) => header.toLowerCase() === 'score'
    );

    if (indexOfScore === -1) {
      isValid = false;
      break;
    }
  }

  return isValid;
};

export const extractData = (data: RiskMatrix): RiskMatrixData => {
  const indexOfScore = data.columns.findIndex(
    (column) => column.toLowerCase() === 'score'
  );
  const scores: string[] = [];
  const newColumns = [...data.columns];
  const values = data.rows.map((row) => {
    const newRow = [...row];
    scores.push(newRow[indexOfScore]);
    newRow.splice(indexOfScore, 1);
    return newRow;
  });
  newColumns.splice(indexOfScore, 1);

  return {
    headers: newColumns,
    values,
    scores,
  };
};
