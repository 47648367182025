// TODO: @Alex - this might need to be moved to a different file
// depending on the api implementation

import { z } from 'zod';

import { baseChildSchemaDto, messageBlockSchemaDto } from './comments';

export const mentionChildSchemaDto = z.object({
  type: z.literal('mention'),
  entity: z.string(),
  children: z.array(baseChildSchemaDto).optional(),
});

export const messageBlockWithMentions = messageBlockSchemaDto.extend({
  children: z.array(z.union([baseChildSchemaDto, mentionChildSchemaDto])),
});

export const emailSettingsSchema = z.object({
  type: z.literal('email'),
  mapping: z.record(z.literal('email'), z.string()),
  messageBody: z.array(messageBlockWithMentions),
});

export const smsSettingsSchema = z.object({
  type: z.literal('sms'),
  mapping: z.record(z.literal('sms'), z.string()),
  messageBody: z.array(messageBlockWithMentions),
});

export const apiSettingsSchema = z.object({
  type: z.literal('api'),
  apiKey: z.string(),
});

export const channelSettingsSchema = z.union([
  emailSettingsSchema,
  smsSettingsSchema,
  apiSettingsSchema,
]);

export type EmailSettings = z.infer<typeof emailSettingsSchema>;
export type SmsSettings = z.infer<typeof smsSettingsSchema>;
export type ApiSettings = z.infer<typeof apiSettingsSchema>;

export type ChannelSettings = z.infer<typeof channelSettingsSchema>;
