import { CONNECTION_HUB_SERVICE_CATALOG_FULL_URL } from '@spektr/shared/utils';
import { useNavigate } from 'react-router-dom';

export function useGoToServiceCatalog() {
  const navigate = useNavigate();
  const state = sessionStorage.getItem('serviceCatalogStateFilter');

  return () =>
    navigate(
      CONNECTION_HUB_SERVICE_CATALOG_FULL_URL + '?state=' + (state || 'all')
    );
}
