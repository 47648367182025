import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '@spektr/client/utils';

const dataStateActive = cn(
  'data-[state=active]:bg-color-bg-tab--active',
  'data-[state=active]:text-color-text-tab--active'
);
const dataStateInactive = cn(
  'data-[state=inactive]:bg-color-bg-tab--inactive',
  'data-[state=inactive]:text-color-text-tab--inactive'
);

const disabled = cn('disabled:cursor-not-allowed', 'disabled:opacity-50');
const focus = cn(
  'focus-visible:ring-ring',
  'focus-visible:outline-none',
  'focus-visible:ring-2',
  'focus-visible:ring-offset-2'
);

export const TabsTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex items-center justify-center',
      'h-full w-full px-3 py-1.5',
      'rounded',
      'bg-color-bg-tab-trigger',
      'text-color-text-tab-trigger whitespace-nowrap text-sm font-medium',
      'transition-colors',
      dataStateActive,
      dataStateInactive,
      disabled,
      focus,
      className
    )}
    {...props}
  />
));
