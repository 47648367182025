import { ChangeEvent } from 'react';

import { Button, Input } from '@spektr/client/components';

export type LoopNameProps = {
  actionLabel: string;
  value: string | undefined;
  onChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
};

export const LoopName = ({
  actionLabel,
  value,
  onChangeName,
  onClick,
}: LoopNameProps) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <label
          htmlFor="new_loop_name"
          className="text-color-text-primary text-xs"
        >
          Let's start by adding a name for your new loop
        </label>
        <Input
          id="new_loop_name"
          placeholder="Input loop name"
          value={value}
          onChange={onChangeName}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onClick();
            }
          }}
        />
      </div>
      <Button disabled={!value} color="red" fullWidth onClick={onClick}>
        {actionLabel}
      </Button>
    </div>
  );
};
