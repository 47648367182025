import { z } from 'zod';

import { objectIdSchema } from '../common';
import { PaginationFields } from '../pagination';
import { SpektrData } from '../spektrfield';
import { spektrAIDetailsSchema } from '../spektrAI';

import {
  actionFormPageHitDataSchema,
  bodaccPageHitDataSchema,
  bodaccSimpleHitDataSchema,
  caPageHitDataDtoSchema,
  caSimpleHitDataSchema,
  ocPageHitDataSchema,
  ocSimpleHitDataSchema,
  vendorBaseSchema,
} from './data';

export const hitDataSchema = z.record(z.string(), z.unknown());

export const hitDecisionStatus = [
  'pending',
  'true-positive',
  'false-positive',
] as const;

export const hitDecisionStatusSchema = z.enum(hitDecisionStatus);
export type HitDecisionStatus = (typeof hitDecisionStatus)[number];

const baseResolvedDecision = z.object({
  userId: z.string(),
  createdAt: z.number(),
});
const pendingDecision = z.object({
  status: z.literal('pending'),
});

const resolvedTrueDecisionBaseSchema = z.object({
  status: z.literal('true-positive'),
  reason: z.string().optional(),
});
const resolvedFalseDecisionBaseSchema = z.object({
  status: z.literal('false-positive'),
  reason: z.string().optional(),
});
export const resolvedDecisionBaseSchema = z.union([
  resolvedTrueDecisionBaseSchema,
  resolvedFalseDecisionBaseSchema,
]);
export const resolvedDecisionBaseSchemaWithId = z.object({
  id: objectIdSchema,
  decision: resolvedDecisionBaseSchema,
});

export type HitResolvedDecisionBase = z.infer<
  typeof resolvedDecisionBaseSchema
>;

export const resolvedTrueDecision = baseResolvedDecision.merge(
  resolvedTrueDecisionBaseSchema
);
export const resolvedFalseDecision = baseResolvedDecision.merge(
  resolvedFalseDecisionBaseSchema
);
export const resolvedDecision = z.union([
  resolvedTrueDecision,
  resolvedFalseDecision,
]);

export const resolvedDecisionWithId = z.object({
  id: objectIdSchema,
  decision: resolvedDecision,
});
export const resolvedDecisions = z.array(resolvedDecisionWithId);

export const decisionSchema = z.discriminatedUnion('status', [
  pendingDecision,
  resolvedTrueDecision,
  resolvedFalseDecision,
]);
export type HitDecision = z.infer<typeof decisionSchema>;
export type HitResolvedDecision = z.infer<typeof resolvedDecision>;

export const hitResolvedDecisionsWithIdSchema = z.array(resolvedDecisionWithId);
export type HitResolvedDecisions = z.infer<
  typeof hitResolvedDecisionsWithIdSchema
>;

export const anyHitDataContentSchema = z.object({
  ocSimpleHit: ocSimpleHitDataSchema.optional(),
  ocPageHit: ocPageHitDataSchema.optional(),
  caSimpleHit: caSimpleHitDataSchema.optional(),
  caPageHit: caPageHitDataDtoSchema.optional(),
  bodaccSimpleHit: bodaccSimpleHitDataSchema.optional(),
  bodaccPageHit: bodaccPageHitDataSchema.optional(),
  actionFormPageHit: actionFormPageHitDataSchema.optional(),
});
export type AnyHitDataContent = z.infer<typeof anyHitDataContentSchema>;
export const anyHitDataSchema = z.object({
  data: anyHitDataContentSchema.optional(),
});
export type AnyHitData = z.infer<typeof anyHitDataSchema>;

export const hitSchemaBase = anyHitDataSchema.merge(
  z.object({
    decision: decisionSchema.default({ status: 'pending' }),
  })
);

export const vendorHitSchemaBaseWithId = hitSchemaBase.merge(
  z.object({
    id: objectIdSchema,
    processId: objectIdSchema,
    nodeId: objectIdSchema,
    vendorDetails: vendorBaseSchema,
    commentCount: z.number().optional(),
    spektrAIDetails: spektrAIDetailsSchema.optional(),
  })
);
export type VendorHitSchemaBaseWithId = z.infer<
  typeof vendorHitSchemaBaseWithId
>;

export const hitSchemaBaseWithId = hitSchemaBase.merge(
  z.object({
    id: objectIdSchema,
  })
);
export type HitBaseWithId = z.infer<typeof hitSchemaBaseWithId>;

export type HitWithVendorDetails = VendorHitSchemaBaseWithId;

export const hitSchemaExtended = hitSchemaBase.merge(
  z.object({
    nodeType: z.string(),
    nodeId: objectIdSchema,
    processId: objectIdSchema,
    spektrId: objectIdSchema,
    datasetId: objectIdSchema,
    spektrAIDetails: spektrAIDetailsSchema.optional(),
    data: z.unknown(),
  })
);

export const hitSchema = hitSchemaExtended.merge(
  z.strictObject({
    id: objectIdSchema.optional(),
  })
);

export type ActionHit = z.infer<typeof hitSchema>;
export type HitData = z.infer<typeof hitDataSchema>;

export const hitListSchema = z.array(hitSchema);
export type ActionHitList = z.infer<typeof hitListSchema>;

export const hitSchemaResponse = hitSchemaExtended.merge(
  z.strictObject({
    id: objectIdSchema,
    executionContextData: SpektrData.optional(),
    workspaceId: z.string().optional(),
    field: z.string().optional(),
    fieldType: z.string().optional(),
    oldValue: z.string().optional(),
    newValue: z.string().optional(),
    commentCount: z.number().optional(),
    spektrAIDetails: spektrAIDetailsSchema.optional(),
  })
);
export type ActionHitResponse = z.infer<typeof hitSchemaResponse>;
export const hitListSchemaResponse = z.array(hitSchemaResponse);
export type ActionHitListResponse = z.infer<typeof hitListSchemaResponse>;
/**
 * Response schemas
 */
export const getHitsResponseSchema = PaginationFields.merge(
  z.strictObject({
    docs: hitListSchema,
  })
);
export type GetActionHitsResponse = z.infer<typeof getHitsResponseSchema>;

export const getActionHitByIdResponseSchema = hitSchema.merge(
  z.object({
    vendorDetails: vendorBaseSchema,
  })
);
export type GetActionHitByIdResponse = z.infer<
  typeof getActionHitByIdResponseSchema
>;
