export const NODE = {
  WIDTH: 300,
  HEIGHT: 80,
  OUTCOME: {
    WIDTH: 90,
    HEIGHT: 36,
  },
} as const;

export const EDGE = {
  WIDTH: 130,
  CENTER: {
    X: 25,
  },
  LABEL: {
    ESTIMATED_PX_PER_CHAR: 4.9, // we offset the edge label by this multiplier per character in the _longest_ label by graph depth to make room for the labels
    OFFSET_X: 25,
  },
} as const;
