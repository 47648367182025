import { ZodiosEndpointDefinitions, ZodiosInstance } from '@zodios/core';

export function createBaseApiClient<T extends ZodiosEndpointDefinitions>(
  clientConstructor: (config: ApiConfig) => ZodiosInstance<T>
) {
  let baseUrl: string;
  let clientId: string;

  let apiInstance: ZodiosInstance<T>;

  return {
    setConfig(url: string, client: string) {
      baseUrl = url;
      clientId = client;
    },

    getClient() {
      if (!apiInstance) {
        apiInstance = clientConstructor({ baseUrl, clientId });
      }

      return apiInstance;
    },
  };
}

export type ApiConfig = {
  baseUrl: string;
  clientId: string;
};
