//TODO:  [ST-1237] Label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and retrieved by the field key
import { SpektrFieldWithInfo } from '@spektr/shared/types';

const returningProcessFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> = [
  {
    key: 'lastReturningProcess.score',
    type: 'number',
    source: 'returningProcess',
    label: 'Last returning process score',
    help: 'The score that was returned by the last returning process',
  },
];

export const returningProcessSpektrFields = [...returningProcessFields];

const monitoringDatasetFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> = [
  {
    key: 'lastMonitoringDataset.changed',
    type: 'boolean',
    source: 'monitoringDataset',
    label: 'Last monitoring dataset changed value',
    help: 'Whether the last monitoring dataset node signaled a change or not',
  },
];

export const monitoringDatasetSpektrFields = [...monitoringDatasetFields];
