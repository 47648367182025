import { HTMLAttributes } from 'react';

export const CreditSafeIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="110"
    height="20"
    viewBox="0 0 110 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_8423_1602)">
      <path
        d="M100.773 4.65067C100.773 4.65067 101.437 0.025467 105.398 0H110.002C110.002 0 109.093 4.65067 105.318 4.65067H100.773Z"
        fill="white"
      />
      <path
        d="M95.9363 10.1535C95.9363 10.1535 96.6004 5.52834 100.56 5.50287H105.163C105.163 5.50287 104.254 10.1535 100.479 10.1535H95.9363Z"
        fill="white"
      />
      <path
        d="M7.71064 17.202C8.00841 17.202 8.39629 17.4763 8.39629 17.8642C8.39629 18.8711 6.86435 19.3511 5.23838 19.3511C1.80816 19.353 0 16.1951 0 13.1293C0 10.0634 1.99035 6.90552 5.44603 6.90552C7.00148 6.90552 8.46681 7.50105 8.46681 8.18671C8.46681 8.71172 8.19255 9.10156 7.55196 9.10156C7.11706 9.10156 6.72722 8.41591 5.24033 8.41591C2.90716 8.41591 1.78465 11.0469 1.78465 12.9902C1.78465 15.5526 3.08935 17.8407 5.24033 17.8407C6.72526 17.8426 7.25223 17.202 7.71064 17.202Z"
        fill="white"
      />
      <path
        d="M12.6512 18.2991C12.6512 18.9847 12.3534 19.3511 11.8264 19.3511C11.3014 19.3511 11.0037 18.9847 11.0037 18.2991V7.95946C11.0037 7.2738 11.3014 6.90747 11.8264 6.90747C12.3534 6.90747 12.6512 7.2738 12.6512 7.95946V9.10351H12.6982C13.2918 7.75376 14.3457 6.90747 15.3742 6.90747C16.1519 6.90747 16.4732 7.34237 16.4732 7.79882C16.4732 8.25722 16.1519 8.50798 15.3507 8.69212C14.4358 8.89782 12.6512 9.67554 12.6512 12.375V18.2991Z"
        fill="white"
      />
      <path
        d="M26.47 12.4887C26.4015 10.4513 25.2574 8.27878 23.0379 8.27878C20.8183 8.27878 19.6743 10.4513 19.6057 12.4887H26.47ZM19.6077 13.8619C19.6762 15.9914 21.118 17.9798 23.222 17.9798C25.8295 17.9798 26.5171 16.103 27.2948 16.103C27.7532 16.103 28.0725 16.2637 28.0725 16.9043C28.0725 17.6369 26.1742 19.353 23.3356 19.353C19.447 19.353 17.821 16.1971 17.821 13.1293C17.821 10.0634 19.6292 6.90552 23.0614 6.90552C26.4936 6.90552 28.2547 9.78721 28.2547 12.855C28.2547 13.2899 28.1176 13.8619 27.1322 13.8619H19.6077Z"
        fill="white"
      />
      <path
        d="M35.3679 17.9797C37.9303 17.9797 38.8001 15.3488 38.8001 13.1292C38.8001 10.9097 37.9303 8.27873 35.3679 8.27873C32.8741 8.27873 31.9122 10.9097 31.9122 13.1292C31.9142 15.3488 32.8055 17.9797 35.3679 17.9797ZM38.8001 2.60545C38.8001 1.9198 39.0978 1.55347 39.6228 1.55347C40.1498 1.55347 40.4476 1.9198 40.4476 2.60545V18.301C40.4476 18.9866 40.1498 19.353 39.6228 19.353C39.0978 19.353 38.8001 18.9866 38.8001 18.301V17.5468H38.7315C37.9988 18.7359 36.7862 19.3549 35.3679 19.3549C31.8672 19.3549 30.1295 16.5634 30.1295 13.1331C30.1295 10.0673 31.7986 6.90938 35.3228 6.90938C36.7412 6.90938 37.7931 7.50492 38.7315 8.71558H38.8001V2.60545Z"
        fill="white"
      />
      <path
        d="M45.1394 18.299C45.1394 18.9847 44.8416 19.351 44.3146 19.351C43.7877 19.351 43.4919 18.9847 43.4919 18.299V7.95747C43.4919 7.27182 43.7896 6.90548 44.3146 6.90548C44.8416 6.90548 45.1394 7.27182 45.1394 7.95747V18.299ZM44.3166 2.78766C44.9337 2.78766 45.4371 3.29112 45.4371 3.90821C45.4371 4.52529 44.9337 5.02876 44.3166 5.02876C43.6995 5.02876 43.196 4.52529 43.196 3.90821C43.196 3.29112 43.6995 2.78766 44.3166 2.78766Z"
        fill="white"
      />
      <path
        d="M50.4483 18.2991C50.4483 18.9847 50.1505 19.351 49.6235 19.351C49.0985 19.351 48.8008 18.9847 48.8008 18.2991V8.41589H47.7253C47.1748 8.41589 47.0161 8.0045 47.0161 7.72828C47.0161 7.45402 47.1748 7.04263 47.7253 7.04263H48.8008V4.36663C48.8008 3.65747 49.0985 3.29114 49.6235 3.29114C50.1505 3.29114 50.4483 3.65747 50.4483 4.36663V7.04459H51.5238C52.0723 7.04459 52.2329 7.45598 52.2329 7.73024C52.2329 8.00646 52.0723 8.41785 51.5238 8.41785H50.4483V18.2991Z"
        fill="white"
      />
      <path
        d="M58.3783 11.9382C57.0305 11.4171 55.8688 10.9685 55.8688 10.179C55.8688 9.19755 56.9051 8.8508 57.7925 8.8508C58.8719 8.8508 59.4048 9.2524 59.873 9.60698C60.2001 9.85381 60.5077 10.0869 60.9269 10.0869C61.7007 10.0869 62.0455 9.57172 62.0455 9.05846C62.0455 7.62055 59.5262 6.70374 57.7925 6.70374C55.6866 6.70374 53.4474 7.97905 53.4474 10.3396C53.4474 12.3182 55.4672 13.1567 57.2479 13.8992C58.6349 14.4751 59.9435 15.0197 59.9435 15.9679C59.9435 16.679 59.1383 17.4116 57.7905 17.4116C56.5133 17.4116 55.7591 16.8847 55.1518 16.4615C54.7384 16.1736 54.3819 15.9248 53.9705 15.9248C53.3945 15.9248 52.942 16.4165 52.942 17.0434C52.942 18.207 55.0303 19.5587 57.722 19.5587C60.0336 19.5587 62.3648 18.3343 62.3648 15.6015C62.3668 13.478 60.2471 12.6591 58.3783 11.9382Z"
        fill="white"
      />
      <path
        d="M69.7523 17.4096C67.4367 17.4096 66.6159 15.1039 66.6159 13.1292C66.6159 11.1545 67.4367 8.84879 69.7523 8.84879C72.0678 8.84879 72.8886 11.1565 72.8886 13.1292C72.8886 15.1039 72.0678 17.4096 69.7523 17.4096ZM74.0542 6.70172C73.4861 6.70172 72.9121 7.11115 72.9121 7.89084V8.10241C71.9463 7.15817 70.959 6.70368 69.7542 6.70368C66.1046 6.70368 64.1965 9.938 64.1965 13.1331C64.1965 16.3263 66.1046 19.5606 69.7542 19.5606C70.9943 19.5606 72.0678 19.0121 72.9121 18.0189V18.3715C72.9121 19.1512 73.4861 19.5606 74.0542 19.5606C74.6086 19.5606 75.1963 19.1434 75.1963 18.3715V7.88888C75.1963 7.11899 74.6067 6.70172 74.0542 6.70172Z"
        fill="white"
      />
      <path
        d="M82.2645 6.83886H81.2105V4.09234C81.2105 3.65157 81.3731 3.49485 81.8296 3.49485H82.333C83.4653 3.49485 83.6358 2.82291 83.6358 2.42131C83.6358 1.75917 82.9344 1.34778 82.288 1.34778H81.6689C79.9195 1.34778 78.7892 2.46049 78.7892 4.18246V6.83886H78.1701C77.41 6.83886 76.9595 7.24046 76.9595 7.9124C76.9595 8.58434 77.412 8.98593 78.1701 8.98593H78.7892V18.2755C78.7892 19.0533 79.2633 19.5567 79.9998 19.5567C80.7345 19.5567 81.2105 19.0533 81.2105 18.2755V8.98593H82.2645C83.0226 8.98593 83.4751 8.58434 83.4751 7.9124C83.4751 7.24046 83.0226 6.83886 82.2645 6.83886Z"
        fill="white"
      />
      <path
        d="M89.4383 8.84884C91.3503 8.84884 92.3591 10.5003 92.4375 12.1929H86.2118C86.2862 11.1703 86.7446 8.75089 89.4383 8.84884ZM93.7011 15.7622C93.37 15.7622 93.0859 15.9835 92.7274 16.2637C92.1084 16.7475 91.2601 17.4116 89.5813 17.4116C87.6282 17.4116 86.3215 16.0854 86.2294 14.1127H93.562C94.4788 14.1127 94.8882 13.7189 94.8882 12.8335C94.8882 9.86165 92.9801 6.70374 89.4441 6.70374C85.7201 6.70374 83.7709 9.93805 83.7709 13.1312C83.7709 16.0952 85.2852 19.5587 89.5578 19.5587C92.0771 19.5587 94.6355 17.9896 94.6355 16.8612C94.6375 16.1461 94.1556 15.7622 93.7011 15.7622Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8423_1602">
        <rect width="110" height="19.5567" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
