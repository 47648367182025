import { Skeleton, Tabbar } from '@spektr/client/components';

export const SkeletonRecordDetailsPageHeader = () => {
  return (
    <div className="flex flex-col gap-6">
      <Skeleton className="w-40" />

      <Tabbar>
        <Skeleton className="mr-4 w-28" />
        <Skeleton className="mr-4 w-28" />
        <Skeleton className="mr-4 w-28" />
        <Skeleton className="w-28" />
      </Tabbar>
    </div>
  );
};
