import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateNodeSchema } from '@spektr/shared/validators';

import {
  NodesApiClient,
  getProcessLinksByIdQueryKey,
  getProcessQueryKey,
  getProcessesQueryKey,
  getTopologyQueryKey,
} from '@spektr/client/services';

import {
  Process,
  NodeUpdateInput,
  isFilterNodeType,
} from '@spektr/shared/types';

export type UpdateNodeMutationParams = {
  node: NodeUpdateInput;
  nodeId: string;
};

export function useUpdateNode(processId: string, handleSuccess?: () => void) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ nodeId, node }: UpdateNodeMutationParams) => {
      const parsedBody = updateNodeSchema.safeParse(node);
      const currentProcess = queryClient.getQueryData<Process>(
        getProcessQueryKey(processId)
      );

      if (!parsedBody.success) {
        throw new Error('Invalid node body');
      }

      const response = await NodesApiClient.getClient().updateNodeById(
        parsedBody.data,
        {
          params: { processId, nodeId },
        }
      );

      if (node.nodeType === 'filter' && currentProcess) {
        const currentFilterNode = currentProcess.nodes.get(nodeId);
        if (currentFilterNode && isFilterNodeType(currentFilterNode)) {
          if (
            currentFilterNode.source?.type === 'process' &&
            currentFilterNode.source?.processId
          ) {
            queryClient.invalidateQueries({
              queryKey: getProcessLinksByIdQueryKey(
                currentFilterNode.source.processId
              ),
            });
          }
        }
      }

      return response;
    },
    onSuccess: async (_, variables) => {
      handleSuccess?.();

      if (
        variables.node.nodeType === 'router' ||
        (variables.node.nodeType === 'filter' &&
          variables.node.source.type === 'process')
      ) {
        await queryClient.invalidateQueries({
          queryKey: getProcessesQueryKey(),
        });
        await queryClient.resetQueries({
          queryKey: getTopologyQueryKey(),
        });
        return;
      }

      await queryClient.invalidateQueries({
        queryKey: getProcessQueryKey(processId),
      });
    },
  });

  return mutation;
}
