import { useContext, useMemo } from 'react';

import { getExistingSource } from '@spektr/shared/utils';

import { areLoopChannelSettingsRequired } from '../utils/areLoopChannelSettingsRequired';

import { ProcessScheduleContext } from './ProcessScheduleContext';

export const useProcessScheduleContext = () => {
  const { process } = useContext(ProcessScheduleContext);

  return useMemo(
    () => ({
      hasSource: process && !!getExistingSource(process),
      isRiskProcess:
        process && (process.type === 'risk' || process.type === 'score'),
      isLoopProcess: process && process.type === 'loop',
      isOnboardingProcess: process && process.type === 'onboarding',
      areLoopChannelSettingsRequired: areLoopChannelSettingsRequired(process),
    }),
    [process]
  );
};
