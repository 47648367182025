import { XCircle } from 'lucide-react';

type InsightsErrorDashboardProps = {
  message: string;
};

export const InsightsErrorDashboard = ({
  message,
}: InsightsErrorDashboardProps) => (
  <div className="flex h-full flex-col items-center justify-center">
    <div className="m-auto flex items-center justify-center gap-2">
      <XCircle className="text-color-red h-6 w-6" />
      <p className="text-color-red text-lg">{message}</p>
    </div>
  </div>
);
