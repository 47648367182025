import { RgbaColor } from 'react-colorful';

const matcher =
  /rgba?\(?\s*(-?\d*\.?\d+)(%)?[,\s]+(-?\d*\.?\d+)(%)?[,\s]+(-?\d*\.?\d+)(%)?,?\s*[/\s]*(-?\d*\.?\d+)?(%)?\s*\)?/i;

export function isRgbaString(value: string): boolean {
  return matcher.test(value);
}

export function toRgbaObject(rgbaString: string): RgbaColor {
  const match = matcher.exec(rgbaString);

  if (!match) return { r: 0, g: 0, b: 0, a: 1 };

  return {
    r: Number(match[1]) / (match[2] ? 100 / 255 : 1),
    g: Number(match[3]) / (match[4] ? 100 / 255 : 1),
    b: Number(match[5]) / (match[6] ? 100 / 255 : 1),
    a: match[7] === undefined ? 1 : Number(match[7]) / (match[8] ? 100 : 1),
  };
}

export function fromRgbaObject(rgba: RgbaColor): string {
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
}
