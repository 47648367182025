import { ReactNode, createContext, useContext, useState } from 'react';

import { updateReturningProcessNodeSchema } from '@spektr/shared/validators';

import { IncompleteReturningProcess } from '../tree-helpers';

export type IncompleteReturningProcessNodeContextType = {
  incomplete: IncompleteReturningProcess;
  returningProcessNode: ReturnType<
    (typeof updateReturningProcessNodeSchema)['safeParse']
  >;
  setTitle: (title: string) => void;
  setProcessId: (processId: string) => void;
};

export const useIncompleteReturningProcessNode = () =>
  useContext(IncompleteReturningProcessNodeContext);

const IncompleteReturningProcessNodeContext = createContext(
  {} as IncompleteReturningProcessNodeContextType
);

export type ReturningProcessNodeProviderProps = {
  initial: IncompleteReturningProcess;
};

export const ReturningProcessNodeProvider = ({
  initial,
  children,
}: ReturningProcessNodeProviderProps & { children: ReactNode }) => {
  const [incomplete, setIncomplete] = useState(initial);

  const returningProcessNode =
    updateReturningProcessNodeSchema.safeParse(incomplete);

  const setTitle = (title: string) =>
    setIncomplete((current) => ({ ...current, title }));

  const setProcessId = (processId: string) =>
    setIncomplete((current) => ({ ...current, processId }));

  return (
    <IncompleteReturningProcessNodeContext.Provider
      value={{
        incomplete,
        returningProcessNode,
        setTitle,
        setProcessId,
      }}
    >
      {children}
    </IncompleteReturningProcessNodeContext.Provider>
  );
};
