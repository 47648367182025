import { useContext } from 'react';
import { format } from 'date-fns';

import { cn } from '@spektr/client/utils';

import { AuthContext } from '../auth/auth-context';
import { HeaderWithHandler } from '../components/HeaderWithHandler';

import { PagesList } from './home/PagesList';
import { ProductVideoContainer } from './home/ProductVideoContainer';
import { InviteMembersContainer } from './home/InviteMembersContainer';

export function RootPage() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <HeaderWithHandler />
      <div className="flex flex-col gap-6 overflow-auto p-6">
        {user && (
          <div className="flex justify-between">
            <h1 className="text-xl font-semibold leading-none">{`Welcome back, ${user?.given_name}`}</h1>

            <div
              className={cn(
                'h-min',
                'px-3 py-1.5',
                'border-color-border-primary rounded-md border',
                'text-color-text-subtext text-xs leading-none'
              )}
            >
              {format(new Date(), 'EEEE, MMMM do')}
            </div>
          </div>
        )}
        <PagesList />
        <div className="flex items-center gap-6">
          <ProductVideoContainer />
          <InviteMembersContainer />
        </div>
      </div>
    </>
  );
}
