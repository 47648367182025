import { type SlateDescendant } from '@spektr/shared/slate-types';

import { slateToHtml } from './slateToHtml';

export function serializeSlate(nodes: SlateDescendant[]) {
  if (Array.isArray(nodes)) {
    return nodes.map((node) => slateToHtml(node)).join('');
  }

  return '';
}
