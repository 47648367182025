import { useState } from 'react';

import { BasicDialog, Button, RichTextEditor } from '@spektr/client/components';
import { type SlateDescendant } from '@spektr/shared/slate-types';

export type EditCommentDialogProps = {
  message: SlateDescendant[];
  onResolve: (message?: SlateDescendant[]) => void;
  onCancel: () => void;
};

export const EditCommentDialog = ({
  message,
  onResolve,
  onCancel,
}: EditCommentDialogProps) => {
  const [comment, updateComment] = useState<SlateDescendant[]>();

  const handleOnResolve = () => {
    onResolve(comment);
  };

  return (
    <BasicDialog
      title="Edit comment"
      dialogHeaderClassName="mb-2"
      className="w-[512px] rounded-2xl border-0"
      defaultOpen
      onClose={onCancel}
      showCloseButton={false}
    >
      <div className="flex flex-col gap-4">
        <RichTextEditor
          className="max-h-60 w-full"
          initialValue={message}
          onChange={(value: SlateDescendant[]) => updateComment(value)}
        />

        <div className="ml-auto flex gap-4">
          <Button variant="text" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button color="white" onClick={() => handleOnResolve()}>
            Update
          </Button>
        </div>
      </div>
    </BasicDialog>
  );
};
