import { z } from 'zod';

import { BaseField } from './BaseField';
import { FieldValidation } from './FieldValidation';

export const DropdownField = BaseField.merge(
  z.object({
    type: z.literal('select'),
    attributes: z.object({
      label: z.string(),
      placeholder: z.string().optional(),
      options: z.array(z.object({ value: z.string(), label: z.string() })),
      helperText: z.string().optional(),
    }),
    validation: z.array(FieldValidation),
  })
);

export type DropdownField = z.infer<typeof DropdownField>;

export function isDropdownField(field: unknown): field is DropdownField {
  return DropdownField.safeParse(field).success;
}
