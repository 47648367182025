export const openLinkInNewTab = (url: string) => {
  if (url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }
};

export const isExternalLink = (url: string) => {
  return url.startsWith('http');
};

export const decodeSearchParams = (
  paramNames: string[] = []
): Record<string, string> | null => {
  if (!paramNames.length) {
    return null;
  }

  const encodedParams = window.location.search?.substring(1);

  if (!encodedParams) {
    return null;
  }

  try {
    let paramMapping = {};
    let decodedParams = `&${atob(encodedParams)}`;
    paramNames.sort(
      (a, b) => decodedParams.indexOf(b) - decodedParams.indexOf(a)
    );

    paramNames.forEach((paramName) => {
      const decodedArr = decodedParams.split(`&${paramName}=`);
      if (decodedArr.length > 0) {
        paramMapping = { ...paramMapping, [paramName]: decodedArr[1] };

        decodedParams = decodedArr[0] ?? '';
      }
    });

    return paramMapping;
  } catch (error) {
    return null;
  }
};
