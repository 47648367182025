import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { cn } from '@spektr/client/utils';
import { Button } from '@spektr/client/components';
import {
  RiskMatrixApiClient,
  getAllRiskMatricesTemplatesQuery,
  getRiskMatricesQueryKey,
} from '@spektr/client/services';

import { matrixViewUrl } from '@spektr/shared/utils';

export const TemplatesList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: templates } = useSuspenseQuery(
    getAllRiskMatricesTemplatesQuery()
  );

  const createMatrixFromTemplateMutation = useMutation({
    mutationFn: (id: string) =>
      RiskMatrixApiClient.getClient().duplicateRiskMatrixTemplate(undefined, {
        params: {
          id,
        },
      }),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({ queryKey: getRiskMatricesQueryKey() });
      navigate(matrixViewUrl(id));
    },
  });

  return (
    <div className="max-h-[260px] min-h-[52px] overflow-y-auto rounded-md border">
      {templates?.length ? (
        templates.map((template, index) => (
          <div
            key={template.id}
            className={cn(
              'flex h-[52px] items-center justify-between',
              index !== 0 && 'border-t'
            )}
          >
            <div className="basis-[40%] px-4">
              <p className="line-clamp-1 text-xs">{template.title}</p>
              <p className="text-xxs text-color-text-subtext line-clamp-1">
                From <strong>{template.title}</strong>
              </p>
            </div>
            <div className="mr-3 flex items-center gap-4">
              <p className="text-color-text-subtext line-clamp-1 w-full text-xs">{`Updated ${formatDistanceToNow(
                template.updatedAt,
                { addSuffix: true }
              )}`}</p>
              <Button
                className="h-7 w-[92px] shrink-0"
                color="cyan"
                onClick={async () =>
                  await createMatrixFromTemplateMutation.mutateAsync(
                    template.id
                  )
                }
                data-cy={`loadRiskMatrixDialog-choose-${template.title}-button`}
              >
                Choose
              </Button>
            </div>
          </div>
        ))
      ) : (
        <div className="text-color-text-subtext flex h-[52px] w-full items-center justify-center text-xs">
          No templates found
        </div>
      )}
    </div>
  );
};
