import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { produce, current } from 'immer';

import { cn } from '@spektr/client/utils';

import { Button, ScrollArea } from '@spektr/client/components';

import {
  WidgetInstance,
  WidgetProperties,
  WidgetTypes,
  WidgetValidationItem,
} from '@spektr/shared/validators';

import { createWidgetInstance } from '../../utils/createWidgetInstance';

import { WIDGET_BLOCK_GROUPS } from '../../config/groupBlocks';

import { CanvasItem } from '../../components/CanvasItem';
import { DropArea } from '../../components/DropArea';

import { WidgetBlock } from '../../types/WidgetBlock';
import { IncompleteWidgetInstance } from '../../types/IncompleteWidgetInstance';

export type CanvasProps = {
  widgets: WidgetInstance[];
  onUpdate: (widgets: WidgetInstance[]) => void;
};

export const Canvas = ({ widgets: widgetsProps, onUpdate }: CanvasProps) => {
  const [widgets, setWidgets] = useState(
    widgetsProps as IncompleteWidgetInstance[]
  );
  const [expandedWidgetId, setExpandedWidgetId] = useState<string>();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: WIDGET_BLOCK_GROUPS,
    drop: (widget: WidgetBlock) => {
      handleAddItem(widget.type);
      return { name: 'Canvas' };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleReorderItem = (dragIndex: number, hoverIndex: number) => {
    setWidgets(
      produce((draft) => {
        draft.splice(hoverIndex, 0, draft.splice(dragIndex, 1)[0]);
        onUpdate(current(draft));
      })
    );
  };

  const handleAddItem = (type: WidgetTypes) => {
    setWidgets(
      produce((draft) => {
        draft.push(createWidgetInstance(type, draft.length));
        onUpdate(current(draft));
      })
    );
  };

  const handleSaveItem = (
    id: string,
    properties: WidgetProperties,
    validators?: WidgetValidationItem[]
  ) => {
    setExpandedWidgetId('');
    setWidgets(
      produce((draft) => {
        const index = draft.findIndex((widget) => widget.id === id);

        if (index === -1) return;

        draft[index].properties = {
          ...draft[index].properties,
          ...properties,
        };

        if (validators) {
          draft[index].validation = validators;
        }
        onUpdate(current(draft));
      })
    );
  };

  const handleDeleteItem = (id: string) => {
    setExpandedWidgetId('');
    setWidgets(
      produce((draft) => {
        const index = draft.findIndex((widget) => widget.id === id);

        if (index === -1) return;

        draft.splice(index, 1);
        onUpdate(current(draft));
      })
    );
  };

  const isActive = canDrop && isOver;

  return (
    <ScrollArea className="max-h-[510px]">
      <div className={cn('flex flex-col items-center', 'h-full px-4 pt-4')}>
        <div className="flex w-[348px] flex-col gap-4 py-4">
          <div>
            {widgets.map((widget, index) => (
              <CanvasItem
                key={`${JSON.stringify(widget.properties)}-${index}`}
                widget={widget}
                index={index}
                isExpanded={expandedWidgetId === widget.id}
                onReorder={handleReorderItem}
                onExpand={(id) => setExpandedWidgetId(id)}
                onCollapse={() => setExpandedWidgetId('')}
                onSave={handleSaveItem}
                onDelete={handleDeleteItem}
              />
            ))}
          </div>
          <DropArea
            ref={drop}
            isActive={isActive}
            canDrop={canDrop}
            canvasLength={widgets.length}
          />
          <Button
            variant="contained"
            color="secondary"
            className="ml-auto mr-auto w-[154px]"
          >
            Submit form
          </Button>
        </div>
      </div>
    </ScrollArea>
  );
};
// color-bg-button-secondary
