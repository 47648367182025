import axios from 'axios';
import { Suspense } from 'react';

import {
  Button,
  ButtonGroup,
  InsightsErrorDashboard,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import { useActionsFilters } from './hooks/useActionsFilters';

import { SkeletonActionsTable } from './components/SkeletonActionsTable';
import { ActionsDataTable } from './containers/ActionsDataTable';

export const InsightsActionsDashboard = () => {
  const { stateFilter, setStateFilter } = useActionsFilters();

  return (
    <SpektrErrorBoundary
      fallbackRender={(error) => {
        const errorMsg = axios.isAxiosError(error)
          ? error.message
          : 'An error has occurred while retrieving the actions!';
        return <InsightsErrorDashboard message={errorMsg} />;
      }}
    >
      <div className="mb-4 flex flex-row">
        {/* <div>
          <Input
            name="customer"
            defaultValue={searchFilter}
            placeholder="Search for customer"
            startIcon={
              <Search className="text-color-text-icon-secondary h-4 w-4" />
            }
            onChange={(ev) => {
              setSearchFilter(ev.target.value);
            }}
          />
        </div> */}
        <ButtonGroup
          className="ml-auto"
          defaultActive={stateFilter}
          onChange={setStateFilter}
        >
          <Button value="pending">To be reviewed</Button>
          <Button value="resolved">Resolved</Button>
        </ButtonGroup>
      </div>
      <Suspense fallback={<SkeletonActionsTable />}>
        <ActionsDataTable />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
