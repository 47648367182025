import { InsightsRootLayout } from '@spektr/client/components';
import {
  InsightsNavigation,
  InsightsRecordsIndex,
} from '@spektr/model-builder/containers';

export const RecordsIndexPage = () => (
  <InsightsRootLayout>
    <InsightsNavigation />
    <InsightsRecordsIndex />
  </InsightsRootLayout>
);
