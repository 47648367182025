import { z } from 'zod';
import { ZodiosEndpointDefinition, makeApi, makeEndpoint } from '@zodios/core';

import {
  ProcessSettingsResponse,
  SandboxExecution,
} from '@spektr/shared/validators';

import { ActionNodeInputSchema, processLinkSchema } from '../node';
import {
  SpektrFieldTypedKeyWithMapping,
  SpektrFieldWithMappings,
  objectIdSchema,
} from '../common';

import { liveProcessVersionSchema } from './launch';

import { versionOverviewResponseSchema, versionSchema } from './version';

import {
  FindAllProcessesTypesQuery,
  createProcessSchema,
  processSchema,
  updateProcessSchema,
  topologyProcessSchema,
  BatchUpdateDependencyMappings,
} from './process';

/**
 * TODO: Add errors
 */

// TODO: find a way to avoid redundancy (see issue #478 for details)
export interface CreateProcessEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'post';
  path: '/processes';
  alias: 'createProcess';
  response: typeof processSchema;
  parameters: [
    {
      name: 'processInput';
      type: 'Body';
      schema: typeof createProcessSchema;
    },
  ];
}

const createProcess: CreateProcessEndpointDefinition = makeEndpoint({
  method: 'post',
  path: '/processes',
  alias: 'createProcess',
  response: processSchema,
  parameters: [
    {
      name: 'processInput',
      type: 'Body',
      schema: createProcessSchema,
    },
  ],
});

const processesSchema = z.array(processSchema);
export interface GetAllProcessesEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'get';
  path: '/processes';
  parameters: [
    {
      name: 'types';
      type: 'Query';
      schema: typeof FindAllProcessesTypesQuery;
    },
  ];
  alias: 'getAllProcesses';
  response: typeof processesSchema;
}

const getAllProcesses: GetAllProcessesEndpointDefinition = makeEndpoint({
  method: 'get',
  path: '/processes',
  alias: 'getAllProcesses',
  parameters: [
    {
      type: 'Query',
      name: 'types',
      schema: FindAllProcessesTypesQuery,
      description: 'Optionally filter processes by type',
    },
  ],
  response: processesSchema,
});

export interface GetProcessEndpointDefinition extends ZodiosEndpointDefinition {
  method: 'get';
  path: '/processes/:id';
  alias: 'getProcessById';
  response: typeof processSchema;
}

const getProcessById: GetProcessEndpointDefinition = makeEndpoint({
  method: 'get',
  path: '/processes/:id',
  alias: 'getProcessById',
  response: processSchema,
});

const getProcessLinksById = makeEndpoint({
  method: 'get',
  path: '/processes/:id/links',
  alias: 'getProcessLinks',
  parameters: [
    {
      name: 'id',
      type: 'Path',
      schema: objectIdSchema,
    },
  ],
  response: z.array(processLinkSchema),
});

const getActionFields = makeEndpoint({
  method: 'get',
  path: '/processes/:id/nodes/:nid/actionfields',
  alias: 'getActionFields',
  parameters: [
    {
      name: 'id',
      type: 'Path',
      schema: z.string(),
    },
    {
      name: 'nid',
      type: 'Path',
      schema: z.string(),
    },
  ],
  response: z.array(ActionNodeInputSchema),
});

const getAllTopology = makeEndpoint({
  method: 'get',
  path: '/processes/topology',
  alias: 'getAllTopology',
  response: topologyProcessSchema.array(),
});

const deleteProcessById = makeEndpoint({
  method: 'delete',
  path: '/processes/:id',
  alias: 'deleteProcessById',
  response: z.union([z.void(), z.string().nullable()]),
});

export interface UpdateProcessEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'patch';
  path: '/processes/:id';
  alias: 'updateProcessById';
  parameters: [
    {
      name: 'processInput';
      type: 'Body';
      schema: typeof updateProcessSchema;
    },
  ];
  response: typeof processSchema;
}
const updateProcessById: UpdateProcessEndpointDefinition = makeEndpoint({
  method: 'patch',
  path: '/processes/:id',
  alias: 'updateProcessById',
  parameters: [
    {
      name: 'processInput',
      type: 'Body',
      schema: updateProcessSchema,
    },
  ],
  response: processSchema,
});

const executeProcessById = makeEndpoint({
  method: 'post',
  path: '/processes/:id/execute',
  alias: 'executeProcessById',
  response: z.void(),
});

const executeSandboxedProcessById = makeEndpoint({
  method: 'post',
  path: '/processes/:id/execute-sandboxed',
  alias: 'executeSandboxedProcessById',
  parameters: [
    {
      name: 'id',
      schema: objectIdSchema,
      type: 'Path',
      description: 'The id of the process to execute',
    },
  ],
  response: z.object({ sandboxId: z.string() }),
});

const getSandboxExecutionById = makeEndpoint({
  method: 'get',
  path: '/processes/:processId/execute-sandboxed/:sandboxId',
  alias: 'getSandboxExecutionById',
  parameters: [
    {
      name: 'sandboxId',
      schema: z.string(),
      type: 'Path',
      description: 'The id of the sandbox execution',
    },
  ],
  response: SandboxExecution,
});

const getLiveVersion = makeEndpoint({
  method: 'get',
  path: '/processes/:processId/versions/live',
  alias: 'getLiveVersion',
  parameters: [
    {
      name: 'processId',
      schema: objectIdSchema,
      type: 'Path',
      description: 'The id of the process',
    },
  ],
  response: liveProcessVersionSchema,
});

const getAllLiveVersions = makeEndpoint({
  method: 'get',
  path: '/processes/versions/live',
  alias: 'getAllLiveVersions',
  response: z.array(liveProcessVersionSchema),
});

const launchVersion = makeEndpoint({
  method: 'post',
  path: '/processes/:processId/versions/:versionId/launch',
  alias: 'launchVersion',
  parameters: [
    {
      name: 'processId',
      schema: objectIdSchema,
      type: 'Path',
      description: 'Process id of the process whose version should be launched',
    },
    {
      name: 'versionId',
      schema: objectIdSchema,
      type: 'Path',
      description: 'Id of the version to launch',
    },
  ],
  response: liveProcessVersionSchema,
});

const removeLaunchedVersion = makeEndpoint({
  method: 'delete',
  path: '/processes/:processId/versions/live',
  alias: 'removeLaunchedVersion',
  description:
    'Removes the live version for the given process. Note that this does not delete the version itself, it only removes the mark making a version the launched version',
  parameters: [
    {
      name: 'processId',
      schema: objectIdSchema,
      type: 'Path',
      description:
        'Process id of the process whose launched version should be removed',
    },
  ],
  response: z.void(),
});

export interface GetVersionOverviewByProcessIdEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'get';
  path: '/processes/:processId/versions';
  alias: 'getVersionOverviewByProcessId';
  response: typeof versionOverviewResponseSchema;
  parameters: [
    {
      name: 'processId';
      type: 'Path';
      schema: typeof objectIdSchema;
    },
  ];
}
const getVersionOverviewByProcessId: GetVersionOverviewByProcessIdEndpointDefinition =
  makeEndpoint({
    method: 'get',
    path: '/processes/:processId/versions',
    alias: 'getVersionOverviewByProcessId',
    parameters: [
      {
        name: 'processId',
        schema: objectIdSchema,
        type: 'Path',
        description: 'The id of the process to get versions for',
      },
    ],
    response: versionOverviewResponseSchema,
  });

export interface GetVersionByIdEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'get';
  path: '/processes/:processId/versions/:versionId';
  alias: 'getVersionById';
  response: typeof versionSchema;
  parameters: [
    {
      name: 'processId';
      type: 'Path';
      schema: typeof objectIdSchema;
    },
    {
      name: 'versionId';
      type: 'Path';
      schema: typeof objectIdSchema;
    },
  ];
}

const getVersionById: GetVersionByIdEndpointDefinition = makeEndpoint({
  method: 'get',
  path: '/processes/:processId/versions/:versionId',
  alias: 'getVersionById',
  parameters: [
    {
      name: 'processId',
      schema: objectIdSchema,
      type: 'Path',
      description: 'The id of the process having the version',
    },
    {
      name: 'versionId',
      schema: objectIdSchema,
      type: 'Path',
      description: 'The id of the version to get',
    },
  ],
  response: versionSchema,
});

export interface GetLatestVersionByProcessIdEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'get';
  path: '/processes/:processId/versions/';
  alias: 'getLatestVersionByProcessId';
  response: typeof versionSchema;
  parameters: [
    {
      name: 'processId';
      type: 'Path';
      schema: typeof objectIdSchema;
    },
  ];
}

const getLatestVersionByProcessId: GetLatestVersionByProcessIdEndpointDefinition =
  makeEndpoint({
    method: 'get',
    path: '/processes/:processId/versions/',
    alias: 'getLatestVersionByProcessId',
    parameters: [
      {
        name: 'processId',
        schema: objectIdSchema,
        type: 'Path',
        description: 'The id of the process to get the latest version for',
      },
    ],
    response: versionSchema,
  });

const createProcessVersion = makeEndpoint({
  method: 'post',
  path: '/processes/:processId/versions',
  alias: 'createProcessVersion',
  parameters: [
    {
      name: 'processId',
      schema: objectIdSchema,
      type: 'Path',
      description: 'The id of the process to publish',
    },
  ],
  response: versionSchema,
});

const getSettings = makeEndpoint({
  method: 'get',
  path: '/processes/settings',
  alias: 'getSettings',
  response: ProcessSettingsResponse,
});

const updateSettings = makeEndpoint({
  method: 'put',
  path: '/processes/settings',
  alias: 'updateSettings',
  response: ProcessSettingsResponse,
});

export interface DuplicateProcessEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'post';
  path: '/processes/:id/duplicate';
  alias: 'duplicateProcess';
  response: typeof processSchema;
}

const duplicateProcess: DuplicateProcessEndpointDefinition = makeEndpoint({
  method: 'post',
  path: '/processes/:id/duplicate',
  alias: 'duplicateProcess',
  response: processSchema,
});

const getDependencies = makeEndpoint({
  method: 'get',
  path: '/processes/:id/dependencies',
  alias: 'getProcessDependencies',
  response: z.object({
    upstream: z.array(SpektrFieldWithMappings),
    dependencies: z.array(SpektrFieldTypedKeyWithMapping),
  }),
});

const updateDependencyMappings = makeEndpoint({
  method: 'put',
  path: '/processes/mappings',
  alias: 'updateDependencyMappings',
  parameters: [
    {
      name: 'updates',
      type: 'Body',
      schema: BatchUpdateDependencyMappings,
      description: 'The batch of mappings updates to apply',
    },
  ],
  response: z.void(),
});

export const processApi = makeApi([
  createProcess,
  getAllProcesses,
  getProcessById,
  deleteProcessById,
  updateProcessById,
  getAllTopology,
  getProcessLinksById,
  executeProcessById,
  executeSandboxedProcessById,
  getSandboxExecutionById,
  getLiveVersion,
  getAllLiveVersions,
  launchVersion,
  removeLaunchedVersion,
  getVersionOverviewByProcessId,
  createProcessVersion,
  getVersionById,
  getLatestVersionByProcessId,
  getSettings,
  updateSettings,
  duplicateProcess,
  getActionFields,
  getDependencies,
  updateDependencyMappings,
]);
