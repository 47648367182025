import { Link } from 'react-router-dom';
import { MessageSquareText } from 'lucide-react';

import {
  insightsActionHitCommentsUrl,
  insightsActionsParamsSchema,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import { cn } from '@spektr/client/utils';

type MessageCountProps = {
  hitId: string;
  count: number;
};

export const MessageCount = ({ hitId, count }: MessageCountProps) => {
  const { actionId } = useParsedParams(insightsActionsParamsSchema);

  return (
    <Link
      to={insightsActionHitCommentsUrl(actionId, hitId)}
      className={cn(
        'flex h-9 items-center gap-1 px-2',
        'rounded-md border',
        'text-color-text-link-secondary text-xs'
      )}
    >
      <MessageSquareText className="h-3 w-3" />
      {count}
    </Link>
  );
};
