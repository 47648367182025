import {
  MATRIX_DASHBOARD_FULL_URL,
  PROCESS_FULL_URL,
} from '@spektr/shared/utils';

import { PageHeadline, Tabbar, TabbarItem } from '@spektr/client/components';
import { Box, GanttChartSquare } from 'lucide-react';

export const DashboardWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="flex h-full flex-col gap-6 p-4">
      <PageHeadline headline="Process Engine" />
      <Tabbar>
        <TabbarItem
          aria-label="Navigate to Processes tab"
          icon={<GanttChartSquare className="h-4 w-4" />}
          to={{ pathname: PROCESS_FULL_URL }}
        >
          Processes
        </TabbarItem>
        <TabbarItem
          aria-label="Navigate to Risk Matrices tab"
          icon={<Box className="h-4 w-4" />}
          to={{ pathname: MATRIX_DASHBOARD_FULL_URL }}
        >
          Risk Matrices
        </TabbarItem>
      </Tabbar>

      {children}
    </div>
  );
};
