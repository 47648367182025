import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import {
  ActionsApiClient,
  ApiKeysApiClient,
  CommentsApiClient,
  DatasetApiClient,
  IngestApiClient,
  InsightsApiClient,
  LoopApiClient,
  MoneypennyApiClient,
  NodesApiClient,
  ProcessApiClient,
  PublicUsersApiClient,
  RiskMatrixApiClient,
  TriggersApiClient,
  UsersApiClient,
  UserSettingsApiClient,
  VendorSettingsApiClient,
} from '@spektr/client/services';

import App from './app/app';

import {
  CLIENT_ID,
  INGEST_API_URL,
  INSIGHTS_API_URL,
  MAPPING_API_URL,
  MONEYPENNY_API_URL,
  PROCESS_API_URL,
  USER_API_URL,
} from './app/config/network';

import './app/services/datadog';

ActionsApiClient.setConfig(PROCESS_API_URL, CLIENT_ID);
ProcessApiClient.setConfig(PROCESS_API_URL, CLIENT_ID);
TriggersApiClient.setConfig(PROCESS_API_URL, CLIENT_ID);
NodesApiClient.setConfig(PROCESS_API_URL, CLIENT_ID);
DatasetApiClient.setConfig(MAPPING_API_URL, CLIENT_ID);
IngestApiClient.setConfig(INGEST_API_URL, CLIENT_ID);
LoopApiClient.setConfig(PROCESS_API_URL, CLIENT_ID);
UsersApiClient.setConfig(USER_API_URL, CLIENT_ID);
ApiKeysApiClient.setConfig(USER_API_URL, CLIENT_ID);
UserSettingsApiClient.setConfig(USER_API_URL, CLIENT_ID);
VendorSettingsApiClient.setConfig(USER_API_URL, CLIENT_ID);
InsightsApiClient.setConfig(INSIGHTS_API_URL, CLIENT_ID);
RiskMatrixApiClient.setConfig(PROCESS_API_URL, CLIENT_ID);
CommentsApiClient.setConfig(PROCESS_API_URL, CLIENT_ID);
MoneypennyApiClient.setConfig(MONEYPENNY_API_URL, CLIENT_ID);

PublicUsersApiClient.setConfig(USER_API_URL, '');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
