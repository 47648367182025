import { ComponentType, createElement } from 'react';

type WithKeyProps = Record<string, unknown>;

/**
 * @see https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
 *
 * @description
 *    A higher-order component that adds a `key` prop to the
 *  wrapped component in order to reset its state when the key changes.
 *
 * @param key The key name to use for generating the `key` prop.
 * @param WrappedComponent The component to wrap.
 * @returns A new component that wraps the specified component and
 *          adds a `key` prop based on the specified key name.
 */
export function withKeyPattern<T extends WithKeyProps>(
  key: string,
  WrappedComponent: ComponentType<T>
) {
  return (props: T) => {
    if (!Object.keys(props).includes(key)) {
      throw new Error(
        `withKeyPattern: ${key} is not defined in ${WrappedComponent.name}`
      );
    }

    return createElement(WrappedComponent, {
      ...props,
      key: props[key] as string,
    });
  };
}
