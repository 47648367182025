import { CreditCard } from 'lucide-react';

import { Button } from '@spektr/client/components';

import { useIncompleteSegment } from '../providers';

export const AddInnerGroupButton = () => {
  const { addRuleGroup } = useIncompleteSegment();

  return (
    <Button
      className="bg-color-bg-button-secondary hover:bg-color-bg-button-secondary active:bg-color-bg-button-secondary--hover text-color-text-button-primary"
      variant="contained"
      startIcon={<CreditCard className="stroke-1" />}
      onClick={addRuleGroup}
    >
      <p>Add Rule Group</p>
    </Button>
  );
};
