import { z } from 'zod';

export const actionSuccessfulReturnTypeSchema = z.object({
  success: z.literal(true),
  value: z.any(),
});

export const actionFailureReturnTypeSchema = z.object({
  success: z.literal(false),
  error: z.string(),
});

export const actionReturnTypeSchema = z.discriminatedUnion('success', [
  actionSuccessfulReturnTypeSchema,
  actionFailureReturnTypeSchema,
]);
