import { DatasetField } from '@spektr/shared/types';

import { TableFields } from '../validators/tableFieldsSchema';
import { tableItemFieldSchema } from '../validators/tableItemFieldSchema';

export function fieldsArrayToObject(fields: DatasetField[]) {
  return fields.reduce((acc, field) => {
    acc[field.name] = tableItemFieldSchema.parse({
      isIncluded: field.isIncluded,
      isIdentifier: field.name,
      type: field.type,
    });

    return acc;
  }, {} as TableFields);
}
