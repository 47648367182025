import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

export const SkeletonRecordExecutionsTable = () => {
  const renderRow = (key: number) => {
    return (
      <TableRow className="h-[64px] border-b" key={key}>
        <TableCell className="p-4">
          <Skeleton className="h-5 w-auto max-w-52" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-5 w-auto max-w-48" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-5 w-auto max-w-32" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-5 w-auto max-w-32" />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table className="border-collapse">
      <TableHeader>
        <TableRow className="text-color-text-subtext h-[42px] text-xs hover:bg-inherit">
          <TableHead className="max-w-4/12 px-4">Name</TableHead>
          <TableHead className="px-4">Run started at</TableHead>
          <TableHead className="px-4">Output</TableHead>
          <TableHead className="px-4">Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>{[...Array(5).keys()].map((key) => renderRow(key))}</TableBody>
    </Table>
  );
};
