import { HTMLAttributes } from 'react';

export const ExperianIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="110"
    height="37"
    viewBox="0 0 110 37"
    fill="none"
    {...props}
  >
    <path
      d="M17.857 8.9631H13.8839C12.681 8.9631 11.7333 8.01539 11.7333 6.81253V2.83943C11.7333 1.67301 12.681 0.688843 13.8839 0.688843H17.857C19.0234 0.688843 20.0075 1.63656 20.0075 2.83943V6.81253C20.0075 8.01539 19.0598 8.9631 17.857 8.9631Z"
      fill="#406EB3"
    />
    <path
      d="M7.54153 17.8935H3.09457C1.74591 17.8935 0.688843 16.7999 0.688843 15.4877V11.0043C0.688843 9.69208 1.78235 8.59857 3.09457 8.59857H7.57798C8.92665 8.59857 9.98371 9.69208 9.98371 11.0043V15.4877C9.98371 16.8364 8.8902 17.8935 7.54153 17.8935Z"
      fill="#632678"
    />
    <path
      d="M7.14053 27.1883H4.40674C3.60483 27.1883 2.94873 26.5322 2.94873 25.7303V22.9965C2.94873 22.1946 3.60483 21.5385 4.40674 21.5385H7.14053C7.94244 21.5385 8.59854 22.1946 8.59854 22.9965V25.7303C8.59854 26.5322 7.94244 27.1883 7.14053 27.1883Z"
      fill="#982881"
    />
    <path
      d="M14.4671 35.8271H11.223C10.2753 35.8271 9.47339 35.0616 9.47339 34.0774V30.8333C9.47339 29.8856 10.2389 29.0837 11.223 29.0837H14.4671C15.4148 29.0837 16.2167 29.8492 16.2167 30.8333V34.0774C16.2167 35.0616 15.4513 35.8271 14.4671 35.8271Z"
      fill="#BA2F7D"
    />
    <path
      d="M26.3135 10.6034H24.0901C23.434 10.6034 22.8872 10.0566 22.8872 9.40054V7.17704C22.8872 6.52094 23.434 5.97418 24.0901 5.97418H26.3135C26.9696 5.97418 27.5164 6.52094 27.5164 7.17704V9.40054C27.5529 10.0566 27.0061 10.6034 26.3135 10.6034Z"
      fill="#BA2F7D"
    />
    <path
      d="M75.3758 12.0979C76.1048 12.0979 76.6516 11.5147 76.6516 10.8221C76.6516 10.0931 76.0684 9.54633 75.3758 9.54633C74.6468 9.54633 74.0636 10.1295 74.0636 10.8221C74.0636 11.5147 74.6468 12.0979 75.3758 12.0979Z"
      fill="#26478D"
    />
    <path
      d="M28.2453 25.8761C28.0631 26.1677 27.7351 26.3135 27.3705 26.3135C27.1883 26.3135 26.9331 26.277 26.7873 26.1677C26.4957 25.9854 26.3135 25.6209 26.3135 25.2564C26.3135 25.0742 26.35 24.8919 26.4593 24.7461L29.4482 20.044L26.4593 15.3419C26.3499 15.1961 26.3135 14.9774 26.3135 14.8316C26.3135 14.4671 26.4957 14.1026 26.8238 13.9203C27.006 13.8109 27.1883 13.7745 27.3705 13.7745C27.7351 13.7745 28.0631 13.9203 28.2818 14.2119L30.724 18.0757L33.2026 14.2119C33.3849 13.9203 33.7494 13.7745 34.1139 13.7745C34.2961 13.7745 34.4784 13.811 34.6606 13.9203C34.9887 14.1026 35.1709 14.4671 35.1709 14.8316C35.1709 15.0138 35.1345 15.1961 35.0251 15.3419L32.0362 20.044L35.0251 24.7461C35.1345 24.8919 35.1709 25.0742 35.1709 25.2564C35.1709 25.6209 34.9887 26.0219 34.6971 26.1677C34.5148 26.2771 34.2961 26.3135 34.1139 26.3135C33.7494 26.3135 33.4578 26.1677 33.2391 25.8761L30.7604 22.0488L28.2453 25.8761Z"
      fill="#26478D"
    />
    <path
      d="M38.9254 15.1961C40.0554 14.1755 41.5863 13.5558 43.2266 13.5558C46.8352 13.5558 49.6783 16.4354 49.6783 20.0076C49.6783 23.5433 46.7987 26.4228 43.2266 26.4228C41.5863 26.4228 40.0554 25.8032 38.9254 24.7826V30.2866C38.9254 30.8334 38.4151 31.3072 37.8683 31.3072C37.358 31.3072 36.8113 30.8334 36.8113 30.2866V14.6858C36.8113 14.139 37.3216 13.6652 37.8683 13.6652C38.4516 13.6652 38.9254 14.139 38.9254 14.6858V15.1961ZM43.1901 15.7064C40.7844 15.7064 38.889 17.6018 38.889 20.0076C38.889 22.4133 40.7844 24.3087 43.1901 24.3087C45.5958 24.3087 47.4913 22.4133 47.4913 20.0076C47.5277 17.6018 45.6323 15.7064 43.1901 15.7064Z"
      fill="#26478D"
    />
    <path
      d="M67.9399 18.8411V25.2929C67.9399 25.8396 67.4296 26.3135 66.8829 26.3135C66.2997 26.3135 65.8258 25.8396 65.8258 25.2929V14.6858C65.8258 14.139 66.3361 13.6652 66.8829 13.6652C67.3932 13.6652 67.9035 14.1026 67.9399 14.6493C68.8147 13.9932 69.9447 13.5923 71.1476 13.5923C71.8037 13.5923 72.314 14.1026 72.314 14.6493C72.314 15.2325 71.8037 15.7429 71.1476 15.7429C69.5073 15.7794 67.9399 16.3625 67.9399 18.8411Z"
      fill="#26478D"
    />
    <path
      d="M75.3758 13.6652C74.7926 13.6652 74.3187 14.1391 74.3187 14.6859V25.3294C74.3187 25.8761 74.829 26.35 75.3758 26.35C75.8861 26.35 76.4328 25.8761 76.4328 25.3294V14.6859C76.4328 14.1391 75.9225 13.6652 75.3758 13.6652Z"
      fill="#26478D"
    />
    <path
      d="M88.9354 14.6858C88.9354 14.139 89.4457 13.6652 89.9924 13.6652C90.5027 13.6652 91.0495 14.139 91.0495 14.6858V25.3293C91.0495 25.8761 90.5392 26.3499 89.9924 26.3499C89.4092 26.3499 88.9354 25.8761 88.9354 25.3293V24.819C87.769 25.8396 86.2745 26.4593 84.6342 26.4593C81.0256 26.4593 78.1825 23.5797 78.1825 20.0076C78.1825 16.4719 81.0621 13.5923 84.6342 13.5923C86.2745 13.5923 87.769 14.2119 88.9354 15.2325V14.6858ZM84.6342 24.3087C87.04 24.3087 88.9354 22.4133 88.9354 20.0076C88.9354 17.6018 87.04 15.7064 84.6342 15.7064C82.2285 15.7064 80.3331 17.6018 80.3331 20.0076C80.3331 22.4133 82.2285 24.3087 84.6342 24.3087Z"
      fill="#26478D"
    />
    <path
      d="M101.875 18.987C101.875 17.2009 100.344 15.67 98.5583 15.67C96.8451 15.67 95.2413 16.3625 95.2413 18.8776V25.3293C95.2413 25.8761 94.731 26.3499 94.1843 26.3499C93.6011 26.3499 93.1272 25.8761 93.1272 25.3293V14.6858C93.1272 14.139 93.6375 13.6652 94.1843 13.6652C94.6946 13.6652 95.2049 14.1026 95.2413 14.6493C96.1526 13.9568 97.2825 13.5923 98.5219 13.5923C101.401 13.5923 103.989 15.8522 103.989 18.6953V25.3293C103.989 25.8761 103.479 26.3499 102.932 26.3499C102.349 26.3499 101.875 25.8761 101.875 25.3293V18.987Z"
      fill="#26478D"
    />
    <path
      d="M25.6939 19.643C25.6574 16.3625 22.7414 13.5923 19.2786 13.5923C15.67 13.5923 12.8269 16.4719 12.8269 20.0076C12.8269 23.6162 15.7065 26.4593 19.2786 26.4593C20.9918 26.4593 22.5956 25.8032 23.762 24.6368C23.9807 24.4545 24.1265 24.1264 24.1265 23.8349C24.1265 23.2516 23.6162 22.7778 23.0695 22.7778C22.7414 22.7778 22.4498 22.96 22.2311 23.2152C21.5021 23.9807 20.445 24.3452 19.3151 24.3452C17.1645 24.3452 15.4149 22.8871 15.0504 20.8459H24.6733C24.6733 20.8459 24.6733 20.8459 24.7098 20.8459H24.7462H24.7827C24.8192 20.8459 24.8556 20.8459 24.8921 20.8094C25.3295 20.6271 25.6939 20.1898 25.6939 19.643ZM19.2786 15.7064C21.2105 15.7064 22.8143 16.9457 23.3611 18.6953H15.1597C15.7065 16.9457 17.3103 15.7064 19.2786 15.7064Z"
      fill="#26478D"
    />
    <path
      d="M64.0397 19.643C64.0033 16.3625 61.0508 13.5923 57.6245 13.5923C54.0159 13.5923 51.1727 16.4719 51.1727 20.0076C51.1727 23.6162 54.0523 26.4593 57.6245 26.4593C59.3376 26.4593 60.9414 25.8032 62.1079 24.6368C62.3266 24.4545 62.4724 24.1264 62.4724 23.8349C62.4724 23.2516 61.9621 22.7778 61.4153 22.7778C61.0873 22.7778 60.7957 22.96 60.577 23.2152C59.8479 23.9807 58.7909 24.3452 57.6609 24.3452C55.5103 24.3452 53.7607 22.8871 53.3962 20.8459H63.0191C63.0191 20.8459 63.0191 20.8459 63.0556 20.8459H63.092C63.1285 20.8459 63.1649 20.8459 63.1649 20.8459H63.2014C63.6388 20.6637 64.0397 20.1898 64.0397 19.643ZM57.6245 15.7064C59.5563 15.7064 61.1602 16.9457 61.7069 18.6953H53.5056C54.0523 16.9457 55.6561 15.7064 57.6245 15.7064Z"
      fill="#26478D"
    />
    <path
      d="M106.249 26.35C106.14 26.35 106.067 26.2771 106.067 26.1677V24.3817H105.666C105.557 24.3817 105.484 24.3088 105.484 24.1994C105.484 24.09 105.557 24.0172 105.666 24.0172H106.833C106.942 24.0172 107.015 24.09 107.015 24.1994C107.015 24.3088 106.942 24.3817 106.833 24.3817H106.432V26.1677C106.432 26.2771 106.359 26.35 106.249 26.35Z"
      fill="#26478D"
    />
    <path
      d="M108.327 26.35C108.218 26.35 108.181 26.2771 108.145 26.2042L107.634 24.7462V26.1677C107.634 26.2771 107.561 26.35 107.452 26.35C107.343 26.35 107.27 26.2771 107.27 26.1677V24.2723C107.27 24.1265 107.379 24.0172 107.525 24.0172C107.634 24.0172 107.744 24.09 107.78 24.1994L108.291 25.6939L108.801 24.1994C108.837 24.09 108.947 24.0172 109.056 24.0172C109.202 24.0172 109.311 24.1265 109.311 24.2723V26.1677C109.311 26.2771 109.238 26.35 109.129 26.35C109.02 26.35 108.947 26.2771 108.947 26.1677V24.7462C108.874 24.9649 108.764 25.3294 108.655 25.621C108.473 26.2042 108.436 26.2042 108.436 26.2042C108.473 26.3136 108.4 26.35 108.327 26.35Z"
      fill="#26478D"
    />
  </svg>
);
