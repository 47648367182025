import { cn } from '@spektr/client/utils';

type MeetingAvatarProps = {
  className: string;
};

export const MeetingAvatar = ({ className }: MeetingAvatarProps) => (
  <span
    className={cn(
      'h-[117px] w-[117px]',
      'absolute top-0',
      'bg-cover bg-center',
      className
    )}
  />
);
