import { createConfig } from '../utils/creators';

import type { Block } from '../types';

export const DEFAULT_BLOCKS: Block[] = [
  {
    id: 'Address',
    category: 'common',
    icon: 'MapPinned',
    title: 'blocks.address.title',
    description: 'blocks.address.description',
    components: [
      {
        id: 'country',
        type: 'input',
        config: createConfig('country'),
        // TODO: (Robert) - transform this to dropdown after ST-1782
        attributes: {
          label: 'Country',
          type: 'text',
          placeholder: 'United States',
        },
        validation: [
          {
            type: 'required',
            value: true,
            message: 'Country is required',
          },
        ],
      },
      {
        id: 'city',
        type: 'input',
        config: createConfig('city'),
        attributes: {
          label: 'City',
          type: 'text',
          placeholder: 'San Francisco',
        },
        validation: [],
      },
      {
        id: 'street',
        type: 'input',
        config: createConfig('street'),
        attributes: {
          label: 'Street',
          type: 'text',
          placeholder: '123 Main St',
        },
        validation: [
          {
            type: 'required',
            value: true,
            message: 'Street is required',
          },
        ],
      },
      {
        id: 'zipCode',
        type: 'input',
        config: createConfig('zip_code'),
        attributes: {
          label: 'Postal Code',
          type: 'number',
          placeholder: '12345',
        },
        validation: [
          {
            type: 'required',
            value: true,
            message: 'Zip code is required',
          },
        ],
      },
      {
        id: 'floor',
        type: 'input',
        config: createConfig('floor'),
        attributes: {
          label: 'Floor / Unit',
          type: 'text',
          placeholder: '3rd',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'individualInfo',
    category: 'kyc',
    icon: 'UserRoundCheck',
    title: 'blocks.individualInfo.title',
    description: 'blocks.individualInfo.description',
    components: [
      {
        id: 'name',
        type: 'input',
        config: createConfig('name'),
        attributes: {
          label: 'Name',
          type: 'text',
          placeholder: 'John Doe',
        },
        validation: [],
      },
      {
        id: 'nationality',
        type: 'select',
        config: createConfig('nationality'),
        attributes: {
          label: 'Nationality',
          options: [],
        },
        validation: [],
      },
      {
        id: 'address',
        type: 'input',
        config: createConfig('address'),
        attributes: {
          label: 'Address',
          type: 'text',
          placeholder: '1234 Main St',
        },
        validation: [],
      },
      {
        id: 'dateOfBirth',
        type: 'input',
        config: createConfig('dateOfBirth'),
        attributes: {
          label: 'Date of birth',
          type: 'date',
        },
        validation: [],
      },
      {
        id: 'phone',
        type: 'input',
        config: createConfig('phone'),
        attributes: {
          label: 'Phone',
          type: 'phone',
          placeholder: '+1234567890',
        },
        validation: [],
      },
      {
        id: 'email',
        type: 'input',
        config: createConfig('email'),
        attributes: {
          label: 'Email',
          type: 'email',
          placeholder: 'john@acme.ltd',
        },
        validation: [],
      },
      {
        id: 'taxId',
        type: 'input',
        config: createConfig('taxId'),
        attributes: {
          label: 'Tax ID',
          type: 'text',
          placeholder: '123-45-6789',
        },
        validation: [],
      },
      {
        id: 'govId',
        type: 'input',
        config: createConfig('govId'),
        attributes: {
          label: 'Government ID',
          type: 'text',
          placeholder: '123456789',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'proofOfAddress',
    category: 'kyc',
    icon: 'Fence',
    title: 'blocks.proofOfAddress.title',
    description: 'blocks.proofOfAddress.description',
    components: [
      {
        id: 'bank_statement',
        type: 'file',
        config: createConfig('bank_statement'),
        attributes: {
          label: 'Bank statement',
          placeholder: 'Upload a bank statement',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'utility_statement',
        type: 'file',
        config: createConfig('utility_statement'),
        attributes: {
          label: 'Utility statement',
          placeholder: 'Upload a utility statement',
          fileType: 'pdf',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'sourceOfFunds',
    category: 'kyc',
    icon: 'Landmark',
    title: 'blocks.sourceOfFunds.title',
    description: 'blocks.sourceOfFunds.description',
    components: [
      {
        id: 'bank_statement',
        type: 'file',
        config: createConfig('bank_statement'),
        attributes: {
          label: 'Bank statement',
          placeholder: 'Upload a bank statement',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'pay_stub',
        type: 'file',
        config: createConfig('pay_stub'),
        attributes: {
          label: 'Pay Stub Upload',
          placeholder: 'Upload a pay stub',
          fileType: 'pdf',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'entityInformation',
    category: 'kyb',
    icon: 'Store',
    title: 'blocks.entityInformation.title',
    description: 'blocks.entityInformation.description',
    components: [
      {
        id: 'name',
        type: 'input',
        config: createConfig('name'),
        attributes: {
          label: 'Name',
          type: 'text',
          placeholder: 'John Doe',
        },
        validation: [],
      },
      {
        id: 'address',
        type: 'input',
        config: createConfig('address'),
        attributes: {
          label: 'Address',
          type: 'text',
          placeholder: '1234 Main St',
        },
        validation: [],
      },
      {
        id: 'dateOfIncorporation',
        type: 'input',
        config: createConfig('dateOfIncorporation'),
        attributes: {
          label: 'Date of Incorporation',
          type: 'date',
        },
        validation: [],
      },
      {
        id: 'registrationNumber',
        type: 'input',
        config: createConfig('registrationNumber'),
        attributes: {
          label: 'Company Registration Number',
          type: 'text',
          placeholder: '123-45-6789',
        },
        validation: [],
      },
      {
        id: 'vat',
        type: 'input',
        config: createConfig('vat'),
        attributes: {
          label: 'Tax/ VAT Number',
          type: 'text',
          placeholder: '123-45-6789',
        },
        validation: [],
      },
      {
        id: 'industry',
        type: 'select',
        config: createConfig('industry'),
        attributes: {
          label: 'Industry',
          options: [],
        },
        validation: [],
      },
    ],
  },
  {
    id: 'legalEntityVerification',
    category: 'kyb',
    icon: 'ShieldCheck',
    title: 'blocks.legalEntityVerification.title',
    description: 'blocks.legalEntityVerification.description',
    components: [
      {
        id: 'register_extract',
        type: 'file',
        config: createConfig('register_extract'),
        attributes: {
          label: 'Commercial Register Extract',
          placeholder: 'Upload a commercial register extract',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'vat_registration',
        type: 'file',
        config: createConfig('vat_registration'),
        attributes: {
          label: 'VAT Registration',
          placeholder: 'Upload a VAT registration',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'articles_of_incorporation',
        type: 'file',
        config: createConfig('articles_of_incorporation'),
        attributes: {
          label: 'Articles of Incorporation',
          placeholder: 'Upload a commercial register extract',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'tax_certificate',
        type: 'file',
        config: createConfig('tax_certificate'),
        attributes: {
          label: 'Tax Certificate',
          placeholder: 'Upload a tax certificate',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'certification_of_registration',
        type: 'file',
        config: createConfig('certification_of_registration'),
        attributes: {
          label: 'Certification of Registration',
          placeholder: 'Upload a certification of registration',
          fileType: 'pdf',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'fetchingRepsUboPscs',
    category: 'kyb',
    icon: 'UsersRound',
    title: 'blocks.fetchingRepsUboPscs.title',
    description: 'blocks.fetchingRepsUboPscs.description',
    components: [
      {
        id: 'root',
        type: 'entity',
        config: {
          spektrDataField: 'entity_reps',
          prefillDefaultValue: true,
        },
        attributes: {
          label: 'Entity representatives',
          buttonText: 'Add new entry',
        },
        validation: [],
        form: {
          fields: {
            name: {
              id: 'name',
              type: 'input',
              config: createConfig('name'),
              attributes: {
                label: 'Name',
                type: 'text',
                placeholder: 'Enter name',
              },
              validation: [],
            },
            email: {
              id: 'email',
              type: 'input',
              config: createConfig('email'),
              attributes: {
                label: 'Email',
                type: 'email',
                placeholder: 'Enter email',
              },
              validation: [],
            },
            phone: {
              id: 'phone',
              type: 'input',
              config: createConfig('phone'),
              attributes: {
                label: 'Phone',
                type: 'phone',
                placeholder: '+1234567890',
              },
              validation: [],
            },
            ownership: {
              id: 'ownership',
              type: 'input',
              config: createConfig('ownership'),
              attributes: {
                label: 'Ownership',
                type: 'percentage',
                placeholder: '100',
              },
              validation: [],
            },
            company_title: {
              id: 'company_title',
              type: 'input',
              config: createConfig('company_title'),
              attributes: {
                label: 'Company title',
                type: 'text',
                placeholder: 'Enter name',
              },
              validation: [],
            },
            dateOfBirth: {
              id: 'dateOfBirth',
              type: 'input',
              config: createConfig('dateOfBirth'),
              attributes: {
                label: 'Date of birth',
                type: 'date',
              },
              validation: [],
            },
            address: {
              id: 'address',
              type: 'input',
              config: createConfig('address'),
              attributes: {
                label: 'Address',
                type: 'text',
                placeholder: '1234 Main St',
              },
              validation: [],
            },
            passport: {
              id: 'passport',
              type: 'file',
              config: createConfig('passport'),
              attributes: {
                label: 'Passport',
                placeholder: 'Upload the passport',
                fileType: '',
              },
              validation: [],
            },
            proof_of_address: {
              id: 'proof_of_address',
              type: 'file',
              config: createConfig('proof_of_address'),
              attributes: {
                label: 'Proof of address',
                placeholder:
                  'Upload the proof of address (utility or bank statement)',
                fileType: '',
              },
              validation: [],
            },
            gov_id: {
              id: 'gov_id',
              type: 'input',
              config: createConfig('gov_id'),
              attributes: {
                label: 'Government ID',
                type: 'text',
                placeholder: '123456789',
              },
              validation: [],
            },
            gov_type: {
              id: 'gov_type',
              type: 'input',
              config: createConfig('gov_type'),
              attributes: {
                label: 'Government ID type',
                type: 'text',
                placeholder: 'ID card',
              },
              validation: [],
            },
          },
          order: [
            'name',
            'email',
            'phone',
            'ownership',
            'company_title',
            'dateOfBirth',
            'address',
            'passport',
            'proof_of_address',
            'gov_id',
            'gov_type',
          ],
        },
      },
    ],
  },
  {
    id: 'shareholders',
    category: 'kyb',
    icon: 'Users',
    title: 'blocks.shareholders.title',
    description: 'blocks.shareholders.description',
    components: [
      {
        id: 'root',
        type: 'entity',
        config: {
          spektrDataField: 'entity_reps',
          prefillDefaultValue: true,
        },
        attributes: {
          label: 'Entity representatives',
          buttonText: 'Add new entry',
        },
        validation: [],
        form: {
          fields: {
            name: {
              id: 'name',
              type: 'input',
              config: createConfig('name'),
              attributes: {
                label: 'Name',
                type: 'text',
                placeholder: 'John Doe',
              },
              validation: [],
            },
            dateOfBirth: {
              id: 'dateOfBirth',
              type: 'input',
              config: createConfig('dateOfBirth'),
              attributes: {
                label: 'Date of birth',
                type: 'date',
              },
              validation: [],
            },
            email: {
              id: 'email',
              type: 'input',
              config: createConfig('email'),
              attributes: {
                label: 'Email',
                type: 'email',
                placeholder: 'john@acme.ltd',
              },
              validation: [],
            },
            function: {
              id: 'function',
              type: 'input',
              config: createConfig('function'),
              attributes: {
                label: 'function',
                type: 'text',
                placeholder: 'CEO',
              },
              validation: [],
            },
          },
          order: ['name', 'dateOfBirth', 'email', 'function'],
        },
      },
    ],
  },
];
