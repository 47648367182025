import { BaseEdge, EdgeProps, getSmoothStepPath } from 'reactflow';

export function EdgeLeaf({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
}: EdgeProps<undefined>) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return <BaseEdge id={id} style={style} path={edgePath} />;
}
