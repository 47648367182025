import { createContext, useState, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk';

import { CognitoUserData } from '@spektr/shared/types';

type AuthProviderContextType = {
  user: CognitoUserData | undefined;
  isLoading: boolean;
  isPasswordRequired: boolean;
  logIn: (email: string, password: string) => Promise<void>;
  changePassword: (newPassword: string) => Promise<void>;
  logOut: (cb: () => void) => Promise<void>;
  updateUserName: (firstName: string, lastName: string) => Promise<void>;
  getCurrentUser: () => Promise<void>;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthContext = createContext<AuthProviderContextType>(
  {} as AuthProviderContextType
);

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<CognitoUserData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const ldClient = useLDClient();

  const getCurrentUser = async () => {
    try {
      const user = await import('./authService').then((module) =>
        module.default.getCurrentUser()
      );

      if (user) {
        setUser(user);

        datadogRum.setUserProperty('email', user.email);
        datadogRum.setUserProperty('workspaceId', user['custom:workspaceId']);
      }
    } catch (err) {
      setUser(undefined);
    }
  };

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (user) {
      if (ldClient) {
        const context: LDContext = {
          key: user.sub,
          custom: {
            role: user.role,
            workspaceId: user['custom:workspaceId'],
          },
        };

        ldClient
          .identify(context)
          .catch((e: unknown) =>
            console.error(`error identifying ${context.key}: ${e}`)
          );
      }
    }
  }, [ldClient, user]);

  const logIn = async (email: string, password: string) => {
    await import('./authService').then((module) =>
      module.default.logIn(
        { email, password },
        {
          newPasswordRequired(userAttributes) {
            setUser(userAttributes);
            setIsPasswordRequired(true);
          },
        }
      )
    );

    await getCurrentUser();
  };

  const changePassword = async (newPassword: string) => {
    if (!user) {
      return;
    }

    await import('./authService').then((module) =>
      module.default.logInWithNewPassword(user.email, newPassword, user, {})
    );

    await getCurrentUser();

    setIsPasswordRequired(false);
  };

  const logOut = async (cb: () => void) => {
    await import('./authService').then((module) => module.default.logOut());

    setUser(undefined);
    cb();
  };

  const updateUserName = async (firstName: string, lastName: string) => {
    await import('./authService').then((module) =>
      module.default.changeName(firstName, lastName)
    );

    await getCurrentUser();
  };

  const authValue = {
    user,
    isLoading,
    isPasswordRequired,
    logIn,
    logOut,
    changePassword,
    updateUserName,
    getCurrentUser,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
