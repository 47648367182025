export const SERVICE_VARIANT_MAP = {
  openCorporatesMonitoring: 'openCorporates' as const,
  openCorporatesRisk: 'openCorporates' as const,
  complyAdvantageKyb: 'complyAdvantage' as const,
  complyAdvantageKyc: 'complyAdvantage' as const,
  bodacc: 'bodacc' as const,
  kyckr: 'kyckr' as const,
  veriff: 'veriff' as const,
  mitId: 'mitId' as const,
  monitoringDataset: 'complyAdvantage' as const,
};
