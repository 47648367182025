import { TriggersApiClient } from './client';
import { getTriggersForProcessQueryKey } from './query.keys';

export function getTriggersForProcessQuery(processId: string) {
  return {
    queryKey: getTriggersForProcessQueryKey(processId),
    queryFn: () =>
      TriggersApiClient.getClient().getTriggersForProcess({
        params: { processId },
      }),
  };
}
