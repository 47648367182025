import { ReactNode, useState } from 'react';
import { useDrag } from 'react-dnd';

import { cn } from '@spektr/client/utils';

export type WidgetProps = {
  icon: ReactNode;
  label: string;
  type: string;
};

export const Widget = ({ icon, label, type }: WidgetProps) => {
  const [isClickHolding, setIsClickHolding] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'input',
    item: { type },
    end: () => {
      setIsClickHolding(false);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={cn(
        'flex items-center gap-2 px-4 py-2',
        'border-color-border-primary rounded-md border',
        'bg-color-bg-widget',
        'text-color-text-primary',
        'cursor-grab',
        'hover:bg-color-bg-widget--hover',
        {
          'border-none opacity-40': isDragging,
          'cursor-grabbing border-none': isClickHolding,
          'bg-color-bg-widget--hold': 'isClickHolding',
        }
      )}
      onMouseDown={() => setIsClickHolding(true)}
      onMouseUp={() => setIsClickHolding(false)}
    >
      {icon}
      <span className="text-xs font-medium">{label}</span>
    </div>
  );
};
