import { useRef, useState } from 'react';

import { SPEKTR_FIELD_TYPES } from '@spektr/client/constants';
import {
  WidgetInstance,
  WidgetProperties,
  WidgetValidationItem,
  isValidWidgetFieldType,
} from '@spektr/shared/validators';

import {
  Input,
  Textarea,
  Select,
  SelectProps,
  Switch,
  Label,
  RadioGroup,
  RadioCheckbox,
} from '@spektr/client/components';

import { useTemplateContext } from '../../providers/TemplateProvider';
import { IncompleteWidgetField } from '../../types/IncompleteWidgetField';
import { OnUpdateFn } from '../../types/UpdateFn';

export type UploadSettingsProps = {
  fieldOptions: SelectProps['options'];
  originalWidget: WidgetInstance;
  properties: WidgetProperties;
  validators: WidgetValidationItem[];
  onUpdate: OnUpdateFn;
  onRequiredChange: (value: boolean) => void;
};

export const UploadSettings = ({
  fieldOptions,
  originalWidget,
  properties,
  validators,
  onUpdate,
  onRequiredChange,
}: UploadSettingsProps) => {
  const { spektrFields } = useTemplateContext();

  const customFieldRef = useRef<HTMLInputElement>(null);
  const [datasetValue, setDatasetValue] = useState<
    IncompleteWidgetField | undefined
  >(
    originalWidget.properties.field.fieldType === 'dataset'
      ? originalWidget.properties.field
      : undefined
  );
  const [customValue, setCustomValue] = useState<
    IncompleteWidgetField | undefined
  >(
    originalWidget.properties.field.fieldType === 'custom'
      ? originalWidget.properties.field
      : undefined
  );

  const attributeMethod = properties.field.fieldType;

  const handleChangeFieldType = (value: string) => {
    if (isValidWidgetFieldType(value)) {
      if (value === 'dataset') {
        onUpdate('field', {
          fieldType: 'dataset',
          ...datasetValue,
        });
      } else if (value === 'custom') {
        onUpdate('field', {
          fieldType: 'custom',
          ...customValue,
        });
      }
    }
  };

  const handleUpdateDatasetField = (value: string) => {
    const spektrField = spektrFields.find((field) => field.key === value);

    if (spektrField) {
      const data: IncompleteWidgetField = {
        fieldType: 'dataset',
        type: spektrField.type,
        name: value,
      };

      setDatasetValue(data);
      onUpdate('field', data);
    }
  };

  const handleBlurCustomField = () => {
    if (attributeMethod === 'custom') {
      const data: IncompleteWidgetField = {
        fieldType: 'custom',
        type: properties.field.type,
        name: customFieldRef.current?.value,
      };

      setCustomValue(data);
      onUpdate('field', data);
    }
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <Input
        defaultValue={properties.label}
        placeholder="Enter label"
        onChange={(ev) => onUpdate('label', ev.target.value)}
      />
      <Textarea
        defaultValue={properties.description}
        placeholder="Enter description"
        onChange={(ev) => onUpdate('description', ev.target.value)}
      />
      <div className="flex items-center gap-2">
        <Switch
          defaultChecked={validators[0]?.value === 'true' ?? false}
          onCheckedChange={onRequiredChange}
        />
        <Label className="text-color-text-primary">Required</Label>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-color-text-subtext text-xs font-medium -tracking-[0.12px]">
          Connect to a dataset field *
        </span>
        <RadioGroup
          value={attributeMethod}
          onValueChange={handleChangeFieldType}
        >
          <div className="flex items-center gap-3">
            <RadioCheckbox id="dataset" value="dataset" />
            <Select
              value={datasetValue?.name !== '' ? datasetValue?.name : undefined}
              disabled={attributeMethod !== 'dataset'}
              placeholder="Select the field"
              options={fieldOptions}
              onValueChange={handleUpdateDatasetField}
            />
          </div>
          <div className="flex items-center gap-3">
            <RadioCheckbox id="custom" value="custom" />
            <Input
              ref={customFieldRef}
              defaultValue={customValue?.name}
              disabled={attributeMethod !== 'custom'}
              className="w-full"
              placeholder="Create a new field"
              onBlur={handleBlurCustomField}
            />
            <Select
              className="w-[117px]"
              disabled
              placeholder="Select type"
              value="file"
              options={SPEKTR_FIELD_TYPES}
            />
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};
