import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  CASES_CUSTOMERS_FULL_URL,
  CASES_URL,
  DASHBOARD_URL,
} from '@spektr/shared/utils';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { HeaderWithHandler } from '../../components/HeaderWithHandler';

export const CasesIndexPage = () => {
  const { customersListView } = useFeatureFlags();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!customersListView) {
      return navigate(DASHBOARD_URL, { replace: true });
    }

    if (location.pathname === CASES_URL) {
      navigate(CASES_CUSTOMERS_FULL_URL, { replace: true });
    }
  }, [location.pathname, navigate, customersListView]);

  return (
    <>
      <HeaderWithHandler />
      <div className="relative flex-grow">
        <Outlet />
      </div>
    </>
  );
};
