import { makeApi, makeEndpoint } from '@zodios/core';
import { z } from 'zod';

import { spektrAIDetailsSchema } from './spektrAI';

export const performSpektrAISearchOnHit = makeEndpoint({
  method: 'post',
  path: '/spektr-ai/action/:actionId/hit/:hitId',
  alias: 'performSpektrAISearchOnHit',
  parameters: [
    {
      name: 'actionId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'hitId',
      schema: z.string(),
      type: 'Path',
    },
  ],
  response: spektrAIDetailsSchema,
});

export const moneypennyApi = makeApi([performSpektrAISearchOnHit]);
