import { useMutation, useQueryClient } from '@tanstack/react-query';

import { COMMENTS_KEYS, CommentsApiClient } from '@spektr/client/services';
import { hasErrorMessage } from '@spektr/shared/utils';
import { toast } from '@spektr/client/components';
import { invalidateMultipleQueries } from '@spektr/client/utils';

export function useDeleteHitComment(commentParentId?: string) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (commentId: string) => {
      return CommentsApiClient.getClient().deleteCommentById(undefined, {
        params: {
          id: commentId,
        },
      });
    },
    onSuccess: async () => {
      await invalidateMultipleQueries(queryClient, [
        { queryKey: COMMENTS_KEYS.ALL() },
        { queryKey: COMMENTS_KEYS.COUNT_BY_PARENT_ID(commentParentId, 'hit') },
      ]);
    },
    onError: (error: Error) => {
      const message = hasErrorMessage(error)
        ? error.message
        : 'Something went wrong. Please try again.';

      toast.error({
        title: 'Something went wrong!',
        description: message,
      });
    },
  });

  return mutation;
}
