import { makeApi, makeEndpoint } from '@zodios/core';

import { z } from 'zod';

import { objectIdSchema } from '../common';

import {
  RiskMatrix,
  RiskMatrixCreate,
  RiskMatrixSlim,
  RiskMatrixUpdate,
} from './matrix';

const createRiskMatrix = makeEndpoint({
  method: 'post',
  path: '/matrices',
  alias: 'createRiskMatrix',
  parameters: [
    {
      name: 'body',
      schema: RiskMatrixCreate,
      type: 'Body',
      description: 'Risk matrix to create',
    },
  ],
  response: RiskMatrix,
});

const uploadRiskMatrix = makeEndpoint({
  method: 'post',
  path: '/matrices/upload',
  alias: 'uploadRiskMatrix',
  parameters: [
    {
      name: 'file',
      type: 'Body',
      description: 'Risk matrix to upload',
      schema: z.instanceof(FormData),
    },
  ],
  response: RiskMatrix,
});

const getAllRiskMatrices = makeEndpoint({
  method: 'get',
  path: '/matrices',
  alias: 'getAllRiskMatrices',
  response: RiskMatrixSlim.array(),
});

const getRiskMatrixById = makeEndpoint({
  method: 'get',
  path: '/matrices/:id',
  alias: 'getRiskMatrixById',
  parameters: [
    {
      name: 'id',
      schema: objectIdSchema,
      type: 'Path',
      description: 'Risk matrix id',
    },
  ],
  response: RiskMatrix,
});

const updateRiskMatrixById = makeEndpoint({
  method: 'patch',
  path: '/matrices/:id',
  alias: 'updateRiskMatrixById',
  parameters: [
    {
      name: 'id',
      schema: objectIdSchema,
      type: 'Path',
      description: 'Risk matrix id',
    },
    {
      name: 'body',
      schema: RiskMatrixUpdate,
      type: 'Body',
      description: 'Risk matrix to update',
    },
  ],
  response: RiskMatrix,
});

const deleteRiskMatrixById = makeEndpoint({
  method: 'delete',
  path: '/matrices/:id',
  alias: 'deleteRiskMatrixById',
  parameters: [
    {
      name: 'id',
      schema: objectIdSchema,
      type: 'Path',
      description: 'Risk matrix id',
    },
  ],
  response: z.void(),
});

const duplicateRiskMatrix = makeEndpoint({
  method: 'post',
  path: '/matrices/:id/duplicate',
  alias: 'duplicateRiskMatrix',
  parameters: [
    {
      name: 'id',
      schema: objectIdSchema,
      type: 'Path',
      description: 'Id of risk matrix to duplicate',
    },
  ],
  response: RiskMatrix,
});

const getAllRiskMatricesTemplates = makeEndpoint({
  method: 'get',
  path: '/matrices/templates',
  alias: 'getAllRiskMatricesTemplates',
  response: RiskMatrixSlim.array(),
});

const getRiskMatrixTemplateById = makeEndpoint({
  method: 'get',
  path: '/matrices/templates/:id',
  alias: 'getRiskMatrixTemplateById',
  parameters: [
    {
      name: 'id',
      schema: objectIdSchema,
      type: 'Path',
      description: 'Risk matrix template id',
    },
  ],
  response: RiskMatrixSlim,
});

const duplicateRiskMatrixTemplate = makeEndpoint({
  method: 'post',
  path: '/matrices/templates/:id/duplicate',
  alias: 'duplicateRiskMatrixTemplate',
  parameters: [
    {
      name: 'id',
      schema: objectIdSchema,
      type: 'Path',
      description: 'Id of risk matrix template to duplicate',
    },
  ],
  response: RiskMatrix,
});

export const riskMatrixApi = makeApi([
  createRiskMatrix,
  getAllRiskMatrices,
  getRiskMatrixById,
  uploadRiskMatrix,
  updateRiskMatrixById,
  deleteRiskMatrixById,
  duplicateRiskMatrix,
  getAllRiskMatricesTemplates,
  getRiskMatrixTemplateById,
  duplicateRiskMatrixTemplate,
]);
