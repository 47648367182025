import { Code, FilePlus, Layers3 } from 'lucide-react';

import {
  CONNECTION_SOURCE_INTEGRATION_URL,
  CONNECTION_SOURCE_UPLOAD_URL,
} from '@spektr/shared/utils';
import { cn } from '@spektr/client/utils';
import { iconBoxVariants } from '@spektr/client/components';

export const getIntegrationSources = (canEdit: boolean) => [
  {
    id: 'api',
    description: 'Add your own custom source through our API',
    icon: (iconClassName: string) => (
      <Code
        className={cn(
          iconBoxVariants({
            variant: 'yellow',
          }),
          iconClassName
        )}
      />
    ),
    title: 'Connect through API',
    actionName: 'Connect through API',
    link: 'https://spektr.readme.io/reference/import-data',
    disabled: !canEdit,
    dataCy: 'integration-source-api-button',
  },
  {
    id: 'integration',
    description: 'Salesforce, HubSpot and many more',
    icon: (iconClassName: string) => (
      <Layers3
        className={cn(
          iconBoxVariants({
            variant: 'yellow',
          }),
          iconClassName
        )}
      />
    ),
    title: 'Choose connections',
    actionName: 'Choose a connection',
    link: CONNECTION_SOURCE_INTEGRATION_URL,
    disabled: true,
    dataCy: 'integration-source-salesforce-button',
  },
  {
    id: 'file-upload',
    description: 'Upload a custom CSV file from your computer',
    icon: (iconClassName: string) => (
      <FilePlus
        className={cn(
          iconBoxVariants({
            variant: 'yellow',
          }),
          iconClassName
        )}
      />
    ),
    title: 'Upload a CSV file',
    actionName: 'Upload a CSV file',
    link: CONNECTION_SOURCE_UPLOAD_URL,
    disabled: !canEdit,
    dataCy: 'integration-source-file-upload-button',
  },
];
