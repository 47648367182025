import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
} from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { cn } from '@spektr/client/utils';

export const dialogOverlayClasses = cn(
  'fixed inset-0',
  'z-50',
  'bg-color-bg-overlay',
  '!duration-200',
  'data-[state=open]:animate-in data-[state=open]:fade-in-0',
  'data-[state=closed]:animate-out data-[state=closed]:fade-out-0'
);

export type DialogOverlayProps = ComponentPropsWithoutRef<
  typeof DialogPrimitive.Overlay
>;

export const DialogOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  DialogOverlayProps
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(dialogOverlayClasses, className)}
    {...props}
  />
));

export const NonModalDialogOverlay = ({
  className,
  ...props
}: ComponentProps<'button'>) => (
  <button
    className={cn(dialogOverlayClasses, 'absolute', className)}
    {...props}
  />
);
