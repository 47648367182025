import { useDrop } from 'react-dnd';

import { cn } from '@spektr/client/utils';
import { Separator } from '@spektr/client/components';

import { useColumnsContext } from '../../providers';
import { ColumnItemType, DragItem } from '../../types';
import { COLUMN } from '../../constants';

import { ColumnItem } from './ColumnItem';

type ColumnListProps = {
  items: ColumnItemType[];
  type: 'hidden' | 'visible';
};

export const ColumnList = ({ items, type }: ColumnListProps) => {
  const { canDrag, handleItemVisibilityChange } = useColumnsContext();
  const [{ isActive }, drop] = useDrop({
    accept: COLUMN,
    drop: (item: DragItem) =>
      handleItemVisibilityChange(item.name, type === 'hidden'),
    collect: (monitor) => ({
      isActive: monitor.isOver() && monitor.canDrop(),
    }),
  });

  return (
    <div className="border-t text-xs font-medium first:border-0">
      {(canDrag || items.length > 0) && (
        <>
          <div className={cn('flex h-9 items-center px-3')}>
            {`${type === 'visible' ? 'Shown' : 'Hidden'} in table`}
          </div>
          <Separator />
        </>
      )}
      {items.length > 0
        ? items?.map((item) => (
            <ColumnItem
              key={item.id}
              id={item.id}
              name={item.name}
              isHidden={item.isHidden}
            />
          ))
        : canDrag && (
            <span
              className={cn(
                'flex items-center',
                'h-9 px-3',
                'text-color-text-subtext',
                isActive && 'bg-color-bg-accent/30'
              )}
              ref={drop}
            >
              {type === 'visible'
                ? 'No columns shown in table'
                : 'No hidden columns'}
            </span>
          )}
    </div>
  );
};
