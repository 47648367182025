import { StickyVideoContainer } from '@spektr/client/components';
import { useDemo } from '@spektr/client/providers';

import { Processes } from './processes';
import { DashboardWrapper } from './DashboardWrapper';

export const ProcessDashboard = () => {
  const { isDemo } = useDemo();

  return (
    <DashboardWrapper>
      <Processes />

      {isDemo && (
        <StickyVideoContainer
          title="Check out our video guide"
          source="https://platform.demo.spektr.com/videos/process_engine.mp4"
          avatar="bg-avatar-jeremy"
        />
      )}
    </DashboardWrapper>
  );
};
