import { Suspense, useEffect } from 'react';
import { Outlet, Params, useMatches } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { Spinner } from '@spektr/client/components';

interface Matches {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle?: {
    title?: string;
  };
}

export function RootLayout() {
  const matches = useMatches() as Array<Matches>;
  const handles = matches.filter((match) => match.handle?.title);

  const last = handles.pop();

  useEffect(() => {
    if (last?.pathname) {
      datadogRum.startView({ name: last.pathname });
    }

    if (last?.handle?.title) {
      document.title = last.handle.title + ' - spektr';
    } else {
      document.title = 'spektr';
    }
  }, [last]);

  return (
    <Suspense
      fallback={
        <div className="dark:bg-color-bg-primary text-color-text-primary h-screen w-screen">
          <Spinner className="m-auto h-8 w-8 opacity-60" />
        </div>
      }
    >
      <div className="dark:bg-color-bg-primary text-color-text-primary h-screen w-screen">
        <Outlet />
      </div>
    </Suspense>
  );
}
