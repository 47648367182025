import { RouteObject } from 'react-router-dom';

import {
  CONNECTION_DATASET_PROCESSES_URL,
  CONNECTION_DATASET_URL,
  CONNECTION_HUB_DATA_SOURCES,
  CONNECTION_HUB_SERVICE_CATALOG,
  CONNECTION_HUB_URL,
  CONNECTION_SERVICE_CATALOG_DETAILS_URL,
  CONNECTION_SOURCE_INTEGRATION_URL,
  CONNECTION_SOURCE_UPLOAD_URL,
} from '@spektr/shared/utils';

import { DatasetProcessesDialog } from '@spektr/model-builder/containers';

import { ConnectionHubIndexPage } from './ConnectionHubIndexPage';
import { FileUploadDialog } from './data-sources/FileUploadDialog';
import { IntegrationDialog } from './data-sources/IntegrationDialog';
import { DatasetPage } from './data-sources/DatasetPage';
import { DataSourcesIndexPage } from './data-sources/DataSourcesIndexPage';
import { ServiceCatalogIndexPage } from './service-catalog/ServiceCatalogIndexPage';
import { ServiceDialog } from './service-catalog/ServiceDialog';

const connectionHubDataSourcesChildren = [
  {
    path: CONNECTION_SOURCE_UPLOAD_URL,
    element: <FileUploadDialog />,
  },
  {
    path: CONNECTION_SOURCE_INTEGRATION_URL,
    element: <IntegrationDialog />,
  },
  {
    path: CONNECTION_DATASET_URL,
    element: <DatasetPage />,
  },
  {
    path: CONNECTION_DATASET_PROCESSES_URL,
    element: <DatasetProcessesDialog />,
  },
];

export const connectionHubRoutes: RouteObject = {
  path: CONNECTION_HUB_URL,
  element: <ConnectionHubIndexPage />,
  handle: {
    title: 'Connections',
  },
  children: [
    {
      path: CONNECTION_HUB_DATA_SOURCES,
      element: <DataSourcesIndexPage />,
      children: connectionHubDataSourcesChildren,
    },
    {
      path: CONNECTION_HUB_SERVICE_CATALOG,
      element: <ServiceCatalogIndexPage />,
      children: [
        {
          path: CONNECTION_SERVICE_CATALOG_DETAILS_URL,
          element: <ServiceDialog />,
        },
      ],
    },
    ...connectionHubDataSourcesChildren,
  ],
};
