import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import { Search } from 'lucide-react';

import { Input, ScrollArea } from '@spektr/client/components';

import { DEFAULT_BLOCKS } from '../../constants/blocks';
import { DEFAULT_WIDGETS } from '../../constants/widgets';
import {
  DEFAULT_READONLY_PRIMITIVES,
  DEFAULT_WRITABLE_PRIMITIVES,
} from '../../constants/primitives';

import { useFormRouter } from '../../hooks/useFormRouter';

import { View } from '../../components/View';
import { FormWidgetSidebar } from '../../components/FormWidgetSidebar';
import { DraggableItemsList } from '../../components/DraggableItemsList';

import { BlockItemCard } from './components/BlockItemCard';
import { WidgetItem } from './components/WidgetItem';
import { PrimitiveItem } from './components/PrimitiveItem';

import type { Block, Primitive, Widget } from '../../types';

export const FormSidebar = () => {
  const [ref, size] = useMeasure<HTMLDivElement>();
  const { currentRoute } = useFormRouter();
  const { t } = useTranslation('moonraker-form');

  const [searchValue, setSearchValue] = useState('');

  const filteredBlocks = useMemo(() => {
    return DEFAULT_BLOCKS.filter((block) =>
      block.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue]);

  const filteredWidgets = useMemo(() => {
    return DEFAULT_WIDGETS.filter((widget) =>
      widget.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue]);

  const filteredWritablePrimitives = useMemo(() => {
    return DEFAULT_WRITABLE_PRIMITIVES.filter((primitive) =>
      primitive.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue]);

  const filteredReadOnlyPrimitives = useMemo(() => {
    return DEFAULT_READONLY_PRIMITIVES.filter((primitive) =>
      primitive.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue]);

  useEffect(() => {
    setSearchValue('');
  }, [currentRoute]);

  if (currentRoute === 'design') {
    return null;
  }

  return (
    <FormWidgetSidebar title={t(`views.${currentRoute}.title`)}>
      <div className="relative h-full w-full">
        <div className="px-3">
          <Input
            placeholder={t(`views.${currentRoute}.searchPlaceholder`)}
            startIcon={
              <Search className="stroke-color-stroke-default h-4 w-4" />
            }
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>
        <div className="relative h-full w-full overflow-hidden">
          <div ref={ref} className="absolute bottom-0 top-0 mb-10 mt-5 w-full">
            <ScrollArea
              style={{
                height: size.height,
              }}
              className="px-3"
            >
              <View route="blocks">
                <DraggableItemsList
                  data={filteredBlocks}
                  renderItem={(block) => (
                    <BlockItemCard block={block as Block} />
                  )}
                />
              </View>
              <View route="fields">
                <DraggableItemsList
                  data={filteredWidgets}
                  renderItem={(block) => (
                    <WidgetItem widget={block as Widget} />
                  )}
                />
              </View>
              <View route="primitives">
                <DraggableItemsList
                  className="grid grid-cols-2"
                  data={filteredWritablePrimitives}
                  renderItem={(block) => (
                    <PrimitiveItem field={block as Primitive} />
                  )}
                />
              </View>
              <View route="visuals">
                <DraggableItemsList
                  className="grid grid-cols-2"
                  data={filteredReadOnlyPrimitives}
                  renderItem={(block) => (
                    <PrimitiveItem field={block as Primitive} />
                  )}
                />
              </View>
            </ScrollArea>
          </div>
        </div>
      </div>
    </FormWidgetSidebar>
  );
};
