import { CognitoUserData } from '@spektr/shared/types';

import { InviteTeamMembers } from './containers/InviteTeamMember';
import { TeamMembers } from './containers/TeamMembers';

export const SettingsWorkspace = ({ user }: { user?: CognitoUserData }) => {
  return (
    <div className="relative h-full w-full">
      <div className="absolute inset-0 overflow-y-auto">
        <div className="mb-5 flex flex-col gap-6 p-5">
          <div className="flex flex-col">
            <h1 className="text-color-text-primary text-lg font-medium">
              Workspace
            </h1>
            <p className="text-sm">
              Invite your team members to join your workspace
            </p>
          </div>
          <div className="grid grid-cols-12 gap-6">
            <InviteTeamMembers
              user={user}
              className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
            />
            <TeamMembers
              user={user}
              className="col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
