import { ActionFieldSchema } from '@spektr/shared/validators';

import { ActionNode as ActionNodeType } from '@spektr/shared/types';

import { getActionRecordKey } from './getActionRecordKey';

export function getActionsAsRecord(node: ActionNodeType) {
  return node.actions.reduce(
    (acc, action) => {
      acc[getActionRecordKey(action.processId, action.nodeId)] = action;
      return acc;
    },
    {} as Record<string, ActionFieldSchema>
  );
}
