import { Select, SelectOption, SelectProps } from '@spektr/client/components';

import { HelperTextTooltip } from '../../HelperTextTooltip';

export type SelectFieldProps = SelectProps & {
  options: SelectOption[];
  label: string;
  emptyLabel?: string;
  placeholder?: string;
  helperText?: string;
};

export const SelectField = ({
  label,
  helperText,
  name,
  ...props
}: SelectFieldProps) => {
  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={name}
        >
          {label}
        </label>
        {helperText && <HelperTextTooltip helperText={helperText} />}
      </div>
      <Select {...props} />
    </div>
  );
};
