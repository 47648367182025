import {
  ApiConfig,
  createBaseApiClient,
  getAccessToken,
} from '@spektr/client/utils';
import { Zodios } from '@zodios/core';
import { pluginToken } from '@zodios/plugins';

import { vendorSettingsApi } from '@spektr/shared/validators';

function createVendorSettingsApiClient(config: ApiConfig) {
  const instance = new Zodios(config.baseUrl, vendorSettingsApi);

  instance.use(
    pluginToken({
      getToken: async () => {
        return Promise.resolve(getAccessToken(config.clientId) ?? undefined);
      },
    })
  );

  return instance;
}

export const VendorSettingsApiClient = createBaseApiClient(
  createVendorSettingsApiClient
);
