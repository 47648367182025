import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { ArrowLeft } from 'lucide-react';

import { insightsActionDetailsUrl } from '@spektr/shared/utils';

import { Button, ScrollArea } from '@spektr/client/components';

import { useActionAndHitIdParam } from '../../hooks/useActionAndHitIdParam';
import { HitPageContent } from '../../containers/HitPageContent';
import { SkeletonHitDetails } from '../../components/SkeletonHitDetails';

export const HitPageDetails = () => {
  const navigate = useNavigate();
  const { actionId } = useActionAndHitIdParam();

  const [ref, size] = useMeasure<HTMLDivElement>();

  const handleClickBack = () => {
    navigate(insightsActionDetailsUrl(actionId));
  };

  return (
    <div className="relative h-full w-full">
      <div ref={ref} className="relative h-full w-full overflow-hidden">
        <ScrollArea
          style={{
            height: size.height - 1,
          }}
        >
          <div className="flex w-full flex-row py-6">
            <Button
              color="secondary"
              startIcon={<ArrowLeft className="h-3 w-3" />}
              onClick={handleClickBack}
            >
              Go back to overview
            </Button>
          </div>

          <Suspense fallback={<SkeletonHitDetails />}>
            <HitPageContent />
          </Suspense>
        </ScrollArea>
      </div>
    </div>
  );
};
