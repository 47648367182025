import { UserSettingsMaxRateLimitUnits } from '@spektr/shared/validators';

export const validateRateLimit = (
  numberOfCalls: number,
  interval: UserSettingsMaxRateLimitUnits
) => {
  switch (interval) {
    case 'second':
      if (numberOfCalls < 1)
        return 'There cannot be less than 1 request per second';
      break;
    case 'minute':
      if (numberOfCalls < 60)
        return 'There cannot be less than 60 requests per minute';
      break;
    case 'hour':
      if (numberOfCalls < 3600)
        return 'There cannot be less than 3600 requests per hour';
      break;
    case 'day':
      if (numberOfCalls < 86400)
        return 'There cannot be less than 86400 requests per day';
      break;
  }

  return '';
};
