import { useNavigate } from 'react-router-dom';

import { rootUrl } from '@spektr/shared/utils';

import {
  Button,
  ResponsiveVideoContainer,
  Dialog,
  DialogContent,
} from '@spektr/client/components';
import { useUpdateCurrentRoute } from '@spektr/client/providers';

type ProductVideoDialogProps = {
  open: boolean;
};

export const ProductVideoDialog = ({ open }: ProductVideoDialogProps) => {
  const navigate = useNavigate();
  const updateState = useUpdateCurrentRoute();

  const handleClose = () => {
    updateState();
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className="bg-color-bg-dialog-default m-auto flex max-w-[802px] flex-col items-center gap-4 rounded-xl p-6"
        requestStartExitAnimation={handleClose}
        onEscapeKeyDown={handleClose}
      >
        <ResponsiveVideoContainer className="border-color-border-tertiary overflow-hidden rounded-xl border">
          <iframe
            width="746"
            height="390"
            src="https://www.youtube.com/embed/qmWhv8VYXG8?si=DWTUudifC2BN5dyD&autoplay=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </ResponsiveVideoContainer>
        <Button
          className="self-end"
          color="white"
          onClick={() => navigate(rootUrl())}
        >
          Start exploring the product
        </Button>
      </DialogContent>
    </Dialog>
  );
};
