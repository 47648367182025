import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { SETTINGS_URL } from '@spektr/shared/utils';

import {
  getDefaultBrandingStyle,
  USERS_KEYS,
  UsersApiClient,
} from '@spektr/client/services';
import { FormBrandingUpdate } from '@spektr/shared/validators';

import { FormLayout } from '@spektr/moonraker-form';
import { type MoonrakerForm as MoonrakerFormType } from '@spektr/moonraker-types';

export const SettingsDefaultFormStyle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: theme } = useSuspenseQuery(getDefaultBrandingStyle());
  const updateBrandingMutation = useMutation({
    mutationFn: (payload: FormBrandingUpdate) =>
      UsersApiClient.getClient().updateBrandingFormById(payload, {
        params: {
          id: theme.id,
        },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: USERS_KEYS.BRANDING_THEME(),
      });
    },
  });

  const handleUpdateBranding = async (data: MoonrakerFormType) => {
    const parsed = FormBrandingUpdate.safeParse({
      ...data.branding,
      assistingContent: data.assistingContent,
    });

    if (parsed?.error) {
      console.log(parsed.error);
      throw new Error(parsed.error.message);
    }

    await updateBrandingMutation.mutateAsync(parsed.data);

    handleClose();
    return Promise.resolve();
  };

  const handleClose = () => {
    // If there is no location history, navigate to the root of the settings page
    if (location.key === 'default') {
      navigate(SETTINGS_URL);
    } else {
      navigate(-1);
    }
  };

  return (
    <FormLayout
      theme={theme}
      onClose={handleClose}
      onSave={handleUpdateBranding}
    />
  );
};
