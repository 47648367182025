import { Suspense } from 'react';
import { isAxiosError } from 'axios';

import { SpektrErrorBoundary, Spinner } from '@spektr/client/components';
import { ErrorPage } from '@spektr/shared/components';

import { ProcessBuilderDetail } from './ProcessBuilderDetail';

export const ProcessBuilderDetailPage = () => {
  return (
    <div className="flex h-full w-full flex-col justify-between">
      <SpektrErrorBoundary
        fallbackRender={(error) => {
          return (
            <ErrorPage
              title={
                isAxiosError(error)
                  ? error?.response?.data.message
                  : 'Something went wrong when loading the process.'
              }
            />
          );
        }}
      >
        <Suspense
          fallback={
            <div className="flex h-full w-full flex-col justify-between">
              <div className="relative flex flex-grow items-center justify-center">
                <Spinner className="h-8 w-8 opacity-60" />
              </div>
            </div>
          }
        >
          <ProcessBuilderDetail />
        </Suspense>
      </SpektrErrorBoundary>
    </div>
  );
};
