import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  ActionFilterStates,
  actionFilterStatesEnum,
} from '../types/ActionStateFilter';

export function useActionsFilters() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchFilter = useCallback(
    (search: string) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        search,
      });
    },
    [searchParams, setSearchParams]
  );

  const setStateFilter = useCallback(
    (state: string) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        state,
      });
    },
    [searchParams, setSearchParams]
  );

  const paramStateFilter = searchParams.get('state');
  let stateFilter: ActionFilterStates = 'pending';

  if (paramStateFilter) {
    const parsed = actionFilterStatesEnum.safeParse(paramStateFilter);

    if (parsed.success) {
      stateFilter = parsed.data;
    }
  }

  const api = useMemo(
    () => ({
      searchFilter: searchParams.get('search') ?? '',
      stateFilter: stateFilter,
      setSearchFilter,
      setStateFilter,
    }),
    [searchParams, setSearchFilter, setStateFilter, stateFilter]
  );

  return api;
}
