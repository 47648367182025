import { createContext, useContext } from 'react';

import { ColumnItemType, DragItem } from '../types';

type ColumnsContextType = {
  columns: ColumnItemType[];
  searchColumnValue: string;
  columnFilter: string;
  canDrag: boolean;
  setSearchColumnValue: (value: string) => void;
  handleItemVisibilityChange: (id: string, isHidden: boolean) => void;
  handleItemMove: (
    item: DragItem,
    newIndex: number,
    newHiddenValue: boolean
  ) => void;
};

export const ColumnsContext = createContext<ColumnsContextType>({
  columns: [],
  searchColumnValue: '',
  columnFilter: '',
  canDrag: false,
  setSearchColumnValue: () => null,
  handleItemVisibilityChange: () => null,
  handleItemMove: () => null,
});

export const useColumnsContext = () => useContext(ColumnsContext);
