import { useMemo } from 'react';
import { produce } from 'immer';

import { type SelectOption } from '@spektr/client/components';

import {
  isWritableField,
  isDateField,
  type Field,
  isCurrencyField,
  isEntitiesField,
  isCheckboxField,
  isDropdownField,
} from '@spektr/moonraker-types';

import { useFormEngine } from '../../../../hooks/useFormEngine';
import { DATE_FORMATS } from '../../../../constants/primitives';

import { ChoiceField, ComboboxField, FormField, SelectField } from '../fields';
import { DateField } from '../fields/DateField';

export type WritableContentProps = {
  currentField: Field;
};
export const WritableContent = ({ currentField }: WritableContentProps) => {
  const { state, updateField, currencyList } = useFormEngine();

  const dateFormatOptions = useMemo<SelectOption[]>(
    () =>
      DATE_FORMATS.map((format) => ({
        value: format,
        label: format,
      })),
    []
  );

  const entitySpektrFields = useMemo<SelectOption[]>(() => {
    if (isEntitiesField(currentField)) {
      return currentField.form.order
        .map((fieldId) => {
          const field = currentField.form.fields[fieldId];
          return isWritableField(field)
            ? field.config.spektrDataField
            : undefined;
        })
        .filter(Boolean)
        .map((option) => ({
          value: option,
          label: option,
        }));
    }
    return [];
  }, []);

  const handleUpdateField = (name: string, value: string) => {
    const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];
    let fieldToBeUpdated = currentField;

    if (fieldId) {
      fieldToBeUpdated = state.fields[rootFieldId];
    }

    if (!fieldToBeUpdated) {
      return;
    }

    const updatedField = produce(fieldToBeUpdated, (draft) => {
      if (isEntitiesField(draft)) {
        const field = draft.form.fields[fieldId];

        if (field && isWritableField(field)) {
          if (name === 'label') {
            field.attributes['label'] = value;
          }
          if (name === 'placeholder') {
            if (name === 'placeholder' && 'placeholder' in field.attributes) {
              field.attributes['placeholder'] = value;
            }
          }
          if (name === 'helperText') {
            field.attributes['helperText'] = value;
          }
          if (name === 'format' && isDateField(field)) {
            field.attributes['format'] = value;
          }
          if (name === 'minDate' && isDateField(field)) {
            field.attributes['minDate'] = value;
          }
          if (name === 'maxDate' && isDateField(field)) {
            field.attributes['maxDate'] = value;
          }
          return;
        }
      }

      if (isWritableField(draft)) {
        if (name === 'label') {
          draft.attributes['label'] = value;
        }
        if (name === 'placeholder' && 'placeholder' in draft.attributes) {
          draft.attributes['placeholder'] = value;
        }
        if (name === 'helperText') {
          draft.attributes['helperText'] = value;
        }
        if (name === 'format' && isDateField(draft)) {
          draft.attributes['format'] = value;
        }
        if (name === 'minDate' && isDateField(draft)) {
          draft.attributes['minDate'] = value;
        }
        if (name === 'maxDate' && isDateField(draft)) {
          draft.attributes['maxDate'] = value;
        }
        if (name === 'currency' && isCurrencyField(draft)) {
          const currency = currencyList.find(
            (currency) => currency.value === value
          );
          draft.attributes['currency'] = {
            symbol: currency?.symbol ?? '',
            code: currency?.value ?? '',
          };
        }
        if (isEntitiesField(draft)) {
          if (name === 'buttonText') {
            draft.attributes['buttonText'] = value;
          }
          if (name === 'labelField') {
            draft.attributes['labelField'] = value;
          }
        }
        return;
      }
    });
    updateField(updatedField);
  };

  const handleChangeOptionValue = (value: string, index: number) => {
    if (!currentField) {
      return;
    }

    const updatedField = produce(currentField, (draft) => {
      if (isCheckboxField(draft)) {
        draft.attributes.options[index] = value;
      } else if (isDropdownField(draft)) {
        draft.attributes.options[index] = { value, label: value };
      }
    });

    updateField(updatedField);
  };

  const handleAddOption = (name: string) => {
    if (!currentField) {
      return;
    }

    const updatedField = produce(currentField, (draft) => {
      if (isCheckboxField(draft)) {
        draft.attributes.options.push(name);
      } else if (isDropdownField(draft)) {
        draft.attributes.options.push({ value: name, label: name });
      }
    });

    updateField(updatedField);
  };

  const handleDeleteOption = (name: string) => {
    if (!currentField) {
      return;
    }

    const updatedField = produce(currentField, (draft) => {
      if (isCheckboxField(draft)) {
        draft.attributes.options = draft.attributes.options.filter(
          (option) => option !== name
        );
      } else if (isDropdownField(draft)) {
        draft.attributes.options = draft.attributes.options.filter(
          (option) => option.label !== name
        );
      }
    });

    updateField(updatedField);
  };

  if (!isWritableField(currentField)) {
    return null;
  }

  return (
    <>
      <FormField
        value={currentField.attributes.label}
        label="Label"
        name="Label"
        placeholder="Enter a label for the field"
        onChange={(ev) => handleUpdateField('label', ev.target.value)}
      />
      <FormField
        value={currentField.attributes?.helperText ?? ''}
        label="Help text"
        name="helperText"
        placeholder="Provide additional information about the field"
        onChange={(ev) => handleUpdateField('helperText', ev.target.value)}
      />
      {'buttonText' in currentField.attributes && (
        <FormField
          value={currentField.attributes.buttonText}
          label="Add Button Text"
          name="buttonText"
          placeholder="Enter a button text"
          onChange={(ev) => handleUpdateField('buttonText', ev.target.value)}
        />
      )}
      {isEntitiesField(currentField) && (
        <SelectField
          value={currentField.attributes.labelField ?? ''}
          options={entitySpektrFields}
          label="Field that displays in dropdown"
          name="labelField"
          placeholder="Field that displays in dropdown"
          onValueChange={(value) => handleUpdateField('labelField', value)}
        />
      )}
      {'placeholder' in currentField.attributes && (
        <FormField
          value={currentField.attributes.placeholder}
          label="Placeholder"
          name="placeholder"
          placeholder="Enter a placeholder text for the field"
          onChange={(ev) => handleUpdateField('placeholder', ev.target.value)}
        />
      )}
      {'options' in currentField.attributes && (
        <ChoiceField
          name="options"
          label="Choices"
          options={currentField.attributes.options.map((option) => {
            if (typeof option === 'string') return option;
            else return option.label;
          })}
          onAddOption={handleAddOption}
          onChangeOption={handleChangeOptionValue}
          onDeleteOption={handleDeleteOption}
        />
      )}
      {isDateField(currentField) && (
        <>
          <SelectField
            value={currentField.attributes.format}
            options={dateFormatOptions}
            label="Date format"
            name="format"
            placeholder="Pick a date format"
            onValueChange={(value) => handleUpdateField('format', value)}
          />
          <DateField
            showToday={false}
            epochOrToday={currentField.attributes.minDate}
            label="Minimum date"
            name="minDate"
            placeholder="Choose a minimum date"
            helperText="The user will not be able to select a date earlier than this date."
            onChange={(value) => {
              handleUpdateField('minDate', String(value));
            }}
          />
          <DateField
            showToday={false}
            epochOrToday={currentField.attributes.maxDate}
            label="Maximum date"
            name="maxDate"
            placeholder="Choose a maximum date"
            helperText="The user will not be able to select a date later than this date."
            onChange={(value) => {
              handleUpdateField('maxDate', String(value));
            }}
          />
        </>
      )}
      {isCurrencyField(currentField) && (
        <ComboboxField
          key={currentField.id}
          defaultValue={
            currencyList.find(
              (currency) =>
                currency.value === currentField.attributes.currency?.code
            )?.label
          }
          onChange={(value) => {
            const indexInList = currencyList.findIndex(
              (currency) => currency.value === value
            );

            if (indexInList > -1) {
              handleUpdateField('currency', value);
            }
          }}
          label="Currency"
          name="currency"
          inputProps={{
            placeholder: 'Select your desired currency',
            dimension: 'default',
          }}
          options={currencyList}
        />
      )}
    </>
  );
};
