import { Suspense } from 'react';

import { Skeleton, SpektrErrorBoundary } from '@spektr/client/components';

import { LoopProcessNameWrapper } from './LoopProcessNameWrapper';

export const LoopProcessDetailsHeader = () => {
  return (
    <div className="flex w-full items-center">
      <SpektrErrorBoundary fallback={null}>
        <Suspense fallback={<Skeleton />}>
          <LoopProcessNameWrapper />
        </Suspense>
      </SpektrErrorBoundary>
    </div>
  );
};
