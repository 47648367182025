import { icons } from 'lucide-react';

import { cn } from '@spektr/client/utils';

export type SidebarSubtitleProps = {
  className?: string;
  title: string;
  iconName: keyof typeof icons;
};

export const SidebarSubtitle = ({
  className,
  title,
  iconName,
}: SidebarSubtitleProps) => {
  const Icon = icons[iconName];

  return (
    <div className={cn('flex flex-row items-center gap-2', className)}>
      <Icon className="h-4 w-4" />
      <span className="text-sm font-medium">{title}</span>
    </div>
  );
};
