import { Skeleton } from '@spektr/client/components';

import { ActionRowItem } from '../ActionRowItem';

export const ActionRowSkeleton = () => {
  return (
    <div className="flex flex-col gap-2 rounded-md border p-6">
      <ActionRowItem label="Process">
        <Skeleton />
      </ActionRowItem>
      <ActionRowItem label="Branch">
        <Skeleton />
      </ActionRowItem>
      <ActionRowItem label="Field">
        <Skeleton />
      </ActionRowItem>
      <ActionRowItem label="Field">
        <Skeleton />
      </ActionRowItem>
    </div>
  );
};
