import { useMemo } from 'react';
import { TextCursorIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { cn } from '@spektr/client/utils';

import {
  BodaccPillIcon,
  BoxIcon,
  ComplyAdvantagePillIcon,
  SpektrRoutingNode,
} from '@spektr/client/components';

import { NodeType, ProcessType } from '@spektr/shared/validators';

import {
  getFieldsWithoutProperties,
  updateFieldsWithProperties,
} from '../../utils';

import { ActionRowItem } from '../ActionRowItem';
import { FieldProcessTitle } from '../FieldProcessTitle';
import { ActionRowValue } from '../ActionRowValue';

export type ActionRowProps = {
  fields: Record<string, boolean>;
  nodeType?: NodeType;
  processBranch: string;
  processName: string;
  processType: ProcessType;
  onUpdateField: (fields: Record<string, boolean>) => void;
};

const ServiceIcon = ({ nodeType }: { nodeType: NodeType }) => {
  switch (nodeType) {
    case 'bodacc': {
      return <BodaccPillIcon className="shrink-0" />;
    }

    case 'complyAdvantageKyb':
    case 'complyAdvantageKyc': {
      return <ComplyAdvantagePillIcon className="shrink-0" />;
    }

    default: {
      return (
        <BoxIcon
          className="bg-color-red shrink-0"
          renderIcon={({ className }) => (
            <TextCursorIcon
              className={cn('stroke-color-stroke-white', className)}
            />
          )}
        />
      );
    }
  }
};

export const ActionRow = ({
  fields,
  nodeType,
  processBranch,
  processName,
  processType,
  onUpdateField,
}: ActionRowProps) => {
  const { t } = useTranslation();

  const fieldsWithoutProperties = useMemo(
    () => getFieldsWithoutProperties(fields),
    [fields]
  );

  return (
    <div className="flex flex-col gap-2 rounded-md border p-6">
      <ActionRowItem label="Process">
        <FieldProcessTitle name={processName} type={processType} />
      </ActionRowItem>

      <ActionRowItem label="Branch">
        <ActionRowValue
          name={processBranch}
          icon={
            <BoxIcon
              className="bg-color-red"
              renderIcon={({ className }) => (
                <SpektrRoutingNode
                  className={cn('stroke-color-stroke-white', className)}
                />
              )}
            />
          }
        />
      </ActionRowItem>

      {Object.entries(fieldsWithoutProperties).map(([key, value]) => (
        <ActionRowItem
          key={`${processName}-${key}`}
          label="Field"
          selected={value}
          onSelect={(value) =>
            onUpdateField(updateFieldsWithProperties(fields, key, value))
          }
        >
          <ActionRowValue
            name={t(`fields:${nodeType}.${key}`)}
            icon={nodeType ? <ServiceIcon nodeType={nodeType} /> : undefined}
          />
        </ActionRowItem>
      ))}
    </div>
  );
};
