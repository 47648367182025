interface PageHeadlineProps {
  headline: string;
  paragraph?: string;
}
export const PageHeadline = ({ headline, paragraph }: PageHeadlineProps) => {
  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-color-text-primary text-page-title select-none font-semibold leading-6">
        {headline}
      </h2>
      <p className="text-color-text-subtext select-none text-base">
        {paragraph}
      </p>
    </div>
  );
};
