import { SpektrAIDetailsEnabled } from '@spektr/shared/validators';
import { TFunction } from 'i18next';

export const formatSpektrAIDetails = (
  spektrAIDetails: SpektrAIDetailsEnabled,
  t: TFunction,
  subject: string
) => {
  return {
    [t('fields:spektrAI.mediaCategory')]: spektrAIDetails.mediaCategory,
    [t('fields:spektrAI.relatedToTarget', { subject })]:
      spektrAIDetails.relatedToTarget ? 'Yes' : 'No',
    [t('fields:spektrAI.relatedToTargetReason', { subject })]:
      spektrAIDetails.relatedToTargetReason,
    [t('fields:spektrAI.isAdverseMedia')]: spektrAIDetails.isAdverseMedia
      ? 'Yes'
      : 'No',
    [t('fields:spektrAI.isAdverseMediaReason')]:
      spektrAIDetails.isAdverseMediaReason,
  };
};
