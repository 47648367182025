import { cn } from '@spektr/client/utils';
import { VariantProps, cva } from 'class-variance-authority';

type SpinnerProps = VariantProps<typeof spinnerVariants> & {
  className?: string;
};

export const Spinner = ({ className, size = 'md' }: SpinnerProps) => {
  return (
    <svg
      className={cn(spinnerVariants({ size }), className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  );
};

const spinnerVariants = cva('animate-spin opacity-60', {
  variants: {
    size: {
      sm: 'w-4 h-4',
      md: 'w-6 h-6',
      lg: 'w-8 h-8',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
