import { HTMLAttributes, forwardRef } from 'react';

import { cn } from '@spektr/client/utils';

type TableProps = HTMLAttributes<HTMLTableSectionElement>;

export const TableHeader = forwardRef<HTMLTableSectionElement, TableProps>(
  ({ className, ...props }, ref) => (
    <thead ref={ref} className={cn('[&_tr]:border', className)} {...props} />
  )
);
