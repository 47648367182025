import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ActionUpdate } from '@spektr/shared/validators';

import {
  ACTIONS_KEYS,
  ActionsApiClient,
  DATASETS_KEYS,
} from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';

export const useUpdateAction = (
  actionId: string,
  datasetId: string,
  spektrId: string
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: ActionUpdate) => {
      await ActionsApiClient.getClient().updateActionById(body, {
        params: { actionId },
      });
      await invalidateMultipleQueries(queryClient, [
        {
          queryKey: ACTIONS_KEYS.BY_ID(actionId),
        },
        { queryKey: DATASETS_KEYS.ALL_ACTIONS(datasetId, spektrId) },
      ]);
    },
  });
};
