import { Tooltip, TooltipProps } from '../Tooltip';

import { Button, ButtonProps } from './Button';

type ButtonWithTooltipProps = ButtonProps & {
  showTooltip: boolean;
  tooltipProps: Omit<TooltipProps, 'children'>;
  children: React.ReactNode;
};

export const ButtonWithTooltip = ({
  showTooltip,
  tooltipProps,
  children,
  disabled = false,
  ...props
}: ButtonWithTooltipProps) => {
  return showTooltip ? (
    <Tooltip
      {...tooltipProps}
      tooltipTriggerProps={{
        ...tooltipProps.tooltipTriggerProps,
        disabled,
        className: 'disabled:cursor-not-allowed disabled:pointer-events-auto',
        asChild: true,
      }}
    >
      <Button disabled={disabled} {...props}>
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button disabled={disabled} {...props}>
      {children}
    </Button>
  );
};
