import { Process } from '@spektr/shared/types';

import { getFilterNodeSource } from './getters';

export function isProcessSource(source?: string): source is 'process' {
  return source === 'process';
}

export function isDatasetSource(source?: string): source is 'source' {
  return source === 'source';
}

export function hasProcessSource(process: Process) {
  return getFilterNodeSource(process) === 'process';
}

export function hasDatasetSource(process: Process) {
  return getFilterNodeSource(process) === 'source';
}
