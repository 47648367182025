import { HTMLAttributes } from 'react';

export const DowJonesIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="54"
    viewBox="0 0 45 54"
    fill="none"
    {...props}
  >
    <path
      d="M0 26.5977V0L14.6389 0.0366421L29.2778 0.0732843L30.53 0.386915C36.6015 1.90757 41.0547 5.78554 43.2338 11.4498C43.7584 12.8133 44.2395 15.0225 44.3279 16.4733L44.4 17.6565H39.9767H35.5535L35.4847 17.0901C35.256 15.2076 34.6623 13.708 33.5983 12.3252C32.534 10.9422 30.9328 9.82083 29.1586 9.2162L28.2045 8.89106L18.5744 8.85723L8.94435 8.82339V26.5969V44.3703H13.3569H17.7694V48.7829V53.1954H8.88472H0V26.5977Z"
      fill="white"
    />
    <path
      d="M24.4479 48.8181V44.4066L26.028 44.3263C26.8971 44.2821 27.948 44.1662 28.3633 44.0687C30.9126 43.4704 33.3814 41.521 34.5311 39.1985C35.4928 37.2558 35.4581 37.562 35.508 30.5662L35.5524 24.335H39.9653H44.3782L44.3332 31.1029L44.2882 37.8707L43.9797 39.123C43.1126 42.6421 41.6078 45.3348 39.1152 47.8274C37.0921 49.8505 34.8566 51.2588 32.2185 52.1718C30.2485 52.8537 28.9517 53.0802 26.5647 53.1594L24.4479 53.2296V48.8181Z"
      fill="white"
    />
  </svg>
);
