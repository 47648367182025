import { useSuspenseQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';

import { processNodeParamsSchema } from '@spektr/shared/utils';

import {
  getLoopAllowedSpektrFieldsQuery,
  getLoopByIdQuery,
  getLoopNodeByIdQuery,
} from '@spektr/client/services';

import { useParsedParams, useUpdateLoopNode } from '@spektr/shared/hooks';

import { NodeDetailsFactoryDialog } from '@spektr/model-builder/containers';

import { NodeUpdateInput } from '@spektr/shared/types';

export const LoopNodeDetails = ({ onClose }: { onClose: () => void }) => {
  const { processId, nodeId } = useParsedParams(processNodeParamsSchema);

  const updateMutation = useUpdateLoopNode(processId, onClose);
  const { data: loop } = useSuspenseQuery(getLoopByIdQuery(processId));
  const { data: node } = useSuspenseQuery(
    getLoopNodeByIdQuery(processId, nodeId)
  );
  const { data: allowedSpektrFields } = useSuspenseQuery({
    ...getLoopAllowedSpektrFieldsQuery(processId, nodeId),
    // FIXME: (@Robert) - Temporary solution until https://github.com/spektr-ai/spektr/issues/754 is merged
    select: (data) => {
      return uniqBy(data, 'key');
    },
  });

  if (!node || !loop) {
    return null;
  }

  const handleUpdateNode = (data: NodeUpdateInput) => {
    updateMutation.mutateAsync({
      nodeId: node.id,
      node: data,
    });
  };

  return (
    <NodeDetailsFactoryDialog
      allowedSpektrFields={allowedSpektrFields ?? []}
      process={loop}
      node={node}
      onClose={onClose}
      onUpdate={handleUpdateNode}
      isPendingUpdate={updateMutation.isPending}
    />
  );
};
