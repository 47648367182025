import { Suspense } from 'react';

import {
  Skeleton,
  SpektrErrorBoundary,
  TableRow,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

import { ExportedSettingsTable } from '../../components/ExportedSettingsTable';
import { SettingsTableCell } from '../../components/SettingsTableCell';
import { ExportedSettingsList } from '../../components/ExportedSettingsList';

type ExportedSettingsProps = {
  className: string;
};

export const ExportedSettings = ({ className }: ExportedSettingsProps) => {
  return (
    <div className={cn('', className)}>
      <SpektrErrorBoundary
        fallbackRender={() => (
          <ExportedSettingsTable>
            <TableRow>
              <SettingsTableCell>There was an error!</SettingsTableCell>
              <SettingsTableCell />
              <SettingsTableCell />
            </TableRow>
          </ExportedSettingsTable>
        )}
      >
        <Suspense
          fallback={
            <ExportedSettingsTable>
              {[...Array(3)].map((_, index) => (
                <TableRow key={index} className="h-[60px]">
                  <SettingsTableCell>
                    <Skeleton className="h-3 w-full max-w-36" />
                  </SettingsTableCell>
                  <SettingsTableCell>
                    <Skeleton className="h-3 w-full max-w-64" />
                  </SettingsTableCell>
                  <SettingsTableCell>
                    <Skeleton className="h-3 w-64" />
                  </SettingsTableCell>
                </TableRow>
              ))}
            </ExportedSettingsTable>
          }
        >
          <ExportedSettingsList />
        </Suspense>
      </SpektrErrorBoundary>
    </div>
  );
};
