import { ReactNode } from 'react';

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

export type TeamTableProps = {
  children: ReactNode;
};

export const TeamTable = ({ children }: TeamTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="text-color-text-table-header w-full border-t-0 p-4 font-medium">
            Team members
          </TableHead>
          <TableHead className="text-color-text-table-header border-t-0 p-4 font-medium">
            Roles
          </TableHead>
          <TableHead className="text-color-text-table-header border-t-0 p-4 text-right font-medium">
            Actions
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>{children}</TableBody>
    </Table>
  );
};
