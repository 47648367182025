import { cn } from '@spektr/client/utils';

import { Tooltip, TooltipProps } from '../Tooltip';

export type TextTruncateProps = Omit<TooltipProps, 'content' | 'children'> & {
  text: string;
  className?: string;
};

export const TextTruncate = ({
  text,
  className,
  side = 'top',
  align = 'center',
}: TextTruncateProps) => {
  return (
    <Tooltip
      content={text}
      side={side}
      align={align}
      contentClassName={cn('bg-color-bg-tooltip break-words')}
      tooltipTriggerProps={{
        asChild: true,
        className: cn(
          'cursor-default overflow-hidden text-ellipsis w-full',
          className
        ),
      }}
    >
      <span>{text}</span>
    </Tooltip>
  );
};
