import { FilePlus } from 'lucide-react';

import { useGoToConnectionHub } from '@spektr/shared/hooks';

import { BasicDialog } from '@spektr/client/components';

import { CsvUpload } from './containers/CsvUpload';

type UploadFileDialogProps = {
  maxSizeInBytes?: number;
};

export const UploadFileDialog = ({ maxSizeInBytes }: UploadFileDialogProps) => {
  const goToConnectionHub = useGoToConnectionHub();

  const handleClose = () => goToConnectionHub();

  return (
    <BasicDialog
      defaultOpen
      title="Add CSV file"
      icon={<FilePlus className="stroke-color-yellow mr-3 h-5 w-5" />}
      description={
        <span>
          <strong className="text-color-text-primary">Import a file</strong> -
          despite not being a standard, CSV are commonly used and can be a quick
          way to get started with spektr. Have a look at{' '}
          <a
            className="underline underline-offset-2 hover:text-white"
            target="_blank"
            rel="noreferrer"
            href="https://discover.spektr.com/doc/import-your-data/doc05CcteXOt#heading-4"
          >
            our documentation
          </a>{' '}
          to use the proper format.
        </span>
      }
      onClose={handleClose}
    >
      <CsvUpload maxSizeInBytes={maxSizeInBytes} />
    </BasicDialog>
  );
};
