import {
  ActionFormPageHitDataDto,
  AnyHitData,
  BodaccSimpleHitDataDto,
  VendorBaseDto,
} from '@spektr/shared/validators';
import { BodaccAnnouncementSpektrField } from '@spektr/vendors';

export function computeHitCardDetails(
  vendorDetails: VendorBaseDto,
  data: AnyHitData
) {
  switch (vendorDetails.service) {
    case 'bodacc':
      return computeBodaccDetails(vendorDetails, data);
    case 'actionForm':
      return computeActionFormDetails(data);
    default:
      return (
        data.data?.actionFormPageHit ||
        data.data?.bodaccPageHit ||
        data.data?.bodaccSimpleHit ||
        data.data?.caPageHit ||
        data.data?.caSimpleHit ||
        data.data?.ocPageHit ||
        data.data?.ocSimpleHit ||
        {}
      );
  }
}

const computeActionFormDetails = (data: AnyHitData) => {
  const actionFormData =
    data.data?.actionFormPageHit || ({} as ActionFormPageHitDataDto);

  return {
    field: actionFormData?.field ?? '',
    oldValue: actionFormData?.oldValue ?? '',
    newValue: actionFormData?.newValue ?? '',
  };
};

export function computeBodaccDetails(
  vendorDetails: VendorBaseDto,
  data: AnyHitData
) {
  const bodaccData =
    data.data?.bodaccSimpleHit || ({} as BodaccSimpleHitDataDto);
  const field = vendorDetails.field as BodaccAnnouncementSpektrField;

  switch (field) {
    case 'is_sold':
      return computeBodaccCommon(bodaccData);
    case 'is_registered':
      return computeBodaccCommon(bodaccData);
    case 'has_branch':
      return computeBodaccCommon(bodaccData);
    case 'has_changes':
      return computeBodaccHasChanges(bodaccData);
    case 'is_deregistered':
      return computeBodaccCommon(bodaccData);
    case 'in_collective_proceedings':
      return computeBodaccCommon(bodaccData);
    case 'in_conciliation_proceedings':
      return computeBodaccCommon(bodaccData);
    default:
      return bodaccData;
  }
}

function computeBodaccCommon(data: BodaccSimpleHitDataDto) {
  return {
    dateparution: data?.dateparution ?? '',
    familleavis_lib: data?.familleavis_lib,
    commercant: data?.commercant ?? '',
    'listepersonnes.typePersonne': data?.listepersonnes?.typePersonne ?? '',
  };
}

function computeBodaccHasChanges(data: BodaccSimpleHitDataDto) {
  return {
    dateparution: data?.dateparution ?? '',
    familleavis_lib: data?.familleavis_lib,
    commercant: data?.commercant ?? '',
    'modificationsgenerales.descriptif': data?.modificationsgenerales ?? '',
    'listepersonnes.typePersonne': data?.listepersonnes?.typePersonne ?? '',
  };
}
