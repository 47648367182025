import { Skeleton } from '@spektr/client/components';

import { SkeletonTable } from '../SkeletonTable';

export const SkeletonRecordDetailsPageSidebar = () => {
  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex items-center gap-4">
        <Skeleton className="h-8 w-8 rounded-full" />

        <div className="flex flex-col gap-1">
          <Skeleton className="h-5 w-24" />
          <Skeleton className="h-4 w-32" />
        </div>
      </div>

      <div className="dark:bg-color-bg-accent/40 bg-color-bg-skeleton/40 grid auto-cols-min grid-cols-3 gap-x-0 gap-y-4 rounded-md p-4">
        <p className="col-span-1 max-w-32 text-sm font-medium">Dataset</p>
        <Skeleton className="col-span-2 h-5 w-44" />

        <p className="col-span-1 max-w-32 text-sm font-medium">Added by</p>
        <Skeleton className="col-span-2 h-5 w-44" />
      </div>

      <div className="flex flex-col gap-3">
        <p className="text-color-text-subtext text-sm font-medium">Details</p>
        <SkeletonTable />
      </div>
    </div>
  );
};
