import { cn } from '@spektr/client/utils';

import { Skeleton } from './Skeleton';

export type SkeletonProcessCardProps = {
  hasTimestamp?: boolean;
};

export const SkeletonProcessCard = ({
  hasTimestamp = false,
}: SkeletonProcessCardProps) => {
  const titleLength = Math.random() < 0.5;

  return (
    <div
      className={cn('rounded-md border', 'flex flex-col', 'overflow-hidden')}
    >
      <div className={cn('flex h-24 flex-grow flex-col gap-3 p-3')}>
        <div className="flex gap-2">
          <Skeleton className="h-5 w-24" />
        </div>
        <Skeleton className={cn('h-5', titleLength ? 'w-1/4' : 'w-full')} />
      </div>
      {hasTimestamp && (
        <div className="flex h-10 items-center px-2">
          <span>
            <Skeleton className="h-4 w-32" />
          </span>
        </div>
      )}
    </div>
  );
};
