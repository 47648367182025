import axios from 'axios';
import { CircleX } from 'lucide-react';
import { Suspense } from 'react';

import { SpektrErrorBoundary, Spinner } from '@spektr/client/components';

import { FallbackDialog } from './FallbackDialog';

export const NodeDetailsPreloader = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        const errorMsg = axios.isAxiosError(error)
          ? error.response?.data?.message
          : 'Something went wrong when loading the node details.';
        return (
          <FallbackDialog onClose={onClose}>
            <CircleX className="text-color-red h-6 w-6" />
            {errorMsg}
          </FallbackDialog>
        );
      }}
    >
      <Suspense
        fallback={
          <FallbackDialog onClose={onClose}>
            <Spinner />
            Loading...
          </FallbackDialog>
        }
      >
        {children}
      </Suspense>
    </SpektrErrorBoundary>
  );
};
