import { Label } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';
import { ReactNode } from 'react';

export type SlackFieldProps = {
  title: string;
  className?: string;
  children: ReactNode;
};

export const SlackFieldRow = ({
  title,
  className,
  children,
}: SlackFieldProps) => {
  return (
    <div
      className={cn('flex w-full items-center border-b px-4 py-2', className)}
    >
      <Label className="line-clamp-1">{title}</Label>
      <div className="ml-auto flex items-center gap-2">
        <p className="text-xs">* Mandatory</p>
        <div className="w-60">{children}</div>
      </div>
    </div>
  );
};
