import { useMemo } from 'react';

import {
  Action,
  HitResolvedDecision,
  UserSchema,
} from '@spektr/shared/validators';

import { InfiniteScroll } from '@spektr/client/components';

import {
  ClientActivityCommentItem,
  ClientActivityItem,
  ClientActivityLogItem,
} from '../../types';

import { useGetComments } from '../../hooks/useGetComments';

import { SidebarSubtitle } from '../SidebarSubtitle';
import { ActivityLogItem } from '../ActivityLogItem';
import { CommentBox } from '../CommentBox';
import { ActivityCommentItem } from '../ActivityCommentItem';

export type ActionActivityLogProps = {
  action: Action;
  users: UserSchema[];
};

export const ActionActivityLog = ({
  action,
  users,
}: ActionActivityLogProps) => {
  const {
    comments: rawComments,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useGetComments(action.id, 'action', 100);

  const comments = useMemo<ClientActivityCommentItem[]>(
    () =>
      rawComments.map((comment) => ({
        id: comment.id,
        createdAt: comment.createdAt,
        message: comment.message,
        user: users.find((user) => user.id === comment.userId),
      })),
    [rawComments, users]
  );

  const decisions = useMemo<ClientActivityLogItem[]>(() => {
    return action.hits
      .filter((hit) => hit.decision.status !== 'pending')
      .map((hit) => {
        const decision = hit.decision as HitResolvedDecision;

        const currentUser = users.find((user) => user.id === decision.userId);

        if (!currentUser) {
          throw new Error('User not found');
        }

        return {
          hitId: hit.id,
          actionId: action.id,
          status: decision.status,
          reason: decision.reason,
          user: currentUser,
          createdAt: decision.createdAt,
          vendorDetails: hit.vendorDetails,
        };
      });
  }, [action.hits, action.id, users]);

  const activities = useMemo<ClientActivityItem[]>(
    () => [...decisions, ...comments].sort((a, b) => b.createdAt - a.createdAt),
    [decisions, comments]
  );

  return (
    <div className="flex flex-col gap-4">
      <SidebarSubtitle title="Log and comments" iconName="MessageCircleMore" />

      <CommentBox parentType="action" parentId={action.id} />

      {activities.length > 0 ? (
        <InfiniteScroll
          className="rounded-md border"
          fetchMoreItems={fetchNextPage}
          hasMoreItems={hasNextPage}
          isFetching={isFetching || isFetchingNextPage}
          loadMoreText="Loading more comments..."
        >
          {activities.map((activity) => {
            const log = activity as ClientActivityLogItem;

            if (log.hitId) {
              return <ActivityLogItem key={log.hitId} data={log} />;
            }

            const comment = activity as ClientActivityCommentItem;
            return <ActivityCommentItem key={comment.id} data={comment} />;
          })}
        </InfiniteScroll>
      ) : (
        <p className="text-color-text-primary text-center text-base">
          No recent activity.
        </p>
      )}
    </div>
  );
};
