import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { PaginationLink, PaginationLinkProps } from './PaginationLink';

export enum ArrowType {
  Backward,
  Forward,
  Previous,
  Next,
}

type PaginationArrowProps = PaginationLinkProps & {
  arrowType: ArrowType;
  iconClassName?: string;
};

export const PaginationArrow = ({
  arrowType,
  iconClassName,
  ...props
}: PaginationArrowProps) => {
  const { icon, label } = (() => {
    const iconClasses = cn('h-3 w-3', iconClassName);

    switch (arrowType) {
      case ArrowType.Backward:
        return {
          icon: <ChevronsLeft className={iconClasses} />,
          label: 'Go to the first page',
        };
      case ArrowType.Forward:
        return {
          icon: <ChevronsRight className={iconClasses} />,
          label: 'Go to the last page',
        };
      case ArrowType.Previous:
        return {
          icon: <ChevronLeft className={iconClasses} />,
          label: 'Go to the previous page',
        };
      case ArrowType.Next:
        return {
          icon: <ChevronRight className={iconClasses} />,
          label: 'Go to the next page',
        };
      default:
        return {
          icon: <ChevronLeft className={iconClasses} />,
          label: 'Go to the previous page',
        };
    }
  })();

  return (
    <PaginationLink aria-label={label} size="default" {...props}>
      {icon}
    </PaginationLink>
  );
};
