import { LayoutGrid, RefreshCw } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { ProcessName } from '@spektr/client/components';
import { ProcessType } from '@spektr/shared/validators';

import { ActionRowValue } from '../ActionRowValue';

export type FieldProcessTitleProps = {
  name: string;
  type: ProcessType;
};

export const FieldProcessTitle = ({ name, type }: FieldProcessTitleProps) => {
  const icon =
    type === 'loop' ? (
      <RefreshCw className="stroke-color-red h-2.5 w-2.5" />
    ) : (
      <LayoutGrid className="stroke-color-cyan h-2.5 w-2.5" />
    );

  return (
    <ActionRowValue
      icon={
        <div
          className={cn(
            'flex h-5 w-5 items-center justify-center rounded-md',
            type === 'loop' ? 'bg-color-red/20' : 'bg-color-cyan/20'
          )}
        >
          {icon}
        </div>
      }
      name={<ProcessName name={name} processType={type} />}
    />
  );
};
