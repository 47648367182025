import { useMemo } from 'react';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { CommentParentType } from '@spektr/shared/validators';

import { getCommentsByParentId } from '@spektr/client/services';

export const useGetComments = (
  parentId: string,
  parentType: CommentParentType,
  limit = 10
) => {
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useSuspenseInfiniteQuery(
      getCommentsByParentId(parentId, parentType, limit)
    );

  const comments = useMemo(
    () => data?.pages.flatMap((page) => page.docs) ?? [],
    [data]
  );

  return useMemo(
    () => ({
      comments,
      hasNextPage,
      fetchNextPage,
      isFetching,
      isFetchingNextPage,
    }),
    [comments, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage]
  );
};
