import { cn } from '@spektr/client/utils';

import { type Field } from '@spektr/moonraker-types';
import { type SpektrField } from '@spektr/shared/types';

import { ConfigSegment } from '../../../../components/ConfigSegment';

import { ConfigContent } from '../ConfigContent';
import { ReadOnlyContent } from '../ReadOnlyContent';
import { WritableContent } from '../WritableContent';

export type ContentProps = {
  currentField: Field;
  spektrFields: SpektrField[];
};

export const Content = ({ currentField, spektrFields }: ContentProps) => {
  return (
    <ConfigSegment name="content" title="Content">
      <div className={cn('flex flex-col gap-4', 'mb-2 pb-2')}>
        <ConfigContent
          currentField={currentField}
          spektrFields={spektrFields}
        />
        <ReadOnlyContent currentField={currentField} />
        <WritableContent currentField={currentField} />
      </div>
    </ConfigSegment>
  );
};
