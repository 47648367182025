import { z } from 'zod';

export const vendorsAsync = [
  'openCorporatesMonitoring',
  'openCorporatesRisk',
  'complyAdvantageKyc',
  'complyAdvantageKyb',
  'kyckr',
  'bodacc',
];

export const vendors = [
  'openCorporates',
  'complyAdvantage',
  'openCorporatesMonitoring',
  'openCorporatesRisk',
  'complyAdvantageKyc',
  'complyAdvantageKyb',
  'kyckr',
  'bodacc',
  'veriff',
  'mitId',
] as const;
export type Vendor = (typeof vendors)[number];

export const vendorList = [
  'openCorporates',
  'complyAdvantage',
  'kyckr',
  'bodacc',
  'veriff',
  'mitId',
] as const;

export const vendorSearchStatuses = [
  'pending',
  'completed',
  'failed',
  'polling',
] as const;
export type VendorSearchStatus = (typeof vendorSearchStatuses)[number];
const vendorsSchema = z.enum(vendors);

export const vendorResponseTypes = [
  'retrieveOwnershipTree',
  'requestOwnershipTree',
  'searchV2EnhancedProfile',
  'veriffDecision',
  'mitIdData',
] as const;
export type VendorResponseType = (typeof vendorResponseTypes)[number];

export const VendorSearchSchema = z.object({
  vendor: vendorsSchema,
  searchId: z.string().optional(),
  request: z.record(z.unknown()),
  response: z
    .record(z.unknown())
    .or(z.record(z.enum(vendorResponseTypes), z.unknown()))
    .optional(),
  workspaceId: z.string().optional(),
  processRunId: z.string().optional(),
  datasetId: z.string().nullish(),
  executionContextId: z.string().optional(),
  nodeId: z.string().optional(),
  status: z.enum(vendorSearchStatuses).optional(),
  pollingId: z.string().optional(),
});

export type VendorSearchSchema = z.infer<typeof VendorSearchSchema>;
