import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getExecutionsBySpektrId } from '@spektr/client/services';
import {
  ExecutionContextWithDatasetId,
  PaginationOptions,
} from '@spektr/shared/validators';

export const useGetExecutionsBySpektrId = (
  datasetId: string,
  spektrId: string,
  filters?: PaginationOptions
) => {
  const { data: executions } = useSuspenseQuery({
    ...getExecutionsBySpektrId(datasetId, spektrId, filters),
    refetchInterval: (data) => (data.state.data?.docs.length ? false : 10000),
  });

  const parsedExecutions: ExecutionContextWithDatasetId[] = useMemo(
    () => executions.docs,
    [executions]
  );

  return useMemo(
    () => ({ executions: parsedExecutions, totalPages: executions.totalPages }),
    [parsedExecutions, executions.totalPages]
  );
};
