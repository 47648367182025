import {
  ComboboxItem,
  ComboboxItemProps,
  SpektrDatasetIcon,
  iconBoxVariants,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

type DatasetFieldProps = {
  value: ComboboxItemProps['value'];
  children: React.ReactNode;
};

export const DatasetField = ({ value, children }: DatasetFieldProps) => {
  return (
    <ComboboxItem value={value}>
      <div className="pointer-events-none flex items-center justify-between gap-1">
        <p className="overflow-hidden text-ellipsis">{children}</p>
        <SpektrDatasetIcon
          className={cn(
            iconBoxVariants({ variant: 'yellow' }),
            'h-5 w-5 shrink-0 p-1'
          )}
        />
      </div>
    </ComboboxItem>
  );
};
