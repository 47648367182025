import {
  Process,
  NodeUpdateInput,
  ServiceNode as ServiceNodeType,
} from '@spektr/shared/types';

import { MonitoringServiceNode } from './containers/MonitoringServiceNode';
import { RiskServiceNode } from './containers/RiskServiceNode';

export type ServiceNodeFactoryProps = {
  process: Process;
  node: ServiceNodeType;
  onClose: () => void;
  onUpdate: (data: NodeUpdateInput) => void;
};

export const ServiceNodeFactory = ({
  process,
  ...props
}: ServiceNodeFactoryProps) => {
  if (process.type === 'monitoring') {
    return <MonitoringServiceNode processId={process.id} {...props} />;
  }

  return <RiskServiceNode processId={process.id} {...props} />;
};
