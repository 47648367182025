import { z } from 'zod';
import { makeApi, makeEndpoint } from '@zodios/core';

import { paginatedParameters } from '../pagination';

import {
  actionSchemaWithBaseHits,
  getActionByIdResponseSchema,
  getActionsResponseSchema,
  actionUpdateSchema,
} from './actions';
import {
  getActionHitByIdResponseSchema,
  getHitsResponseSchema,
  resolvedDecisionBaseSchema,
} from './hits';

export const getAllActions = makeEndpoint({
  method: 'get',
  path: '/actions',
  alias: 'getAllActions',
  parameters: [
    {
      name: 'status',
      schema: z.string().optional(),
      type: 'Query',
    },
    ...paginatedParameters,
  ],
  response: getActionsResponseSchema,
});

export const getActionById = makeEndpoint({
  method: 'get',
  path: '/actions/:actionId',
  alias: 'getActionById',
  parameters: [
    {
      name: 'actionId',
      schema: z.string(),
      type: 'Path',
    },
  ],
  response: actionSchemaWithBaseHits,
});

export const updateActionById = makeEndpoint({
  method: 'put',
  path: '/actions/:actionId',
  alias: 'updateActionById',
  parameters: [
    {
      name: 'actionId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'input',
      type: 'Body',
      schema: actionUpdateSchema,
    },
  ],
  response: getActionByIdResponseSchema,
});

export const getActionHits = makeEndpoint({
  method: 'get',
  path: '/actions/:actionId/hits',
  alias: 'getActionHits',
  parameters: [
    {
      name: 'actionId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'field',
      schema: z.string().optional(),
      type: 'Query',
    },
    ...paginatedParameters,
  ],
  response: getHitsResponseSchema,
});

export const getActionHitById = makeEndpoint({
  method: 'get',
  path: '/actions/:actionId/hits/:hitId',
  alias: 'getActionHitById',
  parameters: [
    {
      name: 'actionId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'hitId',
      schema: z.string(),
      type: 'Path',
    },
  ],
  response: getActionHitByIdResponseSchema,
});

export const updateActionHitById = makeEndpoint({
  method: 'put',
  path: '/actions/:actionId/hits/:hitId',
  alias: 'updateActionHitById',
  parameters: [
    {
      name: 'actionId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'hitId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'input',
      type: 'Body',
      schema: resolvedDecisionBaseSchema,
    },
  ],
  response: getActionHitByIdResponseSchema,
});

export const actionsApi = makeApi([
  getAllActions,
  getActionById,
  updateActionById,
  getActionHits,
  getActionHitById,
  updateActionHitById,
]);
