import { NODE_ACCESS_MATRIX, ProcessType } from '@spektr/shared/validators';

import { DrawerItemType } from '../types/DrawerItemType';

export const getAvailableItemsForProcess = (
  items: DrawerItemType[],
  processType?: ProcessType
): DrawerItemType[] => {
  if (!processType) {
    return [];
  }

  return items.filter(
    (item) =>
      NODE_ACCESS_MATRIX[item.type].includes(processType) && !item.hidden
  );
};
