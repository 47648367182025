import { useIncompleteRuleGroup } from '../providers';

import { SelectBooleanOperator } from './select-boolean-operator';

export const SelectBetweenGroupsOperator = (props: {
  id: string;
  value: string | undefined;
}) => {
  const { setOperatorBetweenGroups } = useIncompleteRuleGroup();

  return (
    <SelectBooleanOperator
      trigger-data-testid={`between-groups-operator-${props.id}`}
      required
      value={props.value}
      onValueChange={(operator) => setOperatorBetweenGroups(operator)}
    />
  );
};
