import { SpektrField } from '../../goldenRecord';

//TODO:  [ST-1237] Label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and retrieved by the field key

export const hasSanctionsFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_sanctions_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_sanctions_fields.last_updated_utc',
  },
  {
    key: 'has_sanctions_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_sanctions_fields.entity_type',
  },
  {
    key: 'has_sanctions_fields.name',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_sanctions_fields.name',
  },
  {
    key: 'has_sanctions_fields.types',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_sanctions_fields.types',
  },
];

export const hasWarningsFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_warnings_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_warnings_fields.last_updated_utc',
  },
  {
    key: 'has_warnings_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_warnings_fields.entity_type',
  },
  {
    key: 'has_warnings_fields.name',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_warnings_fields.name',
  },
  {
    key: 'has_warnings_fields.types',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_warnings_fields.types',
  },
];

export const hasFitnessAndProbityFields: Array<Omit<SpektrField, 'sourceId'>> =
  [];

export const hasAdverseMediaFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_adverse_media_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_adverse_media_fields.last_updated_utc',
  },
  {
    key: 'has_adverse_media_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_adverse_media_fields.entity_type',
  },
  {
    key: 'has_adverse_media_fields.name',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_adverse_media_fields.name',
  },
  {
    key: 'has_adverse_media_fields.types',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_adverse_media_fields.types',
  },
];

export const complyAdvantageKybCalculatedFields: Array<
  Omit<SpektrField, 'sourceId'>
> = [
  {
    key: 'has_sanctions',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Company is on sanctions lists',
  },
  {
    key: 'has_warnings',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Company is on warning lists',
  },
  {
    key: 'has_fitness_and_probity',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Company is on exclusion lists',
  },
  {
    key: 'has_adverse_media',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Company has adverse media mentions',
  },
];

export const complyAdvantageKybSpektrFields: Array<
  Omit<SpektrField, 'sourceId'>
> = [
  ...complyAdvantageKybCalculatedFields,
  ...hasSanctionsFields,
  ...hasWarningsFields,
  ...hasFitnessAndProbityFields,
  ...hasAdverseMediaFields,
];
