import { v4 as uuid } from 'uuid';

import { assertUnreachable } from '@spektr/shared/utils';

import { WidgetTypes } from '@spektr/shared/validators';

export function createWidgetInstance(type: WidgetTypes, index: number) {
  return {
    id: uuid(),
    type: type,
    index: index,
    properties: {
      label: generateLabelByType(type),
      description: '',
      placeholder: '',
      field: {
        fieldType:
          type === 'upload' ? ('custom' as const) : ('dataset' as const),
        type: type === 'upload' ? ('file' as const) : ('string' as const),
        name: '',
      },
    },
    validation: [
      {
        key: 'required',
        message: 'This field is required',
        value: 'false',
      },
    ],
  };
}

function generateLabelByType(type: WidgetTypes) {
  switch (type) {
    case 'headline':
      return 'Headline';
    case 'paragraph':
      return 'Paragraph';

    case 'input':
      return 'Single line input';
    case 'textarea':
      return 'Multi line input';
    case 'upload':
      return 'File upload';

    default:
      assertUnreachable(type);
  }
}
