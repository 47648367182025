import { Skeleton } from '@spektr/client/components';

import { SidebarHeader } from '../SidebarHeader';
import { SidebarSubtitle } from '../SidebarSubtitle';
import { SkeletonTable } from '../SkeletonTable';

export const SkeletonActionDetailsSidebar = () => {
  return (
    <div className="flex flex-col gap-6 p-6">
      <SidebarHeader
        identifier={<Skeleton className="mt-1 h-4 w-28" />}
        isResolveDisabled
        onResolve={() => null}
      />
      <div className="flex flex-col gap-4">
        <SidebarSubtitle title="Record" iconName="ListTodo" />
        <SkeletonTable />
      </div>
      <div className="flex flex-col gap-4">
        <SidebarSubtitle title="Action details" iconName="ListTodo" />
        <SkeletonTable />
      </div>
      <div className="flex flex-col gap-4">
        <SidebarSubtitle
          title="Log and comments"
          iconName="MessageCircleMore"
        />
        <div className="rounded-md border">
          <div className="flex w-full flex-col gap-3 border-b p-6 last:border-b-0">
            <div className="flex w-full flex-row items-start gap-3">
              <Skeleton className="h-8 w-8 rounded-full" />
              <div className="flex flex-col items-start gap-1">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-3 w-16" />
              </div>
              <div className="ml-auto">
                <Skeleton className="h-3 w-20" />
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-4 w-32" />
            </div>
            <div>
              <Skeleton className="h-4 w-48" />
            </div>
          </div>
          <div className="flex w-full flex-col gap-3 border-b p-6 last:border-b-0">
            <div className="flex w-full flex-row items-start gap-3">
              <Skeleton className="h-8 w-8 rounded-full" />
              <div className="flex flex-col items-start gap-1">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-3 w-16" />
              </div>
              <div className="ml-auto">
                <Skeleton className="h-3 w-20" />
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-4 w-32" />
            </div>
            <div>
              <Skeleton className="h-4 w-48" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
