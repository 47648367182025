import { Element, Descendant, BaseText, Text } from 'slate';

export type InlineFormats = 'bold' | 'italic' | 'underline';

export type MarkAttributes = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};

export type SlateText = BaseText & MarkAttributes;

export type SlateTextElement = Element & {
  type: 'paragraph';
  children: SlateText[];
};

export type SlateMentionElement = Element & {
  type: 'mention';
  entity: string;
  children: SlateText[];
};

export type SlateLinkElement = Element & {
  type: 'link';
  url: string;
  children: SlateText[];
};

export type SlateElement =
  | SlateTextElement
  | SlateMentionElement
  | SlateLinkElement;

export type SlateDescendant = SlateElement | Descendant;

export const isSlateElement = (
  node: SlateDescendant,
  types = ['link', 'paragraph']
): node is SlateElement => {
  return types.some((type) => Element.isElementType(node, type));
};

export const isSlateText = (node: SlateDescendant): node is SlateText => {
  return Text.isText(node);
};
