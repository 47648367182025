import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { ProcessName, toast } from '@spektr/client/components';
import {
  ProcessApiClient,
  getProcessByIdQuery,
  getProcessesQueryKey,
} from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';
import { processDetailsParamsSchema } from '@spektr/shared/utils';

export const ProcessNameWrapper = () => {
  const queryClient = useQueryClient();
  const { processId } = useParsedParams(processDetailsParamsSchema);

  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));

  const updateProcess = useMutation({
    mutationFn: (name: string) =>
      ProcessApiClient.getClient().updateProcessById(
        {
          name,
        },
        {
          params: {
            id: processId,
          },
        }
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
      toast.success({
        title: 'Successfully updated',
        description: 'The process name was updated.',
      });
    },
  });

  return (
    <ProcessName
      name={process.name}
      processType={process.type}
      onNameUpdate={updateProcess.mutateAsync}
    />
  );
};
