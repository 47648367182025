import { CircleX } from 'lucide-react';

type ProcessesListErrorProps = {
  message: string;
};

export const ProcessesListError = ({ message }: ProcessesListErrorProps) => {
  return (
    <div className="text-color-text-subtext mt-6 flex items-center gap-2 rounded-md border p-4 text-sm">
      <CircleX className="text-color-red h-4 w-4" />
      {message}
    </div>
  );
};
