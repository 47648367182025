import { z } from 'zod';

import { objectIdSchema } from '../common';

export const RiskMatrixCreate = z.object({
  title: z.string(),
  description: z.string().optional(),
  columns: z.array(z.string()).default([]),
  rows: z.array(z.array(z.string())).default([]),
});
export type RiskMatrixCreate = z.infer<typeof RiskMatrixCreate>;

export const RiskMatrixTemplateCreate = RiskMatrixCreate.merge(
  z.object({
    updatedByPublisherAt: z.number().optional(),
  })
);
export type RiskMatrixTemplateCreate = z.infer<typeof RiskMatrixTemplateCreate>;

export const RiskMatrixUpdate = RiskMatrixCreate.partial();
export type RiskMatrixUpdate = z.infer<typeof RiskMatrixUpdate>;

export const RiskMatrix = RiskMatrixCreate.merge(
  z.object({
    id: objectIdSchema,
    // TODO: @Jakob on get the validation fails because rowCount is not received
    rowCount: z.number().optional(),
    createdAt: z.number(),
    updatedAt: z.number(),
  })
);
export type RiskMatrix = z.infer<typeof RiskMatrix>;

export const RiskMatrixSlim = RiskMatrix.omit({ rows: true });
export type RiskMatrixSlim = z.infer<typeof RiskMatrixSlim>;
