import { HTMLAttributes, forwardRef } from 'react';

import { cn } from '@spektr/client/utils';

type TableBodyProps = HTMLAttributes<HTMLTableSectionElement>;

export const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  ({ className, ...props }, ref) => (
    <tbody
      ref={ref}
      className={cn('text-color-text-table-default', className)}
      {...props}
    />
  )
);
