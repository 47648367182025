import { z } from 'zod';

import { FieldUiConfig } from './FieldConfig';

export const ParagraphField = z.object({
  id: z.string(),
  ui: FieldUiConfig.optional(),
  type: z.literal('paragraph'),
  attributes: z.object({
    content: z.string(),
  }),
});

export type ParagraphField = z.infer<typeof ParagraphField>;

export const isParagraphField = (field: unknown): field is ParagraphField => {
  return ParagraphField.safeParse(field).success;
};
