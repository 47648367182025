import { type BrandingFormTheme } from '@spektr/branding-types';

export function createUpdateBrandingAction(payload: BrandingFormTheme) {
  return {
    type: 'update_branding' as const,
    payload,
  };
}

export type UpdateBrandingAction = ReturnType<
  typeof createUpdateBrandingAction
>;
