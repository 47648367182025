import { type DeserializedBrandingFormAssistingContent } from '@spektr/moonraker-types';

export function createUpdateAssistingContentAction(
  payload: DeserializedBrandingFormAssistingContent
) {
  return {
    type: 'update_assistingContent' as const,
    payload,
  };
}

export type UpdateAssistingContentAction = ReturnType<
  typeof createUpdateAssistingContentAction
>;
