import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AlertTriangle, Key, Tag } from 'lucide-react';

import { ColumnDef } from '@tanstack/react-table';

import { SPEKTR_FIELD_TYPES } from '@spektr/client/constants';

import { isSupportedDataType } from '@spektr/shared/utils';

import {
  Checkbox,
  DataTable,
  RadioCheckbox,
  RadioGroup,
  Select,
  Tooltip,
} from '@spektr/client/components';

import { DatasetField } from '@spektr/shared/types';

export type DatasetTableProps = {
  isEditable?: boolean;
  fieldId?: string;
  fields: DatasetField[];
};

export const DatasetTable = ({
  isEditable = false,
  fieldId,
  fields,
}: DatasetTableProps) => {
  const { control, setValue } = useFormContext();

  const columns = useMemo<ColumnDef<DatasetField>[]>(
    () => [
      {
        accessorKey: 'isIncluded',
        header: () => (
          <div className="flex items-center justify-center">
            <Tooltip
              content={
                <p className="w-[140px]">
                  Select entries to extract into the spektr platform
                </p>
              }
              side="left"
              sideOffset={6}
            >
              <Tag className="h-[14px] w-[14px]" />
            </Tooltip>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex flex-row items-center justify-center">
            <Controller
              name={`fields.${row.original.name}.isIncluded`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  checked={value}
                  disabled={!isEditable}
                  onCheckedChange={(value) => {
                    typeof value === 'boolean' && onChange(value);
                  }}
                />
              )}
            />
          </div>
        ),
      },
      {
        accessorKey: 'name',
        header: 'Attribute name',
      },
      {
        accessorKey: 'type',
        size: 140,
        header: () => <div className="mr-9 text-right">Data type</div>,
        cell: ({ row }) => (
          <div className="flex h-[35px] items-center justify-end gap-6">
            {!isSupportedDataType(row.original.type) && (
              <Tooltip
                align="end"
                side="bottom"
                content={
                  <div className="text-color-secondary-yellow flex flex-col gap-1 text-xs">
                    <span className="font-semibold">
                      Unable to automatically determine type.
                    </span>
                    <span className="">
                      Please make sure the correct type is selected.
                    </span>
                  </div>
                }
              >
                <AlertTriangle className="stroke-color-secondary-yellow h-[14px] w-[14px]" />
              </Tooltip>
            )}
            {
              <div className="w-[100px]">
                <Controller
                  name={`fields.${row.original.name}.type`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value.toLowerCase()}
                      disabled={!isEditable}
                      options={SPEKTR_FIELD_TYPES}
                      placeholder="Select field type"
                      size="small"
                      onValueChange={onChange}
                    />
                  )}
                />
              </div>
            }
          </div>
        ),
      },
      {
        accessorKey: 'identifier',
        header: () => (
          <div className="flex items-center justify-center">
            <Tooltip
              content={
                <p className="w-[140px]">
                  Select the identifier for the dataset
                </p>
              }
              side="left"
              sideOffset={6}
            >
              <Key className="h-[14px] w-[14px] -scale-x-100" />
            </Tooltip>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex flex-col items-center">
            <RadioCheckbox
              disabled={!isEditable}
              value={row.original.name}
              id={`${row.original.name}.isIdentifier`}
            />
          </div>
        ),
      },
    ],
    [control, isEditable]
  );

  return (
    <div className="relative w-full rounded-t">
      <RadioGroup
        defaultValue={fieldId}
        onValueChange={(value) => {
          setValue('fieldId', value);
        }}
      >
        <DataTable className="h-[300px]" columns={columns} data={fields} />
      </RadioGroup>
    </div>
  );
};
