import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { z } from 'zod';
import { InfoIcon } from 'lucide-react';

import {
  Button,
  Checkbox,
  Input,
  Label,
  Tooltip,
} from '@spektr/client/components';
import { PublicUsersApiClient } from '@spektr/client/services';

import { SET_UP_ACCOUNT_URL } from '@spektr/shared/utils';
import { UserSignUpDemoSchema } from '@spektr/shared/validators';

const demoSignUpValidationSchema = UserSignUpDemoSchema.extend({
  passwordConfirm: z.string(),
  termsOfService: z.boolean(),
}).refine(
  (data) => {
    return data.password === data.passwordConfirm;
  },
  {
    message: 'Passwords do not match',
    path: ['passwordConfirm'],
  }
);

type DemoSignUpValidationSchema = z.infer<typeof demoSignUpValidationSchema>;

type DemoSignUpProps = {
  logIn: (email: string, password: string) => Promise<void>;
};

export const DemoSignUp = ({ logIn }: DemoSignUpProps) => {
  const [error, setError] = useState<string>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sessionId = searchParams.get('utm_session');

  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control,
  } = useForm<DemoSignUpValidationSchema>({
    resolver: zodResolver(demoSignUpValidationSchema),
  });

  const signUpMutation = useMutation({
    mutationFn: async (values: UserSignUpDemoSchema) => {
      await PublicUsersApiClient.getClient().signUpDemoUser(
        {
          username: values.username,
          password: values.password,
        },
        {
          headers: {
            'x-session-id': sessionId,
          },
        }
      );
      await logIn(values.username, values.password);
    },
    onError: (error) => {
      throw error;
    },
  });

  const handleSubmit = async (data: DemoSignUpValidationSchema) => {
    setError(undefined);

    try {
      await signUpMutation.mutateAsync({
        username: data.username,
        password: data.password,
      });
      navigate(SET_UP_ACCOUNT_URL);
    } catch (error) {
      if (isAxiosError(error)) {
        setError(error.response?.data?.message ?? 'Something went wrong!');
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <div className="flex w-full flex-col gap-3">
        <div>
          <Label className="text-sm" htmlFor="email">
            Business Email
          </Label>
          <Input
            id="email"
            type="email"
            placeholder="Input your business email"
            data-testid="signup-form-input-email"
            required
            {...register('username', { required: true })}
          />
          {errors.username && (
            <span className="text-color-red text-sm">
              {errors.username.message}
            </span>
          )}
        </div>
        <div>
          <div className="flex items-center gap-2">
            <Label className="text-sm" htmlFor="password">
              Password
            </Label>
            <Tooltip
              content="* Minimum of 8 characters required, including at least one uppercase letter, one symbol and one number"
              side="right"
              sideOffset={6}
            >
              <InfoIcon className="text-color-text-icon-secondary h-3 w-3" />
            </Tooltip>
          </div>
          <Input
            id="password"
            type="password"
            placeholder="Input the password"
            data-testid="signup-form-input-password"
            required
            {...register('password', { required: true })}
          />
          {errors.password && (
            <span className="text-color-red text-sm">
              {errors.password.message}
            </span>
          )}
        </div>
        <div>
          <Label className="text-sm" htmlFor="passwordConfirm">
            Repeat Password
          </Label>
          <Input
            id="passwordConfirm"
            type="password"
            placeholder="Input the password confirmation"
            data-testid="signup-form-input-password-confirm"
            required
            {...register('passwordConfirm', { required: true })}
          />
          {errors.passwordConfirm && (
            <span className="text-color-red text-sm">
              {errors.passwordConfirm.message}
            </span>
          )}
        </div>
        <Controller
          name="termsOfService"
          render={({ field }) => (
            <Checkbox
              id="termsOfService"
              required
              {...field}
              value={undefined}
              className="text-color-text-primary items-start"
              label={
                <span className="text-color-text-checkbox text-xs">
                  By signing up, you accept our{' '}
                  <a
                    href="https://spektr.com/terms-of-service"
                    target="_blank"
                    className="underline"
                  >
                    Terms of Service
                  </a>
                </span>
              }
              defaultChecked={false}
              checked={field.value}
              onCheckedChange={field.onChange}
              data-testid="signup-form-checkbox-terms-of-service"
            />
          )}
          control={control}
        />

        {error && <div className="text-color-red text-sm">{error}</div>}
      </div>
      <Button
        color="white"
        type="submit"
        fullWidth
        className="mt-6"
        data-testid="signup-form-button-signup"
      >
        {signUpMutation.isPending ? 'Signing up...' : 'Sign up'}
      </Button>
    </form>
  );
};
