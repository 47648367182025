import { useRef } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';

import { Button, Input, Label } from '@spektr/client/components';
import { getVendorSettingsByVendor } from '@spektr/client/services';

import {
  useCreateVendorSettings,
  useUpdateVendorSettings,
} from '../../ConnectionHub/hooks/mutations';

export type ServiceDialogContentProps = {
  serviceName: string;
  onCancel: () => void;
};

export const ServiceDialogContent = ({
  serviceName,
  onCancel,
}: ServiceDialogContentProps) => {
  const apiKeyRef = useRef<HTMLInputElement>(null);

  const { data: vendorSettings } = useSuspenseQuery(
    getVendorSettingsByVendor(serviceName)
  );

  const createVendorSettingsMutation = useCreateVendorSettings();
  const updateVendorSettingsMutation = useUpdateVendorSettings();

  const handleOnResolve = () => {
    if (!vendorSettings.vendor) {
      handleOnCreate(apiKeyRef.current?.value || '');
      return;
    }

    handleOnUpdate(apiKeyRef.current?.value || '');
  };

  const handleOnCreate = async (keyValue: string) => {
    await createVendorSettingsMutation.mutateAsync({
      vendor: serviceName,
      apiKey: { key: keyValue },
    });
  };

  const handleOnUpdate = async (keyValue: string) => {
    await updateVendorSettingsMutation.mutateAsync({
      body: { apiKey: { key: keyValue } },
      vendor: serviceName,
    });
  };

  const handleOnDelete = async () => {
    await updateVendorSettingsMutation.mutateAsync({
      body: { apiKey: { key: '' } },
      vendor: serviceName,
    });
  };

  const handleTextareaKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      handleOnResolve();
    }
  };

  return (
    <>
      <p className="text-color-text-subtext mb-6 text-sm font-normal leading-5">
        We offer the ability for you to connect to this service with your own
        contract. Please add you API key below.
      </p>

      <Label className="mb-1.5 text-xs" htmlFor="api-key">
        API key
      </Label>
      <Input
        name="api-key"
        type="text"
        placeholder="Enter API key"
        ref={apiKeyRef}
        defaultValue={vendorSettings.apiKey?.key}
        onKeyDown={(event) => handleTextareaKeyDown(event)}
      />

      <div className="mt-4 flex justify-between gap-2">
        {vendorSettings.apiKey?.key && (
          <span className="text-color-red text-sm" data-cy="deleteButton">
            <Button
              variant="text"
              color="red"
              className="px-1"
              onClick={() => handleOnDelete()}
            >
              Delete configuration
            </Button>
          </span>
        )}

        <div className="ml-auto flex gap-2">
          <span data-cy="cancelButton">
            <Button variant="text" onClick={() => onCancel()}>
              Cancel
            </Button>
          </span>
          <span data-cy="setupButton">
            <Button onClick={() => handleOnResolve()}>
              {!vendorSettings.apiKey?.key ? 'Set up' : 'Edit set up'}
            </Button>
          </span>
        </div>
      </div>
    </>
  );
};
