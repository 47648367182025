import { z } from 'zod';

import { objectIdSchema } from '../common';

export const createNodeBaseSchema = z.strictObject({
  predecessorId: objectIdSchema.optional(),
  successorId: objectIdSchema.optional(),
  edgeType: z
    .string()
    .optional()
    .refine((value) => value === undefined || value === 'fallback'),
});
