import { HTMLAttributes } from 'react';

export const MitIdIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="110"
    height="31"
    viewBox="0 0 110 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_11155_24497)">
      <path
        d="M48.8689 3.61475V9.32229H53.1967V13.8883H48.8689V23.0204C48.8689 24.7327 49.7705 25.3034 51.2131 25.3034C52.1148 25.3034 53.0164 25.1132 53.5574 24.5424V29.6792C52.8361 30.0597 51.3934 30.25 49.9508 30.25C45.9836 30.25 43.459 28.1572 43.459 23.5912V13.8883H40.3934V9.32229H43.459V3.61475H48.8689Z"
        fill="white"
      />
      <path
        d="M93.5902 0.380371C105.131 0.380371 110 7.03918 110 15.22C110 23.4008 105.131 30.0596 93.5902 30.0596H88.9016V0.380371H93.5902Z"
        fill="white"
      />
      <path
        d="M72.8525 0C69.0656 0 66 3.23433 66 7.22961C66 11.2249 69.0656 14.459 72.8525 14.459C76.6394 14.459 79.7048 11.2249 79.7048 7.22961C79.7048 3.23433 76.6394 0 72.8525 0ZM72.8525 17.1226C66.541 17.1226 61.3114 20.9277 60.5901 30.0598H85.2952C84.5738 20.9277 79.3443 17.1226 72.8525 17.1226Z"
        fill="white"
      />
      <path
        d="M5.22951 1.52197L13.5246 15.0298L21.6393 1.52197H26.8689V30.0597H21.2787V11.6053L13.6148 23.7814H13.4344L5.59016 11.6053V30.0597H0V1.52197H5.22951Z"
        fill="white"
      />
      <path
        d="M34.6229 0C32.8196 0 31.3771 1.52193 31.3771 3.42444C31.3771 5.32696 32.8196 6.84906 34.6229 6.84906C36.4262 6.84906 37.8689 5.32696 37.8689 3.42444C37.8689 1.52193 36.4262 0 34.6229 0ZM31.9181 9.32239V30.0598H37.3279V9.32239H31.9181Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_11155_24497">
        <rect width="110" height="30.25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
