import { useRef } from 'react';
import {
  Button,
  BasicDialog,
  Label,
  Input,
  ButtonProps,
} from '@spektr/client/components';

import { Dataset } from '@spektr/shared/types';

export type RenameDatasetDialogProps = {
  buttonLabel?: string;
  buttonColor?: ButtonProps['color'];
  open: boolean;
  dataset?: Dataset;
  title?: string;
  onClose: () => void;
  onSave: (name: string) => void;
};

export const RenameDatasetDialog = ({
  buttonLabel = 'Rename dataset',
  buttonColor = 'yellow',
  open,
  dataset,
  title = 'Rename dataset',
  onClose,
  onSave,
}: RenameDatasetDialogProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = () => {
    if (!inputRef.current) {
      return;
    }

    onSave(inputRef.current.value);
  };

  return (
    <BasicDialog
      className="w-[512px] rounded-2xl border-0"
      key={`${open}-dataset`}
      defaultOpen={open}
      title={title}
      onClose={onClose}
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-col gap-2">
          <Label
            htmlFor="rename-dataset-input"
            className="text-color-text-subtext"
          >
            Dataset name
          </Label>
          <Input
            autoFocus
            ref={inputRef}
            id="rename-dataset-input"
            defaultValue={dataset?.name}
          />
        </div>

        <div className="ml-auto flex flex-row gap-4">
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button color={buttonColor} onClick={handleSave}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </BasicDialog>
  );
};
