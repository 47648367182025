import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from 'reactflow';
import { useTranslation } from 'react-i18next';

import { TopologyProcessOutcome } from '@spektr/shared/types';
import { cn } from '@spektr/client/utils';

export function EdgeToSegment({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style,
}: EdgeProps<TopologyProcessOutcome>) {
  const { t } = useTranslation();
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeLength = targetX - sourceX;
  const edgeSecondSegmentCenter = targetX - edgeLength / 4;
  const spaceAroundLabel = 20;
  const maxLabelWidth = edgeLength / 2 - spaceAroundLabel;

  return (
    <>
      <BaseEdge id={id} style={style} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          className={cn(
            'absolute',
            'px-2.5 py-1.5',
            'dark:bg-spektr-gray-200 bg-color-bg-accent',
            'rounded border shadow-lg dark:border-0'
          )}
          style={{
            transform: `translate(${edgeSecondSegmentCenter}px,${targetY}px) translate(-50%, -50%)`,
            maxWidth: `${maxLabelWidth}px`,
          }}
        >
          <p className="text-color-text-primary/60 line-clamp-1 text-xs">
            {data?.isFinal
              ? t(`process:nodes.kyckr.outcome.label.${data.title}`) || data.id
              : data?.title}
          </p>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
