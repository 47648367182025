import { useCallback, useEffect, useMemo, useState } from 'react';
import { Range, createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { isHotkey } from 'is-hotkey';

import { cn } from '@spektr/client/utils';

import { type SlateDescendant } from '@spektr/shared/slate-types';

import { HOTKEYS } from './constants';
import {
  toggleMark,
  transformNodesToParagraphs,
  trimRichTextEditorContent,
  getMatchesForMention,
  selectMention,
} from './utils';

import { withMentions, withInlines } from './providers';

import { Leaf } from './components/Leaf';
import { Toolbar } from './components/Toolbar';
import { ToolbarOptions } from './components/ToolbarOptions';
import { MentionDropdown } from './components/MentionDropdown';
import { Element } from './components/Element';

export type RichTextEditorProps = {
  className?: string;
  isReadOnly?: boolean;
  initialValue?: SlateDescendant[];
  placeholder?: string;
  resetOnUpdate?: boolean;
  floatingToolbar?: boolean;
  formats?: string[];
  mentionOptions?: string[];
  style?: React.CSSProperties;
  onChange?: (value: SlateDescendant[]) => void;
};

export const RichTextEditor = ({
  className,
  isReadOnly = false,
  initialValue = [{ type: 'paragraph', children: [{ text: '' }] }],
  placeholder = '',
  resetOnUpdate = false,
  floatingToolbar = true,
  formats = ['bold', 'italic', 'underline'],
  mentionOptions = [],
  style,
  onChange,
}: RichTextEditorProps) => {
  const [editor] = useState(() =>
    withInlines(
      withMentions(withReact(transformNodesToParagraphs(createEditor())))
    )
  );
  const [resetKey, setResetKey] = useState(0);
  const [mentionSearch, setMentionSearch] = useState('');
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(0);
  const [target, setTarget] = useState<Range | null>();

  const searchedMentions = useMemo(
    () =>
      mentionOptions.filter((mention) =>
        mention.toLowerCase().includes(mentionSearch.toLowerCase())
      ),
    [mentionSearch, mentionOptions]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];

          if (formats.includes(mark)) {
            toggleMark(editor, mark);
          }
        }
      }

      if (target) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            setSelectedMentionIndex(
              selectedMentionIndex >= searchedMentions.length - 1
                ? 0
                : selectedMentionIndex + 1
            );
            break;
          case 'ArrowUp':
            event.preventDefault();
            setSelectedMentionIndex(
              selectedMentionIndex <= 0
                ? searchedMentions.length - 1
                : selectedMentionIndex - 1
            );
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            selectMention(
              editor,
              searchedMentions,
              selectedMentionIndex,
              target
            );
            break;
        }
      }
    },
    [selectedMentionIndex, mentionSearch, searchedMentions, target, formats]
  );

  const handleChange = (value: SlateDescendant[]) => {
    onChange?.(trimRichTextEditorContent(value));

    if (mentionOptions.length) {
      const matches = getMatchesForMention(editor);

      if (matches) {
        setTarget(matches.target);
        setMentionSearch(matches.substring ?? '');
        setSelectedMentionIndex(0);
        return;
      }
    }

    setTarget(null);
  };

  useEffect(() => {
    if (resetOnUpdate) {
      setResetKey((val) => val + 1);
    }
  }, [initialValue, resetOnUpdate]);

  return (
    <Slate
      key={resetKey}
      editor={editor}
      initialValue={initialValue}
      onChange={handleChange}
    >
      <div
        className={cn(
          'rounded-md border p-3',
          'focus-within:outline-none focus-within:ring-1 focus-within:ring-blue-500',
          'border-color-border-input text-color-text-editor',
          isReadOnly && 'border-none bg-transparent p-0'
        )}
        style={style}
      >
        {!isReadOnly && formats.length > 0 && (
          <Toolbar floating={floatingToolbar}>
            <ToolbarOptions options={formats} />
          </Toolbar>
        )}
        <Editable
          style={{ overflowWrap: 'anywhere' }}
          className={cn(
            'text-sm font-medium',
            'overflow-y-auto',
            'bg-transparent',
            'text-inherit',
            'placeholder:text-color-text-placeholder',
            'disabled:text-color-text-editor--disabled disabled:opacity-50',
            'focus:ring-0 focus-visible:outline-none focus-visible:ring-0',
            className
          )}
          readOnly={isReadOnly}
          placeholder={placeholder}
          renderLeaf={(props) => <Leaf {...props} />}
          renderElement={(props) => <Element {...props} />}
          onKeyDown={handleKeyDown}
        />
        {mentionOptions.length > 0 && (
          <MentionDropdown
            mentions={searchedMentions}
            selectedMentionIndex={selectedMentionIndex}
            target={target}
            onUpdateTarget={setTarget}
          />
        )}
      </div>
    </Slate>
  );
};
