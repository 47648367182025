import { useSearchParams } from 'react-router-dom';

import { getActionTypeBasedOnService } from '../utils';
import { ClientActionType } from '../types';

export const useGetActionType = (): ClientActionType => {
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');

  return getActionTypeBasedOnService(service);
};
