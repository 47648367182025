import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  LOOPS_KEYS,
  NodesApiClient,
  getProcessQueryKey,
  getTopologyQueryKey,
} from '@spektr/client/services';

export function useDeleteNode(
  processId: string,
  nodeId: string,
  isLoop = false
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () =>
      NodesApiClient.getClient().deleteNodeById(undefined, {
        params: {
          processId,
          nodeId,
        },
      }),
    onSuccess: async () => {
      if (isLoop) {
        await queryClient.invalidateQueries({
          queryKey: getProcessQueryKey(processId),
        });
        await queryClient.resetQueries({
          queryKey: LOOPS_KEYS.DETAILS(processId),
        });
      } else {
        await queryClient.resetQueries({
          queryKey: getProcessQueryKey(processId),
        });
      }

      await queryClient.resetQueries({
        queryKey: getTopologyQueryKey(),
      });
    },
  });

  return mutation;
}
