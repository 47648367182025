import { useNavigate } from 'react-router-dom';
import { Rocket, Route, Unplug } from 'lucide-react';

import {
  processDetailsParamsSchema,
  loopScheduleUrl,
  loopStrategyFullUrl,
  loopFieldMappingFullUrl,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import { Button } from '@spektr/client/components';

import { ProcessDemoButton } from '@spektr/model-builder/containers';

export type LoopActionsHeaderProps = { publicPageUrl: string };
export const LoopActionsHeader = ({
  publicPageUrl,
}: LoopActionsHeaderProps) => {
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const navigate = useNavigate();

  const handleClickSchedule = () => {
    navigate(loopScheduleUrl(processId));
  };

  return (
    <div className="flex items-center gap-4">
      <ProcessDemoButton processId={processId} publicPageUrl={publicPageUrl} />
      <Button
        variant="text"
        size="sm"
        className="gap-2"
        onClick={() => navigate(loopStrategyFullUrl(processId))}
      >
        <Route className="h-4 w-4" />
        <span>Strategy</span>
      </Button>
      <Button
        variant="text"
        size="sm"
        className="gap-2"
        onClick={() => navigate(loopFieldMappingFullUrl(processId))}
      >
        <Unplug className="h-4 w-4" />
        <span>Field mapping</span>
      </Button>
      <Button
        startIcon={<Rocket className="h-4 w-4" />}
        color="red"
        onClick={handleClickSchedule}
      >
        Launch loop
      </Button>
    </div>
  );
};
