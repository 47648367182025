import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getProcessOutcomesWithTitle } from '@spektr/shared/utils';

import { getProcessByIdQuery } from '@spektr/client/services';

import { ActionFieldSchema } from '@spektr/shared/validators';
import { LoopSourceExtended } from '@spektr/shared/types';

import { computeAvailableAndSelectedFields } from '../../utils';

import { ActionRow } from '../../components/ActionRow';
import { useSelectedFieldsContext } from '../../hooks/useSelectedFieldsContext';

export type ProcessFieldsDetailsProps = {
  action: ActionFieldSchema;
  sources: LoopSourceExtended[];
};

export const ProcessFieldsDetails = ({
  action,
  sources,
}: ProcessFieldsDetailsProps) => {
  const { data: process } = useSuspenseQuery(
    getProcessByIdQuery(action.processId)
  );

  const { getSelectedFields, onUpdateField } = useSelectedFieldsContext();

  const handleUpdateField = (fields: Record<string, boolean>) => {
    onUpdateField(action.processId, action.nodeId, fields);
  };

  const outcomesWithTitles = useMemo(
    () => getProcessOutcomesWithTitle(process),
    [process]
  );

  const branches = useMemo(() => {
    return sources
      .map((source) => {
        return outcomesWithTitles
          .filter((outcome) => outcome.id === source.outcomeId)
          .map((outcome) => outcome.title);
      })
      .flat();
  }, [outcomesWithTitles, sources]);

  const node = useMemo(() => {
    return process.nodes.get(action.nodeId);
  }, [action.nodeId, process.nodes]);

  const fields = useMemo(() => {
    const selectedFields = getSelectedFields(action.processId, action.nodeId);

    return computeAvailableAndSelectedFields(action, selectedFields);
  }, [action, getSelectedFields]);

  return (
    <ActionRow
      nodeType={node?.nodeType}
      processBranch={
        branches.length > 0 ? branches.join(', ') : 'Something has changed'
      }
      processName={process.name}
      processType={process.type}
      fields={fields}
      onUpdateField={handleUpdateField}
    />
  );
};
