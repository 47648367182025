import { ReactNode } from 'react';

import { Checkbox } from '@spektr/client/components';

export type ActionRowItemProps = {
  children: ReactNode;
  label: string;
  selected?: boolean;
  onSelect?: (value: boolean) => void;
};

export const ActionRowItem = ({
  children,
  label,
  selected = false,
  onSelect,
}: ActionRowItemProps) => {
  return (
    <div className="flex h-6 flex-row flex-nowrap items-center gap-6">
      {onSelect ? (
        <Checkbox
          defaultChecked={selected}
          onCheckedChange={(checked) => {
            if (typeof checked === 'boolean') {
              onSelect(checked);
            }
          }}
        />
      ) : (
        <div className="ml-5" />
      )}
      <div className="w-full max-w-[80px]">
        <span className="text-color-text-subtext text-sm font-medium">
          {label}
        </span>
      </div>
      <div className="text-color-text-primary text-sm font-medium">
        {children}
      </div>
    </div>
  );
};
