import { z } from 'zod';
import { makeParameters } from '@zodios/core';

export const filterParameters = makeParameters([
  {
    name: 'sortOrder',
    schema: z.string().optional(),
    type: 'Query',
  },
  {
    name: 'sortField',
    schema: z.string().optional(),
    type: 'Query',
  },
  {
    name: 'searchField',
    schema: z.string().optional(),
    type: 'Query',
  },
  {
    name: 'searchValue',
    schema: z.string().optional(),
    type: 'Query',
  },
]);
