import { Button, Skeleton } from '@spektr/client/components';

export const SkeletonHitComments = () => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <Skeleton className="h-14 w-full" />
        <Button className="ml-auto" disabled>
          Add comment
        </Button>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-row items-start gap-3">
          <Skeleton className="h-8 w-8 rounded-full" />
          <div className="flex flex-col items-start gap-1">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-3 w-16" />
          </div>
        </div>
        <Skeleton className="h-20 w-full"></Skeleton>
        <Skeleton className="w-20"></Skeleton>
      </div>
    </div>
  );
};
