import { useMemo, useState } from 'react';

import { SpektrField } from '@spektr/shared/types';
import {
  NodeServiceType,
  SpektrFieldTypedKey,
} from '@spektr/shared/validators';

import {
  Combobox,
  ComboboxGroup,
  ComboboxItem,
  ComboboxProps,
  DatasetField,
} from '@spektr/client/components';
import { cn } from '@spektr/client/utils';
import { groupBy, StrictOmit } from '@spektr/shared/utils';
import { getServiceFieldsWithoutSubfields } from '@spektr/shared/components';

import { ServiceGroup } from './ServiceGroup';
import { CustomFieldsComboboxGroup } from './CustomFields';

export type SpektrfieldComboboxProps = {
  fields: Array<SpektrField | SpektrFieldTypedKey>;
} & StrictOmit<
  ComboboxProps,
  'onSearch' | 'noResults' | 'contentClassName' | 'noResultsText' | 'children'
>;

export const SpektrfieldCombobox = ({
  fields: initialFields,
  className,
  ...rest
}: SpektrfieldComboboxProps) => {
  const [search, setSearch] = useState('');
  const hasScore = useMemo(
    () => initialFields.some((field) => field.key === 'score'),
    [initialFields]
  );

  const fields: Array<StrictOmit<SpektrField, 'sourceId'>> = useMemo(
    () =>
      getServiceFieldsWithoutSubfields(initialFields)
        .filter((field) => field.key !== 'score') // handle score separately bc we always want it to be on top in the combobox
        .map((field) => {
          const label = 'label' in field ? field.label : field.key;
          const availableIn =
            'availableIn' in field ? field.availableIn : undefined;
          const source = 'source' in field ? field.source : 'custom'; // a field without a source is considered a custom field

          return {
            key: field.key,
            type: field.type,
            label,
            availableIn,
            source,
          };
        }),
    [initialFields]
  );

  const filteredBySearch = useMemo(
    () =>
      fields.filter((field) =>
        field.label.toLowerCase().includes(search.toLowerCase())
      ),
    [fields, search]
  );

  const groups = useMemo(
    () => groupBy(filteredBySearch, 'source'),
    [filteredBySearch]
  );
  const { dataset, custom, ...serviceGroups } = groups;
  const isEmpty = Object.keys(groups).length === 0;

  return (
    <Combobox
      className={cn('w-[374px]', className)}
      onSearch={setSearch}
      noResults={isEmpty}
      contentClassName="max-h-none p-0"
      {...rest}
    >
      <ComboboxGroup
        className={cn(
          'max-h-64 w-full overflow-y-auto',
          !isEmpty && 'px-2 py-1'
        )}
      >
        {hasScore && <ComboboxItem value="score" children="Score" />}
        {dataset && dataset.length > 0 && (
          <ComboboxGroup label="Dataset fields">
            {dataset.map(({ key, label }) => (
              <DatasetField key={key} value={key} children={label} />
            ))}
          </ComboboxGroup>
        )}
        {Object.keys(serviceGroups).length > 0 && (
          <ComboboxGroup label="Service fields">
            {Object.entries(serviceGroups ?? {}).map(([source, fields]) => (
              <ServiceGroup
                key={source}
                name={source}
                fields={fields ?? []}
                serviceName={source as NodeServiceType}
              />
            ))}
          </ComboboxGroup>
        )}
        {custom && <CustomFieldsComboboxGroup fields={Object.values(custom)} />}
      </ComboboxGroup>
    </Combobox>
  );
};
