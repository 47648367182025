import { Badge } from '../../Badge';

type ActionStatusBadgeProps = {
  hitsCount: number;
  resolvedHitsCount: number;
};

export const ActionStatusBadge = ({
  hitsCount,
  resolvedHitsCount,
}: ActionStatusBadgeProps) => {
  return (
    <Badge
      className="text-nowrap"
      variant={resolvedHitsCount === hitsCount ? 'success' : 'danger'}
    >{`${resolvedHitsCount} out of ${hitsCount} resolved`}</Badge>
  );
};
