import { useMemo, useState } from 'react';
import { Columns3 } from 'lucide-react';

import { cn } from '@spektr/client/utils';
import { Popover } from '@spektr/client/components';

import { useGetDatasets } from '../../hooks';
import { ColumnsProvider, useRecordsContext } from '../../providers';

import { ColumnsContainer } from './ColumnsContainer';

export const ColumnsDropdown = () => {
  const { selectedDataset } = useRecordsContext();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const datasets = useGetDatasets();

  const dataset = useMemo(
    () => datasets?.find((d) => d.id === selectedDataset),
    [datasets, selectedDataset]
  );

  return (
    <Popover
      open={isPopoverOpen}
      side="bottom"
      align="end"
      onOpenChange={setPopoverOpen}
      trigger={
        <div
          className={cn(
            'flex h-9 items-center gap-2 px-4 py-2.5',
            'rounded-md border',
            'text-color-text-subtext text-sm font-medium'
          )}
        >
          <Columns3 className="h-4 w-4" />
          Columns
        </div>
      }
    >
      <ColumnsProvider dataset={dataset}>
        <ColumnsContainer />
      </ColumnsProvider>
    </Popover>
  );
};
