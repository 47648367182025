import { memo } from 'react';
import { Handle, Position } from 'reactflow';

import { cn } from '@spektr/client/utils';
import { Logo } from '@spektr/client/components';

export const RootNode = memo(({ id }: { id: string }) => (
  <div
    className={cn(
      'flex items-center justify-center',
      'h-[50px] w-[50px]',
      'rounded-lg border',
      'bg-color-bg-accent',
      'shadow-lg duration-200',
      'hover:bg-color-bg-accent/70'
    )}
    onClick={(e) => e.stopPropagation()}
  >
    <Logo className="h-[28px] w-[26px]" />

    <Handle
      type="source"
      id={id}
      position={Position.Right}
      className="opacity-0"
    />
  </div>
));
